import React, { FC } from 'react';
import {
  LibraryCategories,
  OperationStepEstimable,
} from 'common-types/library';
import { ListRow } from './ListRow';
import { StepBarItem } from './StepBarItem';
import OperationStep from './OperationStep';
import { useTranslation } from 'react-i18next';

interface ElementsListProps {
  elements?: OperationStepEstimable[];
  onTriggerGallery?(id: number): void;
}

export const ElementsList: FC<ElementsListProps> = ({
  elements,
  onTriggerGallery,
}) => {
  const { t } = useTranslation();

  const getDisplayField = (element: OperationStepEstimable) => {
    const isRisk = element.category === LibraryCategories.risks;
    return {
      key: isRisk ? 'mitigationAction' : 'description',
      label: isRisk ? t('enterMitigationAction') : t('enterDescription'),
    };
  };

  return (
    <>
      {elements?.map((element) => (
        <ListRow
          key={element.uuid}
          barColumn={
            <StepBarItem
              hideEdgeBorders
              category={element.category}
              step={element.step}
            />
          }
        >
          <OperationStep
            className="mb-0"
            displayField={getDisplayField(element)}
            element={element}
            onTriggerGallery={onTriggerGallery}
            readonly
            withImage
          />
        </ListRow>
      ))}
    </>
  );
};
