import deepmerge from 'deepmerge';
import { CREATE_TEMPLATE_MATERIALS_GROUP_SUCCESS } from 'store/modules/templates-library';

export default (state = {}, action) => {
  const { type, payload } = action;
  if (payload?.entities?.templateMaterials) {
    const result = deepmerge(state, payload.entities.templateMaterials, {
      arrayMerge: (prev, next) => next,
    });
    return result;
  }

  switch (type) {
    case CREATE_TEMPLATE_MATERIALS_GROUP_SUCCESS: {
      const { requestId: templateId } = action;
      const { result } = payload;
      const templateMaterials = state[templateId] || {};
      const templateMaterialsGroups = templateMaterials.materialsGroups || [];

      return {
        ...state,
        [templateId]: {
          ...templateMaterials,
          materialsGroups: templateMaterialsGroups.concat(result),
        },
      };
    }

    default:
      return state;
  }
};
