import { Attachment as AttachmentT } from 'common-types/attachments';
import { Col, Row } from 'components/Grid';
import React, { ComponentType } from 'react';
import { AttachmentItem } from '../AttachmentItem/AttachmentItem';
import { AttachmentItemEditable } from '../AttachmentItem/AttachmentItemEditable';

export interface Props {
  attachments?: AttachmentT[];
  colBasis?: string | number;
  onGalleryRequest?: (attachmentId: number) => void;
  onRemove?: (attachmentId: number) => void;
  readonly?: boolean;
}

export const AttachmentsList: ComponentType<Props> = ({
  attachments,
  colBasis = '25%',
  onGalleryRequest,
  onRemove,
  readonly = false,
}) => {
  if (!attachments) return null;

  return (
    <Row>
      {attachments.map((attachment: AttachmentT) => {
        const { id, description, fileUrl, name, mimeType } = attachment;
        return (
          <Col basis={colBasis} className="mb-1">
            {readonly ? (
              <AttachmentItem
                id={id}
                description={description}
                fileUrl={fileUrl}
                key={id}
                name={name}
                mimeType={mimeType}
                onPictureClick={onGalleryRequest}
              />
            ) : (
              <AttachmentItemEditable
                description={description}
                fileUrl={fileUrl}
                id={id}
                key={id}
                name={name}
                mimeType={mimeType}
                onPictureClick={onGalleryRequest}
                onRemove={onRemove}
              />
            )}
          </Col>
        );
      })}
    </Row>
  );
};
