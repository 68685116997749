import {
  createRequestActionCreator,
  createSuccessActionCreator,
  createFailureActionCreator,
} from 'store/utils';
import types from 'store/modules/users/types';

const readNotApprovedOperationsCountRequest = createRequestActionCreator(
  types.READ_NOT_APPROVED_OPERATIONS_COUNT_REQUEST
);

const readNotApprovedOperationsCountSuccess = createSuccessActionCreator(
  types.READ_NOT_APPROVED_OPERATIONS_COUNT_SUCCESS
);

const readNotApprovedOperationsCountFailure = createFailureActionCreator(
  types.READ_NOT_APPROVED_OPERATIONS_COUNT_FAILURE
);

export default {
  readNotApprovedOperationsCountRequest,
  readNotApprovedOperationsCountSuccess,
  readNotApprovedOperationsCountFailure,
};
