import DropToUpload from 'assets/DropToUpload.svg';
import cn from 'classnames';
import { Text } from 'components/Typography';
import React, { ComponentType, HTMLAttributes, useCallback } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styles from './UploadDropzone.module.scss';

interface Props extends DropzoneProps {
  onUpload: (acceptedFiles: File[]) => void;
  onCancel: () => void;
}

export const UploadDropzone: ComponentType<
  Props & HTMLAttributes<HTMLDivElement>
> = ({ className, onUpload, onCancel, accept, ...restProps }) => {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles?.length) return;
      onUpload(acceptedFiles);
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
  });

  return (
    <div
      className={cn(className, styles.container, {
        [styles.dragActive]: isDragActive,
      })}
      {...getRootProps()}
      onDragLeave={onCancel}
      onClick={onCancel}
      {...restProps}
    >
      <input {...getInputProps()} />
      <div className={styles.innerContainer}>
        <img className="mb-1" src={DropToUpload} alt="drop_file" />
        <Text variant="heading-xl" className="m-0">
          {t('dropFilesToUpload')}
        </Text>
      </div>
    </div>
  );
};
