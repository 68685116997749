import React, { FC, HTMLAttributes, useContext } from 'react';
import cn from 'classnames';

import { Icon } from 'components/Icon';

import { DroplistContext } from './utils';
import { ListItem } from 'components/List';

export interface DropListItemProps extends HTMLAttributes<HTMLElement> {
  icon?: string;
  name?: string;
}

export const DropListItem: FC<DropListItemProps> = ({
  children,
  icon,
  className,
  ...props
}) => {
  const getListItemProps = useContext(DroplistContext);

  return (
    <ListItem
      className={cn(className, 'nowrap text-dark')}
      {...props}
      {...getListItemProps()}
    >
      {icon && <Icon icon={icon} gapRight />}
      {children}
    </ListItem>
  );
};
