import React, { ReactElement } from 'react';

import { OperationTimeoutQuestionType } from 'common-types/operation';
import { ListHeader, List, ListItem } from 'components/List';
import { DataTag } from 'components/DataTag';
import { NothingAddedPlaceholder } from 'components/NothingAddedPlaceholder';

interface Props {
  title: string;
  questions: OperationTimeoutQuestionType[];
}

export const QuestionsList = ({ title, questions }: Props): ReactElement => (
  <List data-questions-list="" data-timeout-questions-list="">
    <ListHeader>
      <strong className="text-dark">{title}</strong>
    </ListHeader>
    {Boolean(questions && questions.length) ? (
      questions.map((question) => (
        <ListItem key={question.id}>
          <DataTag propKey="content">{question.content}</DataTag>
        </ListItem>
      ))
    ) : (
      <ListItem>
        <NothingAddedPlaceholder />
      </ListItem>
    )}
  </List>
);
