import React, { FC } from 'react';
import cn from 'classnames';
import classes from './Checkbox.module.scss';

interface CheckboxProps {
  defaultChecked?: boolean;
  id: number | string;
  label?: string;
  onChange: (
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  className?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  id,
  onChange,
  defaultChecked = false,
  label = '',
  className,
  ...inputProps
}) => {
  return (
    <>
      <label className={classes.label} htmlFor={String(id)}>
        <input
          {...inputProps}
          className={cn(className, classes.input)}
          id={String(id)}
          defaultChecked={defaultChecked}
          onChange={(e) => onChange(e.target.checked, e)}
          type="checkbox"
        />
        <span className={classes.check}>
          <svg width="12px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1" />
          </svg>
        </span>
        <span className={classes.text}>{label}</span>
      </label>
    </>
  );
};
