import {
  DOCUMENT_SIZE_LIMIT,
  IMAGE_SIZE_LIMIT,
  VIDEO_SIZE_LIMIT,
} from 'app-constants/files';
import ImageType from 'assets/AttachmentsImages.svg';
import PdfType from 'assets/AttachmentsPdf.svg';
import VideoType from 'assets/Video.svg';
import { FileSizeLimits } from 'common-types/common';
import { Col, Row } from 'components/Grid';
import prettyBytes from 'pretty-bytes';
import React, { ComponentType, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './AllowedFilesInfo.module.scss';

interface Props {
  video?: boolean;
  images?: boolean;
  pdf?: boolean;
  fileSizeLimits?: FileSizeLimits;
}

export const AllowedFilesInfo: ComponentType<
  Props & HTMLAttributes<HTMLDivElement>
> = ({
  className,
  video = false,
  images = false,
  pdf = false,
  fileSizeLimits = {
    images: IMAGE_SIZE_LIMIT,
    pdf: DOCUMENT_SIZE_LIMIT,
    video: VIDEO_SIZE_LIMIT,
  },
}) => {
  const { t } = useTranslation();

  if (!video && !images && !pdf) return null;

  const {
    video: videoSizeLimit,
    images: imgSizeLimit,
    pdf: pdfSizeLimit,
  } = fileSizeLimits;

  return (
    <div className={className}>
      <Row>
        {images && (
          <Col auto>
            <Row alignItems="center">
              <Col auto>
                <img
                  className={classes.fileType}
                  src={ImageType}
                  alt="allowed_file_types"
                />
              </Col>
              <Col auto>
                <div>
                  <h4 className="text-dark mb-0">{t('imageFormats')}</h4>
                  {imgSizeLimit && (
                    <span className="text-light text-xs">
                      {t('maxFileSize', { size: prettyBytes(imgSizeLimit) })}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        )}

        {video && (
          <Col auto>
            <Row alignItems="center">
              <Col auto>
                <img
                  className={classes.fileType}
                  src={VideoType}
                  alt="allowed_file_types"
                />
              </Col>
              <Col auto>
                <div>
                  <h4 className="text-dark mb-0">{t('videoFormat')}</h4>
                  {videoSizeLimit && (
                    <span className="text-light text-xs">
                      {t('maxFileSize', {
                        size: prettyBytes(videoSizeLimit),
                      })}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        )}

        {pdf && (
          <Col auto>
            <Row alignItems="center">
              <Col auto>
                <img
                  className={classes.fileType}
                  src={PdfType}
                  alt="allowed_file_types"
                />
              </Col>
              <Col auto>
                <h4 className="text-dark mb-0">{t('PDFDocuments')}</h4>
                {pdfSizeLimit && (
                  <span className="text-light text-xs">
                    {t('maxFileSize', { size: prettyBytes(pdfSizeLimit) })}
                  </span>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

AllowedFilesInfo.displayName = 'AllowedFilesInfo';
