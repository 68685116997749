import { operationSchema, operationVetSchema } from 'api/v2/schemas';
import { isOperationFinished } from 'app-constants';
import { addSeconds } from 'date-fns';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {
  selectEntities,
  selectOperations,
  selectUsers,
} from 'store/modules/entities/selectors/selectors';
import { createStateGetter } from 'store/utils';
import { /*formatUserName,*/ parseDate } from 'utils';
import { selectUserId } from '../auth';
import { canUserEditOperation } from '../operation-planning';
import { isProductType } from 'utils/get-env';

export const selectScheduledOperationsIds = createStateGetter(
  'schedule.operations'
);

export const getPatientValues = (patient) => {
  if (!patient || patient.isFake) return null;
  // birthDate DEPRECATED
  const { sex, birthdate, patientId, givenName, surname } = patient;
  // const name = formatUserName(patient);

  return { sex, birthDate: birthdate, givenName, surname, patientId };
};

export const getVetPatientValues = (vetPatient) => {
  const { sex, caseNumber, species, breed, animalName } = vetPatient;

  return { sex, caseNumber, species, breed, animalName };
};

export const selectScheduledOperationsList = createSelector(
  [selectScheduledOperationsIds, selectOperations, selectEntities],
  (opIds, operations, entities) => {
    if (!opIds || !operations) return null;
    return opIds
      .map((id) =>
        denormalize(
          operations[id],
          isProductType('vet') ? operationVetSchema : operationSchema,
          entities
        )
      )
      .filter((op) => !op.isDeleted);
  }
);

export const selectScheduledEvents = createSelector(
  [selectScheduledOperationsList, selectUserId, selectUsers],
  (scheduledOperationsList, userId) => {
    if (!scheduledOperationsList) return null;
    return scheduledOperationsList.map((operation) => {
      const {
        diagnosesIds,
        id,
        operationTemplate,
        participants,
        patient,
        vetPatient,
        startsAt: start,
        status,
        title,
      } = operation;
      const isFinished = isOperationFinished(status);
      const readonly = !canUserEditOperation(operation, userId);

      const startsAt = parseDate(start);
      const totalEstimatedTime = operationTemplate?.totalEstimatedTime ?? null;
      const estimatedEndsAt = addSeconds(startsAt, totalEstimatedTime || 60);

      return {
        diagnosesIds: diagnosesIds || null,
        draggable: !isFinished && !readonly,
        endsAt: estimatedEndsAt,
        hasAssessment: Boolean(operation.assessment),
        haveNoSteps: !totalEstimatedTime,
        id,
        participants,
        patient: patient ? getPatientValues(patient) : null,
        vetPatient: vetPatient ? getVetPatientValues(vetPatient) : null,
        readonly,
        startsAt,
        status,
        title,
      };
    });
  }
);
