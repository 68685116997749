import i18next, { InitOptions } from 'i18next';
import LangDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import {
  FALLBACK_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from 'app-constants/translations';
import { Language } from 'common-types/common';

const i18nOptions: InitOptions = {
  // Base i18n configuration.
  // These settings affect i18next-scanner also, upon changing it don't forget
  // to update i18next-scanner.config.js as well.
  defaultNS: 'common',
  fallbackLng: FALLBACK_LANGUAGE,
  interpolation: { escapeValue: false, prefix: '%', suffix: '%' },
  supportedLngs: SUPPORTED_LANGUAGES,
  ns: [
    'common',
    'countries',
    'validation',
    'errors',
    'hint',
    'history',
    'settings',
  ],
  nsSeparator: ':',

  // i18n instance and its plugins config.
  detection: {
    lookupLocalStorage: 'i18nextLng',
    order: ['navigator', 'querystring'],
  },
  backend: {
    loadPath: '/locales/%lng%/%ns%.json',
  },
  react: {
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    nsMode: 'default',
    useSuspense: false,
  },
};

i18next.use(LangDetector).use(HttpApi).init(i18nOptions);

export const getCurrentLanguage = (): Language =>
  (i18next.language as Language) || FALLBACK_LANGUAGE;

export const i18n = i18next;
export default i18next;
