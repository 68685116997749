import { SuccessAction } from 'store/utils';
import { READ_ASSESSMENT_FORMS_SUCCESS } from '../../../modules/assessment-forms/types';

// FIXME: Remove once old assessment forms logic is replaced
// https://nodusmedical.atlassian.net/browse/NM-3803
export default (state = {}, action: SuccessAction) => {
  const { type } = action;
  switch (type) {
    // Skip this action because it is handled in a new reducer (./store/modules/assessment-forms/reducers.ts)
    case READ_ASSESSMENT_FORMS_SUCCESS: {
      return {};
    }

    default:
      return state;
  }
};
