import React, { FC, ReactNode } from 'react';
import { ElementRow } from './ElementRow';
import { ElementsSearchDialog } from './ElementsSearchDialog';
import { ListRow } from './ListRow';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Text } from 'components/Typography';
import {
  OperationStep,
  OperationStepEstimable,
  OperationStepInput,
} from 'common-types/library';
import { useTranslation } from 'react-i18next';

interface ElementsListEditableProps {
  elements?: OperationStepEstimable[];
  onChangeOrder?(fromIndex: number, toIndex: number): void;
  id?: string;
  activeSearchDialogIndex: number | null;
  renderSearchInput?(index: number): ReactNode;
  onDelete?(element: OperationStep): void;
  onSearchClose?(index: number): void;
  onSearchOpen?(index: number): void;
  onAddToLibrary?(step: OperationStep): void;
  onChange?(step: OperationStep, input: OperationStepInput): void;
  onAttachmentRequest?(uuid: string): void;
  onTriggerGallery?(id: number): void;
}

export const ElementsListEditable: FC<ElementsListEditableProps> = ({
  elements,
  onChangeOrder,
  id = 'dragId',
  activeSearchDialogIndex,
  renderSearchInput,
  onDelete,
  onSearchClose,
  onSearchOpen,
  onAddToLibrary,
  onChange,
  onAttachmentRequest,
  onTriggerGallery,
}) => {
  const { t } = useTranslation();
  const lastSearchDialogIndex = elements?.length ?? 0;

  const handleDragEnd = (event: any) => {
    const { destination, source } = event;
    const fromIndex = source?.index;
    const toIndex = destination?.index;
    if (
      !Number.isInteger(fromIndex) ||
      !Number.isInteger(toIndex) ||
      fromIndex === toIndex
    )
      return;

    onChangeOrder?.(fromIndex, toIndex);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {Boolean(elements && elements.length) && (
        <Droppable droppableId={id}>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {elements?.map((element, index) => (
                <ElementRow
                  key={element.uuid}
                  element={element}
                  index={index}
                  barColumn={
                    <ElementsSearchDialog
                      index={index}
                      onClose={onSearchClose}
                      onOpen={onSearchOpen}
                      open={activeSearchDialogIndex === index}
                    >
                      {renderSearchInput?.(index)}
                    </ElementsSearchDialog>
                  }
                  onAddToLibrary={onAddToLibrary}
                  onAttachmentRequest={onAttachmentRequest}
                  onChange={onChange}
                  onDelete={onDelete}
                  onTriggerGallery={onTriggerGallery}
                />
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      <ListRow
        className="align-center"
        barColumn={
          <ElementsSearchDialog
            index={lastSearchDialogIndex}
            onClose={onSearchClose}
            onOpen={onSearchOpen}
            open={activeSearchDialogIndex === lastSearchDialogIndex}
          >
            {renderSearchInput?.(lastSearchDialogIndex)}
          </ElementsSearchDialog>
        }
      >
        {Boolean(elements && !elements.length) && (
          <Text variant="title" className="m-0 text-brand">
            {t('startByAddingProceduresChecksRisks')}
          </Text>
        )}
      </ListRow>
    </DragDropContext>
  );
};
