import move from 'array-move';
import { get, uniqBy } from 'lodash';
import {
  ADD_OPERATION_ELEMENT,
  ADD_OPERATION_ELEMENT_SUCCESS,
  ADD_PATIENT_RISK,
  ASSIGN_ASSESSMENT,
  CHANGE_OPERATION_ELEMENTS_ORDER,
  CREATE_OPERATION_NOTE_SUCCESS,
  CREATE_OPERATION_VITAL_INFO_SUCCESS,
  CREATE_PATIENT_SUCCESS,
  DELETE_OPERATION_ELEMENT,
  DELETE_OPERATION_NOTE_REQUEST,
  READ_OPERATION_ATTACHMENTS_SUCCESS,
  READ_OPERATION_ELEMENTS_ATTACHMENTS_SUCCESS,
  READ_OPERATION_ELEMENTS_SUCCESS,
  READ_OPERATION_PATIENT_RISKS_SUCCESS,
  REMOVE_PATIENT_RISK,
  UNASSIGN_ASSESSMENT,
  UPDATE_OPERATION_ELEMENTS_SUCCESS,
} from 'store/modules/operation-planning';
import {
  CREATE_ASSESSMENT_SUCCESS,
  DELETE_ASSESSMENT_SUCCESS,
} from 'store/modules/assessments/thunks';
import { mergeState, withoutRelationId } from 'store/utils';
import { insert } from 'utils';
import { getResults } from '../utils/get-results';
import { patchState } from '../utils/patch-state';

export default (currentState = {}, action) => {
  const { type, payload } = action;
  let state = currentState;
  if (payload?.entities?.operations) {
    state = mergeState(state, payload.entities.operations);
  }

  switch (type) {
    case READ_OPERATION_ELEMENTS_SUCCESS:
    case ADD_OPERATION_ELEMENT_SUCCESS:
    case UPDATE_OPERATION_ELEMENTS_SUCCESS:
      const {
        requestId: operationId,
        payload: {
          entities: { attachments },
          result: elements,
        },
      } = action;
      const operation = state[operationId] || {};
      const elementsAttachmentsIds = attachments
        ? Object.keys(attachments).sort()
        : [];

      return {
        ...state,
        [operationId]: {
          ...operation,
          elementsAttachments: elementsAttachmentsIds,
          elements,
        },
      };

    case READ_OPERATION_PATIENT_RISKS_SUCCESS: {
      const { result } = payload;
      const operation = state[action.requestId];

      return {
        ...state,
        [action.requestId]: {
          ...operation,
          patientRisks: result,
        },
      };
    }

    case ADD_PATIENT_RISK: {
      const { operationId, uuid } = payload;
      const operation = get(state, operationId, { id: operationId });
      if (!operation) return state;
      const patientRisks = get(operation, 'patientRisks', []);

      return {
        ...state,
        [operationId]: {
          ...operation,
          patientRisks: uniqBy(patientRisks.concat(uuid), withoutRelationId),
        },
      };
    }

    case REMOVE_PATIENT_RISK: {
      const { operationId, uuid } = payload;
      const operation = get(state, operationId, { id: operationId });
      if (!operation) return state;
      const patientRisks = get(operation, 'patientRisks', []);

      return {
        ...state,
        [operationId]: {
          ...operation,
          patientRisks: patientRisks.filter(
            (riskUUID) =>
              withoutRelationId(riskUUID) !== withoutRelationId(uuid)
          ),
        },
      };
    }

    case CREATE_OPERATION_VITAL_INFO_SUCCESS: {
      const { info, result } = payload;
      const operation = state[info.operationId];
      return {
        ...state,
        [info.operationId]: {
          ...operation,
          vitalInformation: result,
        },
      };
    }

    case CHANGE_OPERATION_ELEMENTS_ORDER: {
      const { operationId: id, fromIndex, toIndex } = payload;
      const operation = get(state, id);
      const operationElements = get(operation, 'elements', []);

      return {
        ...state,
        [id]: {
          ...operation,
          elements: move(operationElements, fromIndex, toIndex),
        },
      };
    }

    case ADD_OPERATION_ELEMENT: {
      const { operationId, element, index } = payload;
      const operation = state[operationId];
      const { elements } = operation;
      return {
        ...state,
        [operationId]: {
          ...operation,
          elements: elements
            ? insert(elements, element.uuid, index)
            : [element.uuid],
        },
      };
    }

    case DELETE_OPERATION_ELEMENT: {
      const { operationId, uuid } = payload;
      const operation = state[operationId];
      const { elements } = operation;
      return {
        ...state,
        [operationId]: {
          ...operation,
          elements: elements.filter((elementUUID) => elementUUID !== uuid),
        },
      };
    }

    case READ_OPERATION_ATTACHMENTS_SUCCESS: {
      const {
        payload: {
          result: { attachments: operationAttachmentsIds },
        },
        requestId: operationId,
      } = action;
      const operation = state ? state[operationId] : {};

      return {
        ...state,
        [operationId]: {
          ...operation,
          attachments: operationAttachmentsIds,
        },
      };
    }

    case READ_OPERATION_ELEMENTS_ATTACHMENTS_SUCCESS: {
      const {
        payload: { result: elementsAttachments },
        requestId: operationId,
      } = action;
      const operation = state ? state[operationId] : {};

      return {
        ...state,
        [operationId]: {
          ...operation,
          elementsAttachments,
        },
      };
    }

    case CREATE_PATIENT_SUCCESS: {
      const { result: patientId, entities, operationId } = action.payload;
      const patient = entities.patients[patientId];
      const operation = state[operationId];

      if (!operation) return state;

      return {
        ...state,
        [operation.id]: {
          ...operation,
          patientId: patient.id,
        },
      };
    }

    case ASSIGN_ASSESSMENT: {
      const { operationId, id } = payload;
      return patchState(state, operationId, { assessment: id });
    }

    case UNASSIGN_ASSESSMENT: {
      const { operationId } = payload;
      return patchState(state, operationId, { assessment: null });
    }

    case CREATE_ASSESSMENT_SUCCESS: {
      const assessment = getResults(payload, 'assessments');
      if (!assessment?.operationId) return state;
      const operation = state[assessment.operationId];

      const newState = patchState(state, assessment.operationId, {
        assessments: [...operation.assessments, assessment.id],
      });

      return newState;
    }

    case DELETE_ASSESSMENT_SUCCESS: {
      const { requestId: deletedAssessmentId } = action;
      if (!deletedAssessmentId) return state;
      const newState = Object.values(state).reduce((resultState, operation) => {
        if (
          !operation.assessments ||
          !operation.assessments.includes(deletedAssessmentId)
        )
          return resultState;
        return patchState(resultState, operation.id, {
          ...operation,
          assessments: operation.assessments.filter(
            (assessmentId) => assessmentId !== deletedAssessmentId
          ),
        });
      }, state);
      return newState;
    }

    case DELETE_OPERATION_NOTE_REQUEST: {
      const { operationId } = payload;
      const operation = state[operationId];
      if (!operation) return state;
      return { ...state, [operationId]: { ...operation, notes: null } };
    }

    case CREATE_OPERATION_NOTE_SUCCESS: {
      const {
        requestId: operationId,
        payload: { note: notes },
      } = action;
      const operation = state[operationId];
      if (!operation) return state;
      return { ...state, [operationId]: { ...operation, notes } };
    }

    default:
      return state;
  }
};
