import React, { ComponentPropsWithoutRef, FC, memo } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

interface PageNumberButtonProps extends ComponentPropsWithoutRef<'button'> {
  /**
   * Page number
   */
  page: number;
  /**
   * Flag to change style
   */
  active?: boolean;
}

/**
 * Renders button component with page number
 */
export const PageNumberButton: FC<PageNumberButtonProps> = memo(
  function PageNumberButton({ page, active = false, ...props }) {
    return (
      <button
        type="button"
        className={cn(styles.pageButton, active && styles.active)}
        {...props}
      >
        {page}
      </button>
    );
  }
);
