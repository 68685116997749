import { READ_TEMPLATE_CATEGORIES_SUCCESS } from './types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case READ_TEMPLATE_CATEGORIES_SUCCESS:
      return [...new Set([...state, ...(action.payload?.result ?? [])])];
    default:
      return state;
  }
};
