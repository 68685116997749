import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Typography';

export const NothingAddedPlaceholder: FC = () => {
  const { t } = useTranslation();

  return (
    <Text component="span" className="text-placeholder">
      {t('nothingAddedYet')}
    </Text>
  );
};
