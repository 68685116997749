import { isObject, pick } from 'lodash';
import { LibraryCategories } from '../common-types/library';

export const LIBRARY_CATEGORIES_LIST = Object.keys(LibraryCategories);

export const MATERIALS_CATEGORIES = [
  LibraryCategories.equipment,
  LibraryCategories.instruments,
  LibraryCategories.implants,
];

export const COUNTABLE_MATERIALS_CATEGORIES = [
  LibraryCategories.equipment,
  LibraryCategories.instruments,
];

export const isCountable = (category) =>
  COUNTABLE_MATERIALS_CATEGORIES.includes(category);

export const TEMPLATE_MATERIALS_CATEGORIES = MATERIALS_CATEGORIES;

export const OPERATION_MATERIALS_CATEGORIES = MATERIALS_CATEGORIES;

export const isMaterialsCategory = (category) =>
  OPERATION_MATERIALS_CATEGORIES.includes(category);

export const STEPS_CATEGORIES = [
  LibraryCategories.procedures,
  LibraryCategories.checks,
];

export const ELEMENTS_CATEGORIES = [
  LibraryCategories.procedures,
  LibraryCategories.checks,
  LibraryCategories.risks,
];

export const TEMPLATE_ELEMENTS_CATEGORIES = ELEMENTS_CATEGORIES;
export const OPERATION_ELEMENTS_CATEGORIES = ELEMENTS_CATEGORIES;

export const isTemplateElementsCategory = (category) =>
  TEMPLATE_ELEMENTS_CATEGORIES.includes(category);

export const isStepCategory = (category) => STEPS_CATEGORIES.includes(category);

export const pickElementValues = (element, category) => {
  if (!isObject(element)) {
    throw new Error(
      `Invalid element argument were provided. Expected object, got "${typeof element}"`
    );
  } else if (!category) {
    throw new Error(
      `The element's category isn't provided, cannot decide which props to pick.`
    );
  }

  switch (category) {
    case LibraryCategories.checks:
    case LibraryCategories.procedures:
      return pick(element, ['name', 'description', 'estimatedTime']);

    case LibraryCategories.risks:
    case LibraryCategories.patientRisks:
      return pick(element, ['name', 'mitigationAction']);

    case LibraryCategories.equipment:
    case LibraryCategories.implants:
    case LibraryCategories.instruments:
      return pick(element, ['name', 'company', 'size', 'serialNumber']);

    default:
      return element;
  }
};

export const LIBRARY_ITEM_TRANSLATABLE_FIELDS = [
  'description',
  'mitigationAction',
  'name',
];
