import {
  materialsGroupSchema,
  templateMaterialsSchema,
  operationTemplateSchema,
} from 'api/v1/schemas';
import { TEMPLATE_MATERIALS_CATEGORIES } from 'app-constants';
import memoize from 'lodash/memoize';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { hasDepartment, selectUserId, selectUser } from 'store/modules/auth';
import {
  selectAttachments,
  selectDebriefingQuestions,
  selectEntities,
  selectLibraryElements,
  selectTemplateMaterials,
  selectMaterialsGroups,
  selectTemplates as selectTemplatesEntities,
  selectTimeoutQuestions,
} from 'store/modules/entities/selectors/selectors';
import {
  createCollectionSelector,
  createMatchParamsGetter,
  createPropsGetter,
  createStateGetter,
  getActiveItemsIds,
  getNonStandardQuestions,
  getStandardQuestions,
  mapElementsSteps,
  createDenormalizr,
} from 'store/utils';
import { createElementsAttachmentsSelector } from '../operation-planning/selectors/attachments';
import { accumulate } from 'utils';

export const selectTemplatesLibrary = createStateGetter('templatesLibrary');

export const selectTemplates = createSelector(
  selectTemplatesLibrary,
  createStateGetter('templatesList')
);

export const canPlanFromTemplate = (template) =>
  Boolean(template?.totalEstimatedTime);

export const canEditTemplate = (template, userId) => {
  return Boolean(template && userId && template.createdBy === userId);
};

export const createTemplateByIdSelector = (templateId) =>
  createSelector(selectTemplatesEntities, createStateGetter(templateId, null));

export const createIsOwnTemplateSelector = (templateId) => {
  const selectTemplate = createTemplateByIdSelector(templateId);
  return createSelector([selectTemplate, selectUserId], (template, userId) =>
    Boolean(userId && template?.createdBy === userId)
  );
};

export const selectTemplatesLibraryList = createSelector(
  [selectTemplates, selectUserId],
  (list, userId) =>
    list &&
    list.map((template) => ({
      ...template,
      canEdit: template.createdBy === userId,
      canPlan: canPlanFromTemplate(template),
    }))
);

export const selectTemplatesLibraryTotal = createStateGetter(
  'templatesLibrary.templatesTotal'
);

export const selectTemplatesSearchQuery = createSelector(
  selectTemplatesLibrary,
  createStateGetter('search')
);

export const selectTemplateIdParam = createMatchParamsGetter('templateId');
export const selectTemplateIdProp = createPropsGetter('templateId');

export const selectCurrentTemplate = createSelector(
  [selectTemplateIdParam, selectTemplateIdProp, selectTemplatesEntities],
  (idParam, idProp, templates) =>
    (templates && templates[idParam || idProp]) || null
);

export const selectTemplateTitle = createSelector(
  selectCurrentTemplate,
  createStateGetter('name')
);

export const selectTemplateCategoryId = createSelector(
  selectCurrentTemplate,
  createStateGetter('categoryId')
);

export const selectTemplateAttachmentsIds = createSelector(
  selectCurrentTemplate,
  createStateGetter('attachments')
);

export const selectTemplateAttachments = createSelector(
  [selectCurrentTemplate, selectAttachments],
  (operationTemplate, attachments) => {
    const attachmentsIds = get(operationTemplate, 'attachments');

    if (attachmentsIds) {
      return attachmentsIds.map((id) => attachments[id]);
    }

    return [];
  }
);

export const selectTemplateElementsUUIDs = memoize((templateId) =>
  createSelector(selectTemplatesEntities, (templates) => {
    const template = templates?.[templateId];
    const elements = template?.elements ?? template?.procedureElements ?? null;
    return elements;
  })
);

export const selectTemplateElements = createSelector(
  [selectCurrentTemplate, selectLibraryElements],
  createCollectionSelector(['procedureElements', 'elements'])
);

export const selectTemplateElementsById = memoize((templateId) =>
  createSelector(
    [selectLibraryElements, selectTemplateElementsUUIDs(templateId)],
    (libraryElements, elementsUUIDs) => {
      if (!libraryElements || !elementsUUIDs) return null;
      return elementsUUIDs?.map((uuid) => libraryElements[uuid]);
    }
  )
);

export const selectTemplateTotalEstimatedTime = memoize((templateId) =>
  createSelector(selectTemplateElementsById(templateId), (elements) =>
    elements ? accumulate(elements, 'estimatedTime') : 0
  )
);

export const selectLibraryItemByUUID = memoize((uuid) =>
  createSelector(selectLibraryElements, (elements) => {
    const element = elements[uuid];
    return element;
  })
);

export const selectCurrentTemplateElements = createSelector(
  [selectTemplateElements],
  mapElementsSteps
);

export const selectTemplateElementsAttachments =
  createElementsAttachmentsSelector(selectCurrentTemplateElements);

export const selectUniqueTemplateElementsAttachments = createSelector(
  selectTemplateElementsAttachments,
  (elements) => uniqBy(elements, 'id')
);

export const selectCurrentTemplateTimeoutQuestions = createSelector(
  [selectCurrentTemplate, selectTimeoutQuestions],
  createCollectionSelector('timeoutQuestions')
);

export const createDenormalizedTemplateSelector = (templateId) => {
  const selectTemplate = createTemplateByIdSelector(templateId);
  return createSelector(
    [selectTemplate, selectEntities],
    createDenormalizr(operationTemplateSchema)
  );
};

export const createTemplateTimeoutQuestionsSelector = (templateId) => {
  const selectTemplate = createDenormalizedTemplateSelector(templateId);
  return createSelector(
    [selectTemplate],
    createStateGetter('timeoutQuestions')
  );
};

export const createTemplateDebriefingQuestionsSelector = (templateId) => {
  const selectTemplate = createDenormalizedTemplateSelector(templateId);
  return createSelector(
    [selectTemplate],
    createStateGetter('debriefingQuestions')
  );
};

export const selectCurrentTemplateDebriefingQuestions = createSelector(
  [selectCurrentTemplate, selectDebriefingQuestions],
  createCollectionSelector('debriefingQuestions')
);

export const selectStandardTimeoutQuestions = createSelector(
  selectCurrentTemplateTimeoutQuestions,
  getStandardQuestions
);

export const selectAdditionalTimeoutQuestions = createSelector(
  selectCurrentTemplateTimeoutQuestions,
  getNonStandardQuestions
);

export const selectTimeoutActiveStandardQuestionsIds = createSelector(
  selectCurrentTemplateTimeoutQuestions,
  getActiveItemsIds
);

export const selectStandardDebriefingQuestions = createSelector(
  selectCurrentTemplateDebriefingQuestions,
  getStandardQuestions
);

export const selectAdditionalDebriefingQuestions = createSelector(
  selectCurrentTemplateDebriefingQuestions,
  getNonStandardQuestions
);

export const selectDebriefingActiveStandardQuestionsIds = createSelector(
  selectCurrentTemplateDebriefingQuestions,
  getActiveItemsIds
);

export const selectCurrentTemplateNotes = createSelector(
  selectCurrentTemplate,
  createStateGetter('notes')
);

export const selectCurrentTemplateAuthorId = createSelector(
  selectCurrentTemplate,
  createStateGetter('createdBy')
);

export const isOwnTemplate = createSelector(
  [selectUserId, selectCurrentTemplateAuthorId],
  (userId, templateAuthorId) =>
    Boolean(userId && templateAuthorId && userId === templateAuthorId)
);

export const selectTemplateMaterialsById = createSelector(
  [createPropsGetter('templateId'), selectTemplateMaterials],
  (templateId, materials) => {
    if (!materials) return null;
    const templateMaterials = materials[templateId];
    if (!templateMaterials) return null;

    return pick(templateMaterials, TEMPLATE_MATERIALS_CATEGORIES);
  }
);

export const selectTemplateMaterialItemsUUIDs = createSelector(
  [selectTemplateMaterialsById, selectLibraryElements],
  (materials) => {
    if (!materials) return [];
    return TEMPLATE_MATERIALS_CATEGORIES.reduce(
      (result, category) =>
        materials[category] ? [...result, ...materials[category]] : result,
      []
    );
  }
);

export const shouldShowDepartmentQuestionsMessage = createSelector(
  [isOwnTemplate, hasDepartment, selectStandardTimeoutQuestions],
  (isOwn, hasDepartment, questions) =>
    Boolean((!questions || !questions.length) && isOwn && !hasDepartment)
);

export const createNormalizedTemplateMaterialsSelector = (templateId) =>
  createSelector(
    selectTemplateMaterials,
    (materials) => materials?.[templateId] ?? null
  );

export const createTemplateMaterialsSelector = (templateId) => {
  const normalizedMaterialsSelector =
    createNormalizedTemplateMaterialsSelector(templateId);

  return createSelector(
    [normalizedMaterialsSelector, selectEntities],
    (templateMaterials, entities) => {
      if (!templateMaterials || !entities) return null;
      return omit(
        denormalize(templateMaterials, templateMaterialsSchema, entities),
        ['templateId', 'materialsGroups']
      );
    }
  );
};

export const createTemplateMaterialsGroupsIdsSelector = (templateId) => {
  const selectTemplateMaterials =
    createNormalizedTemplateMaterialsSelector(templateId);
  return createSelector(
    selectTemplateMaterials,
    createStateGetter('materialsGroups', [])
  );
};

export const createTemplateMaterialsGroupsSelector = (templateId) => {
  const groupsIdsSelector =
    createTemplateMaterialsGroupsIdsSelector(templateId);

  return createSelector(
    [groupsIdsSelector, selectEntities],
    (groupsIds, entities) =>
      denormalize(groupsIds, [materialsGroupSchema], entities)
  );
};

export const createMaterialsGroupsAttachmentsSelector = (templateId) => {
  const groupsIdsSelector =
    createTemplateMaterialsGroupsIdsSelector(templateId);

  return createSelector(
    [groupsIdsSelector, selectMaterialsGroups, selectAttachments],
    (groupsIds, groups, attachments) => {
      if (!groupsIds && !groups) return null;
      const attachmentsIds = groupsIds.reduce((result, groupId) => {
        const attachmentsIds = groups?.[groupId]?.attachments ?? [];
        return result.concat(attachmentsIds);
      }, []);

      const selectedAttachments = uniq(attachmentsIds).map(
        (id) => attachments[id]
      );
      return selectedAttachments;
    }
  );
};

export const canPlanFromTemplateSelector = (templateId) =>
  createSelector(createTemplateByIdSelector(templateId), (template) =>
    canPlanFromTemplate(template)
  );

export const canEditTemplateSelector = (templateId) =>
  createSelector(
    createTemplateByIdSelector(templateId),
    selectUser,
    (template, user) => canEditTemplate(template, user?.id)
  );
