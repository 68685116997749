import React, { FC, HTMLAttributes, ReactNode } from 'react';
import classes from './List.module.scss';
import { ListItemBase, ListItemBaseProps } from './ListItemBase';
import cn from 'classnames';
import { IconButton } from 'components/Button';

export interface ListItemProps {
  addon?: ReactNode;
  itemId?: string | number;
  onDelete?(id?: string | number): void;
  onEdit?(id?: string | number): null;
  contentGrow?: boolean;
  contentClassName?: string;
  addonVisible?: boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
  inlineChildren?: boolean;
  isDragging?: boolean;
  hoverable?: boolean;
}

export const ListItem: FC<
  ListItemProps & HTMLAttributes<HTMLElement> & Partial<ListItemBaseProps>
> = ({
  className,
  isDragging,
  addonVisible,
  inlineChildren,
  contentClassName,
  contentGrow = false,
  children,
  onDelete,
  onEdit,
  itemId,
  addon = null,

  ...restProps
}) => {
  const handleEdit = () => {
    onEdit?.(itemId);
  };

  const handleDelete = () => {
    onDelete?.(itemId);
  };

  return (
    <ListItemBase
      className={className}
      isDragging={isDragging}
      addonOnHover={!addonVisible}
      inlineChildren={inlineChildren}
      {...restProps}
    >
      <div
        className={cn(contentClassName, {
          [classes.inlineChildren]: inlineChildren,
          [classes.contentGrow]: contentGrow,
        })}
      >
        {children}
      </div>
      {!isDragging && (
        <div className={classes.iconRight}>
          {onEdit && (
            <IconButton icon="edit" className="mr-1" onClick={handleEdit} />
          )}
          {onDelete && <IconButton icon="delete" onClick={handleDelete} />}
          {addon && <span className={classes.addon}>{addon}</span>}
        </div>
      )}
    </ListItemBase>
  );
};
