import classnames from 'classnames';
import React, { FC } from 'react';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import classes from './FormInputBase.module.scss';
import { FormInputBody } from '../FormInputBody/FormInputBody';
import { Col, Row } from 'components/Grid';
import { FormInputBaseProps } from 'common-types/common';
import { Label } from '../Label';

export const FormInputBase: FC<FormInputBaseProps> = ({
  icon,
  className,
  error = null,
  formInputType = 'input',
  id = '',
  inputAddon = null,
  label = '',
  children,
  ...inputProps
}) => {
  const errorMessage = () => {
    let errorMessage = error;
    if (Array.isArray(error)) {
      errorMessage = error.join(', ');
    }

    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  };

  const getInputProps = (inputClassName, inputClassNames) => {
    return {
      id,
      className: classnames(inputClassName, classes.input, {
        [classes[formInputType]]: formInputType,
        [`${classes.hasError} hasError`]: error,
        ...inputClassNames,
      }),
      error,
      ...inputProps,
    };
  };

  return (
    <FormInputBody className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Row alignItems="center">
        <Col>
          {children && children({ getProps: getInputProps })} {icon}
        </Col>
        {!!inputAddon ? <Col auto>{inputAddon}</Col> : null}
      </Row>
      {error && errorMessage()}
    </FormInputBody>
  );
};
