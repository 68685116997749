import namespace from './namespace';

export const UPLOAD_FILE_REQUEST = `${namespace}UPLOAD_FILE_REQUEST`;
export const UPLOAD_FILE_PROGRESS = `${namespace}UPLOAD_FILE_PROGRESS`;
export const CANCEL_FILE_UPLOAD = `${namespace}CANCEL_FILE_UPLOAD`;
export const UPLOAD_FILE_SUCCESS = `${namespace}UPLOAD_FILE_SUCCESS`;
export const RETRY_FILE_UPLOAD = `${namespace}RETRY_FILE_UPLOAD`;
export const UPLOAD_FILE_FAILURE = `${namespace}UPLOAD_FILE_FAILURE`;

export const RESET_FILES_UPLOAD_PROGRESS = `${namespace}RESET_FILES_UPLOAD_PROGRESS`;

export const UPLOAD_ATTACHMENTS_REQUEST = `${namespace}UPLOAD_ATTACHMENTS_REQUEST`;
export const UPLOAD_ATTACHMENTS_SUCCESS = `${namespace}UPLOAD_ATTACHMENTS_SUCCESS`;
export const UPLOAD_ATTACHMENTS_FAILURE = `${namespace}UPLOAD_ATTACHMENTS_FAILURE`;
