import React, { FC, HTMLAttributes, memo } from 'react';
import cn from 'classnames';

import { Icon } from 'components/Icon';
import styles from './CategoryBadge.module.scss';

import {
  risksCategoriesSet,
  warningsCategoriesSet,
  categoryIconsMap,
  categoryLabelsMap,
} from './utils';
import { useTranslation } from 'react-i18next';
import { LibraryCategories } from 'common-types/library';

interface CategoryBadgeProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Badge category.
   */
  category: LibraryCategories;
}

/**
 * Renders badge with label and icon which depends on passed category.
 */
export const CategoryBadge: FC<CategoryBadgeProps> = memo(
  function CategoryBadge({ category, className, ...props }) {
    const isRisk = risksCategoriesSet.has(category);
    const isWarning = warningsCategoriesSet.has(category);

    const { t } = useTranslation();

    const label = categoryLabelsMap[category];
    const localizedLabel = label ? t(label) : null;

    const icon = categoryIconsMap[category];

    return !category || !localizedLabel ? null : (
      <div
        className={cn(
          styles.categoryBadge,
          isRisk && styles.risk,
          isWarning && styles.warning,
          className
        )}
        {...props}
      >
        {icon && <Icon name={icon} className={styles.icon} />}
        <span>{localizedLabel}</span>
      </div>
    );
  }
);
