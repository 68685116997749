import React, { FC, memo } from 'react';
import cn from 'classnames';
import classes from './Avatar.module.scss';

export interface AvatarProps {
  /**
   * User's given name
   */
  givenName: string;
  /**
   * User's last name
   */
  surname: string;
  /**
   * Url to image to render inside avatar. If missing then content will contain user initials.
   */
  imageUrl?: string | null;
  /**
   * Class applied to root element
   */
  className?: string;
  /**
   * Inverted order of name - surname + given name
   */
  isSurnameFirst?: boolean;
}

/**
 * Renders user avatar from provided image url or from user initials
 */
export const Avatar: FC<AvatarProps> = memo(function Avatar({
  imageUrl,
  className,
  givenName,
  surname,
  isSurnameFirst,
}) {
  const initialsLetters = isSurnameFirst
    ? [surname?.charAt(0), givenName?.charAt(0)]
    : [givenName?.charAt(0), surname?.charAt(0)];
  const initials = initialsLetters.filter(Boolean).join('');

  if (!imageUrl) {
    return (
      <div className={cn(classes.avatarMock, className)}>
        {initials?.toUpperCase()}
      </div>
    );
  }

  return (
    <img
      className={cn(classes.defaultAvatar, className)}
      src={imageUrl}
      alt="avatar"
    />
  );
});
