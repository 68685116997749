import { AllowedMimeType } from 'app-constants/files';
import cn from 'classnames';
import { Attachment as AttachmentT } from 'common-types/attachments';
import { Col, Row } from 'components/Grid';
import React, { ComponentType } from 'react';
import { AttachmentPic } from '../AttachmentPic/AttachmentPic';
import classes from './AttachmentItem.module.scss';

interface Props {
  id: AttachmentT['id'];
  description?: AttachmentT['description'];
  fileUrl: AttachmentT['fileUrl'];
  name: AttachmentT['name'];
  mimeType: AllowedMimeType;

  className?: string;
  onPictureClick?: (id: AttachmentT['id']) => void;
}

export const AttachmentItem: ComponentType<Props> = (props) => {
  const {
    id,
    className,
    description = '',
    fileUrl = '',
    name,
    mimeType,
    onPictureClick,
  } = props;

  const handlePicClick = () => {
    if (!onPictureClick) return;
    onPictureClick(id);
  };

  return (
    <>
      <Row
        className={cn(classes.container, 'm-0 pt-05 pb-05', className)}
        nowrap
        alignItems="center"
      >
        <Col auto>
          <AttachmentPic
            className={cn({ pointer: onPictureClick })}
            description={description}
            fileUrl={fileUrl}
            mimeType={mimeType}
            name={name}
            onClick={handlePicClick}
          />
        </Col>

        <Col className={classes.name}>{name}</Col>
      </Row>
    </>
  );
};

AttachmentItem.displayName = 'AttachmentItem';
