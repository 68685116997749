import React, { FC, ReactNode, useEffect } from 'react';
import cn from 'classnames';

import { SELECT_MODES, SELECT_MODES_TYPE } from '../constants';
import { SelectOption } from '../SelectOption/SelectOption';
import classes from '../Select.module.scss';
import { Option } from 'common-types/common';
import { useTranslation } from 'react-i18next';
import { GetItemPropsOptions } from 'downshift';

interface SelectMenu {
  emptyMenuLabel?: string;
  error?: boolean;
  getItemProps?: (options: GetItemPropsOptions<any>) => any;
  getMenuProps: (...args) => any;
  highlightedIndex?: number | null;
  inputValue?: string | null;
  isLoading?: boolean;
  isOpen?: boolean;
  mode?: SELECT_MODES_TYPE;
  options?: Option[];
  renderMenuAppend?: () => void;
  renderMenuPrepend?: () => void;
  renderOption?: (...args) => ReactNode;
  selectedItem?: Option;
  required?: boolean;
  itemToString?: (...args) => string;
  updatePopper?: () => void;
  placement?: any;
}

export const SelectMenu: FC<SelectMenu> = ({
  itemToString,
  mode,
  renderOption,
  emptyMenuLabel = null,
  error = false,
  getItemProps,
  getMenuProps,
  highlightedIndex = null,
  inputValue = '',
  isLoading = false,
  isOpen = false,
  options: optionsProp = [],
  renderMenuAppend: renderMenuAppendProp = null,
  renderMenuPrepend: renderMenuPrependProp = null,
  required = false,
  selectedItem = null,
  updatePopper,
  placement,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (updatePopper) updatePopper();
  }, [optionsProp?.length]);

  const optionsList = () => {
    let list = optionsProp ? [...optionsProp] : [];

    if (mode === SELECT_MODES.select) {
      if (!required) {
        list = [{ value: '', label: t('None') }, ...list];
      }

      if (inputValue && optionsProp) {
        list = list.filter(
          (option) =>
            !inputValue ||
            (itemToString?.(option) || '')
              .toLowerCase()
              .includes(inputValue.trim().toLowerCase())
        );
      }
    }

    return list;
  };

  const renderAdditionalMenuItem = (renderer, className) => {
    if (!renderer) return null;
    const MenuItem = renderer();
    if (!MenuItem) return null;
    return <div className={cn(classes.option, className)}>{MenuItem}</div>;
  };

  const renderMenuPrepend = () => {
    return renderAdditionalMenuItem(
      renderMenuPrependProp,
      classes.prependOption
    );
  };

  const renderMenuAppend = () => {
    return renderAdditionalMenuItem(renderMenuAppendProp, classes.appendOption);
  };

  const options = optionsList();
  const hasOptions = Boolean(options && options.length);
  const menuAppend = renderMenuAppend();
  const menuPrepend = renderMenuPrepend();
  const nothingToRender = !hasOptions && !menuPrepend;
  const menuHidden = !isOpen || nothingToRender;

  const menuProps = getMenuProps({
    className: cn('Select__menu', classes.menu, {
      [`${classes.menuOpen} Select__menu--open`]: !menuHidden,
      [classes.menuError]: !!error,
      [classes.menuHidden]: menuHidden,
      [classes.placementTop]: placement?.includes('top'),
      [classes.placementBottom]: placement?.includes('bottom'),
    }),
  });

  return (
    <div {...menuProps}>
      <div className={classes.innerMenu}>
        {menuPrepend}
        {hasOptions &&
          options.map((option, index) => (
            <SelectOption
              key={[index, option.value, option.label].join()}
              getItemProps={getItemProps!}
              index={index}
              isHighlighted={index === highlightedIndex}
              isSelected={option.isSelected}
              isCurrent={selectedItem === option}
              option={option}
              renderInner={renderOption!}
              disabled={option.disabled}
            />
          ))}
        {!!(!hasOptions && emptyMenuLabel) && (
          <div className={classes.option}>
            {isLoading
              ? `${t('Loading')}...`
              : emptyMenuLabel || t('noOptions')}
          </div>
        )}
      </div>
      {menuAppend}
    </div>
  );
};
