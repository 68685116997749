export const HOME = process.env.REACT_APP_HOMEPAGE ?? '/dashboard';

export const ROOT_CRASHED = '/root-error' as const;
export const APP_CRASHED = '/error' as const;
export const DASHBOARD = '/dashboard' as const;

/* Params Helpers */

export const WITH_PARAMS = (params) => `?${params}`;

/* Operation planning */
export const OPERATION_PAGES = `${DASHBOARD}/operation` as const;

export const OPERATION_PLANNING = `${OPERATION_PAGES}/:operationId` as const;
export const NEW_OPERATION = `${OPERATION_PAGES}/create` as const;

export const OPERATION_PATH = '/operation-info' as const;
export const PATIENT_PATH = '/patient' as const;
export const PATIENT_RISKS_PATH = '/patient-risks' as const;
export const MATERIALS_PATH = '/materials' as const;
export const TABLES_PATH = '/tables' as const;
export const EQUIPMENT_PATH = '/equipment' as const;
export const ATTACHMENTS_PATH = '/attachments' as const;

export const BRIEFING_PATH = '/briefing' as const;
export const BRIEFING_OPERATION_PATH =
  `${BRIEFING_PATH}${OPERATION_PATH}` as const;
export const BRIEFING_PATIENT_PATH = `${BRIEFING_PATH}${PATIENT_PATH}` as const;
export const BRIEFING_PATIENT_RISKS_PATH =
  `${BRIEFING_PATH}${PATIENT_RISKS_PATH}` as const;
export const BRIEFING_MATERIALS_PATH =
  `${BRIEFING_PATH}${MATERIALS_PATH}` as const;
export const BRIEFING_TABLES_PATH = `${BRIEFING_PATH}${TABLES_PATH}` as const;
export const BRIEFING_EQUIPMENT_PATH =
  `${BRIEFING_PATH}${EQUIPMENT_PATH}` as const;
export const BRIEFING_ATTACHMENTS_PATH =
  `${BRIEFING_PATH}${ATTACHMENTS_PATH}` as const;
export const TIMEOUT_PATH = '/time-out' as const;
export const OPERATION_STEPS_PATH = '/operation-steps' as const;
export const DEBRIEFING_PATH = '/debriefing' as const;
export const RECAP_PATH = '/recap' as const;

// Pages
// Briefing sub-tabs
export const BRIEFING_OPERATION =
  `${OPERATION_PLANNING}${BRIEFING_OPERATION_PATH}` as const;
export const BRIEFING_PATIENT =
  `${OPERATION_PLANNING}${BRIEFING_PATIENT_PATH}` as const;
export const BRIEFING_PATIENT_RISKS =
  `${OPERATION_PLANNING}${BRIEFING_PATIENT_RISKS_PATH}` as const;
export const BRIEFING_MATERIALS =
  `${OPERATION_PLANNING}${BRIEFING_MATERIALS_PATH}` as const;
export const BRIEFING_TABLES =
  `${OPERATION_PLANNING}${BRIEFING_TABLES_PATH}` as const;
export const BRIEFING_EQUIPMENT =
  `${OPERATION_PLANNING}${BRIEFING_EQUIPMENT_PATH}` as const;
export const BRIEFING_ATTACHMENTS =
  `${OPERATION_PLANNING}${BRIEFING_ATTACHMENTS_PATH}` as const;

export const OPERATION_BRIEFING =
  `${OPERATION_PLANNING}${BRIEFING_PATH}` as const;
export const OPERATION_STEPS =
  `${OPERATION_PLANNING}${OPERATION_STEPS_PATH}` as const;
export const OPERATION_TIMEOUT =
  `${OPERATION_PLANNING}${TIMEOUT_PATH}` as const;
export const OPERATION_DEBRIEFING =
  `${OPERATION_PLANNING}${DEBRIEFING_PATH}` as const;
export const OPERATION_RECAP = `${OPERATION_PLANNING}${RECAP_PATH}` as const;

/* History */
export const HISTORY = '/history';

export const HISTORY_OPERATIONS = `${HISTORY}/operations` as const;
export const HISTORY_OPERATION = `${HISTORY_OPERATIONS}/:operationId` as const;

export const BRIEFING_HISTORY = `${HISTORY_OPERATION}${BRIEFING_PATH}` as const;
export const BRIEFING_OPERATION_HISTORY =
  `${BRIEFING_HISTORY}${OPERATION_PATH}` as const;
export const BRIEFING_PATIENT_HISTORY =
  `${BRIEFING_HISTORY}${PATIENT_PATH}` as const;
export const BRIEFING_RISKS_HISTORY =
  `${BRIEFING_HISTORY}${PATIENT_RISKS_PATH}` as const;
export const BRIEFING_MATERIALS_HISTORY =
  `${BRIEFING_HISTORY}${MATERIALS_PATH}` as const;
export const BRIEFING_TABLES_HISTORY =
  `${BRIEFING_HISTORY}${TABLES_PATH}` as const;
export const BRIEFING_EQUIPMENT_HISTORY =
  `${BRIEFING_HISTORY}${EQUIPMENT_PATH}` as const;

export const TIMEOUT_HISTORY = `${HISTORY_OPERATION}/time-out` as const;
export const OPERATION_HISTORY = `${HISTORY_OPERATION}/operation` as const;
export const DEBRIEFING_HISTORY = `${HISTORY_OPERATION}/debriefing` as const;
export const OPERATION_REPORT = `${HISTORY_OPERATION}/report` as const;
export const OPERATION_WPA_LIST = `${HISTORY_OPERATION}/wpa` as const;

/* Assessments  */
export const ASSESSMENTS = `/assessments` as const;
export const EXECUTE_ASSESSMENT =
  `${ASSESSMENTS}/execute/:assessmentId` as const;
export const PREVIEW_ASSESSMENT =
  `${ASSESSMENTS}/preview/:assessmentId` as const;
export const EDIT_ASSESSMENT = `${ASSESSMENTS}/edit/:assessmentId` as const;
export const VERIFY_ASSESSMENT = `${ASSESSMENTS}/verify/:assessmentId` as const;

/* Assessments form management */
export const ASSESSMENT_FORMS = `/assessment-forms` as const;
export const CREATE_ASSESSMENT_FORM = `${ASSESSMENT_FORMS}/create` as const;
export const PREVIEW_ASSESSMENT_FORM =
  `${ASSESSMENT_FORMS}/preview/:assessmentFormId` as const;
export const PLAN_ASSESSMENT_FORM =
  `${ASSESSMENT_FORMS}/plan/:assessmentFormId` as const;
export const EDIT_ASSESSMENT_FORM =
  `${ASSESSMENT_FORMS}/edit/:assessmentFormId` as const;
export const ASSESSMENT_GENERAL = '/general' as const;
export const ASSESSMENT_CRITERIA = '/criteria' as const;
export const ASSESSMENT_WEIGHTING = '/weighting' as const;

/* Qualification form management */
export const QUALIFICATION_FORMS = `/qualification-forms` as const;
export const CREATE_QUALIFICATION_FORM =
  `${QUALIFICATION_FORMS}/create` as const;

export const EDIT_QUALIFICATION_FORM =
  `${QUALIFICATION_FORMS}/edit/:qualificationFormId` as const;

export const EDIT_QUALIFICATION_FORM_TOPICS =
  `${QUALIFICATION_FORMS}/edit/topics/:qualificationFormId` as const;

export const CREATE_QUALIFICATION_FORM_TOPICS =
  `${QUALIFICATION_FORMS}/create/topics/:qualificationFormId` as const;

export const CREATE_QUALIFICATION_FORM_COMPETENCES =
  `${QUALIFICATION_FORMS}/create/competences/:qualificationFormId` as const;

export const EDIT_QUALIFICATION_FORM_COMPETENCES =
  `${QUALIFICATION_FORMS}/edit/competences/:qualificationFormId` as const;

/* Qualification management */
export const QUALIFICATION = `/qualification/:qualificationFormId` as const;
export const QUALIFICATION_TABLE = `/qualification/table` as const;
export const QUALIFICATION_GENERAL = `${QUALIFICATION}/general` as const;
export const QUALIFICATION_INFO =
  `${QUALIFICATION}/info/:qualificationId` as const;
export const QUALIFICATION_CRITERIA =
  `${QUALIFICATION}/criteria/:qualificationId` as const;

/* Incidents Reporting */

export const INCIDENTS_REPORTS = '/incidents' as const;
export const INCIDENT = `${INCIDENTS_REPORTS}/:incidentId` as const;

/* Templates Library */
export const TEMPLATES_LIBRARY = '/templates-library' as const;

export const TEMPLATE = `${TEMPLATES_LIBRARY}/:templateId` as const;
export const NEW_TEMPLATE = `${TEMPLATES_LIBRARY}/create` as const;

export const TEMPLATE_INFO_PATH = '/info' as const;
export const TEMPLATE_INFO = `${TEMPLATE}${TEMPLATE_INFO_PATH}` as const;
export const TEMPLATE_MATERIALS = `${TEMPLATE}${MATERIALS_PATH}` as const;

export const TEMPLATE_MATERIALS_MATERIALS =
  `${TEMPLATE_MATERIALS}${MATERIALS_PATH}` as const;
export const TEMPLATE_TABLES_MATERIALS =
  `${TEMPLATE_MATERIALS}${TABLES_PATH}` as const;
export const TEMPLATE_EQUIPMENT_MATERIALS =
  `${TEMPLATE_MATERIALS}${EQUIPMENT_PATH}` as const;

export const TEMPLATE_TIMEOUT = `${TEMPLATE}${TIMEOUT_PATH}` as const;
export const TEMPLATE_OPERATION_STEPS =
  `${TEMPLATE}${OPERATION_STEPS_PATH}` as const;
export const TEMPLATE_DEBRIEFING = `${TEMPLATE}${DEBRIEFING_PATH}` as const;
export const TEMPLATE_ATTACHMENTS = `${TEMPLATE}${ATTACHMENTS_PATH}` as const;

/* Settings */
export const SETTINGS = '/settings' as const;
export const INSTITUTE_SETTINGS = `${SETTINGS}/institute` as const;
export const REPORTING = `${SETTINGS}/op-report` as const;
export const PROFILE = `${SETTINGS}/profile` as const;
export const PASSWORD = `${SETTINGS}/password` as const;
export const OPERATION_PIN = `${SETTINGS}/operation-pin` as const;
export const GENERAL = `${SETTINGS}/general` as const;

export const STYLEGUIDE = '/styleguide' as const;

export const RESEARCH = '/research' as const;

/* Logged out */

export const AUTH = '/auth' as const;
export const AUTH_DEACTIVATED = `${AUTH}/deactivated` as const;
export const FORGOT_PASSWORD = `${AUTH}/forgot-password` as const;
export const LOGIN = `${AUTH}/login` as const;
export const REQUIRE_UPDATE_PASSWORD =
  `${LOGIN}?requireUpdatePwd=true` as const;
export const LOGOUT = '/logout' as const;
export const RESET_PASSWORD = `${AUTH}/reset-password` as const;
export const RETURN_LOGIN = `${AUTH}/relogin` as const;
