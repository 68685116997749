import {
  DATE_FORMAT,
  FIRST_DAY_OF_THE_WEEK,
  TIME_FORMAT,
} from 'app-constants/dates';
import {
  endOfWeek,
  format as originalFormat,
  parse,
  parseISO,
  startOfWeek,
  toDate,
} from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import leftPad from 'left-pad';
import i18n from 'translations/i18n-instance';

const locales = {
  en: enLocale,
  de: deLocale,
};

export const parseDate = (date, format, baseDate = new Date()) => {
  if (!date) return date;
  if (typeof date === 'string')
    return format ? parse(date, format, baseDate) : parseISO(date);
  return toDate(date);
};

export const format = (date, formatStr) =>
  originalFormat(parseDate(date), formatStr, {
    locale: locales[i18n.language],
  });

// FIX for IE11
const ensurePad = (time) =>
  time
    .split(':')
    .map((timePart) => leftPad(timePart, 2, '0'))
    .join(':');

export const toDateTime = (date, time) => {
  if (!date) {
    return null;
  }
  const timestring = time ? `T${ensurePad(time)}` : '';
  const datetimeString = `${date}${timestring}`;
  try {
    const isoDate = parseISO(datetimeString).toISOString();
    return isoDate;
  } catch (error) {
    return null;
  }
};

export const timeStringToHoursMinutes = (time) => {
  const regex = /([0-9]?[0-9]):([0-5][0-9])\s*?([ap]m)?/i;
  const match = time.match(regex);
  if (!match) return [];
  const [, hh, mm] = match;
  return [Number.parseInt(hh, 10), Number.parseInt(mm, 10)];
};

export const getWeekStartEndDates = (date) => {
  const parsedDate = parseDate(date);
  const fromDate = startOfWeek(parsedDate, {
    weekStartsOn: FIRST_DAY_OF_THE_WEEK,
  });
  const toDate = endOfWeek(parsedDate, { weekStartsOn: FIRST_DAY_OF_THE_WEEK });

  return { fromDate, toDate };
};

const formatter = (date, formatString) => {
  if (!date) return null;
  return format(date, formatString);
};

export const formatDate = (dateString) => formatter(dateString, DATE_FORMAT);
export const formatTime = (dateString) => formatter(dateString, TIME_FORMAT);

export const formatDateTime = (dateString) =>
  formatter(dateString, `${DATE_FORMAT} ${TIME_FORMAT}`);

export const getInputDateTime = (datestring) => ({
  date: datestring ? formatDate(datestring) : undefined,
  time: datestring ? formatTime(datestring) : undefined,
});

export const isoNow = () => new Date().toISOString();
