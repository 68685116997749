import invariant from 'invariant';

export const withParams = (path, pathParams) => {
  invariant(
    typeof path === 'string',
    `Expected \`path\` argument to be of type string, received: ${typeof path}`
  );

  invariant(
    pathParams && typeof pathParams === 'object',
    `Expected \`pathParams\` argument to be object, received: ${
      path === null ? 'null' : typeof path
    }`
  );

  return Object.keys(pathParams).reduce((result, key) => {
    const param = pathParams[key];
    const regexp = new RegExp(`(:${key})(\\??)`);
    const match = result.match(regexp);

    if (!match) return result;

    const [, matchedParam, isOptional] = match;

    if (!isOptional && !param) {
      throw new Error(`Required param ${key} is not provided.`);
    }

    return result.replace(matchedParam, param);
  }, path);
};

export const applyPathParams = withParams;
