import { normalizeResponse } from 'api/helpers/normalize-response';
import { APIv1 } from 'api/instance';
import {
  departmentSchema,
  instituteCompanyContactSchema,
  instituteSchema,
  materialCompaniesSchema,
} from './schemas';

const normalizeInstitute = normalizeResponse(instituteSchema);
export const readInstituteReq = (id) =>
  APIv1.get(`/institutes/${id}`).then((response) => {
    const normalized = normalizeInstitute(response);
    return {
      institute: response,
      ...normalized,
    };
  });

export const updateInstitute = (id, data) =>
  APIv1.patch(`institutes/${id}`, data, { ignoreRequestBodyClean: true }).then(
    normalizeInstitute
  );

export const updateUserRequest = (id, data) => {
  return APIv1.patch(`users/${id}`, data, { ignoreRequestBodyClean: true });
};

export const updateEmailReq = (currentEmail, newEmail) =>
  APIv1.post('user/change_email', { currentEmail, newEmail });

export const readDepartmentsReq = (id) =>
  APIv1.get(`institutes/${id}/departments`).then(
    normalizeResponse([departmentSchema])
  );

const normalizeMaterialsCompanies = normalizeResponse(materialCompaniesSchema);

export const listMaterialCompanies = () =>
  APIv1.get(`material-companies`).then(normalizeMaterialsCompanies);

export const createInstituteContact = (instituteId, contactInput) =>
  APIv1.post(`institutes/${instituteId}/contacts`, contactInput).then(
    normalizeResponse(instituteCompanyContactSchema)
  );

export const deleteInstituteContact = (contactId) =>
  APIv1.delete(`institutes-contacts/${contactId}`).then(
    normalizeResponse(instituteCompanyContactSchema)
  );
