import React, { forwardRef } from 'react';

import { TranslatableInput } from 'components/Form/Input/TranslatableInput/TranslatableInput';

import { FormInputBase } from '../FormInputBase';

export const FormInputTranslatable = forwardRef<any, any>((props, ref) => {
  return (
    <FormInputBase formInputType="textarea" {...props}>
      {({ getProps }) => <TranslatableInput ref={ref} {...getProps()} />}
    </FormInputBase>
  );
});
