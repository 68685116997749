import React, { forwardRef } from 'react';
import styles from './BaseSignaturePad.module.scss';
import SignaturePadWrapper from 'react-signature-pad-wrapper';
import { Row } from 'components/Grid';
import SignaturePad from 'react-signature-pad-wrapper';

interface BaseSignaturePadProps {
  children?: React.ReactNode;
  testId?: string;
}

export const BaseSignaturePad = forwardRef<SignaturePad, BaseSignaturePadProps>(
  ({ children, testId }: BaseSignaturePadProps, ref) => {
    return (
      <Row
        data-testid={testId}
        direction="column"
        className={styles.baseSignaturePad}
      >
        <div className={styles.padWrapper}>
          <SignaturePadWrapper ref={ref} />
        </div>
        {children}
      </Row>
    );
  }
);
