import { countLines } from 'utils/count-lines';

// Name
export const NAME_CHARS_PER_LINE = 35;
export const NAME_CHARS_PER_LINE_ATTACHMENTS = 20;

// Description
export const DESCRIPTION_CHARS_PER_LINE = 55;
export const DESCRIPTION_CHARS_PER_LINE_ATTACHMENTS = 35;

// Rows
export const MAX_ROWS = 7;
export const DESCRIPTION_TO_NAME_HEIGHT_COEFFICIENT = 0.7;

interface calculateOpStepCharLimitsT {
  (props: { name: string; description: string; hasAttachments?: boolean }): {
    // Name
    nameLinesLimit: number;
    nameCharsLimit: number;
    nameCharsPerLineLimit: number;

    // Description
    descriptionLinesLimit: number;
    descriptionCharsLimit: number;
    descriptionCharsPerLineLimit: number;
  };
}

const calculateLinesLimit = (
  occupiedLines: number,
  maximum: number,
  minimum: number = 0
) => Math.max(Math.round(maximum - occupiedLines), minimum);

const calculateCharsLimit = (linesLimit: number, charsPerLinesLimit: number) =>
  Math.floor(linesLimit * charsPerLinesLimit);

export const calculateOpStepCharLimits: calculateOpStepCharLimitsT = ({
  name,
  description,
  hasAttachments = false,
}) => {
  const nameCharsPerLineLimit = hasAttachments
    ? NAME_CHARS_PER_LINE_ATTACHMENTS
    : NAME_CHARS_PER_LINE;
  const nameLinesCount = countLines(name || '', nameCharsPerLineLimit);

  const descriptionCharsPerLineLimit = hasAttachments
    ? DESCRIPTION_CHARS_PER_LINE_ATTACHMENTS
    : DESCRIPTION_CHARS_PER_LINE;
  const descriptionLinesCount = countLines(
    description || '',
    descriptionCharsPerLineLimit
  );

  const nameLinesLimit = Math.round(
    calculateLinesLimit(descriptionLinesCount, MAX_ROWS, 1) *
      DESCRIPTION_TO_NAME_HEIGHT_COEFFICIENT
  );
  const descriptionLinesLimit = calculateLinesLimit(nameLinesCount, MAX_ROWS);

  const nameCharsLimit = calculateCharsLimit(
    nameLinesLimit,
    nameCharsPerLineLimit
  );
  const descriptionCharsLimit = calculateCharsLimit(
    descriptionLinesLimit,
    descriptionCharsPerLineLimit
  );

  return {
    // Name
    nameLinesLimit,
    nameCharsLimit,
    nameCharsPerLineLimit,

    // Description
    descriptionLinesLimit,
    descriptionCharsLimit,
    descriptionCharsPerLineLimit,
  };
};
