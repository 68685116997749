import React from 'react';
import NotificationsCenter from 'scenes/GlobalNotifications/NotificationsCenter';
import SmartLoaderBadge from './SmartLoaderBadge';

const GlobalNotifications = () => (
  <>
    <SmartLoaderBadge />
    <NotificationsCenter />
  </>
);

export default GlobalNotifications;
