import { Multiline } from 'components/Multiline';
import React, { ComponentType, useState } from 'react';
import { InputString } from 'components/Form/InputString';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  description?: string;
  onChange?: (update: object) => void;
}

export const MaterialsGroupHeader: ComponentType<Props> = ({
  name,
  description,
  onChange,
}) => {
  const { t } = useTranslation();
  const canUpdate = typeof onChange === 'function';
  const [nameValue, setNameValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState(null);

  const handleInputChange = (event) => {
    if (!canUpdate || !onChange) return;
    const { name: nameAttr, value } = event?.target ?? {};
    if ({ description, name }[nameAttr] === value.trim()) return;
    const update = { [nameAttr]: value.trim() };
    onChange(update);
  };

  const handleChangeText = (e) => {
    if (e.target.name === 'name') {
      setNameValue(e.target.value);
    }
    if (e.target.name === 'description') {
      setDescriptionValue(e.target.value);
    }
  };

  return (
    <span>
      {Boolean(name) && (
        <strong>
          {canUpdate ? (
            <InputString
              name="name"
              placeholder={t('enterName')}
              value={nameValue || name}
              onBlur={handleInputChange}
              onChange={handleChangeText}
            />
          ) : (
            name
          )}
        </strong>
      )}

      {canUpdate ? (
        <InputString
          name="description"
          placeholder={t('enterDescription')}
          multiline
          value={descriptionValue || description}
          onBlur={handleInputChange}
          onChange={handleChangeText}
        />
      ) : (
        Boolean(description) && (
          <div className="mt-05">
            <Multiline>{description}</Multiline>
          </div>
        )
      )}
    </span>
  );
};

MaterialsGroupHeader.displayName = 'MaterialsGroupHeader';
