import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './AuthUpdatePasswordForm.module.scss';

import {
  FormUpdatePassword,
  useFormUpdatePassword,
} from 'forms/FormUpdatePassword';
import { LOGIN } from 'scenes/routes.enum';
import { Text, Button } from 'components';

export const AuthUpdatePasswordForm: FC = () => {
  const { t } = useTranslation();
  const { submit, control } = useFormUpdatePassword(true);

  return (
    <section className={styles.root}>
      <Text component="h4" variant="heading-lg" align="center">
        {t('updateYourPassword')}
      </Text>

      <Text
        component="p"
        variant="heading-md"
        align="center"
        className="mb-1-5"
      >
        {t('updateYourPasswordDescription')}
      </Text>

      <FormUpdatePassword control={control} className={styles.form} />

      <div className={styles.footer}>
        <Button
          type="button"
          variant="secondary"
          component={Link}
          to={LOGIN}
          className={styles.back}
        >
          {t('backToLogin')}
        </Button>

        <Button
          type="button"
          variant="primary"
          onClick={submit}
          className={styles.submit}
        >
          {t('save')}
        </Button>
      </div>
    </section>
  );
};
