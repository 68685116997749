import { bool, string, number, shape } from 'prop-types';
import { idTypes, translatableString } from './common';

export const templateAuthorTypes = {
  id: idTypes,
  givenName: string,
  surname: string,
  email: string,
};

export const libraryTemplateTypes = {
  id: idTypes,
  createdAt: string,
  updatedAt: string,
  parentId: idTypes,
  createdBy: idTypes,
  updatedBy: idTypes,
  criticalDelay: number,
  isActive: bool,
  isDeleted: bool,
  totalEstimatedTime: number,
  categoryId: idTypes,
  author: shape(templateAuthorTypes),
  name: translatableString,
  description: translatableString,
};

export const libraryTemplateCategories = {
  id: idTypes,
  createdAt: string,
  updatedAt: string,
  isDeleted: bool,
  createdBy: idTypes,
  updatedBy: idTypes,
  parentId: idTypes,
  name: translatableString,
};
