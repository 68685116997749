import invariant from 'invariant';
import {
  createTemplateTimeoutQuestionsSelector,
  createTemplateDebriefingQuestionsSelector,
} from '../selectors';
import {
  updateTemplateTimeoutQuestions,
  updateTemplateDebriefingQuestions,
} from '../thunks';
import { CHANGE_TEMPLATE_QUESTIONS_ORDER } from '../types';

export const stripTemporaryIds = (item) => {
  const { id, ...restItem } = item;
  if (item.id && !item.createdAt) return { ...restItem };
  return item;
};

export const wrapStaticTimeoutQuestionsAction =
  (actionFn) => (payload) => (dispatch, getState) => {
    dispatch(actionFn(payload));

    invariant(payload.templateId, 'payload.templateId is required');

    const templateQuestionsSelector = createTemplateTimeoutQuestionsSelector(
      payload.templateId
    );
    const state = getState();
    const questions = (templateQuestionsSelector(state) || []).map(
      stripTemporaryIds
    );

    invariant(questions, 'questions should be an array');

    const updateAction = updateTemplateTimeoutQuestions(
      payload.templateId,
      {
        questions,
      },
      state?.language?.['deepl-language']
    );
    return dispatch(updateAction);
  };

export const wrapStaticDebriefingQuestionsAction =
  (actionFn) => (payload) => (dispatch, getState) => {
    dispatch(actionFn(payload));

    invariant(payload.templateId, 'payload.templateId is required');

    const templateQuestionsSelector = createTemplateDebriefingQuestionsSelector(
      payload.templateId
    );
    const state = getState();
    const questions = (templateQuestionsSelector(state) || []).map(
      stripTemporaryIds
    );

    invariant(questions, 'questions should be an array');

    const updateAction = updateTemplateDebriefingQuestions(
      payload.templateId,
      {
        questions,
      },
      state?.language?.['deepl-language']
    );
    return dispatch(updateAction);
  };

export const makeChangeTemplateQuestionOrderAction =
  (questionsType) =>
  ({ templateId, fromIndex, toIndex }) => ({
    type: CHANGE_TEMPLATE_QUESTIONS_ORDER,
    payload: {
      fromIndex,
      questionsType,
      templateId,
      toIndex,
    },
  });
