export const JOIN_CHAR = ', ';

export type AllowedMimeType =
  | 'image/jpg'
  | 'image/jpeg'
  | 'image/gif'
  | 'image/png'
  | 'application/pdf'
  | 'video/quicktime'
  | 'video/mp4';

// File size limits are in bytes
export const VIDEO_SIZE_LIMIT = 150e6;
export const DOCUMENT_SIZE_LIMIT = 10e6;
export const IMAGE_SIZE_LIMIT = 10e6;

export const ALLOWED_IMAGES_EXTENSIONS = ['.jpg', '.jpeg', '.gif', '.png'];
export const ALLOWED_VIDEO_EXTENSIONS = ['.mov', '.qt', '.mp4'];
export const ALLOWED_DOCUMENT_EXTENSIONS = ['.pdf'];
export const ALLOWED_FILE_EXTENSIONS = [
  ...ALLOWED_IMAGES_EXTENSIONS,
  ...ALLOWED_DOCUMENT_EXTENSIONS,
  ...ALLOWED_VIDEO_EXTENSIONS,
];

export const ALLOWED_IMAGE_TYPES: AllowedMimeType[] = [
  'image/jpeg',
  'image/png',
  'image/gif',
];
export const ALLOWED_VIDEO_TYPES: AllowedMimeType[] = [
  'video/quicktime',
  'video/mp4',
];

export const ALLOWED_DOCUMENT_TYPES: AllowedMimeType[] = ['application/pdf'];
export const ALLOWED_FILE_TYPES: AllowedMimeType[] = [
  ...ALLOWED_IMAGE_TYPES,
  ...ALLOWED_VIDEO_TYPES,
  ...ALLOWED_DOCUMENT_TYPES,
];

export const SIZE_LIMITS_MAP = Object.assign(
  {},
  ALLOWED_IMAGE_TYPES.reduce(
    (acc, type) => ({ ...acc, [type]: IMAGE_SIZE_LIMIT }),
    {}
  ),
  ALLOWED_VIDEO_TYPES.reduce(
    (acc, type) => ({ ...acc, [type]: VIDEO_SIZE_LIMIT }),
    {}
  ),
  ALLOWED_DOCUMENT_TYPES.reduce(
    (acc, type) => ({ ...acc, [type]: DOCUMENT_SIZE_LIMIT }),
    {}
  )
);

export const toAcceptAttribute = (allowed: AllowedMimeType[]): string =>
  allowed.join(JOIN_CHAR);

export const toAllowed = (accept: string): AllowedMimeType[] =>
  accept.split(JOIN_CHAR) as AllowedMimeType[];

export const ACCEPT_DOCUMENT_TYPES = toAcceptAttribute(ALLOWED_DOCUMENT_TYPES);
export const ACCEPT_IMAGE_TYPES = toAcceptAttribute(ALLOWED_IMAGE_TYPES);
export const ACCEPT_FILE_TYPES = toAcceptAttribute(ALLOWED_FILE_TYPES);

export const MAX_STEP_ATTACHMENTS = 2;
