import { STAGING, PRODUCTION, DEVELOPMENT, Environment } from 'app-constants';
import { ProductType } from 'common-types/common';

const STAGING_HOST = 'staging-app.impedia.org';
const STAGING_HOST_VET = 'staging-app.impediavet.org';
const STAGING_HOST_LEGACY = 'staging-app.nodus-medical.com';
const PRODUCTION_HOST = 'app.impedia.org';
const PRODUCTION_HOST_VET = 'app.impediavet.org';
const PRODUCTION_HOST_LEGACY = 'app.nodus-medical.com';
const DEVELOPMENT_HOST = 'localhost:3000';

const getEnvByURL = () => {
  let env: Environment | null;
  const hostname = window.location.host;

  switch (hostname) {
    case STAGING_HOST:
    case STAGING_HOST_VET:
    case STAGING_HOST_LEGACY:
      env = STAGING;
      break;
    case PRODUCTION_HOST:
    case PRODUCTION_HOST_VET:
    case PRODUCTION_HOST_LEGACY:
      env = PRODUCTION;
      break;
    case DEVELOPMENT_HOST:
      env = DEVELOPMENT;
      break;
    default:
      env = null;
  }

  // Filter out non-HTTPS location
  if (env && env !== DEVELOPMENT && window.location.protocol !== 'https://') {
    return null;
  }

  return env;
};

/**
 * Used to determine product type of the application based on environment variable.
 * Can be used to conditionally execute logic specific for only one of the products.
 */
export const isProductType = (type: ProductType) => {
  return process.env.REACT_APP_PRODUCT_TYPE === type;
};

export default () =>
  process.env.REACT_APP_HOST_ENV || getEnvByURL() || process.env.NODE_ENV;
