import { readOperations, readOperationsVet } from 'api/v2';
import { startOfDay, endOfDay } from 'date-fns';
import { parseDate } from 'utils';
import { makeThunk } from 'store/utils';

import * as actions from './actions';
import { isProductType } from 'utils/get-env';

export const readScheduledOperationsThunk = makeThunk(
  isProductType('vet') ? readOperationsVet : readOperations,
  {
    request: actions.readScheduledOperationsRequest,
    success: actions.readScheduledOperationsSuccess,
    failure: actions.readScheduledOperationsFailure,
  },
  'Failed to read scheduled operations list.'
);

export const readScheduledOperations =
  (fromDate, toDate, lang) => (dispatch) => {
    const from = startOfDay(parseDate(fromDate)).toISOString();
    const to = endOfDay(parseDate(toDate)).toISOString();

    return dispatch(readScheduledOperationsThunk(from, to, lang));
  };
