import deepmerge from 'deepmerge';
import has from 'lodash/has';
import {
  CREATE_OPERATION_MATERIALS_GROUP_SUCCESS,
  UPDATE_OPERATION_MATERIALS_REQUEST,
} from 'store/modules/operation-planning';
import { normalizeOperationMaterials } from 'api/v1/materials/operation-materials';

export default (state = {}, action) => {
  const { type, payload } = action;
  if (has(payload, 'entities.operationMaterials')) {
    const result = deepmerge(state, payload.entities.operationMaterials, {
      arrayMerge: (prev, next) => next,
    });
    return result;
  }

  switch (type) {
    case UPDATE_OPERATION_MATERIALS_REQUEST: {
      const [operationId, update, optimistic = false] = payload.requestArgs;
      if (!optimistic) return state;
      const {
        result,
        entities: { operationMaterials },
      } = normalizeOperationMaterials(operationId)(update);

      return {
        ...state,
        [result]: { ...state[result], ...operationMaterials[result] },
      };
    }

    case CREATE_OPERATION_MATERIALS_GROUP_SUCCESS: {
      const { requestId: operationId } = action;
      const { result } = payload;
      const operationMaterials = state[operationId] || {};
      const operationMaterialsGroups = operationMaterials.materialsGroups || [];

      return {
        ...state,
        [operationId]: {
          ...operationMaterials,
          materialsGroups: operationMaterialsGroups.concat(result),
        },
      };
    }

    default:
      return state;
  }
};
