import { useState } from 'react';

import { requestPasswordReset } from 'api/v2/auth';

export interface AuthFormForgotPasswordState {
  email: string;
}

export const getInitialFormState = (
  email?: string
): AuthFormForgotPasswordState => ({
  email: email || '',
});

export const usePasswordResetRequest = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<null | { message: string }>(null);

  const request = async ({ email }: AuthFormForgotPasswordState) => {
    try {
      setError(null);
      setLoading(true);
      setResponse(await requestPasswordReset({ email }));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    request,
    response,
    loading,
    error,
  };
};
