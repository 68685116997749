import cn from 'classnames';
import React, { FC, memo } from 'react';

import { FormValidationOption } from './utils';
import styles from './FormPasswordValidation.module.scss';

import { Text } from 'components';

interface FormPasswordValidationProps
  extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * Password value to validate
   */
  value: string;
  /**
   * List of options to check
   */
  options: FormValidationOption[];
}

/**
 * Renders password validation requirements
 */
export const FormPasswordValidation: FC<FormPasswordValidationProps> = memo(
  function FormPasswordValidation({ value, options, className, ...props }) {
    return (
      <div className={cn(styles.root, className)} {...props}>
        <div className={styles.options}>
          {options.map(({ id, label, validate }) => (
            <Text
              key={id}
              component="span"
              variant="small"
              weight="bold"
              transform="uppercase"
              className={cn(styles.option, validate(value) && styles.highlight)}
            >
              {label}
            </Text>
          ))}
        </div>
      </div>
    );
  }
);
