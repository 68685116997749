import get from 'lodash/get';
import { denormalize, normalize, NormalizedSchema } from 'normalizr';
import { createSelector } from 'reselect';
import { ResponseMeta } from '../../common-types/common';

export const createPropsGetter = (propertyPath, defaultValue?) => (
  _,
  ownProps
) => get(ownProps, propertyPath, defaultValue);

export const createStateGetter = (propertyPath, defaultValue?) => (state) =>
  get(state, propertyPath, defaultValue);

export const matchParamsGetter = (_, ownProps) => get(ownProps, 'match.params');

export const createMatchParamsGetter = (path) => (_, ownProps) =>
  get(ownProps, `match.params.${path}`, get(ownProps, path, null));

export interface createEntitySelector {
  (
    entitiesSelector: (state: any, props: any) => any[],
    idSelector?: (state: any, props?: any) => number | string
  ): (state: any, props?: any) => any;
}

const noop = (a) => a;

export const createEntitySelector = <T = any>(
  selectEntities,
  selectId = noop
) =>
  createSelector(
    [selectEntities, selectId],
    (list: { [index: string]: T }, id: number | string | null) =>
      (list && id && list?.[id]) ?? null
  );

export type CreateEntitiesListSelector = (
  defaultValue?: any
) => (entities: object, ids: Array<number>) => Array<any> | null;

export const createEntitiesListSelector: CreateEntitiesListSelector = (
  defaultValue = null
) => (entities, ids) => {
  if (!entities || !ids) return defaultValue;
  return ids
    .map((id) => entities[id])
    .filter((entity) => entity && entity.isDeleted !== true);
};

export const createCollectionSelector = (propName, filterDeleted = false) => (
  element,
  collection
) => {
  if (!element || !collection) return null;

  const getCollectionIdsList = () => {
    if (!propName) {
      return element;
    } else if (typeof propName === 'string') {
      return element[propName];
    } else if (Array.isArray(propName)) {
      return propName.reduce((result, key) => result || element[key], null);
    }
  };

  const collectionIds = getCollectionIdsList();

  if (!Array.isArray(collectionIds)) return null;

  return collectionIds.reduce((acc, id) => {
    const collectionItem = collection[id];
    if (!collectionItem || (filterDeleted && collectionItem.isDeleted))
      return acc;
    return [...acc, collectionItem];
  }, []);
};

export const createNormalizr = (schema) => (response) => {
  const meta = response?.meta;
  const normalized: NormalizedSchema<any, any> & {
    meta?: ResponseMeta;
  } = normalize(response, schema);
  if (meta) {
    normalized.meta = meta;
  }

  return normalized;
};

export const createDenormalizr = (schema) => (entity, entities) =>
  entity && entities ? denormalize(entity, schema, entities) : entity;
