import { createContext } from 'react';
import { READONLY } from 'app-constants/form-modes';

export const OperationPlanningContext = createContext<{
  mode: string;
  operationId: string | null;
  templateId: number | null;
}>({
  mode: READONLY,
  operationId: null,
  templateId: null,
});

export const { Consumer, Provider } = OperationPlanningContext;

export default OperationPlanningContext;
