import withT from 'containers/withT';
import Bugsnag from '@bugsnag/js';
import { Formik } from 'formik';
import { arrayOf, func, shape } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import omitBy from 'lodash/omitBy';
import {
  equipmentTypes,
  implantsTypes,
  instrumentsTypes,
  templateTypes,
} from 'shared-prop-types';
import { selectTemplateByIdProp } from 'store/modules/entities/selectors/templates';
import {
  createTemplateSnapshot,
  updateTemplate,
} from 'store/modules/templates-library/thunks';
import {
  isTranslatableStringEmpty,
  toTranslatable,
  translateValue,
} from 'utils';
import {
  Button,
  FormBody,
  FormInput,
  FormTemplateCategoryInput,
  FormTextarea,
  Icon,
  MessageBlock,
  Modal,
  PanelActions,
  PanelBody,
} from 'components';

class CopyTemplateModal extends Component {
  static _propTypes = {
    createTemplateSnapshot: func,
    updateTemplate: func,
    copyMaterials: func,
    t: func,
    template: shape(templateTypes),
    materials: shape({
      equipment: arrayOf(shape(equipmentTypes)),
      implants: arrayOf(shape(implantsTypes)),
      instruments: arrayOf(shape(instrumentsTypes)),
    }),
  };

  static defaultProps = {
    materials: {
      equipment: [],
    },
  };

  get formValues() {
    const { template, operationTitle } = this.props;

    const initialValues = {
      categoryId: template.categoryId ?? null,
      description: toTranslatable(translateValue(template.description)),
      name: operationTitle
        ? toTranslatable(operationTitle)
        : toTranslatable(translateValue(template.name)),
    };

    return initialValues;
  }

  handleValidation = (values) => {
    const { t } = this.props;
    const errors = {};

    if (!values.categoryId) {
      errors.categoryId = t('selectTemplateDiscipline');
    }

    if (isTranslatableStringEmpty(values.name)) {
      errors.name = t('nameIsRequired');
    }

    return errors;
  };

  handleSubmit = async (values) => {
    const { template, updateTemplate, createTemplateSnapshot, onClose } =
      this.props;

    try {
      const newTemplate = await createTemplateSnapshot(template.id, true);
      const updateBody = omitBy(values, isTranslatableStringEmpty);
      await updateTemplate(newTemplate.id, updateBody);
      onClose({ newTemplate });
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

  render() {
    const { isOpen, template, onClose, t } = this.props;

    if (!template) return null;

    return (
      <Modal
        title={t('saveOperationTemplate')}
        opened={isOpen}
        onRequestClose={onClose}
      >
        <MessageBlock type="info">
          {t('hint:templateAlternateMessage')}
        </MessageBlock>
        <Formik
          enableReinitialize
          initialValues={this.formValues}
          validate={this.handleValidation}
          onSubmit={this.handleSubmit}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <PanelBody style={{ overflow: 'visible' }}>
                <FormBody>
                  <FormTemplateCategoryInput
                    error={touched.categoryId && errors.categoryId}
                    label={t('Discipline')}
                    name="categoryId"
                    onChange={handleChange}
                    value={values.categoryId}
                  />
                  <FormInput
                    error={touched.name && errors.name}
                    name="name"
                    label={t('Name')}
                    onChange={handleChange}
                    mode="translatable"
                    placeholder={t('enterName')}
                    value={values.name}
                  />
                  <FormTextarea
                    error={touched.description && errors.description}
                    name="description"
                    label={t('Description')}
                    placeholder={t('enterDescription')}
                    onChange={handleChange}
                    mode="translatable"
                    value={values.description}
                  />
                </FormBody>
              </PanelBody>
              <PanelActions>
                <Button
                  type="button"
                  className="mr-1"
                  variant="secondary"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>

                <Button type="submit" disabled={isSubmitting}>
                  <Icon icon="library" gapRight /> {t('saveToLibrary')}
                </Button>
              </PanelActions>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  template: selectTemplateByIdProp,
});
const mapDispatchToProps = {
  createTemplateSnapshot: createTemplateSnapshot,
  updateTemplate: updateTemplate,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhancer = compose(withT, withRouter, withConnect);

export default enhancer(CopyTemplateModal);
