import React, { ReactElement, ReactNode, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';

import { OperationTimeoutQuestionType } from 'common-types/operation';
import { ToggleableListItem } from './ToggleableListItem';
import { ListItemType } from 'common-types/common';
import { noop } from 'lodash';
import { List, ListHeader, ListItem } from 'components/List';
import { Text } from 'components/Typography/Text';
import { Loader } from 'components/Loader';
import { Button } from 'components/Button';

interface Props {
  /**
   * unique identifier for dropable id
   */
  id: string;
  /**
   * Items to display in the list
   */
  items: OperationTimeoutQuestionType[];
  /**
   * If true, and editMode state is active then it displays button to add new at the bottom.
   * Currently not passed down by any component that uses ToggleableList
   */
  canAdd?: boolean;
  /**
   * Indicator to display loader.
   * Currently not passed down by any component that uses ToggleableList
   */
  isCreateInProgress?: boolean;
  /**
   * React-beautiful-dnd function. Updates the questions order.
   */
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  /**
   * Function that creates new item.
   * Currently not passed down by any component that uses ToggleableList
   */
  onItemCreate?: Function;
  /**
   * Dispatches call to toggle given question
   */
  onItemToggle: (id: number, checked: boolean) => void;
  /**
   * Passed down to list item. Displays ReactElement when called.
   */
  renderItem: (item: ListItemType) => ReactNode;
  /**
   * Ids of the selected questions
   */
  selectedItems: number[];
  /**
   * Title of the list
   */
  title?: string;
}

export const ToggleableList = ({
  id,
  items,
  onDragEnd,
  onItemToggle,
  renderItem,
  selectedItems,
  title,
  canAdd = false,
  isCreateInProgress = false,
  onItemCreate = noop,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const turnOnEditMode = () => {
    setEditMode(true);
  };

  const turnOffEditMode = () => {
    setEditMode(false);
  };

  const handleCreate = (content) => {
    onItemCreate(content);
    turnOffEditMode();
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <List>
        {title && (
          <ListHeader>
            <Text variant="heading-md" component="span">
              {title}
            </Text>
          </ListHeader>
        )}

        <Droppable droppableId={id}>
          {(DroppableProvided) => (
            <div
              ref={DroppableProvided.innerRef}
              {...DroppableProvided.droppableProps}
            >
              {items &&
                items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(item.id)}
                    index={index}
                  >
                    {(DraggableProvided, _DraggableSnapshot) => (
                      <ToggleableListItem
                        {...DraggableProvided.draggableProps}
                        {...DraggableProvided.dragHandleProps}
                        isDragging={_DraggableSnapshot.isDragging}
                        innerRef={DraggableProvided.innerRef}
                        checked={includes(selectedItems, item.id)}
                        id={`toggleable-item-${item.id}`}
                        item={item}
                        onChange={onItemToggle}
                        renderItem={renderItem}
                      />
                    )}
                  </Draggable>
                ))}
              {DroppableProvided.placeholder}
            </div>
          )}
        </Droppable>

        {!isCreateInProgress && editMode && (
          <ToggleableListItem mode="edit" onCreate={handleCreate} />
        )}
        {isCreateInProgress && (
          <ListItem>
            <Loader />
          </ListItem>
        )}
      </List>
      {canAdd && !editMode && (
        <Button type="button" variant="secondary" onClick={turnOnEditMode}>
          {t('addNew')}
        </Button>
      )}
    </DragDropContext>
  );
};
