import React, { ComponentType, HTMLProps } from 'react';
import classes from './InputString.module.scss';
import cn from 'classnames';

interface Props extends HTMLProps<HTMLInputElement> {
  error?: string;
}

export const Input: ComponentType<Props> = ({
  className,
  error,
  ...inputProps
}) => {
  return (
    <input
      className={cn(className, classes.input, { [classes.error]: error })}
      {...inputProps}
    />
  );
};

Input.displayName = 'Input';
