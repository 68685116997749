import { LibraryCategories, MaterialElementInput } from 'common-types/library';
import { IconButton } from 'components/Button';
import { ListItem } from 'components/List';
import invariant from 'invariant';
import React, { ComponentType, useContext } from 'react';
import { MaterialsFormContext } from '../context';
import { ElementCounter } from './ElementCounter';
import { ElementInfo } from './ElementInfo';

interface Props {
  name: string;
  serialNumber?: string | null;
  size?: string | null;
  category: LibraryCategories;
  company?: string | null;
  count?: number;
  readonly?: boolean;
  uuid: string;
  onRemove?: (uuid: string) => void;
  onUpdate?: (uuid: string, update: Partial<MaterialElementInput>) => void;
}

export const MaterialElement: ComponentType<Props> = ({
  category,
  company,
  count = 1,
  name,
  onRemove,
  onUpdate,
  serialNumber,
  size,
  uuid,
}) => {
  const { readonly } = useContext(MaterialsFormContext);
  const handleRemove = () => {
    invariant(onRemove, 'onRemove is undefined, but handleRemove called');
    onRemove(uuid);
  };
  const hasCount = [
    LibraryCategories.instruments,
    LibraryCategories.equipment,
  ].includes(category);

  const handleCountChange = (count) => {
    if (!onUpdate) return;
    onUpdate(uuid, { count });
  };

  const canRemove: boolean = typeof onRemove === 'function' && !readonly;

  return (
    <ListItem
      contentClassName="full-width"
      hoverable={canRemove}
      addon={canRemove && <IconButton icon="delete" onClick={handleRemove} />}
    >
      <div>
        <div className="flex justify-between align-center">
          <div>
            <ElementInfo
              name={name}
              category={category}
              size={size}
              serialNumber={serialNumber}
              company={company}
            />
          </div>
          {hasCount && (
            <ElementCounter
              className="mr-05 ml-05"
              id={uuid}
              count={count}
              onChange={handleCountChange}
            />
          )}
        </div>
      </div>
    </ListItem>
  );
};
