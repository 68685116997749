import {
  arrayOf,
  bool,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { LibraryCategories } from '../common-types/library';

import { translatableString, idTypes, translatedString } from './common';

export const libraryItemsSearchOptionTypes = {
  value: idTypes,
  label: string,
  category: LibraryCategories,
  isSelected: bool,
};

export const diagnosisTypes = {
  code: string.isRequired,
  name: string.isRequired,
  id: idTypes,
  uuid: string.isRequired,
};

export const diagnosesTypes = arrayOf(diagnosisTypes);

export const libraryItemTypes = {
  id: idTypes,
  uuid: string,
  name: translatableString,
  category: LibraryCategories,
  edited: bool,
};

export const checksTypes = {
  ...libraryItemTypes,
  category: oneOf([LibraryCategories.checks]),
  description: translatableString,
  estimatedTime: number,
};

export const proceduresTypes = {
  ...libraryItemTypes,
  category: oneOf([LibraryCategories.procedures]),
  description: translatableString,
  estimatedTime: number,
};

export const equipmentTypes = {
  ...libraryItemTypes,
  category: oneOf([LibraryCategories.equipment]),
  company: string,
  serialNumber: string,
  size: string,
};

export const implantsTypes = {
  ...libraryItemTypes,
  category: oneOf([LibraryCategories.implants]),
  company: string,
  implantNumber: string,
  serialNumber: string,
  size: string,
};

export const instrumentsTypes = {
  ...libraryItemTypes,
  category: oneOf([LibraryCategories.instruments]),
  company: string,
  serialNumber: string,
  size: string,
};

export const risksTypes = {
  ...libraryItemTypes,
  category: oneOf([LibraryCategories.risks, LibraryCategories.patientRisks]),
  description: translatableString,
  mitigationAction: translatableString,
};

export const questionTypes = {
  id: idTypes.isRequired,
  content: string.isRequired,
  isStandard: bool.isRequired,
  isDeleted: bool.isRequired,
};

export const elementTypes = oneOfType([
  shape(checksTypes),
  shape(proceduresTypes),
  shape(risksTypes),
]);

export const materialsTypes = oneOfType([
  shape(equipmentTypes),
  shape(instrumentsTypes),
  shape(implantsTypes),
]);

export const categoryShape = {
  id: idTypes,
  count: number,
  name: translatedString,
};
