import { useMemo } from 'react';

const DEFAULT_WARN_THRESHOLD_COEFFICIENT = 0.15;

export const useLimitComputing = (
  length: number,
  limit: number,
  warnThreshold: number = DEFAULT_WARN_THRESHOLD_COEFFICIENT
): { isOverLimit: boolean; isCloseToLimit: boolean } => {
  const limitDiff = useMemo(() => limit - length, [length, limit]);
  const limitDiffThreshold = useMemo(() => Math.floor(limit * warnThreshold), [
    limit,
    warnThreshold,
  ]);
  const isOverLimit = limitDiff < 0;
  const isCloseToLimit = !isOverLimit && limitDiff <= limitDiffThreshold;

  return { isOverLimit, isCloseToLimit };
};
