import { APIv2 } from 'api/instance';

export const requestPasswordReset = ({ email }) =>
  APIv2.post<{ email: string }, { message: string }>('/auth/reset_password', {
    email,
  });
export const changePassword = ({
  token,
  newPassword,
  currentPassword,
}: {
  token?: string;
  newPassword: string;
  currentPassword?: string;
}) => APIv2.post('/auth/new_password', { token, newPassword, currentPassword });
