import { FC, FocusEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { isEqual, noop } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { ENTER, ESCAPE } from 'app-constants/keycodes';

import { Textarea } from '../../Textarea';
import styles from './LabeledInput.module.scss';
import { Text } from 'components/Typography';
import { ErrorMessage } from '../../FormInput';
import { TFunctionDetailedResult } from 'i18next';
import { TextVariant } from 'components/Typography/Text/utils';
import { ConfirmModal } from 'components/Confirm';
import { useTranslation } from 'react-i18next';

interface LabeledInputProps {
  id: string;
  tags: string[];
  validateEmail?: boolean;
  className?: string;
  variant?: TextVariant;
  handleSave?: (...args) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  error?: string | TFunctionDetailedResult | false;
  disabled?: boolean;
  delimiter?: string;
  capitalized?: boolean;
  warnOnExit?: boolean;
  warningMessage?: string;
}

export const LabeledInput: FC<LabeledInputProps> = ({
  id,
  tags,
  validateEmail = false,
  className = '',
  variant = 'paragraph-md',
  handleSave,
  onBlur = noop,
  error,
  disabled,
  delimiter = ';',
  capitalized = false,
  warnOnExit = false,
  warningMessage,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [localTags, setLocalTags] = useState(tags || []);
  const [warningEvent, setWarningEvent] =
    useState<FocusEvent<HTMLTextAreaElement> | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setLocalTags(tags);
  }, [tags]);

  const onEditIn = () => !disabled && setIsEdit(true);

  const handleUpdateTags = (event: FocusEvent<HTMLTextAreaElement>) => {
    onBlur(event);
    if (handleSave && !isEqual(tags, localTags)) {
      const updatedTags = localTags
        .map((tag) => tag.trim())
        .filter(Boolean)
        .map((tag) => ({ value: tag }));
      handleSave(updatedTags);
    }
    setIsEdit(false);

    if (warningEvent) {
      setWarningEvent(null);
    }
  };

  const onEditOut = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (warnOnExit && tags.length !== 0 && !isEqual(tags, localTags)) {
      setWarningEvent(event);
    } else {
      handleUpdateTags(event);
    }
  };

  const setCursorToEndOfLine = (event) =>
    event.target.setSelectionRange(
      event.target.value.length,
      event.target.value.length
    );

  const handleInputChange = (event) => {
    const { value } = event.target;
    setLocalTags(value.split(delimiter));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ENTER) {
      event.preventDefault();
      onEditOut(event);
    } else if (event.keyCode === ESCAPE) {
      onEditOut(event);
    }
  };

  const handleSelectKey = (event) => {
    if (!disabled && event.keyCode === ENTER) {
      onEditIn();
    }
  };

  return (
    <>
      <ConfirmModal
        title={t('assessmentForms.errors.loosingCriteria')}
        content={warningMessage}
        confirmButtonLabel={t('Continue')}
        declineButtonLabel={t('cancel')}
        onDecline={() => {
          setWarningEvent(null);
          setIsEdit(false);
          setLocalTags(tags);
        }}
        onConfirm={() => (warningEvent ? handleUpdateTags(warningEvent) : null)}
        confirmButtonVariant="secondary-danger"
        isOpen={!!warningEvent}
      />

      <section className={cn(styles.labeledInput, className)}>
        {isEdit ? (
          <Textarea
            id={id}
            data-testid={`labeleditor`}
            autoFocus
            onFocus={setCursorToEndOfLine}
            className={styles.input}
            onChange={handleInputChange}
            value={localTags.join(delimiter)}
            onBlur={onEditOut}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <div
            data-testid={`listbox-${id}`}
            role="listbox"
            tabIndex={disabled ? undefined : 0}
            className={cn(
              styles.mockInput,
              disabled && styles.disabled,
              error && styles.error
            )}
            onClick={onEditIn}
            onKeyDown={handleSelectKey}
          >
            {localTags.map((tag, index) => {
              if (!tag) return null;

              return (
                <Text
                  key={`${tag}${index}`}
                  variant={variant}
                  component="span"
                  className={cn(
                    styles.taggedLabel,
                    capitalized && styles.capitalizeTag,
                    {
                      [styles.taggedLabelError]:
                        validateEmail && !isEmail(tag.trim()),
                    }
                  )}
                >
                  {tag}
                </Text>
              );
            })}
          </div>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </section>
    </>
  );
};
