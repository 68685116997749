import * as types from 'store/modules/operation-planning/types';
import {
  TOGGLE_DEBRIEFING_QUESTION_SELECTION,
  TOGGLE_TIMEOUT_QUESTION_SELECTION,
} from 'store/modules/templates-library/types';

export const openOperationPlanning = () => ({
  type: types.OPEN_OPERATION_PLANNING,
});

export const startOperationCreation = (templateId) => ({
  type: types.START_OPERATION_CREATION,
  payload: { templateId },
});

export const finishOperationCreation = (operationId) => ({
  type: types.FINISH_OPERATION_CREATION,
  payload: { id: operationId },
});

export const closeOperationPlanning = (reason) => ({
  type: types.CLOSE_OPERATION_PLANNING,
  payload: { reason },
});

export const toggleTimeoutQuestionSelection = (id, templateId, checked) => ({
  type: TOGGLE_TIMEOUT_QUESTION_SELECTION,
  payload: {
    id,
    templateId,
    checked,
  },
});

export const toggleDebriefingQuestionSelection = (id, templateId, checked) => ({
  type: TOGGLE_DEBRIEFING_QUESTION_SELECTION,
  payload: {
    id,
    templateId,
    checked,
  },
});

export const addPatientRisk = (operationId, uuid) => ({
  type: types.ADD_PATIENT_RISK,
  payload: {
    operationId,
    uuid,
  },
});

export const removePatientRisk = (operationId, uuid) => ({
  type: types.REMOVE_PATIENT_RISK,
  payload: {
    operationId,
    uuid,
  },
});
