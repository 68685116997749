import { researchListSchema } from 'api/v1/schemas';
import {
  createSuccessActionCreator_DEPRECATED,
  createFailureActionCreator,
} from 'store/utils';

import * as types from './types';

/* get all articles */
export const readResearchArticlesRequest = payload => ({
  type: types.READ_RESEARCH_ARTICLES_REQUEST,
  payload,
});

export const readResearchArticlesSuccess = createSuccessActionCreator_DEPRECATED(
  types.READ_RESEARCH_ARTICLES_SUCCESS,
  null,
  researchListSchema
);

export const readResearchArticlesFailure = createFailureActionCreator(
  types.READ_RESEARCH_ARTICLES_FAILURE
);

/* get favorite articles */
export const readResearchFavArticlesRequest = payload => ({
  type: types.READ_RESEARCH_FAVORITES_ARTICLES_REQUEST,
  payload,
});

export const readResearchFavArticlesSuccess = createSuccessActionCreator_DEPRECATED(
  types.READ_RESEARCH_FAVORITES_ARTICLES_SUCCESS,
  null,
  researchListSchema
);

export const readResearchFavArticlesFailure = createFailureActionCreator(
  types.READ_RESEARCH_FAVORITES_ARTICLES_FAILURE
);

/* get favorites articles ids */
export const readFavoritesArticlesIdsRequest = () => ({
  type: types.READ_RESEARCH_FAVORITES_ARTICLES_IDS_REQUEST,
});

export const readFavoritesArticlesIdsSuccess = createSuccessActionCreator_DEPRECATED(
  types.READ_RESEARCH_FAVORITES_ARTICLES_IDS_SUCCESS
);

/* add article to favorites */
export const addArticleToFavoritesRequest = id => ({
  type: types.ADD_ARTICLE_TO_FAVORITES_REQUEST,
  payload: {
    id,
  },
});

export const addArticleToFavoritesSuccess = favorites => ({
  type: types.ADD_ARTICLE_TO_FAVORITES_SUCCESS,
  payload: {
    favorites,
  },
});

export const addArticleToFavoritesFailure = error => ({
  type: types.ADD_ARTICLE_TO_FAVORITES_FAILURE,
  payload: {
    error,
  },
});

/* remove article from favorites */
export const removeArticleFromFavoritesRequest = id => ({
  type: types.REMOVE_ARTICLE_FROM_FAVORITES_REQUEST,
  payload: {
    id,
  },
});

export const removeArticleFromFavoritesSuccess = removedItem => ({
  type: types.REMOVE_ARTICLE_FROM_FAVORITES_SUCCESS,
  payload: {
    removedItem,
  },
});

export const removeArticleFromFavoritesFailure = error => ({
  type: types.REMOVE_ARTICLE_FROM_FAVORITES_FAILURE,
  payload: {
    error,
  },
});

/* clear articles state */
export const setEmptyResearchData = () => ({
  type: types.CLEAR_RESEARCH_ARTICLES,
});
