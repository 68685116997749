import { APIv2 } from 'api/instance';

export const createSurgeonPin = (data: { password: string; pin: string }) => {
  return APIv2.post(`user/me/surgeon-pin`, data);
};

export const resetSurgeonPin = (data: { password: string }) => {
  return APIv2.delete(`user/me/surgeon-pin`, { data });
};

export const viewSurgeonPin = (data: { password: string }) => {
  return APIv2.post(`auth/surgeon-pin`, data);
};
