import {
  getPaginationMeta,
  hasPaginationMeta,
} from 'api/interceptors/response-interceptor';
import { NormalizedResponse } from 'common-types/normalize';
import { Schema } from 'normalizr';
import { normalize } from 'utils/normalize';

interface normalizeResponse {
  (schema: Schema): (response: { [index: string]: any }) => NormalizedResponse;
}

export const normalizeResponse: normalizeResponse = (schema) => (response) => {
  const normalizedResponse = normalize(response, schema);
  if (!hasPaginationMeta(response)) {
    return normalizedResponse;
  }

  return {
    ...normalizedResponse,
    meta: getPaginationMeta(response),
  };
};
