import { ENTER } from 'app-constants';
import { ALLOWED_IMAGE_TYPES } from 'app-constants/files';
import { kebab } from 'case';
import cn from 'classnames';
import {
  LibraryCategories,
  OperationStep as OperationStepType,
  OperationStepEstimable,
  OperationStepInput,
} from 'common-types/library';
import { IncidentIcon, CheckIcon, RiskIcon } from 'components/Icon';
import withT from 'containers/withT';
import {
  withAttachmentsUpload,
  InjectedProps,
} from 'containers/withAttachmentsUpload';
import React, { ClipboardEvent, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { DEBRIEFING_HISTORY } from 'scenes/routes.enum';
import { withParams } from 'utils';
import { TranslationFn } from 'utils/translation-types';
import { calculateOpStepCharLimits } from './calculateOpStepCharLimits';
import { Consumer } from './context';
import { DescriptionInput } from './DescriptionInput';
import { NameInput } from './NameInput';
import classes from './OperationStep.module.scss';
import { OperationStepAttachmentsForm } from './OperationStepAttachments/OperationStepAttachmentsForm';
import { OperationStepAttachmentsList } from './OperationStepAttachments/OperationStepAttachmentsList';
import { OperationStepMenu } from './OperationStepMenu';
import StepEstimation from './StepEstimation';
import { Attachment } from 'common-types/attachments';
import { DataTag } from 'components/DataTag';
import { Row, Col } from 'components/Grid';
import { ListHeader, List, ListItem } from 'components/List';

type Element = OperationStepEstimable | OperationStepType;

export interface OperationStepProps extends InjectedProps {
  element: Element;
  readonly: boolean;
  t: TranslationFn;

  onAddToLibrary: (element: Element) => void;
  onAttachmentRemove: (attachmentId: number) => void;
  onAttachmentRequest: () => void;
  onAttachmentsChange: (attachments: number[]) => void;
  onDelete: (element: Element) => void;
  onChange: (element: OperationStepType, update: OperationStepInput) => void;
  onTriggerGallery: (attachmentId: number) => void;
}

const hasClipboardImages = (clipboardFiles) => {
  for (let i = 0; i < clipboardFiles.length; i++) {
    let file = clipboardFiles[i];
    if ((ALLOWED_IMAGE_TYPES as string[]).includes(file.type)) return true;
  }
  return false;
};

const isPasteFilesEvent: (event: ClipboardEvent) => boolean = (event) => {
  let hasImages: boolean = false;
  const hasRichText =
    event?.clipboardData?.getData('text/html') &&
    event?.clipboardData?.getData('text/plain');

  if (event?.clipboardData?.files) {
    hasImages = hasClipboardImages(event.clipboardData.files);
  }

  return hasImages && !hasRichText;
};

class OperationStep extends PureComponent<OperationStepProps> {
  static defaultProps = {
    readonly: false,
  };

  get placeholder() {
    const { t } = this.props;
    return this.displayField.label || t('enterDescription');
  }

  get content() {
    const { element } = this.props;
    return element[this.displayField.key];
  }

  get displayField() {
    const { element, t } = this.props;
    const isRisk = element.category === LibraryCategories.risks;

    return {
      key: isRisk ? 'mitigationAction' : 'description',
      label: isRisk ? t('enterMitigationAction') : t('enterDescription'),
    };
  }

  handleNameChange = (event) => {
    const {
      target: { value },
    } = event;
    const headerValue = value.trim();
    if (!headerValue) return;
    this.handleSubmitItem('name', headerValue);
  };

  handleDescriptionChange = (event) => {
    const { value } = event.target;
    this.handleSubmitItem(this.displayField.key, value.trim());
  };

  handleSubmitItem = (propName: string, value: string) => {
    const { element, onChange } = this.props;
    if (element?.[propName] === value) return;
    onChange(element, { [propName]: value });
  };

  handleEstimationChange = (estimatedTime: number) => {
    const { element, onChange } = this.props;
    const hasChanged =
      (element as OperationStepEstimable)?.estimatedTime !== estimatedTime;
    if (!hasChanged) return;

    onChange(element, { estimatedTime });
  };

  handleDelete = () => {
    const { element, onDelete } = this.props;
    onDelete(element);
  };

  handleOpenGallery = (attachmentId: number) => {
    const { onTriggerGallery } = this.props;
    if (!attachmentId) return;
    onTriggerGallery(attachmentId);
  };

  handlePasteFile = async (event: ClipboardEvent<HTMLElement>) => {
    if (!isPasteFilesEvent(event)) return;
    event.preventDefault();
    const { onUpload, closeModal, onAttachmentsChange } = this.props;
    if (!onUpload || !closeModal) return;
    try {
      const files = [...event.clipboardData.files];
      const attachments = (await onUpload(files)).filter(
        Boolean
      ) as Attachment[];
      const filesIds = attachments.map((a) => a.id);
      closeModal();
      onAttachmentsChange(filesIds);
    } catch (error) {
      closeModal();
    }
  };

  handleDescriptionKeyDown = (event) => {
    if (event.keyCode !== ENTER || !(event.metaKey || event.ctrlKey)) return;

    // Submit edit on `cmd/ctrl + Enter`
    this.handleDescriptionChange(event);
  };

  handleAddElementToLibrary = () => {
    const { element, onAddToLibrary } = this.props;
    onAddToLibrary(element);
  };

  handleAttachmentsRemove = () => {
    const { onChange, element } = this.props;
    onChange(element, { attachments: [] });
  };

  handleElementDelete = () => {
    const { element, onDelete } = this.props;
    onDelete(element);
  };

  get categoryIcon() {
    const { element } = this.props;

    switch (element?.category) {
      case LibraryCategories.checks:
        return <CheckIcon />;
      case LibraryCategories.patientRisks:
      case LibraryCategories.risks:
        return <RiskIcon />;
      default:
        return null;
    }
  }

  get limits() {
    const {
      element: { name, attachments },
      readonly,
    } = this.props;

    if (readonly) return null;

    const description = this.content;
    const hasAttachments = Boolean(attachments?.length);
    return calculateOpStepCharLimits({ name, description, hasAttachments });
  }

  renderName(operationId?: number) {
    const { element, readonly, t } = this.props;
    const icon = element.incident ? <IncidentIcon /> : this.categoryIcon;
    const name = readonly ? (
      <DataTag component="strong" propKey="name">
        {element.incident ? (
          <Link
            className="text-error"
            title={t('seeReportedIncident')}
            to={{
              pathname: withParams(DEBRIEFING_HISTORY, { operationId }),
              hash: String(element.incident.id),
            }}
          >
            {element.name}
          </Link>
        ) : (
          element.name
        )}
      </DataTag>
    ) : (
      <NameInput
        className={classes.editableInput}
        defaultValue={element.name}
        charsLimit={this.limits?.nameCharsLimit}
        charsPerLineLimit={this.limits?.nameCharsPerLineLimit}
        onBlur={this.handleNameChange}
      />
    );

    return (
      <Row alignItems="center">
        {Boolean(icon) && (
          <Col auto className={classes.iconCol}>
            {icon}
          </Col>
        )}
        <Col className={classes.elementNameCol}>{name}</Col>
      </Row>
    );
  }

  render() {
    const { element, readonly, onAttachmentRemove, onAttachmentRequest, t } =
      this.props;

    if (!element) return null;

    const shouldShowEstimation = [
      LibraryCategories.procedures,
      LibraryCategories.checks,
    ].includes(element.category);

    const stepMenu = readonly ? null : (
      <OperationStepMenu
        attachmentsCount={element.attachments?.length ?? 0}
        isElementUpdated={Boolean(element.edited)}
        onAddElementToLibrary={this.handleAddElementToLibrary}
        onAttachImage={onAttachmentRequest}
        onAttachmentsRemove={this.handleAttachmentsRemove}
        onElementDelete={this.handleElementDelete}
      />
    );

    return (
      <Consumer>
        {({ operationId }) => (
          <div className={classes.container}>
            <List
              id={`operation-element-${element.uuid}`}
              className={cn('OperationStep', classes.customList)}
              onPaste={this.handlePasteFile}
              data-category={kebab(element.category)}
              data-library-element=""
            >
              <div className={classes.listWrapper}>
                <ListHeader
                  addonVisible
                  addon={stepMenu}
                  contentClassName="flex align-center fullWidth"
                  className={classes.listHeader}
                  hoverable={!readonly}
                  id={element.uuid}
                >
                  <div>
                    {this.renderName(operationId)}
                    {shouldShowEstimation && (
                      <div className="mt-1 ml-05">
                        <StepEstimation
                          actualTime={
                            (element as OperationStepEstimable).actualTime
                          }
                          estimatedTime={
                            (element as OperationStepEstimable).estimatedTime
                          }
                          onChange={this.handleEstimationChange}
                          readonly={readonly}
                        />
                      </div>
                    )}
                  </div>
                </ListHeader>

                {readonly ? (
                  <ListItem>{this.content}</ListItem>
                ) : (
                  <DescriptionInput
                    charsLimit={this.limits?.descriptionCharsLimit}
                    charsPerLineLimit={
                      this.limits?.descriptionCharsPerLineLimit
                    }
                    className={classes.listItemInput}
                    defaultValue={this.content}
                    onBlur={this.handleDescriptionChange}
                    onKeyDown={this.handleDescriptionKeyDown}
                    placeholder={this.placeholder}
                  />
                )}
              </div>

              <div className={classes.imgBlock}>
                {readonly ? (
                  <OperationStepAttachmentsList
                    attachments={element.attachments}
                    onImageClick={this.handleOpenGallery}
                  />
                ) : (
                  <OperationStepAttachmentsForm
                    id={`upload-op-element-${element.uuid}`}
                    attachments={element.attachments}
                    onRemove={onAttachmentRemove}
                    onPlaceholderClick={onAttachmentRequest}
                    onImageClick={this.handleOpenGallery}
                  />
                )}
              </div>
            </List>
            <span className={cn(classes.limitMessage, 'mt-05')}>
              <>{t('followRecommendedCharactersLimits')}</>
            </span>
          </div>
        )}
      </Consumer>
    );
  }
}

export default withAttachmentsUpload<OperationStepProps>(withT(OperationStep));
