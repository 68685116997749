import { DataTag } from 'components/DataTag';
import { TranslatableString } from 'components/TranslatableString';
import { Text } from 'components/Typography/Text';
import React, { ComponentType } from 'react';

interface Props {
  category: string;
  company?: string | null;
  name: string;
  serialNumber?: string | null;
  size?: string | null;
}

export const ElementInfo: ComponentType<Props> = ({
  category,
  size,
  company,
  name,
}) => {
  return (
    <>
      <span className="mr-1">
        <TranslatableString
          string={name}
          render={(name: string | null) => (
            <DataTag propKey={{ name: null, [category]: null }}>{name}</DataTag>
          )}
        />
      </span>
      {Boolean(size) && (
        <Text
          component="span"
          className="mr-05 text-light text-block-inline"
          variant="paragraph-sm"
        >
          {size}
        </Text>
      )}
      {Boolean(company) && (
        <Text
          component="span"
          className="text-light text-block-inline"
          variant="paragraph-sm"
        >
          {company}
        </Text>
      )}
    </>
  );
};
