import React, { Component } from 'react';
import { func, objectOf, string } from 'prop-types';
import findKey from 'lodash/findKey';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import cn from 'classnames';

import { LoaderBadge, Icon } from 'components';
import { is } from 'utils';
import { getActionRequestType } from 'store/utils';
import { REQUEST_STATES, REQUEST_TYPES } from 'app-constants';
import { createAsyncActionStateSelector } from 'store/modules/api-requests';

import SmartLoaderLabel from './SmartLoaderLabel';
import styles from './SmartLoaderBadge.module.scss';
import computeLoaderState from './compute-loader-state';

const loadingDoneIcon = (
  <Icon
    name="check-circle"
    className={cn('text-success', styles.successIcon)}
  />
);

const findKeyByStrValue = (obj, value) => findKey(obj, is(value));

class SmartLoaderBadge extends Component {
  static _propTypes = {
    requestsStates: objectOf(string),
    t: func,
  };

  static defaultProps = {
    requestsStates: {},
  };

  state = {
    /**
     * This rule fails to recognize `activeRequest` usage at
     * `getDerivedStateFromProps()`
     */
    // eslint-disable-next-line react/no-unused-state
    activeRequest: null,
    requestType: null,
    requestState: REQUEST_STATES.NOT_STARTED,
  };

  static getDerivedStateFromProps({ requestsStates }, state) {
    const isRequesting = findKeyByStrValue(
      requestsStates,
      REQUEST_STATES.REQUEST
    );

    if (!isRequesting && !state.activeRequest) return null;

    if (isRequesting) {
      return {
        activeRequest: isRequesting,
        requestType: getActionRequestType(isRequesting),
        requestState: REQUEST_STATES.REQUEST,
      };
    }

    const activeRequestOutcome = requestsStates[state.activeRequest];
    return {
      activeRequest: null,
      requestState: activeRequestOutcome,
    };
  }

  shouldComponentUpdate(_, nextState) {
    const { requestType, requestState } = this.state;
    const loadTypes = [REQUEST_TYPES.LOAD, REQUEST_TYPES.READ];
    const fromNotLoadToLoad =
      !loadTypes.includes(requestType) &&
      loadTypes.includes(nextState.requestType);
    const toFailure =
      requestState !== REQUEST_STATES.FAILURE &&
      nextState.requestState === REQUEST_STATES.FAILURE;

    return (
      toFailure ||
      !fromNotLoadToLoad ||
      requestType !== nextState.requestType ||
      requestState !== nextState.requestState
    );
  }

  get show() {
    const { requestState, requestType } = this.state;

    return (
      requestState === REQUEST_STATES.REQUEST &&
      requestType !== REQUEST_TYPES.SEARCH
    );
  }

  get icon() {
    const { requestState } = this.state;
    if (requestState !== REQUEST_STATES.SUCCESS) return null;

    return loadingDoneIcon;
  }

  get hideDelay() {
    const { requestState } = this.state;
    const shouldHideImmediately = requestState === REQUEST_STATES.FAILURE;

    if (shouldHideImmediately) {
      return 0;
    }

    return 1000;
  }

  render() {
    const { requestState, requestType } = this.state;
    return (
      <LoaderBadge
        className={cn(
          'StatefulLoader',
          computeLoaderState(requestState, requestType)
        )}
        hideDelay={this.hideDelay}
        show={this.show}
        label={
          <SmartLoaderLabel
            requestState={requestState}
            requestType={requestType}
          />
        }
        icon={this.icon || undefined}
      />
    );
  }
}

const requestsStatesSelector = (state, props) => {
  const { actionsToWatch = '*', namespaces } = props;
  const statesSelector = createAsyncActionStateSelector(
    actionsToWatch,
    namespaces
  );
  const requestsStates = statesSelector(state);

  const newObj = {};

  for (const key in requestsStates) {
    if (!key.toUpperCase().includes('AUTOSAVE')) {
      newObj[key] = requestsStates[key];
    }
  }

  return newObj;
};

const mapStateToProps = createStructuredSelector({
  requestsStates: requestsStatesSelector,
});
const withConnect = connect(mapStateToProps);

export default withConnect(SmartLoaderBadge);
