import React from 'react';
import { shape, func, bool } from 'prop-types';
import { categoryShape } from 'shared-prop-types';
import noop from 'lodash/noop';
import { isCategoryHasTemplates } from './isCategoryHasTemplates';
import { MenuListItem, TranslatableString } from 'components';

export const CategoryItem = ({
  category,
  onClick = noop,
  hideEmpty = false,
  ...menuListItemProps
}) => {
  if (!category || (hideEmpty && !isCategoryHasTemplates(category))) {
    return null;
  }

  return (
    <TranslatableString string={category.name}>
      {(categoryName) => (
        <MenuListItem
          isDefault={!category.parentId}
          onClick={(e) => {
            onClick(category);
            e.stopPropagation();
            e.preventDefault();
          }}
          label={categoryName}
          {...menuListItemProps}
        />
      )}
    </TranslatableString>
  );
};

CategoryItem._propTypes = {
  category: shape(categoryShape),
  onClick: func,
  hideEmpty: bool,
};

CategoryItem.displayName = 'CategoryItem';
