import React, { Component, StrictMode, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';

import cssVars from 'css-vars-ponyfill';

import { store, history } from 'store';
import { getEnv } from 'utils';

import i18n from 'translations/i18n-instance';

import { ROOT_CRASHED } from 'scenes/routes.enum';
import ErrorScreen from 'scenes/ErrorScreen';

import Base from './Base';

import './styles/global.scss';

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: getEnv(),
    enabledReleaseStages: ['staging', 'production'],
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = process.env.REACT_APP_BUGSNAG_API_KEY
  ? (
      Bugsnag.getPlugin('react') as BugsnagPluginReactResult
    ).createErrorBoundary(React)
  : Fragment;

class Root extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    return (
      <StrictMode>
        <ErrorBoundary>
          <ReduxProvider store={store}>
            <ConnectedRouter history={history}>
              <I18nextProvider i18n={i18n}>
                <Switch>
                  <Route path={ROOT_CRASHED} component={ErrorScreen} />
                  <Base />
                </Switch>
              </I18nextProvider>
            </ConnectedRouter>
          </ReduxProvider>
        </ErrorBoundary>
      </StrictMode>
    );
  }
}

export default Root;
