import cn from 'classnames';
import React, { ComponentType, HTMLAttributes } from 'react';
import { Icon } from '../Icon';

export const IncidentIcon: ComponentType<HTMLAttributes<HTMLElement>> = ({
  className,
  ...props
}) => {
  return (
    <Icon name="incidents" className={cn('text-error', className)} {...props} />
  );
};

IncidentIcon.displayName = 'IncidentIcon';
