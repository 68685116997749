import cn from 'classnames';
import { Attachment as AttachmentT } from 'common-types/attachments';
import { AttachmentPic } from './../AttachmentPic/AttachmentPic';
import React, { ComponentType } from 'react';
import { onSpace } from 'utils/key-events';
import classes from './Attachment.module.scss';
import { CornerBadge } from './CornerBadge';
import { useAttachmentItemActions } from './useAttachmentItemActions';
import { IconButton } from 'components/Button';

interface Props {
  description?: AttachmentT['description'];
  fileUrl: AttachmentT['fileUrl'];
  id: AttachmentT['id'];
  mimeType: AttachmentT['mimeType'];
  name: AttachmentT['name'];

  className?: string;
  isSelected?: boolean;
  selectedIndex?: number;

  onGalleryRequest?: (id: AttachmentT['id']) => void;
  onRemove?: (id: AttachmentT['id']) => void;
  onSelect?: (id: AttachmentT['id'], fileUrl: string) => void;
}

const ActiveAttachment = ({ actionFn, children }) => {
  return (
    <span
      role="button"
      className={classes.activeAttachment}
      onKeyPress={onSpace(actionFn)}
      onClick={actionFn}
    >
      {children}
    </span>
  );
};

export const Attachment: ComponentType<Props> = ({
  className,
  description = '',
  fileUrl = '',
  id,
  name = '',
  mimeType,
  isSelected = false,
  onGalleryRequest,
  onRemove,
  onSelect,
  selectedIndex,
}) => {
  const {
    isActionable,
    handleAttachmentAction,
    handleRemove,
    handleGalleryRequest,
  } = useAttachmentItemActions({
    id,
    fileUrl,
    name,
    onGalleryRequest,
    onRemove,
    onSelect,
    isSelected,
  });
  const isSelectable: boolean = typeof onSelect === 'function';
  const isShowable: boolean = typeof onGalleryRequest === 'function';
  const showViewButton: boolean = isSelectable && isShowable;

  const pic = (
    <AttachmentPic
      className={cn({
        [classes.selected]: isSelected,
      })}
      fileUrl={fileUrl}
      name={name}
      mimeType={mimeType}
      description={description || ''}
    />
  );

  return (
    <div className={cn(className, classes.container)}>
      {isActionable ? (
        <ActiveAttachment actionFn={handleAttachmentAction}>
          {pic}
        </ActiveAttachment>
      ) : (
        pic
      )}

      {Boolean(selectedIndex && isSelected) && (
        <CornerBadge className={classes.selectedLabel}>
          {selectedIndex}
        </CornerBadge>
      )}
      {showViewButton && (
        <IconButton
          className={classes.viewButton}
          icon="search"
          keepBackground
          onClick={handleGalleryRequest}
        />
      )}
      {Boolean(onRemove) && (
        <IconButton
          className={classes.removeButton}
          onClick={handleRemove}
          icon="close"
        />
      )}
    </div>
  );
};
