import { BACKSPACE, DELETE } from 'app-constants';
import classnames from 'classnames';
import { Option } from 'common-types/common';
import Input from 'components/Form/Input';
import { Icon } from 'components/Icon';
import React, { FC, ReactNode, useRef } from 'react';
import { makeKeyEventHandler } from 'utils/key-events';
import { SELECT_MODES, SELECT_MODES_TYPE } from '../constants';
import styles from '../Select.module.scss';

const onResetKey = makeKeyEventHandler([DELETE, BACKSPACE]);

interface SelectInputProps {
  className: string;
  clearSelection?: (...args) => any;
  getInputProps: (...args) => any;
  id: string;
  isOpen: boolean;
  mode: SELECT_MODES_TYPE;
  openMenu: () => void;
  readOnly?: boolean;
  selectedItem?: Option | null;
  selectInputAppend?: ReactNode | null;
  selectInputPrepend?: ReactNode | null;
  setState: (...args) => void;
  onBlur?: () => void | null;
  allowReset?: boolean;
}

export const SelectInput: FC<SelectInputProps> = ({
  className,
  id,
  isOpen,
  mode,
  openMenu,
  setState,
  children,
  clearSelection,
  getInputProps,
  onBlur = null,
  readOnly,
  selectInputAppend = null,
  selectInputPrepend = null,
  selectedItem = null,
  allowReset = true,
  ...props
}) => {
  const inputRef = useRef<any>();

  const inputProps = () => {
    const hasCurrentItem = !!selectedItem;
    const isReadonly = Boolean(
      (hasCurrentItem && mode !== SELECT_MODES.select) || readOnly
    );

    const downshiftInputProps = getInputProps({
      id,
      className: classnames(className, styles.input, {
        [styles.hasAppend]: !!append(),
        [styles.hasPrepend]: !!selectInputPrepend,
        [styles['menu-open']]: isOpen,
        [styles.inputFilled]: hasCurrentItem,
      }),
      readOnly: isReadonly,
      onFocus: openMenu,
      onBlur,
      ...props,
    });

    return downshiftInputProps || {};
  };

  const append = () => {
    const showSelectIcon = !readOnly && mode === SELECT_MODES.select;
    const showResetButton =
      !readOnly &&
      allowReset &&
      selectedItem &&
      Boolean([SELECT_MODES.suggestions, SELECT_MODES.search].includes(mode));

    if (!selectInputAppend && !showSelectIcon && !showResetButton) return null;

    return (
      <>
        {showResetButton && (
          <button
            type="button"
            className={styles.clearButton}
            onClick={handleClearSelection}
          >
            <Icon name="validation-error" />
          </button>
        )}
        {showSelectIcon && (
          <Icon
            className={styles.selectIcon}
            name="chevron-down"
            onClick={focusInput}
          />
        )}
        {selectInputAppend}
      </>
    );
  };

  const handleClearSelection = () => {
    if (!selectedItem || !clearSelection) return;
    clearSelection(() => {
      focusInput();
    });
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputFocus = () => {
    if (readOnly) return;
    if (mode === SELECT_MODES.select) {
      setState({ inputValue: '' });
    }
    openMenu();
  };

  const handleKeyUp = onResetKey(handleClearSelection);

  return (
    <Input
      {...inputProps()}
      append={append()}
      innerRef={inputRef}
      onFocus={handleInputFocus}
      onKeyPress={readOnly ? undefined : inputProps().onKeyPress}
      onKeyDown={readOnly ? undefined : inputProps().onKeyDown}
      onKeyUp={handleKeyUp}
      prepend={selectInputPrepend}
    />
  );
};
