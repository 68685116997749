import namespace from './namespace';

/* all */
export const READ_RESEARCH_ARTICLES_REQUEST = `${namespace}READ_RESEARCH_ARTICLES_REQUEST`;
export const READ_RESEARCH_ARTICLES_SUCCESS = `${namespace}READ_RESEARCH_ARTICLES_SUCCESS`;
export const READ_RESEARCH_ARTICLES_FAILURE = `${namespace}READ_RESEARCH_ARTICLES_FAILURE`;

/* favorites */
export const READ_RESEARCH_FAVORITES_ARTICLES_REQUEST = `${namespace}READ_RESEARCH_FAVORITES_ARTICLES_REQUEST`;
export const READ_RESEARCH_FAVORITES_ARTICLES_SUCCESS = `${namespace}READ_RESEARCH_FAVORITES_ARTICLES_SUCCESS`;
export const READ_RESEARCH_FAVORITES_ARTICLES_FAILURE = `${namespace}READ_RESEARCH_FAVORITES_ARTICLES_FAILURE`;

/* favorite articles ids */
export const READ_RESEARCH_FAVORITES_ARTICLES_IDS_REQUEST = `${namespace}READ_RESEARCH_FAVORITES_ARTICLES_IDS_REQUEST`;
export const READ_RESEARCH_FAVORITES_ARTICLES_IDS_SUCCESS = `${namespace}READ_RESEARCH_FAVORITES_ARTICLES_IDS_SUCCESS`;

/* add article to favorites */
export const ADD_ARTICLE_TO_FAVORITES_REQUEST = `${namespace}ADD_ARTICLE_TO_FAVORITES_REQUEST`;
export const ADD_ARTICLE_TO_FAVORITES_SUCCESS = `${namespace}ADD_ARTICLE_TO_FAVORITES_SUCCESS`;
export const ADD_ARTICLE_TO_FAVORITES_FAILURE = `${namespace}ADD_ARTICLE_TO_FAVORITES_FAILURE`;

/* remove article from favorites */
export const REMOVE_ARTICLE_FROM_FAVORITES_REQUEST = `${namespace}REMOVE_ARTICLE_FROM_FAVORITES_REQUEST`;
export const REMOVE_ARTICLE_FROM_FAVORITES_SUCCESS = `${namespace}REMOVE_ARTICLE_FROM_FAVORITES_SUCCESS`;
export const REMOVE_ARTICLE_FROM_FAVORITES_FAILURE = `${namespace}REMOVE_ARTICLE_FROM_FAVORITES_FAILURE`;

/* clear store */
export const CLEAR_RESEARCH_ARTICLES = `${namespace}CLEAR_RESEARCH_ARTICLES`;
