import { MenuItem, TranslatableString } from 'components';
import { arrayOf, bool, func, shape } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { categoryShape } from 'shared-prop-types';
import { CategoryItem } from './CategoryItem';

export const TopCategoryItem = ({
  defaultCategory,
  hideEmpty,
  onCategorySelect,
  selectedCategory,
  topLevelCategories,
}) => {
  const { t } = useTranslation();
  const activeCategory = selectedCategory || defaultCategory;
  const label = activeCategory?.name ? (
    <TranslatableString string={activeCategory.name} />
  ) : (
    t('allCategories')
  );

  return (
    <MenuItem
      closeSubmenuOnClick
      onClick={() => onCategorySelect(activeCategory)}
      label={label}
      variant="primary"
    >
      {topLevelCategories.map((category) => {
        if (activeCategory?.id && activeCategory?.id === category.id) {
          return null;
        }

        return (
          <CategoryItem
            key={category.id}
            category={category}
            onClick={onCategorySelect}
            hideEmpty={hideEmpty}
          />
        );
      })}
    </MenuItem>
  );
};

TopCategoryItem._propTypes = {
  categories: arrayOf(shape(categoryShape)),
  categoriesSelected: arrayOf(shape(categoryShape)),
  defaultCategory: shape(categoryShape),
  hideEmpty: bool,
  onCategorySelect: func,
  selectedCategory: shape(categoryShape),
  topLevelCategories: arrayOf(shape(categoryShape)),
};

TopCategoryItem.displayName = 'TopCategoryItem';
