import { FEMALE, MALE, NOT_DEFINED } from 'app-constants/sex';
import {
  arrayOf,
  bool,
  number,
  oneOf,
  shape,
  string,
  object,
} from 'prop-types';
import { addressTypes, errorTypes, idTypes } from './common';

export const sexTypes = [NOT_DEFINED, MALE, FEMALE];

export const rolesTypes = {
  surgeon: bool,
  admin: bool,
};

export const userSettingsTypes = {
  id: idTypes,
  name: string,
  phone: string,
  addressId: number,
  address: oneOf([shape(addressTypes), {}]),
  isAddressLoaded: bool,
  error: shape(errorTypes),
};

export const userTypes = {
  id: idTypes,
  givenName: string,
  surname: string,
  sex: oneOf(sexTypes),
  title: string,
  email: string,
  phone: string,
  isDeleted: bool,
  imageUrl: string,
  signatureUrl: string,
  createdBy: idTypes,
  updatedBy: idTypes,
  supervisor: string,
  institute: shape({
    id: idTypes,
    name: string,
  }),
  department: shape({
    id: idTypes,
    name: string,
  }),
  settings: shape(userSettingsTypes),
  roles: shape(rolesTypes),
  contacts: arrayOf(object),
  accessToken: string,
  refreshToken: string,
  changeEmail: shape({
    token: string,
    expire: string,
    email: string,
  }),
};

export const contactTypes = {
  value: string,
};
