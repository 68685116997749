import React, { FC, useEffect, useRef, useState } from 'react';

import { Loader } from 'components/Loader';
import { NotificationBadge } from 'components/NotificationBadge';

export interface LoaderBadgeProps {
  hideDelay?: number;
  show: boolean;
  showSpinner?: boolean;
  className?: string;
}

export const LoaderBadge: FC<LoaderBadgeProps> = ({
  hideDelay = 500,
  show,
  showSpinner = true,
  className,
  ...restProps
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => resetTimeout();
  }, []);

  useEffect(() => {
    const scheduleHide = () => {
      resetTimeout();

      if (!hideDelay) {
        setIsShown(false);
      } else {
        timeoutRef.current = setTimeout(() => setIsShown(false), hideDelay);
      }
    };

    if (show && !isShown) {
      setIsShown(true);
      scheduleHide();
    }
  }, [show, isShown, hideDelay]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <NotificationBadge show={isShown}>
      <Loader showSpinner={showSpinner} className={className} {...restProps} />
    </NotificationBadge>
  );
};
