import { searchItems } from 'api/v1';
import { i18n } from 'translations/i18n-instance';

import * as actions from './actions';

const searchLibraryItems = (query, categories) => (dispatch) => {
  const lang = i18n.language;
  dispatch(actions.searchRequest(query, categories, lang));

  return searchItems(query, categories, lang).then(
    (response) => {
      dispatch(actions.searchSuccess(response));
    },
    (error) => {
      dispatch(actions.searchFailure(error));
    }
  );
};

export { searchLibraryItems };
