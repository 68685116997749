import React, { FC } from 'react';
import { FormInput } from 'components/Form/FormInput';
import { useTranslation } from 'react-i18next';
import { connect, FormikContextType } from 'formik';

interface InstrumentsFormFormikValueProps {
  company: string;
  serialNumber: string;
  size: string;
}

const InstrumentsForm: FC<FormikContextType<InstrumentsFormFormikValueProps>> =
  ({ values, errors, handleChange }) => {
    const { t } = useTranslation();

    return (
      <>
        <FormInput
          label={t('Company')}
          name="company"
          placeholder={t('enterInstrumentCompany')}
          value={values.company}
          error={errors.company}
          onChange={handleChange}
        />

        <FormInput
          label={t('Size')}
          name="size"
          placeholder={t('enterInstrumentSize')}
          value={values.size}
          error={errors.size}
          onChange={handleChange}
        />

        <FormInput
          label={t('serialNumber')}
          name="serialNumber"
          placeholder={t('enterInstrumentSerialNumber')}
          value={values.serialNumber}
          error={errors.serialNumber}
          onChange={handleChange}
        />
      </>
    );
  };

export default connect(InstrumentsForm);
