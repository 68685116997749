import { isFeatureFlagEnabled } from 'features/flags';
import i18next from 'i18next';

export const getLanguageParam = (lang) => {
  if (lang === 'original') {
    return null;
  }

  if (!isFeatureFlagEnabled('multilang')) {
    return null;
  }

  return lang || mapLanguageForDeepL[i18next.language];
};

export const generateLanguageURL = (url, lang) => {
  if (!isFeatureFlagEnabled('multilang')) {
    return url;
  }

  return lang && lang !== 'original'
    ? `${url}?lang=${getLanguageParam(lang)}`
    : url;
};

export const mapLanguageForDeepL = {
  en: 'en-GB',
  de: 'de',
  fr: 'fr',
  it: 'it',
};

export const DEEPL_LANG_OPTIONS = [
  { label: 'English', value: 'en-GB' },
  { label: 'Deutsch', value: 'de' },
  { label: 'French', value: 'fr' },
  { label: 'Italian', value: 'it' },
  { label: 'Spanish', value: 'es' },
  { label: 'Portuguese', value: 'pt-PT' },
  { label: 'Portuguese (Brazilian)', value: 'pt-BR' },
  { label: 'Dutch', value: 'NL' },
  // { label: 'Bulgarian', value: 'BG' },
  // { label: 'Czech', value: 'CS' },
  // { label: 'Danish', value: 'DA' },
  // { label: 'Greek', value: 'EL' },
  // { label: 'Estonian', value: 'ET' },
  // { label: 'Finnish', value: 'FI' },
  // { label: 'Hungarian', value: 'HU' },
  // { label: 'Indonesian', value: 'ID' },
  // { label: 'Japanese', value: 'JA' },
  // { label: 'Korean', value: 'KO' },
  // { label: 'Lithuanian', value: 'LT' },
  // { label: 'Latvian', value: 'LV' },
  // { label: 'Norwegian (Bokmål)', value: 'NB' },
  // { label: 'Polish', value: 'PL' },
  // { label: 'Romanian', value: 'RO' },
  // { label: 'Russian', value: 'RU' },
  // { label: 'Slovak', value: 'SK' },
  // { label: 'Slovenian', value: 'SL' },
  // { label: 'Swedish', value: 'SV' },
  // { label: 'Turkish', value: 'TR' },
  // { label: 'Ukrainian', value: 'UK' },
  // { label: 'Chinese', value: 'ZH' },
];
