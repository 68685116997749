/* prettier-ignore */
import partial from 'lodash/partial';
import { createActionType } from 'store/utils/create-action-type';

export const namespace = 'operation';
// a - stands for action type
const a = partial(createActionType, namespace);

export const OPEN_OPERATION_PLANNING = a('OPEN_OPERATION_PLANNING');
export const CLOSE_OPERATION_PLANNING = a('CLOSE_OPERATION_PLANNING');
export const START_OPERATION_CREATION = a('START_OPERATION_CREATION');
export const FINISH_OPERATION_CREATION = a('FINISH_OPERATION_CREATION');

export const TOGGLE_LIBRARY_ITEM = a('TOGGLE_LIBRARY_ITEM');
export const TOGGLE_PATIENT_RISK = a('TOGGLE_PATIENT_RISK');
export const ADD_PATIENT_RISK = a('ADD_PATIENT_RISK');
export const REMOVE_PATIENT_RISK = a('REMOVE_PATIENT_RISK');

/* Operation */
export const CREATE_OPERATION_REQUEST = a('CREATE_OPERATION_REQUEST');
export const CREATE_OPERATION_SUCCESS = a('CREATE_OPERATION_SUCCESS');
export const CREATE_OPERATION_FAILURE = a('CREATE_OPERATION_FAILURE');

export const READ_OPERATION_REQUEST = a('READ_OPERATION_REQUEST');
export const READ_OPERATION_SUCCESS = a('READ_OPERATION_SUCCESS');
export const READ_OPERATION_FAILURE = a('READ_OPERATION_FAILURE');

export const UPDATE_OPERATION_REQUEST = a('UPDATE_OPERATION_REQUEST');
export const UPDATE_OPERATION_SUCCESS = a('UPDATE_OPERATION_SUCCESS');
export const UPDATE_OPERATION_FAILURE = a('UPDATE_OPERATION_FAILURE');

export const DELETE_OPERATION_REQUEST = a('DELETE_OPERATION_REQUEST');
export const DELETE_OPERATION_SUCCESS = a('DELETE_OPERATION_SUCCESS');
export const DELETE_OPERATION_FAILURE = a('DELETE_OPERATION_FAILURE');

export const READ_OPERATION_MATERIALS_REQUEST = a(
  'READ_OPERATION_MATERIALS_REQUEST'
);
export const READ_OPERATION_MATERIALS_SUCCESS = a(
  'READ_OPERATION_MATERIALS_SUCCESS'
);
export const READ_OPERATION_MATERIALS_FAILURE = a(
  'READ_OPERATION_MATERIALS_FAILURE'
);

export const UPDATE_OPERATION_MATERIALS_REQUEST = a(
  'UPDATE_OPERATION_MATERIALS_REQUEST'
);
export const UPDATE_OPERATION_MATERIALS_SUCCESS = a(
  'UPDATE_OPERATION_MATERIALS_SUCCESS'
);
export const UPDATE_OPERATION_MATERIALS_FAILURE = a(
  'UPDATE_OPERATION_MATERIALS_FAILURE'
);

/* Patient */
export const CREATE_PATIENT_REQUEST = a('CREATE_PATIENT_REQUEST');
export const CREATE_PATIENT_SUCCESS = a('CREATE_PATIENT_SUCCESS');
export const CREATE_PATIENT_FAILURE = a('CREATE_PATIENT_FAILURE');

export const READ_PATIENT_REQUEST = a('READ_PATIENT_REQUEST');
export const READ_PATIENT_SUCCESS = a('READ_PATIENT_SUCCESS');
export const READ_PATIENT_FAILURE = a('READ_PATIENT_FAILURE');

export const UPDATE_PATIENT_REQUEST = a('UPDATE_PATIENT_REQUEST');
export const UPDATE_PATIENT_SUCCESS = a('UPDATE_PATIENT_SUCCESS');
export const UPDATE_PATIENT_FAILURE = a('UPDATE_PATIENT_FAILURE');

/* VetPatient */
export const CREATE_VET_PATIENT_REQUEST = a('CREATE_VET_PATIENT_REQUEST');
export const CREATE_VET_PATIENT_SUCCESS = a('CREATE_VET_PATIENT_SUCCESS');
export const CREATE_VET_PATIENT_FAILURE = a('CREATE_VET_PATIENT_FAILURE');

export const READ_VET_PATIENT_REQUEST = a('READ_VET_PATIENT_REQUEST');
export const READ_VET_PATIENT_SUCCESS = a('READ_VET_PATIENT_SUCCESS');
export const READ_VET_PATIENT_FAILURE = a('READ_VET_PATIENT_FAILURE');

export const UPDATE_VET_PATIENT_REQUEST = a('UPDATE_VET_PATIENT_REQUEST');
export const UPDATE_VET_PATIENT_SUCCESS = a('UPDATE_VET_PATIENT_SUCCESS');
export const UPDATE_VET_PATIENT_FAILURE = a('UPDATE_VET_PATIENT_FAILURE');

/* Notes */
export const CREATE_OPERATION_NOTE_REQUEST = a('CREATE_OPERATION_NOTE_REQUEST');
export const CREATE_OPERATION_NOTE_SUCCESS = a('CREATE_OPERATION_NOTE_SUCCESS');
export const CREATE_OPERATION_NOTE_FAILURE = a('CREATE_OPERATION_NOTE_FAILURE');

export const UPDATE_OPERATION_NOTE_REQUEST = a('UPDATE_OPERATION_NOTE_REQUEST');
export const UPDATE_OPERATION_NOTE_SUCCESS = a('UPDATE_OPERATION_NOTE_SUCCESS');
export const UPDATE_OPERATION_NOTE_FAILURE = a('UPDATE_OPERATION_NOTE_FAILURE');

export const READ_OPERATION_NOTE_REQUEST = a('READ_OPERATION_NOTE_REQUEST');
export const READ_OPERATION_NOTE_SUCCESS = a('READ_OPERATION_NOTE_SUCCESS');
export const READ_OPERATION_NOTE_FAILURE = a('READ_OPERATION_NOTE_FAILURE');

export const DELETE_OPERATION_NOTE_REQUEST = a('DELETE_OPERATION_NOTE_REQUEST');
export const DELETE_OPERATION_NOTE_SUCCESS = a('DELETE_OPERATION_NOTE_SUCCESS');
export const DELETE_OPERATION_NOTE_FAILURE = a('DELETE_OPERATION_NOTE_FAILURE');

/* Vital info */
export const CREATE_OPERATION_VITAL_INFO_REQUEST = a(
  'CREATE_OPERATION_VITAL_INFO_REQUEST'
);
export const CREATE_OPERATION_VITAL_INFO_SUCCESS = a(
  'CREATE_OPERATION_VITAL_INFO_SUCCESS'
);
export const CREATE_OPERATION_VITAL_INFO_FAILURE = a(
  'CREATE_OPERATION_VITAL_INFO_FAILURE'
);

export const UPDATE_OPERATION_VITAL_INFO_REQUEST = a(
  'UPDATE_OPERATION_VITAL_INFO_REQUEST'
);
export const UPDATE_OPERATION_VITAL_INFO_SUCCESS = a(
  'UPDATE_OPERATION_VITAL_INFO_SUCCESS'
);
export const UPDATE_OPERATION_VITAL_INFO_FAILURE = a(
  'UPDATE_OPERATION_VITAL_INFO_FAILURE'
);

export const READ_OPERATION_VITAL_INFO_REQUEST = a(
  'READ_OPERATION_VITAL_INFO_REQUEST'
);
export const READ_OPERATION_VITAL_INFO_SUCCESS = a(
  'READ_OPERATION_VITAL_INFO_SUCCESS'
);
export const READ_OPERATION_VITAL_INFO_FAILURE = a(
  'READ_OPERATION_VITAL_INFO_FAILURE'
);

export const READ_OPERATION_PATIENT_RISKS_REQUEST = a(
  'READ_OPERATION_PATIENT_RISKS_REQUEST'
);
export const READ_OPERATION_PATIENT_RISKS_SUCCESS = a(
  'READ_OPERATION_PATIENT_RISKS_SUCCESS'
);
export const READ_OPERATION_PATIENT_RISKS_FAILURE = a(
  'READ_OPERATION_PATIENT_RISKS_FAILURE'
);

export const UPDATE_OPERATION_PATIENT_RISKS_REQUEST = a(
  'UPDATE_OPERATION_PATIENT_RISKS_REQUEST'
);
export const UPDATE_OPERATION_PATIENT_RISKS_SUCCESS = a(
  'UPDATE_OPERATION_PATIENT_RISKS_SUCCESS'
);
export const UPDATE_OPERATION_PATIENT_RISKS_FAILURE = a(
  'UPDATE_OPERATION_PATIENT_RISKS_FAILURE'
);

export const READ_OPERATION_DEBRIEFING_REQUEST = a(
  'READ_OPERATION_DEBRIEFING_REQUEST'
);
export const READ_OPERATION_DEBRIEFING_SUCCESS = a(
  'READ_OPERATION_DEBRIEFING_SUCCESS'
);
export const READ_OPERATION_DEBRIEFING_FAILURE = a(
  'READ_OPERATION_DEBRIEFING_FAILURE'
);

export const APPROVE_OPERATION_REQUEST = a('APPROVE_OPERATION_REQUEST');
export const APPROVE_OPERATION_SUCCESS = a('APPROVE_OPERATION_SUCCESS');
export const APPROVE_OPERATION_FAILURE = a('APPROVE_OPERATION_FAILURE');

export const READ_DIAGNOSIS_REQUEST = a('READ_DIAGNOSIS_REQUEST');
export const READ_DIAGNOSIS_SUCCESS = a('READ_DIAGNOSIS_SUCCESS');
export const READ_DIAGNOSIS_FAILURE = a('READ_DIAGNOSIS_FAILURE');

export const READ_OPERATION_ELEMENTS_REQUEST = a(
  'READ_OPERATION_ELEMENTS_REQUEST'
);
export const READ_OPERATION_ELEMENTS_SUCCESS = a(
  'READ_OPERATION_ELEMENTS_SUCCESS'
);
export const READ_OPERATION_ELEMENTS_FAILURE = a(
  'READ_OPERATION_ELEMENTS_FAILURE'
);

export const UPDATE_OPERATION_ELEMENTS_REQUEST = a(
  'UPDATE_OPERATION_ELEMENTS_REQUEST'
);
export const UPDATE_OPERATION_ELEMENTS_SUCCESS = a(
  'UPDATE_OPERATION_ELEMENTS_SUCCESS'
);
export const UPDATE_OPERATION_ELEMENTS_FAILURE = a(
  'UPDATE_OPERATION_ELEMENTS_FAILURE'
);
export const UPDATE_OPERATION_ELEMENT_REQUEST = a(
  'UPDATE_OPERATION_ELEMENT_REQUEST'
);
export const UPDATE_OPERATION_ELEMENT_SUCCESS = a(
  'UPDATE_OPERATION_ELEMENT_SUCCESS'
);
export const UPDATE_OPERATION_ELEMENT_FAILURE = a(
  'UPDATE_OPERATION_ELEMENT_FAILURE'
);

export const ADD_OPERATION_ELEMENT_REQUEST = a('ADD_OPERATION_ELEMENT_REQUEST');
export const ADD_OPERATION_ELEMENT_SUCCESS = a('ADD_OPERATION_ELEMENT_SUCCESS');
export const ADD_OPERATION_ELEMENT_FAILURE = a('ADD_OPERATION_ELEMENT_FAILURE');

export const READ_OPERATION_ELEMENTS_ATTACHMENTS_REQUEST = a(
  'READ_OPERATION_ELEMENTS_ATTACHMENTS_REQUEST'
);
export const READ_OPERATION_ELEMENTS_ATTACHMENTS_SUCCESS = a(
  'READ_OPERATION_ELEMENTS_ATTACHMENTS_SUCCESS'
);
export const READ_OPERATION_ELEMENTS_ATTACHMENTS_FAILURE = a(
  'READ_OPERATION_ELEMENTS_ATTACHMENTS_FAILURE'
);

/* Operation steps form */
export const ADD_OPERATION_ELEMENT = a('ADD_OPERATION_ELEMENT');
export const CHANGE_OPERATION_ELEMENTS_ORDER = a(
  'CHANGE_OPERATION_ELEMENTS_ORDER'
);
export const DELETE_OPERATION_ELEMENT = a('DELETE_OPERATION_ELEMENT');
export const UPDATE_OPERATION_ELEMENT = a('UPDATE_OPERATION_ELEMENT');

/* Materials groups */
export const CREATE_OPERATION_MATERIALS_GROUP_REQUEST = a(
  'CREATE_OPERATION_MATERIALS_GROUP_REQUEST'
);
export const CREATE_OPERATION_MATERIALS_GROUP_SUCCESS = a(
  'CREATE_OPERATION_MATERIALS_GROUP_SUCCESS'
);
export const CREATE_OPERATION_MATERIALS_GROUP_FAILURE = a(
  'CREATE_OPERATION_MATERIALS_GROUP_FAILURE'
);

export const READ_OPERATION_MATERIALS_GROUPS_REQUEST = a(
  'READ_OPERATION_MATERIALS_GROUPS_REQUEST'
);
export const READ_OPERATION_MATERIALS_GROUPS_SUCCESS = a(
  'READ_OPERATION_MATERIALS_GROUPS_SUCCESS'
);
export const READ_OPERATION_MATERIALS_GROUPS_FAILURE = a(
  'READ_OPERATION_MATERIALS_GROUPS_FAILURE'
);

export const READ_OPERATION_MATERIALS_GROUP_REQUEST = a(
  'READ_OPERATION_MATERIALS_GROUPS_REQUEST'
);
export const READ_OPERATION_MATERIALS_GROUP_SUCCESS = a(
  'READ_OPERATION_MATERIALS_GROUPS_SUCCESS'
);
export const READ_OPERATION_MATERIALS_GROUP_FAILURE = a(
  'READ_OPERATION_MATERIALS_GROUPS_FAILURE'
);

export const UPDATE_OPERATION_MATERIALS_GROUP_REQUEST = a(
  'UPDATE_OPERATION_MATERIALS_GROUP_REQUEST'
);
export const UPDATE_OPERATION_MATERIALS_GROUP_SUCCESS = a(
  'UPDATE_OPERATION_MATERIALS_GROUP_SUCCESS'
);
export const UPDATE_OPERATION_MATERIALS_GROUP_FAILURE = a(
  'UPDATE_OPERATION_MATERIALS_GROUP_FAILURE'
);

export const DELETE_OPERATION_MATERIALS_GROUP_REQUEST = a(
  'DELETE_OPERATION_MATERIALS_GROUP_REQUEST'
);
export const DELETE_OPERATION_MATERIALS_GROUP_SUCCESS = a(
  'DELETE_OPERATION_MATERIALS_GROUP_SUCCESS'
);
export const DELETE_OPERATION_MATERIALS_GROUP_FAILURE = a(
  'DELETE_OPERATION_MATERIALS_GROUP_FAILURE'
);

export const ASSIGN_ASSESSMENT = a('ASSIGN_ASSESSMENT');
export const UNASSIGN_ASSESSMENT = a('UNASSIGN_ASSESSMENT');
