import React, { FC } from 'react';
import classes from '../OperationStepAttachments.module.scss';
import { OperationStepAttachment } from '../OperationStepAttachment';
import { AttachmentPlaceholder } from '../AttachmentPlaceholder';

interface Props {
  attachments: number[];
  onImageClick?: (attachmentId: number) => void;
  onPlaceholderClick?: () => void;
}

export const OperationStepAttachmentsList: FC<Props> = ({
  attachments,
  onImageClick,
  onPlaceholderClick,
}) => {
  if (!attachments?.length) {
    return (
      <div className={classes.imgPlaceholderContainer}>
        <AttachmentPlaceholder
          className={classes.imgPlaceholder}
          readonly
          onClick={onPlaceholderClick}
        />
      </div>
    );
  }

  return (
    <div className={classes.imgList}>
      {attachments.map((attachmentId) => (
        <OperationStepAttachment
          key={attachmentId}
          id={attachmentId}
          onClick={onImageClick}
        />
      ))}
    </div>
  );
};
