import cn from 'classnames';
import React, {
  AllHTMLAttributes,
  ElementType,
  FC,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styles from './OverflowText.module.scss';
import { LINE_COEFFICIENT, calculateTextHeight } from './utils';

export interface OverflowTextProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Root html element
   */
  component?: ElementType;
  /**
   * Max show lines of the text
   */
  maxLines: number;
  /**
   * Component's children
   */
  children: string;
}

/**
 * Renders selected amounts lines of the text
 */
export const OverflowText: FC<OverflowTextProps> = memo(function OverflowText({
  children,
  className,
  component: Component = 'p',
  maxLines,
  ...props
}) {
  const [overflow, setOverflow] = useState(false);

  const style = useMemo(
    () => ({
      WebkitLineClamp: maxLines,
      maxHeight: `${maxLines * (1 + LINE_COEFFICIENT)}em`,
    }),
    [maxLines]
  );

  const textRef = useRef(null);
  const fullTextRef = useRef(null);

  const { current: textNode } = textRef;
  const { current: fullTextNode } = fullTextRef;

  useEffect(() => {
    if (!textNode || !fullTextNode) return;

    const textStyle = window?.getComputedStyle?.(textNode);
    const fullTextStyle = window?.getComputedStyle?.(fullTextNode);

    const textSize = parseFloat(textStyle.fontSize);
    const acceptableDiff = textSize * LINE_COEFFICIENT;

    const clearTextHeight = calculateTextHeight(textStyle);
    const clearFullTextHeight = calculateTextHeight(fullTextStyle);

    const hasOverflow = clearFullTextHeight - clearTextHeight > acceptableDiff;
    setOverflow(hasOverflow);
  }, [textNode, fullTextNode, children]);

  return (
    <Component className={cn(styles.container, className)} {...props}>
      <span ref={textRef} className={styles.text} style={style}>
        {children}
      </span>
      <span
        ref={fullTextRef}
        className={cn(styles.fullTextPopup, { [styles.hidden]: !overflow })}
      >
        {children}
      </span>
    </Component>
  );
});
