import classnames from 'classnames';
import React, { ComponentType, forwardRef, HTMLAttributes, Ref } from 'react';
import classes from './List.module.scss';

export interface ListProps {
  dropShadow?: boolean;
}

export const List: ComponentType<ListProps & HTMLAttributes<HTMLElement>> =
  forwardRef(
    (
      { className, children, dropShadow, ...listProps },
      ref: Ref<HTMLDivElement>
    ) => (
      <div
        ref={ref}
        className={classnames(classes.list, className, {
          [classes.shadow]: dropShadow,
        })}
        {...listProps}
      >
        {children}
      </div>
    )
  );

export default List;
