import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import { createStateGetter } from 'store/utils';
import { createErrorsSelector } from '../api-requests';

export const selectCustomNotifications = createStateGetter(
  'customNotifications'
);

export const selectNotifications = createSelector(
  [createErrorsSelector(), selectCustomNotifications],
  (errors, customNotifications) => {
    const notifications = sortBy(
      [
        ...errors.map((e) => {
          e.type = 'error';
          return e;
        }),
        ...customNotifications,
      ],
      'id'
    );
    return notifications;
  }
);
