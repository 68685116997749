import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OperationEquipmentForm } from 'scenes/OperationPlanning/forms/Briefing/MaterialsForms/OperationEquipmentForm';
import { OperationMaterialsForm } from 'scenes/OperationPlanning/forms/Briefing/MaterialsForms/OperationMaterialsForm';
import { OperationTablesForm } from 'scenes/OperationPlanning/forms/Briefing/MaterialsForms/OperationTablesForm';
import {
  ATTACHMENTS_PATH,
  EQUIPMENT_PATH,
  MATERIALS_PATH,
  OPERATION_PATH,
  PATIENT_PATH,
  PATIENT_RISKS_PATH,
  TABLES_PATH,
} from 'scenes/routes.enum';
import Attachments from './Attachments';
import { OperationInfo } from './OperationInfo';
import { Patient } from './Patient';
import { PatientRisks } from './PatientRisks';
import { VetPatient } from './VetPatient';
import { isProductType } from 'utils/get-env';

interface Props extends RouteComponentProps {
  shouldIncludeAttachments?: boolean;
}

const BriefingGroup: FC<Props> = ({
  match: { path },
  shouldIncludeAttachments,
}) => {
  return (
    <Switch>
      <Route path={`${path}${OPERATION_PATH}`} component={OperationInfo} />
      <Route
        path={`${path}${PATIENT_PATH}`}
        component={isProductType('impedia') ? Patient : VetPatient}
      />
      <Route path={`${path}${PATIENT_RISKS_PATH}`} component={PatientRisks} />
      <Route
        path={`${path}${MATERIALS_PATH}`}
        component={OperationMaterialsForm}
      />
      <Route path={`${path}${TABLES_PATH}`} component={OperationTablesForm} />
      <Route
        path={`${path}${EQUIPMENT_PATH}`}
        component={OperationEquipmentForm}
      />

      {shouldIncludeAttachments && (
        <Route path={`${path}${ATTACHMENTS_PATH}`} component={Attachments} />
      )}

      <Redirect to={`${path}${OPERATION_PATH}`} />
    </Switch>
  );
};

export default BriefingGroup;
