import { UNAUTHORIZED } from 'http-status-codes';
import Bugsnag from '@bugsnag/js';
import { REFRESH_PATH } from 'app-constants/api-paths';

export default (strategies) => (axios) => (error) => {
  if (error.response) {
    const { status, config, data } = error.response;

    if (status === UNAUTHORIZED) {
      if (config.url === REFRESH_PATH) {
        throw new Error(
          `Refresh token request responded with 401.
          Check if 'REACT_APP_API_URL' value is valid`
        );
      }

      return strategies.handleUnauthorizedRequest(axios, config);
    }

    // For other types of errors pass error down to handle within call context
    throw data;
  }

  Bugsnag.notify(error);
  throw error;
};
