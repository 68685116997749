import * as APIv1 from 'api/v1';
import * as actions from 'store/modules/operation-planning/actions';
import { makeThunk } from 'store/utils';
import i18n from 'translations/i18n-instance';

export const createDiagnosisNotes = makeThunk(
  APIv1.createDiagnosisNotes,
  {
    request: actions.createNoteRequest,
    success: actions.createNoteSuccess,
    failure: actions.createNoteFailure,
  },
  () => i18n.t('errors:failedToCreateDiagnosisNotes')
);

export const updateDiagnosisNotes = makeThunk(
  APIv1.updateDiagnosisNotes,
  {
    request: actions.updateNoteRequest,
    success: actions.updateNoteSuccess,
    failure: actions.updateNoteFailure,
  },
  () => i18n.t('errors:failedToUpdateDiagnosisNotes')
);

export const deleteDiagnosisNotes = makeThunk(
  APIv1.deleteDiagnosisNotes,
  {
    request: actions.deleteNoteRequest,
    success: actions.deleteNoteSuccess,
    failure: actions.deleteNoteFailure,
  },
  (id) => `${i18n.t('errors:failedToDeleteDiagnosisNotes')} (id: ${id})`
);

export const saveDiagnosisNotes =
  (operationId, notes, hasNotes) => async (dispatch) => {
    if (!notes) {
      if (!hasNotes) return;
      return dispatch(deleteDiagnosisNotes(operationId));
    }

    if (notes && !hasNotes) {
      return dispatch(createDiagnosisNotes(operationId, notes));
    }

    return await dispatch(updateDiagnosisNotes(operationId, notes));
  };

export const readDiagnosisNotes = makeThunk(
  APIv1.readDiagnosisNotes,
  {
    request: actions.readNoteRequest,
    success: actions.readNoteSuccess,
    failure: actions.readNoteFailure,
  },
  (id) => `${i18n.t('errors:failedToReadDiagnosisNotes')} (id: ${id})`
);
