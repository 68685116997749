import cn from 'classnames';
import {
  LibraryCategories,
  LibraryElement,
  MaterialElement,
} from 'common-types/library';
import { CategoryBadge } from 'components/CategoryBadge';
import { Icon } from 'components/Icon';
import { Text } from 'components/Typography/Text';
import React, { ComponentType, HTMLAttributes } from 'react';
import Highlighter from 'react-highlight-words';
import { extractWordsFromString } from 'utils/highlight';
import classes from '../LibraryItemsSearchInput.module.scss';
import { MenuItem } from '../MenuItem/MenuItem';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isHighlighted?: boolean;
  isSelected?: boolean;
  query?: string;
  option: {
    category?: LibraryCategories;
    name: LibraryElement['name'];
    size?: MaterialElement['size'];
  };
}

export const LibraryElementsOption: ComponentType<Props> = ({
  className,
  isSelected = false,
  option: { category, name, size },
  query = '',
}) => {
  const optionAppend = isSelected ? (
    <Icon className="text-success text-xxs" name="checkmark" />
  ) : null;

  const menuClassName = cn('MenuItem Option', className, classes.option, {
    'text-dark': isSelected,
  });

  return (
    <MenuItem
      className={menuClassName}
      append={optionAppend}
      label={
        <>
          <Text component="span" variant="paragraph-sm">
            <Highlighter
              searchWords={extractWordsFromString(query)}
              textToHighlight={name || ''}
            />
          </Text>
          {Boolean(size) && (
            <>
              {' '}
              <Text component="span" variant="paragraph-sm">
                {size}
              </Text>
            </>
          )}
        </>
      }
      sublabel={<CategoryBadge category={category as LibraryCategories} />}
    />
  );
};

LibraryElementsOption.displayName = 'LibraryElementsOption';
