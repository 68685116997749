import cn from 'classnames';
import { id } from 'common-types/api';
import { Attachment as AttachmentT } from 'common-types/attachments';
import { Attachment } from 'components/Attachments';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Modal } from 'components/Modal';
import noop from 'lodash/noop';
import React, { ComponentType, HTMLAttributes, useMemo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import classes from './Gallery.module.scss';
import { GalleryItem } from './GalleryItem';

type ButtonProps = {
  onClick?: () => void;
};

const NextArrowButton: ComponentType<
  ButtonProps & HTMLAttributes<HTMLElement>
> = ({ style, onClick }) => (
  <Button
    className={cn(classes.arrows, classes.nextButton)}
    variant="secondary"
    style={{ ...style }}
    onClick={onClick}
  >
    <Icon name="chevron-right" />
  </Button>
);

const PrevArrowButton: ComponentType<
  ButtonProps & HTMLAttributes<HTMLElement>
> = ({ style, onClick }) => (
  <Button
    className={cn(classes.arrows, classes.prevButton)}
    variant="secondary"
    style={{ ...style }}
    onClick={onClick}
  >
    <Icon name="chevron-left" />
  </Button>
);

const BASE_SETTINGS = {
  focusOnSelect: true,
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface Props {
  filesList: AttachmentT[];
  isOpen?: boolean;
  openFileId?: number;
  sliderSettings?: object;
  withImageDots?: boolean;
  onClose?: () => void;
  onFileUpdate?: (id: id) => void;
}

const ImagePaging = ({ file }) => {
  return <Attachment className={cn(classes.galleryPaging)} {...file} />;
};

export const Gallery: ComponentType<Props> = ({
  isOpen = false,
  onClose = noop,
  openFileId = null,
  sliderSettings = {},
  withImageDots = true,
  filesList,
  onFileUpdate,
}) => {
  const hasFiles: boolean = Boolean(filesList?.find((file) => file.id));
  const settings = useMemo(() => {
    if (!hasFiles) return;

    let settingsObj: any = {
      ...BASE_SETTINGS,
      ...sliderSettings,
    };

    if (openFileId && filesList?.length) {
      settingsObj.initialSlide = filesList.findIndex(
        (file) => file.id === openFileId
      );
    }

    if (withImageDots) {
      settingsObj.dotsClass = classes.galleryPagingContainer;
      settingsObj.customPaging = (index) => (
        <span>
          <ImagePaging file={filesList?.[index]} />
        </span>
      );
      settingsObj.nextArrow = <NextArrowButton />;
      settingsObj.prevArrow = <PrevArrowButton />;
    }

    return settingsObj;
  }, [hasFiles, filesList, openFileId, sliderSettings, withImageDots]);

  if (!hasFiles) return null;

  return (
    <Modal
      size="lg"
      className={classes.galleryModal}
      opened={isOpen}
      onRequestClose={onClose}
    >
      <Slider {...settings}>
        {(filesList as AttachmentT[]).map((file, index) => (
          <GalleryItem
            key={file.id}
            {...file}
            index={index}
            onFileUpdate={onFileUpdate}
          />
        ))}
      </Slider>
    </Modal>
  );
};

Gallery.displayName = 'Gallery';
