import namespace from './namespace';

/* Read templates list */
export const READ_TEMPLATES_LIST_REQUEST = `${namespace}READ_TEMPLATES_LIST_REQUEST`;
export const READ_TEMPLATES_LIST_SUCCESS = `${namespace}READ_TEMPLATES_LIST_SUCCESS`;
export const READ_TEMPLATES_LIST_FAILURE = `${namespace}READ_TEMPLATES_LIST_FAILURE`;

/* CRUD */
export const CREATE_TEMPLATE_REQUEST = `${namespace}CREATE_TEMPLATE_REQUEST`;
export const CREATE_TEMPLATE_SUCCESS = `${namespace}CREATE_TEMPLATE_SUCCESS`;
export const CREATE_TEMPLATE_FAILURE = `${namespace}CREATE_TEMPLATE_FAILURE`;

export const READ_TEMPLATE_REQUEST = `${namespace}READ_TEMPLATE_REQUEST`;
export const READ_TEMPLATE_SUCCESS = `${namespace}READ_TEMPLATE_SUCCESS`;
export const READ_TEMPLATE_FAILURE = `${namespace}READ_TEMPLATE_FAILURE`;

export const UPDATE_TEMPLATE_REQUEST = `${namespace}UPDATE_TEMPLATE_REQUEST`;
export const UPDATE_TEMPLATE_SUCCESS = `${namespace}UPDATE_TEMPLATE_SUCCESS`;
export const UPDATE_TEMPLATE_FAILURE = `${namespace}UPDATE_TEMPLATE_FAILURE`;

export const DELETE_TEMPLATE_REQUEST = `${namespace}DELETE_TEMPLATE_REQUEST`;
export const DELETE_TEMPLATE_SUCCESS = `${namespace}DELETE_TEMPLATE_SUCCESS`;
export const DELETE_TEMPLATE_FAILURE = `${namespace}DELETE_TEMPLATE_FAILURE`;

/* Template snapshot */
export const CREATE_TEMPLATE_SNAPSHOT_REQUEST = `${namespace}CREATE_TEMPLATE_SNAPSHOT_REQUEST`;
export const CREATE_TEMPLATE_SNAPSHOT_SUCCESS = `${namespace}CREATE_TEMPLATE_SNAPSHOT_SUCCESS`;
export const CREATE_TEMPLATE_SNAPSHOT_FAILURE = `${namespace}CREATE_TEMPLATE_SNAPSHOT_FAILURE`;

/* Template elements (Operation steps) */
export const ADD_TEMPLATE_ELEMENT = `${namespace}ADD_TEMPLATE_ELEMENT`;
export const UPDATE_TEMPLATE_ELEMENT = `${namespace}UPDATE_TEMPLATE_ELEMENT`;
export const DELETE_TEMPLATE_ELEMENT = `${namespace}DELETE_TEMPLATE_ELEMENT`;

/* Template attachments */
export const DELETE_TEMPLATE_ATTACHMENTS_REQUEST = `${namespace}DELETE_TEMPLATE_ATTACHMENTS_REQUEST`;
export const DELETE_TEMPLATE_ATTACHMENTS_SUCCESS = `${namespace}DELETE_TEMPLATE_ATTACHMENTS_SUCCESS`;
export const DELETE_TEMPLATE_ATTACHMENTS_FAILURE = `${namespace}DELETE_TEMPLATE_ATTACHMENTS_FAILURE`;

export const READ_TEMPLATE_ELEMENTS_REQUEST = `${namespace}READ_TEMPLATE_ELEMENTS_REQUEST`;
export const READ_TEMPLATE_ELEMENTS_SUCCESS = `${namespace}READ_TEMPLATE_ELEMENTS_SUCCESS`;
export const READ_TEMPLATE_ELEMENTS_FAILURE = `${namespace}READ_TEMPLATE_ELEMENTS_FAILURE`;

export const UPDATE_TEMPLATE_ELEMENTS_REQUEST = `${namespace}UPDATE_TEMPLATE_ELEMENTS_REQUEST`;
export const UPDATE_TEMPLATE_ELEMENTS_SUCCESS = `${namespace}UPDATE_TEMPLATE_ELEMENTS_SUCCESS`;
export const UPDATE_TEMPLATE_ELEMENTS_FAILURE = `${namespace}UPDATE_TEMPLATE_ELEMENTS_FAILURE`;

export const UPDATE_TEMPLATE_ELEMENT_REQUEST = `${namespace}UPDATE_TEMPLATE_ELEMENT_REQUEST`;
export const UPDATE_TEMPLATE_ELEMENT_SUCCESS = `${namespace}UPDATE_TEMPLATE_ELEMENT_SUCCESS`;
export const UPDATE_TEMPLATE_ELEMENT_FAILURE = `${namespace}UPDATE_TEMPLATE_ELEMENT_FAILURE`;

export const ADD_TEMPLATE_ELEMENTS_REQUEST = `${namespace}ADD_TEMPLATE_ELEMENTS_REQUEST`;
export const ADD_TEMPLATE_ELEMENTS_SUCCESS = `${namespace}ADD_TEMPLATE_ELEMENTS_SUCCESS`;
export const ADD_TEMPLATE_ELEMENTS_FAILURE = `${namespace}ADD_TEMPLATE_ELEMENTS_FAILURE`;

export const DELETE_TEMPLATE_ELEMENTS_REQUEST = `${namespace}DELETE_TEMPLATE_ELEMENTS_REQUEST`;
export const DELETE_TEMPLATE_ELEMENTS_SUCCESS = `${namespace}DELETE_TEMPLATE_ELEMENTS_SUCCESS`;
export const DELETE_TEMPLATE_ELEMENTS_FAILURE = `${namespace}DELETE_TEMPLATE_ELEMENTS_FAILURE`;

export const READ_TEMPLATE_ELEMENTS_ATTACHMENTS_REQUEST = `${namespace}READ_TEMPLATE_ELEMENTS_ATTACHMENTS_REQUEST`;
export const READ_TEMPLATE_ELEMENTS_ATTACHMENTS_SUCCESS = `${namespace}READ_TEMPLATE_ELEMENTS_ATTACHMENTS_SUCCESS`;
export const READ_TEMPLATE_ELEMENTS_ATTACHMENTS_FAILURE = `${namespace}READ_TEMPLATE_ELEMENTS_ATTACHMENTS_FAILURE`;

/* Search */
export const CHANGE_TEMPLATES_SEARCH_QUERY = `${namespace}CHANGE_TEMPLATES_SEARCH_QUERY`;

/* Questions */
export const TOGGLE_TIMEOUT_QUESTION_SELECTION = `${namespace}TOGGLE_TIMEOUT_QUESTION_SELECTION`;
export const TOGGLE_DEBRIEFING_QUESTION_SELECTION = `${namespace}TOGGLE_DEBRIEFING_QUESTION_SELECTION`;

export const CREATE_ADDITIONAL_TIMEOUT_QUESTION = `${namespace}CREATE_ADDITIONAL_TIMEOUT_QUESTION`;
export const DELETE_ADDITIONAL_TIMEOUT_QUESTION = `${namespace}DELETE_ADDITIONAL_TIMEOUT_QUESTION`;

export const CREATE_ADDITIONAL_DEBRIEFING_QUESTION = `${namespace}CREATE_ADDITIONAL_DEBRIEFING_QUESTION`;
export const DELETE_ADDITIONAL_DEBRIEFING_QUESTION = `${namespace}DELETE_ADDITIONAL_DEBRIEFING_QUESTION`;

export const READ_TEMPLATE_TIMEOUT_QUESTIONS_REQUEST = `${namespace}READ_TEMPLATE_TIMEOUT_QUESTIONS_REQUEST`;
export const READ_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS = `${namespace}READ_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS`;
export const READ_TEMPLATE_TIMEOUT_QUESTIONS_FAILURE = `${namespace}READ_TEMPLATE_TIMEOUT_QUESTIONS_FAILURE`;

export const UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_REQUEST = `${namespace}UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_REQUEST`;
export const UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS = `${namespace}UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS`;
export const UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_FAILURE = `${namespace}UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_FAILURE`;

export const READ_TEMPLATE_DEBRIEFING_QUESTIONS_REQUEST = `${namespace}READ_TEMPLATE_DEBRIEFING_QUESTIONS_REQUEST`;
export const READ_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS = `${namespace}READ_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS`;
export const READ_TEMPLATE_DEBRIEFING_QUESTIONS_FAILURE = `${namespace}READ_TEMPLATE_DEBRIEFING_QUESTIONS_FAILURE`;

export const UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_REQUEST = `${namespace}UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_REQUEST`;
export const UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS = `${namespace}UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS`;
export const UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_FAILURE = `${namespace}UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_FAILURE`;

export const CHANGE_TEMPLATE_QUESTIONS_ORDER = `${namespace}CHANGE_TEMPLATE_QUESTIONS_ORDER`;
export const CHANGE_TEMPLATE_ELEMENTS_ORDER = `${namespace}CHANGE_TEMPLATE_ELEMENTS_ORDER`;

export const READ_TEMPLATE_MATERIALS_REQUEST = `${namespace}READ_TEMPLATE_MATERIALS_REQUEST`;
export const READ_TEMPLATE_MATERIALS_SUCCESS = `${namespace}READ_TEMPLATE_MATERIALS_SUCCESS`;
export const READ_TEMPLATE_MATERIALS_FAILURE = `${namespace}READ_TEMPLATE_MATERIALS_FAILURE`;

export const UPDATE_TEMPLATE_MATERIALS_REQUEST = `${namespace}UPDATE_TEMPLATE_MATERIALS_REQUEST`;
export const UPDATE_TEMPLATE_MATERIALS_SUCCESS = `${namespace}UPDATE_TEMPLATE_MATERIALS_SUCCESS`;
export const UPDATE_TEMPLATE_MATERIALS_FAILURE = `${namespace}UPDATE_TEMPLATE_MATERIALS_FAILURE`;

export const CREATE_TEMPLATE_MATERIALS_REQUEST = `${namespace}CREATE_TEMPLATE_MATERIALS_REQUEST`;
export const CREATE_TEMPLATE_MATERIALS_SUCCESS = `${namespace}CREATE_TEMPLATE_MATERIALS_SUCCESS`;
export const CREATE_TEMPLATE_MATERIALS_FAILURE = `${namespace}CREATE_TEMPLATE_MATERIALS_FAILURE`;

export const UPDATE_TEMPLATE_MATERIALS_GROUP_REQUEST = `${namespace}UPDATE_TEMPLATE_MATERIALS_GROUP_REQUEST`;
export const UPDATE_TEMPLATE_MATERIALS_GROUP_SUCCESS = `${namespace}UPDATE_TEMPLATE_MATERIALS_GROUP_SUCCESS`;
export const UPDATE_TEMPLATE_MATERIALS_GROUP_FAILURE = `${namespace}UPDATE_TEMPLATE_MATERIALS_GROUP_FAILURE`;

export const CREATE_TEMPLATE_MATERIALS_GROUP_REQUEST = `${namespace}CREATE_TEMPLATE_MATERIALS_GROUP_REQUEST`;
export const CREATE_TEMPLATE_MATERIALS_GROUP_SUCCESS = `${namespace}CREATE_TEMPLATE_MATERIALS_GROUP_SUCCESS`;
export const CREATE_TEMPLATE_MATERIALS_GROUP_FAILURE = `${namespace}CREATE_TEMPLATE_MATERIALS_GROUP_FAILURE`;
export const READ_TEMPLATE_MATERIALS_GROUPS_REQUEST = `${namespace}READ_TEMPLATE_MATERIALS_GROUPS_REQUEST`;
export const READ_TEMPLATE_MATERIALS_GROUPS_SUCCESS = `${namespace}READ_TEMPLATE_MATERIALS_GROUPS_SUCCESS`;
export const READ_TEMPLATE_MATERIALS_GROUPS_FAILURE = `${namespace}READ_TEMPLATE_MATERIALS_GROUPS_FAILURE`;
export const READ_TEMPLATE_MATERIALS_GROUP_REQUEST = `${namespace}READ_TEMPLATE_MATERIALS_GROUP_REQUEST`;
export const READ_TEMPLATE_MATERIALS_GROUP_SUCCESS = `${namespace}READ_TEMPLATE_MATERIALS_GROUP_SUCCESS`;
export const READ_TEMPLATE_MATERIALS_GROUP_FAILURE = `${namespace}READ_TEMPLATE_MATERIALS_GROUP_FAILURE`;
export const DELETE_TEMPLATE_MATERIALS_GROUP_REQUEST = `${namespace}DELETE_TEMPLATE_MATERIALS_GROUP_REQUEST`;
export const DELETE_TEMPLATE_MATERIALS_GROUP_SUCCESS = `${namespace}DELETE_TEMPLATE_MATERIALS_GROUP_SUCCESS`;
export const DELETE_TEMPLATE_MATERIALS_GROUP_FAILURE = `${namespace}DELETE_TEMPLATE_MATERIALS_GROUP_FAILURE`;
