import React, { FC } from 'react';

import { ListElement } from 'components/List/ListElement';
import { Icon } from 'components/Icon';
import { Multiline } from 'components/Multiline';
import { TranslatableString } from 'components/TranslatableString';
import { t } from 'i18next';
import { TranslatableString as TranslatableStringI } from 'common-types/common';

interface Props {
  title: string;
  description: TranslatableStringI | string | null;
}

export const VitalInformation: FC<Props> = ({ title, description }) => {
  if (!description) return null;
  return (
    <ListElement
      title={
        <>
          <Icon name="vital-information" className="p-05 text-error" />
          <strong>{title}</strong>
        </>
      }
    >
      <TranslatableString string={description || ''}>
        {(translatedContent) =>
          translatedContent ? (
            <Multiline>{translatedContent}</Multiline>
          ) : (
            t('N/A')
          )
        }
      </TranslatableString>
    </ListElement>
  );
};
