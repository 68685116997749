import { RequestId } from 'common-types/api';
import { Attachment } from 'common-types/attachments';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTemplateAttachmentsByOpId } from 'store/modules/entities/selectors/operations';
import { selectOperationElementsAttachments } from 'store/modules/operation-planning';

interface useOpAttachmentsCollectionFn {
  (operationId: RequestId): Attachment[];
}

export const useOpAttachmentsCollection: useOpAttachmentsCollectionFn = operationId => {
  const templateAttachments =
    useSelector(selectTemplateAttachmentsByOpId(operationId)) || [];
  const elementsAttachments =
    useSelector(state =>
      (selectOperationElementsAttachments as (
        state: any,
        props: any
      ) => Attachment[])(state, { operationId })
    ) || [];

  const attachments = useMemo(() => {
    const allAttachments = [...templateAttachments, ...elementsAttachments];
    return uniqBy(allAttachments, 'id');
  }, [elementsAttachments, templateAttachments]);

  return attachments || [];
};
