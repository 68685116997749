import './polyfill';

import 'normalize.css';
import 'app-init/ie-fixes';
import 'app-init/analytics';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from 'scenes/Root';
import { unregister } from 'utils/registerServiceWorker';

ReactDOM.render(<Root />, document.getElementById('root'));
unregister();
