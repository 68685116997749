import { Col, Label, Row } from 'components';
import { sortBy, reverse } from 'lodash';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

interface Props {
  values;
}

export const OswestryScores: ComponentType<Props> = ({ values }) => {
  const { t } = useTranslation();

  const sortedValues = reverse(
    sortBy(values, (i) => i?.date && new Date(i.date).getTime())
  );

  return (
    <div>
      {sortedValues.map(({ score, date }) => (
        <Row nowrap alignItems="center" className="mb-05">
          <Col auto>
            <Label className="mb-0">{t('Date')}:</Label>
          </Col>
          <Col auto>{formatDate(date)}</Col>
          <Col auto>
            <Label className="mb-0">{t('indexValue')}:</Label>
          </Col>
          <Col auto>{score}</Col>
        </Row>
      ))}
    </div>
  );
};

OswestryScores.displayName = 'OswestryScores';
