import { Button, ButtonProps } from 'components/Button';
import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import styles from './UploadFilesButton.module.scss';

type ButtonElementProps = Pick<ButtonProps, 'variant' | 'size'>;
type InputElementProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'size'
>;

export interface UploadFilesButtonProps
  extends InputElementProps,
    ButtonElementProps {
  /**
   * Flag to show/hide loader
   */
  loading?: boolean;
  /**
   * Component rendered on the left side of passed children
   */
  icon?: ReactNode;
  /**
   * Maximum allowed files to upload
   */
  maxFilesAllowed?: number;
}

/**
 * Renders file input styled like a button
 */
export const UploadFilesButton = forwardRef<
  HTMLInputElement,
  UploadFilesButtonProps
>(function UploadFilesButton(
  {
    id,
    loading,
    disabled,
    className,
    icon,
    children,
    variant = 'primary',
    size = 'md',
    maxFilesAllowed = 1,
    onChange,
    ...props
  },
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, []);

  const onButtonClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const onChangeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (!ev.currentTarget.files?.length || !maxFilesAllowed) return;

      if (ev.currentTarget.files?.length > maxFilesAllowed) return;
      onChange?.(ev);
    },
    [maxFilesAllowed, onChange]
  );

  return (
    <label htmlFor={id} className={className}>
      <Button
        before={icon}
        disabled={disabled}
        loading={loading}
        variant={variant}
        size={size}
        onClick={onButtonClick}
      >
        {children}
      </Button>

      <input
        ref={inputRef}
        id={id}
        type="file"
        hidden
        disabled={disabled || loading}
        className={styles.input}
        tabIndex={-1}
        onChange={onChangeHandler}
        {...props}
      />
    </label>
  );
});
