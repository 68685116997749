import { ATTACHMENTS } from 'app-constants/analytics';
import { ACCEPT_FILE_TYPES } from 'app-constants/files';
import cn from 'classnames';
import Bugsnag from '@bugsnag/js';
import { id } from 'common-types/api';
import { Attachment } from 'common-types/attachments';
import { Loader } from 'components/Loader';
import {
  InjectedProps,
  withAttachmentsUpload,
} from 'containers/withAttachmentsUpload';
import React, { ClipboardEvent, ComponentType, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { isPasteFilesEvent } from 'utils/is-paste-files-event';
import { AllowedFilesInfo } from './AllowedFilesInfo';
import classes from './AttachmentsForm.module.scss';
import { UploadDropzone } from './UploadDropzone';
import { AttachmentsList } from '../AttachmentsList/AttachmentsList';
import { Gallery } from 'components/Gallery';
import { UploadFilesButton } from 'components/UploadFilesButton';
import { Icon } from 'components/Icon';
import { AttachmentUpdateFormModal } from '../AttachmentUpdateForm/AttachmentUpdateFormModal';

interface Props extends InjectedProps {
  attachments: Attachment[];
  isLoading?: boolean;
  onAttach?: (attachmentsIds: id[]) => void;
  onDelete?: (attachmentId: id) => void;
  readonly?: boolean;
}

const ACCEPT_ATTACHMENTS_FILE_TYPES = ACCEPT_FILE_TYPES;

const AttachmentsFormUploadableComponent: ComponentType<Props> = ({
  attachments,
  isLoading = false,
  onAttach,
  onDelete,
  readonly = true,

  closeModal,
  onUpload,
}) => {
  const [showGalleryFor, setShowGalleryFor] = useState<null | number>(null);
  const galleryVisible: boolean = typeof showGalleryFor === 'number';
  const [dropzoneVisible, setDropzoneVisible] = useState<boolean>(false);
  const [attachmentToUpdate, setAttachmentToUpdate] = useState<number | null>(
    null
  );
  const { t } = useTranslation();

  const showLoader = !attachments?.length && isLoading;
  if (showLoader) return <Loader />;

  const isEmpty: boolean = !isLoading && !attachments?.length;
  const canUpload: boolean = !readonly && typeof onAttach === 'function';
  const canDelete: boolean = !readonly && typeof onDelete === 'function';

  const showGallery = (id) => {
    if (showGalleryFor) return;

    ReactGA.event({
      category: ATTACHMENTS,
      action: 'Show Gallery',
      value: id,
    });

    setShowGalleryFor(id);
  };

  const closeGallery = () => setShowGalleryFor(null);

  const showDropzone = () => {
    if (showGalleryFor) return;
    setDropzoneVisible(true);
  };
  const hideDropzone = () => setDropzoneVisible(false);

  const handleDelete = (attachmentId: number) => {
    if (!onDelete) return;

    onDelete(attachmentId);
    ReactGA.event({
      category: ATTACHMENTS,
      action: 'Delete',
      value: attachmentId,
    });
  };

  const handleFilesUpload = async (files: File[]) => {
    if (!onAttach || !onUpload || !files?.length) return;

    try {
      const attachments = (await onUpload(files)).filter(
        Boolean
      ) as Attachment[];
      const attachmentsIds = attachments.map((a) => a.id);
      await onAttach(attachmentsIds);

      if (closeModal && files.length === attachmentsIds.length) {
        closeModal();
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

  const handlePasteFile = (event: ClipboardEvent<HTMLDivElement>) => {
    if (!isPasteFilesEvent(event)) return;
    event.preventDefault();
    const files = event.clipboardData.files ?? [];
    handleFilesUpload([...files]);

    ReactGA.event({
      category: ATTACHMENTS,
      action: 'From Clipboard',
    });
  };

  const handleUploadButton = (event) => {
    const { files } = event.target;
    event.preventDefault();
    handleFilesUpload([...files]);

    ReactGA.event({
      category: ATTACHMENTS,
      action: 'With Upload Button',
    });
  };

  const handleDropzoneUpload = (files) => {
    handleFilesUpload(files);
    hideDropzone();
    ReactGA.event({
      category: ATTACHMENTS,
      action: 'With Drag & Drop',
    });
  };

  const disableEditMode = () => {
    setShowGalleryFor(attachmentToUpdate);
    setAttachmentToUpdate(null);
  };

  const handleFileUpdateRequest = (id: number) => {
    setShowGalleryFor(null);
    setAttachmentToUpdate(id);
  };

  return (
    <>
      <div
        className={classes.container}
        onDragEnter={showDropzone}
        onPaste={readonly ? undefined : handlePasteFile}
        tabIndex={1}
      >
        <Gallery
          isOpen={galleryVisible}
          withImageDots
          openFileId={showGalleryFor ? (showGalleryFor as number) : undefined}
          filesList={attachments}
          onClose={closeGallery}
          onFileUpdate={readonly ? undefined : handleFileUpdateRequest}
        />

        {canUpload && (
          <div className="pb-1 pt-1 mb-1">
            <AllowedFilesInfo className="mb-1" images pdf video />

            <UploadFilesButton
              id="upload_new_file"
              icon={<Icon name="upload" gapRight />}
              accept={ACCEPT_ATTACHMENTS_FILE_TYPES}
              onChange={handleUploadButton}
              maxFilesAllowed={1}
              multiple
            >
              {t('uploadFiles')}
            </UploadFilesButton>
          </div>
        )}
        {isEmpty ? (
          <p>{t('noAttachmentsUploaded')}</p>
        ) : (
          <>
            <div>
              <AttachmentsList
                readonly={readonly}
                attachments={attachments}
                onGalleryRequest={showGallery}
                onRemove={canDelete ? handleDelete : undefined}
              />
            </div>
          </>
        )}

        {canUpload && (
          <UploadDropzone
            accept={ACCEPT_ATTACHMENTS_FILE_TYPES}
            className={cn(classes.dropzone, {
              [classes.dropzoneActive]: dropzoneVisible,
            })}
            onUpload={handleDropzoneUpload}
            onCancel={hideDropzone}
          />
        )}
      </div>

      <AttachmentUpdateFormModal
        opened={!!attachmentToUpdate}
        attachmentId={attachmentToUpdate}
        onRequestClose={disableEditMode}
      />
    </>
  );
};

export const AttachmentsFormUploadable = withAttachmentsUpload<Props>(
  AttachmentsFormUploadableComponent
);
