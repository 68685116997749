import React, { ComponentType } from 'react';

interface Props {
  featureCheck: () => boolean;
}

export const FeatureCheckBase: ComponentType<Props> = ({
  featureCheck,
  children,
}) => {
  const enabled = featureCheck();
  if (typeof children === 'function') {
    return children(enabled);
  }
  if (!enabled) return null;
  return <>{children}</>;
};

FeatureCheckBase.displayName = 'FeatureCheckBase';
