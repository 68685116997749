import React, { FC, ReactNode, useState } from 'react';
import i18n from 'translations/i18n-instance';
import cn from 'classnames';

import styles from './List.module.scss';
import { onEnter } from 'utils/key-events';
import { Multiline } from 'components/Multiline';
import { DataTag } from 'components/DataTag';
import { List } from './List';
import { ListHeader } from './';
import { ListItemInput } from './ListItemInput';
import { ListItem } from './ListItem';
import { NothingAddedPlaceholder } from 'components/NothingAddedPlaceholder';
import { TranslatableString } from 'components/TranslatableString';
import { noop } from 'lodash';

export interface EditableListItemProps {
  disabled?: boolean;
  icon?: ReactNode;
  inputProps?: any; //TODO define input props properly through components
  onEdit?(value: string): void;
  placeholder?: string;
  readonly?: boolean;
  title?: string | ReactNode;
  translatable?: boolean;
  value?: string;
  propKey?: string;
}

const renderContent = (
  value: string | null,
  readonly: boolean,
  placeholder: string,
  propKey: string
) => {
  if (!value) {
    return (
      <span className="text-placeholder">
        {readonly ? <NothingAddedPlaceholder /> : placeholder}
      </span>
    );
  }

  return (
    <DataTag propKey={propKey}>
      <Multiline>{value}</Multiline>
    </DataTag>
  );
};

export const EditableListItem: FC<EditableListItemProps> = ({
  disabled = false,
  icon = null,
  inputProps = {},
  onEdit = noop,
  placeholder = '',
  readonly = false,
  title = null,
  translatable = false,
  value = '',
  propKey = 'content',
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const isEditable = !readonly && !disabled;

  const enableEditMode = () => {
    setEditMode(true);
  };

  const disableEditMode = () => {
    setEditMode(false);
  };

  const handleEdit = (value) => {
    onEdit(translatable ? { [i18n.language]: value } : value);
    disableEditMode();
  };

  return (
    <List>
      {!!(title || icon) && (
        <ListHeader>
          {!!icon && <span className="p-05">{icon}</span>}
          {title}
        </ListHeader>
      )}
      <TranslatableString string={value}>
        {(translatedValue) => {
          if (editMode && !disabled) {
            return (
              <ListItemInput
                inputProps={{
                  placeholder,
                  defaultValue: translatedValue,
                  ...inputProps,
                }}
                onSave={handleEdit}
                onCancel={disableEditMode}
              />
            );
          }

          return (
            <ListItem
              className={cn({ [styles.itemDisabled]: disabled })}
              tabIndex={isEditable ? 0 : -1}
              onKeyDown={isEditable ? onEnter(enableEditMode) : undefined}
              onClick={isEditable ? enableEditMode : undefined}
            >
              {renderContent(translatedValue, readonly, placeholder, propKey)}
            </ListItem>
          );
        }}
      </TranslatableString>
    </List>
  );
};
