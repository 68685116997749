import {
  debriefingSchema,
  diagnosesListSchema,
  operationTemplateSchema,
  patientSchema,
  usersListSchema,
  vitalInformationSchema,
  attachmentsListSchema,
} from 'api/v1/schemas';
import { legacyAssessmentSchema } from 'api/v1/schemas';
import { schema } from 'normalizr';

export const operationSchema = new schema.Entity(
  'operations',
  {
    assessments: [legacyAssessmentSchema],
    attachments: attachmentsListSchema,
    debriefing: debriefingSchema,
    diagnoses: diagnosesListSchema,
    operationTemplate: operationTemplateSchema,
    participants: {
      leads: usersListSchema,
      assistants: usersListSchema,
      watchers: usersListSchema,
      nurses: usersListSchema,
    },
    patient: patientSchema,
    vitalInformation: vitalInformationSchema,
  },
  {
    // FIXME: diagnosisIds property has list of objects instead of list of ids
    // in GET /v2/operations?range=... endpoint response
    // see https://nodusmedical.atlassian.net/browse/NM-1334
    // this processStrategy used as a workaround to normalize OP objects
    processStrategy(operation) {
      if (!operation) return operation;

      const hasObjectsAtDiagnosesIds =
        Array.isArray(operation.diagnosesIds) &&
        operation.diagnosesIds?.length &&
        typeof operation.diagnosesIds.some((diagnosis) =>
          Boolean(diagnosis?.id)
        );

      if (hasObjectsAtDiagnosesIds) {
        const updatedOperation = { ...operation };

        if (!updatedOperation.diagnoses) {
          updatedOperation.diagnoses = updatedOperation.diagnosesIds;
        }

        updatedOperation.diagnosesIds = operation.diagnosesIds.map(
          (diagnosis) => diagnosis?.id ?? diagnosis
        );

        return updatedOperation;
      }

      return operation;
    },
  }
);

export const operationVetSchema = new schema.Entity(
  'operations',
  {
    assessments: [legacyAssessmentSchema],
    attachments: attachmentsListSchema,
    debriefing: debriefingSchema,
    diagnoses: diagnosesListSchema,
    operationTemplate: operationTemplateSchema,
    participants: {
      leads: usersListSchema,
      assistants: usersListSchema,
      watchers: usersListSchema,
      nurses: usersListSchema,
    },
    vetPatient: patientSchema,
    vitalInformation: vitalInformationSchema,
  },
  {
    // FIXME: diagnosisIds property has list of objects instead of list of ids
    // in GET /v2/operations?range=... endpoint response
    // see https://nodusmedical.atlassian.net/browse/NM-1334
    // this processStrategy used as a workaround to normalize OP objects
    processStrategy(operation) {
      if (!operation) return operation;

      const hasObjectsAtDiagnosesIds =
        Array.isArray(operation.diagnosesIds) &&
        operation.diagnosesIds?.length &&
        typeof operation.diagnosesIds.some((diagnosis) =>
          Boolean(diagnosis?.id)
        );

      if (hasObjectsAtDiagnosesIds) {
        const updatedOperation = { ...operation };

        if (!updatedOperation.diagnoses) {
          updatedOperation.diagnoses = updatedOperation.diagnosesIds;
        }

        updatedOperation.diagnosesIds = operation.diagnosesIds.map(
          (diagnosis) => diagnosis?.id ?? diagnosis
        );

        return updatedOperation;
      }

      return operation;
    },
  }
);

export const operationsListSchema = new schema.Array(operationSchema);
export const operationsVetListSchema = new schema.Array(operationVetSchema);
