import { Attachment } from 'components/Attachments';
import { useAttachment } from 'hooks/useAttachment';
import React, { ComponentType } from 'react';
import classes from './OperationStepAttachments.module.scss';
import { Attachment as AttachmentT } from 'common-types/attachments';

interface Props {
  id: number;
  onClick?: (attachmentId: number) => void;
  onRemove?: (id: number) => void;
}

export const OperationStepAttachment: ComponentType<Props> = ({
  id,
  onClick,
  onRemove,
}) => {
  const { attachment, loading } = useAttachment(id);
  if (loading) return null;
  const {
    fileUrl = '',
    name = '',
    mimeType,
  } = (attachment || {}) as AttachmentT;

  return (
    <span className={classes.imgContainer} data-e2e-attachment="">
      <Attachment
        id={id}
        name={name}
        fileUrl={fileUrl}
        mimeType={mimeType}
        onSelect={onClick}
        onRemove={onRemove}
      />
    </span>
  );
};
