import React, { FC, memo } from 'react';
import cn from 'classnames';

import styles from './MenuItem.module.scss';

interface MenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Label element or text
   */
  label?: React.ReactNode;
  /**
   * Sublabel element or text
   */
  sublabel?: React.ReactNode;
  /**
   * Elemet to render on right side of the menu item
   */
  append?: React.ReactNode;
}

/**
 * Menu item component to render in popups menu
 */
export const MenuItem: FC<MenuItemProps> = memo(function MenuItem({
  label,
  sublabel,
  append,
  className,
  ...props
}) {
  return (
    <div className={cn(styles.root, className)} {...props}>
      <div className={styles.inner}>
        <span className="mr-1">{label}</span>
        <span className="text-placeholder">{sublabel}</span>
      </div>
      {append && <span className={styles.append}>{append}</span>}
    </div>
  );
});
