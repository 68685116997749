export const GENERATED_FIELDS = Object.freeze([
  'createdAt',
  'createdBy',
  'id',
  'isDeleted',
  'status',
  'updatedAt',
  'updatedBy',
]);

export const LOCAL_GENERATED_FIELDS = Object.freeze([
  'uuid',
  '_tempRelationId',
]);
