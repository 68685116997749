import i18n from 'translations/i18n-instance';

export const FEMALE = 'F';
export const MALE = 'M';
export const NOT_DEFINED = 'N/A';

export const getSexValues = () => ({
  NOT_DEFINED: { value: NOT_DEFINED, label: i18n.t('N/A') },
  MALE: { value: MALE, label: i18n.t('M') },
  FEMALE: { value: FEMALE, label: i18n.t('F') },
});

export const getSexOptions = () => {
  const values = getSexValues();
  return [values.NOT_DEFINED, values.MALE, values.FEMALE];
};

export const getVetSexOptions = () => {
  const values = getSexValues();
  return [values.MALE, values.FEMALE];
};
