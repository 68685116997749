import { Operation, VetPatient as VetPatientI } from 'common-types/operation';
import { DataTag, FormBody, FormTextBox, Col, Row } from 'components';
import { FeatureFlagCheck } from 'features/FeatureFlagCheck';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { createLoadingSelector } from 'store/modules/api-requests';
import {
  selectCurrentPageOperationDenormalized,
  selectOperationVetPatient,
} from 'store/modules/entities/selectors/operations';
import { READ_OPERATIONS_HISTORY_REQUEST } from 'store/modules/history';
import {
  READ_OPERATION_REQUEST,
  READ_VET_PATIENT_REQUEST,
} from 'store/modules/operation-planning';
import { formatUserName } from 'utils';

interface Props extends RouteComponentProps {
  operation: Operation;
  vetPatient: VetPatientI;
  isLoading: boolean;
}

const VetPatientComponent = ({
  operation,
  vetPatient,
  isLoading,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const placeholder = isLoading ? '...' : t('N/A');

  if (!operation) return null;

  const {
    caseNumber,
    ownerName,
    animalName,
    species,
    breed,
    sex,
    age,
    weight,
    estimatedBloodLoss,
    castrated,
    diagnosis,
    knownAllergies,
    knownRisks,
    suspectedDiagnosis,
  } = vetPatient || {};

  return (
    <FormBody>
      <div className="mb-2">
        <Row>
          <Col width="12">
            <FormTextBox label={t('veterinaryPlanningPatient.caseNumber')}>
              <DataTag propKey="patiendId">{caseNumber ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row data-patient-name={formatUserName(vetPatient) ?? placeholder}>
          <Col width="12">
            <FormTextBox label={t('veterinaryPlanningPatient.ownerName')}>
              <DataTag propKey="ownerName">{ownerName ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row data-patient-name={formatUserName(vetPatient) ?? placeholder}>
          <Col width="12">
            <FormTextBox label={t('veterinaryPlanningPatient.animalName')}>
              <DataTag propKey="animalName">{animalName ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row data-patient-name={formatUserName(vetPatient) ?? placeholder}>
          <Col width="6">
            <FormTextBox label={t('veterinaryPlanningPatient.species')}>
              <DataTag propKey="species">{species ?? ''}</DataTag>
            </FormTextBox>
          </Col>
          <Col width="6">
            <FormTextBox label={t('veterinaryPlanningPatient.breed')}>
              <DataTag propKey="animalName">{breed ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <FeatureFlagCheck name="patientParameters">
          <Row>
            <Col width="3">
              <FormTextBox label={t('Age')}>
                <DataTag propKey="age">{age ?? ''}</DataTag>
              </FormTextBox>
            </Col>
            <Col width="3">
              <FormTextBox label={t('Weight')}>
                <DataTag propKey="weight">
                  {weight ? `${weight} ${t('kg')}` : ''}
                </DataTag>
              </FormTextBox>
            </Col>
          </Row>
        </FeatureFlagCheck>
        <Row>
          <Col width="3">
            <FormTextBox
              label={t('veterinaryPlanningPatient.estimatedBloodLoss')}
              style={{ textAlign: 'center' }}
            >
              <DataTag propKey="bloodLoss">
                {estimatedBloodLoss ? `${estimatedBloodLoss} ml` : '0 ml'}
              </DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row>
          <Col width="3">
            <FormTextBox label={t('Sex')} style={{ textAlign: 'center' }}>
              <DataTag propKey="sex">{sex ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row>
          <Col width="3">
            <FormTextBox
              label={t('veterinaryPlanningPatient.castrated')}
              style={{ textAlign: 'center' }}
            >
              <DataTag propKey="castrated">
                {castrated ? t('yes') : t('no')}
              </DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row>
          <Col width="12">
            <FormTextBox label={t('Diagnosis')}>
              <DataTag propKey="diagnosis">{diagnosis ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row>
          <Col width="12">
            <FormTextBox label={t('veterinaryPlanningPatient.knownAllergies')}>
              <DataTag propKey="allergies">{knownAllergies ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row>
          <Col width="12">
            <FormTextBox label={t('veterinaryPlanningPatient.knownRisks')}>
              <DataTag propKey="risks">{knownRisks ?? ''}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row>
          <Col width="12">
            <FormTextBox
              label={t('veterinaryPlanningPatient.suspectedDiagnosis')}
            >
              <DataTag propKey="suspectedDiagnosis">
                {suspectedDiagnosis ?? ''}
              </DataTag>
            </FormTextBox>
          </Col>
        </Row>
      </div>
    </FormBody>
  );
};

const loadingSelector = createLoadingSelector([
  READ_OPERATION_REQUEST,
  READ_OPERATIONS_HISTORY_REQUEST,
  READ_VET_PATIENT_REQUEST,
]);
const mapStateToProps = createStructuredSelector<Props, {}>({
  operation: selectCurrentPageOperationDenormalized,
  vetPatient: selectOperationVetPatient,
  isLoading: loadingSelector,
});

const withConnect = connect(mapStateToProps);

const enhancer = compose(withConnect);

export const VetPatient = enhancer(VetPatientComponent);
