import {
  debriefingSchema,
  diagnosisSchema,
  libraryElementsListSchema,
  operationMaterialsSchema,
  operationSchema,
} from 'api/v1/schemas';
import * as schemasV2 from 'api/v2/schemas';
import {
  CREATE_OPERATION_FAILURE,
  CREATE_OPERATION_REQUEST,
  CREATE_OPERATION_SUCCESS,
  DELETE_OPERATION_FAILURE,
  DELETE_OPERATION_REQUEST,
  DELETE_OPERATION_SUCCESS,
  READ_DIAGNOSIS_FAILURE,
  READ_DIAGNOSIS_REQUEST,
  READ_DIAGNOSIS_SUCCESS,
  READ_OPERATION_DEBRIEFING_FAILURE,
  READ_OPERATION_DEBRIEFING_REQUEST,
  READ_OPERATION_DEBRIEFING_SUCCESS,
  READ_OPERATION_FAILURE,
  READ_OPERATION_MATERIALS_FAILURE,
  READ_OPERATION_MATERIALS_REQUEST,
  READ_OPERATION_MATERIALS_SUCCESS,
  READ_OPERATION_PATIENT_RISKS_FAILURE,
  READ_OPERATION_PATIENT_RISKS_REQUEST,
  READ_OPERATION_PATIENT_RISKS_SUCCESS,
  READ_OPERATION_REQUEST,
  READ_OPERATION_SUCCESS,
  UPDATE_OPERATION_FAILURE,
  UPDATE_OPERATION_MATERIALS_FAILURE,
  UPDATE_OPERATION_MATERIALS_REQUEST,
  UPDATE_OPERATION_MATERIALS_SUCCESS,
  UPDATE_OPERATION_PATIENT_RISKS_FAILURE,
  UPDATE_OPERATION_PATIENT_RISKS_REQUEST,
  UPDATE_OPERATION_PATIENT_RISKS_SUCCESS,
  UPDATE_OPERATION_REQUEST,
  UPDATE_OPERATION_SUCCESS,
  APPROVE_OPERATION_FAILURE,
  APPROVE_OPERATION_REQUEST,
  APPROVE_OPERATION_SUCCESS,
} from 'store/modules/operation-planning/types';
import {
  createFailureActionCreator,
  createSuccessActionCreator,
  createSuccessActionCreator_DEPRECATED,
} from 'store/utils';

export const createOperationRequest = (operation) => ({
  type: CREATE_OPERATION_REQUEST,
  payload: { operation },
});

export const createOperationSuccess = createSuccessActionCreator_DEPRECATED(
  CREATE_OPERATION_SUCCESS,
  'operation',
  schemasV2.operationSchema
);

export const createOperationFailure = createFailureActionCreator(
  CREATE_OPERATION_FAILURE
);

export const readOperationRequest = (id) => ({
  type: READ_OPERATION_REQUEST,
  payload: { id },
});

export const readOperationSuccess = createSuccessActionCreator(
  READ_OPERATION_SUCCESS
);

export const readOperationFailure = createFailureActionCreator(
  READ_OPERATION_FAILURE
);

export const updateOperationRequest = (id, update) => ({
  type: UPDATE_OPERATION_REQUEST,
  payload: { id, update },
});

export const updateOperationSuccess = createSuccessActionCreator_DEPRECATED(
  UPDATE_OPERATION_SUCCESS,
  'operation',
  schemasV2.operationSchema
);

export const updateOperationFailure = createFailureActionCreator(
  UPDATE_OPERATION_FAILURE
);

export const readOperationMaterialsRequest = (id) => ({
  type: READ_OPERATION_MATERIALS_REQUEST,
  payload: { id },
});

export const readOperationMaterialsSuccess =
  createSuccessActionCreator_DEPRECATED(
    READ_OPERATION_MATERIALS_SUCCESS,
    'materials',
    operationMaterialsSchema
  );

export const readOperationMaterialsFailure = createFailureActionCreator(
  READ_OPERATION_MATERIALS_FAILURE
);

export const updateOperationMaterialsRequest = (id, update) => ({
  type: UPDATE_OPERATION_MATERIALS_REQUEST,
  payload: { id, update },
});

export const updateOperationMaterialsSuccess = createSuccessActionCreator(
  UPDATE_OPERATION_MATERIALS_SUCCESS
);

export const updateOperationMaterialsFailure = createFailureActionCreator(
  UPDATE_OPERATION_MATERIALS_FAILURE
);

export const readOperationPatientRisksRequest = (operationId) => ({
  type: READ_OPERATION_PATIENT_RISKS_REQUEST,
  payload: { operationId },
});

export const readOperationPatientRisksSuccess =
  createSuccessActionCreator_DEPRECATED(
    READ_OPERATION_PATIENT_RISKS_SUCCESS,
    'patientRisks',
    libraryElementsListSchema
  );

export const readOperationPatientRisksFailure = createFailureActionCreator(
  READ_OPERATION_PATIENT_RISKS_FAILURE
);

export const updateOperationPatientRisksRequest = (operationId, update) => ({
  type: UPDATE_OPERATION_PATIENT_RISKS_REQUEST,
  payload: { operationId, update },
});

export const updateOperationPatientRisksSuccess =
  createSuccessActionCreator_DEPRECATED(
    UPDATE_OPERATION_PATIENT_RISKS_SUCCESS,
    'patientRisks'
  );

export const updateOperationPatientRisksFailure = createFailureActionCreator(
  UPDATE_OPERATION_PATIENT_RISKS_FAILURE
);

export const deleteOperationRequest = (operationId) => ({
  type: DELETE_OPERATION_REQUEST,
  payload: { operationId },
});

export const deleteOperationSuccess = createSuccessActionCreator_DEPRECATED(
  DELETE_OPERATION_SUCCESS,
  'operation',
  operationSchema
);

export const deleteOperationFailure = createFailureActionCreator(
  DELETE_OPERATION_FAILURE
);

export const readOperationDebriefingRequest = (operationId) => ({
  type: READ_OPERATION_DEBRIEFING_REQUEST,
  payload: { operationId },
});

export const readOperationDebriefingSuccess =
  createSuccessActionCreator_DEPRECATED(
    READ_OPERATION_DEBRIEFING_SUCCESS,
    'debriefing',
    debriefingSchema
  );

export const readOperationDebriefingFailure = createFailureActionCreator(
  READ_OPERATION_DEBRIEFING_FAILURE
);

export const readDiagnosisRequest = (id) => ({
  type: READ_DIAGNOSIS_REQUEST,
  payload: { id },
});

export const readDiagnosisSuccess = createSuccessActionCreator_DEPRECATED(
  READ_DIAGNOSIS_SUCCESS,
  null,
  diagnosisSchema
);

export const readDiagnosisFailure = createFailureActionCreator(
  READ_DIAGNOSIS_FAILURE
);

export const approveOperationRequest = () => ({
  type: APPROVE_OPERATION_REQUEST,
});

export const approveOperationSuccess = createSuccessActionCreator_DEPRECATED(
  APPROVE_OPERATION_SUCCESS,
  'debriefing',
  debriefingSchema
);

export const approveOperationFailure = createFailureActionCreator(
  APPROVE_OPERATION_FAILURE
);
