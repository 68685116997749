import React, { Ref, ElementType, HTMLAttributes, forwardRef } from 'react';
import classnames from 'classnames';

import classes from './Panel.module.scss';

interface PanelProps extends HTMLAttributes<HTMLElement> {
  component?: ElementType;
  fullPage?: 'disabled' | 'fixed' | 'min' | 'max' | 'planning';
  innerRef?: Ref<HTMLElement>;
  mode?: 'default' | 'material';
  placement?: string;
  popper?: boolean;
  shadow?: 1 | 2 | 3 | '1' | '2' | '3';
}

const Panel = forwardRef<HTMLElement, PanelProps>(
  (
    {
      children,
      className,
      component: RenderComponent = 'div',
      fullPage = 'disabled',
      innerRef,
      mode = 'default',
      placement,
      popper = false,
      shadow,
      ...restProps
    },
    ref
  ) => {
    const panelClassName = classnames(className, classes.panel, {
      [classes.fullPage]: fullPage !== 'disabled',
      [classes.popper]: popper,
      [classes[`fullPage-${fullPage}`]]: fullPage && fullPage !== 'disabled',
      [classes[`mode-${mode}`]]: !!mode,
      [classes[`shadow-${shadow}`]]: shadow,
    });
    const arrowClassName = classnames(classes.popperArrow, {
      [classes[`placement-${placement}`]]: placement,
    });

    return (
      <RenderComponent ref={ref} className={panelClassName} {...restProps}>
        {children}
        {popper && <span className={arrowClassName} />}
      </RenderComponent>
    );
  }
);

Panel.displayName = 'Panel';

export default Panel;
