import { createSelector } from 'reselect';
import { createStateGetter } from 'store/utils';

export const selectUsers = createStateGetter('users');

export const selectNotApprovedOperationsCount = createSelector(
  selectUsers,
  (state: { notApprovedOperationsCount: number }) => {
    return state?.notApprovedOperationsCount || 0;
  }
);
