import {
  READ_TEMPLATES_LIST_SUCCESS,
  CHANGE_TEMPLATES_SEARCH_QUERY,
} from './types';

export const initialState = {
  templatesList: [],
  paginationPages: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case READ_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CHANGE_TEMPLATES_SEARCH_QUERY:
      return {
        ...state,
        search: action.payload.query,
      };

    default:
      return state;
  }
};
