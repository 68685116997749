import React, { FC, HTMLProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SUPPORTED_LANGUAGES } from 'app-constants';
import { Language } from 'common-types/common';

import classes from './LanguageBadge.module.scss';

export interface LanguageBadgeProps extends HTMLProps<HTMLElement> {
  /**
   * Language supported by application
   */
  lang: Language;
  /**
   * Flag whether to display component
   */
  alwaysDisplay?: boolean;
}

/**
 * Renders language in badge
 */
export const LanguageBadge: FC<LanguageBadgeProps> = memo(
  function LanguageBadge({ lang, alwaysDisplay = false }) {
    const { i18n } = useTranslation();

    if (
      (!alwaysDisplay && lang === i18n?.language) ||
      !lang ||
      !SUPPORTED_LANGUAGES.includes(lang)
    )
      return null;

    return <span className={classes.badge}>{lang}</span>;
  }
);
