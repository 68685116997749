import { FC } from 'react';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CREATE_ASSESSMENT_FORM } from 'scenes/routes.enum';
import styles from './CreateAssessmentButton.module.scss';

export const CreateAssessmentButton: FC = () => {
  const { t } = useTranslation();

  return (
    <Button
      className={styles.button}
      component={Link}
      to={CREATE_ASSESSMENT_FORM}
    >
      {t('assessmentForms.newForm')}
    </Button>
  );
};
