import {
  READ_RESEARCH_ARTICLES_SUCCESS,
  READ_RESEARCH_FAVORITES_ARTICLES_SUCCESS,
  REMOVE_ARTICLE_FROM_FAVORITES_SUCCESS,
  ADD_ARTICLE_TO_FAVORITES_SUCCESS,
} from 'store/modules/research';
import { set } from 'lodash';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case READ_RESEARCH_ARTICLES_SUCCESS:
    case READ_RESEARCH_FAVORITES_ARTICLES_SUCCESS:
      return {
        ...payload.entities.research,
      };
    case REMOVE_ARTICLE_FROM_FAVORITES_SUCCESS: {
      const { removedItem } = payload;
      return {
        ...set(state, `${removedItem.articleId}.isBookmarked`, false),
      };
    }
    case ADD_ARTICLE_TO_FAVORITES_SUCCESS: {
      const { favorites } = payload;
      return {
        ...set(state, `${favorites.articleId}.isBookmarked`, true),
      };
    }
    default:
      return state;
  }
};
