import React, { forwardRef, memo } from 'react';
import { LibraryCategories } from 'common-types/library';
import classNames from 'classnames';
import styles from './StepBarItem.module.scss';
import { Text } from '../Typography';
import { useTranslation } from 'react-i18next';
import { Dot } from '../Dot';

interface StepBarItemProps {
  category?: LibraryCategories;
  className?: string;
  hideEdgeBorders?: boolean;
  step?: number;
  innerRef?: any;
}

export const StepBarItem = memo(
  forwardRef<HTMLElement, StepBarItemProps>(function StepBarItem(
    { category, className, hideEdgeBorders = false, step, ...restProps },
    ref
  ) {
    const { t } = useTranslation();
    const getStepCaption = () => {
      switch (category) {
        case LibraryCategories.procedures:
          return `${t('step')} ${step}`;
        case LibraryCategories.checks:
          return t('check');
        case LibraryCategories.risks:
          return t('risk');
        default:
          return '';
      }
    };

    return (
      <span
        ref={ref}
        className={classNames(className, 'bar-item', styles.barItem, {
          [styles.hideEdgeBorders]: hideEdgeBorders,
        })}
        {...restProps}
      >
        <Text
          variant="caption"
          className={classNames('text-uppercase', styles.barItemCaption)}
        >
          {getStepCaption}
        </Text>
        <Dot className={styles.dot} variant={category} />
      </span>
    );
  })
);
