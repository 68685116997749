import cn from 'classnames';
import { CategoryChangeEvent } from 'common-types/components';
import { Category } from 'common-types/templates';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Loader } from 'components/Loader';
import { MenuItem } from 'components/Menu';
import { PanelActions } from 'components/Panel';
import { PopperDialog } from 'components/PopperDialog';
import { useDefaultDisciplineId } from 'hooks/useDefaultDiscipline';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { TemplateCategoriesMenuView } from 'scenes/TemplatesLibrary/TemplatesList/TemplateCategoriesFilters/TemplateCategoriesMenuView';
import { createLoadingSelector } from 'store/modules/api-requests';
import {
  readTemplateCategories,
  selectCategories,
} from 'store/modules/template-categories';
import { READ_TEMPLATE_CATEGORIES_REQUEST } from 'store/modules/template-categories/types';
import styles from './TemplateCategory.module.scss';

const loadingSelector = createLoadingSelector(READ_TEMPLATE_CATEGORIES_REQUEST);

const useTemplateCategories = (): {
  categories: Category[];
  loading: boolean;
} => {
  const dispatch = useDispatch();
  const requestCategories = compose(dispatch, readTemplateCategories);
  const categories: Category[] = useSelector(selectCategories);
  const loading = useSelector(loadingSelector);

  useEffect(() => {
    requestCategories();
    // eslint-disable-next-line
  }, []);

  return { categories, loading };
};

interface MockBlurEvent {
  type: 'blur';
  target: {};
}

interface Props {
  allowEmpty?: boolean;
  name: string;
  value: number | string;
  onBlur: (event: MockBlurEvent) => void;
  onChange: (event: CategoryChangeEvent) => void;
  readOnly?: boolean;
}

export const TemplateCategorySelect: FC<Props> = ({
  name,
  onBlur,
  onChange,
  allowEmpty = false,
  readOnly,
  value: categoryId,
}) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );

  const defaultCategoryId = useDefaultDisciplineId();
  const { t } = useTranslation();
  const defaultSelected =
    parseInt(categoryId as string, 10) || defaultCategoryId || null;
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    defaultSelected
  );
  const { categories, loading } = useTemplateCategories();

  if (loading) return <Loader />;
  if (!categories) return null;

  const onCategorySelect = () => {
    const value = selectedCategoryId || null;
    const event: CategoryChangeEvent = {
      type: 'change',
      target: { name, value },
    };

    setDialogOpened(false);
    onChange(event);

    if (typeof onBlur === 'function') {
      // Postpone `onBlur` call to prevent `submitForm` cancellation by Formik's
      // validator which called after `onChange` was triggered.
      // refs: https://github.com/jaredpalmer/formik/issues/1218#issuecomment-451119016
      setTimeout(() => {
        const mockBlurEvent: MockBlurEvent = { type: 'blur', target: {} };
        onBlur(mockBlurEvent);
      });
    }
  };

  const onCategorySet = (category: Category) => {
    setSelectedCategoryId(category?.id ?? null);
  };

  const onResetCategory = () => setSelectedCategoryId(null);

  const onOpenDialog = () => setDialogOpened(true);
  const onCloseDialog = () => setDialogOpened(false);

  return (
    <>
      {!readOnly && (
        <Button
          type="button"
          className="TemplateCategorySelect__button"
          variant="text"
          size="xs"
          data-testid="select-template-category"
          data-e2e-template-category-select-button
          ref={setReferenceElement}
          onClick={onOpenDialog}
        >
          <Icon name="edit" />
        </Button>
      )}

      <PopperDialog
        reference={referenceElement}
        opened={dialogOpened}
        onClose={onCloseDialog}
        title={t('selectTemplateDiscipline')}
      >
        <>
          <div className={cn('mb-2', styles.selectDialogBody)}>
            <TemplateCategoriesMenuView
              activeCategoryId={selectedCategoryId ?? null}
              categories={categories}
              onCategorySelect={onCategorySet}
            />
            {allowEmpty && (
              <MenuItem
                id="empty"
                className="mt-05"
                label={t('None')}
                onClick={onResetCategory}
              />
            )}
          </div>
          <PanelActions>
            <Button
              className="mr-05"
              variant="secondary"
              onClick={() => setDialogOpened(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              type="button"
              disabled={!allowEmpty && !selectedCategoryId}
              onClick={onCategorySelect}
            >
              {t('Select')}
            </Button>
          </PanelActions>
        </>
      </PopperDialog>
    </>
  );
};
