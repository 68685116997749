import { APIv1 } from 'api/instance';
import { LibraryCategories, Risk } from 'common-types/library';
import { OperationStep } from 'common-types/library';
import { OperationNote } from 'common-types/operation';
import { omitGeneratedFields } from 'utils';
import { generateLanguageURL } from 'utils/languages';
import { normalize } from 'utils/normalize';
import { Assessment } from 'common-types/assessments';
import {
  makeCreateRequest,
  makeDeleteRequest,
  makePatchUpdateRequest,
  makeReadRequest,
} from './make-request';
import {
  legacyAssessmentListSchema,
  normalizeAttachmentsListResponse,
  normalizeElementsListResponse,
} from './schemas';

type OperationElementsResponse = {
  elements: OperationStep[];
};

type PatientRisksResponse = {
  patientRisks: Risk[];
};

export const createOperation = makeCreateRequest('/operations');
export const readOperation = (id) => APIv1.get(`/operations/${id}`);
export const readOperations = (fromDate, toDate) =>
  APIv1.get('/operations', { params: { fromDate, toDate } });
export const updateOperation = makePatchUpdateRequest('/operations');
export const deleteOperation = makeDeleteRequest('/operations');

export const createVitalInfoReq = (operationId, data) =>
  APIv1.post(
    `/operations/${operationId}/vital_information`,
    omitGeneratedFields(data),
    { ignoreRequestBodyClean: true }
  );

export const updateVitalInfoReq = (operationId, update) =>
  APIv1.patch(`/operations/${operationId}/vital_information`, update);

export const readVitalInfoReq = (operationId) =>
  APIv1.get(`/operations/${operationId}/vital_information`);

export const readOperationPatientRisks = (operationId) =>
  APIv1.get<PatientRisksResponse, PatientRisksResponse>(
    `/operations/${operationId}/patient_risks`
  ).then(({ patientRisks }) => {
    if (!Array.isArray(patientRisks)) return patientRisks;
    return patientRisks.map((risk) => ({
      ...risk,
      category: LibraryCategories.patientRisks,
    }));
  });

export const updateOperationPatientRisks = (operationId, risks) =>
  APIv1.post(
    `/operations/${operationId}/patient_risks`,
    {
      patientRisks: risks,
    },
    { ignoreRequestBodyClean: true }
  );

export const readOperationElements = (operationId, lang?) =>
  APIv1.get<OperationElementsResponse, OperationElementsResponse>(
    generateLanguageURL(`/operations/${operationId}/elements`, lang)
  ).then(normalizeElementsListResponse);

export const readOperationElementsAttachments = (id) =>
  APIv1.get(`/operations/${id}/elements/attachments`).then(
    normalizeAttachmentsListResponse
  );

export const updateOperationElements = (operationId, elements, lang?) =>
  APIv1.post<OperationElementsResponse, OperationElementsResponse>(
    generateLanguageURL(`/operations/${operationId}/elements`, lang),
    { elements },
    { ignoreRequestBodyClean: true }
  ).then(normalizeElementsListResponse);

export const reorderOperationElements = (operationId, elements, lang?) =>
  APIv1.post<OperationElementsResponse, OperationElementsResponse>(
    generateLanguageURL(`/operations/${operationId}/elements/reorder`, lang),
    { elements },
    { ignoreRequestBodyClean: true }
  ).then(normalizeElementsListResponse);

export const updateOperationElement = (
  operationId,
  elementId,
  element,
  lang?
) =>
  APIv1.patch<OperationElementsResponse, OperationElementsResponse>(
    generateLanguageURL(
      `/operations/${operationId}/${elementId}/element`,
      lang
    ),
    { element },
    { ignoreRequestBodyClean: true }
  ).then(normalizeElementsListResponse);

export const updateOperationElementAttachment = (
  operationId,
  elementId,
  attachment,
  lang?
) =>
  APIv1.patch<OperationElementsResponse, OperationElementsResponse>(
    generateLanguageURL(
      `/operations/${operationId}/${elementId}/element/attachment`,
      lang
    ),
    { attachment },
    { ignoreRequestBodyClean: true }
  ).then(normalizeElementsListResponse);

export const addOperationElement = (operationId, element, lang?) =>
  APIv1.post<OperationElementsResponse, OperationElementsResponse>(
    generateLanguageURL(`/operations/${operationId}/element`, lang),
    { element },
    { ignoreRequestBodyClean: true }
  ).then(normalizeElementsListResponse);

export const deleteOperationElement = (operationId, elementId, lang?) =>
  APIv1.delete<OperationElementsResponse, OperationElementsResponse>(
    generateLanguageURL(`/operations/${operationId}/${elementId}/element`, lang)
  ).then(normalizeElementsListResponse);

export const readOperationsHistory = makeReadRequest('/operations_history');

/* OP Diagnosis notes */
export const createDiagnosisNotes = (operationId, content) =>
  APIv1.post(`/operations/${operationId}/notes`, { content });

export const updateDiagnosisNotes = (operationId, content) =>
  APIv1.put(`/operations/${operationId}/notes`, { content });

export const readDiagnosisNotes = (operationId) =>
  APIv1.get(`/operations/${operationId}/notes`);

export const deleteDiagnosisNotes = (operationId) =>
  APIv1.delete(`/operations/${operationId}/notes`);

export const readDiagnosis = (id) => APIv1.get(`/diagnoses/${id}`);

export const searchOperationNotes = ({ query, disciplineId }) =>
  APIv1.get<OperationNote[], OperationNote[]>('/operation-notes/search', {
    params: { query, disciplineId },
  });

export const readOperationAssessments = (operationId: number) =>
  APIv1.get<Assessment, Assessment>(
    `/operations/${operationId}/assessments`
  ).then((response) => normalize(response, legacyAssessmentListSchema));
