import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { changePassword } from 'api/v2/auth';
import { saveAuthenticationData } from 'store/modules/auth';
import { HOME } from 'scenes/routes.enum';
import { passwordSchema } from 'validation-schemas';
import { TFunction } from 'i18next';

export interface AuthFormResetPasswordState {
  password: string;
  passwordConfirmation: string;
}

export const initialFormState: AuthFormResetPasswordState = {
  password: '',
  passwordConfirmation: '',
};

export const validateResetPasswordForm =
  (t: TFunction<'translation'>) =>
  async (values: AuthFormResetPasswordState) => {
    const errors: { password?: string; passwordConfirmation?: string } = {};
    const { password, passwordConfirmation } = values;

    errors.password = await passwordSchema
      .validate(password)
      .then(() => {})
      .catch((error) => error.message);

    errors.passwordConfirmation =
      password && password !== passwordConfirmation
        ? t('passwordConfirmationDoesNotMatch')
        : undefined;

    if (!errors.password && !errors.passwordConfirmation) return;

    return errors;
  };

/**
 * Hook to handle reset password form submition
 */
export const useResetPasswordForm = () => {
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const request = async (payload: { token: string; newPassword: string }) => {
    try {
      setLoading(true);
      setError(null);

      const response = await changePassword(payload);

      saveAuthenticationData(response);
      history.replace(HOME);
    } catch (error) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    request,
  };
};
