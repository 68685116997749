import { combineReducers } from 'redux';
import { get, isObject, omit, takeRight } from 'lodash';

import parseAsyncActionType from 'store/utils/parse-async-action-type';
import { REQUEST_STATES } from 'app-constants';
import { LOCATION_CHANGE } from 'connected-react-router';

export const loadingReducer = (state = {}, action) => {
  const requestActionType = parseAsyncActionType(action.type);

  if (!requestActionType) {
    return state;
  }

  const { requestState, isReset, requestName } = requestActionType;

  return {
    ...state,
    [requestName]: isReset ? REQUEST_STATES.NOT_STARTED : requestState,
  };
};

export const errorsReducer = (state = {}, action) => {
  if (action.type === LOCATION_CHANGE) return {};

  const requestActionType = parseAsyncActionType(action.type);
  if (!requestActionType) {
    return state;
  }
  const { isFailure, isReset, requestName } = requestActionType;

  if (!isFailure || isReset) {
    return omit(state, requestName);
  }

  const {
    error: { message = null, statusCode = null } = {},
    errorMessage = '',
  } = get(action, 'payload', {});

  const error = {
    id: Date.now(),
    message: errorMessage,
    statusCode,
    requestBodyErrors: null,
  };

  if (isObject(message)) {
    error.requestBodyErrors = message;
  } else if (typeof message === 'string' && !error.message) {
    error.message = message;
  }

  return {
    ...state,
    [requestName]: error,
  };
};

const requestsLogReducer = (state = [], action) => {
  const requestActionType = parseAsyncActionType(action.type);
  if (!requestActionType) {
    return state;
  }
  const { requestState, isReset } = requestActionType;

  return takeRight(
    state.concat({
      actionType: action.type,
      status: isReset ? REQUEST_STATES.NOT_STARTED : requestState,
    }),
    20
  );
};

const requestsReducer = combineReducers({
  loading: loadingReducer,
  errors: errorsReducer,
  requestsLog: requestsLogReducer,
});

export default requestsReducer;
