import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import apiRequestsReducer from 'store/modules/api-requests';
import authReducer from 'store/modules/auth';
import customNotificationsReducer from 'store/modules/custom-notifications';
import entitiesReducer from 'store/modules/entities/reducers';
import { filesUploadProgressReducer } from 'store/modules/files-upload/reducers';
import historyReducer from 'store/modules/history';
import libraryItemsSearchReducer from 'store/modules/library/items-search';
import modalDialogsReducer from 'store/modules/modal-dialogs';
import researchReducer from 'store/modules/research';
import scheduleReducer from 'store/modules/schedule';
import settingsReducer from 'store/modules/settings';
import languageReducer from 'store/modules/language';
import templateCategoriesReducers from 'store/modules/template-categories';
import templatesLibraryReducer from 'store/modules/templates-library';
import usersReducer from 'store/modules/users';
import assessmentFormsReducer from 'store/modules/assessment-forms';

const createRootReducer = (history) =>
  combineReducers({
    apiRequests: apiRequestsReducer,
    auth: authReducer,
    customNotifications: customNotificationsReducer,
    entities: entitiesReducer,
    filesUploadProgress: filesUploadProgressReducer,
    history: historyReducer,
    library: combineReducers({ search: libraryItemsSearchReducer }),
    modalDialogs: modalDialogsReducer,
    research: researchReducer,
    router: connectRouter(history),
    schedule: scheduleReducer,
    settings: settingsReducer,
    templateCategories: templateCategoriesReducers,
    templatesLibrary: templatesLibraryReducer,
    users: usersReducer,
    language: languageReducer,
    assessmentForms: assessmentFormsReducer,
  });

export default createRootReducer;
