import { PlanOperationFromTemplateButton } from 'containers/PlanOperationButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  canEditTemplateSelector,
  canPlanFromTemplateSelector,
  createTemplateByIdSelector,
  selectTemplateCategoryId,
  selectTemplateTitle,
} from 'store/modules/templates-library';
import { createMatchParamsGetter } from 'store/utils';
import { Col, Row, IconEmphasized, Icon } from 'components';
import { TemplateTitleMenu } from './TemplateTitleMenu';
import TitleAndTemplateCategory from './TitleAndTemplateCategory';

const TemplateTitle = (props) => {
  const { t } = useTranslation();
  const { templateId } = useParams<{ templateId?: string }>();
  const canPlan: boolean = useSelector(canPlanFromTemplateSelector(templateId));
  const canEdit: boolean = useSelector(canEditTemplateSelector(templateId));
  const template = useSelector(createTemplateByIdSelector(templateId));

  const isCreating = templateId === 'create';

  return (
    <Row alignItems="center" justifyContent="space-between" nowrap>
      <Col>
        <Row alignItems="center" nowrap>
          {!isCreating && (
            <Col auto>
              <IconEmphasized icon="library" type="info" />
            </Col>
          )}
          <Col>
            <TitleAndTemplateCategory {...props} />
          </Col>
          {!isCreating && (
            <>
              {template?.isPrivate && (
                <Col auto>
                  <Icon
                    name="lock"
                    className="text-md text-light"
                    title={t('Private')}
                  />
                </Col>
              )}
              <Col auto>
                <TemplateTitleMenu readonly={!canEdit} />
              </Col>
            </>
          )}
        </Row>
      </Col>

      {!isCreating && (
        <Col auto>
          <PlanOperationFromTemplateButton
            disabled={!canPlan}
            variant="primary"
            title={
              canPlan
                ? t('hint:useThisTemplateToPlanAnOperation')
                : t('hint:cannotPlanOpFromATemplateWithNoSteps')
            }
          />
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = createStructuredSelector({
  categoryId: selectTemplateCategoryId,
  templateId: createMatchParamsGetter('templateId'),
  title: selectTemplateTitle,
});
const withConnect = connect(mapStateToProps);

export default withConnect(TemplateTitle);
