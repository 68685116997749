import i18n from 'translations/i18n-instance';
import { date, number, object, string } from './yup';

export const operationCalendarSchedule = (t) =>
  object().shape({
    startsAt: date().min(
      new Date(),
      t('validation:operationStartCannotBeInPast')
    ),
  });

export const patientFormValidation = object().shape(
  {
    patientId: string(),
    // givenName: string().required(
    //   i18n.t("validation:patientsGivenNameIsRequired")
    // ),
    givenName: string(),
    height: number().nullable().integer().min(0).max(400),
    weight: number().nullable().integer().min(0).max(1000),
    // surname: string().required(
    //   i18n.t("validation:patientsSurnameNameIsRequired")
    // ),
    surname: string(),
    birthDate: date(i18n.t('validation:invalidDate'))
      .nullable()
      // .required(i18n.t('validation:dateOfBirthIsRequired'))
      .max(
        new Date((Number(Date.now()) || 0) + 60000),
        i18n.t('validation:dateOfBirthCantBeInFuture')
      ),
  },
  ['givenName', 'surname']
);
