import cn from 'classnames';
import React, {
  AllHTMLAttributes,
  ElementType,
  forwardRef,
  ReactNode,
  Ref,
} from 'react';

import styles from '../Input.module.scss';

interface InputBaseProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Component to be rendered as input
   */
  component?: ElementType;
  /**
   * Component rendered on the left side of the input
   */
  prepend?: ReactNode;
  /**
   * Component rendered on the left side of the input
   */
  append?: ReactNode;
  /**
   * Flag to render textarea insted of the input
   */
  multiline?: boolean;
  /**
   * React ref for input component
   * TODO - check all the occurrences and remove, because currently component supports forwardRef
   */
  innerRef?: Ref<HTMLElement>;
  mask?: string;
  disabled?: boolean;
}

/**
 * Renders input component with basic styles
 */
export const InputBase = forwardRef<HTMLElement, InputBaseProps>(
  function InputBase(
    {
      component,
      prepend,
      append,
      className,
      innerRef,
      multiline,
      id,
      disabled,
      value,
      ...props
    },
    ref
  ) {
    const Component = component || (multiline ? 'textarea' : 'input');

    return (
      <div
        className={cn(
          styles.inputBase,
          multiline && styles.multiline,
          disabled && styles.disabled,
          className
        )}
      >
        {prepend && <span className={styles.prepend}>{prepend}</span>}

        <Component
          id={id}
          ref={ref || innerRef}
          className={styles.input}
          disabled={disabled}
          value={value}
          {...props}
        />

        {append && <span className={styles.append}>{append}</span>}
      </div>
    );
  }
);
