import React, { FC } from 'react';
import cn from 'classnames';
import { isNumber } from 'lodash';
import { Text } from 'components/Typography/Text';
import { ManualPopover } from 'components/Popover/ManualPopover';
import styles from '../OperationItem.module.scss';
import { DataTag } from 'components/DataTag';
import {
  EditableDurationLabel,
  FormattedDuration,
} from 'components/DurationLabels';
import { useTranslation } from 'react-i18next';

export interface OperationItemEstimationProps {
  actualTime?: number; // in seconds
  estimatedTime: number; // in seconds
  onChange?: (...args) => void;
  readonly?: boolean;
  label?: string | null;
}

export const OperationItemEstimation: FC<OperationItemEstimationProps> = ({
  actualTime,
  estimatedTime,
  label = null,
  onChange,
  readonly = true,
}) => {
  const { t } = useTranslation();
  const delay =
    actualTime && isNumber(actualTime)
      ? actualTime - (estimatedTime || 0)
      : null;

  return (
    <Text variant="caption">
      <EditableDurationLabel
        className="mr-05"
        duration={estimatedTime}
        label={label || t('estimatedDuration')}
        onChange={!readonly ? onChange : undefined}
        propKey="estimated-duration"
      />
      {delay && (
        <span
          className={cn('delay pointer', styles.delay, {
            'text-success': delay <= 0,
            'text-error': delay > 0,
          })}
        >
          <span style={{ position: 'relative' }}>
            <FormattedDuration
              showPlus
              duration={delay}
              render={(formatted) => (
                <DataTag propKey="delay">{formatted}</DataTag>
              )}
            />
            <ManualPopover className={styles.popover}>
              {t('actualDuration')}:{' '}
              <FormattedDuration
                duration={actualTime}
                render={(formatted) => (
                  <DataTag propKey="actual-duration">{formatted}</DataTag>
                )}
              />
            </ManualPopover>
          </span>
        </span>
      )}
    </Text>
  );
};
