import * as APIv1 from 'api/v1';
import { negate, omitBy } from 'lodash';
import { orderDirection } from '../../../../common-types/common';
import {
  readTemplatesFailure,
  readTemplatesRequest,
  readTemplatesSuccess,
} from '../actions';

type readTemplatesThunkParams = {
  range: string;
  filterCategories?;
  filterByUserId?: number;
  searchQuery?: string;
  orderBy?:
    | 'id'
    | 'name'
    | 'totalEstimatedTime'
    | 'author'
    | 'updatedAt'
    | 'createdAt';
  orderDirection?: orderDirection;
  filterByInstituteId?: number;
};

export const readTemplates = (params: readTemplatesThunkParams) => async (
  dispatch
) => {
  const {
    range,
    filterCategories,
    filterByUserId,
    searchQuery: query,
    orderBy,
    orderDirection,
    filterByInstituteId,
  } = params || {};

  try {
    dispatch(readTemplatesRequest(range));

    const requestParams: any = omitBy<any>(
      {
        categoryId: filterCategories ? `[${filterCategories}]` : null,
        instituteId: filterByInstituteId ? `[${filterByInstituteId}]` : null,
        userId: filterByUserId ? `[${filterByUserId}]` : null,
        orderBy,
        orderDirection,
        query,
        range,
      },
      negate(Boolean)
    );
    const templatesList = await APIv1.readTemplates(requestParams);
    const templatesTotal = templatesList.meta.range.total;

    dispatch(readTemplatesSuccess({ templatesTotal, templatesList }));
  } catch (error) {
    dispatch(readTemplatesFailure(error));
  }
};
