import React, { ElementType, FC, HTMLAttributes } from 'react';
import classnames from 'classnames';
import classes from './Tabs.module.scss';

export interface TabListProps {
  component?: ElementType;
  fill?: 'panel' | 'panel-header';
  variant?: 'default' | 'immersed';
}

export const TabList: FC<TabListProps & HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  component: RenderComponent = 'div',
  fill,
  variant = 'default',
  ...restProps
}) => {
  return (
    <RenderComponent
      className={classnames(
        className,
        classes.tabList,
        classes[`tabs-${variant}`],
        {
          [classes[`fill-${fill}`]]: fill,
        }
      )}
      {...restProps}
    >
      {children}
    </RenderComponent>
  );
};

export default TabList;
