import cn from 'classnames';
import React, { FC, memo, useState } from 'react';
import { usePopper } from 'react-popper';
import { Options as PopperOptions } from '@popperjs/core';

import styles from './PopperDialog.module.scss';

import Panel from 'components/Panel/Panel';
import { ModalHeader } from 'components/Modal';

interface PopperDialogProps extends Partial<PopperOptions> {
  /**
   * Popper reference element
   */
  reference: HTMLElement | null;
  /**
   * Dialog title
   */
  title?: string;
  /**
   * Flag to display/hide popper
   */
  opened?: boolean;
  /**
   * HTML classname attribute
   */
  className?: string;
  /**
   * Callback to be fired on overlay, close button click
   */
  onClose: VoidFunction;
}

/**
 * Renders dialog component which reference to some selected element
 */
export const PopperDialog: FC<PopperDialogProps> = memo(function PopperDialog({
  reference,
  placement = 'right-start',
  opened = false,
  title,
  children,
  className,
  onClose,
  ...props
}) {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    reference,
    popperElement,
    { placement, ...props }
  );

  const onKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Escape' || ev.key === 'Enter') onClose();
  };

  return !opened ? null : (
    <>
      <div
        role="button"
        tabIndex={0}
        className={styles.popperDialogOverlay}
        onClick={onClose}
        onKeyDown={onKeyDown}
      />

      <Panel
        ref={setPopperElement}
        shadow={2}
        popper
        placement={placement}
        style={popperStyles.popper}
        className={cn(styles.popperDialog, className)}
        {...popperAttributes.popper}
      >
        <ModalHeader title={title} onClose={onClose} />

        {children}
      </Panel>
    </>
  );
});
