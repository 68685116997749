import { Col, OverflowText, Row } from 'components';
import { FC } from 'react';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
export const QualificationPageTitle: FC = () => {
  const { t } = useTranslation();
  const pageTitle = `${t('qualification.qualificationTitle')}`;

  return (
    <Row alignItems="center" justifyContent="space-between" nowrap>
      <Col>
        <Row alignItems="center" nowrap>
          <Col>
            <Text variant="heading-xl" className="mb-0">
              <OverflowText component="span" maxLines={1}>
                {pageTitle}
              </OverflowText>
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
