import React, { FC, ElementType, AllHTMLAttributes, memo } from 'react';
import cn from 'classnames';

import classes from './Badge.module.scss';

export interface BadgeProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Root html element
   */
  component?: ElementType;
  /**
   * Style variant
   */
  variant?: 'default' | 'primary';
}

/**
 * Render passed children in badge
 */
export const Badge: FC<BadgeProps> = memo(function Badge({
  children,
  className,
  component: Component = 'span',
  variant = 'default',
  ...props
}) {
  if (!children) return null;

  return (
    <Component
      className={cn(classes.badge, classes[variant], className)}
      {...props}
    >
      {children}
    </Component>
  );
});
