import { APIv1 } from 'api/instance';
import { AxiosRequestConfig } from 'common-types/api';
import { IncidentInput } from 'common-types/incidents';
import { NormalizedResponse } from 'common-types/normalize';
import { incidentSchema, incidentsListSchema } from './schemas';
import { normalizeResponse } from 'api/helpers/normalize-response';

interface getPublicIncidents {
  (config: AxiosRequestConfig): Promise<NormalizedResponse>;
}

interface getPublicIncident {
  (incidentId: number): Promise<NormalizedResponse>;
}

export const getPublicIncidents: getPublicIncidents = (config) =>
  APIv1.get('/incidents', config).then(normalizeResponse(incidentsListSchema));

export const getPublicIncident: getPublicIncident = (incidentId) =>
  APIv1.get(`/incidents/${incidentId}`).then(normalizeResponse(incidentSchema));

export const postIncident = (data: IncidentInput) =>
  APIv1.post('/incidents', data).then(normalizeResponse(incidentSchema));

export const deleteIncident = (incidentId: number) =>
  APIv1.delete(`/incidents/${incidentId}`).then(
    normalizeResponse(incidentSchema)
  );
