import { AllowedMimeType } from 'app-constants/files';
import { Attachment } from 'common-types/attachments';
import { Col, Row } from 'components/Grid';
import React, { ComponentType } from 'react';
import { AttachmentItemSelectable } from '../AttachmentItem/AttachmentItemSelectable';

export interface Props {
  attachments?: Attachment[];
  colBasis?: string | number;
  onSelect?: (
    attachmentId: Attachment['id'] | string,
    type: AllowedMimeType
  ) => void;
  selected?: Array<Attachment['id']>;
}

export const AttachmentsListSelectable: ComponentType<Props> = ({
  attachments,
  colBasis = '25%',
  onSelect,
  selected = [],
}) => {
  if (!attachments) return null;

  return (
    <Row>
      {attachments.map((attachment: Attachment) => {
        const selectedIndex = selected.findIndex((id) => id === attachment.id);
        const isSelected = selectedIndex >= 0;
        const { description, fileUrl, id, name, mimeType } = attachment;

        return (
          <Col basis={colBasis} className="mb-1">
            <AttachmentItemSelectable
              description={description}
              fileUrl={fileUrl}
              id={id}
              isSelected={isSelected}
              key={id}
              name={name}
              onSelect={onSelect}
              selectedIndex={selectedIndex + 1}
              mimeType={mimeType}
            />
          </Col>
        );
      })}
    </Row>
  );
};
