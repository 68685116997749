import { IconEmphasized, Col, Row } from 'components';
import { useSearchParams } from 'hooks/useSearchParams';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectTemplateById } from 'store/modules/entities/selectors/templates';
import {
  createCanEditOperationSelector,
  selectOperationPageTitle,
  selectOperationTemplateCategoryId,
  selectOperationTemplateId,
} from 'store/modules/operation-planning';
import { translateValue } from 'utils';
import { OperationTitleMenu } from './OperationTitleMenu';
import TitleAndTemplateCategory from './TitleAndTemplateCategory';

const OperationTitle = ({ title, categoryId, ...props }) => {
  const [{ fromTemplate }] = useSearchParams();
  const { operationId } = useParams<{
    operationId?: string;
  }>();
  const canEdit = useSelector(createCanEditOperationSelector(operationId));

  const template = useSelector(selectTemplateById(fromTemplate));
  const operationTitle = title ?? translateValue(template?.name) ?? '';

  return (
    <Row alignItems="center" nowrap>
      <Col auto>
        <IconEmphasized icon="operation-steps" type="success" />
      </Col>
      <Col>
        <TitleAndTemplateCategory
          title={operationTitle}
          categoryId={categoryId || template?.categoryId}
          {...props}
        />
      </Col>
      {canEdit && (
        <Col auto>
          <OperationTitleMenu />
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = createStructuredSelector({
  categoryId: selectOperationTemplateCategoryId,
  templateId: selectOperationTemplateId,
  title: selectOperationPageTitle,
});
const withConnect = connect(mapStateToProps);

export default withConnect(OperationTitle);
