import { APIv1 } from 'api/instance';
import { compose } from 'redux';
import { createNormalizr } from 'store/utils';
import { generateLanguageURL } from 'utils/languages';
import { debriefingQuestionSchema, timeoutQuestionSchema } from './schemas';

/**
 * This fn sorts collection of questions so standard questions (with `isStandard: true`)
 * would be always at start of array. It is required for drag'n'drop feature
 * work correctly, because singe array of questions being split into two arrays to
 * render standard and non-standard questions in a different components with a different logic
 * and drag'n'drop works in the following way:
 * it adds length of standard questions to index of non-standard when recalculating
 * its positions.
 */
const sortQuestionsByIsStandard = (questions) =>
  questions.sort(
    (prev, next) => Number(next.isStandard) - Number(prev.isStandard)
  );

const normalizeTimeoutQuestions = createNormalizr([timeoutQuestionSchema]);
const normalizeDebriefingQuestions = createNormalizr([
  debriefingQuestionSchema,
]);

const createResponsePreparator =
  (normalizationFn) =>
  (templateId) =>
  ({ questions }) =>
    compose(
      (questions) => ({ templateId, ...questions }),
      normalizationFn,
      sortQuestionsByIsStandard
    )(questions);

const prepareTimeoutQuestions = createResponsePreparator(
  normalizeTimeoutQuestions
);

const prepareDebriefingQuestions = createResponsePreparator(
  normalizeDebriefingQuestions
);

export const readTemplateTimeoutQuestions = (templateId, lang) =>
  APIv1.get(
    generateLanguageURL(`/operation_templates/${templateId}/timeouts`, lang)
  ).then(prepareTimeoutQuestions(templateId));

export const updateTemplateTimeoutQuestions = (
  templateId,
  update,
  lang = null
) =>
  APIv1.post(
    generateLanguageURL(`/operation_templates/${templateId}/timeouts`, lang),
    update,
    {
      ignoreRequestBodyClean: true,
    }
  ).then(prepareTimeoutQuestions(templateId));

export const readTemplateDebriefingQuestions = (templateId, lang) =>
  APIv1.get(
    generateLanguageURL(`/operation_templates/${templateId}/debriefings`, lang)
  ).then(prepareDebriefingQuestions(templateId));

export const updateTemplateDebriefingQuestions = (
  templateId,
  update,
  lang = null
) =>
  APIv1.post(
    generateLanguageURL(`/operation_templates/${templateId}/debriefings`, lang),
    update,
    {
      ignoreRequestBodyClean: true,
    }
  ).then(prepareDebriefingQuestions(templateId));
