import React, { FC, HTMLAttributes } from 'react';
import { TranslatableString } from 'components/TranslatableString';
import { Text } from 'components/Typography/Text';
import { MenuItem } from './MenuItem/MenuItem';
import { extractWordsFromString } from 'utils/highlight';
import { Icon } from 'components/Icon';
import { CategoryBadge } from 'components/CategoryBadge';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import { LibraryCategories } from '../../common-types/library';

export interface OptionProps {
  isSelected?: boolean;
  className?: string;
  label?: string;
  query?: string;
  size?: string;
  category: LibraryCategories;
}

export const Option: FC<OptionProps & HTMLAttributes<HTMLElement>> = ({
  isSelected = false,
  className,
  label = '',
  query = '',
  size,
  category,
  ...restProps
}) => {
  return (
    <MenuItem
      className={classNames('MenuItem Option', className, {
        'text-dark': isSelected,
      })}
      append={
        isSelected && (
          <Icon className="text-success text-xxs" name="checkmark" />
        )
      }
      label={
        <>
          <TranslatableString
            string={label}
            render={(translatedLabel) => (
              <Text component="span" variant="paragraph-sm">
                <Highlighter
                  searchWords={extractWordsFromString(query)}
                  textToHighlight={translatedLabel}
                />
              </Text>
            )}
          />
          {Boolean(size) && (
            <>
              {' '}
              <Text component="span" variant="paragraph-sm">
                {size}
              </Text>
            </>
          )}
        </>
      }
      sublabel={<CategoryBadge category={category} />}
      {...restProps}
    />
  );
};
