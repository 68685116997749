import { string, object } from 'yup';
import i18n from 'translations/i18n-instance';

export const addressSchema = object().shape({
  address: string().trim().required().min(4),
  zipCode: string().trim().required().min(4),
  city: string().trim().required().min(3),
  country: string().required(),
});

export const instituteFormValidationSchema = object().shape({
  address: addressSchema,
});

export const profileSchema = object().shape({
  givenName: string().trim().required(i18n.t('validation:nameIsRequiredField')),
  surname: string().trim().required(),
  email: string().email().trim().required(),
  phone: string().trim().required(),
});
