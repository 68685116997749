import { READONLY } from 'app-constants';
import { LibraryCategories } from 'common-types/library';
import { MaterialsGroupType } from 'common-types/materials';
import {
  Button,
  Col,
  getGroupMaterials,
  MaterialFormProvider,
  MaterialsGroup,
  MaterialsSearch,
  Row,
  SearchLayout,
} from 'components';

import { sortBy } from 'lodash';
import React, { ComponentType, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OperationPlanningContext } from 'scenes/OperationPlanning/context';
import OperationPlanningActions from 'scenes/OperationPlanning/OperationPlanningActions';
import { BRIEFING_ATTACHMENTS } from 'scenes/routes.enum';
import { applyPathParams } from 'utils';
import { useOperationMaterials } from './useOperationMaterials';
import { useOperationStaticGroup } from './useOperationStaticGroup';

interface Props {}

const DEVICES_CATEGORIES = [LibraryCategories.equipment];

const useOperationEquipmentStaticGroups = () => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      { value: 'equipment', label: t('Devices'), categories: ['equipment'] },
      {
        value: MaterialsGroupType.ADDITIONAL_EQUIPMENT,
        label: t('extraEquipment'),
        categories: ['equipment'],
      },
    ],
    // changes of t should not trigger an update

    []
  );
};

export const OperationEquipmentForm: ComponentType<Props> = () => {
  const { t } = useTranslation();
  const { mode, operationId } = useContext(OperationPlanningContext);
  const readonly = mode === READONLY;
  const groups = useOperationEquipmentStaticGroups();
  const {
    additionalEquipmentGroup,
    equipment,
    addElement: handleSelect,
    updateElement: handleUpdateMaterial,
    removeElement: handleRemoveMaterial,
    reorderElements: handleReorderMaterials,
  } = useOperationMaterials(operationId);

  const additionalEquipment = useMemo(
    () => sortBy(getGroupMaterials(additionalEquipmentGroup), 'order'),
    [additionalEquipmentGroup]
  );

  const materialsGroups = useMemo(
    () => ({
      equipment,
      [MaterialsGroupType.ADDITIONAL_EQUIPMENT]: additionalEquipment,
    }),
    [equipment, additionalEquipment]
  );

  useOperationStaticGroup(operationId, MaterialsGroupType.ADDITIONAL_EQUIPMENT);

  return (
    <>
      <SearchLayout
        header={
          !readonly && (
            <Row className="justify-center">
              <Col auto className="mb-2" basis="620px">
                <MaterialsSearch
                  categories={DEVICES_CATEGORIES}
                  groups={groups}
                  onSelectMaterial={handleSelect}
                />
              </Col>
            </Row>
          )
        }
      >
        <MaterialFormProvider value={{ readonly }}>
          <Row>
            {groups.map((group) => (
              <Col width="4" key={group.value}>
                <MaterialsGroup
                  id={group.value}
                  materials={materialsGroups[group.value]}
                  name={group.label}
                  onRemoveMaterial={handleRemoveMaterial}
                  onUpdateMaterial={handleUpdateMaterial}
                  onReorderMaterials={handleReorderMaterials}
                />
              </Col>
            ))}
          </Row>
        </MaterialFormProvider>
      </SearchLayout>

      {!readonly && (
        <OperationPlanningActions>
          <Button
            type="button"
            component={Link}
            to={applyPathParams(BRIEFING_ATTACHMENTS, { operationId })}
          >
            {t('Next')}
          </Button>
        </OperationPlanningActions>
      )}
    </>
  );
};

OperationEquipmentForm.displayName = 'OperationEquipmentForm';

export default OperationEquipmentForm;
