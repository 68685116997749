import {
  OPERATION_PARTICIPANTS_ROLES,
  pluralizeRole,
} from 'app-constants/operation-participants-roles';
import { Participant } from 'common-types/operation';

export const makeParticipantsList = (operationParticipants: {
  [index: string]: Participant[];
}): Participant[] => {
  if (!operationParticipants) return [];
  return OPERATION_PARTICIPANTS_ROLES.reduce<Participant[]>((acc, role) => {
    const roleParticipants =
      operationParticipants[pluralizeRole(role)]?.map(
        (p) => ({ ...p, role } as Participant)
      ) || [];
    return [...acc, ...roleParticipants];
  }, []);
};
