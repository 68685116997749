import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import keyBy from 'lodash/keyBy';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';

import { selectCurrentPageOperationDenormalized } from 'store/modules/entities/selectors/operations';
import { formatDateTime } from 'utils';
import { makeParticipantsList } from 'utils/participants';
import { Operation } from 'common-types/operation';
import { FormBody } from 'components/Form/FormBody';
import { FormTextBox } from 'components/Form/FormInput';
import { DataTag } from 'components/DataTag';
import { Row, Col } from 'components/Grid';
import { Text } from 'components/Typography/Text';
import { ParticipantsList } from 'components/ParticipantsList';

interface Props {
  operation: Operation;
}

const OperationInfoComponent = ({ operation }: Props): ReactElement | null => {
  const { t } = useTranslation();

  if (!operation) return null;

  const isFinished = !!operation.debriefing;
  const participants = makeParticipantsList(operation.participants);

  return (
    <>
      <FormBody>
        <FormTextBox label={t('operationTitle')}>
          <DataTag propKey="title">{operation.title}</DataTag>
        </FormTextBox>
        <Row>
          <Col auto>
            {isFinished ? (
              <Row>
                <Col auto>
                  <FormTextBox className="mb-0 mr-2" label={t('startedAt')}>
                    <DataTag propKey="endedAt">
                      {formatDateTime(
                        operation.debriefing?.startedAt ?? operation.startsAt
                      )}
                    </DataTag>
                  </FormTextBox>
                </Col>
                <Col auto>
                  <FormTextBox className="mb-0" label={t('completedAt')}>
                    <DataTag propKey="endedAt">
                      {formatDateTime(operation.debriefing?.endedAt)}
                    </DataTag>
                  </FormTextBox>
                </Col>
              </Row>
            ) : (
              <FormTextBox className="mb-0" label={t('startsAt')}>
                <DataTag propKey="endedAt">
                  {formatDateTime(operation.startsAt)}
                </DataTag>
              </FormTextBox>
            )}
          </Col>
        </Row>

        <hr className="mb-2 mt-2" />

        <Text variant="title">{t('Participants')}</Text>

        <ParticipantsList
          assessments={keyBy(operation?.assessments ?? [], 'assigneeId')}
          participants={participants}
        />
      </FormBody>
    </>
  );
};

const mapStateToProps = createStructuredSelector<Props, {}>({
  operation: selectCurrentPageOperationDenormalized,
});
const withConnect = connect(mapStateToProps);

export const OperationInfo = withConnect(OperationInfoComponent);
