import React, { ReactElement } from 'react';
import classnames from 'classnames';
import noop from 'lodash/noop';

import { RatingItem } from './RatingItem';

import styles from './Rating.module.scss';

interface Props {
  max?: number;
  onChange?: (num: number) => void;
  readOnly?: boolean;
  className?: string;
  value: number;
}

export const Rating = ({
  value = 0,
  onChange = noop,
  max = 5,
  className = '',
  readOnly = false,
}: Props): ReactElement => {
  const handleChange = (newValue: number): void => {
    if (value === newValue) return;
    onChange(newValue);
  };

  const items: boolean[] = new Array(max).fill(true);

  return (
    <span
      className={classnames(className, styles.wrapper, {
        [styles.editable]: !readOnly,
      })}
    >
      {items.map((_, index: number) => {
        const itemValue = index + 1;

        return (
          <RatingItem
            key={index}
            value={itemValue}
            isActive={itemValue <= value}
            onSelect={readOnly ? undefined : handleChange}
          />
        );
      })}
    </span>
  );
};
