import { OPERATION_MATERIALS_CATEGORIES } from 'app-constants';
import { MaterialElement } from 'common-types/library';
import { MaterialsGroup, MaterialsGroupType } from 'common-types/materials';
import invariant from 'invariant';

export const getGroupMaterials = (
  group?: Partial<MaterialsGroup>
): MaterialElement[] => {
  if (!group) return [];
  return OPERATION_MATERIALS_CATEGORIES.reduce(
    (materials, category) => materials.concat(group[category] ?? []),
    [] as MaterialElement[]
  ).sort((a, b) => a.order - b.order);
};

export const isPlainMaterialList = (groupId) => {
  invariant(groupId, 'groupId is required');
  return OPERATION_MATERIALS_CATEGORIES.includes(groupId);
};

export const filterMaterialsTables = (materialsGroups) =>
  materialsGroups.filter(
    (group) => group.type === MaterialsGroupType.OTHER && !group.isDeleted
  );
