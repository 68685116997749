import { Action } from 'store/utils';
import types from 'store/modules/users/types';

export const initialState = {
  notApprovedOperationsCount: 0,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case types.READ_NOT_APPROVED_OPERATIONS_COUNT_SUCCESS: {
      return {
        ...state,
        notApprovedOperationsCount: action.payload.count,
      };
    }

    default:
      return state;
  }
};
