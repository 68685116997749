import { Row, Col } from 'components';
import React, { ComponentType, ReactNode } from 'react';
import classes from './SearchLayout.module.scss';

interface Props {
  header: ReactNode;
}

export const SearchLayout: ComponentType<Props> = ({ children, header }) => {
  if (!children && !header) return null;

  return (
    <Row className={classes.container} direction="column">
      {header && (
        <Col basis="auto" className={classes.header}>
          {header}
        </Col>
      )}
      {children && <Col className={classes.main}>{children}</Col>}
    </Row>
  );
};

SearchLayout.displayName = 'SearchLayout';
