import cn from 'classnames';
import React, { ComponentPropsWithoutRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useWatch, Control } from 'react-hook-form';

import styles from './FormUpdatePassword.module.scss';
import { FormUpdatePasswordValues } from './utils';

import { FormInput } from 'components';
import {
  FormPasswordValidation,
  usePasswordValidationOptions,
} from 'forms/FormPasswordValidation';

interface FormUpdatePasswordProps extends ComponentPropsWithoutRef<'form'> {
  /**
   * React hook form control
   */
  control: Control<FormUpdatePasswordValues>;
}

/**
 * Renders update password form connected with react-hook-form
 */
export const FormUpdatePassword: FC<FormUpdatePasswordProps> = ({
  control,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const newPassword = useWatch({
    name: 'newPassword',
    control,
  });

  const passwordValidationOptions = usePasswordValidationOptions();

  return (
    <form className={cn(styles.root, className)} {...props}>
      <Controller
        name="password"
        control={control}
        render={({ field: { value, name, onChange }, formState }) => (
          <FormInput
            name={name}
            value={value}
            error={formState.errors.password?.message}
            type="password"
            label={t('currentPassword')}
            placeholder={t('enterCurrentPassword')}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="newPassword"
        control={control}
        render={({ field: { value, name, onChange }, formState }) => (
          <FormInput
            name={name}
            value={value}
            type="password"
            label={t('passwordNew')}
            placeholder={t('passwordEnterNew')}
            error={formState.errors.newPassword?.message}
            onChange={onChange}
          />
        )}
      />

      <FormPasswordValidation
        value={newPassword}
        options={passwordValidationOptions}
        className="mb-1-5"
      />

      <Controller
        name="newPasswordConfirmation"
        control={control}
        render={({ field: { value, name, onChange }, formState }) => (
          <FormInput
            name={name}
            value={value}
            type="password"
            label={t('passwordRepeatNew')}
            placeholder={t('passwordRepeatNew')}
            error={formState.errors.newPasswordConfirmation?.message}
            onChange={onChange}
          />
        )}
      />
    </form>
  );
};
