import React, { ComponentType } from 'react';
import classes from './AttachmentPic.module.scss';

interface Props {
  src: string;
  alt: string;
}

export const Image: ComponentType<Props> = ({ src, alt }) => {
  return <img className={classes.image} src={src} alt={alt} />;
};

Image.displayName = 'Image';
