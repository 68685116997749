import React, { useMemo } from 'react';
import { InputBase } from '../InputBase';

const getExtension = (
  value: string
): { filename: string; extension: string } => {
  const [extension = ''] = value.match(/\.\w{2,5}$/i) || [];
  let filename = value;
  if (extension) {
    filename = filename.replace(extension, '');
  }
  return { filename, extension };
};

export const InputFilename = ({
  value: filenameValue,
  onChange,
  ...inputProps
}) => {
  const { filename, extension } = useMemo(
    () => getExtension(filenameValue),
    [filenameValue]
  );

  const handleChange = (event) => {
    const {
      type,
      target: { name, id, value },
    } = event;
    const mockEvent = {
      type,
      target: { name, id, value: extension ? value.concat(extension) : value },
    };

    onChange(mockEvent);
  };

  return <InputBase value={filename} onChange={handleChange} {...inputProps} />;
};

InputFilename.displayName = 'InputFilename';
