import React, { FC, memo, ReactNode, useCallback, useState } from 'react';

import { NOT_DEFINED } from 'app-constants';

import { Icon } from 'components/Icon';

import styles from './EditableBlock.module.scss';
import { Editable } from 'components/Editable';
import { EditableInput } from 'components/Form/Input';

// TODO - fix dynamic types and extend with EditableInput props after refactoring
export interface EditableBlockProps extends Record<string, unknown> {
  /*
   * React children prop
   */
  children?: ReactNode;
  /**
   * Error message
   */
  error?: string;
  /**
   * Placeholder class name
   */
  placeholderClass?: string;
  /**
   * Default input value
   */
  defaultValue?: string;
  /**
   * Input value
   */
  value?: string;
  /**
   * Placeholder string
   */
  placeholder?: string;
  /**
   * Flag to show/hide editable block
   */
  show?: boolean;
  /**
   * Change value callback
   */
  onEdit?: (value: string) => void;
  /**
   * Blur event callback
   */
  onEditComplete?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Escape press callback
   */
  onEditCancel?: () => void;
  /**
   * Invalid value callback
   */
  onInvalid?: () => void;
}

/**
 * Renders input component, which can be toggled between editable and not editables states
 */
export const EditableBlock: FC<EditableBlockProps> = memo(
  function EditableBlock({
    children,
    error,
    placeholderClass,
    show,
    onEdit,
    onEditCancel,
    onEditComplete,
    ...props
  }) {
    const [editMode, setEditMode] = useState(false);

    const enableEditMode = useCallback(() => {
      setEditMode(true);
    }, []);

    const disableEditMode = useCallback(() => {
      setEditMode(false);
    }, []);

    const onEditHandler = useCallback(
      (value: string) => {
        if (onEdit) {
          onEdit(value);
          setEditMode(false);
        }
      },
      [onEdit]
    );

    const onEditCompleteHandler = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (onEditComplete) {
          onEditComplete(event);
          setEditMode(false);
        }
      },
      [onEditComplete]
    );

    if (!editMode) {
      const label =
        children ||
        props.defaultValue ||
        props.value ||
        props.placeholder ||
        NOT_DEFINED;

      return (
        <Editable
          show={show}
          className={placeholderClass}
          onClick={enableEditMode}
        >
          {error ? (
            <span className="text-error">
              {label} <Icon name="incidents" /> {error}
            </span>
          ) : (
            <span>{label as string}</span>
          )}
        </Editable>
      );
    }

    return (
      <EditableInput
        componentType="native"
        inputClassName={styles.input}
        onEdit={onEditHandler}
        onEditComplete={onEditCompleteHandler}
        onEditCancel={disableEditMode}
        defaultValue={props.defaultValue}
        {...props}
      />
    );
  }
);
