import { Attachment, FileUploadProgress } from 'common-types/attachments';
import { FilesUploadProgressModal } from 'components/FilesUploadProgressModal/FilesUploadProgressModal';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { createLoadingSelector } from 'store/modules/api-requests';
import { uploadAttachments } from 'store/modules/attachments';
import { UPLOAD_FILE_REQUEST } from 'store/modules/files-upload';
import {
  cancelFileUpload,
  resetFilesUploadProgress,
} from 'store/modules/files-upload/actions';
import { selectOngoingFilesUploads } from 'store/modules/files-upload/selectors';
import { TranslationFn } from 'utils/translation-types';
import withT from './withT';

const MODAL_CLOSE_DELAY = 1000; // ms

interface Props {
  isUploading: boolean;
  onCancelUpload: ({ id }: { id: string }) => void;
  onFilesUpload: (files: File[]) => Promise<Attachment[]>;
  onFileUploadRetry: ({ id }: { id: string }) => void;
  onResetFilesUploadProgress: () => void;
  t: TranslationFn;
  uploads: FileUploadProgress[];
}

interface State {
  isModalOpen: boolean;
}

export interface InjectedProps {
  onUpload: (files: File[]) => (Attachment | null)[];
  closeModal: () => void;
}

export function withAttachmentsUpload<P extends InjectedProps>(
  ComponentToWrap: ComponentType<P>
) {
  class WithAttachmentsUpload extends Component<
    Exclude<P, InjectedProps> & Props,
    State
  > {
    state = {
      isModalOpen: false,
    };

    modalTimeout?: number;

    componentWillUnmount() {
      clearTimeout(this.modalTimeout);
    }

    closeModal = () => {
      /* clean file store property is important
        because (only) error file objects have inside huge File instance
      */
      const { onResetFilesUploadProgress } = this.props;
      this.setState({ isModalOpen: false });
      onResetFilesUploadProgress();
    };

    closeModalDelayed = () => {
      this.modalTimeout = window.setTimeout(() => {
        this.closeModal();
      }, MODAL_CLOSE_DELAY);
    };

    openModal = () => {
      if (this.state.isModalOpen) return;
      /* clean file store property is important
        because (only) error file objects have inside huge File instance
      */
      const { onResetFilesUploadProgress } = this.props;
      onResetFilesUploadProgress();

      this.setState({ isModalOpen: true });
    };

    handleFilesUpload = async (files) => {
      this.openModal();
      const { onFilesUpload } = this.props;
      const attachments = await onFilesUpload(files);
      return attachments;
    };

    handleUploadCancel = () => {
      const { uploads, onCancelUpload, t } = this.props;
      uploads.forEach((file) => {
        const { cancel, id } = file;
        if (typeof cancel === 'function') {
          cancel(t('cancelledFilesUploading'));
        }
        onCancelUpload({ id });
      });

      this.closeModal();
    };

    handleFileUploadCancel = ({ id: idToCancel }) => {
      const { uploads, onCancelUpload, t } = this.props;
      const upload = uploads.find((up) => up.id === idToCancel);
      if (!upload) return;
      const { cancel } = upload;
      if (typeof cancel === 'function') {
        cancel(t('cancelledFilesUploading'));
      }
      onCancelUpload({ id: idToCancel });
    };

    render() {
      const { isModalOpen } = this.state;
      const {
        isUploading,
        onFilesUpload,
        onFileUploadRetry,
        onResetFilesUploadProgress,
        t,
        uploads,
        onCancelUpload,
        ...restProps
      } = this.props;

      return (
        <>
          <FilesUploadProgressModal
            isOpen={isModalOpen}
            isUploading={isUploading}
            uploads={uploads}
            onCancel={this.handleFileUploadCancel}
            onCancelAll={this.handleUploadCancel}
            onClose={this.closeModal}
          />
          <ComponentToWrap
            {...(restProps as P)}
            closeModal={this.closeModalDelayed}
            onUpload={this.handleFilesUpload}
          />
        </>
      );
    }
  }

  const loadingSelector = createLoadingSelector([UPLOAD_FILE_REQUEST]);
  const mapStateToProps = createStructuredSelector({
    isUploading: loadingSelector,
    uploads: selectOngoingFilesUploads,
  });
  const mapDispatchToProps = {
    onCancelUpload: cancelFileUpload,
    onFilesUpload: uploadAttachments,
    onResetFilesUploadProgress: resetFilesUploadProgress,
  };
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  const enhancer = compose(withConnect, withT);
  const WrappedConnectedComponent = enhancer(WithAttachmentsUpload);
  hoistStatics(WithAttachmentsUpload, ComponentToWrap);

  return WrappedConnectedComponent;
}
