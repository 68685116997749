import partial from 'lodash/partial';
import { createActionType } from 'store/utils/create-action-type';

export const namespace = 'operation';
const action = partial(createActionType, namespace);

const READ_NOT_APPROVED_OPERATIONS_COUNT_REQUEST = action(
  'READ_NOT_APPROVED_OPERATIONS_COUNT_REQUEST'
);
const READ_NOT_APPROVED_OPERATIONS_COUNT_SUCCESS = action(
  'READ_NOT_APPROVED_OPERATIONS_COUNT_SUCCESS'
);
const READ_NOT_APPROVED_OPERATIONS_COUNT_FAILURE = action(
  'READ_NOT_APPROVED_OPERATIONS_COUNT_FAILURE'
);

export default {
  READ_NOT_APPROVED_OPERATIONS_COUNT_REQUEST,
  READ_NOT_APPROVED_OPERATIONS_COUNT_SUCCESS,
  READ_NOT_APPROVED_OPERATIONS_COUNT_FAILURE,
};
