import partial from 'lodash/partial';
import { createActionType } from 'store/utils/create-action-type';

const report = partial(createActionType, 'report');

export const UPDATE_REPORT_REQUEST = report('UPDATE_REPORT_REQUEST');
export const UPDATE_REPORT_SUCCESS = report('UPDATE_REPORT_SUCCESS');
export const UPDATE_REPORT_FAILURE = report('UPDATE_REPORT_FAILURE');

export const READ_OPERATION_REPORT_REQUEST = report(
  'READ_OPERATION_REPORT_REQUEST'
);
export const READ_OPERATION_REPORT_SUCCESS = report(
  'READ_OPERATION_REPORT_SUCCESS'
);
export const READ_OPERATION_REPORT_FAILURE = report(
  'READ_OPERATION_REPORT_FAILURE'
);

export const UPDATE_OPERATION_REPORT_REQUEST = report(
  'UPDATE_OPERATION_REPORT_REQUEST'
);
export const UPDATE_OPERATION_REPORT_SUCCESS = report(
  'UPDATE_OPERATION_REPORT_SUCCESS'
);
export const UPDATE_OPERATION_REPORT_FAILURE = report(
  'UPDATE_OPERATION_REPORT_FAILURE'
);

export const SUBMIT_OPERATION_REPORT_REQUEST = report(
  'SUBMIT_OPERATION_REPORT_REQUEST'
);
export const SUBMIT_OPERATION_REPORT_SUCCESS = report(
  'SUBMIT_OPERATION_REPORT_SUCCESS'
);
export const SUBMIT_OPERATION_REPORT_FAILURE = report(
  'SUBMIT_OPERATION_REPORT_FAILURE'
);
