import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './OperationStepContent.module.scss';
import { categoryIconsMap } from './utils';

import { ENTER } from 'app-constants';
import {
  calculateOpStepCharLimits,
  NameInput,
  StepEstimation,
  DescriptionInput,
} from 'components/ElementsForm/OperationStep';
import { ListHeader } from 'components/List';
import { Row, Col } from 'components/Grid';
import { WpaBadge } from 'components/Badge';
import { LibraryCategories } from 'common-types/library';

interface OperationStepContentProps {
  addon?;
  category;
  description;
  estimatedTime;
  hasAttachments: boolean;
  name;
  mitigationAction;
  onUpdate;
  uuid;
  wpa?: boolean;
}

export const OperationStepContent: FC<OperationStepContentProps> = ({
  name,
  category,
  hasAttachments = false,
  uuid,
  estimatedTime,
  onUpdate,
  addon,
  wpa,
  ...props
}) => {
  const { t } = useTranslation();

  const { content, contentKey, placeholder } = useMemo(() => {
    const isRisk = category === LibraryCategories.risks;
    const contentKey = isRisk ? 'mitigationAction' : 'description';
    const content = props[contentKey];
    const placeholder = isRisk
      ? t('enterMitigationAction')
      : t('enterDescription');

    return { content, contentKey, placeholder };
  }, [category, props, t]);

  const limits = useMemo(() => {
    const description = content ?? '';
    return calculateOpStepCharLimits({ name, description, hasAttachments });
  }, [name, content, hasAttachments]);

  const shouldShowEstimation = [
    LibraryCategories.procedures,
    LibraryCategories.checks,
  ].includes(category);

  const handleNameChange = (event) => {
    const { value } = event.target;
    const nameValue = value.trim();
    if (!nameValue || nameValue === name) return;
    onUpdate(uuid, { name: nameValue });
  };

  const handleContentChange = (event) => {
    const { value } = event.target;
    const contentValue = value.trim();
    if (contentValue === content) return;
    onUpdate(uuid, { [contentKey]: contentValue });
  };

  const handleEstimationChange = (estimatedTime) => {
    onUpdate(uuid, { estimatedTime });
  };

  const handleContentKeyDown = (event) => {
    if (event.keyCode !== ENTER || !(event.metaKey || event.ctrlKey)) return;
    // Submit edit on `cmd/ctrl + Enter`
    handleContentChange(event);
  };

  const Icon = categoryIconsMap[category];

  return (
    <div className={styles.root}>
      <ListHeader
        addonVisible
        addon={addon}
        contentClassName="flex align-center fullWidth"
        className={styles.header}
        hoverable
        id={uuid}
      >
        <div className={styles.content}>
          <Row alignItems="center" justifyContent="flex-start" className="mb-1">
            {wpa && (
              <Col auto>
                <WpaBadge />
              </Col>
            )}

            {Icon && (
              <Col auto>
                <Icon />
              </Col>
            )}

            <Col>
              <NameInput
                defaultValue={name}
                charsLimit={limits?.nameCharsLimit}
                charsPerLineLimit={limits?.nameCharsPerLineLimit}
                onBlur={handleNameChange}
                className={styles.nameInput}
              />
            </Col>
          </Row>

          {shouldShowEstimation && (
            <Row>
              <Col>
                <StepEstimation
                  estimatedTime={estimatedTime}
                  onChange={handleEstimationChange}
                />
              </Col>
            </Row>
          )}
        </div>
      </ListHeader>

      <DescriptionInput
        charsLimit={limits?.descriptionCharsLimit}
        charsPerLineLimit={limits?.descriptionCharsPerLineLimit}
        defaultValue={content}
        onBlur={handleContentChange}
        onKeyDown={handleContentKeyDown}
        placeholder={placeholder}
        className={styles.descriptionInput}
      />
    </div>
  );
};
