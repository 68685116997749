import React, { FC } from 'react';
import { IncidentImpact } from 'common-types/incidents';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import classes from './ImpactLabel.module.scss';

export interface ImpactLabelProps {
  impact?: IncidentImpact;
}

export const ImpactLabel: FC<ImpactLabelProps> = ({ impact }) => {
  const { t } = useTranslation();
  if (!impact) return null;

  return (
    <strong className={cn(classes.impact, { [classes[impact]]: !!impact })}>
      {t(impact)}
    </strong>
  );
};
