import { FormTextarea } from 'components/Form/FormInput';
import { MaxLengthIndicator } from 'components/MaxLengthIndicator';
import { LibraryFormLimitsType } from 'containers/LibraryItemsSearch/AddLibraryItemForm.types';
import { connect, FormikContextType } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface RisksFormProps {
  limits?: LibraryFormLimitsType;
}

interface RisksFormFormikValueProps {
  description: string;
  mitigationAction: string;
}

const RisksForm: FC<
  RisksFormProps & FormikContextType<RisksFormFormikValueProps>
> = ({ handleChange, values, errors, limits }) => {
  const { t } = useTranslation();

  return (
    <FormTextarea
      label={t('mitigationAction')}
      mode="translatable"
      name="mitigationAction"
      placeholder={t('enterRiskMitigationAction')}
      value={values.mitigationAction}
      error={errors.mitigationAction}
      onChange={handleChange}
      append={
        limits ? (
          <MaxLengthIndicator
            length={limits.descriptionLength ?? 0}
            limit={limits.descriptionCharsLimit}
          />
        ) : undefined
      }
    />
  );
};

export default connect(RisksForm);
