import { normalizeResponse } from 'api/helpers/normalize-response';
import { APIv1 } from 'api/instance';
import { LOCAL_GENERATED_FIELDS } from 'app-constants';
import { AxiosRequestConfig, RequestId } from 'common-types/api';
import { MaterialsGroupInput } from 'common-types/materials';
import { NormalizedResponse } from 'common-types/normalize';
import { omitDeep } from 'utils';
import { normalize } from 'utils/normalize';
import { templateMaterialsSchema } from '../schemas/materials';
import {
  addMaterialsGroupElementsFn,
  deleteMaterialsGroupElementFn,
  normalizeMaterialsGroup,
} from './common';
import { generateLanguageURL } from 'utils/languages';

const normalizeTemplateMaterials = (templateId) => (response) =>
  normalize(
    {
      // Setting templateId to make normalizr work.
      // See `templateMaterialsSchema`
      templateId,
      ...response,
    },
    templateMaterialsSchema
  );

export const readTemplateMaterials = (
  templateId: RequestId,
  opts,
  lang?: string
) =>
  APIv1.get(
    generateLanguageURL(`/operation_templates/${templateId}/materials`, lang),
    opts
  ).then(normalizeTemplateMaterials(templateId));

export const updateTemplateMaterials = (
  templateId: RequestId,
  update,
  lang
) => {
  // Elements from Materials Groups can have same `relationId` as OP/template
  // materials which cause merge of the objects during local normalization
  // https://nodusmedical.atlassian.net/browse/NM-2778
  const data = omitDeep(update, [...LOCAL_GENERATED_FIELDS, 'order']);
  return APIv1.post(
    generateLanguageURL(`/operation_templates/${templateId}/materials`, lang),
    data,
    { ignoreRequestBodyClean: true }
  ).then(normalizeTemplateMaterials(templateId));
};

const prepareMaterialsCreateBody = (materials) =>
  omitDeep(materials, [
    ...LOCAL_GENERATED_FIELDS,
    'relationId',
    'label',
    'value',
    'isSelected',
  ]);

export const createTemplateMaterials = (templateId, materials) =>
  APIv1.post(
    `/operation_templates/${templateId}/materials`,
    prepareMaterialsCreateBody(materials),
    { ignoreRequestBodyClean: true }
  );

export const createTemplateMaterialsGroup = (
  templateId: RequestId,
  group: MaterialsGroupInput,
  lang?: string
): Promise<NormalizedResponse> =>
  APIv1.post(
    generateLanguageURL(
      `/operation_templates/${templateId}/materials/groups`,
      lang
    ),
    group
  ).then(normalizeMaterialsGroup);

export const readTemplateMaterialsGroups = async (
  templateId: RequestId,
  config?: AxiosRequestConfig,
  lang?: string
): Promise<NormalizedResponse> =>
  APIv1.get(
    generateLanguageURL(
      `/operation_templates/${templateId}/materials/groups`,
      lang
    ),
    config
  )
    // Simultate Template Materials object to normalize it with relation to OP
    .then((materialsGroups) => ({
      materialsGroups,
      templateId,
    }))
    .then(normalizeResponse(templateMaterialsSchema));

export const readTemplateMaterialsGroup = async (
  templateId: RequestId,
  groupId: RequestId,
  config?: AxiosRequestConfig
): Promise<NormalizedResponse> =>
  APIv1.get(
    `/operation_templates/${templateId}/materials/groups/${groupId}`,
    config
  ).then(normalizeMaterialsGroup);

export const updateTemplateMaterialsGroup = async (
  templateId: RequestId,
  groupId: RequestId,
  update: Partial<MaterialsGroupInput>,
  lang?: string
): Promise<NormalizedResponse> =>
  APIv1.patch(
    generateLanguageURL(
      `/operation_templates/${templateId}/materials/groups/${groupId}`,
      lang
    ),
    update
  ).then(normalizeMaterialsGroup);

export const deleteTemplateMaterialsGroup = (
  templateId: RequestId,
  groupId: RequestId
) =>
  APIv1.delete(
    `/operation_templates/${templateId}/materials/groups/${groupId}`
  ).then(normalizeMaterialsGroup);

export const addTemplateMaterialsGroupAttachments: addMaterialsGroupElementsFn =
  (templateId: RequestId, groupId: RequestId, attachments) =>
    APIv1.post(
      `/operation_templates/${templateId}/materials/groups/${groupId}/attachments`,
      attachments
    ).then(normalizeMaterialsGroup);

export const deleteTemplateMaterialsGroupAttachment: deleteMaterialsGroupElementFn =
  (templateId, groupId, attachmentId) =>
    APIv1.delete(
      `operation_templates/${templateId}/materials/groups/${groupId}/attachments/${attachmentId}/all`
    ).then(normalizeMaterialsGroup);

export const updateTemplateMaterialsOfGroup: addMaterialsGroupElementsFn = (
  templateId,
  groupId,
  elementsIds,
  language
) =>
  APIv1.post(
    generateLanguageURL(
      `/operation_templates/${templateId}/materials/groups/${groupId}/materials`,
      language
    ),
    elementsIds,
    { ignoreRequestBodyClean: true }
  ).then(normalizeMaterialsGroup);
