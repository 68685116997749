import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthFormLogin, AuthFormRelogin } from './AuthFormLogin';
import { AuthFormResetPassword } from './AuthFormResetPassword';
import { AuthFormForgotPassword } from './AuthFormForgotPassword';
import { AuthFormDeactivatedAccount } from './AuthFormDeactivatedAccount';
import styles from './Auth.module.scss';

import { Logo } from 'components';
import { user as userStore } from 'local-store';
import {
  AUTH_DEACTIVATED,
  FORGOT_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
  RETURN_LOGIN,
} from 'scenes/routes.enum';
import { useProduct } from 'hooks/useProduct';
import impediaCover from './cover-impedia.png';
import vetCover from './cover-vet.png';

export const Auth = () => {
  const { isProductType } = useProduct();
  const cover = isProductType('vet') ? vetCover : impediaCover;

  return (
    <div className={styles.authPage}>
      <aside className={styles.aside}>
        <div className={styles.asideInner}>
          <img alt="presentation" src={cover} className={styles.asideImage} />
        </div>
      </aside>

      <section className={styles.main}>
        <header className={styles.logo}>
          <Logo className={styles.logoImage} />
        </header>
        <div className={styles.content}>
          <Switch>
            <Route path={LOGIN} component={AuthFormLogin} />
            <Route path={RETURN_LOGIN} component={AuthFormRelogin} />
            <Route path={RESET_PASSWORD} component={AuthFormResetPassword} />
            <Route path={FORGOT_PASSWORD} component={AuthFormForgotPassword} />
            <Route
              path={AUTH_DEACTIVATED}
              component={AuthFormDeactivatedAccount}
            />
            <Redirect to={userStore.get() ? RETURN_LOGIN : LOGIN} />
          </Switch>
        </div>
      </section>
    </div>
  );
};
