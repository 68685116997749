import { get } from 'lodash';
import sleep from 'sleep-promise';
import invariant from 'invariant';

import Bugsnag from '@bugsnag/js';

import { RETURN_LOGIN, LOGIN } from 'scenes/routes.enum';
import { NOT_FOUND } from 'http-status-codes';

const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

export const createHandleUnauthorizedRequest = (
  user,
  history,
  requestTokenRefresh
) => {
  invariant(
    user && typeof user === 'object',
    `"user" argument is invalid. Expected "object", got "${typeof user}"`
  );
  invariant(
    history && typeof history === 'object',
    `"history" argument is invalid. Expected "object", got "${typeof history}"`
  );
  invariant(
    typeof requestTokenRefresh === 'function',
    `"axios" argument is invalid. Expected "function", got "${typeof requestTokenRefresh}"`
  );

  const prepareConfig = (config) => {
    config.headers.Authorization = user.authHeader();
    if (config.data) {
      config.data = parseData(config.data);
    }
    return config;
  };

  let isRefreshTokenInProgress = false;

  return async (api, config) => {
    const refreshToken = user.getRefreshToken();

    if (!refreshToken) {
      user.removeTokens();
      return;
    } else if (isRefreshTokenInProgress) {
      return;
    }

    try {
      isRefreshTokenInProgress = true;
      const tokens = await requestTokenRefresh(refreshToken);
      isRefreshTokenInProgress = false;
      user.setTokens(tokens);
    } catch (error) {
      if (error.message === 'Cancel repeated refresh token request') {
        return null;
      }

      if (get(error, 'response.status') === NOT_FOUND) {
        history.push(Boolean(user.get()) ? RETURN_LOGIN : LOGIN);
        user.removeTokens();
        throw new Error('Refresh token not found');
      }

      Bugsnag.notify(error);
    }

    // Prevent retry simultaneous requests during refresh
    // token request in progress
    await sleep(100);

    const requestConfig = prepareConfig(config);
    return api.request(requestConfig);
  };
};
