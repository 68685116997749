const prefilledArray = (size, startValue = 1) =>
  Array(size)
    .fill()
    .map((v, i) => startValue + i);

const stringOrArrayToIntArray = data => {
  if (typeof data === 'string') {
    return [parseInt(data, 10)];
  } else if (Array.isArray(data)) {
    return data.map(val => parseInt(val, 10));
  } else if (typeof data === 'number') {
    return [data];
  }

  return null;
};

const flattenDeep = (arr, depth = 1) =>
  arr.reduce(
    (a, v) =>
      a.concat(depth > 1 && Array.isArray(v) ? flattenDeep(v, depth - 1) : v),
    []
  );

const toHash = (collection, key) => {
  if (!Array.isArray(collection)) return collection;

  return Array.prototype.reduce.call(
    collection,
    // eslint-disable-next-line no-sequences
    (acc, data, index) => ((acc[!key ? index : data[key]] = data), acc),
    {}
  );
};

export { prefilledArray, stringOrArrayToIntArray, toHash, flattenDeep };
