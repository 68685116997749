import {
  READ_RESEARCH_ARTICLES_REQUEST,
  READ_RESEARCH_FAVORITES_ARTICLES_REQUEST,
  READ_RESEARCH_ARTICLES_SUCCESS,
  READ_RESEARCH_FAVORITES_ARTICLES_SUCCESS,
  READ_RESEARCH_FAVORITES_ARTICLES_IDS_SUCCESS,
  CLEAR_RESEARCH_ARTICLES,
  READ_RESEARCH_ARTICLES_FAILURE,
  READ_RESEARCH_FAVORITES_ARTICLES_FAILURE,
} from 'store/modules/research/types';
import i18n from 'translations/i18n-instance';
import { get } from 'lodash';

const initialState = {
  isLoading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case READ_RESEARCH_ARTICLES_REQUEST:
    case READ_RESEARCH_FAVORITES_ARTICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case READ_RESEARCH_ARTICLES_SUCCESS:
      return {
        favoriteIds: [...state.favoriteIds],
        isLoading: false,
        meta: action.meta,
        articles: action.payload.result,
      };

    case READ_RESEARCH_FAVORITES_ARTICLES_SUCCESS:
      return {
        favoriteIds: [...state.favoriteIds],
        articles: action.payload.result,
      };
    case READ_RESEARCH_FAVORITES_ARTICLES_IDS_SUCCESS:
      return {
        ...state,
        favoriteIds: action.payload,
      };
    case READ_RESEARCH_ARTICLES_FAILURE:
    case READ_RESEARCH_FAVORITES_ARTICLES_FAILURE: {
      const error = get(
        action,
        'payload.errorMessage.message',
        (() => i18n.t('pubMedServiceCannotReach'))()
      );
      return {
        error,
        isLoading: false,
      };
    }
    case CLEAR_RESEARCH_ARTICLES:
      return {};

    default:
      return state;
  }
};
