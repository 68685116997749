import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { Link, Route } from 'react-router-dom';
import { compose } from 'redux';

import { Panel, Text, Button, PanelBody, Icon } from 'components';
import { HOME, APP_CRASHED, ROOT_CRASHED, LOGIN } from 'scenes/routes.enum';
import withT from 'containers/withT';
import { withSearchParams } from 'containers/withSearchParams';

export class ErrorScreen extends Component {
  static _propTypes = {
    returnPath: string,
    t: func.isRequired,
  };

  static defaultProps = {
    returnPath: HOME,
  };

  render() {
    const { t, returnPath } = this.props;

    return (
      <Panel fullPage="fixed" className="justify-center">
        <PanelBody className="text-center" style={{ flexGrow: 0 }}>
          <div className="mb-3">
            <div className="mb-2">
              <Icon name="incidents" className="text-warning text-xxl" />
            </div>
            <Text variant="heading-md">{t('opsSomethingWentWrong')}</Text>
            <Text>{t('pleaseRefreshTheTage')}</Text>
          </div>

          <Button
            component="a"
            href={`${window.location.origin}${returnPath}`}
            replace="true"
            className="mr-1"
          >
            {t('Refresh')}
          </Button>
          <Route
            path={APP_CRASHED}
            render={() => (
              <Button component={Link} to={HOME} replace="true">
                {t('goToHome')}
              </Button>
            )}
          />
          <Route
            path={ROOT_CRASHED}
            render={() => (
              <Button component={Link} to={LOGIN} replace>
                {t('goToLogin')}
              </Button>
            )}
          />
        </PanelBody>
      </Panel>
    );
  }
}

const enhancer = compose(withT, withSearchParams('returnPath'));

export default enhancer(ErrorScreen);
