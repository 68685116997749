import { OperationParticipantRole } from 'common-types/operation';
import { Avatar } from 'components/Avatar';
import { Col, Row } from 'components/Grid';
import { Text } from 'components/Typography/Text';
import React, { ComponentType, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useProduct } from 'hooks/useProduct';

interface Props {
  givenName: string;
  surname: string;
  imageUrl?: string | null;
  role?: OperationParticipantRole;
  isSurnameFirst?: boolean;
}

export const UserBadge: ComponentType<Props & HTMLAttributes<HTMLDivElement>> =
  ({ givenName, imageUrl, role, surname, className, isSurnameFirst }) => {
    const { t } = useTranslation();
    const { productType } = useProduct();

    return (
      <Row alignItems="center" className={className} inline nowrap>
        <Col auto>
          <Avatar
            imageUrl={imageUrl}
            givenName={givenName}
            surname={surname}
            isSurnameFirst={isSurnameFirst}
          />
        </Col>
        <Col>
          <Text variant="heading-md" className="mb-0">
            {isSurnameFirst
              ? `${surname} ${givenName}`
              : `${givenName} ${surname}`}
          </Text>
          {role && (
            <span className="mt-05">{t(role, { context: productType })}</span>
          )}
        </Col>
      </Row>
    );
  };

UserBadge.displayName = 'UserBadge';
