import React, { FC } from 'react';
import { Text } from 'components/Typography';

import styles from './HistoryStatistic.module.scss';

export interface HistoryStatisticProps {
  label?: string | number;
  subLabel?: string;
  icon: string;
}

export const HistoryStatistic: FC<HistoryStatisticProps> = ({
  label = '',
  subLabel = '',
  icon,
}) => {
  return (
    <div className={styles.statsContainer}>
      <div className={styles.statsTextBlock}>
        <div className="flex align-baseline">
          <Text variant="heading-xl" className={styles.largeLabel}>
            {label}
          </Text>
          &nbsp;
        </div>
        <h4 className={styles.subLabel}>{subLabel}</h4>
      </div>
      <img className={styles.icon} src={icon} alt="stats_type" />
    </div>
  );
};
