import { READONLY } from 'app-constants';
import {
  LibraryCategories,
  MaterialsGroupOption,
  Implant,
} from 'common-types/library';
import { MaterialsGroup } from 'common-types/materials';

import React, {
  ComponentType,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { OperationPlanningContext } from 'scenes/OperationPlanning/context';
import OperationPlanningActions from 'scenes/OperationPlanning/OperationPlanningActions';
import { MaterialsGroupAttachmentsManager } from './MaterialsGroupAttachmentsManager';
import {
  operationMaterialsGroupsApiHook,
  useOperationMaterialsGroupsApi,
} from './useOperationMaterialsGroupsApi';
import { getElementsUUIDs } from 'store/utils';
import { BRIEFING_EQUIPMENT } from 'scenes/routes.enum';
import { applyPathParams } from 'utils';
import {
  BaseModal,
  Button,
  Col,
  filterMaterialsTables,
  Loader,
  MaterialFormProvider,
  MaterialsGroupsForm,
  MaterialsSearch,
  Row,
  SearchLayout,
  MessageBlock,
} from 'components';

interface Props {
  readonly?: boolean;
  operationId: string;
  onSubmitSuccess: () => void;
}

interface operationTablesHook extends operationMaterialsGroupsApiHook {
  groups: MaterialsGroup[];
  fetchGroups: () => void;
}

interface useOperationTablesFn {
  (operationId: string): operationTablesHook;
}

const useOperationTables: useOperationTablesFn = (operationId) => {
  const { groups, fetchGroups, ...rest } =
    useOperationMaterialsGroupsApi(operationId);

  const tables = filterMaterialsTables(groups);

  useEffect(() => {
    fetchGroups();
    // changes of fetchGroups should not trigger an update
  }, []);

  return {
    fetchGroups,
    groups: tables,
    ...rest,
  };
};

const OPERATION_MATERIALS_TABLES_CATEGORIES = [
  LibraryCategories.instruments,
  LibraryCategories.implants,
  LibraryCategories.equipment,
];

export const OperationTablesForm: ComponentType<Props> = ({
  operationId: operationIdProp,
}) => {
  const { mode } = useContext(OperationPlanningContext);
  const readonly = mode === READONLY;

  const { operationId: operationIdParam } = useParams<{
    operationId?: string;
  }>();
  const operationId = operationIdParam || operationIdProp;
  const [openAttachmentsManager, setAttachmentsManager] = useState<
    number | null
  >(null);
  const { t } = useTranslation();
  const {
    addElement,
    addGroup,
    groups,
    loading,
    pristine,
    removeElement,
    removeGroup,
    updateElement,
    reorderElements,
    updateGroup,
    addGroupAttachments,
  } = useOperationTables(operationId);

  const selectedImplantsUUIDs = useMemo(() => {
    const implantsList = groups.reduce<Implant[]>(
      (result, group) => result.concat(group.implants),
      []
    );
    return getElementsUUIDs(implantsList);
  }, [groups]);

  const materialsGroups = useMemo(
    () =>
      groups.map(
        (group): MaterialsGroupOption => ({
          value: group.id,
          label: group.name,
        })
      ),
    [groups]
  );

  const handleSelect = (groupId, element) => {
    addElement(groupId, element);
  };

  const requestModalOpen = (groupId: number) => {
    setAttachmentsManager(groupId);
  };

  const requestModalClose = () => {
    setAttachmentsManager(null);
  };

  const handleAttachmentsSelect = async (attachments: number[]) => {
    const groupId = openAttachmentsManager;
    if (!groupId) return;
    await addGroupAttachments(groupId, attachments);
    requestModalClose();
  };

  const handleAttachmentsRequest = (groupId) => {
    requestModalOpen(groupId as number);
  };

  const hasGroups: boolean = Boolean(groups?.length);
  const showLoader: boolean = !hasGroups && loading;
  const showGroupsWarning: boolean =
    !readonly && !loading && !pristine && !hasGroups;

  return (
    <>
      {!readonly && (
        <>
          <BaseModal
            size="lg"
            opened={Boolean(openAttachmentsManager)}
            onRequestClose={requestModalClose}
          >
            <MaterialsGroupAttachmentsManager
              selectedGroupId={openAttachmentsManager ?? undefined}
              onCancel={requestModalClose}
              onSelect={handleAttachmentsSelect}
              operationId={Number(operationId)}
            />
          </BaseModal>
        </>
      )}

      <SearchLayout
        header={
          !readonly && (
            <Row className="justify-center">
              <Col auto className="mb-2" basis="620px">
                <MaterialsSearch
                  categories={OPERATION_MATERIALS_TABLES_CATEGORIES}
                  groups={materialsGroups}
                  selectedMaterials={selectedImplantsUUIDs}
                  onSelectMaterial={handleSelect}
                />
                {showGroupsWarning && (
                  <MessageBlock type="warning">
                    {t('createAtLeastOneTableToAddMaterials')}
                  </MessageBlock>
                )}
              </Col>
            </Row>
          )
        }
      >
        {showLoader && <Loader />}
        <MaterialFormProvider value={{ readonly, isLoading: loading }}>
          <MaterialsGroupsForm
            materialsGroups={groups}
            onAddGroup={addGroup}
            onAttachmentRequest={handleAttachmentsRequest}
            onRemoveGroup={removeGroup}
            onRemoveMaterial={removeElement}
            onUpdateGroup={updateGroup}
            onUpdateMaterial={updateElement}
            onReorderMaterials={reorderElements}
          />
        </MaterialFormProvider>
      </SearchLayout>

      {!readonly && (
        <OperationPlanningActions>
          <Button
            type="button"
            component={Link}
            to={applyPathParams(BRIEFING_EQUIPMENT, { operationId })}
          >
            {t('Next')}
          </Button>
        </OperationPlanningActions>
      )}
    </>
  );
};

OperationTablesForm.displayName = 'OperationTablesForm';

export default OperationTablesForm;
