import React, { FC } from 'react';
import { LibraryCategories } from '../../common-types/library';
import * as libraryForms from './library-forms';

export const forms = {
  checks: libraryForms.ChecksForm,
  equipment: libraryForms.EquipmentForm,
  implants: libraryForms.ImplantsForm,
  instruments: libraryForms.InstrumentsForm,
  procedures: libraryForms.ProceduresForm,
  risks: libraryForms.RisksForm,
  patientRisks: libraryForms.RisksForm,
};

export interface LibraryFormProps {
  category: LibraryCategories;
  mode?: 'central-library' | 'search';
  [key: string]: any;
}

export const LibraryForm: FC<LibraryFormProps> = ({
  category,
  mode = 'search',
  ...formProps
}) => {
  if (!category) return null;
  const FormComponent = forms[category];

  return FormComponent ? <FormComponent mode={mode} {...formProps} /> : null;
};
