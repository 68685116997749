import * as APIv1 from 'api/v1';
import { makeThunkFromTypes } from 'store/utils/make-thunk';
import * as actionTypes from '../types';

export const readOperationMaterials = makeThunkFromTypes(
  APIv1.readOperationMaterials,
  {
    request: actionTypes.READ_OPERATION_MATERIALS_REQUEST,
    success: actionTypes.READ_OPERATION_MATERIALS_SUCCESS,
    failure: actionTypes.READ_OPERATION_MATERIALS_FAILURE,
  }
);

export const updateOperationMaterials = makeThunkFromTypes(
  APIv1.updateOperationMaterials,
  {
    request: actionTypes.UPDATE_OPERATION_MATERIALS_REQUEST,
    success: actionTypes.UPDATE_OPERATION_MATERIALS_SUCCESS,
    failure: actionTypes.UPDATE_OPERATION_MATERIALS_FAILURE,
  }
);

export const createOperationMaterialsGroup = makeThunkFromTypes(
  APIv1.createOperationMaterialsGroup,
  {
    request: actionTypes.CREATE_OPERATION_MATERIALS_GROUP_REQUEST,
    success: actionTypes.CREATE_OPERATION_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.CREATE_OPERATION_MATERIALS_GROUP_FAILURE,
  }
);

export const readOperationMaterialsGroups = makeThunkFromTypes(
  APIv1.readOperationMaterialsGroups,
  {
    request: actionTypes.READ_OPERATION_MATERIALS_GROUPS_REQUEST,
    success: actionTypes.READ_OPERATION_MATERIALS_GROUPS_SUCCESS,
    failure: actionTypes.READ_OPERATION_MATERIALS_GROUPS_FAILURE,
  }
);

export const readOperationMaterialsGroup = makeThunkFromTypes(
  APIv1.readOperationMaterialsGroup,
  {
    request: actionTypes.READ_OPERATION_MATERIALS_GROUP_REQUEST,
    success: actionTypes.READ_OPERATION_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.READ_OPERATION_MATERIALS_GROUP_FAILURE,
  }
);

export const updateOperationMaterialsGroup = makeThunkFromTypes(
  APIv1.updateOperationMaterialsGroup,
  {
    request: actionTypes.UPDATE_OPERATION_MATERIALS_GROUP_REQUEST,
    success: actionTypes.UPDATE_OPERATION_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.UPDATE_OPERATION_MATERIALS_GROUP_FAILURE,
  }
);

export const deleteOperationMaterialsGroup = makeThunkFromTypes(
  APIv1.deleteOperationMaterialsGroup,
  {
    request: actionTypes.DELETE_OPERATION_MATERIALS_GROUP_REQUEST,
    success: actionTypes.DELETE_OPERATION_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.DELETE_OPERATION_MATERIALS_GROUP_FAILURE,
  }
);
