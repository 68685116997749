import { RequestId } from 'common-types/api';
import { MaterialElement, MaterialsGroupOption } from 'common-types/library';
import { LibraryCategories } from 'common-types/library';
import { LibraryElementsSearch } from 'components/LibraryItemsSearchInput';
import find from 'lodash/find';
import noop from 'lodash/noop';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { withoutRelationId } from 'store/utils';
import { SearchButton } from '../SearchButton/SearchButton';
import { useTranslation } from 'react-i18next';

interface Props {
  categories?: LibraryCategories[];
  groups?: MaterialsGroupOption[];
  selectedMaterials?: string[];
  onSelectMaterial?: (groupId: RequestId, material: MaterialElement) => void;
}

const selectUUID = (element) => element?.uuid;

export const MaterialsSearch: ComponentType<Props> = ({
  categories = [],
  groups,
  selectedMaterials,
  onSelectMaterial = noop,
}) => {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] =
    useState<MaterialsGroupOption | null>(null);
  const [selectedElement, setSelectedElement] = useState<any>(null);

  const groupsOptions: MaterialsGroupOption[] = useMemo(() => {
    if (!selectedElement || !groups) return [];

    return groups.filter(
      (group) =>
        !group?.categories ||
        (Array.isArray(group.categories) &&
          group.categories.includes(selectedElement.category))
    );
  }, [selectedElement, groups]);

  useEffect(() => {
    if (!groupsOptions.length) return;
    if (
      !selectedGroup ||
      (selectedGroup && !find(groupsOptions, { value: selectedGroup.value }))
    ) {
      setSelectedGroup(groupsOptions[0]);
    }
  }, [selectedGroup, groupsOptions]);

  const checkSelected = selectedMaterials
    ? (option) => selectedMaterials.includes(withoutRelationId(option.uuid))
    : false;

  const handleSelect = (element) => {
    setSelectedElement(element);
  };

  const handleAddToGroup = () => {
    if (!selectedGroup) return;
    onSelectMaterial(selectedGroup.value, selectedElement);
    setSelectedElement(null);
  };

  const inputAppend = groups ? (
    <SearchButton
      disabled={!selectedElement}
      group={selectedGroup ?? undefined}
      groups={groupsOptions}
      onAdd={handleAddToGroup}
      onGroupSelect={setSelectedGroup}
      className="mr-05"
    />
  ) : null;

  return (
    <>
      <LibraryElementsSearch
        autoFocus
        placeholder={t('searchMaterials')}
        categories={categories}
        checkSelected={checkSelected}
        inputAppend={inputAppend}
        onSelect={handleSelect}
        selectValue={selectUUID}
        value={selectedElement?.uuid}
      />
    </>
  );
};
