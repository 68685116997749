import * as APIv1 from 'api/v1';
import { uuid } from 'common-types/api';
import { Assessment, QualitativeFeedbackItem } from 'common-types/assessments';
import { makeThunkFromTypeString } from 'store/utils/make-thunk';

export const {
  thunk: updateAssessment,
  asyncActionTypes: {
    request: UPDATE_ASSESSMENT_REQUEST,
    success: UPDATE_ASSESSMENT_SUCCESS,
    failure: UPDATE_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString<Assessment>(
  APIv1.updateAssessment,
  'updateAssessment'
);

export const {
  thunk: createAssessment,
  asyncActionTypes: {
    request: CREATE_ASSESSMENT_REQUEST,
    success: CREATE_ASSESSMENT_SUCCESS,
    failure: CREATE_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.createAssessment, 'createAssessment');

export const {
  thunk: deleteAssessment,
  asyncActionTypes: {
    request: DELETE_ASSESSMENT_REQUEST,
    success: DELETE_ASSESSMENT_SUCCESS,
    failure: DELETE_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.deleteAssessment, 'deleteAssessment');

export const {
  thunk: readAssessments,
  asyncActionTypes: {
    request: READ_ASSESSMENTS_REQUEST,
    success: READ_ASSESSMENTS_SUCCESS,
    failure: READ_ASSESSMENTS_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.readAssessments, 'readAssessments');

export const {
  thunk: readAssessment,
  asyncActionTypes: {
    request: READ_ASSESSMENT_REQUEST,
    success: READ_ASSESSMENT_SUCCESS,
    failure: READ_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.readAssessment, 'readAssessment');

export const {
  thunk: submitAssessment,
  asyncActionTypes: {
    request: SUBMIT_ASSESSMENT_REQUEST,
    success: SUBMIT_ASSESSMENT_SUCCESS,
    failure: SUBMIT_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.submitAssessment, 'submitAssessment');

export const {
  thunk: autosaveAssessment,
  asyncActionTypes: {
    request: AUTOSAVE_ASSESSMENT_REQUEST,
    success: AUTOSAVE_ASSESSMENT_SUCCESS,
    failure: AUTOSAVE_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.submitAssessment, 'autosaveAssessment');

export const {
  thunk: verifyAssessment,
  asyncActionTypes: {
    request: VERIFY_ASSESSMENT_REQUEST,
    success: VERIFY_ASSESSMENT_SUCCESS,
    failure: VERIFY_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.verifyAssessment, 'verifyAssessment');

export const {
  thunk: signAssessment,
  asyncActionTypes: {
    request: SIGN_ASSESSMENT_REQUEST,
    success: SIGN_ASSESSMENT_SUCCESS,
    failure: SIGN_ASSESSMENT_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.signAssessment, 'signAssessment');

export const setAssessmentExplanations =
  (
    assessmentId: uuid,
    subCompetenceId: uuid,
    explanations: QualitativeFeedbackItem[]
  ): any =>
  (dispatch) => {
    APIv1.setAssessmentExplanations(assessmentId, subCompetenceId, explanations)
      .then((response: any) => {
        // Idk why its undefined that's what we are getting from BE
        dispatch({
          type: 'SET_EXPLANATIONS_ASSESSMENT_SUCCESS',
          payload: response.entities.assessments.undefined[0],
        });
      })
      .catch((error: any) => {
        dispatch({ type: 'SET_EXPLANATIONS_ASSESSMENT_FAILURE', error });
      });
  };
