import { FC } from 'react';
import { OverflowText, Text } from 'components';

interface TitleProps {
  pageTitle: string;
  institute: string;
  traineeName?: string;
}

export const Title: FC<TitleProps> = ({
  pageTitle,
  institute,
  traineeName,
}) => {
  return (
    <>
      <Text variant="heading-xl" className="mb-0">
        <OverflowText component="span" maxLines={1}>
          {pageTitle}
        </OverflowText>
      </Text>
      {traineeName ? (
        <Text>{traineeName}</Text>
      ) : (
        <Text variant="heading-md">{institute}</Text>
      )}
    </>
  );
};
