const splitNumber = phone => {
  let code = '';
  let phoneNumber = '';

  if (phone) [code, phoneNumber] = phone.replace('(', '').split(')');

  return {
    code,
    phoneNumber,
  };
};

export default splitNumber;
