import { Icon } from 'components/Icon';
import { Loader } from 'components/Loader';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { id } from '../../common-types/api';
import { Category } from '../../common-types/templates';
import {
  createErrorsSelector,
  createLoadingSelector,
} from '../../store/modules/api-requests';
import { selectTemplateCategories } from '../../store/modules/entities/selectors/selectors';
import { readTemplateCategories } from '../../store/modules/template-categories';
import { READ_TEMPLATE_CATEGORIES_REQUEST } from '../../store/modules/template-categories/types';
import { getCategoriesTree } from '../../utils/get-categories-tree';
import { TemplateCategoryLabel } from './TemplateCategoryLabel';

interface Props {
  id: id;
}

interface TemplateCategoriesHook {
  categories: Category[];
  loading: boolean;
  error: object[];
}

const useTemplateCategories = ({ id }): TemplateCategoriesHook => {
  const loading = useSelector(
    createLoadingSelector([READ_TEMPLATE_CATEGORIES_REQUEST])
  );
  const error = useSelector(
    createErrorsSelector([READ_TEMPLATE_CATEGORIES_REQUEST])
  );
  const dispatch = useDispatch();
  const categoriesEntities = useSelector<any, { [index: string]: Category }>(
    selectTemplateCategories
  );
  const categories = useMemo(() => {
    const categories = getCategoriesTree(id, categoriesEntities);
    return categories;
  }, [categoriesEntities, id]);

  async function fetch() {
    await dispatch(readTemplateCategories());
  }

  useEffect(() => {
    if (!categoriesEntities || !Object.keys(categoriesEntities).length) fetch();
  }, [categoriesEntities]);

  return {
    categories,
    loading,
    error,
  };
};

export const TemplateCategory = ({ id }: Props): ReactElement => {
  const { categories, loading } = useTemplateCategories({ id });

  if (loading) return <Loader mode="minimal" />;

  return (
    <>
      {categories.map((category, index) => {
        const { id, name } = category;
        const isLast = index === categories.length - 1;

        return (
          <div key={index}>
            <TemplateCategoryLabel key={id} id={id} name={name} />
            {!isLast && (
              <Icon
                name="chevron-right"
                className="text-placeholder text-xs pr-1 pl-1"
              />
            )}
          </div>
        );
      })}
    </>
  );
};
