import React, { FC } from 'react';

import styles from './Alert.module.scss';

import { Modal } from 'components/Modal';
import { Button } from 'components/Button';

interface AlertProps {
  /**
   * Flag to open/close alert
   */
  opened?: boolean;
  /**
   * Alert title
   */
  title?: string;
  /**
   * Submit alert label button
   */
  submitLabel?: string;
  /**
   * Callback to be fired on submit button click
   */
  onSubmit: VoidFunction;
  /**
   * Callback to be fired on overlay, close button click
   */
  onClose: VoidFunction;
}

/**
 * Renders alert modal
 */
export const Alert: FC<AlertProps> = ({
  children,
  opened,
  title,
  submitLabel = 'Ok',
  onSubmit,
  onClose,
}) => {
  return (
    <Modal opened={opened} title={title} onRequestClose={onClose}>
      {children}

      <div className={styles.footer}>
        <Button variant="primary" onClick={onSubmit}>
          {submitLabel}
        </Button>
      </div>
    </Modal>
  );
};
