import React, { FC } from 'react';

// FIXME: this can be optimized with Suspense/lazy.
// It requires investigating how to fix tests after those changes
import { InputBase } from './InputBase';
import { InputDuration } from './InputDuration';
import { InputMask } from './InputMask';
import { InputFilename } from './InputFilename';
import translatableHOC from 'components/Form/translatableHOC';

interface InputProps {
  mask?: string | null;
  mode?: 'default' | 'duration' | 'translatable' | 'filename';
  [key: string]: any;
}

const Input: FC<InputProps> = ({
  mask = null,
  mode = 'default',
  ...inputProps
}) => {
  const getInputComponent = () => {
    if (mask) {
      return InputMask;
    }

    switch (mode) {
      case 'duration':
        return InputDuration;
      case 'translatable':
        return translatableHOC(InputBase);
      case 'filename':
        return InputFilename;
      default:
        return InputBase;
    }
  };

  const InputComponent = getInputComponent();

  return <InputComponent {...inputProps} />;
};

export default Input;
