import {
  CLEAN_NOTIFY_MESSAGE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  READ_CURRENT_USER_DATA_SUCCESS,
  LOGIN_CLEAR_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from './types';

export const initialState = {
  userId: null,
  error: null,
  deactivated: false,
  isLoading: false,
  notify: {
    status: '',
    message: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LOGIN_SUCCESS: {
      const { result: userId } = action.payload;
      return {
        ...state,
        userId,
        isLoading: false,
      };
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        userId: null,
        isLoading: false,
        deactivated: action.payload?.error?.error?.deactivated,
        ...action.payload,
      };
    }

    case READ_CURRENT_USER_DATA_SUCCESS: {
      return {
        userId: action.payload.result,
      };
    }

    case LOGIN_CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        notify: {
          status: 'success',
        },
      };
    }

    case FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        notify: {
          status: 'error',
        },
      };
    }

    case CLEAN_NOTIFY_MESSAGE: {
      return {
        ...state,
        notify: {
          status: '',
          message: '',
        },
      };
    }

    default:
      return state;
  }
};
