import cn from 'classnames';
import React, { ComponentType, HTMLAttributes } from 'react';
import classes from './Indicator.module.scss';

interface Props {
  length: number;
  limit: number;
  isCloseToLimit?: boolean;
  isOverLimit?: boolean;
}

export const IndicatorBase: ComponentType<
  Props & HTMLAttributes<HTMLElement>
> = ({
  className,
  length,
  limit,
  isCloseToLimit = false,
  isOverLimit = false,
  ...restProps
}) => {
  return (
    <span
      className={cn(classes.container, {
        [classes.closeToLimit]: isCloseToLimit,
        [classes.overLimit]: isOverLimit,
      })}
      {...restProps}
    >
      <span className={classes.length}>{length}</span>&nbsp;/&nbsp;
      <span className={classes.limit}>{limit}</span>
    </span>
  );
};

IndicatorBase.displayName = 'IndicatorBase';
