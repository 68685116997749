import Input from 'components/Form/Input';
import React, {
  ComponentType,
  HTMLAttributes,
  useContext,
  useState,
} from 'react';
import { MaterialsFormContext } from '../../context';
import classes from './ElementCounter.module.scss';
interface Props {
  count: number;
  id: string;
  onChange: (value: number) => void;
}

export const ElementCounter: ComponentType<
  Props & HTMLAttributes<HTMLSpanElement>
> = ({ id, count, onChange, ...props }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const { readonly, isLoading } = useContext(MaterialsFormContext);

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(Number(value));
    setEdit(false);
  };

  const enableEdit = () => setEdit(true);

  return (
    <span {...props}>
      {!readonly && edit ? (
        <Input
          type="number"
          id={id}
          min={1}
          className={classes.input}
          defaultValue={count}
          onBlur={handleChange}
          disabled={isLoading}
          autoFocus
        />
      ) : (
        <span
          role="button"
          className={classes.count}
          onClick={readonly ? undefined : enableEdit}
        >
          {count}
        </span>
      )}
    </span>
  );
};
