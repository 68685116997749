import React, { Component } from 'react';
import { bool, func, string, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Modal } from 'components';
import {
  selectActiveModalName,
  hideModalDialog,
  selectActiveModalProps,
  selectActiveModalDialogProps,
} from 'store/modules/modal-dialogs';

class ConnectedModal extends Component {
  static _propTypes = {
    activeModalName: string,
    allowClose: bool,
    hideModal: func.isRequired,
    name: string.isRequired,
    /* eslint-disable react/forbid-prop-types */
    modalProps: object,
    dialogProps: object,
    /* eslint-enable react/forbid-prop-types */
  };

  static defaultProps = {
    activeModalName: null,
    allowClose: true,
    dialogProps: {},
    modalProps: {},
  };

  render() {
    const {
      name,
      activeModalName,
      hideModal,
      allowClose,
      children,
      modalProps,
      dialogProps,
      ...restProps
    } = this.props;
    const isOpen = name === activeModalName;
    const innerModalProps = {
      ...restProps,
      ...modalProps,
      isOpen,
      onRequestClose: allowClose ? hideModal : undefined,
    };

    if (typeof children === 'function') {
      return (
        <Modal {...innerModalProps}>{children(isOpen, dialogProps)}</Modal>
      );
    }

    return <Modal {...innerModalProps}>{children}</Modal>;
  }
}

const mapStateToProps = createStructuredSelector({
  activeModalName: selectActiveModalName,
  modalProps: selectActiveModalProps,
  dialogProps: selectActiveModalDialogProps,
});
export default connect(mapStateToProps, { hideModal: hideModalDialog })(
  ConnectedModal
);
