import cn from 'classnames';
import { DataTag } from 'components/DataTag';
import {
  EditableDurationLabel,
  FormattedDuration,
} from 'components/DurationLabels';
import { ManualPopover } from 'components/Popover/ManualPopover';
import { Text } from 'components/Typography/Text';
import isNumber from 'lodash/isNumber';
import React, { ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OperationStep.module.scss';

interface Props {
  actualTime?: number;
  estimatedTime?: number;
  label?: string;
  onChange?: (duration: number) => void;
  readonly?: boolean;
}

export const StepEstimation: ComponentType<Props> = ({
  actualTime,
  estimatedTime = 0,
  label,
  onChange,
  readonly,
}) => {
  const { t } = useTranslation();

  const delay: number | null = useMemo(() => {
    return isNumber(actualTime) ? actualTime - (estimatedTime || 0) : null;
  }, [actualTime, estimatedTime]);

  return (
    <Text variant="caption">
      <EditableDurationLabel
        duration={estimatedTime}
        label={label || t('estimatedDuration')}
        onChange={!readonly ? onChange : undefined}
        propKey="estimated-duration"
      />
      {isNumber(delay) && (
        <span
          className={cn('delay pointer ml-05', styles.delay, {
            'text-success': delay <= 0,
            'text-error': delay > 0,
          })}
        >
          <span style={{ position: 'relative' }}>
            <FormattedDuration showPlus showSeconds={false} duration={delay}>
              {(formatted) => <DataTag propKey="delay">{formatted}</DataTag>}
            </FormattedDuration>
            <ManualPopover className={styles.popover}>
              {t('actualDuration')}:{' '}
              <FormattedDuration
                duration={actualTime}
                showSeconds={false}
                render={(formatted) => (
                  <DataTag propKey="actual-duration">{formatted}</DataTag>
                )}
              />
            </ManualPopover>
          </span>
        </span>
      )}
    </Text>
  );
};

export default StepEstimation;
