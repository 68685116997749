import { isNil } from 'lodash';
import i18n from 'translations/i18n-instance';

export const translatableValue = (value, lang = i18n.language) => {
  if (isNil(value)) return value;
  const translatable = { [lang]: value };
  return translatable;
};

export default translatableValue;
