import { useState, useEffect } from 'react';

export const useReactPath = () => {
  const [path, setPath] = useState(window.location);
  const listenToPopstate = () => {
    const winPath = window.location;
    setPath(winPath);
  };
  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate);
    return () => {
      window.removeEventListener('popstate', listenToPopstate);
    };
  }, []);
  return path;
};
