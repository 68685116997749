import { DropList, DropListItem } from 'components/DropList';
import { Icon } from 'components/Icon';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  attachmentsCount?: number;
  isElementUpdated?: boolean;

  onAddElementToLibrary: () => void;
  onAttachImage: () => void;
  onAttachmentsRemove?: () => void;
  onElementDelete: () => void;
}

export const OperationStepMenu: ComponentType<Props> = ({
  attachmentsCount = 10,
  isElementUpdated = false,
  onAddElementToLibrary,
  onAttachImage,
  onAttachmentsRemove,
  onElementDelete,
}) => {
  const { t } = useTranslation();

  return (
    <DropList dropdownBtnClass="m-0" placement="bottom-start">
      <DropListItem className="nowrap" onClick={onAttachImage}>
        <Icon name="attachment" /> {t('attachImage', { count: 2 })}
      </DropListItem>
      {!!attachmentsCount && (
        <DropListItem className="nowrap" onClick={onAttachmentsRemove}>
          <Icon name="close" /> {t('removeImage', { count: attachmentsCount })}
        </DropListItem>
      )}
      {isElementUpdated && (
        <DropListItem className="nowrap" onClick={onAddElementToLibrary}>
          <Icon name="library" /> {t('addToLibrary')}
        </DropListItem>
      )}
      <DropListItem
        name="delete"
        onClick={onElementDelete}
        className="nowrap delete-button"
      >
        <Icon name="delete" /> {t('deleteStep')}
      </DropListItem>
    </DropList>
  );
};

OperationStepMenu.displayName = 'OperationStepMenu';
