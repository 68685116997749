import * as APIv1 from 'api/v1';
import { makeThunkFromTypes } from 'store/utils/make-thunk';
import * as types from './types';

export const createAssessmentForm = makeThunkFromTypes(
  APIv1.createAssessmentForm,
  {
    request: types.CREATE_ASSESSMENT_FORM_REQUEST,
    success: types.CREATE_ASSESSMENT_FORM_SUCCESS,
    failure: types.CREATE_ASSESSMENT_FORM_FAILURE,
  }
);

export const readAssessmentForms = makeThunkFromTypes(
  APIv1.readAssessmentForms,
  {
    request: types.READ_ASSESSMENT_FORMS_REQUEST,
    success: types.READ_ASSESSMENT_FORMS_SUCCESS,
    failure: types.READ_ASSESSMENT_FORMS_FAILURE,
  }
);

export const deleteAssessmentFormById = makeThunkFromTypes(
  APIv1.deleteAssessmentFormById,
  {
    request: types.DELETE_ASSESSMENT_FORM_REQUEST,
    success: types.DELETE_ASSESSMENT_FORM_SUCCESS,
    failure: types.DELETE_ASSESSMENT_FORM_FAILURE,
  }
);

export const updateAssessmentForm = makeThunkFromTypes(
  APIv1.updateAssessmentForm,
  {
    request: types.UPDATE_ASSESSMENT_FORM_REQUEST,
    success: types.UPDATE_ASSESSMENT_FORM_SUCCESS,
    failure: types.UPDATE_ASSESSMENT_FORM_FAILURE,
  }
);
