import { isEmpty, pick } from 'lodash';
import { createSelector } from 'reselect';
import { selectDefaultCategoryId } from 'store/modules/auth';
import { selectTemplateCategories as selectTemplateCategoriesEntities } from 'store/modules/entities/selectors/selectors';
import { createStateGetter } from 'store/utils';
import Bugsnag from '@bugsnag/js';
import { isCategoryHasTemplates } from 'scenes/TemplatesLibrary/TemplatesList/TemplateCategoriesFilters/isCategoryHasTemplates';

export const selectCategoriesIds = createStateGetter('templateCategories', []);

export const selectCategories = createSelector(
  [selectCategoriesIds, selectTemplateCategoriesEntities],
  (ids, entities) => {
    if (!ids || !entities) return null;
    return ids.reduce((acc, id) => {
      if (!entities[id]) return acc;
      return [...acc, entities[id]];
    }, []);
  }
);
/*
 * selectCategoriesWithTotalCalculated
 *   @return - Array(result, error)
 *     result - Array<categories>
 *     error - Error | null
 * */
export const selectCategoriesWithTotalCalculated = createSelector(
  selectCategories,
  (selection) => {
    let categories = Array.isArray(selection) ? selection : [];
    try {
      const getRecursive = ({ id }) =>
        categories.reduce(
          (acc, cat) =>
            cat.parentId === id ? [...acc, cat, ...getRecursive(cat)] : acc,
          []
        );

      categories = categories
        .map((category) => ({
          ...category,
          total: getRecursive(category).reduce(
            (acc, cur) => acc + cur.count,
            category.count
          ),
        }))
        .sort((a, b) => b?.total - a?.total);

      return [categories, null];
    } catch (error) {
      /**
       * This is check for circular dependencies in template categories.
       *
       * The `selectCategoriesWithTotalCalculated` goes through each category and
       * calculates the `total` prop, which is sum of all children's `count`.
       * Since there is no check for circular dependencies in template categories
       * on server, user can unintentionally (I hope) create them in admin panel,
       * which leads to infinite loop within this selector.
       *
       * To prevent that we're catching `RangeError` error and returning
       * categories list without calculated total, then in library navigation
       * component - empty categories should not be hidden, because their children
       * could have some templates
       */
      if (error.name === 'RangeError') {
        const error = new RangeError(
          'There is circular relation in Template Categories. Templates navigation displayed fallback version'
        );
        Bugsnag.notify(error);
      } else {
        Bugsnag.notify(error);
      }

      return [categories, error];
    }
  }
);

export const getTopLevelCategories = (categories) => {
  if (isEmpty(categories)) return [];
  return categories.filter(({ parentId }) => !parentId);
};

export const selectTopLevelTemplateCategories = createSelector(
  [selectCategories],
  getTopLevelCategories
);

export const selectDefaultCategoryData = createSelector(
  [selectTemplateCategoriesEntities, selectDefaultCategoryId],
  (categories, defaultCategoryId) => {
    if (!categories || !defaultCategoryId) return null;
    return categories[defaultCategoryId];
  }
);

export const selectCategoriesByRelation = (
  storeCategories,
  parentId = null,
  valuesToPick = null
) => {
  if (!storeCategories) return [];
  const categories = [...storeCategories];
  categories.forEach((category) => {
    const relatedCategories = categories.filter(
      (cat) => cat.parentId === category.id
    );
    /* eslint-disable no-param-reassign */
    category.nested = relatedCategories;
  });

  const categoriesTree = categories.filter((cat) => cat.id === parentId);

  if (valuesToPick) {
    const values = [];
    let unflatten;

    const pickValues = (val) =>
      Array.isArray(valuesToPick) ? pick(val, valuesToPick) : val[valuesToPick];

    (unflatten = (nestedCategories) =>
      nestedCategories.forEach((cat) => {
        values.push(pickValues(cat));
        if (cat.nested) unflatten(cat.nested);
      }))(categoriesTree);

    return values;
  }

  return categoriesTree;
};

export const selectCategoriesParentsChain = (categoryId, categories) => {
  const relatedCategories = [];

  const search = (id) => {
    if (id) {
      const foundParent = categories.find((cat) => cat.id === id);
      if (!foundParent) return;

      relatedCategories.push(foundParent);
      return search(foundParent.parentId);
    }
    return null;
  };

  search(parseInt(categoryId, 10));

  if (!relatedCategories.length) return null;

  return relatedCategories.reverse();
};

export const hasCategoriesSelector = createSelector(
  selectCategories,
  (categories) => Boolean(categories && categories.length)
);

export const getSubcategories = (
  categories,
  selectedParentId,
  hideEmpty = false
) => {
  if (isEmpty(categories) || !selectedParentId) return [];
  return categories.filter((category) => {
    if (hideEmpty && !isCategoryHasTemplates(category)) {
      return false;
    }
    return category?.parentId === selectedParentId;
  });
};
