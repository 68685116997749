import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';

import { MaterialsGroupOption } from 'common-types/library';
import { Button } from 'components/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { stopPropagation } from 'utils/stop-propagation';

interface SearchButtonProps {
  disabled?: boolean;
  group?: MaterialsGroupOption;
  groups: MaterialsGroupOption[];
  onAdd?: (selectedGroup: MaterialsGroupOption) => void;
  onGroupSelect?: (selectedGroup: MaterialsGroupOption) => void;
  className?: string;
}

export const SearchButton: FC<SearchButtonProps> = ({
  disabled = false,
  group,
  groups,
  onAdd = noop,
  onGroupSelect = noop,
  className,
}) => {
  const { t } = useTranslation();
  if (!groups || !groups.length) return null;

  let label = t('Add');
  if (group && group?.label) {
    label = t('addToMaterialsGroup', {
      materialsGroup: group?.label,
    });
  }

  const handleButtonClick = () => {
    onAdd(group);
  };

  return (
    <div onClick={stopPropagation} className={className}>
      {groups?.length > 1 ? (
        <ButtonGroup
          size="sm"
          disabled={!groups.length || disabled}
          options={groups}
          variant="secondary"
          onClick={handleButtonClick}
          onSelect={onGroupSelect}
        >
          {label}
        </ButtonGroup>
      ) : (
        <Button
          size="sm"
          className="nowrap"
          disabled={!groups.length || disabled}
          variant="secondary"
          onClick={handleButtonClick}
        >
          {label}
        </Button>
      )}
    </div>
  );
};

SearchButton.displayName = 'SearchButton';
