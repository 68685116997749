import { denormalize, Schema } from 'normalizr';
import { createSelector } from 'reselect';
import { createStateGetter } from 'store/utils';

const entitiesGetter = (entityPath, defaultValue?) =>
  createStateGetter(`entities.${entityPath}`, defaultValue);

export const selectEntities = createStateGetter('entities', {});

export const selectAddresses = entitiesGetter('addresses');
export const selectAttachments = entitiesGetter('attachments');
export const selectAssessments = entitiesGetter('assessments');
export const selectDebriefingQuestions = entitiesGetter('debriefingQuestions');
export const selectDebriefings = entitiesGetter('debriefings');
export const selectDepartments = entitiesGetter('departments');
export const selectDiagnoses = entitiesGetter('diagnoses');
export const selectIncidents = entitiesGetter('incidents');
export const selectInstitutes = entitiesGetter('institutes');
export const selectOperations = entitiesGetter('operations');
export const selectOperationStepsActualTime = entitiesGetter(
  'operationStepsActualTime'
);
export const selectTemplates = entitiesGetter('templates');
export const selectPatients = entitiesGetter('patients');
export const selectReports = entitiesGetter('reports');
export const selectTemplateCategories = entitiesGetter('templateCategories');
export const selectTimeoutQuestions = entitiesGetter('timeoutQuestions');
export const selectUsers = entitiesGetter('users');
export const selectLibraryElements = entitiesGetter('libraryElements');
export const selectTemplateMaterials = entitiesGetter('templateMaterials');
export const selectOperationMaterials = entitiesGetter('operationMaterials');
export const selectVitalInformationEntities =
  entitiesGetter('vitalInformation');
export const selectMaterialsGroups = entitiesGetter('materialsGroups');

export const createDenormalizeSelector = (entitySelector, schema: Schema) =>
  createSelector([entitySelector, selectEntities], (entity, entities) =>
    denormalize(entity, schema, entities)
  );
