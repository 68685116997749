import React, { FC, memo } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

interface StatefulNavLinkProps extends Omit<NavLinkProps, 'to'> {
  /**
   * Link destination. If undefined then it uses "from" property from location state.
   */
  to?: string;
}

/**
 * Extract domain from pathname
 */
const getPathDomain = (pathname: string): string | null => {
  const match = pathname.match(/^\/(.*)\/?/);

  if (!match) {
    return match;
  }

  const [, domain] = match;

  return domain;
};

/**
 * Link that uses location.state applied by PersistStateLink
 */
export const StatefulNavLink: FC<StatefulNavLinkProps> = memo(
  function StatefulNavLink({ children, to, ...props }) {
    const { state, pathname: currentPathname } = useLocation<{
      search?: string;
      from?: string;
    }>();

    const pathname = to || state?.from;
    const currentDomain = getPathDomain(currentPathname);
    const nextDomain = pathname && getPathDomain(pathname);
    const isSameDomain = currentDomain === nextDomain;
    const search = isSameDomain ? state?.search : '';
    const newState = isSameDomain
      ? { from: currentPathname, search: state?.search || '' }
      : { from: currentPathname };

    return (
      <NavLink
        to={{
          pathname,
          search,
          state: newState,
        }}
        {...props}
      >
        {children}
      </NavLink>
    );
  }
);
