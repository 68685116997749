import { Component, lazy, Suspense } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Bugsnag from '@bugsnag/js';

import {
  APP_CRASHED,
  ASSESSMENT_FORMS,
  ASSESSMENTS,
  HOME,
  DASHBOARD,
  HISTORY_OPERATIONS,
  INCIDENTS_REPORTS,
  QUALIFICATION,
  QUALIFICATION_FORMS,
  RESEARCH,
  SETTINGS,
  STYLEGUIDE,
  TEMPLATES_LIBRARY,
} from 'scenes/routes.enum';
import { RebrandingAlert } from 'modules/rebranding/RebrandingAlert';
import { Loader, Panel } from 'components';

const Dashboard = lazy(() => import('scenes/Dashboard'));
const History = lazy(() => import('scenes/History'));
const IncidentsReporting = lazy(() => import('scenes/Incidents'));
const Library = lazy(() => import('scenes/TemplatesLibrary'));
const Research = lazy(() => import('scenes/Research'));
const StyleGuide = lazy(() => import('scenes/StyleGuide'));
const Settings = lazy(() => import('scenes/Settings'));
const AssessmentsCollection = lazy(
  () => import('scenes/Assessments/AssessmentsCollection')
);
const AssessmentForms = lazy(() => import('scenes/AssessmentForms'));
const QualificationForms = lazy(() => import('scenes/QualificationForm'));
const Qualifications = lazy(() => import('scenes/Qualification'));

class Main extends Component<RouteComponentProps> {
  state = {
    error: null,
    returnPath: '/',
  };

  componentDidCatch(error: Error) {
    this.setState({ error, returnPath: this.props.location.pathname });
    Bugsnag.notify(error);
  }

  render() {
    if (this.state.error) {
      return (
        <Redirect to={`${APP_CRASHED}?returnPath=${this.state.returnPath}`} />
      );
    }

    return (
      <>
        <Suspense
          fallback={
            <Panel fullPage="fixed">
              <Loader />
            </Panel>
          }
        >
          <Switch>
            <Route path={DASHBOARD} component={Dashboard} />
            <Route path={TEMPLATES_LIBRARY} component={Library} />
            <Route path={HISTORY_OPERATIONS} component={History} />
            <Route path={ASSESSMENTS} component={AssessmentsCollection} />
            <Route path={ASSESSMENT_FORMS} component={AssessmentForms} />
            <Route path={INCIDENTS_REPORTS} component={IncidentsReporting} />
            <Route path={RESEARCH} component={Research} />
            <Route path={SETTINGS} component={Settings} />
            <Route path={STYLEGUIDE} component={StyleGuide} />
            <Route path={QUALIFICATION_FORMS} component={QualificationForms} />
            <Route path={QUALIFICATION} component={Qualifications} />
            <Redirect to={HOME} />
          </Switch>
        </Suspense>

        <RebrandingAlert />
      </>
    );
  }
}

export default Main;
