import { NOT_DEFINED } from 'app-constants';

const formatHours = (hours) => `${hours}hr`;
const formatMinutes = (minutes) => `${minutes}min`;
const formatSeconds = (seconds) => `${seconds}sec`;

export default function humanReadableDuration(
  seconds: number,
  showSeconds: boolean = false,
  showPlus: boolean = false
): string {
  if (!seconds) {
    return NOT_DEFINED;
  }

  const isNegative = seconds < 0;
  const sign = isNegative ? '-' : showPlus ? '+' : '';
  const positiveSeconds = Math.abs(seconds);
  let formattedDuration;

  if (positiveSeconds <= 60 && !showSeconds) {
    formattedDuration = formatMinutes(1);
  } else {
    const hr = Math.floor(positiveSeconds / 3600);
    let min;
    let sec;

    if (showSeconds) {
      min = Math.floor((positiveSeconds - hr * 3600) / 60);
      sec = Math.floor(positiveSeconds - hr * 3600 - min * 60);
    } else {
      min = Math[isNegative ? 'floor' : 'ceil'](
        (positiveSeconds - hr * 3600) / 60
      );
    }

    formattedDuration = [
      hr && formatHours(hr),
      min && formatMinutes(min),
      showSeconds && sec && formatSeconds(sec),
    ]
      .filter(Boolean)
      .join(' ');
  }

  return `${sign}${formattedDuration}`;
}
