import { LibraryElement } from 'common-types/library';
import { LibraryCategories } from 'common-types/library';
import { Modal } from 'components/Modal';
import AddLibraryItemForm from 'containers/LibraryItemsSearch/AddLibraryItemForm';
import { noop } from 'lodash';
import React, { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LibraryElementsSearchInput,
  Props as LibraryElementsSearchInputProps,
} from './LibraryElementsSearchInput';
import { useLibrarySearch } from './useLibrarySearch';

interface Props {
  categories: LibraryCategories[];
  initialElementValues?: Partial<LibraryElement>;
  onSelect?: (element: LibraryElement) => any;
}

type LibraryElementsSearchType = ComponentType<
  Props &
    Omit<
      LibraryElementsSearchInputProps,
      | 'error'
      | 'isLoading'
      | 'onReset'
      | 'onSearch'
      | 'results'
      | 'onElementCreate'
      | 'onSelect'
    >
>;

export const LibraryElementsSearch: LibraryElementsSearchType = ({
  categories,
  onSelect = noop,
  initialElementValues,
  ...inputProps
}) => {
  const [query, setQuery] = useState<string | null>(null);
  const { t } = useTranslation();
  const { results, reset, search, loading, error } =
    useLibrarySearch(categories);

  const handleCreate = (query) => {
    setQuery(query);
  };

  const closeModal = () => {
    setQuery(null);
  };

  const handleSelect = (element) => {
    onSelect(element);
    closeModal();
  };

  return (
    <>
      <LibraryElementsSearchInput
        error={!!error}
        isLoading={loading}
        results={results}
        onElementCreate={handleCreate}
        onReset={reset}
        onSearch={search}
        onSelect={handleSelect}
        {...inputProps}
      />
      <Modal
        title={t('addNewItemToLibrary')}
        opened={typeof query === 'string'}
        onRequestClose={closeModal}
      >
        <AddLibraryItemForm
          categories={categories}
          element={initialElementValues}
          name={query}
          onNewItemAdded={handleSelect}
        />
      </Modal>
    </>
  );
};

LibraryElementsSearch.displayName = 'LibraryElementsSearch';
