import React, { ComponentType, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { DropList, ConfirmModal, DropListItem } from 'components';
import {
  createOperationByIdSelector,
  deleteOperation,
  selectOperationTemplate,
} from '../../../store/modules/operation-planning';
// import {
//   createIsOwnTemplateSelector,
//   createTemplateByIdSelector,
// } from '../../../store/modules/templates-library';
import { withParams } from '../../../utils';
import CopyTemplateModal from '../../OperationPlanning/forms/CopyTemplateModal';
import { DASHBOARD, TEMPLATE } from '../../routes.enum';

interface Props {}

export const OperationTitleMenu: ComponentType<Props> = () => {
  const { operationId } = useParams<{ operationId?: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const operationTemplate = useSelector((state) =>
    // @ts-ignore
    selectOperationTemplate(state, { operationId })
  );
  const operation = useSelector(createOperationByIdSelector(operationId));
  // const template = useSelector(createTemplateByIdSelector(templateId));
  // const isOwnTemplate = useSelector(
  //   createIsOwnTemplateSelector(template.parentId)
  // );
  /**
   * FIXME:
   * show this option if template is owned the user
   * ref: NM-2206
   *  <DropListItem icon="refresh">
   *    {t('saveChangesToTheOriginalTemplate')}
   *  </DropListItem>
   */
  const [confirm, setConfirm] = useState(false);
  const [showTemplateCopyModal, setShowTemplateCopyModal] = useState(false);

  const handleDeleteOperation = async () => {
    if (!operationId) return;
    await dispatch(deleteOperation(operationId));
    history.replace(DASHBOARD);
  };

  const openConfirm = () => {
    setConfirm(true);
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  // const openTemplateCopyModal = () => {
  //   setShowTemplateCopyModal(true);
  // };

  const closeTemplateCopyModal = () => {
    setShowTemplateCopyModal(false);
  };

  const analyticsDelete = () => {
    ReactGA.event({
      category: 'Title Menu',
      action: 'Delete OP',
    });
  };

  const analyticsOrigin = () => {
    ReactGA.event({
      category: 'Title Menu',
      action: 'See Original Template',
    });
  };

  if (!operation || !operationTemplate) return null;
  const { parentId: originalTemplateId, id: templateId } = operationTemplate;

  return (
    <>
      <ConfirmModal
        title={t('deleteOperation')}
        content={t('deleteOperation')}
        confirmButtonLabel={t('Delete')}
        declineButtonLabel={t('cancel')}
        onDecline={closeConfirm}
        onConfirm={handleDeleteOperation}
        confirmButtonVariant="secondary-danger"
        isOpen={confirm}
      />

      <CopyTemplateModal
        id={templateId}
        operationTitle={operation?.title}
        isOpen={showTemplateCopyModal}
        onClose={closeTemplateCopyModal}
      />

      <DropList label={t('Options')} size="md">
        <DropListItem icon="library">
          {originalTemplateId && (
            <Link
              className="link-text"
              to={withParams(TEMPLATE, { templateId: originalTemplateId })}
              onClickCapture={analyticsOrigin}
            >
              {t('seeOriginalTemplate')}
            </Link>
          )}
        </DropListItem>
        {/*   {isOwnTemplate ? null : (
          <DropListItem icon="copy" onClick={openTemplateCopyModal}>
            {t('saveAsMyOwnTemplate')}
          </DropListItem>
        )} */}
        <DropListItem
          className="text-error"
          icon="delete"
          onClickCapture={analyticsDelete}
          onClick={openConfirm}
        >
          {t('deleteOperation')}
        </DropListItem>
      </DropList>
    </>
  );
};

OperationTitleMenu.displayName = 'OperationTitleMenu';
