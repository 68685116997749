import { AllowedMimeType } from 'app-constants/files';
import cn from 'classnames';
import { Attachment as AttachmentT } from 'common-types/attachments';
import { IconButton } from 'components/Button';
import { ConfirmModal } from 'components/Confirm';
import { Editable } from 'components/Editable';
import { Col, Row } from 'components/Grid';
import React, { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentPic } from '../AttachmentPic/AttachmentPic';
import { AttachmentUpdateFormModal } from '../AttachmentUpdateForm/AttachmentUpdateFormModal';
import classes from './AttachmentItem.module.scss';

interface Props {
  description?: AttachmentT['description'];
  fileUrl: AttachmentT['fileUrl'];
  id: AttachmentT['id'];
  name: AttachmentT['name'];
  mimeType?: AllowedMimeType;

  className?: string;
  onPictureClick?: (id: AttachmentT['id']) => void;
  onRemove?: (id: AttachmentT['id']) => void;
}

export const AttachmentItemEditable: ComponentType<Props> = (props) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const {
    className,
    description = '',
    fileUrl = '',
    id,
    name,
    mimeType,
    onPictureClick,
    onRemove,
  } = props;

  const canRemove = typeof onRemove === 'function';

  const [confirmAction, setConfirmAction] = useState<{
    onConfirm: () => any;
    label?: string;
  } | null>(null);

  const handleRemoveRequest = () => {
    if (!onRemove) return;
    setConfirmAction({
      onConfirm() {
        setConfirmAction(null);
        return onRemove(id);
      },
    });
  };

  const handlePicClick = () => {
    if (!onPictureClick) return;
    onPictureClick(id);
  };

  const handleConfirmCancel = () => {
    setConfirmAction(null);
  };

  const enableEditMode = () => setEditMode(true);
  const disableEditMode = () => setEditMode(false);

  return (
    <>
      <Row
        className={cn(classes.container, 'm-0 pt-05 pb-05', className)}
        nowrap
        alignItems="center"
      >
        <Col auto>
          <AttachmentPic
            className={cn({ pointer: onPictureClick })}
            onClick={handlePicClick}
            fileUrl={fileUrl}
            name={name}
            description={description}
            mimeType={mimeType}
          />
        </Col>

        <Col className={classes.name}>
          <Editable role="button" onClick={enableEditMode}>
            {name}
          </Editable>
        </Col>
        {canRemove && (
          <Col auto className={classes.menuCol}>
            <IconButton icon="delete" onClick={handleRemoveRequest} />
          </Col>
        )}
      </Row>

      <AttachmentUpdateFormModal
        opened={editMode}
        attachmentId={id}
        onRequestClose={disableEditMode}
      />

      {confirmAction?.onConfirm && (
        <ConfirmModal
          title={t('removeAttachment')}
          content={confirmAction.label ?? t('removeAttachment?')}
          confirmButtonLabel={t('Remove')}
          declineButtonLabel={t('cancel')}
          onDecline={handleConfirmCancel}
          onConfirm={confirmAction.onConfirm}
          confirmButtonVariant="secondary-danger"
          isOpen={!!confirmAction}
        />
      )}
    </>
  );
};

AttachmentItemEditable.displayName = 'AttachmentItemEditable';
