import { OPERATION_STATUSES } from 'app-constants';
import { CREATE } from 'app-constants/form-modes';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { createTranslatableSelector, selectUserId } from 'store/modules/auth';
import { selectCurrentPageOperation } from 'store/modules/entities/selectors/operations';
import {
  selectOperations,
  selectTemplateCategories,
  selectTemplates,
  selectVitalInformationEntities,
} from 'store/modules/entities/selectors/selectors';
import {
  createEntitySelector,
  createMatchParamsGetter,
  createStateGetter,
} from 'store/utils';
import { getCategoriesTree } from 'utils/get-categories-tree';

export const selectOperationPlanningMode = createStateGetter(
  'operationPlanning.mode'
);

export const selectOperationTemplateId = createSelector(
  [selectCurrentPageOperation],
  (operation) => operation?.operationTemplateId ?? operation?.templateId ?? null
);

export const selectOperationNote = createSelector(
  [selectCurrentPageOperation],
  (operation) => operation && Boolean(operation.notes)
);

export const selectOperationStatus = createSelector(
  selectCurrentPageOperation,
  (operation) => {
    if (!operation) return null;
    return OPERATION_STATUSES[operation.status];
  }
);

export const selectOperationTemplate = createEntitySelector(
  selectTemplates,
  selectOperationTemplateId
);

export const selectOperationPageTitle = createSelector(
  [selectCurrentPageOperation, selectOperationTemplate],
  (operation, template) => {
    if (!operation && !template) return null;
    return get(operation, 'title', get(template, 'name'));
  }
);

export const selectOperationTemplateCategoryId = createSelector(
  selectOperationTemplate,
  createStateGetter('categoryId')
);

export const selectOperationTemplateName = createSelector(
  selectOperationTemplate,
  createStateGetter('name')
);

export const selectOperationTemplateNameTranslated = createTranslatableSelector(
  selectOperationTemplateName
);

export const selectPostOpNotes = createSelector(
  selectOperationTemplate,
  createStateGetter('notes', '')
);

export const selectVitalInformation = createSelector(
  [selectCurrentPageOperation, selectVitalInformationEntities],
  (operation, vitalInfoEntities) =>
    get(vitalInfoEntities, get(operation, 'vitalInformation'), null)
);

export const canUserEditOperation = (operation, userId) => {
  if (!operation || !operation.participants || !userId) return;

  const { participants } = operation;
  const isCurrentUser = (participant) =>
    !!(participant === userId || participant?.id === userId);

  const isAuthor = isCurrentUser(operation.createdBy);
  const isLead = operation.leadId === userId;
  const isAssistant = participants.assistants.some(isCurrentUser);

  return isAuthor || isLead || isAssistant;
};

export const selectOperationReadonly = createSelector(
  [
    selectCurrentPageOperation,
    selectUserId,
    createMatchParamsGetter('operationId'),
  ],
  (operation, userId, operationId) => {
    if (operationId === CREATE) return false;
    const canEdit = canUserEditOperation(operation, userId);
    if (typeof canEdit !== 'boolean') return;
    return !canEdit;
  }
);

export const createOperationByIdSelector = (operationId) =>
  createSelector(selectOperations, createStateGetter(operationId, null));

export const createCanEditOperationSelector = (operationId) =>
  createSelector(
    [createOperationByIdSelector(operationId), selectUserId],
    (operation, userId) => canUserEditOperation(operation, userId)
  );

export const createOperationTemplateIdSelector = (operationId) => {
  const operationSelector = createOperationByIdSelector(operationId);
  return createSelector(
    [operationSelector],
    (operation) => operation?.operationTemplateId
  );
};

export const createCategoriesTreeSelector = (categoryId) => {
  return createSelector([selectTemplateCategories], (categories) => {
    if (!categories) return null;
    return getCategoriesTree(categoryId, categories);
  });
};

export const canAddOswestrySelector = (...selectorArgs) => {
  const disciplineId = selectOperationTemplateCategoryId(...selectorArgs);
  const categories = createCategoriesTreeSelector(disciplineId)(
    ...selectorArgs
  );

  if (!categories?.length) return false;

  return categories.some((category) => category?.name?.en === 'Spine');
};

export const createOperationTemplateSelector = (operationId) => {
  const operationTemplateIdSelector = createOperationTemplateIdSelector(
    operationId
  );

  return createSelector(
    [operationTemplateIdSelector, selectTemplates],
    (templates, templateId) => templates?.[templateId] ?? null
  );
};
