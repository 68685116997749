import { useEffect, useState } from 'react';
import { useOperationMaterialsGroupsApi } from './useOperationMaterialsGroupsApi';

export const useOperationStaticGroup = (operationId, groupType) => {
  const [isGroupsRequested, setIsGroupsRequested] = useState(false);
  const { loading, fetchGroups, makeStaticGroup } =
    useOperationMaterialsGroupsApi(operationId);

  useEffect(() => {
    fetchGroups();
    setIsGroupsRequested(true);
  }, []);

  useEffect(() => {
    if (!isGroupsRequested || loading) return;
    makeStaticGroup(groupType);
  }, [isGroupsRequested, loading]);
};
