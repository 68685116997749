import React, { FC, memo } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

interface PersistStateNavLinkProps extends NavLinkProps {
  /**
   * Link destination
   */
  to: string;
}

/**
 *
 * PersistStateNavLink component is used to store the path and search query into the history state,
 * to help build more intelligent "back" buttons.
 *
 * For example, it's used within `HistoryList`(title link) + `OperationHistoryHeader`(close button)
 */
export const PersistStateNavLink: FC<PersistStateNavLinkProps> = memo(
  function PersistStateNavLink({ children, to, ...props }) {
    const { pathname: from, search, state: currentState } = useLocation();

    const state = currentState || { search, from };

    return (
      <NavLink
        to={{
          pathname: to,
          state,
        }}
        {...props}
      >
        {children}
      </NavLink>
    );
  }
);
