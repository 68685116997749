import { isStepCategory } from 'app-constants';
import { find, get } from 'lodash';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { selectShortUserData } from 'store/modules/auth';
import {
  selectEntities,
  selectIncidents,
  selectLibraryElements,
  selectOperations,
} from 'store/modules/entities/selectors/selectors';
import {
  selectOperationAssistant,
  selectOperationElementsAttachments,
  selectOperationLead,
  selectOperationTemplate,
} from 'store/modules/operation-planning';
import {
  createCollectionSelector,
  createPropsGetter,
  createStateGetter,
} from 'store/utils';
import { operationSchema, operationVetSchema } from '../../../api/v2/schemas';
import {
  selectActualStepsTimeList,
  selectCurrentPageOperation,
  selectOperationElements,
  selectOperationIncidentsList,
} from '../entities/selectors/operations';
import { isProductType } from 'utils/get-env';

export const selectHistoryOperations = createStateGetter('history.operations');
export const selectHistoryPagination = createStateGetter('history.page');
export const selectHistoryOperationsList = createSelector(
  [selectHistoryOperations, selectOperations, selectEntities],
  (historyOperationsIds, operations, entities) => {
    if (!historyOperationsIds || !operations) return [];
    return historyOperationsIds.map((id) =>
      denormalize(
        operations[id],
        isProductType('vet') ? operationVetSchema : operationSchema,
        entities
      )
    );
  }
);

export const selectHistoryItemsTotalCount = createSelector(
  selectHistoryPagination,
  createStateGetter('total')
);

export const selectSignedUsersDataHistoryOp = createSelector(
  [selectOperationLead, selectOperationAssistant],
  (lead, assistant) => {
    if (!lead && !assistant) return [];
    return [selectShortUserData(lead), selectShortUserData(assistant)].filter(
      Boolean
    );
  }
);

export const selectIncident = createSelector(
  [selectIncidents, createPropsGetter('id'), selectLibraryElements],
  (incidents, id, elements) => {
    if (!incidents || !id || !incidents[id]) return null;
    const incident = incidents[id];
    const elementRelationId = incident.elementId;
    const element = find(elements, ['relationId', elementRelationId]);

    return { ...incident, title: element?.name };
  }
);

const selectOperationPatientRisks = createSelector(
  [selectCurrentPageOperation],
  createStateGetter('patientRisks')
);

export const selectPatientRisksList = createSelector(
  [selectOperationPatientRisks, selectLibraryElements],
  createCollectionSelector()
);

export const createMaterialsListSelector = (materialsType) => {
  return createSelector(
    [selectCurrentPageOperation, selectLibraryElements],
    (operation, materials) => {
      if (!operation || !materials || !operation[materialsType]) return null;
      return operation[materialsType]
        .map((uuid) => materials[uuid])
        .filter(Boolean);
    }
  );
};

export const selectHistoryOperationAttachments =
  selectOperationElementsAttachments;

export const hasOperationTemplateElements = createSelector(
  selectOperationTemplate,
  (template) => Array.isArray(get(template, 'elements', false))
);

const selectOperationStepsWithActualTimes = createSelector(
  [selectOperationElements, selectActualStepsTimeList],
  (elements, stepsActualTime) => {
    if (!elements) return null;

    return elements
      .filter((element) => element && isStepCategory(element.category))
      .map((element) => {
        const { category, name, estimatedTime, relationId } = element;
        const actualTimeOfStep =
          stepsActualTime &&
          stepsActualTime.find((step) => step.relationId === relationId);
        const elementValues = { relationId, category, name, estimatedTime };
        return {
          ...elementValues,
          actualTime: get(actualTimeOfStep, 'actualTime', 0),
        };
      });
  }
);

/* stats */
export const selectHistoryOperationsStats = createStateGetter('history.stats');

export const selectHistoryChartData = createSelector(
  [selectOperationStepsWithActualTimes, selectOperationIncidentsList],
  (steps, incidents) => {
    if (!steps) return null;

    // Calculate timeline chart dots
    let totalEstimatedTimeSpent = 0;
    let totalActualTimeSpent = 0;

    const data = steps.map((step, index) => {
      const { relationId, estimatedTime, actualTime } = step;
      const incident =
        (incidents &&
          incidents.find((incident) => incident.elementId === relationId)) ||
        null;

      const estimatedTimeSpent = (totalEstimatedTimeSpent =
        estimatedTime + totalEstimatedTimeSpent);
      const actualTimeSpent = (totalActualTimeSpent =
        actualTime + totalActualTimeSpent);

      return {
        ...step,
        stepNumber: index + 1,
        estimatedTimeSpent,
        actualTimeSpent,
        incident,
      };
    });

    return data;
  }
);
