import { createContext } from 'react';
import noop from 'lodash/noop';
import { Callback } from 'downshift';

export type ListItempPropsGetter = () => {
  clickable: true;
  onItemClickCapture: (cb?: Callback) => void;
} | void;

export type RenderChildren = (props: ListItempPropsGetter) => React.ReactNode;

export const DroplistContext = createContext<ListItempPropsGetter>(noop);
