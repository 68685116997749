export const IMPACT_ENUM = {
  LOW: 'LOW',
  MED: 'MED',
  HIGH: 'HIGH',
  NONE: 'NONE',
};

export const IMPACT_OPTIONS = [
  { label: 'None', value: 'NONE' },
  { label: 'Low', value: IMPACT_ENUM.LOW },
  { label: 'Medium', value: IMPACT_ENUM.MED },
  { label: 'High', value: IMPACT_ENUM.HIGH },
];

export const STAGE_ENUM = {
  PRE_OP: 'PRE-OP',
  INTRA_OP: 'INTRA-OP',
  POST_OP: 'POST-OP',
};

export const STAGE_OPTIONS = Object.values(STAGE_ENUM).map((stage) => ({
  label: stage,
  value: stage,
}));
