import React, { FC } from 'react';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';

export interface AttachmentUploadStatusProps {
  progress: number | null;
  error?: string;
}

export const AttachmentUploadStatus: FC<AttachmentUploadStatusProps> = ({
  progress,
  error,
}) => {
  const { t } = useTranslation();

  if (progress === 100 && !error) {
    return (
      <strong className="text-success">
        {t('Loaded')}
        <Icon className="ml-05 text-xs" name="checkmark" />
      </strong>
    );
  } else if (progress !== 100 && !error) {
    return <span className="text-lighter">{progress}&nbsp;&#37;</span>;
  } else if (error) {
    return (
      <span className="text-error">
        <strong>
          {t('Failed')}&nbsp;<Icon className="ml-05" icon="incidents"></Icon>
        </strong>
      </span>
    );
  }

  return null;
};
