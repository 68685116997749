import { OPERATION_STATUSES } from './operation-statuses';

const ALL = null;

export const DATE_FILTER_RANGES = {
  all: ALL,
  last7days: 'last7days',
  last14days: 'last14days',
  lastMonth: 'lastMonth',
};

export const DATE_RANGES = {
  all: null,
  week: 7,
  twoWeek: 14,
  month: 30,
};

export const STATUS_FILTERS = {
  all: ALL,
  done: OPERATION_STATUSES.done,
  todo: OPERATION_STATUSES.finished,
};

export const APPROVED_BY_LEAD_FILTER = 'approvedByLead';

export const RESEARCH_BOOKMARKS = {
  all: 'all',
  favorites: 'favorites',
};

export enum SortDirections {
  ASC = 'asc',
  DESC = 'desc',
}
