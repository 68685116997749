import { BaseModal, BaseModalProps } from 'components/Modal';
import { useAttachment, useAttachmentUpdate } from 'hooks/useAttachment';
import React, { ComponentType, HTMLAttributes } from 'react';
import { AttachmentUpdateForm } from './AttachmentUpdateForm';

interface Props extends BaseModalProps {
  attachmentId?: number | null;
  onRequestClose?: VoidFunction;
  opened: boolean;
}

export const AttachmentUpdateFormModal: ComponentType<
  Props & HTMLAttributes<HTMLDivElement>
> = ({ attachmentId = null, ...modalProps }) => {
  const { attachment, loading } = useAttachment(attachmentId);
  const { handleChange } = useAttachmentUpdate(attachmentId);

  const handleFormSubmit = async (values) => {
    if (modalProps.onRequestClose) {
      modalProps.onRequestClose();
    }

    handleChange(values);
  };

  return (
    <BaseModal {...modalProps}>
      {Boolean(modalProps.opened && !loading) && !!attachment && (
        <AttachmentUpdateForm
          initialValues={{
            name: attachment.name,
            description: attachment.description || '',
          }}
          onSubmit={handleFormSubmit}
          onClose={modalProps.onRequestClose}
        />
      )}
    </BaseModal>
  );
};

AttachmentUpdateFormModal.displayName = 'AttachmentUpdateFormModal';
