import React, { ComponentType, useContext, useState } from 'react';
import { RequestId, uuid } from 'common-types/api';
import { MaterialElementInput } from 'common-types/library';
import {
  MaterialsGroup as MaterialsGroupInterface,
  MaterialsGroupInput,
} from 'common-types/materials';
import { Button } from 'components/Button';
import { Col, Row } from 'components/Grid';
import { Icon } from 'components/Icon';
import { NotFound } from 'components/NotFound';
import { useTranslation } from 'react-i18next';
import { MaterialsFormContext } from '../../context';
import { MaterialsGroup } from '../MaterialsGroup/MaterialsGroup';
import { MaterialsGroupColumn } from '../MaterialsGroupColumn/MaterialsGroupColumn';

interface Props {
  materialsGroups: MaterialsGroupInterface[];
  onAddGroup: (group: MaterialsGroupInput) => Promise<MaterialsGroupInterface>;
  onRemoveGroup?: (groupId: RequestId) => void;
  onRemoveMaterial?: (groupId: RequestId, uuid: uuid) => any;
  onUpdateMaterial?: (
    groupId: RequestId,
    uuid: uuid,
    update: Partial<MaterialElementInput>
  ) => any;
  onUpdateGroup?: (
    groupId: RequestId,
    update: Partial<MaterialsGroupInput>
  ) => any;
  onAttachmentRequest?: (groupId: RequestId) => void;
  onReorderMaterials?: (
    groupId: RequestId,
    fromIndex: number,
    toIndex: number
  ) => void;
}

const groupInputMaterials = [];

export const MaterialsGroupsForm: ComponentType<Props> = ({
  materialsGroups,
  onAddGroup,
  onRemoveGroup,
  onUpdateGroup,
  onRemoveMaterial,
  onUpdateMaterial,
  onAttachmentRequest,
  onReorderMaterials,
}) => {
  const [showGroupForm, setShowGroupForm] = useState<boolean>(false);
  const { readonly, isLoading } = useContext(MaterialsFormContext);
  const { t } = useTranslation();

  const handleGroupSubmit = async (groupInput) => {
    const { name, description } = groupInput;
    await onAddGroup({ name, description, order: materialsGroups.length });
    setShowGroupForm(false);
  };

  const hasMaterialsGroups = Boolean(materialsGroups?.length);
  const showAddButton = Boolean(!readonly && !showGroupForm && !isLoading);

  if (readonly && !hasMaterialsGroups)
    return (
      <NotFound
        header={t('noInstrumentTablesAdded')}
        message=""
        withBackButton={false}
      ></NotFound>
    );

  return (
    <>
      <Row>
        {hasMaterialsGroups &&
          materialsGroups.map((group) => (
            <Col width="4" className="mb-1" key={group.id}>
              <MaterialsGroupColumn
                group={group}
                onAttachmentRequest={onAttachmentRequest}
                onUpdate={onUpdateGroup}
                onRemove={onRemoveGroup}
                onRemoveMaterial={onRemoveMaterial}
                onUpdateMaterial={onUpdateMaterial}
                onReorderMaterials={onReorderMaterials}
              />
            </Col>
          ))}
        {Boolean(!readonly && showGroupForm) && (
          <Col width="4">
            <div className="appear">
              <MaterialsGroup
                mode="create"
                name=""
                id="new-group"
                active
                materials={groupInputMaterials}
                onSubmit={
                  typeof onAddGroup === 'function'
                    ? handleGroupSubmit
                    : undefined
                }
                onSubmitCancel={() => setShowGroupForm(false)}
                onRemoveMaterial={onRemoveMaterial}
                onUpdateMaterial={onUpdateMaterial}
              />
            </div>
          </Col>
        )}
      </Row>
      {showAddButton && (
        <div>
          <Button
            key="add-button"
            type="button"
            variant="secondary"
            onClick={() => setShowGroupForm(true)}
          >
            <Icon name="plus" gapRight /> {t('addTable')}
          </Button>
        </div>
      )}
    </>
  );
};
