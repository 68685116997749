import { FileUploadProgress } from 'common-types/attachments';
import { combineReducers } from 'redux';
import { Action } from 'store/utils';
import {
  CANCEL_FILE_UPLOAD,
  RESET_FILES_UPLOAD_PROGRESS,
  RETRY_FILE_UPLOAD,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from './types';

interface State {
  uploadsList: string[];
  uploadsMap: { [index: string]: FileUploadProgress };
}

export const initialState = () => ({
  uploadsList: [],
  uploadsMap: {},
});

function uploadsListReducer(
  state: string[] = initialState().uploadsList,
  action: Action
): string[] {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST: {
      const { id } = action.payload;
      if (state.includes(id)) return state;
      return state.concat(id);
    }

    case CANCEL_FILE_UPLOAD: {
      const { id } = action.payload;
      return state.filter((uploadId) => uploadId !== id);
    }

    case RETRY_FILE_UPLOAD: {
      // return [...state.filter((file) => !isEmpty(file.error))];
      return state;
    }

    case RESET_FILES_UPLOAD_PROGRESS: {
      return initialState().uploadsList;
    }

    default:
      return state;
  }
}

function uploadsMapReducer(
  state: { [index: string]: FileUploadProgress } = initialState().uploadsMap,
  action: Action
): { [index: string]: FileUploadProgress } {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_FILE_REQUEST: {
      const { id } = payload;
      return {
        ...state,
        [id]: { ...payload },
      };
    }

    case UPLOAD_FILE_PROGRESS: {
      const { id } = payload;

      return {
        ...state,
        [id]: { ...state[id], ...payload },
      };
    }

    case UPLOAD_FILE_FAILURE: {
      const { errorMessage, id } = payload;
      return {
        ...state,
        [id]: { ...state[id], error: errorMessage, progress: null },
      };
    }

    case UPLOAD_FILE_SUCCESS: {
      const { id } = payload;
      const newProgressState = { ...state[id] };
      delete newProgressState.file;
      return { ...state, [id]: newProgressState };
    }

    case CANCEL_FILE_UPLOAD: {
      const { id } = payload;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }

    case RETRY_FILE_UPLOAD: {
      // FIXME:
      // return [...state.filter((file) => !isEmpty(file.error))];
      return state;
    }

    case RESET_FILES_UPLOAD_PROGRESS: {
      return initialState().uploadsMap;
    }

    default:
      return state;
  }
}

interface Reducer {
  (state: State, action: Action): State;
}

export const filesUploadProgressReducer: Reducer = combineReducers({
  uploadsList: uploadsListReducer,
  uploadsMap: uploadsMapReducer,
});
