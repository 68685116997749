import React, {
  createRef,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { noop } from 'lodash';

import withFileUpload_DEPRECATED from 'containers/withFileUpload';
import { getBlob } from 'utils';
import { TranslationFn } from 'utils/translation-types';
import classes from './SignaturePad.module.scss';
import { useTranslation } from 'react-i18next';
import { DeprecatedFlex } from 'components/Grid';
import { Button } from 'components/Button';
import { BaseSignaturePad } from 'components/SignaturePad/BaseSignaturePad';

interface Props {
  /**
   * HTML id attribute
   */
  id?: string;
  /**
   * IMG tag source attribute
   */
  defaultValue: string;
  /**
   * HTML tag name attr
   */
  name: string;
  /**
   * On change signature function
   */
  onChange: Function;
  /**
   * Uploading file if file is present
   */
  onUpload: Function;
  /**
   * Translations package function
   */
  t: TranslationFn;
  /**
   * Signature pad value
   */
  value: string;
}

const SignaturePadComponent = ({
  defaultValue = '',
  value = 'null',
  onChange = noop,
  id,
  onUpload,
  name,
}: Props): ReactElement => {
  const [hideDefaultImg, setHideDefaultImg] = useState(false);
  const pad: RefObject<any> = createRef();
  const { t } = useTranslation();

  const drawDefaultImg = async () => {
    const data = await getBlob(defaultValue).then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onerror = reject;
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        })
    );
    pad?.current.fromDataURL(data);
  };

  const updateValue = () => {
    if (!pad?.current || !value || value === 'null') return;
    pad?.current.fromData(value);
  };

  const handleClear = useCallback(() => {
    if (!pad?.current) return;
    pad?.current.clear();
  }, [pad]);

  useEffect(() => {
    updateValue();
  }, []);

  useEffect(() => {
    if (!value) {
      handleClear();
    }

    if (!!defaultValue && hideDefaultImg) {
      drawDefaultImg().catch(console.error);
    }
  }, [defaultValue, hideDefaultImg, value]);

  const handleSave = async () => {
    if (!pad?.current) return;
    const isEmpty = pad?.current.isEmpty();
    const file = isEmpty ? null : await getBlob(pad?.current.toDataURL());

    const event = {
      type: 'change',
      target: { name, files: [file], value: '' },
    };

    if (file) {
      const files = await onUpload(event);
      if (files?.[0]?.fileKey) {
        event.target.value = files?.[0]?.fileKey;
      }
    }

    onChange(event);
  };

  const onClickHideDefaultImg = () => {
    setHideDefaultImg(true);
  };

  return (
    <div id={id} className={classes.padWrapper}>
      {defaultValue && !hideDefaultImg ? (
        <>
          <div className={classes.pad}>
            <img src={defaultValue} alt="signature.png" />
          </div>
          <DeprecatedFlex className="justify-end" inline={false}>
            <Button
              className="change-button"
              onClick={onClickHideDefaultImg}
              type="button"
              variant="secondary"
            >
              {t('change')}
            </Button>
          </DeprecatedFlex>
        </>
      ) : (
        <>
          <BaseSignaturePad ref={pad}>
            <DeprecatedFlex className="justify-end" inline={false}>
              <Button
                type="button"
                className="mr-1 clear-button"
                variant="secondary"
                onClick={handleClear}
              >
                {t('Clear')}
              </Button>
              <Button
                type="button"
                className="save-button"
                onClick={handleSave}
              >
                {t('save')}
              </Button>
            </DeprecatedFlex>
          </BaseSignaturePad>
        </>
      )}
    </div>
  );
};

export const SignaturePad = withFileUpload_DEPRECATED(SignaturePadComponent);
