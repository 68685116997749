import { LOGIN, RETURN_LOGIN, FORGOT_PASSWORD } from 'scenes/routes.enum';
import { user } from 'local-store';

/**
 * Check whether user tokens are available
 */
export const areUserTokensAvailable = () => {
  return user.getAccessToken() && user.getRefreshToken();
};

/**
 * Set of auth routes
 */
export const authRoutes = new Set<string>([
  LOGIN,
  RETURN_LOGIN,
  FORGOT_PASSWORD,
]);
