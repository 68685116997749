import { createActionCreator } from '../../../utils';
import { ASSIGN_ASSESSMENT, UNASSIGN_ASSESSMENT } from '../types';

export interface assignPayload {
  assigneeId: number;
  leadId: number;
  operationId: null | string | number;
}

export const assignAssessment = createActionCreator<assignPayload>(
  ASSIGN_ASSESSMENT
);

export interface unassignPayload {
  operationId: number | string;
}

export const unassignAssessment = createActionCreator<unassignPayload>(
  UNASSIGN_ASSESSMENT
);
