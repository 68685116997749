import { sortBy } from 'lodash';

type OrderedElement = {
  order: number;
};

export const materialsOrder = (
  collection: OrderedElement[],
  fromIndex,
  toIndex
) => {
  const sortedCollection: OrderedElement[] = sortBy(collection, 'order');
  const [movedEl] = sortedCollection.splice(fromIndex, 1);
  sortedCollection.splice(toIndex, 0, movedEl);

  return sortedCollection.map((item, index) => ({
    ...item,
    order: index,
  }));
};
