import { READONLY } from 'app-constants';
import { LibraryCategories } from 'common-types/library';
import { MaterialsGroupType } from 'common-types/materials';
import React, { ComponentType, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { OperationPlanningContext } from 'scenes/OperationPlanning/context';
import OperationPlanningActions from 'scenes/OperationPlanning/OperationPlanningActions';
import { BRIEFING_TABLES } from 'scenes/routes.enum';
import { applyPathParams } from 'utils';
import { getElementsUUIDs } from 'store/utils';
import { useOperationMaterials } from './useOperationMaterials';
import { useOperationStaticGroup } from './useOperationStaticGroup';
import {
  Button,
  Col,
  getGroupMaterials,
  MaterialFormProvider,
  MaterialsGroup,
  MaterialsSearch,
  Row,
  SearchLayout,
} from 'components';

interface Props {
  onSubmitSuccess: () => void;
  operationId: string;
  readonly: boolean;
}

const OPERATION_MATERIALS_CATEGORIES: LibraryCategories[] = [
  LibraryCategories.instruments,
  LibraryCategories.implants,
];

const useOperationMaterialsStaticGroups = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: MaterialsGroupType.GENERAL_INSTRUMENTS,
        label: t('Instruments'),
        categories: ['instruments'],
      },
      { value: 'implants', label: t('Implants'), categories: ['implants'] },
      {
        value: 'instruments',
        label: t('extraInstruments'),
        categories: ['instruments'],
      },
    ],
    // changes of t should not trigger an update

    []
  );
};

export const OperationMaterialsForm: ComponentType<Props> = ({
  operationId: operationIdProp,
}) => {
  const { t } = useTranslation();
  const { mode } = useContext(OperationPlanningContext);
  const readonly = mode === READONLY;
  const { operationId: operationIdParam } = useParams<{
    operationId: string;
  }>();
  const groupsList = useOperationMaterialsStaticGroups();
  const operationId = operationIdParam || operationIdProp;

  const {
    loading,
    generalInstrumentsGroup,
    implants,
    instruments,
    addElement: handleSelect,
    removeElement: handleRemoveMaterial,
    updateElement: handleUpdateMaterial,
    reorderElements: handleReorderMaterials,
  } = useOperationMaterials(operationId);
  const generalInstruments = useMemo(
    () => getGroupMaterials(generalInstrumentsGroup),
    [generalInstrumentsGroup]
  );

  const selectedImplantsUUIDs = useMemo(() => {
    return implants ? getElementsUUIDs(implants) : [];
  }, [implants]);

  const materialsGroups = useMemo(
    () => ({
      [MaterialsGroupType.GENERAL_INSTRUMENTS]: generalInstruments,
      implants,
      instruments,
    }),
    [instruments, implants, generalInstruments]
  );

  useOperationStaticGroup(operationId, MaterialsGroupType.GENERAL_INSTRUMENTS);

  return (
    <>
      <SearchLayout
        header={
          !readonly && (
            <Row className="justify-center">
              <Col auto basis="620px">
                <MaterialsSearch
                  groups={groupsList}
                  categories={OPERATION_MATERIALS_CATEGORIES}
                  selectedMaterials={selectedImplantsUUIDs}
                  onSelectMaterial={handleSelect}
                />
              </Col>
            </Row>
          )
        }
      >
        <MaterialFormProvider value={{ readonly, isLoading: loading }}>
          <Row className="mb-2">
            {groupsList.map((group) => (
              <Col width="4" key={group.value}>
                <MaterialsGroup
                  id={group.value}
                  name={group.label}
                  materials={materialsGroups[group.value]}
                  onUpdateMaterial={handleUpdateMaterial}
                  onRemoveMaterial={handleRemoveMaterial}
                  onReorderMaterials={handleReorderMaterials}
                />
              </Col>
            ))}
          </Row>
        </MaterialFormProvider>
      </SearchLayout>
      {!readonly && (
        <OperationPlanningActions>
          <Button
            type="button"
            component={Link}
            to={applyPathParams(BRIEFING_TABLES, { operationId })}
          >
            {t('Next')}
          </Button>
        </OperationPlanningActions>
      )}
    </>
  );
};

OperationMaterialsForm.displayName = 'OperationMaterialsForm';

export default OperationMaterialsForm;
