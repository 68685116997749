import React, { ElementType, FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Tab } from './Tab';

export interface NavTabProps {
  component?: ElementType;
}

export const NavTab: FC<NavTabProps> = ({
  component = NavLink,
  ...restProps
}) => {
  return (
    <Tab
      activeClassName="Tablist__tab--active"
      component={component}
      {...restProps}
    />
  );
};
