import React, {
  FC,
  memo,
  AllHTMLAttributes,
  ReactNode,
  MouseEvent,
  KeyboardEvent,
  useState,
  ReactElement,
} from 'react';
import cn from 'classnames';
import { Icon } from 'components/Icon';
import Downshift from 'downshift';
import { ENTER } from 'app-constants/keycodes';
import { MenuListItemProps } from './MenuListItem';

import styles from './MenuItem.module.scss';
import classnames from 'classnames';

export interface MenuItemProps
  extends Omit<AllHTMLAttributes<HTMLLIElement>, 'label' | 'children'> {
  /**
   * Unique id of menu item
   */
  id: string;
  /**
   * Item label to display
   */
  label: ReactNode;
  /**
   * Number of sub-items inside this menu item
   */
  numberOfSubItems?: number;
  /**
   * Item style variant.
   * 'normal'    - default
   * 'primary'   - highlighted in primary color
   * 'secondary' - secondary grey color
   */
  variant?: 'normal' | 'primary' | 'secondary';
  /**
   * Whether to close sub-menu on click.
   * True if no onClick handler provided.
   */
  closeSubmenuOnClick?: boolean;
  /**
   * Callback to run on menu item click or on ENTER key click
   */
  onClick?: (event: MouseEvent<HTMLLIElement> | KeyboardEvent) => void;
  /**
   * List of sub-items
   */
  children?:
    | ReactElement<MenuListItemProps>
    | ReactElement<MenuListItemProps>[];
}

/**
 * One item inside menu
 */
export const MenuItem: FC<MenuItemProps> = memo(function MenuItem({
  id,
  label,
  numberOfSubItems = 0,
  variant = 'normal',
  closeSubmenuOnClick = true,
  onClick,
  className,
  children = [],
  ...props
}) {
  const [isSubMenuOpen, setIsSubmenuOpen] = useState(false);

  const handleOpenCloseSubMenu = (
    event: MouseEvent<HTMLLIElement> | KeyboardEvent
  ) => {
    if (onClick) {
      onClick(event);
    }

    if (!onClick || closeSubmenuOnClick) {
      setIsSubmenuOpen((open) => !open);
    }
  };

  const handleCloseSubMenu = () => {
    setIsSubmenuOpen(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === ENTER) {
      handleOpenCloseSubMenu(event);
    }
  };

  const copiedChildren = React.Children.map(
    children,
    (child: ReactElement<MenuListItemProps>) =>
      child &&
      React.cloneElement(child, {
        onClick: (
          event: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>
        ) => {
          if (child.props.onClick) {
            child.props.onClick(event);
          }
          handleCloseSubMenu();
        },
      })
  );

  const hasChildren = copiedChildren.length > 0 || numberOfSubItems > 0;

  return (
    <li
      aria-haspopup
      aria-owns={id}
      role="menuitem"
      className={cn(className, styles.listElement, styles[variant], {
        [styles.selected]: isSubMenuOpen,
      })}
      onClick={handleOpenCloseSubMenu}
      onKeyDown={handleKeyDown}
      {...props}
    >
      <span className={styles.elementLabel}>{label}</span>

      {hasChildren ? (
        <span
          className={classnames(styles.iconCircle, {
            [styles.count]: !!numberOfSubItems,
          })}
        >
          {numberOfSubItems || (
            <Icon className={styles.icon} name="chevron-right" />
          )}
        </span>
      ) : null}

      {hasChildren && (
        <Downshift isOpen={isSubMenuOpen} onOuterClick={handleCloseSubMenu}>
          {({ isOpen, getMenuProps }) =>
            isOpen && (
              <div
                id={id}
                aria-hidden={!isOpen}
                role="tooltip"
                className={cn(styles.subMenuCnt, {
                  [styles.hideCnt]: !isOpen,
                })}
              >
                <ul {...getMenuProps()} role="menu">
                  {copiedChildren}
                </ul>
              </div>
            )
          }
        </Downshift>
      )}
    </li>
  );
});
