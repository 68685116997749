import { stringify, IStringifyOptions } from 'qs';
import { useHistory } from 'react-router-dom';
import { parseSearchParams } from 'utils';
import { History } from 'history';
import { useCallback, useMemo } from 'react';

type paramsSetter = (
  newParams: object,
  options?: {
    stringify?: IStringifyOptions;
  }
) => boolean;

type useSearchParamsHook = () => [{ [index: string]: any }, paramsSetter];

const defaultStringifyOptions: IStringifyOptions = {
  arrayFormat: 'comma',
  encode: false,
};

export const useSearchParams: useSearchParamsHook = () => {
  const history: History = useHistory();
  const params: object = useMemo(
    () => parseSearchParams(history.location.search),
    [history.location.search]
  );

  const setParams: paramsSetter = useCallback(
    (newParams, options) => {
      if (!newParams) return false;

      const existingParams = parseSearchParams(history.location.search);
      const params = {
        ...existingParams,
        ...newParams,
      };

      let stringifyOptions = defaultStringifyOptions;
      if (options?.stringify) {
        stringifyOptions = {
          ...defaultStringifyOptions,
          ...options?.stringify,
        };
      }

      history.push({
        search: stringify(params, stringifyOptions),
      });

      return true;
    },
    [history]
  );

  return [params, setParams];
};
