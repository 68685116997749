import { ASSIGN_ASSESSMENT } from '../../operation-planning';
import { DELETE_ASSESSMENT_SUCCESS } from '../../assessments/thunks';
import { patchState } from '../utils/patch-state';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ASSIGN_ASSESSMENT: {
      return {
        ...state,
        [payload.id]: payload,
      };
    }

    case 'SET_EXPLANATIONS_ASSESSMENT_SUCCESS': {
      return {
        ...state,
        [payload.id]: payload,
      };
    }

    case DELETE_ASSESSMENT_SUCCESS: {
      const id = action.requestId;
      return patchState(state, id, { isDeleted: true });
    }

    default:
      return state;
  }
};
