import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { updatePassword } from 'store/modules/settings';
import { passwordSchema } from 'validation-schemas';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export interface FormUpdatePasswordValues {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

/**
 * Hook to handle update password form, connected to store and api
 */
export const useFormUpdatePassword = (login?: boolean) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resolver = useMemo(() => {
    return yupResolver(
      yup.object().shape({
        password: yup
          .string()
          .required(t('validation:currentPasswordIsRequiredField')),

        newPassword: passwordSchema.required(
          t('validation:newPasswordIsRequiredField')
        ),

        newPasswordConfirmation: passwordSchema
          .required(t('validation:newPasswordIsRequiredField'))
          .oneOf(
            [yup.ref('newPassword')],
            t('passwordConfirmationDoesNotMatch')
          ),
      })
    );
  }, [t]);

  const { handleSubmit, reset, ...formState } =
    useForm<FormUpdatePasswordValues>({
      defaultValues: {
        password: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
      resolver,
      reValidateMode: 'onChange',
      mode: 'onSubmit',
    });

  const submit = handleSubmit((data) => {
    dispatch(
      updatePassword(
        {
          currentPassword: data.password,
          newPassword: data.newPassword,
        },
        login
      )
    );

    reset();
  });

  return {
    submit,
    reset,
    ...formState,
  };
};
