import React, { ReactElement } from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';

import { Icon } from 'components/Icon';

import styles from './Rating.module.scss';

interface Props {
  value: number;
  isActive: boolean;
  onSelect: Function | undefined;
}

export const RatingItem = ({
  value,
  isActive = false,
  onSelect = noop,
}: Props): ReactElement => {
  const handleClick = () => {
    onSelect && onSelect(value);
  };
  const iconName = isActive ? 'star' : 'star-stroke';

  return (
    <Icon
      className={cn(styles.star, { [styles.active]: isActive })}
      role="button"
      name={iconName}
      onClick={handleClick}
    />
  );
};
