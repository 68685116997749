import React, { FC } from 'react';
import ConnectedModal from 'containers/ConnectedModal';
import AddLibraryItemForm from 'containers/LibraryItemsSearch/AddLibraryItemForm';
import { TEMPLATE_ELEMENTS_CATEGORIES } from 'app-constants';
import { useTranslation } from 'react-i18next';

interface StepAsLibraryItemModalProps {}

export const StepAsLibraryItemModal: FC<StepAsLibraryItemModalProps> = () => {
  const { t } = useTranslation();

  return (
    <ConnectedModal
      name="saveStepAsNewLibraryItem"
      title={t('addNewItemToLibrary')}
    >
      {(isOpen: boolean, dialogProps: any) =>
        isOpen && (
          <AddLibraryItemForm
            categories={TEMPLATE_ELEMENTS_CATEGORIES}
            {...dialogProps}
          />
        )
      }
    </ConnectedModal>
  );
};
