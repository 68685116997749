import classNames from 'classnames';
import React, { ElementType, FC, HTMLAttributes, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'components/Icon';
import { Text } from 'components/Typography';
import styles from './TabWithIcon.module.scss';

export interface TabWithIconProps {
  mode?: 'default' | 'multistep';
  disabled?: boolean;
  icon?: string;
  isActive?: boolean;
  isDone?: boolean;
  title?: string;
  badge?: ReactNode | string;
  component?: ElementType;
  to?: string;
}

export const TabWithIcon: FC<TabWithIconProps & HTMLAttributes<HTMLElement>> =
  ({
    mode = 'default',
    disabled = false,
    isDone = false,
    component: LinkComponent = NavLink,
    isActive,
    title,
    badge,
    icon,
    to,
    ...restProps
  }) => {
    return (
      <LinkComponent
        activeClassName={styles.tabActive}
        className={classNames(styles.tabWithIcon, {
          [styles.tabMultistep]: mode === 'multistep',
          [styles.tabDone]: isDone,
          [styles.tabDisabled]: disabled,
        })}
        isActive={isActive}
        to={to}
        {...restProps}
      >
        <span className={classNames('mr-1', styles.tabIconWrapper)}>
          <Icon className={styles.tabIcon} name={icon} />
          {isDone && (
            <Icon className={styles.tabIconDone} name="check-circle" />
          )}
        </span>
        <Text variant="title" className={styles.tabTitle} component="span">
          {title}
        </Text>
        {badge && <span className={styles.badge}>{badge}</span>}
      </LinkComponent>
    );
  };
