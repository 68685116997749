import React, { HTMLAttributes, FC, memo, ElementType } from 'react';
import cn from 'classnames';

import { setDataAttributes } from './utils';
import styles from './DataTag.module.scss';

export interface DataTagProps extends HTMLAttributes<HTMLElement> {
  /**
   * Root html element
   */
  component?: ElementType;
  /**
   * Root html element data attributes
   */
  propKey: string | string[] | Record<string, string | null>;
  /**
   * Flag to hide element from view but keep it in elements tree
   */
  hidden?: boolean;
}

/**
 * Renders html element with predefined data attributes
 */
export const DataTag: FC<DataTagProps> = memo(function DataTag({
  component: Component = 'span',
  propKey,
  hidden,
  children,
  className,
  ...props
}) {
  return (
    <Component
      className={cn(className, { [styles.hidden]: hidden })}
      {...setDataAttributes(propKey, children)}
      {...props}
    >
      {children}
    </Component>
  );
});
