import { createStateGetter } from 'store/utils';

export const selectActiveModal = createStateGetter('modalDialogs');
export const selectActiveModalName = createStateGetter(
  'modalDialogs.modalName'
);
export const selectActiveModalProps = createStateGetter(
  'modalDialogs.modalProps'
);
export const selectActiveModalDialogProps = createStateGetter(
  'modalDialogs.dialogProps'
);
