import { Button } from 'components/Button';
import { FormInput, FormTextarea } from 'components/Form/FormInput';
import { ModalHeader } from 'components/Modal';
import { PanelActions, PanelBody, Panel } from 'components/Panel';
import { useFormik } from 'formik';
import React, { ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'validation-schemas/yup';

type FormValues = { name: string; description: string };
interface Props {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  onClose?: () => void;
}

export const AttachmentUpdateForm: ComponentType<Props> = ({
  initialValues,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required(t('nameIsRequired')),
        description: string(),
      }),
    []
  );

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Panel>
      <ModalHeader title={t('editAttachment')} onClose={onClose} />

      <form onSubmit={handleSubmit}>
        <PanelBody>
          <FormInput
            name="name"
            label={t('Name')}
            placeholder={t('attachmentName')}
            value={values.name}
            error={errors.name}
            onChange={handleChange}
            mode="filename"
          />
          <FormTextarea
            name="description"
            label={t('Description')}
            placeholder={t('attachmentDescription')}
            value={values.description}
            error={errors.description}
            onChange={handleChange}
          />
        </PanelBody>

        <PanelActions>
          <Button title={t('save')} type="submit">
            {t('save')}
          </Button>
        </PanelActions>
      </form>
    </Panel>
  );
};
