import React, { FC, memo, AllHTMLAttributes, ReactElement } from 'react';
import classes from './Menu.module.scss';
import { MenuItemProps } from './MenuItem';

export interface MenuProps
  extends Omit<AllHTMLAttributes<HTMLElement>, 'children'> {
  /**
   * List of menu items
   */
  children: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
}

/**
 * Simple menu with items inside
 */
export const Menu: FC<MenuProps> = memo(function Menu({ children, ...props }) {
  return (
    <nav {...props}>
      <ul className={classes.unorderedList}>{children}</ul>
    </nav>
  );
});
