import React, { FC } from 'react';
import classnames from 'classnames';

import classes from '../Form.module.scss';

interface FormBodyProps {
  className?: string;
  narrow?: boolean;
  [key: string]: any;
}

export const FormBody: FC<FormBodyProps> = ({
  className,
  narrow = false,
  ...restProps
}) => {
  return (
    <div
      className={classnames(className, classes.body, {
        [classes.narrow]: narrow,
      })}
      {...restProps}
    />
  );
};
