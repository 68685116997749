import React, { FC, useMemo, useState } from 'react';
import { ErrorTypes } from 'common-types/common';
import {
  LibraryCategories,
  LibraryItemsSearchOption,
} from 'common-types/library';
import { Button } from '../Button';
import { FormSelect } from '../Form/FormInput';
import styles from './LibraryItemsSearchInput.module.scss';
import { useTranslation } from 'react-i18next';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import { withoutRelationId } from '../../store/utils';
import { SearchIcon } from './libraryItemsSearchInput/SearchIcon';
import { getMenuPreloaderItemsLength } from './libraryItemsSearchInput/utils/getMenuPreloaderItemsLength';
import { MenuItemPreloader } from './MenuItemPreloader';
import { MenuItem } from './MenuItem/MenuItem';
import classNames from 'classnames';
import { Text } from '../Typography';
import { Option } from './Option';

interface LibraryItemsSearchInputProps {
  categories?: LibraryCategories[];
  defaultQuery?: string;
  error?: ErrorTypes;
  isLoading?: boolean;
  onAddItem?(query: string): void;
  onItemSelect?(item: LibraryItemsSearchOption): void;
  onReset?(): void;
  onSearch?(query: string, categories: LibraryCategories[]): void;
  results?: LibraryItemsSearchOption[];
  selectedUUIDs?: string[];
  minimumCharactersRequired?: boolean;
  minimumCharactersNumber?: number;
}

const MIN_QUERY_LENGTH = 4;

export const LibraryItemsSearchInput: FC<LibraryItemsSearchInputProps> = ({
  categories = [],
  defaultQuery = '',
  error = '',
  isLoading = false,
  onAddItem,
  onItemSelect,
  onReset,
  onSearch,
  results,
  selectedUUIDs,
  minimumCharactersRequired,
  minimumCharactersNumber,
}) => {
  const { t } = useTranslation();

  const minimumCharacters = minimumCharactersNumber ?? MIN_QUERY_LENGTH;

  const [query, setQuery] = useState<string>(defaultQuery || '');
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const handleReset = () => {
    onReset?.();
    setQuery('');
    setIsTouched(false);
  };

  const getOptions = () => {
    if (
      isLoading ||
      error ||
      !Array.isArray(results) ||
      (minimumCharactersRequired && query.length < minimumCharacters)
    )
      return [];

    return results
      .map((item) => ({
        ...item,
        isSelected: selectedUUIDs
          ? selectedUUIDs.includes(withoutRelationId(item.uuid))
          : false,
        label: item.name,
        value: item.id,
      }))
      .sort((prev, next) => Number(next.isSelected) - Number(prev.isSelected));
  };

  const searchResults = useMemo(getOptions, [results, query]);

  const handleInputValueChange = (inputValue: string, { type }) => {
    if (type !== Downshift.stateChangeTypes.changeInput) {
      return;
    }

    setQuery(inputValue);
    setIsTouched(true);

    if (!minimumCharactersRequired || inputValue.length >= minimumCharacters) {
      debounceSearch(inputValue);
    }
  };

  const handleSearch = (value?: string) => {
    if (!value) return;
    onSearch?.(value, categories);
  };

  const debounceSearch = debounce(handleSearch, 400);

  const handleItemSelect = (
    selectedItem: LibraryItemsSearchOption,
    { reset }
  ) => {
    reset();
    handleReset();
    onItemSelect?.(selectedItem);
  };

  const handleSearchClick = (e: any) => {
    e.preventDefault();
    onSearch?.(query, categories);
  };

  const renderPreloader = () => {
    if (minimumCharactersRequired && query.length < minimumCharacters) {
      return;
    }

    const itemsLength = getMenuPreloaderItemsLength(query.length);

    return Array(itemsLength)
      .fill(true)
      .map((_, i) => (
        <MenuItemPreloader
          className="MenuItemPreloader"
          key={`menu-item-preloader-${i}`}
        />
      ));
  };

  const renderAddToLibrary = (caption: string) => {
    const appendElement = onAddItem ? (
      <Button type="button" id="add-to-library" variant="text" size="xs">
        {t('addToLibrary')}
      </Button>
    ) : null;

    return (
      <MenuItem
        className={classNames(
          'MenuItem MenuItem--not-found',
          styles.itemNotFound
        )}
        label={<Text variant="caption">{caption}</Text>}
        sublabel={
          <Text component="span" color="secondary">
            {query}
          </Text>
        }
        append={appendElement}
        onClick={() => onAddItem?.(query)}
      />
    );
  };

  const renderOption = (option: LibraryItemsSearchOption) => {
    return (
      <Option
        id={`library-items-search-option-${option.uuid}`}
        key={option.uuid}
        query={query}
        label={option.label}
        category={option.category}
        isSelected={option.isSelected}
      />
    );
  };

  const renderMenuAppend = (isLoading: boolean) => {
    if (minimumCharactersRequired && query.length < minimumCharacters) {
      return;
    }

    if (!isLoading && !error && results && results.length) {
      return renderAddToLibrary(t('itemNotFoundAddNewOne'));
    }

    return null;
  };

  const renderErrorElement = (caption?: string) => {
    return (
      <MenuItem
        label={
          <Text variant="caption" color="error">
            {caption ?? t('somethingWentWrong')}
          </Text>
        }
        sublabel={
          !caption ? (
            <Text component="span" color="error">
              {t('pleaseTryAgainLater')}
            </Text>
          ) : null
        }
      />
    );
  };

  const renderMenuPrepend = () => {
    if (error) {
      return renderErrorElement();
    } else if (isLoading || (isTouched && !results)) {
      return renderPreloader();
    } else if (minimumCharactersRequired && query.length < minimumCharacters) {
      return renderErrorElement(
        `${t('minimumSearchLength')} ${minimumCharacters}`
      );
    } else if (isTouched && results && !results.length) {
      return renderAddToLibrary(t('itemNotFound'));
    }

    return null;
  };

  const selectProps = {
    categories,
    error,
    isLoading,
    onItemSelect,
    onReset,
    onSearch,
    results,
    onAddItem,
  };

  return (
    <div className={styles.container}>
      <FormSelect
        {...selectProps}
        emptyMenuLabel={null}
        error={error}
        inputValue={query}
        mode="search"
        name="libraryItemSearch"
        onSelect={handleItemSelect}
        onInputValueChange={handleInputValueChange}
        options={searchResults}
        renderMenuAppend={renderMenuAppend}
        renderMenuPrepend={renderMenuPrepend}
        renderOption={renderOption}
        selectInputAppend={
          <Button
            type="button"
            variant="text"
            className={styles.button}
            disabled={!query}
            onClick={handleSearchClick}
          >
            {t('Search')}
          </Button>
        }
        selectInputPrepend={<SearchIcon />}
      />
    </div>
  );
};
