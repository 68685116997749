import { nanoid } from 'nanoid';
import {
  CREATE_ADDITIONAL_DEBRIEFING_QUESTION,
  CREATE_ADDITIONAL_TIMEOUT_QUESTION,
  DELETE_ADDITIONAL_DEBRIEFING_QUESTION,
  DELETE_ADDITIONAL_TIMEOUT_QUESTION,
  TOGGLE_DEBRIEFING_QUESTION_SELECTION,
  TOGGLE_TIMEOUT_QUESTION_SELECTION,
} from 'store/modules/templates-library/types';
import {
  makeChangeTemplateQuestionOrderAction as makeOrderAction,
  wrapStaticDebriefingQuestionsAction as wrapDebriefingAction,
  wrapStaticTimeoutQuestionsAction as wrapTimeoutAction,
} from './questions-helpers';

export const createAdditionalDebriefingQuestion_STATIC = ({
  content,
  templateId,
}) => ({
  type: CREATE_ADDITIONAL_DEBRIEFING_QUESTION,
  payload: {
    question: {
      id: nanoid(),
      category: 'debriefings',
      content,
    },
    templateId,
  },
});

export const createAdditionalDebriefingQuestion = wrapDebriefingAction(
  createAdditionalDebriefingQuestion_STATIC
);

export const changeDebriefingQuestionsOrder_STATIC = makeOrderAction(
  'debriefingQuestions'
);

export const changeDebriefingQuestionsOrder = wrapDebriefingAction(
  changeDebriefingQuestionsOrder_STATIC
);

export const toggleDebriefingQuestionSelection_STATIC = ({
  id,
  templateId,
  checked,
}) => ({
  type: TOGGLE_DEBRIEFING_QUESTION_SELECTION,
  payload: {
    id,
    templateId,
    checked,
  },
});

export const toggleDebriefingQuestionSelection = wrapDebriefingAction(
  toggleDebriefingQuestionSelection_STATIC
);

export const deleteAdditionalDebriefingQuestion_STATIC = ({
  id,
  templateId,
}) => ({
  type: DELETE_ADDITIONAL_DEBRIEFING_QUESTION,
  payload: { id, templateId },
});

export const deleteAdditionalDebriefingQuestion = wrapDebriefingAction(
  deleteAdditionalDebriefingQuestion_STATIC
);

export const changeTimeoutQuestionsOrder_STATIC = makeOrderAction(
  'timeoutQuestions'
);
export const changeTimeoutQuestionsOrder = wrapTimeoutAction(
  changeTimeoutQuestionsOrder_STATIC
);

export const createAdditionalTimeoutQuestion_STATIC = ({
  content,
  templateId,
}) => ({
  type: CREATE_ADDITIONAL_TIMEOUT_QUESTION,
  payload: {
    question: { id: nanoid(), category: 'timeouts', content },
    templateId,
  },
});
export const createAdditionalTimeoutQuestion = wrapTimeoutAction(
  createAdditionalTimeoutQuestion_STATIC
);

export const toggleTimeoutQuestionSelection_STATIC = ({
  id,
  templateId,
  checked,
}) => ({
  type: TOGGLE_TIMEOUT_QUESTION_SELECTION,
  payload: { id, templateId, checked },
});
export const toggleTimeoutQuestionSelection = wrapTimeoutAction(
  toggleTimeoutQuestionSelection_STATIC
);

export const deleteAdditionalTimeoutQuestion_STATIC = ({ id, templateId }) => ({
  type: DELETE_ADDITIONAL_TIMEOUT_QUESTION,
  payload: { id, templateId },
});
export const deleteAdditionalTimeoutQuestion = wrapTimeoutAction(
  deleteAdditionalTimeoutQuestion_STATIC
);
