import { RequestId } from './api';
import { Attachment } from './attachments';
import { Equipment, Implant, Instrument } from './library';
import { Entity } from './Entity';

export enum MaterialsGroupType {
  ADDITIONAL_EQUIPMENT = 'ADDITIONAL_EQUIPMENT',
  GENERAL_INSTRUMENTS = 'GENERAL_INSTRUMENTS',
  OTHER = 'OTHER',
}

export interface MaterialsGroup extends Entity {
  attachments: Attachment[];
  description: string;
  equipment: Equipment[];
  implants: Implant[];
  instruments: Instrument[];
  name: string;
  type?: MaterialsGroupType;
}

export type MaterialsGroupInput = {
  attachments?: number[];
  description?: MaterialsGroup['description'];
  name: MaterialsGroup['name'];
  type?: MaterialsGroupType;
  order?: number;
};

export interface MaterialsResponse {
  equipment: Equipment[];
  implants: Implant[];
  instruments: Instrument[];
  materialsGroups: MaterialsGroup[];
}

export interface OperationMaterials extends MaterialsResponse {
  operationId: RequestId;
}

export interface TemplateMaterials extends MaterialsResponse {
  templateId: RequestId;
}
