import cn from 'classnames';
import { TextareaBase } from 'components/Form/Textarea/TextareaBase';
import { ListItemBase } from 'components/List';
import { MaxLengthIndicator } from 'components/MaxLengthIndicator';
import { Multiline } from 'components/Multiline';
import React, {
  ComponentType,
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { countCharsOccupied } from 'utils/count-lines';
import classes from './DescriptionInput.module.scss';

interface Props {
  value?: any;
  charsLimit?: number;
  initialLinesCount?: number;
  charsPerLineLimit?: number;
  defaultValue?: string;
}

export const DescriptionInput: ComponentType<
  Props & HTMLAttributes<HTMLTextAreaElement>
> = memo(
  ({
    className,
    charsLimit,
    charsPerLineLimit,

    defaultValue: defaultValueProp,
    onChange,
    onBlur,
    onKeyDown,
    placeholder,
  }) => {
    const defaultValue = defaultValueProp?.trim?.();
    const [showTextarea, setShowTextarea] = useState(false);

    const calculateCharsOccupied = useCallback(
      (value) => {
        if (!value?.length) return 0;
        if (!charsPerLineLimit) return value.length;
        return countCharsOccupied(value, charsPerLineLimit);
      },
      [charsPerLineLimit]
    );

    const updateCharsLength = useCallback(
      (value) => {
        setCharsLength(calculateCharsOccupied(value));
      },
      [calculateCharsOccupied]
    );

    const hasCharsLimit = typeof charsLimit === 'number';
    const [charsLength, setCharsLength] = useState<number>(
      calculateCharsOccupied(defaultValue)
    );

    useEffect(() => {
      if (!hasCharsLimit) return;
      updateCharsLength(defaultValue);
    }, [hasCharsLimit, defaultValue, charsPerLineLimit, updateCharsLength]);

    const handleChange = (event) => {
      const { value } = event.target;

      if (typeof onChange === 'function') {
        onChange(event);
      }

      if (hasCharsLimit && charsPerLineLimit) {
        const charsOccupied = calculateCharsOccupied(value);
        setCharsLength(charsOccupied);
      }
    };

    const handleBlur = (event) => {
      onBlur && onBlur(event);
      setShowTextarea(false);
    };

    return (
      <ListItemBase inlineChildren className={cn(className, classes.listItem)}>
        {showTextarea ? (
          <TextareaBase
            // ref={inputRef}
            autoFocus
            wrapperClassName={classes.textareaWrapper}
            className={cn(classes.input)}
            defaultValue={defaultValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
          />
        ) : (
          <p
            className={classes.description}
            role="button"
            onClick={() => setShowTextarea(true)}
          >
            {defaultValue ? (
              <Multiline>{defaultValue}</Multiline>
            ) : (
              <span className={classes.descriptionPlaceholder}>
                {placeholder}
              </span>
            )}
          </p>
        )}

        {hasCharsLimit && (
          <div className={classes.indicator}>
            <MaxLengthIndicator
              length={charsLength}
              limit={charsLimit as number}
            />
          </div>
        )}
      </ListItemBase>
    );
  }
);

DescriptionInput.displayName = 'DescriptionInput';
