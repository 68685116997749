import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Label } from 'components/Form/FormInput';
import { Loader } from 'components/Loader';
import { readDiagnosis } from 'store/modules/operation-planning';
import { useTranslation } from 'react-i18next';
import { DiagnosesType } from 'common-types/library';
import { Badge } from 'components/Badge';
import { DataTag } from 'components/DataTag';
import { TranslatableString } from 'components/TranslatableString';

interface Props {
  diagnosesIds: number[];
}

export const DiagnosesList = ({ diagnosesIds }: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const diagnoses: DiagnosesType[] = useSelector((state: any) => {
    if (state.entities) {
      return Object.keys(state.entities.diagnoses).map(
        (id) => state.entities.diagnoses[id]
      );
    }

    return [];
  });

  const fetchDiagnoses = () => {
    if (!diagnosesIds) return;

    diagnosesIds.forEach((id) => {
      if (typeof id !== 'number' && typeof id !== 'string') return;

      dispatch(readDiagnosis(id));
    });
  };

  useEffect(() => {
    if (!diagnoses) {
      fetchDiagnoses();
    }
  }, []);

  useEffect(() => {
    if (diagnosesIds) {
      fetchDiagnoses();
    }
  }, [diagnosesIds?.length]);

  const hasDiagnoses = Boolean(diagnosesIds && diagnosesIds.length);
  return (
    <div>
      <Label>{t('Diagnosis')}</Label>
      {hasDiagnoses && !diagnoses && <Loader mode="minimal" />}
      {diagnoses &&
        diagnoses
          .filter(Boolean)
          .filter((diagnose) => diagnosesIds.includes(diagnose.id))
          .map((diagnosis) => (
            <p key={diagnosis.id}>
              <Badge className="mr-05">
                <DataTag propKey="diagnosis-code">{diagnosis.code}</DataTag>
              </Badge>
              <TranslatableString string={diagnosis.name}>
                {(translattedName) => (
                  <DataTag propKey="diagnosis-name">{translattedName}</DataTag>
                )}
              </TranslatableString>
            </p>
          ))}
      {!hasDiagnoses && t('noDiagnosesAdded')}
    </div>
  );
};
