import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, IconEmphasized, Row } from 'components';
import { Title } from './formPageTitle/Title';
import { TitleMenu } from './formPageTitle/TitleMenu';
import { useParams, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AssessmentForm } from 'common-types/assessmentForm';
import { selectInstitute, selectUser } from 'store/modules/auth';
import { deleteAssessmentFormById } from 'store/modules/assessment-forms';
import { withParams } from 'utils';
import { PLAN_ASSESSMENT_FORM } from 'scenes/routes.enum';

export const AssessmentFormPageTitle: FC = () => {
  const url = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { assessmentFormId } = useParams<{ assessmentFormId: string }>();

  const assessmentForm: AssessmentForm = useSelector(
    (state: Record<string, any>) =>
      state.assessmentForms.entities[assessmentFormId]
  );

  const titleFromStore: string = useSelector(
    (state: Record<string, any>) => state.assessmentForms.title
  );

  const institute = useSelector(selectInstitute);
  const user = useSelector(selectUser);
  const isInstituteAdmin =
    !!institute && !!user && institute.adminId === user.id;

  const pathname = window.location.pathname;

  const title = useMemo(() => {
    if (url.path.includes('create')) {
      if (pathname.includes('criteria') || pathname.includes('weighting')) {
        return titleFromStore;
      }
      return t('assessmentForms.pageTitleCreate');
    } else {
      return assessmentForm && assessmentForm.title;
    }
  }, [url.path, t, assessmentForm, pathname, titleFromStore]);

  const instituteName = useMemo(() => {
    if (url.path.includes('create')) {
      return institute.name;
    } else {
      return assessmentForm && assessmentForm.institute.name;
    }
  }, [assessmentForm, institute.name, url.path]);

  const isPreviewAssessment = useMemo(() => {
    return url.path.includes('preview');
  }, [url.path]);

  const icon = useMemo(() => {
    if (url.path.includes('create') || url.path.includes('edit')) {
      return <IconEmphasized icon="edit" type="info" />;
    } else if (url.path.includes('execute')) {
      return <IconEmphasized icon="check-circle" type="info" />;
    }
    return <IconEmphasized icon="library" type="info" />;
  }, [url.path]);

  const deleteAssessmentForm = () => {
    dispatch(deleteAssessmentFormById(assessmentFormId));
  };

  return (
    <Row alignItems="center" justifyContent="space-between" nowrap>
      <Col>
        <Row alignItems="center" nowrap>
          <Col auto>{icon}</Col>
          <Col>
            <Title pageTitle={title} institute={instituteName} />
          </Col>
          {isPreviewAssessment && (
            <>
              {isInstituteAdmin && (
                <Col auto>
                  <TitleMenu onDeleteAction={deleteAssessmentForm} />
                </Col>
              )}
              <Col auto>
                <Button
                  component={Link}
                  to={withParams(PLAN_ASSESSMENT_FORM, { assessmentFormId })}
                  onClick={(e) => e.stopPropagation()}
                >
                  {t('Plan')}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};
