import { readCategoriesReq } from 'api/v1';
import { makeThunkFromTypes } from 'store/utils/make-thunk';
import * as actionTypes from './types';

const readTemplateCategories = makeThunkFromTypes(readCategoriesReq, {
  request: actionTypes.READ_TEMPLATE_CATEGORIES_REQUEST,
  success: actionTypes.READ_TEMPLATE_CATEGORIES_SUCCESS,
  failure: actionTypes.READ_TEMPLATE_CATEGORIES_FAILURE,
});

export { readTemplateCategories };
