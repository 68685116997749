import {
  CREATE_INSTITUTE_CONTACT_SUCCESS,
  DELETE_INSTITUTE_CONTACT_SUCCESS,
} from 'store/modules/settings';
import { patchState } from '../utils/patch-state';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_INSTITUTE_CONTACT_SUCCESS: {
      const contact = payload.input;
      const instituteId = contact?.instituteId;
      const institute = state[instituteId];
      if (!institute) return state;

      return patchState(state, instituteId, {
        companiesContacts: [...(institute.companiesContacts || []), contact.id],
      });
    }

    case DELETE_INSTITUTE_CONTACT_SUCCESS: {
      const contact = payload.input;
      const instituteId = contact?.instituteId;
      const institute = state[instituteId];
      if (!institute || !contact?.id) return state;

      return patchState(state, instituteId, {
        companiesContacts: (institute.companiesContacts || []).filter(
          (id) => id !== contact.id
        ),
      });
    }

    default:
      return state;
  }
};
