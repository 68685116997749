import React, { Component } from 'react';
import { func, string, shape } from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { withRouter } from 'react-router-dom';
import { stringify } from 'qs';
import { parseSearchParams } from 'utils';
import { pick } from 'lodash';

export const withSearchParams = (
  paramsPath = null,
  shouldWrapWithRouter = true
) => ComponentToWrap => {
  const pickSearchParams = params => {
    if (!params) return {};
    if (!paramsPath) return params;
    return pick(params, paramsPath);
  };

  class WrappedComponent extends Component {
    static _propTypes = {
      history: shape({
        push: func,
      }),
      location: shape({
        search: string,
      }),
    };

    get searchProps() {
      const { history } = this.props;
      const searchParams = parseSearchParams(history.location.search);
      return pickSearchParams(searchParams);
    }

    onSearchParamsChange = params => {
      const { history } = this.props;
      const searchProps = {
        ...this.searchProps,
        ...params,
      };
      history.push({
        search: stringify(searchProps),
      });
    };

    render() {
      return (
        <ComponentToWrap
          {...this.props}
          {...this.searchProps}
          setSearchParams={this.onSearchParamsChange}
        />
      );
    }
  }

  hoistStatics(WrappedComponent, ComponentToWrap);

  if (shouldWrapWithRouter) {
    return withRouter(WrappedComponent);
  }

  return WrappedComponent;
};
