import {
  CREATE_ADDITIONAL_DEBRIEFING_QUESTION,
  DELETE_ADDITIONAL_DEBRIEFING_QUESTION,
  READ_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS,
  TOGGLE_DEBRIEFING_QUESTION_SELECTION,
  UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS,
} from 'store/modules/templates-library/types';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case READ_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS:
    case UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS:
      return { ...state, ...payload.entities.debriefingQuestions };

    case TOGGLE_DEBRIEFING_QUESTION_SELECTION: {
      const questionId = payload.id;
      const question = state[questionId];

      return {
        ...state,
        [questionId]: { ...question, isActive: !question.isActive },
      };
    }

    case CREATE_ADDITIONAL_DEBRIEFING_QUESTION: {
      const { question } = payload;
      return {
        ...state,
        [question.id]: question,
      };
    }

    case DELETE_ADDITIONAL_DEBRIEFING_QUESTION: {
      const deletedQuestion = state[payload.id];
      return {
        ...state,
        [payload.id]: { ...deletedQuestion, isDeleted: true },
      };
    }

    default:
      return state;
  }
};
