import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/js';

import withFileUpload_DEPRECATED from 'containers/withFileUpload';

import styles from './SettingsAvatar.module.scss';

import { BucketFile } from 'common-types/attachments';
import { HTMLFile } from 'common-types/common';
import { UploadFilesButton } from 'components/UploadFilesButton';
import { Button } from 'components/Button';
import { Row } from 'components/Grid';
import { Badge } from 'components/Badge/Badge';
import { Avatar } from 'components/Avatar';

interface Props {
  onUpload: (files: HTMLFile[]) => Promise<BucketFile[]>;
  updateUserAvatar: ({ imageUrl }: { imageUrl: string }) => void;
  givenName: string;
  surname: string;
  email: string;
  imageUrl: string;
  isFileUploading: boolean;
  roles: string[];
}

const SettingsAvatarComponent = ({
  onUpload,
  updateUserAvatar,
  givenName,
  surname,
  email,
  imageUrl,
  isFileUploading,
  roles,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const handleUserImageChange = (event) => {
    const files: HTMLFile[] = [...event.currentTarget.files];
    onUpload([...files])
      .then((files) => {
        files[0].fileKey && updateUserAvatar({ imageUrl: files[0].fileKey });
      })
      .catch((err) => Bugsnag.notify(err));
  };

  const handleUserImageDelete = () => {
    updateUserAvatar({ imageUrl: '' });
  };

  return (
    <section className={styles.uploadImageCnt}>
      <Avatar
        className={imageUrl ? styles.selectedImage : styles.imageStub}
        givenName={givenName}
        surname={surname}
        imageUrl={imageUrl}
      />
      <h4 className={styles.userName}>
        {givenName}
        &nbsp;
        {surname}
      </h4>
      <h5 className={styles.userEmail}>{email}</h5>
      <div className="mb-1">
        <div className="mb-1">
          <UploadFilesButton
            className="mb-05"
            id="settings-avatar"
            onChange={handleUserImageChange}
            loading={isFileUploading}
            maxFilesAllowed={1}
          >
            {imageUrl ? t('settings.changePhoto') : t('settings.uploadPhoto')}
          </UploadFilesButton>
        </div>
        {Boolean(imageUrl) && (
          <div>
            <Button variant="secondary-danger" onClick={handleUserImageDelete}>
              {t('settings.removePhoto')}
            </Button>
          </div>
        )}
      </div>
      <Row>
        {!!roles?.length &&
          roles.map((role) => (
            <Badge style={{ fontSize: '.8em', margin: '.1em' }} key={role}>
              {role}
            </Badge>
          ))}
      </Row>
    </section>
  );
};

export const SettingsAvatar = withFileUpload_DEPRECATED(
  SettingsAvatarComponent
);
