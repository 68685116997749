import {
  createSuccessActionCreator_DEPRECATED,
  createFailureActionCreator,
} from 'store/utils';
import { templateMaterialsSchema } from 'api/v1/schemas';

import {
  READ_TEMPLATE_MATERIALS_FAILURE,
  READ_TEMPLATE_MATERIALS_REQUEST,
  READ_TEMPLATE_MATERIALS_SUCCESS,
  UPDATE_TEMPLATE_MATERIALS_FAILURE,
  UPDATE_TEMPLATE_MATERIALS_REQUEST,
  UPDATE_TEMPLATE_MATERIALS_SUCCESS,
  CREATE_TEMPLATE_MATERIALS_REQUEST,
  CREATE_TEMPLATE_MATERIALS_SUCCESS,
  CREATE_TEMPLATE_MATERIALS_FAILURE,
} from '../types';

export const readTemplateMaterialsRequest = templateId => ({
  type: READ_TEMPLATE_MATERIALS_REQUEST,
  payload: { templateId },
});

export const readTemplateMaterialsSuccess = createSuccessActionCreator_DEPRECATED(
  READ_TEMPLATE_MATERIALS_SUCCESS,
  null,
  templateMaterialsSchema
);

export const readTemplateMaterialsFailure = createFailureActionCreator(
  READ_TEMPLATE_MATERIALS_FAILURE
);

export const updateTemplateMaterialsRequest = (templateId, materials) => ({
  type: UPDATE_TEMPLATE_MATERIALS_REQUEST,
  payload: { templateId, materials },
});

export const updateTemplateMaterialsSuccess = createSuccessActionCreator_DEPRECATED(
  UPDATE_TEMPLATE_MATERIALS_SUCCESS,
  null,
  templateMaterialsSchema
);

export const updateTemplateMaterialsFailure = createFailureActionCreator(
  UPDATE_TEMPLATE_MATERIALS_FAILURE
);

export const createTemplateMaterialsRequest = (templateId, materials) => ({
  type: CREATE_TEMPLATE_MATERIALS_REQUEST,
  payload: { templateId, materials },
});

export const createTemplateMaterialsSuccess = createSuccessActionCreator_DEPRECATED(
  CREATE_TEMPLATE_MATERIALS_SUCCESS,
  null,
  templateMaterialsSchema
);

export const createTemplateMaterialsFailure = createFailureActionCreator(
  CREATE_TEMPLATE_MATERIALS_FAILURE
);
