import cn from 'classnames';
import { Text } from 'components/Typography';
import React, { ComponentType, InputHTMLAttributes, useMemo } from 'react';
import { AutosizeInput } from './AutosizeInput';
import { Textarea } from './Textarea';
import { Input } from './Input';
import { Editable } from 'components/Editable';

interface Props {
  block?: boolean;
  autoSize?: boolean;
  defaultInputSize?: number;
  inputClassName?: string;
  error?: string;
  multiline?: boolean;
}

export const InputString: ComponentType<
  Props &
    InputHTMLAttributes<HTMLInputElement> &
    InputHTMLAttributes<HTMLTextAreaElement>
> = ({
  block = false,
  className,
  autoSize = true,
  defaultInputSize = 20,
  multiline = false,
  error,
  inputClassName,
  ...inputProps
}) => {
  const InputComponent = useMemo(() => {
    if (multiline) return Textarea;
    if (!autoSize) return Input;
    return AutosizeInput;
  }, [multiline, autoSize]);

  return (
    <span className={cn(className, 'relative')}>
      <Editable component="div" block={block}>
        <InputComponent
          className={cn(inputClassName, 'input-string')}
          defaultInputSize={defaultInputSize}
          error={error}
          {...inputProps}
        />
      </Editable>
      {Boolean(error) && (
        <div className="pl-05 pr-05">
          <Text variant="error">{error}</Text>
        </div>
      )}
    </span>
  );
};
