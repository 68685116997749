import { LibraryCategories, LibraryElement } from 'common-types/library';
import { Button } from 'components/Button';
import { FormSelect } from 'components/Form/FormInput';
import { Icon } from 'components/Icon';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import React, {
  ComponentType,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classes from '../LibraryItemsSearchInput.module.scss';
import { AdditionalItem } from './AdditionalItem';
import { LibraryElementsOption } from './LibraryElementsOption';
import { SearchError } from './SearchError';
import { SearchPreloader } from './SearchPreloader';

export type LibraryElementOption = LibraryElement & {
  label: number | string;
  value: number | string;
  isSelected?: boolean;
};

export interface Props {
  autoFocus?: boolean;
  checkSelected?: boolean | ((option: LibraryElementOption) => boolean);
  defaultQuery?: string;
  error?: string | boolean;
  inputAppend?: ReactElement | null;
  isLoading?: boolean;
  results: LibraryElementOption[];
  value?: string | number | null;
  placeholder?: string;
  onElementCreate?: (query: string) => any;
  onReset?: () => void;
  onSearch?: (query: string, categories: LibraryCategories[]) => any;
  onSelect?: (option: LibraryElementOption) => any;
  selectValue: (any) => any;
}

const defaultCheckSelected = false;
const QUERY_CALLBACK_DEBOUNCE_RATE = 400;
const searchIconElement = <Icon className={classes.icon} name="magnifier" />;
const elementToString = (element) => element?.name ?? '';

export const LibraryElementsSearchInput: ComponentType<Props> = ({
  autoFocus,
  checkSelected = defaultCheckSelected,
  defaultQuery = '',
  error,
  inputAppend,
  isLoading = false,
  results,
  selectValue,
  value,
  onElementCreate = noop,
  onSearch = noop,
  onSelect = noop,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(defaultQuery);
  const [touched, setTouched] = useState<boolean>(false);

  const searchResults = useMemo<LibraryElementOption[] | null>(() => {
    if (isLoading || error || !Array.isArray(results)) return null;

    return results
      .map((option) => {
        return {
          ...option,
          value: option.uuid,
          label: option.name,
          isSelected:
            typeof checkSelected === 'boolean'
              ? checkSelected
              : checkSelected(option),
        };
      })
      .sort((prev, next) => Number(next.isSelected) - Number(prev.isSelected));
  }, [isLoading, error, results, checkSelected]);

  const handleSearch = useCallback(
    debounce((query: string): void => {
      onSearch(query);
    }, QUERY_CALLBACK_DEBOUNCE_RATE),
    [onSearch]
  );

  const handleSelect = (selectedElement?: LibraryElementOption) => {
    if (!selectedElement) {
      onSelect(selectedElement);
      return;
    }

    const { value, label, isSelected, ...originalElement } = selectedElement;
    onSelect(originalElement);
  };

  const handleInputChange = (query, { type }) => {
    if (type !== Downshift.stateChangeTypes.changeInput) {
      return;
    }
    setQuery(query);
    setTouched(true);

    if (query) handleSearch(query);
  };

  const renderOption = (option, { isSelected, isHighlighted }) => {
    return (
      <LibraryElementsOption
        id={`library-elements-search-option-${option.uuid}`}
        isHighlighted={isHighlighted}
        isSelected={isSelected}
        option={option}
        query={query}
      />
    );
  };

  const renderNotFound = (label = t('itemNotFoundAddNewOne')) => {
    return (
      <AdditionalItem
        label={label}
        subLabel={query}
        append={
          <Button id="add-to-library" variant="text" size="xs">
            {t('addToLibrary')}
          </Button>
        }
        onClick={() => onElementCreate(query)}
      />
    );
  };

  const renderMenuAppend = () => {
    if (isLoading || error || !searchResults || !searchResults.length) {
      return null;
    }

    return renderNotFound();
  };

  const renderMenuPrepend = () => {
    if (error) {
      return <SearchError />;
    } else if (isLoading || (touched && !searchResults)) {
      return <SearchPreloader queryLength={query?.length} />;
    } else if (touched && searchResults && !searchResults.length) {
      return renderNotFound();
    }

    return null;
  };

  return (
    <div className={classes.container}>
      <FormSelect
        autoFocus={autoFocus}
        emptyMenuLabel={null}
        error={error}
        itemToString={elementToString}
        mode="search"
        name="library-elements-search-input"
        options={searchResults}
        renderMenuAppend={renderMenuAppend}
        renderMenuPrepend={renderMenuPrepend}
        renderOption={renderOption}
        selectInputAppend={inputAppend}
        selectInputPrepend={searchIconElement}
        selectValue={selectValue}
        value={value}
        placeholder={placeholder}
        onInputValueChange={handleInputChange}
        onSelect={handleSelect}
      />
    </div>
  );
};

LibraryElementsSearchInput.displayName = 'LibraryElementsSearchInput';
