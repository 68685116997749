import React, { FC, ChangeEvent } from 'react';

import { FormInputBase } from 'components/Form/FormInput/FormInputBase';
import { DeprecatedFlex } from 'components/Grid';

import { TemplateCategoryBreadcrumbs } from './TemplateCategoryBreadcrumbs';
import { TemplateCategorySelect } from './TemplateCategorySelect';
import { FormInputBaseProps } from 'common-types/common';
import { useTranslation } from 'react-i18next';

interface FormTemplateCategoryInputProps extends FormInputBaseProps {
  value: number | null;
  onChange(event: ChangeEvent): void;
  placeholder?: string;
  allowEmpty: boolean;
  readOnly?: boolean;
}

export const FormTemplateCategoryInput: FC<FormTemplateCategoryInputProps> = ({
  value,
  placeholder,
  readOnly,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FormInputBase {...props}>
      {({ getProps }) => (
        <DeprecatedFlex>
          {value && <TemplateCategoryBreadcrumbs categoryId={value} />}
          {!value && (placeholder || t('selectTemplateDiscipline'))}
          <div className="ml-05">
            <TemplateCategorySelect
              readOnly={readOnly}
              {...getProps({ className: 'align-center' })}
            />
          </div>
        </DeprecatedFlex>
      )}
    </FormInputBase>
  );
};
