import { DATE_FORMAT } from 'app-constants';
import { format, formatISO, isValid } from 'date-fns';
import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { parseDate } from 'utils';
import Bugsnag from '@bugsnag/js';

export type ChangeEventLike = {
  type: string;
  target: { name?: string; value: string };
};

/**
 * Prevents default key press event
 */
export const preventSubmitOnEnter = (ev: KeyboardEvent) => {
  if (ev.key === 'Enter') ev.preventDefault();
};

/**
 * Time options for time picker
 */
export const timesArray = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

/**
 * Shares date picker logic between DatePicker and DateTimePicker components
 */
export const useDatePickerState = ({
  name,
  rawValue,
  withEvent,
  onChange,
  defaultTime = '08:00',
}: {
  name?: string;
  rawValue?: string | number | Date;
  /**
   * What type of an event to use
   */
  withEvent?: boolean;
  /**
   * Formik function which changes the values in form
   */
  onChange?: (value: string | ChangeEventLike) => void;

  defaultTime?: string;
}) => {
  const parsedRawValue = parseDate(rawValue);
  const [date, setDate] = useState(
    parsedRawValue && isValid(parsedRawValue)
      ? format(parsedRawValue, 'MM/dd/yyyy')
      : ''
  );
  const [time, setTime] = useState(
    parsedRawValue && isValid(parsedRawValue)
      ? format(parsedRawValue, 'kk:mm')
      : ''
  );

  // Formats raw value prop to date format appropriate for atlaskit DatePicker
  const dateISO = useMemo(() => {
    const parsedRawMemoValue = parseDate(rawValue);
    if (!rawValue) return;

    if (isValid(parsedRawMemoValue)) return formatISO(parsedRawMemoValue);
  }, [rawValue]);

  const onDateChange = (value: string) => {
    //! Safari expects to get Date in 02/02/2023 format, cannot use dashes!
    const datetimeISO = formatISO(
      new Date(value.replace(/-/g, '/') + ' ' + (time || defaultTime))
    );

    if (!withEvent) {
      onChange?.(datetimeISO);
      setDate(value);
    } else {
      onChange?.({
        type: 'change',
        target: { name, value: datetimeISO },
      });
      setDate(value);
    }

    if (time === '') {
      onTimeChange(defaultTime, value);
    }
  };

  const parseInputValue = useCallback(
    (date: string) => {
      return parseDate(
        date,
        DATE_FORMAT,
        dateISO ? new Date(dateISO) : undefined
      );
    },
    [dateISO]
  );

  const onTimeChange: any = (time, date = '') => {
    try {
      const dateFormatted = format(new Date(dateISO || date), 'MM/dd/yyyy');
      const datetimeISO = formatISO(new Date(dateFormatted + ' ' + time));
      setTime(time);

      if (!withEvent) {
        onChange?.(datetimeISO);
      } else {
        onChange?.({ type: 'change', target: { name, value: datetimeISO } });
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

  return { date, time, dateISO, onDateChange, parseInputValue, onTimeChange };
};
