import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './AuthFormKeepLoggedInField.module.scss';

import { Col, Row, Checkbox } from 'components';
import { user as userStore } from 'local-store';
import { FORGOT_PASSWORD } from 'scenes/routes.enum';

interface AuthFormKeepLoggedInFieldProps {
  email: string;
}

export const AuthFormKeepLoggedInField: FC<AuthFormKeepLoggedInFieldProps> =
  memo(function AuthFormKeepLoggedInField({ email }) {
    const { t } = useTranslation();

    return (
      <Row justifyContent="space-between">
        <Col auto>
          <Checkbox
            id="keep-logged-in"
            label={t('keepLogged')}
            onChange={userStore.setKeepSession}
            defaultChecked={userStore.getKeepSession()}
          />
        </Col>

        <Col auto>
          <Link
            className={styles.link}
            to={`${FORGOT_PASSWORD}?email=${email}`}
          >
            {t('passwordForgotQuestion')}
          </Link>
        </Col>
      </Row>
    );
  });
