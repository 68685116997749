import {
  LIBRARY_ITEM_TRANSLATABLE_FIELDS,
  pickElementValues,
  TEMPLATE_ELEMENTS_CATEGORIES,
} from 'app-constants';
import { SUPPORTED_LANGUAGES } from 'app-constants/translations';
import {
  FormActions,
  FormBody,
  FormSelect,
  calculateOpStepCharLimits,
  FormTextarea,
  MaxLengthIndicator,
  LibraryForm,
  Button,
} from 'components';
import withT from 'containers/withT';
import { Formik } from 'formik';
import { get, isObject } from 'lodash';
import { arrayOf, bool, func, oneOf, shape, string } from 'prop-types';
import React, { Component, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { elementTypes } from 'shared-prop-types';
import {
  createErrorsSelector,
  createLoadingSelector,
} from 'store/modules/api-requests';
import {
  addLibraryItemFromSearch,
  CREATE_NEW_LIBRARY_ITEM_REQUEST,
} from 'store/modules/library';
import { useTranslatableString } from 'utils';
import { countCharsOccupied, countLines } from 'utils/count-lines';
import translatableValue from 'utils/translatable-value';
import { LibraryCategories } from '../../common-types/library';

const getCategoryTransKey = (category, t) =>
  ({
    checks: t('Checks'),
    equipment: t('Equipment'),
    implants: t('Implants'),
    instruments: t('Instruments'),
    procedures: t('Procedures'),
    risks: t('Risks'),
  }[category] || null);

const ElementForm = ({
  categories,
  isLoading,

  errors,
  resetForm,
  setFieldValue,
  handleChange,
  handleSubmit,
  values,
  touched,
}) => {
  const { t } = useTranslation();
  const { category } = values;
  const isTemplateElement = TEMPLATE_ELEMENTS_CATEGORIES.includes(category);
  const resetAndHandleChange = (event) => {
    resetForm();
    setFieldValue('name', values.name);
    handleChange(event);
  };

  const categoriesOptions = useMemo(() => {
    if (categories.length === 1) return categories[0];

    return categories.map((category) => ({
      label: getCategoryTransKey(category, t),
      value: category,
    }));
  }, [categories, t]);

  const nameTranslationResult = useTranslatableString(values.name);
  const descriptionTranslationResult = useTranslatableString(
    values.description
  );

  const name = nameTranslationResult?.translation ?? '';
  const description = descriptionTranslationResult?.translation ?? '';

  const limits = useMemo(() => {
    if (!isTemplateElement) return null;
    const limits = calculateOpStepCharLimits({ name, description });

    const nameLinesCount = name
      ? countLines(name, limits.nameCharsPerLineLimit)
      : 0;
    const nameCharsOccupied = name
      ? countCharsOccupied(name, limits.nameCharsPerLineLimit)
      : 0;

    const descriptionLinesCount = description
      ? countLines(description, limits.descriptionCharsPerLineLimit)
      : 0;
    const descriptionCharsOccupied = description
      ? countCharsOccupied(description, limits.descriptionCharsPerLineLimit)
      : 0;

    return {
      ...limits,
      nameLinesCount,
      nameCharsOccupied,
      descriptionLinesCount,
      descriptionCharsOccupied,
    };
  }, [isTemplateElement, name, description]);

  return (
    <form onSubmit={handleSubmit}>
      <FormBody>
        <FormTextarea
          mode="translatable"
          value={values.name}
          error={touched.name && errors.name}
          id="name"
          label={t('Name')}
          name="name"
          onChange={handleChange}
          placeholder="Enter Name"
          append={
            limits ? (
              <MaxLengthIndicator
                length={limits.nameCharsOccupied}
                limit={limits.nameCharsLimit}
              />
            ) : undefined
          }
        />

        {Array.isArray(categoriesOptions) && (
          <FormSelect
            id="category"
            name="category"
            error={touched.category && errors.category}
            label={t('Category')}
            placeholder={t('selectCategory')}
            value={values.category}
            options={categoriesOptions}
            required
            onChange={resetAndHandleChange}
          />
        )}

        {values.category && (
          <LibraryForm
            category={values.category}
            limits={limits}
            values={values}
            errors={errors}
            handleChange={handleChange}
          />
        )}
      </FormBody>
      {isTemplateElement && (
        <span className="text-xs">
          <>{t('followRecommendedCharactersLimits')}</>
        </span>
      )}
      <FormActions>
        <Button type="submit" loading={isLoading}>
          {t('save')}
        </Button>
      </FormActions>
    </form>
  );
};

export class AddLibraryItemForm extends Component {
  static _propTypes = {
    categories: arrayOf(oneOf(LibraryCategories)),
    errors: shape({
      name: string,
    }),
    element: elementTypes,
    isLoading: bool,
    name: string,
    onItemCreate: func.isRequired,
    onNewItemAdded: func,
    t: func.isRequired,
  };

  static defaultProps = {
    categories: LibraryCategories,
    element: null,
    errors: null,
    isLoading: false,
    name: '',
    onNewItemAdded: null,
  };

  get initialValues() {
    const { name, element, categories } = this.props;

    const makeTranslatable = (value) => translatableValue(value);
    const applyTranslatable = (elementObject) => {
      return LIBRARY_ITEM_TRANSLATABLE_FIELDS.reduce(
        (result, translatableFieldName) => {
          const value = elementObject[translatableFieldName];
          return {
            ...result,
            [translatableFieldName]: makeTranslatable(value),
          };
        },
        { ...elementObject }
      );
    };

    let initialValues = {
      name,
      category: categories.length === 1 ? categories[0] : null,
    };

    if (isObject(element)) {
      const elementValues = pickElementValues(element, element.category);

      initialValues = {
        ...initialValues,
        ...elementValues,
        category: element.category,
      };
    }

    return applyTranslatable(initialValues);
  }

  handleSubmit = (values) => {
    const { onItemCreate, onNewItemAdded } = this.props;
    const { category, ...restValues } = values;
    const elementValues = pickElementValues(restValues, category);

    onItemCreate(category, elementValues, onNewItemAdded);
  };

  handleValidation = (values) => {
    const { t } = this.props;
    const name = get(values, 'name', null);
    const isNameValid = SUPPORTED_LANGUAGES.some(
      (lang) => typeof name[lang] === 'string' && name[lang].length
    );
    const category = get(values, 'category');

    if (isNameValid && category) return {};

    // Nothing should be returned from Formik's validation function
    // eslint-disable-next-line consistent-return
    return {
      name: !isNameValid ? t('nameIsRequired') : null,
      category: !category ? t('chooseLibraryCategory') : null,
    };
  };

  render() {
    const { errors: serverErrors, isLoading, categories } = this.props;

    return (
      <Formik
        validate={this.handleValidation}
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
      >
        {({ errors: localErrors, ...formikProps }) => (
          <ElementForm
            categories={categories}
            isLoading={isLoading}
            errors={{ ...serverErrors, ...localErrors }}
            {...formikProps}
          />
        )}
      </Formik>
    );
  }
}

const errorsSelector = createErrorsSelector([CREATE_NEW_LIBRARY_ITEM_REQUEST]);
const loadingSelector = createLoadingSelector([
  CREATE_NEW_LIBRARY_ITEM_REQUEST,
]);
const mapStateToProps = createStructuredSelector({
  errors: errorsSelector,
  isLoading: loadingSelector,
});
const mapDispatchToProps = {
  onItemCreate: addLibraryItemFromSearch,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhancer = compose(withT, withConnect);

export default enhancer(AddLibraryItemForm);
