import { LoaderBadge } from 'components';
import React, { Component } from 'react';

class LoaderScreen extends Component {
  static _propTypes = {};

  render() {
    return <LoaderBadge show />;
  }
}

export default LoaderScreen;
