import { NormalizedSchema, Schema } from 'normalizr';
import { normalize } from 'utils/normalize';
import { ResponseMeta } from '../../common-types/common';
import { AsyncActionTypes } from './create-action-type';

export type ActionType = string;

export interface Action<P = any> {
  type: ActionType;
  payload: P;
}

export interface RequestAction<P = any> {
  type: ActionType;
  payload: P;
}

export interface SuccessAction<E = any, R = any, U = any> {
  type: ActionType;
  payload: NormalizedSchema<E, R>;
  requestId: number | string | null;
  requestUpdate: U | null;
  meta?: ResponseMeta;
}

export interface FailureAction {
  type: ActionType;
  payload: {
    errorMessage: string; // custom error message that is applied in make-thunk factory
    error?: Error | null; // actual Error that was thrown by a request function
    input?;
  };
}

export interface AsyncRequestActionCreator {
  (payload?: object, ...restParams: any[]): RequestAction;
}

export interface AsyncSuccessActionCreator {
  (
    response: NormalizedSchema<any, any> & { meta? },
    ...restParams: any[]
  ): SuccessAction;
}

export interface AsyncFailureActionCreator {
  (errorMessage: string, error?: Error | null, input?: any): FailureAction;
}

export interface AsyncActionCreators {
  request: AsyncRequestActionCreator;
  success: AsyncSuccessActionCreator;
  failure: AsyncFailureActionCreator;
}

// TODO: Migrate to `createSuccessActionCreator` after all schema normalizations
//  are moved to request functions
// refs: https://nodusmedical.atlassian.net/browse/NM-1719
export const createSuccessActionCreator_DEPRECATED = (
  type: string,
  payloadPropName: string | null = null,
  entitySchema: Schema
) => (response, ...requestArgs) => {
  let action: SuccessAction = {
    type,
    payload: { entities: {}, result: null },
    requestId: null,
    requestUpdate: null,
  };

  if (response && response.meta) {
    action.meta = response.meta;
  }

  if (requestArgs.length) {
    action = {
      ...action,
      requestArgs,
      get requestId() {
        return requestArgs[0];
      },
      get requestUpdate() {
        return requestArgs[1];
      },
    } as SuccessAction;
  }

  if (entitySchema) {
    const normalizedResponse = normalize(response, entitySchema);
    action.payload = {
      ...action.payload,
      ...normalizedResponse,
    };
  }

  if (payloadPropName) {
    action.payload = { ...action.payload, [payloadPropName]: response };
  }

  if (!entitySchema && !payloadPropName) {
    action.payload = response;
  }

  return action;
};

export const createSuccessActionCreator = (
  type: string
): AsyncSuccessActionCreator => (response, ...requestArgs) => {
  let action: SuccessAction = {
    type,
    payload: { ...response },
    requestId: null,
    requestUpdate: null,
  };

  if (response && response.meta) {
    action.meta = response.meta;
  }

  if (requestArgs.length) {
    action = {
      ...action,
      requestArgs,
      get requestId() {
        return requestArgs[0];
      },
      get requestUpdate() {
        return requestArgs[1];
      },
    } as SuccessAction;
  }

  return action;
};

export const createFailureActionCreator = (
  type: string
): AsyncFailureActionCreator => (errorMessage, error, input) => ({
  type,
  payload: {
    error,
    errorMessage,
    input,
  },
});

export const createRequestActionCreator = (
  type: string
): AsyncRequestActionCreator => (payload: any) => ({
  type,
  payload,
});

export const createActionCreator = <P>(type: string) => (
  payload: P = {} as P
): Action<P> => ({
  type,
  payload,
});

export function createAsyncActionCreators(
  actionTypes: AsyncActionTypes
): AsyncActionCreators {
  const { request, success, failure } = actionTypes;
  return {
    request: createRequestActionCreator(request),
    success: createSuccessActionCreator(success),
    failure: createFailureActionCreator(failure),
  };
}
