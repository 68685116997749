import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'components/Typography';

interface ComponentErrorProps {
  /**
   * Component name in which error occurred
   */
  componentName: string;
}

/**
 * Rendres component with error message and component name in which error occured
 */
export const ComponentError: FC<ComponentErrorProps> = ({ componentName }) => {
  const { t } = useTranslation();

  return (
    <Text>
      {t('somethingWentWrong')}. {t('cannotLoadComponent', { componentName })}
    </Text>
  );
};
