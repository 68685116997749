import move from 'array-move';
import { get, has } from 'lodash';
import { mergeState } from 'store/utils';

import {
  ADD_TEMPLATE_ELEMENT,
  CHANGE_TEMPLATE_ELEMENTS_ORDER,
  CHANGE_TEMPLATE_QUESTIONS_ORDER,
  CREATE_ADDITIONAL_DEBRIEFING_QUESTION,
  CREATE_ADDITIONAL_TIMEOUT_QUESTION,
  DELETE_ADDITIONAL_DEBRIEFING_QUESTION,
  DELETE_ADDITIONAL_TIMEOUT_QUESTION,
  DELETE_TEMPLATE_ELEMENT,
  READ_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS,
  READ_TEMPLATE_ELEMENTS_SUCCESS,
  READ_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS,
  UPDATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_ELEMENTS_SUCCESS,
  UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS,
  UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS,
  ADD_TEMPLATE_ELEMENTS_SUCCESS,
} from 'store/modules/templates-library/types';
import { insert, accumulate } from 'utils';
import {
  READ_TEMPLATE_ATTACHMENTS_SUCCESS,
  UPDATE_TEMPLATE_ATTACHMENTS_SUCCESS,
} from 'store/modules/templates-library';

const createQuestion = (questionsType) => (state, payload) => {
  const { question, templateId } = payload;
  const template = state[templateId];
  return {
    ...state,
    [templateId]: {
      ...template,
      [questionsType]: template[questionsType].concat(question.id),
    },
  };
};

const deleteQuestion = (questionsType) => (state, payload) => {
  const { id, templateId } = payload;
  const template = state[templateId];

  return {
    ...state,
    [payload.templateId]: {
      ...template,
      [questionsType]: template[questionsType].filter(
        (questionId) => questionId !== id
      ),
    },
  };
};

const setResult = (key, state, action) => {
  const {
    payload: { templateId, result },
    requestId,
  } = action;
  const element = state && state[requestId];

  return {
    ...state,
    [templateId || requestId]: {
      ...element,
      [key]: result,
    },
  };
};

export default (state = {}, action) => {
  const { type, payload } = action;

  if (has(payload, 'entities.templates')) {
    return { ...mergeState(state || {}, payload.entities.templates) };
  }

  switch (type) {
    case CREATE_ADDITIONAL_TIMEOUT_QUESTION:
      return createQuestion('timeoutQuestions')(state, payload);
    case DELETE_ADDITIONAL_TIMEOUT_QUESTION:
      return deleteQuestion('timeoutQuestions')(state, payload);

    case CREATE_ADDITIONAL_DEBRIEFING_QUESTION:
      return createQuestion('debriefingQuestions')(state, payload);
    case DELETE_ADDITIONAL_DEBRIEFING_QUESTION:
      return deleteQuestion('debriefingQuestions')(state, payload);

    case ADD_TEMPLATE_ELEMENT: {
      const { templateId, uuid, index } = payload;
      const template = state[templateId];
      return {
        ...state,
        [templateId]: {
          ...template,
          elements: template.elements
            ? insert(template.elements, uuid, index)
            : [uuid],
        },
      };
    }

    case DELETE_TEMPLATE_ELEMENT: {
      const { templateId, uuid } = payload;
      const template = get(state, templateId, {});
      return {
        ...state,
        [templateId]: {
          ...template,
          elements: get(template, 'elements', []).filter(
            (elementUUID) => elementUUID !== uuid
          ),
        },
      };
    }

    case UPDATE_TEMPLATE_REQUEST: {
      const { id, update } = payload;
      const template = state && state[id];

      if (!template) return state;

      return {
        ...state,
        [id]: { ...state[id], ...update },
      };
    }

    case READ_TEMPLATE_ATTACHMENTS_SUCCESS:
    case UPDATE_TEMPLATE_ATTACHMENTS_SUCCESS: {
      const { requestId: templateId } = action;
      const {
        result: { attachments },
      } = payload;
      const template = state[templateId];

      return {
        ...state,
        [templateId]: {
          ...template,
          attachments,
        },
      };
    }

    case READ_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS:
    case UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS:
      return setResult('timeoutQuestions', state, action);

    case READ_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS:
    case UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS:
      return setResult('debriefingQuestions', state, action);

    case CHANGE_TEMPLATE_QUESTIONS_ORDER: {
      const { templateId, fromIndex, toIndex, questionsType } = payload;
      const template = get(state, templateId, {});
      const questions = get(template, questionsType, []);

      return {
        ...state,
        [templateId]: {
          ...template,
          [questionsType]: move(questions, fromIndex, toIndex),
        },
      };
    }

    case CHANGE_TEMPLATE_ELEMENTS_ORDER: {
      const { templateId, fromIndex, toIndex } = payload;
      const template = get(state, templateId);
      const templateElements = get(template, 'elements', []);

      return {
        ...state,
        [templateId]: {
          ...template,
          elements: move(templateElements, fromIndex, toIndex),
        },
      };
    }

    case READ_TEMPLATE_ELEMENTS_SUCCESS: {
      const {
        payload: { result: elementsUUIDs },
        requestId: templateId,
      } = action;
      const template = get(state, templateId, {});

      return {
        ...state,
        [templateId]: {
          ...template,
          elements: elementsUUIDs,
        },
      };
    }

    case UPDATE_TEMPLATE_ELEMENTS_SUCCESS: {
      const {
        payload: { result: elementsUUIDs },
        requestArgs,
      } = action;
      const [templateId, elements] = requestArgs;
      const template = get(state, templateId, {});
      const totalEstimatedTime =
        elements && accumulate(elements, 'estimatedTime');

      return {
        ...state,
        [templateId]: {
          ...template,
          elements: elementsUUIDs,
          totalEstimatedTime,
        },
      };
    }

    case ADD_TEMPLATE_ELEMENTS_SUCCESS: {
      const {
        payload: { result: elementsUUIDs },
        requestArgs,
      } = action;
      const [templateId, elements] = requestArgs;
      const template = get(state, templateId, {});

      const totalEstimatedTime =
        elements && accumulate([elements], 'estimatedTime');

      return {
        ...state,
        [templateId]: {
          ...template,
          elements: elementsUUIDs,
          totalEstimatedTime,
        },
      };
    }

    default:
      return state;
  }
};
