import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Multiline } from 'components/Multiline';
import styles from '../Input.module.scss';

interface TextBox {
  emptyLabel?: string;
  icon?: ReactNode | null;
  mode?: 'text' | 'textarea';
  value?: string;
  className?: string;
  label: string;
}

export const TextBox: FC<TextBox> = ({
  emptyLabel = 'N/A',
  icon = null,
  mode = 'text',
  value = '',
  className,
  children,
  ...textBoxProps
}) => {
  const inner = children || value || emptyLabel;

  return (
    <div
      className={cn(styles.textBox, {
        [styles.hasIcon]: !!icon,
        [styles.textBoxTextarea]: mode === 'textarea',
      })}
      {...textBoxProps}
    >
      <div className={styles.textBoxInner}>
        {mode === 'textarea' && typeof inner === 'string' ? (
          <Multiline>{inner}</Multiline>
        ) : (
          inner
        )}
        {icon && <span className={styles.icon}>{icon}</span>}
      </div>
    </div>
  );
};
