import { createActionType } from 'store/utils/create-action-type';

const a = (type) => createActionType('materials-groups', type);

export const UPDATE_OPERATION_MATERIALS_OF_GROUP_REQUEST = a(
  'UPDATE_OPERATION_MATERIALS_OF_GROUP_REQUEST'
);
export const UPDATE_OPERATION_MATERIALS_OF_GROUP_SUCCESS = a(
  'UPDATE_OPERATION_MATERIALS_OF_GROUP_SUCCESS'
);
export const UPDATE_OPERATION_MATERIALS_OF_GROUP_FAILURE = a(
  'UPDATE_OPERATION_MATERIALS_OF_GROUP_FAILURE'
);

export const ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_REQUEST = a(
  'ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_REQUEST'
);
export const ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_SUCCESS = a(
  'ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_SUCCESS'
);
export const ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_FAILURE = a(
  'ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_FAILURE'
);

export const ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_REQUEST = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_REQUEST'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_SUCCESS = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_SUCCESS'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_FAILURE = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_FAILURE'
);

export const ADD_TEMPLATE_MATERIALS_GROUP_IMPLANTS_REQUEST = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_IMPLANTS_REQUEST'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_IMPLANTS_SUCCESS = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_IMPLANTS_SUCCESS'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_IMPLANTS_FAILURE = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_IMPLANTS_FAILURE'
);

export const ADD_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_REQUEST = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_REQUEST'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_SUCCESS = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_SUCCESS'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_FAILURE = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_FAILURE'
);

export const ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_REQUEST = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_REQUEST'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_SUCCESS = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_SUCCESS'
);
export const ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_FAILURE = a(
  'ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_FAILURE'
);

export const DELETE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_REQUEST = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_REQUEST'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_SUCCESS = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_SUCCESS'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_FAILURE = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_FAILURE'
);

export const DELETE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_REQUEST = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_REQUEST'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_SUCCESS = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_SUCCESS'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_FAILURE = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_FAILURE'
);

export const DELETE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_REQUEST = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_REQUEST'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_SUCCESS = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_SUCCESS'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_FAILURE = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_FAILURE'
);

export const DECREASE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_REQUEST = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_REQUEST'
);
export const DECREASE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_SUCCESS = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_SUCCESS'
);
export const DECREASE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_FAILURE = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_FAILURE'
);

export const DECREASE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_REQUEST = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_REQUEST'
);
export const DECREASE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_SUCCESS = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_SUCCESS'
);
export const DECREASE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_FAILURE = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_IMPLANTS_FAILURE'
);

export const DECREASE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_REQUEST = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_REQUEST'
);
export const DECREASE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_SUCCESS = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_SUCCESS'
);
export const DECREASE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_FAILURE = a(
  'DECREASE_TEMPLATE_MATERIALS_GROUP_EQUIPMENT_FAILURE'
);

export const DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_REQUEST = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_REQUEST'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_SUCCESS = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_SUCCESS'
);
export const DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_FAILURE = a(
  'DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_FAILURE'
);
