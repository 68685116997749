import { normalizeResponse } from 'api/helpers/normalize-response';
import { materialsGroupSchema } from '../schemas';
import { RequestId } from 'common-types/api';
import { NormalizedResponse } from 'common-types/normalize';
import { Equipment, Implant, Instrument } from 'common-types/library';

export const normalizeMaterialsGroup = normalizeResponse(materialsGroupSchema);

export interface updateMaterialsOfGroupFn {
  (
    relationId: RequestId,
    groupId: RequestId,
    materialsOfGroup: object,
    lang?: string
  ): Promise<NormalizedResponse>;
}

export interface addMaterialsGroupElementsFn {
  (
    relationId: RequestId,
    groupId: RequestId,
    elements: number[] | MaterialGroupElements,
    language?: string
  ): Promise<NormalizedResponse>;
}

export interface deleteMaterialsGroupElementFn {
  (
    relationId: RequestId,
    groupId: RequestId,
    elementId: RequestId
  ): Promise<NormalizedResponse>;
}

interface MaterialGroupElements {
  instruments: Instrument[];
  implants: Implant[];
  equipment: Equipment[];
}
