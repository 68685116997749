import { normalize } from 'utils/normalize';

import {
  CREATE_NEW_LIBRARY_ITEM_REQUEST,
  CREATE_NEW_LIBRARY_ITEM_SUCCESS,
  CREATE_NEW_LIBRARY_ITEM_FAILURE,
  EDIT_LIBRARY_ELEMENT,
} from './types';
import { libraryElementsSchema } from 'api/v1/schemas';

export const editLibraryElement = (uuid, update) => ({
  type: EDIT_LIBRARY_ELEMENT,
  payload: {
    uuid,
    update,
  },
});

export const createNewLibraryItemRequest = (category, item) => ({
  type: CREATE_NEW_LIBRARY_ITEM_REQUEST,
  payload: { item },
  category,
});

export const createNewLibraryItemSuccess = (category, element) => ({
  type: CREATE_NEW_LIBRARY_ITEM_SUCCESS,
  payload: {
    ...normalize(element, libraryElementsSchema),
  },
  category,
});

export const createNewLibraryItemFailure = (category, errorMessage, error) => ({
  type: CREATE_NEW_LIBRARY_ITEM_FAILURE,
  payload: { error, errorMessage },
  category,
});
