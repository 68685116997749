import { APIv1 } from 'api/instance';
import { makeDeleteRequest } from './make-request';

export const readResearchArticlesReq = ({ from, to }, query = '') => {
  const params = {
    range: JSON.stringify([from, to]),
    query: query.trim(),
  };

  return APIv1.get('/research', { params });
};

export const readResearchFavArticlesReq = () =>
  APIv1.get('/research/favorites');

export const readFavoritesArticlesIdsReq = () =>
  APIv1.get('research/favorites/ids');

export const postAddArticleToFavoritesReq = articleId =>
  APIv1.post('/research/favorites', { articleId });

export const deleteArticleFromFavoritesReq = makeDeleteRequest(
  '/research/favorites'
);
