import React, { FC, useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './Vital.module.scss';

import { Icon } from 'components/Icon';
import { onEnter } from 'utils/key-events';
import { ErrorMessages } from 'components/ErrorMessages';
import { List, ListHeader, ListItem } from 'components/List';
import { EditableString } from 'components/EditableString';
import { Textarea } from 'components/Form/Textarea';
import { Multiline } from 'components/Multiline';

export interface VitalProps {
  /**
   * Editable title value
   */
  title: string;
  /**
   * Editable description value
   */
  description?: string;
  /**
   * Placeholder string
   */
  placeholder?: string;
  /**
   * Error string
   */
  error?: string;
  /**
   * Html classname attribut
   */
  className?: string;
  /**
   * Callback to be fired in title/description changed, or on description blur
   */
  onSave: (state: Pick<VitalProps, 'title' | 'description'>) => void;
}

/**
 * Renders field with editable title and description
 */
export const Vital: FC<VitalProps> = ({
  title,
  description,
  placeholder = 'Add any vital information for your operation here',
  error,
  className,
  onSave,
}) => {
  const [editable, setEditable] = useState(false);

  const enableEditMode = useCallback(() => {
    setEditable(true);
  }, []);

  const onTextareaKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (ev.key === 'Escape') {
        setEditable(false);
        return;
      }

      if (ev.key === 'Enter' && (ev.ctrlKey || ev.metaKey)) {
        onSave({ title, description: ev.currentTarget.value });
        setEditable(false);
        return;
      }
    },
    [onSave, title]
  );

  const onTextareaBlur = useCallback(
    (ev: React.FocusEvent<HTMLTextAreaElement>) => {
      onSave({ title, description: ev.currentTarget.value });
      setEditable(false);
    },
    [onSave, title]
  );

  const onTitleChange = useCallback(
    (title: string) => {
      onSave({ title, description });
    },
    [description, onSave]
  );

  return (
    <>
      <ErrorMessages errors={error} />

      <List className={className}>
        <ListHeader
          className={styles.header}
          contentClassName={styles.headerInner}
        >
          <Icon name="vital-information" className={styles.icon} />

          <strong>
            <EditableString
              onEdit={onTitleChange}
              inputProps={{ value: title }}
            >
              {title}
            </EditableString>
          </strong>
        </ListHeader>

        {editable ? (
          <Textarea
            autoFocus
            name="vital-info"
            defaultValue={description}
            placeholder={placeholder}
            className={styles.input}
            onBlur={onTextareaBlur}
            onKeyDown={onTextareaKeyDown}
          />
        ) : (
          <ListItem
            tabIndex={0}
            onKeyDown={onEnter(enableEditMode)}
            onClick={enableEditMode}
            className={cn(styles.body, !description && styles.empty)}
          >
            <Multiline string={description || placeholder} />
          </ListItem>
        )}
      </List>
    </>
  );
};
