import { RequestId } from 'common-types/api';
import { createSelector } from 'reselect';
import { createCollectionSelector } from 'store/utils';
import { selectAttachments } from './selectors';
import { selectTemplateById } from './templates';

export const selectAttachmentById = (attachmentId) =>
  createSelector(selectAttachments, (attachments: object) => {
    if (!attachments || !attachments[attachmentId]) return null;
    return attachments[attachmentId];
  });

export const selectTemplateAttachmentsById = (templateId: RequestId) => {
  const templateSelector = selectTemplateById(templateId);

  return createSelector(
    [templateSelector, selectAttachments],
    createCollectionSelector('attachments')
  );
};
