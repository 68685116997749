import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/js';
import withFileUpload_DEPRECATED from 'containers/withFileUpload';

import { Label } from 'components/Form/FormInput/Label';

import { BucketFile } from 'common-types/attachments';
import styles from './HospitalLogo.module.scss';
import { HTMLFile } from 'common-types/common';
import { Button } from 'components/Button';
import { UploadFilesButton } from 'components/UploadFilesButton';

interface Props {
  label: string;
  imageUrl: string;
  /**
   * Loader is file uploading
   */
  isFileUploading: boolean;
  /**
   * This is comming from HOC withFileUpload. It is responsible for handling file upload
   */
  onUpload: (files: HTMLFile[]) => Promise<BucketFile[]>;
  /**
   * This is passed by HOC but it not used
   */
  failedUpload: [];
  updateHospitalLogo: ({ imageUrl }: { imageUrl: string }) => void;
  /**
   * This is passed by HOC but it not used
   */
  uploadErrorMessage: string;
}

const HospitalLogoComponent = ({
  onUpload,
  updateHospitalLogo,
  label = '',
  imageUrl,
  isFileUploading,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const handleUploadImage = (event) => {
    const files: HTMLFile[] = [...event.currentTarget.files];
    onUpload([...files])
      .then((files: BucketFile[]) => {
        files[0].fileKey && updateHospitalLogo({ imageUrl: files[0].fileKey });
      })
      .catch((err) => Bugsnag.notify(err));
  };

  const handleDeleteLogo = () => updateHospitalLogo({ imageUrl: '' });
  const hasImage = Boolean(imageUrl);

  return (
    <section className={styles.uploadImageCnt}>
      <Label htmlFor="image-select">{label}</Label>
      {hasImage ? (
        <img className={styles.selectedImage} src={imageUrl} alt="uploadfile" />
      ) : (
        <div className={styles.imageStub} />
      )}
      <div className={styles.buttonsCnt}>
        <Button
          className={styles.deleteBtn}
          type="button"
          variant="secondary-danger"
          disabled={!imageUrl}
          onClick={handleDeleteLogo}
        >
          {t('delete')}
        </Button>

        <UploadFilesButton
          id="hospital-logo"
          className={styles.uploadBtn}
          onChange={handleUploadImage}
          loading={isFileUploading}
          maxFilesAllowed={1}
        >
          {hasImage ? t('change') : t('Upload')}
        </UploadFilesButton>
      </div>
    </section>
  );
};

export const HospitalLogo = withFileUpload_DEPRECATED(HospitalLogoComponent);
