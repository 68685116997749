import React, { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';

import { ListItemType } from 'common-types/common';
import { List, ListItemInput, ListItem, ListHeader } from 'components/List';
import { Text } from 'components/Typography';
import { Button } from 'components/Button';

interface EditableListProps {
  /**
   * List of items to render
   */
  items: ListItemType[];
  /**
   * Droppable id
   */
  id?: string;
  /**
   * List title
   */
  title?: string;
  /**
   * Callback to render list item
   */
  renderItem: (item: ListItemType) => ReactNode;
  /**
   * Callback to be fired on input value save
   */
  onCreate: (value: string) => void;
  /**
   * Callback to be fired on delete list item
   */
  onDelete: (id: string | number) => void;
  /**
   * Callback to be fired on drag event end
   */
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

/**
 * Renders list with create, delete and drag & drop list items functionality
 */
export const EditableList: FC<EditableListProps> = ({
  items,
  id = 'editable-list-drag-and-drop',
  title,
  renderItem,
  onCreate,
  onDelete,
  onDragEnd,
}) => {
  const { t } = useTranslation();

  const [editable, setEditable] = useState(false);

  const enableEditable = () => {
    setEditable(true);
  };

  const disableEditable = () => {
    setEditable(false);
  };

  const onCreateHandler = (value: string) => {
    if (value) onCreate?.(value);
    setEditable(false);
  };

  return (
    <>
      {(editable || items?.length > 0) && (
        <List>
          {title && (
            <ListHeader>
              <Text variant="heading-md" className="mb-0">
                {title}
              </Text>
            </ListHeader>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={id}>
              {(droppable) => (
                <div ref={droppable.innerRef} {...droppable.droppableProps}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(draggable, snapshot) => (
                        <ListItem
                          itemId={item.id}
                          innerRef={draggable.innerRef}
                          onDelete={onDelete}
                          isDragging={snapshot.isDragging}
                          {...draggable.draggableProps}
                          {...draggable.dragHandleProps}
                        >
                          {renderItem(item)}
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {droppable.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {editable && (
            <ListItemInput
              inputProps={{ id: 'editable-list-input' }}
              onSave={onCreateHandler}
              onCancel={disableEditable}
            />
          )}
        </List>
      )}

      {!editable && (
        <Button type="button" variant="secondary" onClick={enableEditable}>
          {t('addNew')}
        </Button>
      )}
    </>
  );
};
