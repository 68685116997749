import { PureComponent } from 'react';
import { string, func } from 'prop-types';
import withT from 'containers/withT';

import computeLoaderState from './compute-loader-state';

export class SmartLoaderLabel extends PureComponent {
  static _propTypes = {
    requestState: string,
    requestType: string,
    t: func,
  };

  render() {
    const { t, requestState, requestType } = this.props;
    const loaderState = computeLoaderState(requestState, requestType);

    switch (loaderState) {
      case 'save':
        return t('Saving');
      case 'update':
        return t('Updating');
      case 'delete':
        return t('Deleting');
      case 'submit':
        return t('Submitting');
      case 'save-completed':
        return t('savedSuccessfully');
      case 'update-completed':
        return t('updatedSuccessfully');
      case 'delete-completed':
        return t('deletedSuccessfully');
      case 'submit-completed':
        return t('submittedSuccessfully');
      case 'upload':
        return t('uploadingFile');
      case 'upload-completed':
        return t('uploadedSuccessfully');
      case 'search':
      case 'search-completed':
        return null;
      default:
        return t('Loading');
    }
  }
}

export default withT(SmartLoaderLabel);
