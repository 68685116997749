import invariant from 'invariant';
import { nanoid } from 'nanoid';
import { isObject, get } from 'lodash';
import { uuid } from 'common-types/api';
import { LibraryElement } from 'common-types/library';
import { LibraryCategories } from 'common-types/library';

interface withoutRelationIdFn {
  (uuid: uuid): uuid;
}

export const withoutRelationId: withoutRelationIdFn = (uuid) => {
  const uuidMatch = uuid.match(/^(\d+)-(\w+)(-\w+)?/);
  if (!uuidMatch) {
    return uuid;
  }
  const [, id, category] = uuidMatch;
  return `${id}-${category}`;
};

interface setTempRelationIdFn {
  (element: LibraryElement): LibraryElement & { _tempRelationId: string };
}

export const setTempRelationId: setTempRelationIdFn = (element) => ({
  ...element,
  _tempRelationId: nanoid(),
});

export const getTempRelationId = (element) => get(element, '_tempRelationId');

export const makeLibraryElementUUID = (
  element: LibraryElement,
  tempRelationId?: uuid
): string => {
  invariant(
    isObject(element) && !Array.isArray(element),
    'Invalid `element` argument provided, expected object with `id` field.'
  );
  const { id, category, relationId } = element;
  const relId = tempRelationId || relationId || getTempRelationId(element);
  return [id, category, relId].filter(Boolean).join('-');
};

export const setLibraryElementUUID = (element) => ({
  ...element,
  uuid: makeLibraryElementUUID(element),
});

export const mapLibraryElementsUUID = (elements) =>
  elements.map((element) => setLibraryElementUUID(element));

interface parseElementUUIDFn {
  (uuid: uuid): { id: string; category: LibraryCategories; relationId: string };
}

export const parseElementUUID: parseElementUUIDFn = (uuid) => {
  const match = uuid.match(/(\d+)-(\w+)-?(\d+)?/);
  invariant(match, 'Invalid uuid string passed');
  const [, id, category, relationId] = match;
  return { id, category: category as LibraryCategories, relationId };
};

export const getElementsUUIDs = (
  elements: Partial<LibraryElement>[]
): uuid[] => {
  invariant(elements, 'elements is required');
  return elements.map((element) => {
    invariant(element.uuid, 'element.uuid is not present in element object');
    return withoutRelationId(element.uuid);
  });
};
