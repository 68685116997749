import { RequestId } from 'common-types/api';
import { Equipment, Implant, Instrument } from 'common-types/library';
import { MaterialsGroup } from 'common-types/materials';
import {
  getAdditionalEquipmentGroup,
  getGeneralInstrumentsGroup,
  getStaticGroup,
  isPlainMaterialList,
  isStaticGroup,
} from 'components';
import { useEffect } from 'react';

import { useOperationMaterialsApi } from './useOperationMaterialsApi';
import { useOperationMaterialsGroupsApi } from './useOperationMaterialsGroupsApi';

export const useOperationMaterials = (operationId) => {
  const {
    errors,
    fetchMaterials,
    loading: materialsLoading,
    materials,
    addElement: addMaterialsElement,
    removeElement: removeMaterialsElement,
    updateElement: updateMaterialsElement,
    reorderElements: reorderMaterialsElements,
  } = useOperationMaterialsApi(operationId);
  const {
    groups,
    loading: groupsLoading,
    addElement: addGroupElement,
    removeElement: removeGroupElement,
    updateElement: updateGroupElement,
    reorderElements: reorderGroupElements,
  } = useOperationMaterialsGroupsApi(operationId);

  const isLoading = materialsLoading || groupsLoading;

  useEffect(() => {
    fetchMaterials();
    // changes of fetchMaterials should not trigger an update
  }, []);

  let instruments: Instrument[] = [];
  let equipment: Equipment[] = [];
  let implants: Implant[] = [];
  let materialsGroups: MaterialsGroup[] = [];
  let generalInstrumentsGroup: MaterialsGroup | undefined;
  let additionalEquipmentGroup: MaterialsGroup | undefined;

  if (materials) {
    equipment = materials.equipment;
    implants = materials.implants;
    instruments = materials.instruments;
    materialsGroups = groups || [];
    generalInstrumentsGroup = getGeneralInstrumentsGroup(materialsGroups);
    additionalEquipmentGroup = getAdditionalEquipmentGroup(materialsGroups);
  }

  const addElement = async (groupId, element) => {
    if (groupsLoading || !groups) return;

    if (isPlainMaterialList(groupId)) {
      if (materialsLoading) return;
      return addMaterialsElement(groupId, element);
    }

    const actualGroupId: RequestId = isStaticGroup(groupId)
      ? getStaticGroup(groups, groupId)?.id
      : groupId;

    return addGroupElement(actualGroupId, element);
  };

  const removeElement = (groupId, elementId) => {
    if (isPlainMaterialList(groupId)) {
      if (materialsLoading) return;
      return removeMaterialsElement(groupId, elementId);
    } else {
      if (groupsLoading || !groups) return;

      if (isStaticGroup(groupId)) {
        let group = getStaticGroup(groups, groupId);
        if (!group) return;

        return removeGroupElement((group as MaterialsGroup).id, elementId);
      } else {
        return removeGroupElement(groupId, elementId);
      }
    }
  };

  const updateElement = (groupId, uuid, update) => {
    if (isPlainMaterialList(groupId)) {
      if (materialsLoading) return;
      return updateMaterialsElement(groupId, uuid, update);
    }

    if (groupsLoading || !groups) return;
    let actualGroupId: RequestId = isStaticGroup(groupId)
      ? getStaticGroup(groups, groupId)?.id
      : groupId;
    if (!actualGroupId) return;
    return updateGroupElement(actualGroupId, uuid, update);
  };

  const reorderElements = (groupId, fromIndex, toIndex) => {
    if (isPlainMaterialList(groupId)) {
      reorderMaterialsElements(groupId, fromIndex, toIndex);
    } else {
      let actualGroupId: RequestId = groupId;
      if (isStaticGroup(groupId)) {
        let group = getStaticGroup(groups, groupId);
        if (!group) return;
        actualGroupId = group?.id;
      }
      reorderGroupElements(actualGroupId, fromIndex, toIndex);
    }
  };

  return {
    instruments,
    equipment,
    implants,
    materialsGroups,

    generalInstrumentsGroup,
    additionalEquipmentGroup,

    errors,
    loading: isLoading,

    fetchMaterials,
    addElement,
    removeElement,
    updateElement,
    reorderElements,
  };
};
