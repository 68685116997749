import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Fetcher from './Fetcher';
import { ComponentType } from 'react';

export const createFetcherMaker = (FetcherComponent: ComponentType<any>) => (
  entitySelector,
  fetchThunk
) => {
  const mapStateToProps = createStructuredSelector({
    data: entitySelector,
  });
  const mapDispatchToProps = {
    fetchData: fetchThunk,
  };
  const withConnect = connect<{}, {}, any>(mapStateToProps, mapDispatchToProps);

  return withConnect(FetcherComponent);
};

export const makeFetcher = createFetcherMaker(Fetcher);
