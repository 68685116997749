import {
  DELETE_TEMPLATE_ATTACHMENTS_FAILURE,
  DELETE_TEMPLATE_ATTACHMENTS_REQUEST,
  DELETE_TEMPLATE_ATTACHMENTS_SUCCESS,
} from 'store/modules/templates-library/types';
import {
  createFailureActionCreator,
  createSuccessActionCreator,
} from 'store/utils';

export const deleteTemplateAttachmentRequest = (templateId, attachmentId) => ({
  type: DELETE_TEMPLATE_ATTACHMENTS_REQUEST,
  payload: {
    templateId,
    attachmentId,
  },
});

export const deleteTemplateAttachmentsSuccess = createSuccessActionCreator(
  DELETE_TEMPLATE_ATTACHMENTS_SUCCESS
);

export const deleteTemplateAttachmentsFailure = createFailureActionCreator(
  DELETE_TEMPLATE_ATTACHMENTS_FAILURE
);
