import { attachmentsListSchema } from 'api/v1/schemas';
import { Canceler } from 'axios';
import {
  createActionCreator,
  createFailureActionCreator,
  createSuccessActionCreator_DEPRECATED,
} from 'store/utils';
import {
  CANCEL_FILE_UPLOAD,
  UPLOAD_FILE_SUCCESS,
  RESET_FILES_UPLOAD_PROGRESS,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_REQUEST,
  UPLOAD_ATTACHMENTS_FAILURE,
  UPLOAD_ATTACHMENTS_REQUEST,
  UPLOAD_ATTACHMENTS_SUCCESS,
  UPLOAD_FILE_FAILURE,
} from './types';

export const cancelFileUpload = createActionCreator<{ id: string }>(
  CANCEL_FILE_UPLOAD
);

export const uploadFileProgress = createActionCreator<{
  id: string;
  progress: number;
  cancel?: Canceler;
}>(UPLOAD_FILE_PROGRESS);

export const resetFilesUploadProgress = createActionCreator(
  RESET_FILES_UPLOAD_PROGRESS
);

interface FilePayload {
  id: string;
  name: string;
}

interface FilePayloadWithFile extends FilePayload {
  file: File;
}

export const uploadFileRequest = createActionCreator<FilePayloadWithFile>(
  UPLOAD_FILE_REQUEST
);

export const uploadFileSuccess = createActionCreator<FilePayload>(
  UPLOAD_FILE_SUCCESS
);

export const uploadFileFailure = (
  filePayload: FilePayload,
  errorMessage: string
) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: {
    ...filePayload,
    errorMessage,
  },
});

export const uploadAttachmentsRequest = createActionCreator(
  UPLOAD_ATTACHMENTS_REQUEST
);

export const uploadAttachmentsSuccess = createSuccessActionCreator_DEPRECATED(
  UPLOAD_ATTACHMENTS_SUCCESS,
  'attachments',
  attachmentsListSchema
);

export const uploadAttachmentsFailure = createFailureActionCreator(
  UPLOAD_ATTACHMENTS_FAILURE
);
