import { Action } from 'store/utils';
import * as types from './types';

export const initialState = {
  entities: {},
  result: [],
  title: null,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case types.READ_ASSESSMENT_FORMS_SUCCESS: {
      const { result, entities } = action.payload;
      return {
        ...state,
        entities: {
          ...entities.assessmentForms,
        },
        result,
      };
    }

    case types.ADD_FORM_TITLE: {
      const title = action.payload;

      return {
        ...state,
        title: title,
      };
    }

    default:
      return state;
  }
};
