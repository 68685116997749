/* eslint-disable @typescript-eslint/no-unused-vars */
import Bugsnag from '@bugsnag/js';
import invariant from 'invariant';
import { AnyAction, Dispatch } from 'redux';
import { i18n } from 'translations/i18n-instance';
import {
  AsyncActionCreators,
  createAsyncActionCreators,
} from './create-action-creators';
import { AsyncActionTypes, makeAsyncActionTypes } from './create-action-type';

export type RequestFunction<P extends any[] = any[]> = (
  ...requestParameters: P
) => Promise<any>;

export type ErrorMessageMakerFn = (...params: any[]) => string;
export type ErrorMessageMaker = ErrorMessageMakerFn | string;

type MakeThunk = <T = any, P extends any[] = any[]>(
  requestFn: RequestFunction<P>,
  actionCreators: AsyncActionCreators,
  makeErrorMessage?: ErrorMessageMaker,
  options?: {
    toCatchServerError?: boolean;
    fromTypes?: boolean;
  }
) => (...requestArgs: P) => (dispatch: Dispatch<AnyAction>) => Promise<T>;

const makeThunk: MakeThunk = (
  requestFn,
  actionCreators,
  makeErrorMessage = i18n.t('failedToMakeRequest'),
  options = { toCatchServerError: false, fromTypes: false }
) => {
  invariant(
    typeof options === 'object' && options !== null,
    'options parameter is invalid'
  );

  const { request, success, failure } = actionCreators;
  const { toCatchServerError, fromTypes } = options;
  return (...requestArgs) =>
    async function regularThunk(dispatch: Dispatch): Promise<any> {
      if (fromTypes) {
        dispatch(request({ requestArgs }));
      } else {
        dispatch(request(...requestArgs));
      }
      try {
        const response = await requestFn(...requestArgs);
        dispatch(success(response, ...requestArgs));
        return response;
      } catch (error) {
        Bugsnag.notify(error);

        let errorMessage;
        if (error?.message && typeof error.message === 'string') {
          errorMessage =
            error.message === '{}' ? 'Failed to make a request' : error.message;
        } else if (typeof makeErrorMessage === 'function') {
          errorMessage = makeErrorMessage(...requestArgs);
        } else if (typeof makeErrorMessage === 'string') {
          errorMessage = makeErrorMessage;
        }

        dispatch(failure(errorMessage, error));
        if (!toCatchServerError) {
          throw new Error(errorMessage);
        }

        return null;
      }
    };
};

export const makeThunkFromTypes = <T = any, P extends any[] = any[]>(
  requestFn: RequestFunction<P>,
  actionTypes: AsyncActionTypes,
  makeErrorMessage?,
  options = { toCatchServerError: false }
) => {
  const actionCreators = createAsyncActionCreators(actionTypes);

  return makeThunk<T, P>(requestFn, actionCreators, makeErrorMessage, {
    ...options,
    fromTypes: true,
  });
};

export const makeThunkFromTypeString = <T = any, P extends any[] = any[]>(
  requestFn: RequestFunction<P>,
  syncActionType: string
) => {
  const asyncActionTypes = makeAsyncActionTypes(syncActionType);
  const thunk = makeThunkFromTypes<T, P>(requestFn, asyncActionTypes);

  return {
    asyncActionTypes,
    thunk,
  };
};

export default makeThunk;
