/* eslint-disable no-underscore-dangle */

class Timer {
  constructor(name) {
    this._name = name;
    this.startedAt = null;
    this.finishedAt = null;
  }

  get duration() {
    if (!this.startedAt || !this.finishedAt) return null;
    return this.finishedAt - this.startedAt;
  }

  get name() {
    return this._name;
  }

  start() {
    this.reset();
    this.startedAt = Date.now();
  }

  stop() {
    this.finishedAt = Date.now();
    return this.duration;
  }

  reset() {
    this.startedAt = null;
    this.finishedAt = null;
  }
}

export default name => new Timer(name);
