import deepMap from 'deep-map';
import cleanDeep from 'clean-deep';
import { compose } from 'redux';

import { omitByDeep, omitGeneratedFields } from 'utils';
import { SUPPORTED_LANGUAGES } from 'app-constants';
import transformS3Urls from 'utils/transform-s3-urls';

export const cleanEmptyValues = requestBody =>
  cleanDeep(requestBody, { emptyArrays: false });

const omitEmptyTranslationValues = requestBody =>
  omitByDeep(
    requestBody,
    (value, key) => SUPPORTED_LANGUAGES.includes(key) && !value
  );

const transformEmptyTranslationValuesToNull = requestBody =>
  deepMap(requestBody, (value, key) => {
    const isEmptyTranslation = SUPPORTED_LANGUAGES.includes(key) && !value;
    return isEmptyTranslation ? null : value;
  });

export const cleanCreateRequestBody = compose(
  cleanEmptyValues,
  omitGeneratedFields,
  transformS3Urls
);

export const cleanUpdateRequestBody = compose(
  omitEmptyTranslationValues,
  omitGeneratedFields,
  transformS3Urls
);

export const cleanPatchRequestBody = compose(
  transformEmptyTranslationValuesToNull,
  omitGeneratedFields,
  transformS3Urls
);

export default {
  post: cleanCreateRequestBody,
  put: cleanUpdateRequestBody,
  patch: cleanPatchRequestBody,
};
