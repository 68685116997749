import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import i18n from 'translations/i18n-instance';
import { translatableInputName, translateValue } from 'utils';

export default (InputBase) => {
  class WrappedTranslatableInput extends Component {
    static defaultProps = {
      defaultValue: '',
      value: '',
    };

    state = {
      inputLanguage: i18n.language,
    };

    getValue(value) {
      const { inputLanguage } = this.state;
      const translatedValue = translateValue(value, inputLanguage, false);
      return translatedValue;
    }

    render() {
      const {
        name,
        defaultValue,
        value,
        allowLanguageSwitch,
        ...inputProps
      } = this.props;
      const inputName = translatableInputName(name, this.state.inputLanguage);

      return (
        <InputBase
          key={inputName}
          name={inputName}
          defaultValue={this.getValue(defaultValue)}
          value={this.getValue(value)}
          {...inputProps}
        />
      );
    }
  }

  hoistStatics(WrappedTranslatableInput, InputBase);

  return WrappedTranslatableInput;
};
