import { APIv1 } from 'api/instance';

export const readOperationDebriefing = operationId =>
  APIv1.get(`/operations/${operationId}/debriefing`);

export const updateIncident = (operationId, incidentId, update) =>
  APIv1.put(`/operations/${operationId}/incident/${incidentId}`, update);

export const readHistoryStatisticReq = () => APIv1.get('/user/me/stats');

export const readHistoryStatisticPeriodReq = (userId, period = 'week') =>
  APIv1.get(`users/${userId}/stats/${period}`);
