import { constant } from 'case';
import { ActionType } from './create-action-creators';

export interface AsyncActionTypes {
  request: ActionType;
  success: ActionType;
  failure: ActionType;
}

export const createActionType = (namespace: string, action: string): string =>
  `[ ${namespace} ]: ${constant(action)}`;

export const makeAsyncActionTypes = (
  syncActionType: string
): AsyncActionTypes => {
  const properCaseActionType = constant(syncActionType);
  return {
    request: `${properCaseActionType}_REQUEST`,
    success: `${properCaseActionType}_SUCCESS`,
    failure: `${properCaseActionType}_FAILURE`,
  };
};
