import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { SortDirections } from '../../app-constants';
import { ENTER, SPACE } from 'app-constants';
import cn from 'classnames';
import styles from './Table.module.scss';

export interface HeaderColProps {
  accessor?: string;
  title?: ReactNode;
  isSortable?: boolean;
  isActive?: boolean;
  orderDirection?: SortDirections;
  onSort?(accessor?: string, direction?: SortDirections): void;
  className?: string;
}

const toggleSortingDirections = (item: SortDirections) =>
  item === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC;

export const HeaderCol: FC<HeaderColProps & HTMLAttributes<HTMLElement>> = ({
  accessor = '',
  title,
  isActive = false,
  isSortable = false,
  orderDirection = SortDirections.ASC,
  onSort,
  className,
  ...restProps
}) => {
  const handleSort = () => {
    const direction = toggleSortingDirections(orderDirection);

    onSort?.(accessor, direction);
  };

  const handleKeyDown = (event) => {
    if ([ENTER, SPACE].includes(event.keyCode)) {
      handleSort();
    }
  };

  const sortableProps = {
    tabIndex: 0,
    role: 'columnheader',
    onClick: isSortable ? handleSort : () => null,
    onKeyDown: isSortable ? handleKeyDown : () => null,
  };

  return (
    <div
      className={cn(styles.columnHeader, className, {
        [styles.columnHeaderSortable]: isSortable,
        [styles.sortActive]: isSortable && isActive,
        [styles[`sort-${orderDirection}`]]: isSortable && isActive,
      })}
      {...(isSortable ? sortableProps : null)}
      {...restProps}
    >
      {title}
      {isSortable && <span className={styles.sortDirection} />}
    </div>
  );
};
