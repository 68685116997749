import { schema } from 'normalizr';

import { departmentSchema, instituteSchema } from './institutes';
import { templateCategoriesSchema } from './templates';

export const userSchema = new schema.Entity(
  'users',
  {
    defaultCategory: templateCategoriesSchema,
    department: departmentSchema,
    institute: instituteSchema,
  },
  {
    processStrategy(user) {
      if (!user.defaultAssignees?.length) return user;

      user.defaultAssignees = user.defaultAssignees.map((d) => {
        d.defaultAssigneeRole = d.role;
        return d;
      });

      return user;
    },
  }
);
instituteSchema.define({ defaultAssignees: [userSchema] });
userSchema.define({ defaultAssignees: [userSchema] });

export const usersListSchema = new schema.Array(userSchema);

export const authorsSchema = new schema.Entity('authors');
