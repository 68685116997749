import { TABLE_DEFAULT_PAGE_SIZE } from 'app-constants';
import { compose } from 'redux';
import { Range } from '../common-types/common';

type calculatePaginationType = (page: number, displayPerPage?: number) => Range;
export const calculatePagination: calculatePaginationType = (
  page = 1,
  displayPerPage = TABLE_DEFAULT_PAGE_SIZE
) => {
  const currentPage = page - 1;

  if (currentPage <= 0) {
    return {
      from: 0,
      to: displayPerPage - 1,
    };
  }

  return {
    from: displayPerPage * currentPage,
    /* prettier-ignore */
    to: (displayPerPage * currentPage) + (displayPerPage - 1),
  };
};

type calculatePagesType = (total: number, displayPerPage?: number) => number;

export const calculatePages: calculatePagesType = (
  total,
  displayPerPage = TABLE_DEFAULT_PAGE_SIZE
) => Math.ceil(total / displayPerPage);

export const toRangeString: (range: Range) => string = ({ from = 0, to }) =>
  `[${from},${to}]`;

export const toRangeParam = ({ from, to }: Range): [number, number] => [
  from,
  to,
];

type getPaginationRangeParamType = (
  page: number,
  displayPerPage?: number
) => [number, number];

export const getPaginationRangeParam: getPaginationRangeParamType = compose(
  toRangeParam,
  calculatePagination
);
