import invariant from 'invariant';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import {
  selectCurrentPageOperation,
  selectOperationById,
  selectOperationElements,
} from 'store/modules/entities/selectors/operations';
import { selectAttachments } from 'store/modules/entities/selectors/selectors';
import { createEntitiesListSelector, createStateGetter } from 'store/utils';

export const selectOperationAttachments = createSelector(
  [selectCurrentPageOperation, selectAttachments],
  (operation, attachments) => {
    const attachmentsIds = get(operation, 'attachments', null);
    return attachmentsIds && attachmentsIds.map((id) => attachments[id]);
  }
);

export const selectAttachmentsOfOperationById = (operationId) => {
  const selectAttachmentsIds = createSelector(
    selectOperationById(operationId),
    createStateGetter('attachments')
  );

  return createSelector(
    [selectAttachments, selectAttachmentsIds],
    createEntitiesListSelector()
  );
};

export const createElementsAttachmentsIdsSelector = (selectElementsList) =>
  createSelector(
    [selectElementsList, selectAttachments],
    (steps, attachments) => {
      if (!steps || !attachments) return null;

      return steps?.length
        ? steps.reduce((attachments, element) => {
            if (!Array.isArray(element?.attachments)) return attachments;
            return attachments.concat(element.attachments);
          }, [])
        : null;
    }
  );

export const createElementsAttachmentsSelector = (
  selectElementsList,
  defaultValue = []
) => {
  const selector = createEntitiesListSelector(defaultValue);
  const selectTemplateElementsAttachmentsIds = createElementsAttachmentsIdsSelector(
    selectElementsList
  );

  return createSelector(
    [selectAttachments, selectTemplateElementsAttachmentsIds],
    (attachments, ids) => {
      const uniqIds = ids ? uniq(ids) : ids;
      return selector(attachments, uniqIds);
    }
  );
};

const accumulateBy = (collection, property) => {
  invariant(
    Array.isArray(collection),
    'Expected `collection` argument to be an array'
  );
  return collection.reduce((acc, item) => acc.concat(item[property] ?? []), []);
};

export const createOperationElementsAttachmentsIdsSelector = (operationId) => {
  const elementsSelector = (state) =>
    selectOperationElements(state, { operationId });

  return createSelector([elementsSelector], (elements) => {
    if (!elements) return null;
    const elementsAttachmentsIds = accumulateBy(elements, 'attachments');
    return elementsAttachmentsIds;
  });
};

export const createOperationElementsAttachmentsSelector = (operationId) => {
  const selectAttachmentsIds = createOperationElementsAttachmentsIdsSelector(
    operationId
  );

  return (
    createSelector([selectAttachments, selectAttachmentsIds]),
    createEntitiesListSelector(null)
  );
};

export const selectOperationElementsAttachmentsIds = (
  state,
  { operationId, id }
) => createOperationElementsAttachmentsIdsSelector(operationId || id)(state);

export const selectOperationElementsAttachments = createSelector(
  [selectAttachments, selectOperationElementsAttachmentsIds],
  createEntitiesListSelector()
);

export const selectUniqueOperationElementsAttachments = createSelector(
  selectOperationElementsAttachments,
  (elements) => uniqBy(elements, 'id')
);
