import React, { FC } from 'react';
import { FileUploadProgress } from 'common-types/attachments';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Text } from 'components/Typography/Text';
import { useTranslation } from 'react-i18next';
import { AttachmentUpload } from './AttachmentUpload';
import classes from './FilesUploadProgressModal.module.scss';

export interface FilesUploadProgressModalProps {
  isOpen: boolean;
  isUploading: boolean;
  uploads: FileUploadProgress[];

  onCancelAll: () => void;
  onCancel: ({ id }: { id: string }) => void;
  onClose: () => void;
  onRetry?: ({ id }: { id: string }) => void;
}

export const FilesUploadProgressModal: FC<FilesUploadProgressModalProps> = ({
  isOpen,
  isUploading,
  onClose,
  onRetry,
  onCancel,
  onCancelAll,
  uploads,
}) => {
  const { t } = useTranslation();

  return (
    <Modal opened={isOpen && !!uploads?.length}>
      <Text variant="title">{t('uploadProgress')}</Text>
      <div className="p-1">
        {uploads?.map(({ id, name, progress, error }) => {
          return (
            <AttachmentUpload
              id={id}
              key={id}
              name={name}
              progress={progress}
              error={error}
              onRetry={onRetry}
              onCancel={onCancel}
            />
          );
        }) ?? null}
      </div>
      <div className={classes.buttonsContainer}>
        {isUploading ? (
          <Button type="button" variant="secondary" onClick={onCancelAll}>
            {t('cancel')}
          </Button>
        ) : (
          <Button onClick={onClose}>{t('Ok')}</Button>
        )}
      </div>
    </Modal>
  );
};

FilesUploadProgressModal.displayName = 'FilesUploadProgressModal';
