import { FabButton } from 'components/Button';
import { PopperDialog } from 'components/PopperDialog';
import React, { FC, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

interface ElementsSearchDialogProps {
  index: number;
  onClose?(index: number): void;
  onOpen?(index: number): void;
  open: boolean;
}

/**
 * Popper modifiers
 */
const modifiers = [
  {
    name: 'offset',
    options: { offset: [-15, 15] },
  },
];

export const ElementsSearchDialog: FC<ElementsSearchDialogProps> = ({
  children,
  index,
  open = false,
  onClose,
  onOpen,
}) => {
  const { t } = useTranslation();

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);

  const onCloseHandler = useCallback(() => {
    onClose?.(index);
  }, [index, onClose]);

  const onOpenHandler = useCallback(() => {
    onOpen?.(index);
  }, [index, onOpen]);

  return (
    <>
      <FabButton
        id={`add-step-button-${index}`}
        ref={setReferenceElement}
        onClick={onOpenHandler}
      />
      <PopperDialog
        reference={referenceElement}
        opened={open}
        onClose={onCloseHandler}
        title={t('addProcedureCheckOrRisk')}
        modifiers={modifiers}
      >
        {children}
      </PopperDialog>
    </>
  );
};
