import React, { ReactElement, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import { selectActiveOperationTimeouts } from 'store/modules/operation-planning/selectors/questions';
import { selectOperationTemplateId } from 'store/modules/operation-planning';
import { readTemplateTimeoutQuestions } from 'store/modules/templates-library';
import { useTranslation } from 'react-i18next';
import { OperationTimeoutQuestionType } from 'common-types/operation';
import { RouteComponentProps } from 'react-router';
import { QuestionsList } from './QuestionsList';

interface Props extends RouteComponentProps {
  questions: OperationTimeoutQuestionType[];
  templateId: number;
}

const TimeoutQuestions = ({ questions, templateId }: Props): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchQuestions = () => {
    if (templateId) {
      dispatch(readTemplateTimeoutQuestions(templateId));
    }
  };
  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (templateId) {
      fetchQuestions();
    }
  }, [templateId]);

  return <QuestionsList title={t('timeOutQuestions')} questions={questions} />;
};

const mapStateToProps = createStructuredSelector<Props, {}>({
  questions: selectActiveOperationTimeouts,
  templateId: selectOperationTemplateId,
});
const withConnect = connect(mapStateToProps);

const enhancer = compose(withConnect);

export default enhancer(TimeoutQuestions);
