import {
  CREATE_OPERATION_NOTE_FAILURE,
  CREATE_OPERATION_NOTE_REQUEST,
  CREATE_OPERATION_NOTE_SUCCESS,
  DELETE_OPERATION_NOTE_FAILURE,
  DELETE_OPERATION_NOTE_REQUEST,
  DELETE_OPERATION_NOTE_SUCCESS,
  READ_OPERATION_NOTE_FAILURE,
  READ_OPERATION_NOTE_REQUEST,
  READ_OPERATION_NOTE_SUCCESS,
  UPDATE_OPERATION_NOTE_FAILURE,
  UPDATE_OPERATION_NOTE_REQUEST,
  UPDATE_OPERATION_NOTE_SUCCESS,
} from 'store/modules/operation-planning/types';
import {
  createFailureActionCreator,
  createSuccessActionCreator_DEPRECATED,
} from 'store/utils/create-action-creators';

export const createNoteRequest = (operationId, note) => ({
  type: CREATE_OPERATION_NOTE_REQUEST,
  payload: {
    operationId,
    note,
  },
});

export const createNoteSuccess = createSuccessActionCreator_DEPRECATED(
  CREATE_OPERATION_NOTE_SUCCESS,
  'note'
);

export const createNoteFailure = createFailureActionCreator(
  CREATE_OPERATION_NOTE_FAILURE
);

export const updateNoteRequest = (operationId, note) => ({
  type: UPDATE_OPERATION_NOTE_REQUEST,
  payload: {
    operationId,
    note,
  },
});

export const updateNoteSuccess = createSuccessActionCreator_DEPRECATED(
  UPDATE_OPERATION_NOTE_SUCCESS,
  'note'
);

export const updateNoteFailure = createFailureActionCreator(
  UPDATE_OPERATION_NOTE_FAILURE
);

export const readNoteRequest = (operationId) => ({
  type: READ_OPERATION_NOTE_REQUEST,
  payload: {
    operationId,
  },
});

export const readNoteSuccess = createSuccessActionCreator_DEPRECATED(
  READ_OPERATION_NOTE_SUCCESS,
  'note'
);

export const readNoteFailure = createFailureActionCreator(
  READ_OPERATION_NOTE_FAILURE
);

export const deleteNoteRequest = (operationId) => ({
  type: DELETE_OPERATION_NOTE_REQUEST,
  payload: {
    operationId,
  },
});

export const deleteNoteSuccess = () => ({
  type: DELETE_OPERATION_NOTE_SUCCESS,
});

export const deleteNoteFailure = createFailureActionCreator(
  DELETE_OPERATION_NOTE_FAILURE
);
