import React, { FC, memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './RebrandingAlert.module.scss';

import { Text, Alert } from 'components';

export const REBRANDING_ALERT_SHOWN_KEY = 'rebrandingAlertShown';
export const REBRANDING_ALERT_SHOWN_VALUE = 'true';

/**
 * Renders alert with message about rebranding
 */
export const RebrandingAlert: FC = memo(function RebrandingAlert() {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    // Wait till ui is loaded
    const timeout = setTimeout(() => {
      const shownValue = localStorage.getItem(REBRANDING_ALERT_SHOWN_KEY);
      setOpened(REBRANDING_ALERT_SHOWN_VALUE !== shownValue);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onClose = () => {
    localStorage.setItem(
      REBRANDING_ALERT_SHOWN_KEY,
      REBRANDING_ALERT_SHOWN_VALUE
    );
    setOpened(false);
  };

  return (
    <Alert
      opened={opened}
      submitLabel={t('Ok')}
      onClose={onClose}
      onSubmit={onClose}
    >
      <Text variant="paragraph-md">
        <Trans
          i18nKey="rebrandingMessage"
          components={{
            ImpediaLink: (
              <a
                href="https://www.impedia.org"
                rel="noopener noreferrer"
                target="_blank"
                className={styles.link}
              >
                impedia.org
              </a>
            ),

            InfoImpediaLink: (
              <a href="mailto:info@impedia.org" className={styles.link}>
                info@impedia.org
              </a>
            ),

            br: <br />,
          }}
        />
      </Text>
    </Alert>
  );
});
