import React, { FC, ReactNode, useState } from 'react';
import { Provider } from './context';
import { ElementsList } from './ElementsList';
import { ElementsListEditable } from './ElementsListEditable';
import { accumulate } from 'utils';
import {
  OperationStep,
  OperationStepEstimable,
  OperationStepInput,
} from 'common-types/library';
import { StepAsLibraryItemModal } from './StepAsLibraryItemModal';
import { OperationItemEstimation } from 'components/OperationItem';
import { useTranslation } from 'react-i18next';
import { OperationFormsTypes } from 'app-constants';

interface ElementsFormProps {
  elements: OperationStepEstimable[];
  showModal?(key: string, props: any): void;
  readonly?: boolean;
  type: OperationFormsTypes;
  onChangeElement?(step: OperationStep, input: OperationStepInput): void;
  onDeleteElement?(element: OperationStep): void;
  onChangeElementsOrder?(fromIndex: number, toIndex: number): void;
  onTriggerGallery?(id: number): void;
  renderSearchInput?(index: number): ReactNode;
  onAttachmentRequest?(uuid: string): void;
}

export const ElementsForm: FC<ElementsFormProps> = ({
  elements,
  showModal,
  readonly = false,
  type,
  onChangeElement,
  onDeleteElement,
  onChangeElementsOrder,
  onTriggerGallery,
  renderSearchInput,
  onAttachmentRequest,
}) => {
  const { t } = useTranslation();

  const [activeSearchDialogIndex, setActiveSearchDialogIndex] = useState<
    number | null
  >(null);

  const getTotalEstimatedTime = () => {
    if (!elements || !elements.length) return 0;
    return accumulate(elements, 'estimatedTime');
  };

  const getTotalActualTime = () => {
    if (!elements || !elements.length) return 0;
    return accumulate(elements, 'actualTime');
  };

  const handleSearchOpen = (index: number) => {
    let activeSearchDialogIndex = 0;
    if (typeof index === 'number') {
      activeSearchDialogIndex = index;
    } else if (Array.isArray(elements) && elements.length) {
      activeSearchDialogIndex = elements.length;
    }

    setActiveSearchDialogIndex(activeSearchDialogIndex);
  };

  const handleSearchClose = () => {
    setActiveSearchDialogIndex(null);
  };

  const handleAddToLibrary = (element: OperationStepEstimable) => {
    showModal?.('saveStepAsNewLibraryItem', {
      dialogProps: { element, categories: [element.category] },
    });
  };

  const totalEstimation =
    elements && elements.length ? (
      <div className="mb-2">
        <OperationItemEstimation
          label={t('totalEstimatedDuration')}
          estimatedTime={getTotalEstimatedTime()}
          actualTime={getTotalActualTime()}
        />
      </div>
    ) : null;

  return (
    <Provider value={{ readonly, type }}>
      {totalEstimation}
      <div className="mb-2">
        {readonly ? (
          <ElementsList
            elements={elements}
            onTriggerGallery={onTriggerGallery}
          />
        ) : (
          <ElementsListEditable
            elements={elements}
            activeSearchDialogIndex={activeSearchDialogIndex}
            onChange={onChangeElement}
            onDelete={onDeleteElement}
            onChangeOrder={onChangeElementsOrder}
            onSearchClose={handleSearchClose}
            onSearchOpen={handleSearchOpen}
            onAddToLibrary={handleAddToLibrary}
            onTriggerGallery={onTriggerGallery}
            renderSearchInput={renderSearchInput}
            onAttachmentRequest={onAttachmentRequest}
          />
        )}
      </div>
      {totalEstimation}
      {!readonly && <StepAsLibraryItemModal />}
    </Provider>
  );
};
