import { Loader } from 'components/Loader/Loader';
import Bugsnag from '@bugsnag/js';
import React, { PureComponent } from 'react';
import { isId } from 'store/utils/types';

class Fetcher extends PureComponent {
  static defaultProps = {
    data: null,
    placeholder: <Loader mode="minimal" />,
    cache: 'redux',
  };

  state = {
    requestSucceed: false,
    error: null,
  };

  componentDidMount() {
    const { id, data, cache } = this.props;

    // Entity considered loaded only if its object has id present, otherwise
    // object is not complete/partial and needs to be fetched.
    const hasReduxCache = !!data?.id;
    if (cache === 'redux' && hasReduxCache) return;
    if (!isId(id)) return;

    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { requestSucceed, error } = this.state;
    if (requestSucceed || error) return;
    const { id, data } = this.props;
    const idHaveChanged = id && prevProps.id !== id;
    const dataIdIsDifferent =
      data && Number(data.id) && Number(data.id) !== Number(id);

    if ((!data && idHaveChanged) || dataIdIsDifferent) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const { id, fetchData } = this.props;
    if (!fetchData || typeof fetchData !== 'function') {
      const error = new Error(
        'Fetcher: `fetchData` prop is not a function. Data is not going to be fetched'
      );
      Bugsnag.notify(error);
      return;
    }

    fetchData(id)
      .then(() => {
        this.setState({ requestSucceed: Date.now(), error: null });
      })
      .catch((error) => {
        this.setState({ requestSucceed: false, error });
      });
  };

  render() {
    const { children, data, placeholder } = this.props;
    if (!data) return placeholder;
    if (!children || typeof children !== 'function') return null;
    return children(data);
  }
}

export default Fetcher;
