export const patchState = (state, id, update) => {
  const item = state[id] ?? {};
  return {
    ...state,
    [id]: {
      ...item,
      ...update,
    },
  };
};
