import {
  Operation,
  OperationInfoFormValues,
  OperationInput,
} from 'common-types/operation';
import { Template } from 'common-types/templates';
import invariant from 'invariant';
import pick from 'lodash/pick';
import { i18n } from 'translations/i18n-instance';

export const makeParticipant = (role, id: number = 0) => {
  invariant(role, 'role is mandatory');
  invariant(typeof id === 'number', 'id should be of type number');
  return { role, id };
};

const createFieldsPicker = (requiredFields) => (item) =>
  item && pick(item, requiredFields);

const TEMPLATE_ELEMENT_REQUIRED_FIELDS = [
  'attachments',
  'category',
  'content',
  'description',
  'estimatedTime',
  'id',
  'mitigationAction',
  'name',
  'relationId',
];
export const getCleanTemplateElement = createFieldsPicker(
  TEMPLATE_ELEMENT_REQUIRED_FIELDS
);

export const getClearedUpElementsList = (elements) =>
  elements.filter((element) => !element.isDeleted).map(getCleanTemplateElement);

export const prepareOperationInfoFormValues: (
  operation: Operation,
  operationTemplate?: Template
) => OperationInfoFormValues = (operation, operationTemplate) => {
  const { startsAt, title, participants: operationParticipants } = operation;
  const { leads, assistants, watchers, nurses } = operationParticipants;
  const [leadId] = leads;
  const participants = [
    { id: leadId, role: 'lead' },
    ...assistants.map((id) => ({ id, role: 'assistant' })),
    ...watchers.map((id) => ({ id, role: 'watcher' })),
    ...nurses.map((id) => ({ id, role: 'nurse' })),
  ];
  const values: OperationInfoFormValues = {
    startsAt,
    title,
    participants,
    description: operationTemplate?.description?.[i18n.language],
  };

  return values;
};

export const prepareOperationBody = (
  operation: Partial<OperationInfoFormValues>
): Partial<OperationInput> => {
  const { assessments, ...rest } = operation;
  const operationInput: Partial<OperationInput> = {
    ...rest,
  };

  if (assessments) {
    operationInput.assessments = Object.values(assessments);
  }

  return operationInput;
};

export const mockOperationValues = ({
  leadId,
  templateName = '',
}: {
  leadId?: number;
  templateName?: string;
}) => ({
  assistants: [],
  leadId,
  startsAt: '',
  title: templateName ?? '',
  watchers: [],
});
