import {
  templateCategoriesSchema,
  operationTemplateSchema,
} from 'api/v1/schemas';
import get from 'lodash/get';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {
  createCollectionSelector,
  createEntitySelector,
  createPropsGetter,
  createMatchParamsGetter,
  createStateGetter,
  createDenormalizr,
} from 'store/utils';
import getOneOf from 'utils/get-one-of';
import { selectCurrentPageOperation } from './operations';
import {
  selectEntities,
  selectLibraryElements,
  selectOperations,
  selectTemplateCategories,
  selectTemplates,
} from './selectors';

export const selectTemplate = createSelector(
  [selectCurrentPageOperation, selectTemplates],
  (operation, templates) => {
    if (!operation || !templates) return null;
    return templates[operation.operationTemplateId];
  }
);

export const selectTemplateById = (templateId) =>
  createSelector([selectTemplates], createStateGetter(templateId, null));

export const selectDenormalizedTemplateById = (templateId) => {
  const templateSelector = selectTemplateById(templateId);
  return createSelector(
    [templateSelector, selectEntities],
    createDenormalizr(operationTemplateSchema)
  );
};

export const selectTemplateElements = createSelector(
  [selectTemplate, selectLibraryElements],
  createCollectionSelector(['procedureElements', 'elements'])
);

export const selectTemplateCategoryByIdProp = createSelector(
  [selectTemplateCategories, createPropsGetter('id'), selectEntities],
  (categories, categoryId, entities) => {
    if (!categories) return null;

    return denormalize(
      categories[categoryId],
      templateCategoriesSchema,
      entities
    );
  }
);

export const selectTemplateByOperationIdPropOrParam = createSelector(
  [
    selectOperations,
    selectTemplates,
    createPropsGetter('operationId'),
    createMatchParamsGetter('operationId'),
    createPropsGetter('templateId'),
  ],
  (
    operations,
    templates,
    operationIdProp,
    operationIdMatch,
    templateIdProp
  ) => {
    const operationId = operationIdProp || operationIdMatch;
    if (!operations || !templates || (!operationId && !templateIdProp))
      return null;

    if (templateIdProp) {
      return get(templates, templateIdProp, null);
    }

    const operation = operations[operationId];
    const templateId = getOneOf(
      operation,
      ['templateId', 'operationTemplateId'],
      null
    );
    return get(templates, templateId, null);
  }
);

export const selectTemplateByIdProp = createEntitySelector(
  selectTemplates,
  createPropsGetter('id')
);
