import { normalizeResponse } from 'api/helpers/normalize-response';
import { APIv2 } from 'api/instance';
import { operationAttachmentsResponse, patientSchema } from 'api/v1/schemas';
import { generateLanguageURL, getLanguageParam } from 'utils/languages';
import {
  operationSchema,
  operationsListSchema,
  operationsVetListSchema,
  operationVetSchema,
} from './schemas';

export const readOperations = (fromDate, toDate, lang = null) =>
  APIv2.get(`/operations`, {
    params: { fromDate, toDate, lang: getLanguageParam(lang) },
  }).then(normalizeResponse(operationsListSchema));

export const readOperationsVet = (fromDate, toDate, lang = null) =>
  APIv2.get(`/operations-vet`, {
    params: { fromDate, toDate, lang: getLanguageParam(lang) },
  }).then(normalizeResponse(operationsVetListSchema));

export const createOperation = (data) =>
  APIv2.post(`/operations`, data, { ignoreRequestBodyClean: true });

export const readOperation = (operationId, lang = null) =>
  APIv2.get(generateLanguageURL(`/operations/${operationId}`, lang)).then(
    normalizeResponse(operationSchema)
  );

export const readOperationVet = (operationId, lang = null) =>
  APIv2.get(generateLanguageURL(`/operations-vet/${operationId}`, lang)).then(
    normalizeResponse(operationVetSchema)
  );

export const updateOperation = (id, data) =>
  APIv2.patch(`/operations/${id}`, data, { ignoreRequestBodyClean: true });

export const putOperation = (id, data) =>
  APIv2.put(`/operations/${id}`, data, { ignoreRequestBodyClean: true });

export const approveOperation = (id) => {
  return APIv2.patch(`/operations/${id}/debriefing/approval`);
};

export const deleteOperation = (id) => APIv2.delete(`/operations/${id}`);

export const createOperationPatient = ({
  id: operationId,
  body: patientBody,
}) =>
  APIv2.post(`/operations/${operationId}/patient`, patientBody)
    .then(normalizeResponse(patientSchema))
    .then((normalized) => ({ ...normalized, operationId }));

export const createVetPatient = async ({
  id: operationId,
  body: patientBody,
}) =>
  APIv2.post(`/operations/${operationId}/vet-patient`, patientBody)
    .then(normalizeResponse(patientSchema))
    .then((normalized) => ({ ...normalized, operationId }));

export const updateOperationPatient = ({
  id: operationId,
  body: patientValues,
}) =>
  APIv2.patch(`/operations/${operationId}/patient`, patientValues)
    .then(normalizeResponse(patientSchema))
    .then((normalized) => ({ ...normalized, operationId }));

export const updateOperationVetPatient = ({
  id: operationId,
  body: patientValues,
}) =>
  APIv2.patch(`/operations/${operationId}/vet-patient`, patientValues)
    .then(normalizeResponse(patientSchema))
    .then((normalized) => ({ ...normalized, operationId }));

export const readOperationPatient = ({ id: operationId }) =>
  APIv2.get(`/operations/${operationId}/patient`)
    .then(normalizeResponse(patientSchema))
    .then((normalized) => ({ ...normalized, operationId }));

export const readOperationVetPatient = ({ id: operationId }) =>
  APIv2.get(`/operations/${operationId}/vet-patient`)
    .then(normalizeResponse(patientSchema))
    .then((normalized) => ({ ...normalized, operationId }));

export const readOperationsHistory = (options) =>
  APIv2.get('/operations_history', options).then(
    normalizeResponse(operationsListSchema)
  );

export const readOperationsHistoryVet = (options) =>
  APIv2.get('/operations_history-vet', options).then(
    normalizeResponse(operationsVetListSchema)
  );

/**
 * Request the amount of operations user participated in
 */
export const readOperationsCount = (params = {}) => {
  return APIv2.get('/operations/count', { params });
};

export const readOperationAttachments = (operationId, ...rest) =>
  APIv2.get(`/operations/${operationId}/attachments`, ...rest).then(
    normalizeResponse(operationAttachmentsResponse)
  );

export const updateOperationAttachments = (
  operationId,
  attachmentsIds,
  options
) =>
  APIv2.put(
    `/operations/${operationId}/attachments`,
    { attachments: attachmentsIds },
    options
  ).then(normalizeResponse(operationAttachmentsResponse));
