import cn from 'classnames';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { useReactPath } from 'hooks/useReactPath';
import { debounce, isEqual } from 'lodash';
import React, { Component, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { parseSearchParams } from 'utils';
import { FormInput } from '../FormInput/FormInput';
import classes from './SearchInput.module.scss';

interface SearchInputProps {
  defaultQuery?: string | null;
  error?: any;
  isLoading?: boolean;
  useParams?: boolean;
  onReset: (...args) => void;
  onSearch: (query: string) => void;
  containerClassName?: string;
  className?: string;
  placeholder?: string;
}

export const SearchInput: FC<SearchInputProps> = ({
  defaultQuery = null,
  error = null,
  isLoading = false,
  useParams = false,
  onReset,
  onSearch,
  containerClassName,
  ...props
}) => {
  const location = useReactPath();
  const [prevLocation, setPrevLocation] = useState(location);
  const [touched, setTouched] = useState(false);
  const [query, setQuery] = useState(defaultQuery || '');
  const { t } = useTranslation();

  const inputProps = () => {
    return {
      containerClassName,
      defaultQuery,
      error,
      isLoading,
      onReset,
      onSearch,
      t,
      useParams,
      ...props,
    };
  };

  useEffect(() => {
    if (query && !touched) {
      handleSearch();
    }
  }, []);

  useEffect(() => {
    if (useParams && location.search) {
      const parsedUrl = parseSearchParams(location.search);
      if (parsedUrl.s && !query) {
        setQuery(parsedUrl.s as string);
      }
    }
    handleDebouncedSearch();
  }, [useParams, location.search, query]);

  useEffect(() => {
    const prevParams = parseSearchParams(prevLocation.search);
    const currentParams = parseSearchParams(location.search);

    if (!isEqual(prevParams.c, currentParams.c)) {
      setQuery('');
    }

    setPrevLocation(location);
  }, [location.search, prevLocation.search, setQuery]);

  const handleSearch = () => {
    if (!query) return;
    onSearch(query);
  };

  const reset = () => {
    onReset();
    setTouched(false);
    setQuery('');
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleDebouncedSearch = debounce(handleSearch, 500);

  const handleQueryChange = (event) => {
    if (!event.target.value && query) {
      reset();
    }
    setQuery(event.target.value);
    setTouched(true);
  };

  return (
    <div className={cn(containerClassName, classes.container)}>
      <FormInput
        {...inputProps()}
        name="search"
        value={query}
        onChange={handleQueryChange}
        prepend={<Icon className={classes.icon} name="magnifier" />}
        append={
          <Button
            type="button"
            variant="text"
            className={classes.button}
            disabled={!query}
            onClick={handleSearchClick}
          >
            {t('Search')}
          </Button>
        }
      />
    </div>
  );
};

export class SearchInputv1 extends Component {}
