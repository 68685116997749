/* eslint-disable no-param-reassign */
import Axios from 'axios';

import userInstance from 'local-store/user';
import historyInstance from 'store/history';

import { createHandleUnauthorizedRequest } from 'api/interceptors/request-error-strategies';

import {
  createRequestInterceptor,
  createResponseErrorInterceptor,
  responseInterceptor,
} from 'api/interceptors';
import { REFRESH_PATH } from 'app-constants/api-paths';

const getApiUrl = (version) => `${process.env.REACT_APP_API_URL}${version}/`;

const baseConfig = Object.freeze({
  headers: {
    common: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  },
});

const requestTokenRefresh = (refreshToken) =>
  Axios.post(
    REFRESH_PATH,
    { refreshToken },
    {
      ...baseConfig,
      baseURL: getApiUrl('v1'),
    }
  ).then((response) => response.data);

const handleUnauthorizedRequest = createHandleUnauthorizedRequest(
  userInstance,
  historyInstance,
  requestTokenRefresh
);
const responseErrorInterceptor = createResponseErrorInterceptor({
  handleUnauthorizedRequest,
});

const requestInterceptor = createRequestInterceptor();

export default (version) => {
  if (!version) {
    throw new TypeError('API version is a required parameter.');
  }

  const axios = Axios.create({
    ...baseConfig,
    baseURL: getApiUrl(version),
  });
  axios.interceptors.request.use(requestInterceptor);
  axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor(axios)
  );

  return axios;
};
