import React, { Component } from 'react';
import hoistStatics from 'hoist-non-react-statics';

import Bugsnag from '@bugsnag/js';
import { ComponentError } from 'components/ComponentError/ComponentError';

export default (name) => (ComponentToWrap) => {
  class WithComponentDidCatch extends Component {
    state = {
      error: false,
    };

    componentDidCatch(error) {
      Bugsnag.notify(error);
      this.setState({ error: true });
    }

    render() {
      if (this.state.error) {
        return <ComponentError componentName={name} />;
      }

      return <ComponentToWrap {...this.props} />;
    }
  }

  hoistStatics(WithComponentDidCatch, ComponentToWrap);

  return WithComponentDidCatch;
};
