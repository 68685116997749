import { FC } from 'react';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CREATE_QUALIFICATION_FORM } from 'scenes/routes.enum';

export const CreateQualificationFormButton: FC = () => {
  const { t } = useTranslation();

  return (
    <Button component={Link} to={CREATE_QUALIFICATION_FORM}>
      {t('assessmentForms.newForm')}
    </Button>
  );
};
