import { DataTag } from 'components/DataTag';
import { Text } from 'components/Typography/Text';
import React, { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FormattedDuration } from '../FormattedDuration';

export interface DurationLabelProps {
  /**
   * Duration label
   */
  label?: string;
  /**
   * Duration time
   */
  duration?: number;
  /**
   * Flag to hide component if there is no duration
   */
  hideOnEmpty?: boolean;
  /**
   * Flag to show/hide seconds
   */
  showSeconds?: boolean;
  /**
   * Flag to show/hide plus icon
   */
  showPlus?: boolean;
  /**
   * Data attribute key (check DataTag component props)
   */
  propKey?: string;
}

/**
 * Renders operation/procedure time with label
 */
export const DurationLabel: FC<DurationLabelProps> = memo(
  function DurationLabel({
    label,
    duration,
    hideOnEmpty,
    showSeconds,
    showPlus,
    propKey = 'duration',
    ...props
  }) {
    const { t } = useTranslation();

    if (hideOnEmpty && !duration) return null;

    return (
      <Text variant="caption" {...props}>
        <Text variant="paragraph-sm" color="secondary" component="span">
          {label || t('Duration')}:
        </Text>{' '}
        <FormattedDuration
          duration={duration}
          showSeconds={showSeconds}
          showPlus={showPlus}
        >
          {(formatted: ReactNode) =>
            !formatted ? (
              'N/A'
            ) : (
              <DataTag propKey={propKey}>{formatted}</DataTag>
            )
          }
        </FormattedDuration>
      </Text>
    );
  }
);
