import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Downshift from 'downshift';

import styles from './HeaderUser.module.scss';

import { Avatar, IconButton, Icon, Text } from 'components';
import { PROFILE } from 'scenes/routes.enum';
import { requestLogout, selectUser } from 'store/modules/auth';
import { useTranslation } from 'react-i18next';

export const HeaderUser: FC = memo(function HeaderUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(requestLogout());
  };

  // This equality FN always returns false because of the problems with formik.
  // Formik changes values of the user in store instantly and when this selector is being ran, it has both current and prev versions the same.
  // Form submitting is done on blur there, and on every submit it changes the user data from store.
  // Either this stays the same, or formik should not be connected to the store (and dispatch changes on every blur/change).
  // Formik is in the Profile component
  const { givenName, surname, email, imageUrl } = useSelector(
    selectUser,
    () => false
  );

  return (
    <Downshift>
      {({ isOpen, getToggleButtonProps, getMenuProps }) => (
        <div>
          <div {...getToggleButtonProps()} className={styles.shortInfo}>
            <Text
              variant="paragraph-md"
              weight="bold"
              component="span"
              className="mr-1"
            >
              {t('greetGivenName', { givenName })}
            </Text>

            <Avatar
              givenName={givenName}
              surname={surname}
              imageUrl={imageUrl}
            />
          </div>

          {isOpen && (
            <div className={styles.menu} {...getMenuProps()}>
              <div className={styles.user}>
                <div className={styles.data}>
                  <Text component="div" weight="bold" variant="heading-md">
                    {givenName} {surname}
                  </Text>
                  <Text component="div" weight="regular" variant="paragraph-sm">
                    {email}
                  </Text>
                </div>

                <Link to={PROFILE}>
                  <IconButton icon="settings" />
                </Link>
              </div>

              <button
                type="button"
                onClick={logout}
                className={styles.logoutButton}
              >
                <Icon name="log-out" />
                <span className={styles.logoutText}>{t('logOut')}</span>
              </button>
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
});
