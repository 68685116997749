import React, { FC, useEffect, useLayoutEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/js';

import styles from './App.module.scss';
import { authRoutes, areUserTokensAvailable } from './utils';

import Header from 'scenes/Header';
import { SideBar } from 'scenes/SideBar';
import Main from 'scenes/Main';
import LoaderScreen from 'scenes/LoaderScreen';
import { AUTH, APP_CRASHED } from 'scenes/routes.enum';
import ErrorScreen from 'scenes/ErrorScreen';
import { readCurrentUser, selectUser } from 'store/modules/auth';

/**
 * Renders application layout withn inner pages routing
 */
export const App: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();

  const user = useSelector(selectUser);

  // Alternative to componentDidMount which was in the class implementation of the component
  useLayoutEffect(() => {
    if (
      !authRoutes.has(history.location.pathname) &&
      !user &&
      areUserTokensAvailable()
    ) {
      dispatch(readCurrentUser());
    }
  }, []);

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.settings?.lang);
      Bugsnag.setUser(user.id, user.email, `${user.givenName} ${user.surname}`);
    }
  }, [i18n, user]);

  if (!user && !areUserTokensAvailable()) {
    return <Redirect to={{ pathname: AUTH, state: { ...location } }} />;
  }

  if (!user) {
    return <LoaderScreen />;
  }

  return (
    <div className={styles.container}>
      <SideBar />
      <div className={styles.center}>
        <Header />
        <Switch>
          <Route path={APP_CRASHED} component={ErrorScreen} />
          <Route component={Main} />
        </Switch>
      </div>
    </div>
  );
};
