import React, { FC } from 'react';
import { AttachmentPlaceholder } from '../AttachmentPlaceholder';
import { OperationStepAttachment } from '../OperationStepAttachment';
import classes from '../OperationStepAttachments.module.scss';

interface Props {
  attachments: number[];
  id: string;
  onRemove: (attachmentId: number) => void;
  onImageClick: (attachmentId: number) => void;
  onPlaceholderClick?: () => void;
}

export const OperationStepAttachmentsForm: FC<Props> = ({
  attachments,
  onRemove,
  onImageClick,
  onPlaceholderClick,
}) => {
  if (!attachments?.length) {
    return (
      <AttachmentPlaceholder
        className={classes.imgPlaceholder}
        onClick={onPlaceholderClick}
      />
    );
  }

  return (
    <div className={classes.imgList}>
      {attachments.map((attachmentId) => (
        <OperationStepAttachment
          key={attachmentId}
          id={attachmentId}
          onClick={onImageClick}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};
