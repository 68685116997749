import Bugsnag from '@bugsnag/js';
import * as APIv1 from 'api/v1';
import { normalizeLibraryElement } from 'api/v1/schemas';
import get from 'lodash';
import { makeThunk } from 'store/utils';
import { makeThunkFromTypes } from 'store/utils/make-thunk';
import i18n from 'translations/i18n-instance';
import { selectTemplates } from '../../entities/selectors/selectors';
import { getClearedUpElementsList } from '../../operation-planning';
import {
  createTemplateFailure,
  createTemplateMaterialsFailure,
  createTemplateMaterialsRequest,
  createTemplateMaterialsSuccess,
  createTemplateRequest,
  createTemplateSnapshotFailure,
  createTemplateSnapshotRequest,
  createTemplateSnapshotSuccess,
  createTemplateSuccess,
  deleteTemplateFailure,
  deleteTemplateRequest,
  deleteTemplateSuccess,
  readTemplateFailure,
  readTemplateRequest,
  readTemplateSuccess,
  updateTemplateFailure,
  updateTemplateRequest,
  updateTemplateSuccess,
} from '../actions';
import { selectCurrentTemplateElements } from '../selectors';
import * as actionTypes from '../types';

export const deleteTemplate = makeThunk(APIv1.deleteTemplate, {
  request: deleteTemplateRequest,
  success: deleteTemplateSuccess,
  failure: deleteTemplateFailure,
});

export const createTemplateSnapshot = makeThunk(APIv1.createTemplateSnapshot, {
  request: createTemplateSnapshotRequest,
  success: createTemplateSnapshotSuccess,
  failure: createTemplateSnapshotFailure,
});

export const readTemplateTimeoutQuestions = makeThunkFromTypes(
  APIv1.readTemplateTimeoutQuestions,
  {
    request: actionTypes.READ_TEMPLATE_TIMEOUT_QUESTIONS_REQUEST,
    success: actionTypes.READ_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS,
    failure: actionTypes.READ_TEMPLATE_TIMEOUT_QUESTIONS_FAILURE,
  },
  (id) => `Failed to read template time-out questions (template id: ${id})`
);

export const updateTemplateTimeoutQuestions = makeThunkFromTypes(
  APIv1.updateTemplateTimeoutQuestions,
  {
    request: actionTypes.UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_TIMEOUT_QUESTIONS_FAILURE,
  },
  (id) => `Failed to update template time-out questions (template id: ${id})`
);

export const readTemplateDebriefingQuestions = makeThunkFromTypes(
  APIv1.readTemplateDebriefingQuestions,
  {
    request: actionTypes.READ_TEMPLATE_DEBRIEFING_QUESTIONS_REQUEST,
    success: actionTypes.READ_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS,
    failure: actionTypes.READ_TEMPLATE_DEBRIEFING_QUESTIONS_FAILURE,
  },
  (id) => `Failed to read template debriefing questions (template id: ${id})`
);

export const updateTemplateDebriefingQuestions = makeThunkFromTypes(
  APIv1.updateTemplateDebriefingQuestions,
  {
    request: actionTypes.UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_DEBRIEFING_QUESTIONS_FAILURE,
  },
  (id) => `Failed to update template debriefing questions (template id: ${id})`
);

export const createTemplate = makeThunk(
  APIv1.createTemplate,
  {
    request: createTemplateRequest,
    success: createTemplateSuccess,
    failure: createTemplateFailure,
  },
  () => i18n.t('errors:failedToCreateOperationTemplate')
);

export const readTemplate = makeThunk(
  APIv1.readTemplate,
  {
    request: readTemplateRequest,
    success: readTemplateSuccess,
    failure: readTemplateFailure,
  },
  (id) => `${i18n.t('errors:failedToReadOperationTemplate')} (id: ${id})`
);

export const readTemplateNotes = makeThunk(
  APIv1.readTemplateNotes,
  {
    request: readTemplateRequest,
    success: readTemplateSuccess,
    failure: readTemplateFailure,
  },
  (id) => `${i18n.t('errors:failedToReadOperationTemplate')} (id: ${id})`
);

export const updateTemplate = makeThunk(
  APIv1.updateTemplate,
  {
    request: updateTemplateRequest,
    success: updateTemplateSuccess,
    failure: updateTemplateFailure,
  },
  (id) => `${i18n.t('errors:failedToUpdateOperationTemplate')} (id: ${id})`
);

// FIXME: Get rid of getState in thunk once PATCH requests are available
// refs:
export const updatePostOPNotes =
  (templateId, notes) => (dispatch, getState) => {
    const state = getState();
    const template = get(selectTemplates(state), templateId, {});
    const { name, description, categoryId } = template;
    updateTemplate(templateId, { name, description, categoryId, notes })(
      dispatch
    );
  };

export const readTemplateElements = makeThunkFromTypes(
  APIv1.readTemplateElements,
  {
    request: actionTypes.READ_TEMPLATE_ELEMENTS_REQUEST,
    success: actionTypes.READ_TEMPLATE_ELEMENTS_SUCCESS,
    failure: actionTypes.READ_TEMPLATE_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToReadTemplateElements')} (id: ${id})`
);

export const updateTemplateElementByIdThunk = makeThunkFromTypes(
  APIv1.updateTemplateElementById,
  {
    request: actionTypes.UPDATE_TEMPLATE_ELEMENT_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_ELEMENT_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_ELEMENT_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateTemplateElements')} (id: ${id})`
);

export const updateTemplateElementAttachmentThunk = makeThunkFromTypes(
  APIv1.updateTemplateElementAttachment,
  {
    request: actionTypes.UPDATE_TEMPLATE_ELEMENT_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_ELEMENT_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_ELEMENT_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateTemplateElements')} (id: ${id})`
);

export const updateTemplateElements = makeThunkFromTypes(
  APIv1.updateTemplateElements,
  {
    request: actionTypes.UPDATE_TEMPLATE_ELEMENTS_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_ELEMENTS_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateTemplateElements')} (id: ${id})`
);

export const reorderTemplateElements = makeThunkFromTypes(
  APIv1.reorderTemplateElements,
  {
    request: actionTypes.UPDATE_TEMPLATE_ELEMENTS_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_ELEMENTS_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateTemplateElements')} (id: ${id})`
);

export const addTemplateElementsThunk = makeThunkFromTypes(
  APIv1.addTemplateElements,
  {
    request: actionTypes.ADD_TEMPLATE_ELEMENTS_REQUEST,
    success: actionTypes.ADD_TEMPLATE_ELEMENTS_SUCCESS,
    failure: actionTypes.ADD_TEMPLATE_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateTemplateElements')} (id: ${id})`
);

export const deleteTemplateElementThunk = makeThunkFromTypes(
  APIv1.deleteTemplateElement,
  {
    request: actionTypes.DELETE_TEMPLATE_ELEMENTS_REQUEST,
    success: actionTypes.DELETE_TEMPLATE_ELEMENTS_SUCCESS,
    failure: actionTypes.DELETE_TEMPLATE_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateTemplateElements')} (id: ${id})`
);

export const readTemplateMaterials = makeThunkFromTypes(
  APIv1.readTemplateMaterials,
  {
    request: actionTypes.READ_TEMPLATE_MATERIALS_REQUEST,
    success: actionTypes.READ_TEMPLATE_MATERIALS_SUCCESS,
    failure: actionTypes.READ_TEMPLATE_MATERIALS_FAILURE,
  }
);

export const updateTemplateMaterials = makeThunkFromTypes(
  APIv1.updateTemplateMaterials,
  {
    request: actionTypes.UPDATE_TEMPLATE_MATERIALS_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_MATERIALS_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_MATERIALS_FAILURE,
  }
);

export const createTemplateMaterials = makeThunk(
  APIv1.createTemplateMaterials,
  {
    request: createTemplateMaterialsRequest,
    success: createTemplateMaterialsSuccess,
    failure: createTemplateMaterialsFailure,
  }
);

const updateTemplateElementsBackend = (dispatch, getState, templateId) => {
  const state = getState();
  const updatedElements = selectCurrentTemplateElements(state, {
    templateId,
  });
  const elements = getClearedUpElementsList(updatedElements);

  try {
    dispatch(
      updateTemplateElements(
        templateId,
        elements,
        state?.language?.['deepl-language']
      )
    );
  } catch (error) {
    Bugsnag.notify(error);
  }
};

export const addTemplateElement =
  ({ templateId, element: rawElement, index }) =>
  async (dispatch, getState) => {
    const state = getState();
    const normalized = normalizeLibraryElement(rawElement);
    const { entities, result } = normalized;
    const element = entities.libraryElements[result];

    await dispatch({
      type: actionTypes.ADD_TEMPLATE_ELEMENT,
      payload: {
        ...normalized,
        templateId,
        index,
        element,
        uuid: element.uuid,
      },
    });

    const clearedUpElements = getClearedUpElementsList([element]);
    const elementWithOrder = { ...clearedUpElements[0], order: index };

    try {
      dispatch(
        addTemplateElementsThunk(
          templateId,
          elementWithOrder,
          state?.language?.['deepl-language']
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const updateTemplateElement =
  ({ templateId, uuid, update }) =>
  async (dispatch, getState) => {
    await dispatch({
      type: actionTypes.UPDATE_TEMPLATE_ELEMENT,
      payload: { templateId, uuid, update },
    });
    updateTemplateElementsBackend(dispatch, getState, templateId);
  };

export const updateTemplateElementById =
  (templateId, uuid, update, language) => async (dispatch, getState) => {
    await dispatch({
      type: actionTypes.UPDATE_TEMPLATE_ELEMENT,
      payload: { templateId, uuid, update },
    });

    const state = getState();
    const updatedElements = selectCurrentTemplateElements(state, {
      templateId,
    });

    const elementToUpdate = updatedElements.find((el) => el.uuid === uuid);
    const element = getClearedUpElementsList([elementToUpdate])[0];

    try {
      dispatch(
        updateTemplateElementByIdThunk(
          templateId,
          element.relationId,
          element,
          language || state?.language?.['deepl-language']
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const updateTemplateElementAttachment =
  (templateId, uuid, update) => async (dispatch, getState) => {
    await dispatch({
      type: actionTypes.UPDATE_TEMPLATE_ELEMENT,
      payload: { templateId, uuid, update },
    });

    const state = getState();
    const updatedElements = selectCurrentTemplateElements(state, {
      templateId,
    });

    const elementToUpdate = updatedElements.find((el) => el.uuid === uuid);
    const element = getClearedUpElementsList([elementToUpdate])[0];

    try {
      dispatch(
        updateTemplateElementAttachmentThunk(
          templateId,
          element.relationId,
          update,
          state?.language?.['deepl-language']
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const deleteTemplateElement =
  ({ templateId, uuid }) =>
  async (dispatch, getState) => {
    const state = getState();
    const elements = selectCurrentTemplateElements(state, {
      templateId,
    });

    await dispatch({
      type: actionTypes.DELETE_TEMPLATE_ELEMENT,
      payload: { templateId, uuid },
    });

    const elementToDelete = elements.find((el) => el.uuid === uuid);

    try {
      dispatch(
        deleteTemplateElementThunk(
          templateId,
          elementToDelete.relationId,
          state?.language?.['deepl-language']
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const changeTemplateElementsOrder =
  ({ templateId, fromIndex, toIndex }) =>
  async (dispatch, getState) => {
    await dispatch({
      type: actionTypes.CHANGE_TEMPLATE_ELEMENTS_ORDER,
      payload: { templateId, fromIndex, toIndex },
    });

    const state = getState();
    const updatedElements = selectCurrentTemplateElements(state, {
      templateId,
    });
    const elements = getClearedUpElementsList(updatedElements);

    try {
      dispatch(
        reorderTemplateElements(
          templateId,
          elements,
          state?.language?.['deepl-language']
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const createTemplateMaterialsGroup = makeThunkFromTypes(
  APIv1.createTemplateMaterialsGroup,
  {
    request: actionTypes.CREATE_TEMPLATE_MATERIALS_GROUP_REQUEST,
    success: actionTypes.CREATE_TEMPLATE_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.CREATE_TEMPLATE_MATERIALS_GROUP_FAILURE,
  }
);

export const readTemplateMaterialsGroups = makeThunkFromTypes(
  APIv1.readTemplateMaterialsGroups,
  {
    request: actionTypes.READ_TEMPLATE_MATERIALS_GROUPS_REQUEST,
    success: actionTypes.READ_TEMPLATE_MATERIALS_GROUPS_SUCCESS,
    failure: actionTypes.READ_TEMPLATE_MATERIALS_GROUPS_FAILURE,
  }
);

export const readTemplateMaterialsGroup = makeThunkFromTypes(
  APIv1.readTemplateMaterialsGroup,
  {
    request: actionTypes.READ_TEMPLATE_MATERIALS_GROUP_REQUEST,
    success: actionTypes.READ_TEMPLATE_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.READ_TEMPLATE_MATERIALS_GROUP_FAILURE,
  }
);

export const updateTemplateMaterialsGroup = makeThunkFromTypes(
  APIv1.updateTemplateMaterialsGroup,
  {
    request: actionTypes.UPDATE_TEMPLATE_MATERIALS_GROUP_REQUEST,
    success: actionTypes.UPDATE_TEMPLATE_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.UPDATE_TEMPLATE_MATERIALS_GROUP_FAILURE,
  }
);

export const deleteTemplateMaterialsGroup = makeThunkFromTypes(
  APIv1.deleteTemplateMaterialsGroup,
  {
    request: actionTypes.DELETE_TEMPLATE_MATERIALS_GROUP_REQUEST,
    success: actionTypes.DELETE_TEMPLATE_MATERIALS_GROUP_SUCCESS,
    failure: actionTypes.DELETE_TEMPLATE_MATERIALS_GROUP_FAILURE,
  }
);
