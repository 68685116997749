import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';

import { RadioSwitch } from 'components/Form/RadioSwitch';
import { Prefer } from 'common-types';

interface OptionType {
  label: string;
  value: string | number | null;
  checked?: boolean;
  defaultChecked?: boolean;
}

type FilterSwitchProps = Prefer<
  {
    /**
     * Filter options
     */
    options: OptionType[];
    /**
     * Prefered active value
     */
    active?: string | number | null;
    /**
     * Callback to be fired on value change
     */
    onChange?: (value: string, name: string) => void;
  },
  InputHTMLAttributes<HTMLInputElement>
>;

/**
 * Renders a line list of options with the option to select only one option from the list
 * TODO - fix radioswitch state management and remove this component
 */
export const DeprecatedFilterSwitch: FC<FilterSwitchProps> = ({
  value,
  active,
  options,
  onChange,
  ...props
}) => {
  const activeValue = active || value || null;

  const onChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = ev.currentTarget;
    onChange?.(value, name);
  };

  return (
    <RadioSwitch
      name=""
      value={activeValue}
      options={options}
      onChange={onChangeHandler}
      {...props}
      size="sm"
    />
  );
};
