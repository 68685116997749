export const OPERATION_STATUSES = Object.freeze({
  planning: 'planning',
  draft: 'draft',
  planned: 'planned',
  done: 'done',
  finished: 'finished',
});

export const OPERATION_STATUSES_RANK = Object.freeze({
  planning: 0,
  draft: 1,
  planned: 2,
  done: 3,
  finished: 4,
});

export const isOperationFinished = (status) =>
  !!status &&
  [OPERATION_STATUSES.done, OPERATION_STATUSES.finished].includes(status);
