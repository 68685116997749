import {
  createFailureActionCreator,
  createSuccessActionCreator,
} from 'store/utils';
import {
  CREATE_ATTACHMENT_FAILURE,
  CREATE_ATTACHMENT_REQUEST,
  CREATE_ATTACHMENT_SUCCESS,
} from './types';

export const createAttachmentRequest = (payload) => ({
  payload,
  type: CREATE_ATTACHMENT_REQUEST,
});

export const createAttachmentSuccess = createSuccessActionCreator(
  CREATE_ATTACHMENT_SUCCESS
);

export const createAttachmentFailure = createFailureActionCreator(
  CREATE_ATTACHMENT_FAILURE
);
