import * as types from './types';

const initialState = Object.freeze({
  closeOn: null,
  dialogProps: null,
  modalProps: null,
  modalName: null,
});

export default (state = initialState, action) => {
  if (
    (Array.isArray(state.closeOn) &&
      state.closeOn.some(
        actionTypeToCloseOn => action.type === actionTypeToCloseOn
      )) ||
    (typeof state.closeOn === 'string' && state.closeOn === action.type)
  ) {
    return initialState;
  }

  switch (action.type) {
    case types.SHOW_MODAL_DIALOG:
      return action.payload;

    case types.HIDE_MODAL_DIALOG:
      return initialState;

    default:
      return state;
  }
};
