/* istanbul ignore file */

export const REQUEST_STATES = {
  NOT_STARTED: 'NOT_STARTED',
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  RESET: 'RESET',
};

export const REQUEST_TYPES = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  LOAD: 'LOAD',
  OTHER: 'OTHER',
  READ: 'READ',
  SEARCH: 'SEARCH',
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
  UPLOAD: 'UPLOAD',
  AUTOSAVE: 'AUTOSAVE',
};
