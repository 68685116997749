import { LibraryCategories } from '../../common-types/library';

export const risksCategoriesSet = new Set([
  LibraryCategories.risks,
  LibraryCategories.patientRisks,
]);

export const warningsCategoriesSet = new Set([LibraryCategories.checks]);

export const categoryIconsMap = {
  [LibraryCategories.checks]: 'check',
  [LibraryCategories.risks]: 'risk',
  [LibraryCategories.patientRisks]: 'risk',
};

export const categoryLabelsMap = {
  [LibraryCategories.checks]: 'check',
  [LibraryCategories.risks]: 'risk',
  [LibraryCategories.patientRisks]: 'risk',
  [LibraryCategories.implants]: 'Implants',
  [LibraryCategories.equipment]: 'Equipment',
  [LibraryCategories.instruments]: 'Instruments',
};
