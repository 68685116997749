import React, { ReactElement, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { OperationFormsTypes } from 'app-constants';
import { ElementsForm } from 'components/ElementsForm/ElementsForm';
import { Loader } from 'components/Loader';
import { Text } from 'components/Typography/Text';
import { selectHistoryStepsList } from 'store/modules/entities/selectors/operations';
import { selectHistoryOperationAttachments } from 'store/modules/history';
import {
  readOperationElements,
  readOperationElementsAttachments,
  selectPostOpNotes,
  selectVitalInformation,
} from 'store/modules/operation-planning';
import { OperationStepEstimable } from 'common-types/library';
import { Attachment } from 'common-types/attachments';
import { VitalInformation as VitalInformationI } from 'common-types/operation';
import { RouteComponentProps } from 'react-router';
import { TranslatableString } from 'common-types/common';
import { PostOPNotes } from './PostOPNotes';
import { VitalInformation } from './VitalInformation';
import withRouterParams from 'containers/withRouterParams';
import withGallery from 'containers/withGallery';

type RestProps = RouteComponentProps & { elementsAttachments: Attachment[] };

interface Props {
  elements: OperationStepEstimable[];
  operationId: number;
  onOpenById?(id: number): void;
  vitalInformation?: VitalInformationI;
  postOPNotes: TranslatableString | string;
  restProps: RestProps;
}

const OperationStepsComponent = ({
  operationId,
  postOPNotes,
  vitalInformation,
  elements,
  onOpenById,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchElements = () => {
    if (!operationId) return;
    dispatch(readOperationElements(operationId));
    dispatch(readOperationElementsAttachments(operationId));
  };
  useEffect(() => {
    fetchElements();
  }, []);

  useEffect(() => {
    if (operationId) {
      fetchElements();
    }
  }, [operationId]);

  const elementsLoaded = Boolean(elements);
  const hasElements = Boolean(elements && elements.length);

  return (
    <div>
      {!elementsLoaded && <Loader />}
      {hasElements && (
        <ElementsForm
          readonly
          type={OperationFormsTypes.history}
          elements={elements}
          onTriggerGallery={onOpenById}
        />
      )}
      {elementsLoaded && !hasElements && <Text>{t('noStepsAdded')}</Text>}
      <PostOPNotes content={postOPNotes || ''} />
      {vitalInformation && (
        <VitalInformation
          title={vitalInformation.title}
          description={vitalInformation.description}
        />
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector<Props, {}>({
  elements: selectHistoryStepsList,
  elementsAttachments: selectHistoryOperationAttachments,
  postOPNotes: selectPostOpNotes,
  vitalInformation: selectVitalInformation,
});

const withConnect = connect(mapStateToProps);

const enhancer = compose(
  withRouterParams('operationId', true),
  withConnect,
  withGallery({ filesPropName: 'elementsAttachments' })
);

export const OperationSteps = enhancer(OperationStepsComponent);
