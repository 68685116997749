import cn from 'classnames';
import { Editable } from 'components/Editable';
import { InputString } from 'components/Form/InputString';
import { MaxLengthIndicator } from 'components/MaxLengthIndicator';
import { Multiline } from 'components/Multiline';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { countCharsOccupied } from 'utils/count-lines';
import classes from './NameInput.module.scss';

interface Props {
  className?: string;
  defaultValue?: string;
  onBlur;
  charsLimit?: number;
  initialLinesCount?: number;
  charsPerLineLimit?: number;
}

export const NameInput: ComponentType<Props> = ({
  className,
  onBlur,
  defaultValue = '',
  charsLimit,
  charsPerLineLimit,
}) => {
  const [edit, setEdit] = useState(false);
  const hasCharsLimit = typeof charsLimit === 'number';
  const calculateCharsOccupied = useCallback(
    (value) => {
      if (!value?.length) return 0;
      if (!charsPerLineLimit) return value.length;
      return countCharsOccupied(value, charsPerLineLimit);
    },
    [charsPerLineLimit]
  );

  const updateCharsLength = useCallback(
    (value) => {
      setCharsLength(calculateCharsOccupied(value));
    },
    [calculateCharsOccupied]
  );

  const [charsLength, setCharsLength] = useState<number>(
    calculateCharsOccupied(defaultValue)
  );

  useEffect(() => {
    if (!hasCharsLimit) return;
    updateCharsLength(defaultValue);
  }, [defaultValue, charsPerLineLimit, updateCharsLength, hasCharsLimit]);

  const handleChange = (event) => {
    const { value } = event.target;

    if (hasCharsLimit && charsPerLineLimit) {
      updateCharsLength(value);
    }
  };

  const handleBlur = (event) => {
    onBlur?.(event);
    setEdit(false);
  };

  return (
    <div className={cn(className, classes.container)}>
      {edit ? (
        <>
          <InputString
            autoFocus
            className={classes.input}
            defaultValue={defaultValue}
            block
            multiline
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {hasCharsLimit && (
            <span className={classes.indicator}>
              <MaxLengthIndicator
                length={charsLength}
                limit={charsLimit as number}
              />
            </span>
          )}
        </>
      ) : (
        <Editable onClick={() => setEdit(true)}>
          <Multiline>{defaultValue}</Multiline>
        </Editable>
      )}
    </div>
  );
};

NameInput.displayName = 'NameInput';
