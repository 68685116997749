import { shape, string, number, arrayOf, oneOf } from 'prop-types';
import { idTypes } from './common';

export const articleListTypes = shape({
  id: idTypes,
  type: oneOf(['Article', 'Book']),
  title: string,
  description: string,
  language: string,
  authorList: arrayOf(
    shape({
      LastName: string,
      ForeName: string,
      Initials: string,
    })
  ),
  pubDate: shape({
    Year: number,
    Month: number,
    Day: number,
  }),
  eLocationId: arrayOf(string),
  source: shape({
    ISSN: string,
    Title: string,
  }),
});
