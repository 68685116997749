import { normalize, schema } from 'normalizr';
import { makeLibraryElementUUID, setTempRelationId } from 'store/utils';
import { attachmentsListSchema } from './file';

export const libraryElementsSchema = new schema.Entity(
  'libraryElements',
  {
    attachments: attachmentsListSchema,
  },
  {
    idAttribute: (value, _, category) => {
      value.category = value.category || category;
      const uuid = makeLibraryElementUUID(value);
      return uuid;
    },
    processStrategy: (element, _, category) => {
      element.category = element.category || category;
      const uuid = makeLibraryElementUUID(element);
      element.uuid = uuid;
      if (Array.isArray(element.attachments)) {
        element.attachments = element.attachments.map((a) => {
          a.elementRelationId = element.relationId;
          return a;
        });
      }

      return element;
    },
  }
);

export const libraryElementsListSchema = new schema.Array(
  libraryElementsSchema
);

export const normalizeLibraryElement = (element) =>
  normalize(setTempRelationId({ ...element }), libraryElementsSchema);

export const normalizeElementsListResponse = ({ elements }) => {
  return normalize(elements, libraryElementsListSchema);
};
