/* istanbul ignore file */

import React, { FC } from 'react';

import { FormInputBase } from 'components/Form/FormInput/FormInputBase';
import { SignaturePad } from './SignaturePad';

interface Props {
  /**
   * HTML id attr
   */
  id: string;
  /**
   * HTML name attr
   */
  name: string;
  /**
   * HTML class
   */
  className: string;
  /**
   * HTML tag label text
   */
  label: string;
  /**
   * Image source default value attribute
   */
  defaultValue: string;
  /**
   * Function called on click the save button for saving the user image
   */
  onChange: Function;
}

export const FormSignaturePad: FC<Props> = (props: Props) => (
  <FormInputBase {...props}>
    {({ getProps }) => <SignaturePad {...getProps()} />}
  </FormInputBase>
);
