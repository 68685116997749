import React, { ComponentType } from 'react';
import { useLimitComputing } from './useLimitComputing';
import { IndicatorBase } from './IndicatorBase';

interface Props {
  length: number;
  limit: number;
}

export const MaxLengthIndicator: ComponentType<Props> = ({ length, limit }) => {
  const { isCloseToLimit, isOverLimit } = useLimitComputing(length, limit);

  return (
    <IndicatorBase
      length={length}
      limit={limit}
      isCloseToLimit={isCloseToLimit}
      isOverLimit={isOverLimit}
    />
  );
};

MaxLengthIndicator.displayName = 'MaxLengthIndicator';
