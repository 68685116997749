import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { isObject } from 'lodash';

import styles from './AuthFormLogin.module.scss';
import { initialLoginFormState, useLoginForm } from './utils';
import { AuthFormKeepLoggedInField } from './AuthFormKeepLoggedInField';

import { AuthFormTemplate } from 'scenes/Auth/AuthFormTemplate';
import { Button, FormActions, FormBody, FormInput } from 'components';
import { parseSearchParams } from 'utils';
import { LoginSchema } from 'validation-schemas';
import { AuthUpdatePasswordForm } from 'scenes/Auth/AuthUpdatePasswordForm';
import { useProduct } from 'hooks/useProduct';
import { HidePasswordIcon, ShowPasswordIcon } from 'assets/svg';

/**
 * Renders sign in to the application form
 */
export const AuthFormLogin: FC = () => {
  const { t } = useTranslation();
  const { productName } = useProduct();

  const { auth, location, onSubmit, onResetErrors } = useLoginForm();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = () => {
      onResetErrors();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [onResetErrors]);

  const queryParams = useMemo(() => {
    return parseSearchParams(location.search);
  }, [location.search]);

  if (!!queryParams.requireUpdatePwd) {
    return <AuthUpdatePasswordForm />;
  }

  return (
    <AuthFormTemplate
      title={t('loginTitle', { product: productName })}
      errorMessage={
        isObject(auth.error?.message)
          ? auth.error?.message.email || auth.error?.message.password
          : auth.error?.message
      }
      displayErrorMessage={!!auth.error}
    >
      <Formik
        validationSchema={LoginSchema}
        initialValues={initialLoginFormState}
        onSubmit={onSubmit}
        validateOnChange
        validateOnMount
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => {
          const onChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(ev);
            onResetErrors();
          };

          return (
            <form onSubmit={handleSubmit}>
              <FormBody narrow>
                <FormInput
                  className={styles.formInput}
                  id="login-email"
                  name="email"
                  type="email"
                  value={values.email}
                  error={touched.email && errors.email && ''}
                  label={t('email')}
                  placeholder={t('emailAddress')}
                  onChange={onChangeHandler}
                  onBlur={handleBlur}
                />

                {touched.email && errors.email && (
                  <div className="text-error mr-05 mb-1">
                    {t(`validation:${errors.email}`)}
                  </div>
                )}

                <FormInput
                  className={styles.formInput}
                  id="login-password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  error={touched.password && errors.password && ''}
                  label={t('password')}
                  placeholder={t('passwordEnterYours')}
                  onChange={onChangeHandler}
                  onBlur={handleBlur}
                  icon={
                    showPassword ? (
                      <div
                        className={styles.showPasswordIcon}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <ShowPasswordIcon />
                      </div>
                    ) : (
                      <div
                        className={styles.showPasswordIcon}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <HidePasswordIcon />
                      </div>
                    )
                  }
                />

                {touched.password && errors.password && (
                  <div className="text-error mb-1">
                    {t(`validation:${errors.password}`)}
                  </div>
                )}

                <AuthFormKeepLoggedInField email={values.email} />

                <FormActions>
                  <Button
                    loading={auth.isLoading}
                    type="submit"
                    disabled={!isValid}
                  >
                    {t('logIn')}
                  </Button>
                </FormActions>
              </FormBody>
            </form>
          );
        }}
      </Formik>
    </AuthFormTemplate>
  );
};
