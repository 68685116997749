import { Attachment } from './attachments';
import { TranslatableString } from './common';
import { Entity } from './Entity';
import { Incident } from './incidents';

export enum LibraryCategories {
  equipment = 'equipment',
  implants = 'implants',
  instruments = 'instruments',
  checks = 'checks',
  procedures = 'procedures',
  risks = 'risks',
  patientRisks = 'patientRisks',
  notes = 'notes',
}

export interface LibraryItemsSearchOption {
  value: number;
  label: string;
  category: LibraryCategories;
  isSelected: boolean;
  createdAt: string;
  createdBy: number;
  description: string;
  estimatedTime: number;
  id: number;
  isDeleted: boolean;
  name: string;
  updatedAt: string;
  updatedBy: number;
  uuid: string;
}

export enum MaterialCategories {
  equipment = 'equipment',
  implants = 'implants',
  instruments = 'instruments',
}

export interface LibraryElement extends Entity {
  category: LibraryCategories;
  name: string;
  description: string;
  id: number;
  uuid: string;
  relationId?: string;
}

export interface Check extends LibraryElement {}

export interface Procedure extends LibraryElement {}

export interface Risk extends LibraryElement {
  mitigationAction: number;
}

export interface MaterialElement extends LibraryElement {
  attachments: Attachment[];
  company: string | null;
  count?: number;
  order: number;
  serialNumber: string | null;
  size: string | null;
}

export interface Implant extends MaterialElement {}
export interface Instrument extends MaterialElement {}
export interface Equipment extends MaterialElement {}

export type MaterialElementInput = {
  company: MaterialElement['company'];
  description: MaterialElement['description'];
  name: MaterialElement['name'];
  serialNumber: MaterialElement['serialNumber'];
  size: MaterialElement['size'];
  count: MaterialElement['count'];
};

export type MaterialsGroupOption = {
  categories?: string[];
  label: string;
  value: number | string;
};

export interface OperationStep extends LibraryElement {
  attachments: number[];
  edited: boolean;
  mitigationAction?: string;
  incident?: Incident;
  step?: number;
}

export interface OperationStepInput {
  description?: OperationStep['description'];
  name?: OperationStep['name'];
  attachments?: OperationStep['attachments'];
  estimatedTime?: OperationStepEstimable['estimatedTime'];
}

export interface OperationStepEstimable extends OperationStep {
  estimatedTime: number;
  actualTime?: number;
}

export interface DiagnosesType extends Entity {
  code: string;
  name: TranslatableString;
  id: number;
  uuid: string;
}

export interface LibraryItemType {
  id: number;
  uuid: string;
  name: TranslatableString;
  category: LibraryCategories;
  edited: boolean;
  step: number;
}
