import React, { FC } from 'react';
import { FormInput } from 'components/Form/FormInput';
import { useTranslation } from 'react-i18next';
import { connect, FormikContextType } from 'formik';

interface EquipmentFormFormikValueProps {
  company: string;
  serialNumber: string;
  size: string;
}

const EquipmentForm: FC<FormikContextType<EquipmentFormFormikValueProps>> = ({
  handleChange,
  values,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormInput
        label={t('Company')}
        name="company"
        placeholder={t('enterEquipmentCompany')}
        value={values.company}
        error={errors.company}
        onChange={handleChange}
      />

      <FormInput
        label={t('Size')}
        name="size"
        placeholder={t('enterEquipmentSize')}
        value={values.size}
        error={errors.size}
        onChange={handleChange}
      />

      <FormInput
        label={t('serialNumber')}
        name="serialNumber"
        placeholder={t('enterEquipmentSerialNumber')}
        value={values.serialNumber}
        error={errors.serialNumber}
        onChange={handleChange}
      />
    </>
  );
};

export default connect(EquipmentForm);
