import { schema, normalize } from 'normalizr';

export const attachmentsSchema = new schema.Entity('attachments');
export const attachmentsListSchema = new schema.Array(attachmentsSchema);

export const operationAttachmentsResponse = new schema.Object({
  attachments: attachmentsListSchema,
});

export const templateAttachmentsResponse = new schema.Object({
  attachments: attachmentsListSchema,
});

export const normalizeAttachmentsListResponse = (attachments) =>
  normalize(attachments, [attachmentsSchema]);
