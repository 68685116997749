import hoistStatics from 'hoist-non-react-statics';
import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createLoadingSelector } from 'store/modules/api-requests';
import {
  selectFailedFilesUpload,
  selectUploadErrorMessage,
  uploadFiles_DEPRECATED,
  UPLOAD_FILE_REQUEST,
} from 'store/modules/files-upload';

export default (ComponentToWrap: ComponentType<any>): ComponentType<any> => {
  class WithFileUpload extends Component<any> {
    handleFileUpload = async (files) => {
      const { onUploadFiles } = this.props;
      if (!files?.length && !files?.type) {
        return null;
      }
      let filesList;

      if (Array.isArray(files)) {
        filesList = files;
      } else if (files.type === 'change') {
        filesList = [...(files.target.files ?? [])];
      } else if (files.type === 'paste') {
        filesList = [...(files?.clipboardData?.files ?? [])];
      }

      if (!filesList?.length) return null;

      const result = await onUploadFiles([...filesList]);
      return result;
    };

    render() {
      const {
        isFileUploading,
        error,
        failedUpload,
        onUploadFiles,
        ...restProps
      } = this.props;

      return (
        <ComponentToWrap
          failedUpload={failedUpload}
          isFileUploading={isFileUploading}
          onUpload={this.handleFileUpload}
          uploadErrorMessage={error?.error}
          {...restProps}
        />
      );
    }
  }

  const mapDispatchToProps = {
    onUploadFiles: uploadFiles_DEPRECATED,
  };

  const loadingSelector = createLoadingSelector(UPLOAD_FILE_REQUEST);

  const mapStateToProps = createStructuredSelector({
    isFileUploading: loadingSelector,
    error: selectUploadErrorMessage,
    failedUpload: selectFailedFilesUpload,
  });

  hoistStatics(WithFileUpload, ComponentToWrap);

  return connect(mapStateToProps, mapDispatchToProps)(WithFileUpload);
};
