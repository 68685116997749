import invariant from 'invariant';

const getLines = (str: string, charsPerLine?: number): string[] => {
  if (charsPerLine) {
    invariant(Number.isInteger(charsPerLine), 'charsPerLine should be integer');
    invariant(charsPerLine > 0, 'charsPerLine should be more than 0');
  }

  let lines = str.split(/\r\n|\r|\n/);
  if (charsPerLine) {
    lines = lines.reduce((result: string[], line) => {
      if (line.length > charsPerLine) {
        const lineChunks: string[] = [];
        for (
          var i = 0, charsLength = line.length;
          i < charsLength;
          i += charsPerLine
        ) {
          lineChunks.push(line.substring(i, i + charsPerLine));
        }

        return result.concat(lineChunks);
      }

      return result.concat(line);
    }, []);
  }

  return lines;
};

export const countLines = (str: string, charsPerLine?: number): number => {
  const lines = getLines(str, charsPerLine);
  return lines.length;
};

export const countCharsOccupied = (
  str: string,
  charsPerLine: number
): number => {
  const lines = getLines(str, charsPerLine);

  if (lines.length === 1) {
    return str.length;
  }

  const lastLineChars = lines[lines.length - 1].length;
  const linesChars = (lines.length - 1) * charsPerLine;
  const charsOccupied = linesChars + lastLineChars;
  return charsOccupied;
};
