import React, { FC } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import cn from 'classnames';

import styles from './Modal.module.scss';

export interface BaseModalProps
  extends Partial<Omit<ReactModalProps, 'isOpen'>> {
  /**
   * Flag to hide/show modal
   */
  opened?: boolean;
  /**
   * Modal size variants
   */
  size?: 'md' | 'lg';
}

/**
 * Renders modal with default styles
 */
export const BaseModal: FC<BaseModalProps> = ({
  opened = false,
  size = 'md',
  children,
  className,
  overlayClassName,
  ...props
}) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={opened}
      className={cn(styles.modal, styles[size], className)}
      overlayClassName={cn(styles.modalOverlay, overlayClassName)}
      {...props}
    >
      {children}
    </ReactModal>
  );
};
