import {
  readDepartmentsReq,
  readInstituteReq,
  updateEmailReq,
  updateInstitute as updateInstituteRequest,
  updatePasswordReq,
  updateUserRequest,
  createInstituteContact as createInstituteContactRequest,
  deleteInstituteContact as deleteInstituteContactRequest,
  listMaterialCompanies as listMaterialCompaniesReq,
} from 'api/v1';
import {
  createSurgeonPin as createSurgeonPinRequest,
  resetSurgeonPin as resetSurgeonPinRequest,
  viewSurgeonPin as viewSurgeonPinRequest,
} from 'api/v2';
import { push } from 'connected-react-router';
import { FORBIDDEN } from 'http-status-codes';
import { HOME } from 'scenes/routes.enum';
import { readCurrentUserDataSuccess } from 'store/modules/auth';
import { makeThunk } from 'store/utils';
import {
  makeThunkFromTypes,
  makeThunkFromTypeString,
} from 'store/utils/make-thunk';
import i18n from 'translations/i18n-instance';
import * as actions from './actions';
import * as actionTypes from './types';

export const readInstitute = makeThunkFromTypes(readInstituteReq, {
  request: actionTypes.READ_INSTITUTE_REQUEST,
  success: actionTypes.READ_INSTITUTE_SUCCESS,
  failure: actionTypes.READ_INSTITUTE_FAILURE,
});

export const updateInstitute = (id, data) => async (dispatch) => {
  try {
    dispatch(actions.updateInstituteRequest(id, data));
    const institute = await updateInstituteRequest(id, data);
    dispatch(actions.updateInstituteSuccess(institute));
  } catch (error) {
    dispatch(actions.updateInstituteFailure(error));
  }
};

export const updatePassword = (data, withLogin) => async (dispatch) => {
  try {
    dispatch(actions.updatePasswordRequest(data));

    if (data?.newPassword === data?.currentPassword) {
      const errorMessage = i18n.t('errors:currentAndNewPasswordsAreTheSame');
      return dispatch(actions.updatePasswordFailure(errorMessage));
    }

    const response = await updatePasswordReq(data);

    dispatch(actions.updatePasswordSuccess(response));
    if (withLogin) dispatch(push(HOME));
  } catch (error) {
    const errorMessage =
      error.statusCode === FORBIDDEN
        ? i18n.t('errors:currentPasswordWasIncorrectPleaseTryAgain')
        : i18n.t('errors:anErrorOccurredWhileUpdatingYourPassword');

    dispatch(actions.updatePasswordFailure(errorMessage, error));
  }
};

export const createSurgeonPin = (data) => async (dispatch) => {
  try {
    dispatch(actions.createSurgeonPinRequest());
    const user = await createSurgeonPinRequest(data);
    dispatch(actions.createSurgeonPinSuccess(user));
  } catch (error) {
    const message = i18n.t('errors:errorOccurredWhileCreatingSurgeonPin');
    dispatch(actions.createSurgeonPinFailure(error, message));
  }
};

export const resetSurgeonPin = (data) => async (dispatch) => {
  try {
    dispatch(actions.resetSurgeonPinRequest());
    const user = await resetSurgeonPinRequest(data);
    dispatch(actions.resetSurgeonPinSuccess(user));
    dispatch(actions.resetViewSurgeonPin());
  } catch (error) {
    const message = i18n.t('errors:errorOccurredAttemptingToResetSurgeonPin');
    dispatch(actions.resetSurgeonPinFailure(error, message));
  }
};

export const viewSurgeonPin = (data) => async (dispatch) => {
  try {
    dispatch(actions.viewSurgeonPinRequest());
    const response = await viewSurgeonPinRequest(data);
    dispatch(actions.viewSurgeonPinSuccess(response));
  } catch (error) {
    const message = i18n.t('errors:errorOccurredAttemptingToViewSurgeonPin');
    dispatch(actions.viewSurgeonPinFailure(error, message));
  }
};

export const updateUserEmail = makeThunk(updateEmailReq, {
  request: actions.updateEmailRequest,
  success: actions.updateEmailSuccess,
  failure: actions.updateEmailFailure,
});

export const updateUserProfile = (id, input) => async (dispatch) => {
  try {
    dispatch(actions.updateProfileRequest(id, input));
    const user = await updateUserRequest(id, input);

    dispatch(actions.updateProfileSuccess(user));

    dispatch(readCurrentUserDataSuccess(user));
  } catch (error) {
    const message =
      typeof error.message === 'string' && error.message !== '{}'
        ? error.message
        : i18n.t('failedToMakeRequest');

    dispatch(actions.updateProfileFailure(error, message));
  }
};

export const updateUserSurgeonPin = () => {};

export const readDepartments = makeThunk(readDepartmentsReq, {
  request: actions.readDepartmentsRequest,
  success: actions.readDepartmentsSuccess,
  failure: actions.readDepartmentsFailure,
});

export const {
  thunk: createInstituteContact,
  asyncActionTypes: {
    request: CREATE_INSTITUTE_CONTACT_REQUEST,
    success: CREATE_INSTITUTE_CONTACT_SUCCESS,
    failure: CREATE_INSTITUTE_CONTACT_FAILURE,
  },
} = makeThunkFromTypeString(
  createInstituteContactRequest,
  'CREATE_INSTITUTE_CONTACT'
);

export const {
  thunk: deleteInstituteContact,
  asyncActionTypes: {
    request: DELETE_INSTITUTE_CONTACT_REQUEST,
    success: DELETE_INSTITUTE_CONTACT_SUCCESS,
    failure: DELETE_INSTITUTE_CONTACT_FAILURE,
  },
} = makeThunkFromTypeString(
  deleteInstituteContactRequest,
  'DELETE_INSTITUTE_CONTACT'
);

export const {
  thunk: listMaterialCompanies,
  asyncActionTypes: {
    request: READ_MATERIAL_COMPANIES_REQUEST,
    success: READ_MATERIAL_COMPANIES_SUCCESS,
    failure: READ_MATERIAL_COMPANIES_FAILURE,
  },
} = makeThunkFromTypeString(
  listMaterialCompaniesReq,
  'READ_INSTITUTE_CONTACTS'
);
