import { MaterialsGroupType } from 'common-types/materials';

export const STATIC_MATERIALS_GROUPS = [
  MaterialsGroupType.ADDITIONAL_EQUIPMENT,
  MaterialsGroupType.GENERAL_INSTRUMENTS,
];

export const getStaticGroup = (groups, groupType) =>
  groups.find((group) => group.type === groupType);

export const getGeneralInstrumentsGroup = (groups) =>
  getStaticGroup(groups, MaterialsGroupType.GENERAL_INSTRUMENTS);

export const getAdditionalEquipmentGroup = (groups) =>
  getStaticGroup(groups, MaterialsGroupType.ADDITIONAL_EQUIPMENT);

export const createAdditionalEquipmentInput = (t) =>
  Object.freeze({
    name: t('extraEquipment'),
    type: MaterialsGroupType.ADDITIONAL_EQUIPMENT,
  });

export const createGeneralInstrumentsInput = (t) =>
  Object.freeze({
    name: t('Instruments'),
    type: MaterialsGroupType.GENERAL_INSTRUMENTS,
  });

const CREATE_STATIC_GROUP_FN_MAP = {
  [MaterialsGroupType.ADDITIONAL_EQUIPMENT]: createAdditionalEquipmentInput,
  [MaterialsGroupType.GENERAL_INSTRUMENTS]: createGeneralInstrumentsInput,
};

export const getCreateStaticGroupFn = (groupType: MaterialsGroupType) => {
  return CREATE_STATIC_GROUP_FN_MAP[groupType];
};

export const isStaticGroup = (groupId) =>
  STATIC_MATERIALS_GROUPS.includes(groupId);
