import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';

import { TranslatableString as TranslatableStringI } from 'common-types/common';
import { ListElement } from 'components/List';
import { TranslatableString } from 'components/TranslatableString';
import { Multiline } from 'components/Multiline';
interface Props {
  content: TranslatableStringI | string | null;
}

export const PostOPNotes = ({ content }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ListElement
      title={
        <>
          <Icon name="reports" className="text-primary mr-05" />
          <strong>{t('postOPNotes')}</strong>
        </>
      }
    >
      <TranslatableString string={content || ''}>
        {(translatedContent) =>
          translatedContent ? (
            <Multiline>{translatedContent}</Multiline>
          ) : (
            t('N/A')
          )
        }
      </TranslatableString>
    </ListElement>
  );
};
