import React, {
  ClipboardEvent,
  ComponentType,
  useCallback,
  useState,
} from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { ATTACHMENTS } from 'app-constants/analytics';
import { AllowedMimeType } from 'app-constants/files';
import cn from 'classnames';
import { isPasteFilesEvent } from 'utils/is-paste-files-event';
import { AllowedFilesInfo } from '../AttachmentsForm/AllowedFilesInfo';
import { UploadDropzone } from '../AttachmentsForm/UploadDropzone';
import { AttachmentsListSelectable } from '../AttachmentsList/AttachmentsListSelectable';
import classes from './AttachmentsManager.module.scss';
import { Attachment } from 'common-types/attachments';
import { ModalHeader } from 'components/Modal';
import { PanelBody, Panel, PanelActions } from 'components/Panel';
import { Text } from 'components/Typography/Text';
import { Button } from 'components/Button';
import { UploadFilesButton } from 'components/UploadFilesButton';
import { Icon } from 'components/Icon';
import { MessageBlock } from 'components/Message';

interface Props {
  accept: string;
  availableAttachments?: Attachment[];
  canSave?: boolean;
  id: string;
  localAttachments?;
  selectedAttachmentsIds?;
  maxSelect: number;
  title: string;

  videoAllowed: boolean;
  imagesAllowed: boolean;
  pdfAllowed: boolean;
  hasFilesToUpload?: boolean;

  onAttachmentSelect: (
    selectedId: number | string,
    type: AllowedMimeType
  ) => void;
  onCancel: () => void;
  onFilesInput: (files: File[]) => void;
  onSave: () => void;
}

export const AttachmentsManagerView: ComponentType<Props> = ({
  accept,
  availableAttachments = [],
  canSave = false,
  id,
  localAttachments = [],
  maxSelect,
  selectedAttachmentsIds = [],
  title,
  hasFilesToUpload = false,

  videoAllowed,
  imagesAllowed,
  pdfAllowed,

  onAttachmentSelect,
  onCancel,
  onFilesInput,
  onSave,
}) => {
  const { t } = useTranslation();
  const [showDropzone, setShowDropzone] = useState<boolean>(false);

  const hasMaxSelect: boolean = Boolean(
    maxSelect && typeof maxSelect === 'number' && maxSelect !== Infinity
  );

  function handleShowDropzone() {
    setShowDropzone(true);
  }

  function handleHideDropzone() {
    setShowDropzone(false);
  }

  async function handleDropzoneUpload(files) {
    onFilesInput(files);
    setShowDropzone(false);
  }

  const onFilesInputHandler = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      onFilesInput([...(ev.currentTarget.files || [])]);
    },
    [onFilesInput]
  );

  async function handlePasteFiles(event: ClipboardEvent<HTMLDivElement>) {
    if (!isPasteFilesEvent(event)) return;
    event.preventDefault();

    const { files } = event.clipboardData;
    onFilesInput([...files]);

    ReactGA.event({
      category: ATTACHMENTS,
      action: 'From Clipboard',
    });
  }

  return (
    <Panel
      shadow={1}
      role="dialog"
      fullPage="max"
      tabIndex={-1}
      onPaste={handlePasteFiles}
    >
      <ModalHeader title={title} onClose={onCancel} />
      <PanelBody
        onDragEnter={handleShowDropzone}
        className="relative"
        style={{ minHeight: '250px' }}
      >
        {hasMaxSelect && (
          <MessageBlock type="info" inline>
            {t('maximumFilesToSelect')}: {maxSelect}
          </MessageBlock>
        )}
        <div>
          <Text variant="heading-md">{t('uploadedFiles')}</Text>
          {Boolean(localAttachments?.length) ? (
            <AttachmentsListSelectable
              attachments={localAttachments}
              colBasis="33.33%"
              onSelect={onAttachmentSelect}
              selected={selectedAttachmentsIds}
            />
          ) : (
            <>
              <AllowedFilesInfo
                className="mb-1"
                video={videoAllowed}
                images={imagesAllowed}
                pdf={pdfAllowed}
              ></AllowedFilesInfo>
              <p>{t('noAttachmentsUploaded')}</p>
            </>
          )}
        </div>
        <div>
          <Text variant="heading-md">{t('attachedFiles')}</Text>
          {Boolean(availableAttachments?.length) ? (
            <AttachmentsListSelectable
              attachments={availableAttachments}
              colBasis="33.33%"
              onSelect={onAttachmentSelect}
              selected={selectedAttachmentsIds}
            />
          ) : (
            <p>{t('noAttachmentsUploaded')}</p>
          )}
        </div>

        <UploadDropzone
          className={cn(classes.dropzone, {
            [classes.show]: showDropzone,
          })}
          onUpload={handleDropzoneUpload}
          onCancel={handleHideDropzone}
        />
      </PanelBody>
      <PanelActions>
        {Boolean(onCancel) && (
          <Button
            type="button"
            variant="secondary"
            className="mr-1"
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
        )}

        <UploadFilesButton
          id={id}
          accept={accept}
          maxFilesAllowed={maxSelect}
          multiple
          variant="secondary"
          className="mr-1"
          onChange={onFilesInputHandler}
        >
          {t('uploadFiles')}
        </UploadFilesButton>

        <Button type="button" disabled={!canSave} onClick={onSave}>
          {hasFilesToUpload && <Icon name="upload" gapRight />}
          {t('save')}
        </Button>
      </PanelActions>
    </Panel>
  );
};

AttachmentsManagerView.displayName = 'AttachmentsManagerView';
