import invariant from 'invariant';
import { camel } from 'case';
import { mergeState } from 'store/utils';

const shallowSetter = (prevState, nextState) => {
  const next = { ...prevState };
  Object.keys(nextState).forEach((id) => {
    const prevEntity = prevState[id];
    const newEntity = nextState[id];

    if (!prevEntity) {
      next[id] = newEntity;
    } else {
      Object.keys(newEntity).forEach((prop) => {
        prevEntity[prop] = newEntity[prop];
      });
    }
  });
  return next;
};
const deepSetter = (state, newState) => ({
  ...mergeState(state, newState),
});

export const makeSetReducer = (
  { propName, deep = false, customSetter },
  extendReducer
) => {
  invariant(typeof propName === 'string', 'propName is required');
  const setter = customSetter || deep ? deepSetter : shallowSetter;
  const setReducer = function (state = {}, action) {
    const { payload } = action;
    const entities = payload?.entities?.[propName];
    const newState = entities ? setter(state, entities) : state;

    if (typeof extendReducer === 'function') {
      return extendReducer(newState, action);
    }

    return newState;
  };

  // Older browsers (looking at IE11) do not support
  // defining property on the function object
  try {
    // Define reducer's name for debugging purposes
    Object.defineProperty(setReducer, 'name', {
      writable: true,
      value: camel(`${propName}_SetReducer`),
    });
  } catch (error) {}

  return setReducer;
};
