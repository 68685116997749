import React, { FC, memo, useMemo } from 'react';
import {
  DatePicker as AtlaskitDatePicker,
  TimePicker as AtlaskitTimePicker,
} from '@atlaskit/datetime-picker';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT_MOMENT } from 'app-constants';
import cn from 'classnames';
import styles from './DatePicker.module.scss';
import {
  useDatePickerState,
  preventSubmitOnEnter,
  timesArray,
  ChangeEventLike,
} from './utils';
import { ErrorMessage, Label } from 'components/Form/FormInput';
import { Col, Row } from 'components/Grid';

interface DateTimePickerProps {
  /**
   * Date input label
   */
  label?: string;
  /**
   * Html element name
   */
  name?: string;
  /**
   * Date picker value
   */
  value?: string | number | Date;
  /**
   * Date picker error
   */
  error?: boolean | string | Record<string, string>;
  /**
   * Flog to enable/disable date picker
   */
  disabled?: boolean;
  /**
   * Flag to change onChange event value from string to ChangeEvent like
   */
  withEvent?: boolean;
  /**
   * Date picker select classname
   */
  className?: string;
  /**
   * Change event handler
   */
  onChange?: (value?: string | ChangeEventLike) => void;
  /**
   * Blur event handler
   */
  onBlur?: () => void;
}

/**
 * Render date picker component with date and time inputs
 */
export const DateTimePicker: FC<DateTimePickerProps> = memo(
  function DateTimePicker({
    label,
    name,
    value: rawValue,
    error,
    withEvent,
    disabled,
    onChange,
    onBlur,
  }) {
    const { t } = useTranslation();

    const { date, time, onDateChange, onTimeChange } = useDatePickerState({
      name,
      rawValue,
      withEvent,
      onChange,
    });

    const { datePickerSelectProps, timePickerSelectProps } = useMemo(
      () => ({
        datePickerSelectProps: {
          classNamePrefix: 'datePicker',
          className: cn(styles.datePicker, error && styles.error),
        },
        timePickerSelectProps: {
          classNamePrefix: 'timePicker',
          className: cn(styles.timePicker, error && styles.error),
        },
      }),
      [error]
    );

    return (
      <div onKeyDown={preventSubmitOnEnter}>
        <Row>
          <Col>
            {label !== '' && <Label>{label || t('Date')}</Label>}

            <AtlaskitDatePicker
              locale="en-GB"
              name="date"
              appearance="subtle"
              spacing="compact"
              hideIcon
              selectProps={datePickerSelectProps}
              isDisabled={disabled}
              isInvalid={!!error}
              placeholder="dd.mm.yyyy"
              dateFormat={DATE_FORMAT_MOMENT}
              onChange={onDateChange}
              value={date}
              onBlur={onBlur}
              weekStartDay={1}
            />
          </Col>
          <Col>
            {label !== '' && <Label>{t('Time')}</Label>}

            <AtlaskitTimePicker
              name="time"
              appearance="subtle"
              spacing="compact"
              isDisabled={disabled || !date}
              timeIsEditable
              hideIcon
              placeholder="--:-- --"
              timeFormat="HH:mm"
              times={timesArray}
              selectProps={timePickerSelectProps}
              isInvalid={!!error}
              value={time}
              onChange={onTimeChange}
              onBlur={onBlur}
            />
          </Col>
        </Row>

        {error && typeof error === 'string' && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
      </div>
    );
  }
);
