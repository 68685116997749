import {
  Language,
  TranslatableString as TranslatableStringType,
} from 'common-types/common';
import { FC, ReactNode } from 'react';
import { useTranslatableString } from 'utils';

/**
 * Usage example:
 * ```jsx
 * const label = { en: "Scalpel", de: "Skalpel" };
 *
 * return (<TranslatableString string={label}>);
 * ```
 *
 * or with children as render prop:
 *
 * ```jsx
 * return (
 *   <TranslatableString string={label}>
 *    {(translatedString) => <span>{translatedString}</span>}
 *   </TranslatableString>
 * );
 * ```
 */

type renderFn = (string: string | null, result: any) => any;

interface Props {
  children?: renderFn;
  empty?: ReactNode;
  allowFallback?: boolean;
  lang?: Language;
  render?: renderFn;
  string: string | TranslatableStringType;
}

export const TranslatableString: FC<Props> = ({
  children,
  render,
  string,
  empty = null,
  lang = 'en' as Language,
  allowFallback = true,
}) => {
  const translationResult = useTranslatableString(string, {
    lang,
    allowFallback,
  });

  const translatedString = translationResult?.translation ?? null;
  const renderProp = render || children;

  if (typeof renderProp === 'function') {
    return renderProp(translatedString, translationResult);
  }

  return translatedString || empty;
};
