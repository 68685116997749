import { createSelector } from 'reselect';
import { formatAddress } from 'utils';
import { selectAddresses, selectInstitutes, selectUsers } from './selectors';
import { selectUserInstituteId } from 'store/modules/auth';
import {
  createEntitySelector,
  createStateGetter,
  createPropsGetter,
} from 'store/utils';

export const selectUserInstitute = createEntitySelector(
  selectInstitutes,
  selectUserInstituteId
);

export const selectUserInstituteAddress = createSelector(
  [selectUserInstitute, selectAddresses],
  (institute, addresses) => {
    if (!institute || !addresses) return null;
    const instituteAddress = institute && addresses[institute.address];
    return instituteAddress;
  }
);

export const selectUserInstituteAddressString = createSelector(
  selectUserInstituteAddress,
  formatAddress
);

export const selectUserInstituteCity = createSelector(
  [selectUserInstituteAddress],
  createStateGetter('city')
);

export const selectUserInstituteImage = createSelector(
  [selectUserInstitute],
  createStateGetter('imageUrl')
);

export const selectUserByIdProp = createEntitySelector(
  selectUsers,
  createPropsGetter('id')
);

export const selectUserById = (userId) => {
  const userSelector = createSelector(selectUsers, createStateGetter(userId));
  return userSelector;
};
