import { schema } from 'normalizr';
import { addressSchema } from './address';
import { attachmentsSchema } from './file';
import { debriefingSchema, reportSchema } from './history';
import { departmentSchema, instituteSchema } from './institutes';
import { libraryElementsSchema } from './library-elements';
import {
  debriefingQuestionSchema,
  operationTemplateSchema,
  timeoutQuestionSchema,
} from './templates';
import { userSchema } from './users';
import { isProductType } from 'utils/get-env';

export const criteriaSchema = new schema.Entity('criteria');

const assessmentFormSchema = new schema.Entity('assessmentForms', {
  form: {
    general: {
      organizationEfficiency: { criteria: [criteriaSchema] },
      professionalBehavior: { criteria: [criteriaSchema] },
      overallImpression: { criteria: [criteriaSchema] },
    },
    dops: {
      preparation: { criteria: [criteriaSchema] },
      executionTechnicalSkills: { criteria: [criteriaSchema] },
      safetySterility: { criteria: [criteriaSchema] },
    },
  },
});

export const assessmentSubmissionsSchema = new schema.Entity(
  'assessmentsSubmissions',
  {
    assessmentForm: assessmentFormSchema,
  }
);

/**
 * @deprecated Use assessmentSchema from src/api/v1/schemas/assessments.ts instead.
 */
export const legacyAssessmentSchema = new schema.Entity(
  'assessments',
  {
    assessmentForm: assessmentFormSchema,
    assignee: userSchema,
    department: departmentSchema,
    institute: instituteSchema,
    lead: userSchema,
    senior: userSchema,
    submissions: [assessmentSubmissionsSchema],
    verifier: userSchema,
    operationSteps: [libraryElementsSchema],
  },
  {
    processStrategy(value) {
      if (value.leadId && !value.lead) value.lead = value.leadId;
      if (value.assigneeId && !value.assignee)
        value.assignee = value.assigneeId;
      if (value.seniorId && !value.senior) value.senior = value.seniorId;
      return value;
    },
  }
);

/**
 * @deprecated Use assessmentListSchema from src/api/v1/schemas/assessments.ts instead.
 */
export const legacyAssessmentListSchema = new schema.Array(
  legacyAssessmentSchema
);

export const diagnosisSchema = new schema.Entity('diagnoses');
export const diagnosesListSchema = new schema.Array(diagnosisSchema);

export const patientSchema = isProductType('vet')
  ? new schema.Entity('patients')
  : new schema.Entity(
      'patients',
      {
        address: addressSchema,
        risks: [libraryElementsSchema],
      },
      {
        processStrategy(patient) {
          if (patient.isFake) {
            const { isFake, id } = patient;
            return { id, isFake };
          }
          return patient;
        },
      }
    );

export const vitalInformationSchema = new schema.Entity('vitalInformation');

export const operationSchema = new schema.Entity('operations', {
  assessments: legacyAssessmentListSchema,
  assistant: userSchema,
  attachments: [attachmentsSchema],
  debriefing: debriefingSchema,
  debriefings: [debriefingQuestionSchema],
  diagnoses: [diagnosisSchema],
  diagnosesIds: [diagnosisSchema],
  equipment: [libraryElementsSchema],
  implants: [libraryElementsSchema],
  instruments: [libraryElementsSchema],
  lead: userSchema,
  operationTemplate: operationTemplateSchema,
  participants: [userSchema],
  patient: patientSchema,
  report: reportSchema,
  timeouts: [timeoutQuestionSchema],
  vitalInformation: vitalInformationSchema,
  vetPatient: patientSchema,
});

legacyAssessmentSchema.define({ operation: operationSchema });

export const operationsListSchema = new schema.Array(operationSchema);
