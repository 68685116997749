import classnames from 'classnames';
import React, { ComponentType, HTMLAttributes } from 'react';
import styles from './icomoon-impedia/style.module.css';

interface Props {
  name?: string;
  icon?: string;
  gapRight?: boolean;
}

export const Icon: ComponentType<Props & HTMLAttributes<HTMLSpanElement>> = (
  props
) => {
  const { className, name, icon, gapRight, ...restProps } = props;
  const iconName = icon || name;
  if (!iconName) return null;

  return (
    <i
      className={classnames('Icon', className, styles.icon, {
        [styles[iconName]]: iconName,
        'mr-05': gapRight,
      })}
      {...restProps}
    />
  );
};

Icon.displayName = 'Icon';
