import i18n from 'translations/i18n-instance';

export const TEMPLATE_ACCESS_LEVELS = Object.freeze({
  private: 'private',
  public: 'public',
  institute: 'institute',
});

export const TEMPLATE_ACCESS_LIST = (t = i18n.t) => [
  { label: t('Public'), value: TEMPLATE_ACCESS_LEVELS.public },
  { label: t('Private'), value: TEMPLATE_ACCESS_LEVELS.private },
  // { label: t('Institute only'), value: TEMPLATE_ACCESS_LEVELS.institute },
];
