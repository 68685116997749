import * as APIv1 from 'api/v1';
import {
  DOCUMENT_SIZE_LIMIT,
  IMAGE_SIZE_LIMIT,
  VIDEO_SIZE_LIMIT,
} from 'app-constants/files';
import axios from 'axios';
import { Attachment, LocalAttachment } from 'common-types/attachments';
import { ThunkDispatch } from 'common-types/common';
import { NormalizedResponse } from 'common-types/normalize';
import { nanoid as uuid } from 'nanoid';
import { compose, Dispatch } from 'redux';
import { makeThunkFromTypeString } from 'store/utils/make-thunk';
import { validateFileSize } from 'utils';
import {
  selectUploadsMap,
  uploadFileFailure,
  uploadFileProgress,
  uploadFileRequest,
  uploadFileSuccess,
} from '../files-upload';
import {
  createAttachmentFailure,
  createAttachmentRequest,
  createAttachmentSuccess,
} from './actions';

export function createLocalAttachment(file: File): LocalAttachment {
  const { type, size, name } = file;
  const fileUrl = URL.createObjectURL(file);
  const localId = uuid();

  return {
    file,
    fileUrl,
    id: localId,
    local: true,
    mimeType: type,
    name,
    size,
  };
}

function uploadAttachment(input: LocalAttachment) {
  return async function uploadAttachmentThunk(
    dispatch: Dispatch
  ): Promise<NormalizedResponse | null> {
    const { name, file, id, description } = input;
    const filePayload = { id, name };

    try {
      const formData = new FormData();
      name && formData.append('name', name);
      description && formData.append('description', description);
      file && formData.append('file', file);

      dispatch(uploadFileRequest({ ...filePayload, file }));

      if (
        !validateFileSize(file, {
          images: IMAGE_SIZE_LIMIT,
          pdf: DOCUMENT_SIZE_LIMIT,
          video: VIDEO_SIZE_LIMIT,
        })
      ) {
        throw new Error('File is too large');
      }

      const cancelSource = axios.CancelToken.source();
      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: cancelSource.token,
        onUploadProgress: (ProgressEvent) => {
          let progress = 0;
          const totalLength = ProgressEvent.lengthComputable
            ? ProgressEvent.total
            : ProgressEvent.target.getResponseHeader('content-length') ||
              ProgressEvent.target.getResponseHeader(
                'x-decompressed-content-length'
              );
          if (totalLength !== null) {
            progress = Math.round((ProgressEvent.loaded * 100) / totalLength);
          }

          return dispatch(
            uploadFileProgress({
              id,
              progress,
              cancel: cancelSource.cancel,
            })
          );
        },
      };

      const normalizedResponse = await APIv1.createAttachment(
        formData,
        requestConfig
      );
      dispatch(uploadFileSuccess(filePayload));
      return normalizedResponse;
    } catch (error) {
      const errorMessage =
        typeof error?.message === 'string' ? error.message : 'Upload Error';
      dispatch(uploadFileFailure(filePayload, errorMessage));
      return null;
    }
  };
}

export function createAttachment(input: LocalAttachment) {
  return async function createAttachmentThunk(
    dispatch: ThunkDispatch
  ): Promise<Attachment | undefined> {
    const { id, name } = input;
    const filePayload = {
      id,
      name,
    };

    try {
      dispatch(createAttachmentRequest(filePayload));
      const normalizedResponse = await dispatch(uploadAttachment(input));
      if (!normalizedResponse) throw new Error('Upload failed');
      dispatch(createAttachmentSuccess(normalizedResponse));
      return normalizedResponse.input;
    } catch (error) {
      dispatch(
        createAttachmentFailure('Failed to upload the file', error, filePayload)
      );
    }
  };
}

export const uploadAttachments = (files: File[]) => async (
  dispatch: ThunkDispatch
): Promise<Attachment[] | null> => {
  try {
    dispatch({ type: 'UPLOAD_ATTACHMENTS_REQUEST' });
    const fileHandler = compose(
      dispatch,
      uploadAttachment,
      createLocalAttachment
    );
    const requests = files.map(async (file) => {
      const result = (await fileHandler(file)) as NormalizedResponse | null;
      if (!result) return null;
      return result.input;
    });
    const responses = await Promise.all(requests);
    dispatch({ type: 'UPLOAD_ATTACHMENTS_SUCCESS' });
    return responses;
  } catch (error) {
    dispatch({ type: 'UPLOAD_ATTACHMENTS_FAILURE' });
    throw error;
  }
};

const {
  asyncActionTypes: {
    request: UPDATE_ATTACHMENT_REQUEST,
    success: UPDATE_ATTACHMENT_SUCCESS,
    failure: UPDATE_ATTACHMENT_FAILURE,
  },
  thunk: updateAttachment,
} = makeThunkFromTypeString(APIv1.updateAttachment, 'updateAttachment');

export const retryFileUpload = ({ id }) => async (dispatch, getState) => {
  const state = getState();
  const uploads = selectUploadsMap(state);
  const upload = uploads[id];
  if (!upload?.file) return;
  return dispatch(uploadAttachment(upload));
};

export {
  UPDATE_ATTACHMENT_REQUEST,
  UPDATE_ATTACHMENT_SUCCESS,
  UPDATE_ATTACHMENT_FAILURE,
  updateAttachment,
};
