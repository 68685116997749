import * as APIv1 from 'api/v1';
import * as APIv2 from 'api/v2';
import makeThunk, { makeThunkFromTypeString } from 'store/utils/make-thunk';
import actions from 'store/modules/users/actions';

export const {
  thunk: searchUsers,
  asyncActionTypes: {
    request: SEARCH_USERS_REQUEST,
    success: SEARCH_USERS_SUCCESS,
    failure: SEARCH_USERS_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.searchUsers, 'searchUsers');

export const {
  thunk: readUser,
  asyncActionTypes: {
    request: READ_USER_REQUEST,
    success: READ_USER_SUCCESS,
    failure: READ_USER_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.readUser, 'readUser');

/**
 * Read user's operations count
 */
export const readNotApprovedOperationsCount = makeThunk(
  () => APIv2.readOperationsCount({ approvedByLead: false }),
  {
    request: actions.readNotApprovedOperationsCountRequest,
    success: actions.readNotApprovedOperationsCountSuccess,
    failure: actions.readNotApprovedOperationsCountFailure,
  }
);

export const {
  thunk: updateUser,
  asyncActionTypes: {
    request: UPDATE_USER_REQUEST,
    success: UPDATE_USER_SUCCESS,
    failure: UPDATE_USER_FAILURE,
  },
} = makeThunkFromTypeString(APIv1.updateUser, 'updateUser');
