export const SURGEON_PIN_BUTTONS = [
  {
    label: 'A',
    value: '1',
    arrow: '▲',
    key: 'ArrowUp',
  },
  {
    label: 'B',
    value: '2',
    arrow: '▼',
    key: 'ArrowDown',
  },
  {
    label: 'C',
    value: '3',
    arrow: '◀',
    key: 'ArrowLeft',
  },
  {
    label: 'D',
    value: '4',
    arrow: '▶',
    key: 'ArrowRight',
  },
];
