import cn from 'classnames';
import React, { forwardRef } from 'react';
import classes from './Button.module.scss';
import { IconButton, IconButtonProps } from './IconButton';

export interface IconButtonWithBadgeProps
  extends IconButtonProps,
    Record<string, any> {
  /**
   * Badge component to render
   */
  badge?: React.ReactNode;
  /**
   * Flag whether to render badge
   */
  showBadge?: boolean;
}

/**
 * Render icon button component with badge
 */
export const IconButtonWithBadge = forwardRef<
  HTMLElement,
  IconButtonWithBadgeProps
>(function IconButtonWithBadge(
  {
    className,
    badge = <span className={classes.badge} />,
    showBadge = false,
    ...props
  },
  ref
) {
  return (
    <IconButton
      ref={ref}
      className={cn(classes.buttonWithBadge, className)}
      {...props}
    >
      {showBadge && badge}
    </IconButton>
  );
});
