import cn from 'classnames';
import React, { FC, memo, useEffect, useRef, useState } from 'react';

import styles from './OperationPinInput.module.scss';
import { SURGEON_PIN_BUTTONS } from './utils';

import {
  FormPasswordValidation,
  FormValidationOption,
} from 'forms/FormPasswordValidation';
import { Label } from '../FormInput/Label/Label';
import { InputBase } from '../Input';
import { IconButton } from 'components/Button';
import { Text } from 'components/Typography/Text';

interface OperationPinInputProps {
  type?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  validationOptions?: FormValidationOption[];
  className?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export const OperationPinInput: FC<OperationPinInputProps> = memo(
  function SurgeonPasswordInput({
    name,
    value,
    defaultValue = '',
    label,
    placeholder,
    validationOptions = [],
    disabled,
    className,
    onChange,
    ...props
  }) {
    const [activeButton, setActiveButton] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>(value || defaultValue);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (value !== undefined) {
        setInputValue(value);
      }
    }, [value]);

    const handleChange = (value: string) => {
      if (onChange) {
        onChange(value);
      } else {
        setInputValue(value);
      }
    };

    const onResetHandler = () => {
      handleChange('');
    };

    const onClickHandler = ({
      currentTarget,
    }: React.MouseEvent<HTMLButtonElement>) => {
      handleChange(`${inputValue}${currentTarget.value}`);
      inputRef.current?.focus();
    };

    const onKeyDownHandler = ({
      key,
      metaKey,
    }: React.KeyboardEvent<HTMLInputElement>) => {
      const button = SURGEON_PIN_BUTTONS.find(
        (button) => button.label === key.toUpperCase() || button.key === key
      );

      if (button && !metaKey) {
        handleChange(`${inputValue}${button.value}`);
        setActiveButton(button.value);
      }

      if (key === 'Backspace') {
        handleChange(inputValue.slice(0, inputValue.length - 1));
      }

      if (key === 'Escape') {
        onResetHandler();
      }
    };

    const onKeyUpHandler = () => {
      if (activeButton) {
        setActiveButton(null);
      }
    };

    return (
      <div className={cn(styles.root, className)}>
        {label && <Label>{label}</Label>}

        <InputBase
          ref={inputRef}
          placeholder={placeholder}
          name={name}
          value={inputValue}
          onKeyDown={onKeyDownHandler}
          onKeyUp={onKeyUpHandler}
          autoComplete="off"
          className="mb-1"
          disabled={disabled}
          append={
            value && !disabled ? (
              <IconButton
                icon="validation-error"
                size="md"
                onClick={onResetHandler}
              />
            ) : undefined
          }
          {...props}
        />

        {validationOptions.length > 0 && (
          <FormPasswordValidation
            value={inputValue}
            options={validationOptions}
            className="mb-1"
          />
        )}

        <div className={styles.buttons}>
          {SURGEON_PIN_BUTTONS.map(({ label, value, arrow }, index) => (
            <button
              type="button"
              key={value}
              name={name}
              value={value}
              data-pin-index={index}
              data-label={label}
              onClick={onClickHandler}
              disabled={disabled}
              tabIndex={-1}
              className={cn(
                styles.button,
                activeButton === value && styles.active
              )}
            >
              <Text
                component="span"
                variant="heading-lg"
                color="inherit"
                weight="bold"
              >
                {label}
              </Text>

              <Text component="span" color="inherit">
                {arrow}
              </Text>
            </button>
          ))}
        </div>
      </div>
    );
  }
);
