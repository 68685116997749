import React, { PureComponent } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { get } from 'lodash';
import { Gallery } from 'components';

/*
  filesPropName - name of property to select array of files from props of the wrapped component
*/

export default (customParams) => {
  if (!customParams.filesPropName) {
    throw new TypeError('name of property to select files is missing');
  }
  return (ComponentToWrap) => {
    class WithGallery extends PureComponent {
      state = {
        isGalleryOpen: false,
        indexToOpen: 0,
      };

      handleOpenGallery = (index) =>
        this.setState({
          isGalleryOpen: true,
          indexToOpen: !index ? 0 : index,
        });

      handleCloseGallery = () => this.setState({ isGalleryOpen: false });

      handleFileItemIdClick = (id) => {
        const filesList = this.props[customParams.filesPropName];
        const index = filesList.findIndex((file) => file.id === id);

        this.handleOpenGallery(index);
      };

      handleFileItemClick = (event) => {
        const index = get(event, 'target.value');

        this.handleOpenGallery(index);
      };

      render() {
        const filesList = this.props[customParams.filesPropName];
        const { isGalleryOpen, indexToOpen } = this.state;

        return (
          <>
            <Gallery
              isOpen={isGalleryOpen}
              filesList={filesList}
              onClose={this.handleCloseGallery}
              sliderSettings={{ initialSlide: indexToOpen }}
            />
            <ComponentToWrap
              {...this.props}
              onOpenByIndex={this.handleFileItemClick}
              onOpenById={this.handleFileItemIdClick}
            />
          </>
        );
      }
    }

    hoistStatics(WithGallery, ComponentToWrap);

    return WithGallery;
  };
};
