import React, { AllHTMLAttributes, ElementType, FC, memo } from 'react';
import cn from 'classnames';

import { Icon } from 'components/Icon';

import styles from './Editable.module.scss';

interface EditableProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Root HTML element
   */
  component?: ElementType;
  /**
   * Flag to show/hide editable style
   */
  show?: boolean;
  /**
   * Flag to change css display style
   */
  block?: boolean;
}

/**
 * Renders passed children as editable component
 */
export const Editable: FC<EditableProps> = memo(function Editable({
  component: Component = 'span',
  show,
  block,
  children,
  className,
  ...props
}) {
  if (!children) return null;

  return (
    <Component
      className={cn(
        styles.editable,
        block && styles.block,
        show && styles.show,
        className
      )}
      {...props}
    >
      <span className={styles.text}>{children}</span>
      <Icon name="edit" className={styles.icon} />
    </Component>
  );
});
