import React, { FC } from 'react';
import cn from 'classnames';

import styles from './AuthFormTemplate.module.scss';

import { MessageBlock, Text } from 'components';

interface AuthFormTemplateProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  successMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  displaySubtitle?: boolean;
  displaySuccessMessage?: boolean;
  displayErrorMessage?: boolean;
  className?: string;
}

export const AuthFormTemplate: FC<AuthFormTemplateProps> = ({
  title,
  subtitle,
  successMessage,
  errorMessage,
  displaySubtitle = true,
  displaySuccessMessage = false,
  displayErrorMessage = false,
  children,
  className,
}) => {
  return (
    <div className={cn(styles.authFormTemplate, className)}>
      <div className={styles.heading}>
        <Text variant="heading-xl">{title}</Text>

        {displaySubtitle && subtitle && (
          <Text variant="paragraph-md">{subtitle}</Text>
        )}

        {displaySuccessMessage && successMessage && (
          <MessageBlock type="success">{successMessage}</MessageBlock>
        )}

        {displayErrorMessage && errorMessage && (
          <MessageBlock type="error">{errorMessage}</MessageBlock>
        )}
      </div>

      {children}
    </div>
  );
};
