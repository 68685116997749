import { Attachment } from 'common-types/attachments';

interface AttachmentsActionsH {
  (props: {
    fileUrl: Attachment['fileUrl'];
    id: Attachment['id'];
    name: Attachment['name'];

    onGalleryRequest?: (id: Attachment['id']) => void;
    onRemove?: (id: Attachment['id']) => void;
    onSelect?: (id: Attachment['id'], fileUrl: string) => void;
    selectOnCmdClick?: boolean;
    isSelected: boolean;
  }): {
    isActionable: boolean;
    handleGalleryRequest;
    handleAttachmentAction;
    handleRemove;
    handleSelect;
  };
}
export const useAttachmentItemActions: AttachmentsActionsH = ({
  fileUrl,
  id,

  onGalleryRequest,
  onRemove,
  onSelect,
  selectOnCmdClick,
}) => {
  const isSelectable: boolean = typeof onSelect === 'function';
  const isShowable: boolean = typeof onGalleryRequest === 'function';
  const showViewButton: boolean =
    isSelectable && !selectOnCmdClick && isShowable;
  const isActionable = isSelectable || isShowable;
  const selectOnClick = isSelectable;
  const showOnClick = !isSelectable && isShowable && !showViewButton;

  const handleSelect = () => {
    if (!onSelect) return;
    onSelect(id, fileUrl);
  };

  const handleRemove = () => {
    if (!onRemove) return;
    onRemove(id);
  };

  const handleGalleryRequest = () => {
    if (!onGalleryRequest) return;
    onGalleryRequest(id);
  };

  const handleAttachmentAction = (event: KeyboardEvent) => {
    if (selectOnClick) {
      if (selectOnCmdClick) {
        if (event.metaKey || event.ctrlKey) {
          handleSelect();
        }
      } else {
        handleSelect();
      }
    } else if (showOnClick) {
      handleGalleryRequest();
    }
  };

  return {
    isActionable,

    handleAttachmentAction,
    handleGalleryRequest,
    handleRemove,
    handleSelect,
  };
};
