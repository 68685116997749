import React, { FC, memo, ReactElement } from 'react';
import {
  OperationStep as OperationStepType,
  OperationStepEstimable,
} from 'common-types/library';
import { ListRow } from './ListRow';
import OperationStep, {
  OperationStepProps,
} from './OperationStep/OperationStep_DEPRECATED';
import { StepBarItem } from './StepBarItem';

interface ElementRowInnerProps {
  barColumn?: ReactElement;
  element: OperationStepType | OperationStepEstimable;
  isDragging: boolean;
  draggableHandleProps: any;
}

export const ElementRowInner: FC<
  ElementRowInnerProps & Partial<OperationStepProps>
> = memo(
  ({ isDragging, barColumn, element, draggableHandleProps, ...stepProps }) => (
    <>
      <ListRow isDragging={isDragging} barColumn={barColumn} />
      <ListRow
        isDragging={isDragging}
        key={element.uuid}
        barColumn={
          <StepBarItem
            hideEdgeBorders={false}
            category={element.category}
            step={element.step}
          />
        }
      >
        <div {...draggableHandleProps}>
          <OperationStep element={element} {...stepProps} />
        </div>
      </ListRow>
    </>
  )
);

ElementRowInner.displayName = 'ElementRowInner';
