import { AllowedMimeType } from 'app-constants/files';
import cn from 'classnames';
import { Attachment as AttachmentT } from 'common-types/attachments';
import { Col, Row } from 'components/Grid';
import React, { ComponentType } from 'react';
import { AttachmentPic } from '../AttachmentPic/AttachmentPic';
import classes from './AttachmentItem.module.scss';
import { CornerBadge } from './CornerBadge';

interface Props {
  description?: AttachmentT['description'];
  fileUrl: AttachmentT['fileUrl'];
  id: AttachmentT['id'] | string;
  name: AttachmentT['name'];
  size?: number;
  mimeType: AllowedMimeType;

  className?: string;
  isSelected?: boolean;
  onSelect?: (id: AttachmentT['id'] | string, type: AllowedMimeType) => void;
  selectedIndex?: number;
}

export const AttachmentItemSelectable: ComponentType<Props> = (props) => {
  const {
    className,
    description = '',
    fileUrl = '',
    id,
    name,
    mimeType,
    isSelected = false,
    onSelect,
    selectedIndex,
  } = props;

  const handleSelect = () => {
    if (!onSelect) return;
    onSelect(id, mimeType);
  };

  return (
    <>
      <Row
        className={cn(
          classes.container,
          'm-0 pt-05 pb-05',
          'pointer',
          className,
          {
            [classes.selected]: isSelected,
          }
        )}
        nowrap
        alignItems="center"
        onClick={handleSelect}
        role="button"
      >
        {Boolean(selectedIndex && isSelected) && (
          <CornerBadge className={classes.selectedLabel}>
            {selectedIndex}
          </CornerBadge>
        )}
        <Col auto>
          <AttachmentPic
            fileUrl={fileUrl}
            name={name}
            description={description}
            mimeType={mimeType}
          />
        </Col>

        <Col className={classes.name}>{name}</Col>
      </Row>
    </>
  );
};

AttachmentItemSelectable.displayName = 'AttachmentItemSelectable';
