import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'components/Typography';

import styles from './EditableDurationLabel.module.scss';
import { toNumber, toSeconds } from './utils';
import { DurationLabel, DurationLabelProps } from '../DurationLabel';
import { Editable } from 'components/Editable';
import { EditableInput } from 'components/Form/Input';

export interface EditableDurationLabelProps extends DurationLabelProps {
  /**
   * Duration label
   */
  label?: string;
  /**
   * Duration time
   */
  duration?: number;
  /**
   * Change duration callback
   */
  onChange?: (duration: number) => void;
  className?: string;
}

/**
 * Renders editable operation/procedure time with label
 */
export const EditableDurationLabel: FC<EditableDurationLabelProps> = memo(
  function EditableDurationLabel({ label, duration = 0, onChange, ...props }) {
    const [error, setError] = useState<unknown>(null);
    const [editMode, setEditMode] = useState(false);

    const { t } = useTranslation();

    const onEditCancel = useCallback(() => {
      setEditMode(false);
      setError(null);
    }, []);

    const onEdit = useCallback(
      (event) => {
        onChange?.(toSeconds(toNumber(event.target.value)));
        setEditMode(false);
        setError(null);
      },
      [onChange]
    );

    const onInvalid = useCallback(({ validationMessage }, isBlur: boolean) => {
      setEditMode(isBlur ? null : validationMessage);
      setError(!isBlur);
    }, []);

    if (!editMode) {
      const labelElement = (
        <DurationLabel
          showSeconds
          duration={duration}
          label={label}
          {...props}
        />
      );

      return onChange ? (
        <Editable onClick={() => setEditMode(true)}>{labelElement}</Editable>
      ) : (
        labelElement
      );
    }

    return (
      <Text component="div" variant="caption" className={styles.root}>
        <Text component="span" variant="paragraph-sm" color="secondary">
          {label || t('Duration')}:
        </Text>
        <EditableInput
          type="number"
          min="0"
          error={error}
          className={styles.inputWrapper}
          inputClassName={styles.input}
          defaultValue={duration / 60}
          onEditCancel={onEditCancel}
          onEditComplete={onEdit}
          onInvalid={onInvalid}
        />
        {t('MIN')}
      </Text>
    );
  }
);
