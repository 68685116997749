import cn from 'classnames';
import { Icon } from 'components/Icon';
import React, { ComponentType, HTMLAttributes, useMemo } from 'react';
import { isValidReactChildren } from 'utils/is-valid-react-children';
import classes from './MessageBlock.module.scss';

export type MessageType =
  | 'default'
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'saving';
export interface MessageBlockProps {
  className?: string;
  type?: MessageType;
  inline?: boolean;
}

export const MessageBlock: ComponentType<
  MessageBlockProps & HTMLAttributes<HTMLSpanElement>
> = ({
  className,
  children,
  type = 'default',
  inline = false,
  ...restProps
}) => {
  const messageIconName = useMemo(() => {
    switch (type) {
      case 'success':
        return 'check-circle';
      case 'error':
        return 'validation-error';
      case 'warning':
        return 'incidents';
      case 'saving':
        return '';
      case 'info':
      default:
        return 'info';
    }
  }, [type]);

  if (!isValidReactChildren(children)) return null;

  return (
    <span
      className={cn(classes.default, className, {
        [classes.error]: type === 'error',
        [classes.info]: type === 'info',
        [classes.inline]: inline,
        [classes.success]: type === 'success',
        [classes.warning]: type === 'warning',
      })}
      {...restProps}
    >
      <Icon className={classes.icon} gapRight name={messageIconName} />
      <span>{children}</span>
    </span>
  );
};
