import React, { ComponentPropsWithoutRef, forwardRef, memo } from 'react';
import cn from 'classnames';

import { Icon } from 'components/Icon';
import styles from './Button.module.scss';
import { ButtonVariant } from 'components/Button/Button';

export interface FabButtonProps extends ComponentPropsWithoutRef<'button'> {
  /**
   * Icon rendered inside button
   */
  icon?: JSX.Element;
  variant?: Extract<ButtonVariant, 'primary' | 'secondary'>;
  isActive?: boolean;
}

/**
 * Renders button component with icon inside
 */
export const FabButton = memo(
  forwardRef<HTMLButtonElement, FabButtonProps>(function FabButton(
    {
      isActive = false,
      icon = <Icon name="plus" />,
      type = 'button',
      variant = 'primary',
      className,
      ...props
    },
    ref
  ) {
    return (
      <button
        ref={ref}
        type={type}
        className={cn(
          styles.fabButton,
          styles[variant], // injects styles from mixin button variant
          isActive && styles.active,
          className
        )}
        {...props}
      >
        {icon}
      </button>
    );
  })
);
