import { MaterialElement } from 'common-types/library';
import { MaterialsGroup as MaterialsGroupT } from 'common-types/materials';
import React, { ComponentType, useMemo } from 'react';
import { getGroupMaterials } from '../../helpers';
import {
  MaterialsGroup,
  MaterialsGroupProps,
} from '../MaterialsGroup/MaterialsGroup';

interface Props {
  group: MaterialsGroupT;
}

export const MaterialsGroupColumn: ComponentType<
  Props & Partial<MaterialsGroupProps>
> = ({ group, ...props }) => {
  const materials: MaterialElement[] = useMemo(
    () => getGroupMaterials(group),
    [group]
  );

  return (
    <MaterialsGroup
      materials={materials}
      id={group.id}
      name={group.name}
      description={group.description}
      attachments={group.attachments}
      {...props}
    />
  );
};

MaterialsGroupColumn.displayName = 'MaterialsGroupColumn';
