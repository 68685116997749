import React, { HTMLAttributes, ElementType, FC } from 'react';
import cn from 'classnames';
import classes from './List.module.scss';
import { Callback } from 'downshift';

export interface ListItemBaseProps {
  addonOnHover?: boolean;
  clickable?: boolean;
  component?: ElementType;
  hoverable?: boolean;
  inlineChildren?: boolean;
  innerRef?: any;
  isDragging?: boolean;
  onItemClick?(cb: Callback): void;
  onItemClickCapture?(cb: Callback): void;
}

export const ListItemBase: FC<ListItemBaseProps & HTMLAttributes<HTMLElement>> =
  ({
    addonOnHover = false,
    children,
    className,
    clickable = false,
    hoverable = false,
    inlineChildren = false,
    innerRef,
    isDragging = false,
    component: ListItemBaseComponent = 'div',
    onItemClick,
    onItemClickCapture,
    ...restProps
  }) => {
    return (
      <ListItemBaseComponent
        ref={innerRef}
        onClick={onItemClick}
        onClickCapture={onItemClickCapture}
        className={cn('ListItem', className, classes.itemBase, {
          [classes.addonOnHover]: addonOnHover,
          [classes.clickable]: clickable,
          [classes.dragging]: isDragging,
          [classes.hasInlineChildren]: inlineChildren,
          [classes.hoverable]: hoverable,
        })}
        {...restProps}
      >
        {children}
      </ListItemBaseComponent>
    );
  };

ListItemBase.displayName = 'ListItemBase';
