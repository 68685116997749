/* istanbul ignore file */

import ReactGA from 'react-ga';
import {
  START_OPERATION_CREATION,
  CLOSE_OPERATION_PLANNING,
  FINISH_OPERATION_CREATION,
} from 'store/modules/operation-planning';
import {
  START_OPERATION_REPORT_EDIT,
  CLOSE_OPERATION_REPORT_EDIT,
} from 'store/modules/history';
import { createTimer } from 'utils';
import { SUBMIT_OPERATION_REPORT_SUCCESS } from 'store/modules/report/types';

export default () => next => {
  const OPPlanningTimer = createTimer('OP planning');
  const OperationReportTimer = createTimer('OperationReport');

  return action => {
    const result = next(action);

    switch (action.type) {
      case START_OPERATION_CREATION:
        OPPlanningTimer.start();
        break;

      case CLOSE_OPERATION_PLANNING:
      case FINISH_OPERATION_CREATION: {
        const planningDuration = OPPlanningTimer.stop();
        if (planningDuration) {
          ReactGA.timing({
            category: 'OP planning',
            label:
              action.type === FINISH_OPERATION_CREATION ? 'finish' : 'close',
            value: planningDuration,
            variable: 'duration',
          });
        }
        OPPlanningTimer.reset();
        break;
      }

      case START_OPERATION_REPORT_EDIT:
        OperationReportTimer.start();
        break;

      case CLOSE_OPERATION_REPORT_EDIT:
      case SUBMIT_OPERATION_REPORT_SUCCESS: {
        const reportEditDuration = OperationReportTimer.stop();
        if (reportEditDuration) {
          ReactGA.timing({
            category: 'OP report',
            label:
              action.type === SUBMIT_OPERATION_REPORT_SUCCESS
                ? 'submit'
                : 'close',
            value: reportEditDuration,
            variable: 'duration',
          });
        }
        OperationReportTimer.reset();
        break;
      }

      default:
      // Do nothing
    }

    return result;
  };
};
