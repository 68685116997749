export const SELECT_MODES = {
  select: 'select',
  suggestions: 'suggestions',
  search: 'search',
};

export type SELECT_MODES_TYPE = 'select' | 'suggestions' | 'search';

export const LANG_OPTIONS = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  // { label: 'Français', value: 'fr' },
  // { label: 'Italiano', value: 'it' },
];
