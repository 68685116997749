import { Col, OverflowText, Row } from 'components';
import { FC, useMemo } from 'react';
import { Text } from 'components';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

export const QualificationFormPageTitle: FC = () => {
  const url = useRouteMatch();

  const { t } = useTranslation();

  const pageTitle = useMemo(() => {
    if (url.path.includes('topics')) {
      return t('qualification.qualificationFormTopics');
    } else if (url.path.includes('competences')) {
      return t('qualification.qualificationFormCompetences');
    }
    return t('qualification.qualificationForm');
  }, [url.path, t]);

  return (
    <Row alignItems="center" justifyContent="space-between" nowrap>
      <Col>
        <Row alignItems="center" nowrap>
          <Col>
            <Text variant="heading-xl" className="mb-0">
              <OverflowText component="span" maxLines={1}>
                {pageTitle}
              </OverflowText>
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
