import * as APIv1 from 'api/v1';
import * as APIv2 from 'api/v2';
import invariant from 'invariant';
import { selectOperationElements } from 'store/modules/entities/selectors/operations';
import * as actions from 'store/modules/operation-planning/actions';
import { makeThunk } from 'store/utils';
import {
  makeThunkFromTypes,
  makeThunkFromTypeString,
} from 'store/utils/make-thunk';
import i18n from 'translations/i18n-instance';
import { getCleanTemplateElement } from '../helpers';
import { updateOperationElements } from './elements';
import { isProductType } from 'utils/get-env';

/**
 * @param body {object}
 */
export const createOperationThunk = makeThunk(
  APIv2.createOperation,
  {
    request: actions.createOperationRequest,
    success: actions.createOperationSuccess,
    failure: actions.createOperationFailure,
  },
  () => i18n.t('errors:failedtoCreateOperation')
);

export const readOperation = makeThunk(
  isProductType('vet') ? APIv2.readOperationVet : APIv2.readOperation,
  {
    request: actions.readOperationRequest,
    success: actions.readOperationSuccess,
    failure: actions.readOperationFailure,
  }
);

const updateOperationThunk = makeThunk(
  APIv2.updateOperation,
  {
    request: actions.updateOperationRequest,
    success: actions.updateOperationSuccess,
    failure: actions.updateOperationFailure,
  },
  (operationId) =>
    `${i18n.t('errors:failedToUpdateOperation')} (id: ${operationId})`
);

export const createOperation =
  (operationBody, templateId) => async (dispatch) => {
    operationBody.operationTemplateId = templateId;
    return await dispatch(createOperationThunk(operationBody));
  };

export const updateOperation = (id, operationBody) => {
  invariant(operationBody, 'operationBody param is required');
  return updateOperationThunk(id, operationBody);
};

export const readOperationPatientRisks = makeThunk(
  APIv1.readOperationPatientRisks,
  {
    request: actions.readOperationPatientRisksRequest,
    success: actions.readOperationPatientRisksSuccess,
    failure: actions.readOperationPatientRisksFailure,
  },
  (operationId) =>
    `${i18n.t('errors:failedToReadOperationPatientRisks')} (id: ${operationId})`
);

export const updateOperationPatientRisks = makeThunk(
  APIv1.updateOperationPatientRisks,
  {
    request: actions.updateOperationPatientRisksRequest,
    success: actions.updateOperationPatientRisksSuccess,
    failure: actions.updateOperationPatientRisksFailure,
  },
  (operationId) =>
    `${i18n.t(
      'errors:failedToUpdateOperationPatientRisks'
    )} (id: ${operationId})`
);

export const deleteOperation = makeThunk(
  APIv1.deleteOperation,
  {
    request: actions.deleteOperationRequest,
    success: actions.deleteOperationSuccess,
    failure: actions.deleteOperationFailure,
  },
  (operationId) =>
    `${i18n.t('errors:failedToDeleteOperation')} (id: ${operationId})`
);

export const readOperationDebriefing = makeThunk(
  APIv1.readOperationDebriefing,
  {
    request: actions.readOperationDebriefingRequest,
    success: actions.readOperationDebriefingSuccess,
    failure: actions.readOperationDebriefingFailure,
  }
);

export const readDiagnosis = makeThunk(APIv1.readDiagnosis, {
  request: actions.readDiagnosisRequest,
  success: actions.readDiagnosisSuccess,
  failure: actions.readDiagnosisFailure,
});

export const createOperationIncident = makeThunkFromTypes(APIv1.postIncident, {
  request: '[ operation ]: CREATE_OPERATION_INCIDENT_REQUEST',
  success: '[ operation ]: CREATE_OPERATION_INCIDENT_SUCCESS',
  failure: '[ operation ]: CREATE_OPERATION_INCIDENT_FAILURE',
});

export const {
  thunk: readOperationAttachments,
  asyncActionTypes: {
    request: READ_OPERATION_ATTACHMENTS_REQUEST,
    success: READ_OPERATION_ATTACHMENTS_SUCCESS,
    failure: READ_OPERATION_ATTACHMENTS_FAILURE,
  },
} = makeThunkFromTypeString(
  APIv2.readOperationAttachments,
  '[ operation ]: READ_OPERATION_ATTACHMENTS'
);

export const {
  thunk: updateOperationAttachments,
  asyncActionTypes: {
    request: UPDATE_OPERATION_ATTACHMENTS_REQUEST,
    success: UPDATE_OPERATION_ATTACHMENTS_SUCCESS,
    failure: UPDATE_OPERATION_ATTACHMENTS_FAILURE,
  },
} = makeThunkFromTypeString(
  APIv2.updateOperationAttachments,
  '[ operation ]: UPDATE_OPERATION_ATTACHMENTS'
);

export const deleteOperationElementAttachment =
  ({ operationId, attachmentId: attachmentToDeleteId, elementId = null }) =>
  (dispatch, getState) => {
    const state = getState();
    const operationElements = selectOperationElements(state, {
      id: operationId,
    });
    if (!Array.isArray(operationElements)) return;
    const elementsUpdate = operationElements.map((element) => {
      const elementAttachmentsIds = element.attachments;
      const hasAttachment =
        elementAttachmentsIds?.length &&
        element.attachments.some((a) => a === attachmentToDeleteId);
      if (!hasAttachment || (elementId && element.id !== elementId))
        return getCleanTemplateElement(element);

      element.attachments = element.attachments.filter(
        (a) => a !== attachmentToDeleteId
      );
      return getCleanTemplateElement(element);
    }, []);

    return dispatch(updateOperationElements(operationId, elementsUpdate));
  };

export const approveOperation = makeThunk(
  APIv2.approveOperation,
  {
    request: actions.approveOperationRequest,
    success: actions.approveOperationSuccess,
    failure: actions.approveOperationFailure,
  },
  () => `${i18n.t('errors:failedToApproveOperation')}`
);
