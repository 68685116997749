import { LOCATION_CHANGE } from 'connected-react-router';
import * as types from './types';
import { CREATE_NEW_LIBRARY_ITEM_SUCCESS } from '../types';

const initialState = null;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SEARCH_LIBRARY_ELEMENTS_SUCCESS:
      return payload.result;

    case LOCATION_CHANGE:
    case types.SEARCH_LIBRARY_ELEMENTS_RESET:
      return initialState;

    case CREATE_NEW_LIBRARY_ITEM_SUCCESS:
      const { result } = payload;
      return (state || []).concat(result);

    default:
      return state;
  }
};
