import { REQUEST_STATES } from 'app-constants';
import { RequestError } from 'common-types/api';
import isObject from 'lodash/isObject';
import { createStateGetter } from 'store/utils';
import parseAsyncActionType from 'store/utils/parse-async-action-type';

export const selectRequestsErrors = createStateGetter('apiRequests.errors', {});
export const selectRequestsLoadingStates = createStateGetter(
  'apiRequests.loading',
  {}
);

export const selectRequestsLog = createStateGetter(
  'apiRequests.requestsLog',
  []
);

export const getAllErrorMessages = (errors: Array<RequestError>) =>
  Object.values(errors).map(
    ({
      id,
      message,
      errorMessage,
      error,
    }: RequestError): { id?: number; message?: string } => ({
      id,
      message: message || errorMessage || error,
    })
  );

// FIXME:
// Warning: this code of errors selector creator is in the raw state.
// It should be revisit once API endpoints have stable errors response shape.
// refs: https://github.com/NodusMedical/impedia-fe/issues/8
type createErrorsSelectorFn = (
  actions: Array<string> | string,
  category?: string
) => (state: any, props?: any) => Array<object>;

export const createErrorsSelector: createErrorsSelectorFn =
  (actions = '*', category) =>
  (state) => {
    const stateErrors = selectRequestsErrors(state);

    const getRequestErrors = (actionType) => {
      const parsedActionType = parseAsyncActionType(actionType);
      if (!parsedActionType) return null;
      const { requestName } = parsedActionType;

      let errors = stateErrors[requestName];
      if (typeof category === 'string' && errors) {
        errors = stateErrors[requestName][category];
      }

      return errors;
    };

    if (actions === '*') {
      return getAllErrorMessages(stateErrors);
    } else if (typeof actions === 'string') {
      return getRequestErrors(actions);
    } else if (
      Array.isArray(actions) &&
      actions.every((action) => typeof action === 'string')
    ) {
      return actions.reduce(
        (errors: null | object, action: string): object | null => {
          const requestErrors = getRequestErrors(action);

          if (!requestErrors) {
            return errors;
          }

          if (typeof requestErrors === 'string') {
            return {
              ...(errors || {}),
              ...{ [action]: requestErrors },
            };
          } else if (isObject(requestErrors)) {
            return {
              ...(errors || {}),
              ...requestErrors,
            };
          }

          return errors;
        },
        null
      );
    }

    throw new Error(
      'Invalid actions argument were provided. Expected action type or array of action types.'
    );
  };

export const createAsyncActionStateSelector = (actions = '*') => {
  let mode;
  let requestNames;
  const InvalidActionsArgumentErrorMessage =
    'Invalid actions argument were provided. Expected action type, array of action types or "*" for "all"';

  try {
    if (actions === '*') {
      return selectRequestsLoadingStates;
    } else if (Array.isArray(actions)) {
      mode = 'list';
      requestNames = actions.map(
        (action) => parseAsyncActionType(action)?.requestName
      );
    } else if (typeof actions === 'string') {
      mode = 'single';
      requestNames = parseAsyncActionType(actions)?.requestName;
    } else {
      throw new TypeError(InvalidActionsArgumentErrorMessage);
    }
  } catch (e) {
    throw new TypeError(InvalidActionsArgumentErrorMessage);
  }

  return (state) => {
    const stateLoading = selectRequestsLoadingStates(state);
    const stateName = (requestName) => stateLoading[requestName];

    if (mode === 'single') {
      return stateName(requestNames);
    } else if (mode === 'list') {
      const stateReducer = (namesList) =>
        namesList.reduce(
          (result, name) => ({
            ...result,
            [name]: stateName(name),
          }),
          {}
        );

      return stateReducer(requestNames);
    }

    throw new TypeError(
      `Unrecognized watch mode. Expected one of "single", "list", got: ${mode}`
    );
  };
};

export const createLoadingSelector = (actions) => {
  let mode;
  let requestNames;
  const InvalidActionsArgumentErrorMessage =
    'Invalid actions argument were provided. Expected action type or array of action types.';

  try {
    if (Array.isArray(actions)) {
      mode = 'list';
      requestNames = actions.map(
        (action) => parseAsyncActionType(action)?.requestName
      );
    } else if (typeof actions === 'string') {
      mode = 'single';
      requestNames = parseAsyncActionType(actions)?.requestName;
    } else {
      throw new TypeError(InvalidActionsArgumentErrorMessage);
    }
  } catch (e) {
    throw new TypeError(InvalidActionsArgumentErrorMessage);
  }

  return (state) => {
    const stateLoading = selectRequestsLoadingStates(state);
    const isRequesting = (requestName) =>
      stateLoading[requestName] === REQUEST_STATES.REQUEST;

    if (mode === 'list') {
      return requestNames.some(isRequesting);
    } else if (mode === 'single') {
      return isRequesting(requestNames);
    }

    throw new TypeError(
      `Unrecognized watch mode. Expected one of "single", "list", got: ${mode}`
    );
  };
};
