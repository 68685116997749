import React, { ReactElement } from 'react';
import { DeprecatedFlex } from 'components/Grid';
import { TemplateCategory } from './TemplateCategory';

interface Props {
  categoryId?: number;
}

export const TemplateCategoryBreadcrumbs = ({
  categoryId,
}: Props): ReactElement | null => {
  if (!categoryId) return null;

  return (
    <DeprecatedFlex className="align-center" nowrap>
      <TemplateCategory id={categoryId} />
    </DeprecatedFlex>
  );
};
