import React, { ComponentType, HTMLAttributes } from 'react';
import cn from 'classnames';
import classes from '../FormInputBase/FormInputBase.module.scss';

export const FormInputBody: ComponentType<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...blockProps
}) => (
  <div className={cn(className, classes.formInputBlock)} {...blockProps}>
    {children}
  </div>
);

FormInputBody.displayName = 'FormInputBody';
