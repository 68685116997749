import React, { FC } from 'react';
import classnames from 'classnames';

import { Icon } from '../Icon';

interface RiskIconProps {
  className?: string;
}

export const RiskIcon: FC<RiskIconProps> = ({ className, ...restProps }) => {
  return (
    <Icon
      name="risk"
      className={classnames(className, 'text-error')}
      {...restProps}
    />
  );
};
