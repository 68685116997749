const parsePaginationHeader = (headers) => {
  if (!headers?.['content-range']) return null;
  const [, from, to, total] = headers['content-range'].match(
    /(\d+)-(\d+)\/(\d+)/,
    'gi'
  );

  return {
    range: {
      from: Number(from),
      to: Number(to),
      total: Number(total),
    },
  };
};

const responseInterceptor = (response) => {
  const meta = parsePaginationHeader(response.headers);
  const data = response.data;
  if (meta) {
    data.meta = meta;
  }
  return data;
};

export const getPaginationMeta = (response) => response?.meta;
export const hasPaginationMeta = (response) =>
  Boolean(getPaginationMeta(response));

export default responseInterceptor;
