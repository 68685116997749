import { AttachmentsFormUploadable } from 'components/Attachments';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  selectOperationTemplateId,
  selectOperationAttachments,
} from 'store/modules/operation-planning';

const mapStateToProps = createStructuredSelector({
  entityId: selectOperationTemplateId,
  readonly: () => true,
  attachments: selectOperationAttachments,
});
const mapDispatchToProps = {};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(AttachmentsFormUploadable);
