/* istanbul ignore file */

import ReactGA from 'react-ga';
import { get } from 'lodash';
import {
  CREATE_OPERATION_SUCCESS,
  ADD_PATIENT_RISK,
  REMOVE_PATIENT_RISK,
} from 'store/modules/operation-planning';
import {
  TOGGLE_TIMEOUT_QUESTION_SELECTION as TEMPLATE_TOGGLE_TIMEOUT_QUESTION_SELECTION,
  TOGGLE_DEBRIEFING_QUESTION_SELECTION as TEMPLATE_TOGGLE_DEBRIEFING_QUESTION_SELECTION,
  CREATE_ADDITIONAL_TIMEOUT_QUESTION as TEMPLATE_CREATE_ADDITIONAL_TIMEOUT_QUESTION,
  CREATE_ADDITIONAL_DEBRIEFING_QUESTION as TEMPLATE_CREATE_ADDITIONAL_DEBRIEFING_QUESTION,
  CREATE_TEMPLATE_SUCCESS,
} from 'store/modules/templates-library/types';
import {
  CREATE_NEW_LIBRARY_ITEM_SUCCESS,
  EDIT_LIBRARY_ELEMENT,
} from 'store/modules/library';

const trackOPCreate = action => {
  const operationTemplateId = get(action, 'requestArgs[0].operationTemplateId');

  if (operationTemplateId) {
    ReactGA.event({
      category: 'OP planning',
      action: 'Create from a template',
      label: `operationTemplateId: ${operationTemplateId}`,
    });
  } else {
    ReactGA.event({
      category: 'OP planning',
      action: 'Create from scratch',
    });
  }
};

const trackPatientRiskChangeEvent = ({ payload: { operationId } }, action) => {
  ReactGA.event({
    category: 'Patient risks',
    action,
    label: `operationId: ${operationId}`,
  });
};

const trackQuestionsEvent = questionsType => (
  { payload: { id, templateId, checked } },
  action
) => {
  const category = `${questionsType} questions`;

  if (action === 'Create') {
    ReactGA.event({
      category,
      action,
      label: `operationTemplateId: ${templateId}`,
    });
  } else {
    ReactGA.event({
      category,
      action: checked ? 'Select' : 'Un-select',
      label: `questionId: ${id}`,
    });
  }
};

const trackTemplateCreation = () => {
  ReactGA.event({
    category: 'Operation template',
    action: 'Create',
  });
};

const trackLibraryItemCreation = ({ payload: { category } }) => {
  ReactGA.event({
    category: 'Library item',
    action: 'Create',
    label: category,
  });
};

export default () => next => action => {
  const result = next(action);

  switch (action.type) {
    case CREATE_OPERATION_SUCCESS:
      trackOPCreate(action);
      break;

    case EDIT_LIBRARY_ELEMENT:
      trackPatientRiskChangeEvent(action, 'Edit');
      break;

    case REMOVE_PATIENT_RISK:
      trackPatientRiskChangeEvent(action, 'Remove');
      break;

    case ADD_PATIENT_RISK:
      trackPatientRiskChangeEvent(action, 'Add');
      break;

    case TEMPLATE_TOGGLE_DEBRIEFING_QUESTION_SELECTION:
      trackQuestionsEvent('Debriefing')(action);
      break;

    case TEMPLATE_CREATE_ADDITIONAL_DEBRIEFING_QUESTION:
      trackQuestionsEvent('Debriefing')(action, 'Create');
      break;

    case TEMPLATE_TOGGLE_TIMEOUT_QUESTION_SELECTION:
      trackQuestionsEvent('Time-out')(action);
      break;

    case TEMPLATE_CREATE_ADDITIONAL_TIMEOUT_QUESTION:
      trackQuestionsEvent('Time-out')(action, 'Create');
      break;

    case CREATE_TEMPLATE_SUCCESS:
      trackTemplateCreation(action);
      break;

    case CREATE_NEW_LIBRARY_ITEM_SUCCESS:
      trackLibraryItemCreation(action);
      break;

    default:
    // Do nothing
  }

  return result;
};
