import { Attachment, AttachmentInput } from 'common-types/attachments';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'store/modules/api-requests';
import { selectAttachmentById } from 'store/modules/entities/selectors/attachments';
import { updateAttachment } from 'store/modules/attachments';
import { selectUser } from '../store/modules/auth';

type useAttachmentT = {
  attachment: Attachment | null;
  loading: boolean;
};

export const useAttachmentUpdate = (attachmentId?: number | null) => {
  const dispatch = useDispatch();

  const handleChange = (values: AttachmentInput) => {
    if (!attachmentId) return;
    return dispatch(updateAttachment(attachmentId, values));
  };

  return {
    handleChange,
  };
};

export const useAttachment = (attachmentId?: number | null): useAttachmentT => {
  const attachment = useSelector(selectAttachmentById(attachmentId));
  const isLoading = useSelector(createLoadingSelector([]));

  return { attachment, loading: isLoading };
};

export const useCurrentUser = () => {
  const user = useSelector(selectUser);
  return user;
};
