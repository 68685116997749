import { schema } from 'normalizr';
import { attachmentsSchema } from './file';

export const incidentSchema = new schema.Entity('incidents');
export const incidentsListSchema = new schema.Array(incidentSchema);
export const operationStepsActualTimeSchema = new schema.Entity(
  'operationStepsActualTime'
);
export const debriefingSchema = new schema.Entity('debriefings', {
  incidents: incidentsListSchema,
  stepsActualTime: new schema.Array(operationStepsActualTimeSchema),
});

export const reportSchema = new schema.Entity('reports', {
  attachments: [attachmentsSchema],
});
