import React, { FC } from 'react';
import { LibraryFormLimitsType } from 'containers/LibraryItemsSearch/AddLibraryItemForm.types';
import { FormTextarea } from 'components/Form/FormInput';
import { MaxLengthIndicator } from 'components/MaxLengthIndicator';
import { useTranslation } from 'react-i18next';
import { connect, FormikContextType } from 'formik';

interface ProceduresFormProps {
  limits?: LibraryFormLimitsType;
}

interface ProceduresFormFormikValueProps {
  description: string;
}

const ProceduresForm: FC<
  ProceduresFormProps & FormikContextType<ProceduresFormFormikValueProps>
> = ({ limits, values, errors, handleChange }) => {
  const { t } = useTranslation();

  return (
    <FormTextarea
      label={t('Description')}
      name="description"
      mode="translatable"
      placeholder={t('enterProcedureDescription')}
      value={values.description}
      error={errors.description}
      append={
        limits ? (
          <MaxLengthIndicator
            length={limits.descriptionCharsOccupied}
            limit={limits.descriptionCharsLimit}
          />
        ) : undefined
      }
      onChange={handleChange}
    />
  );
};

export default connect(ProceduresForm);
