import { Panel, Col, Row } from 'components';

import React, { ComponentType, ReactNode } from 'react';
import classes from './SidebarLayout.module.scss';

interface Props {
  sidebar: ReactNode;
}

export const SidebarLayout: ComponentType<Props> = ({ sidebar, children }) => {
  return (
    <Row>
      <Col basis="23em">
        <div className={classes.content}>{sidebar}</div>
      </Col>
      <Col>
        <Panel fullPage="min">{children}</Panel>
      </Col>
    </Row>
  );
};
