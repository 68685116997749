import { ElementType } from 'react';

export type TextVariant =
  | 'heading-xxl'
  | 'heading-xl'
  | 'heading-lg'
  | 'heading-md'
  | 'title'
  | 'paragraph-md'
  | 'paragraph-sm'
  | 'caption'
  | 'small'
  | 'error';

export type TextColor =
  | 'inherit'
  | 'default'
  | 'secondary'
  | 'primary'
  | 'error'
  | 'warning';

export type TextAlign = 'left' | 'center' | 'right';
export type TextWeight = 'bold' | 'semibold' | 'regular';
export type TextTransform = 'uppercase' | 'capitalize' | 'lowercase' | 'unset';

export const variantToComponent: Record<TextVariant, ElementType> = {
  'heading-xxl': 'h1',
  'heading-xl': 'h2',
  'heading-lg': 'h3',
  'heading-md': 'h4',
  title: 'h4',
  'paragraph-md': 'p',
  'paragraph-sm': 'p',
  caption: 'span',
  small: 'span',
  error: 'span',
};

export const variantToWeight: Record<TextVariant, TextWeight> = {
  'heading-xxl': 'semibold',
  'heading-xl': 'semibold',
  'heading-lg': 'semibold',
  'heading-md': 'semibold',
  title: 'bold',
  'paragraph-md': 'regular',
  'paragraph-sm': 'regular',
  caption: 'bold',
  small: 'regular',
  error: 'bold',
};

export const variantToColor: Record<TextVariant, TextColor> = {
  'heading-xxl': 'default',
  'heading-xl': 'default',
  'heading-lg': 'default',
  'heading-md': 'default',
  title: 'default',
  'paragraph-md': 'default',
  'paragraph-sm': 'default',
  caption: 'default',
  small: 'default',
  error: 'error',
};

export const variantToTransform: Record<TextVariant, TextTransform> = {
  'heading-xxl': 'unset',
  'heading-xl': 'unset',
  'heading-lg': 'unset',
  'heading-md': 'unset',
  title: 'uppercase',
  'paragraph-md': 'unset',
  'paragraph-sm': 'unset',
  caption: 'unset',
  small: 'unset',
  error: 'unset',
};
