/* istanbul ignore file */

/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */

import { isIE } from 'utils';

if (isIE()) {
  console.info('Browser is IE. Loading:');

  console.log('- DOM4 polyfill...');
  import('dom4');

  console.log('- window.fetch polyfill...');
  import('whatwg-fetch');

  // nanoid fix
  // Reference: https://github.com/ai/nanoid/blob/97cece94d011b19ada958911b50ec7e249a2719c/index.browser.js#L19
  window.crypto = window.msCrypto;
}
