import { forEach, omitBy, isNil, isObject } from 'lodash';

export default function omitByDeep(object, predicate) {
  function omitDeepOnOwnProps(obj) {
    if (typeof object === 'undefined') {
      return object;
    }

    if (!Array.isArray(obj) && !isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return omitByDeep(obj, predicate);
    }

    const o = {};
    forEach(obj, (value, key) => {
      o[key] = !isNil(value) ? omitByDeep(value, predicate) : value;
    });

    return omitBy(o, predicate);
  }

  if (Array.isArray(object)) {
    return object.map(omitDeepOnOwnProps);
  }

  return omitDeepOnOwnProps(object);
}
