import isIE from './is-ie';

const fetchBlob = b64Data => fetch(b64Data).then(res => res.blob());
const base64Regex = /data:(\w+\/\w+);base64,/;
const remoteDataURLRegex = /^https:\/\//;

function base64ToBlob(base64, contentType = '') {
  const sliceSize = 1024;
  const byteChars = window.atob(base64);
  const byteArrays = [];

  for (
    let offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    const slice = byteChars.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export default async dataURL => {
  const isRemote = dataURL.match(remoteDataURLRegex);

  if (!isRemote && isIE()) {
    const match = dataURL.match(base64Regex);
    const contentType = match ? match[1] : '';
    return base64ToBlob(dataURL.replace(base64Regex, ''), contentType);
  }

  return fetchBlob(dataURL);
};
