import namespace from './namespace';

export const READ_OPERATIONS_HISTORY_REQUEST = `${namespace}READ_OPERATIONS_HISTORY_REQUEST`;
export const READ_OPERATIONS_HISTORY_SUCCESS = `${namespace}READ_OPERATIONS_HISTORY_SUCCESS`;
export const READ_OPERATIONS_HISTORY_FAILURE = `${namespace}READ_OPERATIONS_HISTORY_FAILURE`;

export const START_OPERATION_REPORT_EDIT = `${namespace}START_OPERATION_REPORT_EDIT`;
export const CLOSE_OPERATION_REPORT_EDIT = `${namespace}CLOSE_OPERATION_REPORT_EDIT`;

export const UPDATE_OPERATION_DEBRIEFING_REQUEST = `${namespace}UPDATE_OPERATION_DEBRIEFING_REQUEST`;
export const UPDATE_OPERATION_DEBRIEFING_SUCCESS = `${namespace}UPDATE_OPERATION_DEBRIEFING_SUCCESS`;
export const UPDATE_OPERATION_DEBRIEFING_FAILURE = `${namespace}UPDATE_OPERATION_DEBRIEFING_FAILURE`;

export const UPDATE_INCIDENT_REQUEST = `${namespace}UPDATE_INCIDENT_REQUEST`;
export const UPDATE_INCIDENT_SUCCESS = `${namespace}UPDATE_INCIDENT_SUCCESS`;
export const UPDATE_INCIDENT_FAILURE = `${namespace}UPDATE_INCIDENT_FAILURE`;

export const READ_OPERATIONS_STATS_REQUEST = `${namespace}READ_OPERATIONS_STATS_REQUEST`;
export const READ_OPERATIONS_STATS_SUCCESS = `${namespace}READ_OPERATIONS_STATS_SUCCESS`;
export const READ_OPERATIONS_STATS_FAILURE = `${namespace}READ_OPERATIONS_STATS_FAILURE`;
