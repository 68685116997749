import React, { ComponentType, useCallback } from 'react';
import { FeatureCheckBase } from './FeatureCheckBase';
import { Feature, isFeatureFlagEnabled } from './flags';

interface Props {
  name: Feature;
}

export const FeatureFlagCheck: ComponentType<Props> = ({ name, children }) => {
  const featureCheck = useCallback(() => isFeatureFlagEnabled(name), [name]);

  return (
    <FeatureCheckBase featureCheck={featureCheck}>{children}</FeatureCheckBase>
  );
};

FeatureFlagCheck.displayName = 'FeatureFlagCheck';
