import { normalizeResponse } from 'api/helpers/normalize-response';
import { APIv1 } from 'api/instance';
import { templateAttachmentsResponse } from 'api/v1/schemas';
import { generateLanguageURL } from 'utils/languages';
import { orderDirection } from '../../common-types/common';
import {
  makeCreateRequest,
  makeDeleteRequest,
  makePatchUpdateRequest,
} from './make-request';
import {
  normalizeAttachmentsListResponse,
  normalizeElementsListResponse,
  templateCategoriesListSchema,
} from './schemas';

type templatesListParams = {
  categoryId?: string;
  instituteId?: number;
  userId?: number;
  orderBy?: 'id' | 'name' | 'totalEstimatedTime' | 'author' | 'updatedAt';
  orderDirection?: orderDirection;
  query?: string;
  range: string;
};

export const readTemplates = (params: templatesListParams): Promise<any> => {
  return APIv1.get('/operation_templates', { params });
};

export const readCategoriesReq = (config?) =>
  APIv1.get('/categories', config).then(
    normalizeResponse(templateCategoriesListSchema)
  );

export const readTemplateCategory = (categoryId) =>
  APIv1.get(`/categories/${categoryId}`);

export const createTemplate = makeCreateRequest('/operation_templates');

export const readTemplate = (id, lang) =>
  APIv1.get(generateLanguageURL(`/operation_templates/${id}`, lang));

export const readTemplateNotes = (id, lang) =>
  APIv1.get(generateLanguageURL(`/operation_templates/${id}/notes`, lang));

export const updateTemplate = makePatchUpdateRequest('/operation_templates');
export const deleteTemplate = makeDeleteRequest('/operation_templates');

export const readTemplateElements = (id, lang) =>
  APIv1.get(
    generateLanguageURL(`/operation_templates/${id}/elements`, lang)
  ).then(
    // @ts-ignore
    normalizeElementsListResponse
  );

export const readTemplateElementsAttachments = (templateId) =>
  APIv1.get(`/operation_templates/${templateId}/elements/attachments`).then(
    normalizeAttachmentsListResponse
  );

export const updateTemplateElementById = (
  templateId,
  elementId,
  element,
  lang?
) => {
  return APIv1.patch(
    generateLanguageURL(
      `/operation_templates/${templateId}/${elementId}/element`,
      lang
    ),
    { element },
    {
      ignoreRequestBodyClean: true,
    }
    // @ts-ignore
  ).then(normalizeElementsListResponse);
};

export const updateTemplateElementAttachment = (
  templateId,
  elementId,
  attachment,
  lang?
) => {
  return APIv1.patch(
    generateLanguageURL(
      `/operation_templates/${templateId}/${elementId}/element/attachment`,
      lang
    ),
    { attachment },
    {
      ignoreRequestBodyClean: true,
    }
    // @ts-ignore
  ).then(normalizeElementsListResponse);
};

export const updateTemplateElements = (id, elements, lang?) => {
  return APIv1.post(
    generateLanguageURL(`/operation_templates/${id}/elements`, lang),
    { elements },
    {
      ignoreRequestBodyClean: true,
    }
    // @ts-ignore
  ).then(normalizeElementsListResponse);
};

export const reorderTemplateElements = (id, elements, lang?) => {
  return APIv1.post(
    generateLanguageURL(`/operation_templates/${id}/elements/reorder`, lang),
    { elements },
    {
      ignoreRequestBodyClean: true,
    }
    // @ts-ignore
  ).then(normalizeElementsListResponse);
};

export const addTemplateElements = (id, element, lang?) => {
  return (
    APIv1.post(
      generateLanguageURL(`/operation_templates/${id}/element`, lang),
      { element },
      {
        ignoreRequestBodyClean: true,
      }
    )
      // @ts-ignore
      .then(normalizeElementsListResponse)
  );
};

export const deleteTemplateElement = (id, uuid, lang?) => {
  return (
    APIv1.delete(
      generateLanguageURL(`/operation_templates/${id}/${uuid}/element`, lang)
    )
      // @ts-ignore
      .then(normalizeElementsListResponse)
  );
};

export const createTemplateSnapshot = (id, isActive = false) =>
  APIv1.post(`/operation_templates/${id}/snapshot`, { isActive });

export const readTemplateAttachments = (templateId, options) =>
  APIv1.get(`/operation_templates/${templateId}/attachments`, options).then(
    normalizeResponse(templateAttachmentsResponse)
  );

export const updateTemplateAttachments = (
  templateId,
  attachmentsIds: number[]
) =>
  APIv1.put(`/operation_templates/${templateId}/attachments`, {
    attachments: attachmentsIds,
  }).then(normalizeResponse(templateAttachmentsResponse));
