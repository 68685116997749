import React, { FC, memo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

interface PersistStateLinkProps extends LinkProps {
  /**
   * Link destination
   */
  to: string;
}

/**
 * PersistStateLink component is used to store the path and search query into the history state,
 * to help build more intelligent "back" buttons.
 *
 * For example, it's used within `HistoryList`(title link) + `OperationHistoryHeader`(close button)
 */
export const PersistStateLink: FC<PersistStateLinkProps> = memo(
  function PersistStateLink({ children, to, ...props }) {
    const { pathname: from, search, state: currentState } = useLocation();

    const state = currentState || { search, from };

    return (
      <Link
        to={{
          pathname: to,
          state,
        }}
        {...props}
      >
        {children}
      </Link>
    );
  }
);
