import { combineReducers } from 'redux';

import {
  READ_OPERATIONS_HISTORY_SUCCESS,
  READ_OPERATIONS_STATS_SUCCESS,
} from 'store/modules/history/types';

export const operationsHistoryReducer = (state = null, { type, payload }) => {
  switch (type) {
    case READ_OPERATIONS_HISTORY_SUCCESS:
      return payload.result;

    default:
      return state;
  }
};

const paginationInitialState = Object.freeze({
  current: 1,
  total: null,
});

export const historyPaginationReducer = (
  state = paginationInitialState,
  { type, payload }
) => {
  switch (type) {
    case READ_OPERATIONS_HISTORY_SUCCESS:
      return { ...state, total: payload?.meta?.range.total };

    default:
      return state;
  }
};

export const operationsHistoryStatsReducer = (
  state = null,
  { type, payload }
) => {
  switch (type) {
    case READ_OPERATIONS_STATS_SUCCESS: {
      return !payload.current ? { current: { ...payload.all } } : payload;
    }

    default:
      return state;
  }
};

export default combineReducers({
  operations: operationsHistoryReducer,
  page: historyPaginationReducer,
  stats: operationsHistoryStatsReducer,
});
