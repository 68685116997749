import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';

export default (
  paramsPath = '*',
  shouldWrapWithRouter = false
) => ComponentToWrap => {
  const getRouterParams = params => {
    if (!paramsPath || !params) return {};
    if (paramsPath === '*') {
      return params;
    } else if (typeof paramsPath === 'string') {
      return { [paramsPath]: get(params, paramsPath) };
    } else if (Array.isArray(paramsPath)) {
      return paramsPath.reduce((acc, name) => {
        const paramValue = params[name];
        if (!paramValue) return acc;
        return { ...acc, [name]: paramValue };
      }, {});
    }
  };

  function WrappedComponent(props) {
    const routerParams = getRouterParams(get(props, 'match.params'));

    return (
      <ComponentToWrap
        {...{
          ...routerParams,
          ...props,
        }}
      />
    );
  }

  hoistStatics(WrappedComponent, ComponentToWrap);

  if (shouldWrapWithRouter) {
    return withRouter(WrappedComponent);
  }

  return WrappedComponent;
};
