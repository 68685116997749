import { normalize } from 'utils/normalize';
import { libraryElementsListSchema } from 'api/v1/schemas';
import {
  SEARCH_LIBRARY_ELEMENTS_REQUEST,
  SEARCH_LIBRARY_ELEMENTS_SUCCESS,
  SEARCH_LIBRARY_ELEMENTS_FAILURE,
  SEARCH_LIBRARY_ELEMENTS_RESET,
} from './types';

export const searchRequest = (query, categories, lang) => ({
  type: SEARCH_LIBRARY_ELEMENTS_REQUEST,
  payload: { query, categories, lang },
});

export const searchSuccess = (list) => ({
  type: SEARCH_LIBRARY_ELEMENTS_SUCCESS,
  payload: { ...normalize(list, libraryElementsListSchema) },
});

export const searchFailure = (error) => ({
  type: SEARCH_LIBRARY_ELEMENTS_FAILURE,
  payload: { error },
});

export const searchReset = () => ({
  type: SEARCH_LIBRARY_ELEMENTS_RESET,
});
