import { createSelector } from 'reselect';
import { selectOperationSteps } from 'store/modules/entities/selectors/operations';
import { createElementByUUIDSelector } from 'store/modules/library';
import { createStateGetter } from 'store/utils';
import { accumulate } from 'utils';
import { selectOperationElementsAttachments } from './attachments';

export const selectOperationStepsAttachments = (selectOperationSteps) =>
  createSelector([selectOperationSteps], (steps) => {
    const attachments2D =
      steps?.filter?.((element) => element.attachmentsData) ?? [];

    return attachments2D.length ? attachments2D.flat() : attachments2D;
  });

export const selectOperationElementsAttachmentsById = (operationId) => (
  state
) => selectOperationElementsAttachments(state, { id: operationId });

export const selectTotalStepsEstimation = createSelector(
  selectOperationSteps,
  (steps) => (steps ? accumulate(steps, 'estimatedTime') : 0)
);

export const createElementAttachmentsIdsSelector = (uuid) => {
  const selectElement = createElementByUUIDSelector(uuid);
  return createSelector(selectElement, createStateGetter('attachments'));
};
