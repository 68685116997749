import React, { FC } from 'react';

import { InputBase } from '../InputBase';

interface InputDurationProps {
  value?: any;
  onChange?: (...args) => void;
}

export const InputDuration: FC<InputDurationProps> = ({
  onChange,
  value,
  ...inputProps
}) => {
  const handleChange = (event) => {
    event.target.value =
      event.target.value && parseFloat(event.target.value) * 60;
    if (!onChange) return;
    onChange(event);
  };

  return (
    <InputBase
      type="number"
      value={value && parseFloat(value) / 60}
      onChange={handleChange}
      {...inputProps}
    />
  );
};
