import React, { FC } from 'react';
import { SortDirections } from '../../app-constants';
import cn from 'classnames';

import styles from './Table.module.scss';
import { HeaderCol } from './HeaderCol';
import { get } from 'lodash';

export interface TableFixedHeaderProps {
  columns: any[];
  className?: string;
  classNameCol?: string;
  onColHeaderClick?(params: { ob: string; od: string }): void;
  searchParams?: {
    od: SortDirections;
    ob: string;
  };
}

const calcPercentage = (base: number, total: number) => (base * 99) / total;

const getColumnTitle = (col) => col.Header || col.title || col.name;
const getColumnWidth = (col) => col.width || col.minWidth || 0;

export const TableFixedHeader: FC<TableFixedHeaderProps> = ({
  columns = [],
  classNameCol = '',
  className,
  onColHeaderClick,
  searchParams = {
    ob: null,
    od: SortDirections.ASC,
  },
}) => {
  const handleSort = (orderBy: string, orderDirection: string) => {
    onColHeaderClick?.({ ob: orderBy, od: orderDirection });
  };

  const totalWidth = () => {
    if (!columns) return 0;

    return columns.reduce((acc, col) => acc + getColumnWidth(col), 0);
  };

  const orderByParam = () => {
    return get(searchParams, 'ob', null);
  };

  const orderDirectionParam = () => {
    return get(searchParams, 'od');
  };

  return (
    <div className={cn(className, styles.tableHeaderRow)} role="row">
      {columns.map((col, index) => {
        const title = getColumnTitle(col);
        const width = getColumnWidth(col);
        const isSortable = Boolean(col.isSortable);
        const isActive = orderByParam() === col.accessor;

        return (
          <HeaderCol
            key={`${title}-${index}`}
            className={cn(classNameCol, col.classNameHeader)}
            title={title}
            isSortable={isSortable}
            accessor={col.accessor}
            orderDirection={orderDirectionParam()}
            isActive={isActive}
            style={{
              minWidth: `${width}px`,
              width: `${calcPercentage(width, totalWidth())}%`,
            }}
            onSort={col.isSortable && handleSort}
          />
        );
      })}
    </div>
  );
};
