import * as APIv1 from 'api/v1';
import { getCleanTemplateElement } from 'store/modules/operation-planning';
import {
  makeThunkFromTypes,
  makeThunkFromTypeString,
} from 'store/utils/make-thunk';
import i18n from 'translations/i18n-instance';
import {
  deleteTemplateAttachmentRequest,
  deleteTemplateAttachmentsFailure,
  deleteTemplateAttachmentsSuccess,
} from '../actions';
import {
  selectTemplateAttachmentsIds,
  selectTemplateElements,
} from '../selectors';
import * as actionTypes from '../types';
import { updateTemplate, updateTemplateElements } from './common';

export const addTemplateAttachments =
  (templateId, newAttachments, prevAttachments) => (dispatch, getState) => {
    let templateAttachments = prevAttachments;
    if (!prevAttachments) {
      templateAttachments =
        selectTemplateAttachmentsIds(getState(), {
          templateId,
        }) || [];
    }

    // FIXME: this logic should be executed within reducer
    const updateAction = updateTemplate(templateId, {
      attachments: [...templateAttachments, ...newAttachments],
    });
    dispatch(updateAction);
  };

export const deleteTemplateAttachment =
  (templateId, id, prevAttachedFilesIds) => async (dispatch) => {
    try {
      dispatch(deleteTemplateAttachmentRequest(templateId, id));
      const response = await APIv1.deleteAttachment(id);

      dispatch(deleteTemplateAttachmentsSuccess(response));

      // FIXME: this logic should be executed within reducer
      dispatch(
        updateTemplate(templateId, {
          attachments: [...prevAttachedFilesIds.filter((v) => v !== id)],
        })
      );
    } catch (error) {
      dispatch(deleteTemplateAttachmentsFailure(error));
    }
  };

export const readTemplateElementsAttachments = makeThunkFromTypes(
  APIv1.readTemplateElementsAttachments,
  {
    request: actionTypes.READ_TEMPLATE_ELEMENTS_ATTACHMENTS_REQUEST,
    success: actionTypes.READ_TEMPLATE_ELEMENTS_ATTACHMENTS_SUCCESS,
    failure: actionTypes.READ_TEMPLATE_ELEMENTS_ATTACHMENTS_FAILURE,
  },
  (id) =>
    `${i18n.t('errors:failedToReadTemplateElementsAttachments')} (id: ${id})`
);

export const {
  asyncActionTypes: {
    request: READ_TEMPLATE_ATTACHMENTS_REQUEST,
    success: READ_TEMPLATE_ATTACHMENTS_SUCCESS,
    failure: READ_TEMPLATE_ATTACHMENTS_FAILURE,
  },
  thunk: readTemplateAttachments,
} = makeThunkFromTypeString(
  APIv1.readTemplateAttachments,
  'READ_TEMPLATE_ATTACHMENTS'
);

export const {
  asyncActionTypes: {
    request: UPDATE_TEMPLATE_ATTACHMENTS_REQUEST,
    success: UPDATE_TEMPLATE_ATTACHMENTS_SUCCESS,
    failure: UPDATE_TEMPLATE_ATTACHMENTS_FAILURE,
  },
  thunk: updateTemplateAttachments,
} = makeThunkFromTypeString(
  APIv1.updateTemplateAttachments,
  'UPDATE_TEMPLATE_ATTACHMENTS'
);

export const deleteTemplateElementAttachment =
  ({ templateId, attachmentId }) =>
  (dispatch, getState) => {
    const state = getState();
    const elementsList = selectTemplateElements(state, { templateId });
    if (!Array.isArray(elementsList)) return;
    const elementsUpdate = elementsList.map((element) => {
      const elementAttachmentsIds = element.attachments;
      const hasAttachment =
        elementAttachmentsIds?.length &&
        element.attachments.some((a) => a === attachmentId);
      if (!hasAttachment) return getCleanTemplateElement(element);

      element.attachments = element.attachments.filter(
        (a) => a !== attachmentId
      );
      return getCleanTemplateElement(element);
    }, []);

    return dispatch(updateTemplateElements(templateId, elementsUpdate));
  };
