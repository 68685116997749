import namespace from './namespace';

/* **** Institute **** */

export const READ_INSTITUTE_REQUEST = `${namespace}READ_INSTITUTE_REQUEST`;
export const READ_INSTITUTE_SUCCESS = `${namespace}READ_INSTITUTE_SUCCESS`;
export const READ_INSTITUTE_FAILURE = `${namespace}READ_INSTITUTE_FAILURE`;

export const READ_INSTITUTE_ADDRESS_REQUEST = `${namespace}READ_INSTITUTE_ADDRESS_REQUEST`;
export const READ_INSTITUTE_ADDRESS_SUCCESS = `${namespace}READ_INSTITUTE_ADDRESS_SUCCESS`;
export const READ_INSTITUTE_ADDRESS_FAILURE = `${namespace}READ_INSTITUTE_ADDRESS_FAILURE`;

export const UPDATE_INSTITUTE_REQUEST = `${namespace}UPDATE_INSTITUTE_REQUEST`;
export const UPDATE_INSTITUTE_SUCCESS = `${namespace}UPDATE_INSTITUTE_SUCCESS`;
export const UPDATE_INSTITUTE_FAILURE = `${namespace}UPDATE_INSTITUTE_FAILURE`;

export const READ_INSTITUTES_LIST_REQUEST = `${namespace}READ_INSTITUTES_LIST_REQUEST`;
export const READ_INSTITUTES_LIST_SUCCESS = `${namespace}READ_INSTITUTES_LIST_SUCCESS`;
export const READ_INSTITUTES_LIST_FAILURE = `${namespace}READ_INSTITUTES_LIST_FAILURE`;

/* **** Department **** */

export const UPDATE_DEPARTMENT_REQUEST = `${namespace}UPDATE_DEPARTMENT_REQUEST`;
export const UPDATE_DEPARTMENT_SUCCESS = `${namespace}UPDATE_DEPARTMENT_SUCCESS`;
export const UPDATE_DEPARTMENT_FAILURE = `${namespace}UPDATE_DEPARTMENT_FAILURE`;

export const READ_DEPARTMENTS_LIST_REQUEST = `${namespace}READ_DEPARTMENTS_LIST_REQUEST`;
export const READ_DEPARTMENTS_LIST_SUCCESS = `${namespace}READ_DEPARTMENTS_LIST_SUCCESS`;
export const READ_DEPARTMENTS_LIST_FAILURE = `${namespace}READ_DEPARTMENTS_LIST_FAILURE`;

/* **** Profile **** */

export const UPDATE_PROFILE_REQUEST = `${namespace}UPDATE_PROFILE_REQUEST`;
export const UPDATE_PROFILE_SUCCESS = `${namespace}UPDATE_PROFILE_SUCCESS`;
export const UPDATE_PROFILE_FAILURE = `${namespace}UPDATE_PROFILE_FAILURE`;

/* Email */
export const UPDATE_PROFILE_EMAIL_REQUEST = `${namespace}UPDATE_PROFILE_EMAIL_REQUEST`;
export const UPDATE_PROFILE_EMAIL_SUCCESS = `${namespace}UPDATE_PROFILE_EMAIL_SUCCESS`;
export const UPDATE_PROFILE_EMAIL_FAILURE = `${namespace}UPDATE_PROFILE_EMAIL_FAILURE`;

/* **** Password **** */

export const UPDATE_PASSWORD_REQUEST = `${namespace}UPDATE_PASSWORD_REQUEST`;
export const UPDATE_PASSWORD_SUCCESS = `${namespace}UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAILURE = `${namespace}UPDATE_PASSWORD_FAILURE`;

/* **** Surgeon pin **** */

export const CREATE_SURGEON_PIN_REQUEST = `${namespace}CREATE_SURGEON_PIN_REQUEST`;
export const CREATE_SURGEON_PIN_SUCCESS = `${namespace}CREATE_SURGEON_PIN_SUCCESS`;
export const CREATE_SURGEON_PIN_FAILURE = `${namespace}CREATE_SURGEON_PIN_FAILURE`;

export const VIEW_SURGEON_PIN_REQUEST = `${namespace}VIEW_SURGEON_PIN_REQUEST`;
export const VIEW_SURGEON_PIN_SUCCESS = `${namespace}VIEW_SURGEON_PIN_SUCCESS`;
export const VIEW_SURGEON_PIN_FAILURE = `${namespace}VIEW_SURGEON_PIN_FAILURE`;
export const RESET_VIEW_SURGEON_PIN = `${namespace}RESET_VIEW_SURGEON_PIN`;

export const RESET_SURGEON_PIN_REQUEST = `${namespace}RESET_SURGEON_PIN_REQUEST`;
export const RESET_SURGEON_PIN_SUCCESS = `${namespace}RESET_SURGEON_PIN_SUCCESS`;
export const RESET_SURGEON_PIN_FAILURE = `${namespace}RESET_SURGEON_PIN_FAILURE`;
