import { CREATE } from 'app-constants/form-modes';
import {
  TemplateCategoryBreadcrumbs,
  Loader,
  TranslatableString,
  Text,
  OverflowText,
} from 'components';
import { withPageTitle } from 'containers/PageTitle';
import { bool, func, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { idTypes } from 'shared-prop-types';
import { createLoadingSelector } from 'store/modules/api-requests';
import { READ_TEMPLATE_CATEGORY_REQUEST } from 'store/modules/template-categories/types';
import {
  readTemplate,
  READ_TEMPLATE_REQUEST,
} from 'store/modules/templates-library';
import styles from './TitleAndTemplateCategory.module.scss';

export class TitleAndTemplateCategory extends Component {
  static _propTypes = {
    categoryId: idTypes,
    isLoading: bool,
    pageTitle: string.isRequired,
    requestTemplate: func.isRequired,
    templateId: idTypes,
    title: string,
  };

  static defaultProps = {
    categoryId: null,
    isLoading: false,
    templateId: null,
    title: null,
  };

  componentDidMount() {
    this.checkTemplate();
  }

  componentDidUpdate(prevProps) {
    const { templateId } = this.props;
    if (
      !prevProps.templateId &&
      templateId &&
      templateId !== prevProps.templateId
    ) {
      this.checkTemplate();
    }
  }

  checkTemplate() {
    const { isLoading, templateId, requestTemplate, categoryId, title } =
      this.props;

    const shouldFetch = !title && !categoryId && !isLoading;
    const hasId = templateId && templateId !== CREATE;

    if (shouldFetch && hasId) {
      requestTemplate(templateId);
    }
  }

  render() {
    const { title, isLoading, categoryId, pageTitle } = this.props;

    return (
      <>
        <Text variant="heading-xl" className={styles.title}>
          <TranslatableString string={title}>
            {(translatedTitle) => (
              <OverflowText component="span" maxLines={1}>
                {translatedTitle || pageTitle}
              </OverflowText>
            )}
          </TranslatableString>
        </Text>
        {isLoading ? (
          <Loader mode="minimal" />
        ) : (
          categoryId && <TemplateCategoryBreadcrumbs categoryId={categoryId} />
        )}
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  READ_TEMPLATE_REQUEST,
  READ_TEMPLATE_CATEGORY_REQUEST,
]);
const mapStateToProps = createStructuredSelector({
  isLoading: loadingSelector,
});
const mapDispatchToProps = { requestTemplate: readTemplate };
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhancer = compose(withConnect, withPageTitle);

export default enhancer(TitleAndTemplateCategory);
