import {
  createFailureActionCreator,
  createSuccessActionCreator,
} from 'store/utils';

import * as types from './types';

export const readScheduledOperationsRequest = (fromDate, toDate) => ({
  type: types.READ_SCHEDULED_OPERATIONS_REQUEST,
  payload: { fromDate, toDate },
});

export const readScheduledOperationsSuccess = createSuccessActionCreator(
  types.READ_SCHEDULED_OPERATIONS_SUCCESS
);

export const readScheduledOperationsFailure = createFailureActionCreator(
  types.READ_SCHEDULED_OPERATIONS_FAILURE
);
