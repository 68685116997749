import { ALLOWED_FILE_TYPES } from 'app-constants/files';
import { ClipboardEvent } from 'react';

export const isPasteFilesEvent: (event: ClipboardEvent<any>) => boolean = (
  event
) => {
  const hasRichText =
    event?.clipboardData?.getData('text/html') &&
    event?.clipboardData?.getData('text/plain');
  const hasImages =
    event?.clipboardData?.files &&
    Array.from(event.clipboardData.files).some((file) =>
      (ALLOWED_FILE_TYPES as string[]).includes(file.type)
    );

  return hasImages && !hasRichText;
};
