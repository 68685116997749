import qs from 'qs';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from 'components';
import { NEW_OPERATION } from 'scenes/routes.enum';
import { startOperationCreation } from 'store/modules/operation-planning';

interface PlanOperationFromTemplateButtonProps extends ButtonProps {
  /**
   * Template id from which create new operation
   */
  templateId?: string;
}

/**
 * Renders button connected to redux store which handles create operation from template functionality
 */
export const PlanOperationFromTemplateButton: FC<PlanOperationFromTemplateButtonProps> =
  ({ children, disabled, ...props }) => {
    const { t } = useTranslation();

    const params = useParams<{ templateId?: string }>();
    const templateId = props.templateId || params.templateId;

    const createOperationHref = `${NEW_OPERATION}?${qs.stringify({
      fromTemplate: templateId,
    })}`;

    const dispatch = useDispatch();

    const handleButtonClick = () => {
      dispatch(startOperationCreation(templateId));
    };

    return (
      <Button
        variant="secondary"
        title={t('hint:planOperationFromTheTemplate')}
        onClickCapture={handleButtonClick}
        disabled={disabled}
        component={disabled ? undefined : Link}
        to={disabled ? undefined : createOperationHref}
        {...props}
      >
        {children || t('planOP')}
      </Button>
    );
  };

export default PlanOperationFromTemplateButton;
