import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from '../Select.module.scss';
import { GetItemPropsOptions } from 'downshift';
import { Option } from 'common-types/common';

interface SelectOptionProps {
  getItemProps: (options: GetItemPropsOptions<any>) => void;
  index: number;
  isCurrent?: boolean;
  isHighlighted?: boolean;
  isSelected?: boolean;
  option: Option;
  renderInner: (...args) => ReactNode;
  disabled?: boolean;
}

export const SelectOption: FC<SelectOptionProps> = ({
  getItemProps,
  index,
  isCurrent,
  isHighlighted = false,
  isSelected,
  option,
  renderInner,
  disabled,
}) => {
  return (
    <div
      {...(isSelected
        ? {}
        : getItemProps({
            index,
            item: option,
            key: [index, option.value, option.label].join(),
            disabled,
          }))}
      className={cn('Select__option', styles.option, {
        [styles.optionHighlighted]: isHighlighted,
        [styles.optionSelected]: isSelected,
      })}
      data-value={option.value}
    >
      {renderInner(option, { isCurrent, isSelected, isHighlighted })}
    </div>
  );
};
