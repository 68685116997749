import { Button } from 'components';
import React, { ComponentType } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NEW_TEMPLATE } from 'scenes/routes.enum';

interface Props {}

const NewTemplateButton: ComponentType<Props> = () => {
  const { t } = useTranslation();

  const sendAnalyticsEvent = () => {
    ReactGA.event({
      category: 'OP planning',
      action: 'Create Template',
    });
  };

  return (
    <Button
      component={Link}
      to={NEW_TEMPLATE}
      onClickCapture={sendAnalyticsEvent}
    >
      {t('createTemplate')}
    </Button>
  );
};

export default NewTemplateButton;
