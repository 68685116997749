import Icon from 'assets/Spine.svg';
import cn from 'classnames';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './AttachmentPlaceholder.module.scss';

interface Props {
  className?: string;
  readonly?: boolean;
  onClick?: (event: any) => void;
}

const AttachmentPlaceholder: ComponentType<Props> = ({
  className,
  readonly = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(className, 'text-center', classes.attachmentPlaceholder)}
      onClick={onClick}
    >
      <span
        className={classes.attachmentPlaceholderImage}
        style={{ backgroundImage: `url(${Icon})` }}
      />
      {!readonly && (
        <small className="text-center text-lighter nowrap">
          <strong>{t('attachImage', { count: 2 })}</strong>
          <br />({t('max')} 2)
        </small>
      )}
    </div>
  );
};

export default AttachmentPlaceholder;
