import partial from 'lodash/partial';
import { createActionType } from 'store/utils/create-action-type';

export const namespace = 'assessmentForm';
const action = partial(createActionType, namespace);

export const CREATE_ASSESSMENT_FORM_REQUEST = action(
  'CREATE_ASSESSMENT_FORM_REQUEST'
);
export const CREATE_ASSESSMENT_FORM_SUCCESS = action(
  'CREATE_ASSESSMENT_FORM_SUCCESS'
);
export const CREATE_ASSESSMENT_FORM_FAILURE = action(
  'CREATE_ASSESSMENT_FORM_FAILURE'
);

export const READ_ASSESSMENT_FORMS_REQUEST = action(
  'READ_ASSESSMENT_FORMS_REQUEST'
);
export const READ_ASSESSMENT_FORMS_SUCCESS = action(
  'READ_ASSESSMENT_FORMS_SUCCESS'
);
export const READ_ASSESSMENT_FORMS_FAILURE = action(
  'READ_ASSESSMENT_FORMS_FAILURE'
);

export const DELETE_ASSESSMENT_FORM_REQUEST = action(
  'DELETE_ASSESSMENT_FORM_REQUEST'
);
export const DELETE_ASSESSMENT_FORM_SUCCESS = action(
  'DELETE_ASSESSMENT_FORM_SUCCESS'
);
export const DELETE_ASSESSMENT_FORM_FAILURE = action(
  'DELETE_ASSESSMENT_FORM_FAILURE'
);

export const UPDATE_ASSESSMENT_FORM_REQUEST = action(
  'UPDATE_ASSESSMENT_FORM_REQUEST'
);
export const UPDATE_ASSESSMENT_FORM_SUCCESS = action(
  'UPDATE_ASSESSMENT_FORM_SUCCESS'
);
export const UPDATE_ASSESSMENT_FORM_FAILURE = action(
  'UPDATE_ASSESSMENT_FORM_FAILURE'
);

export const ADD_FORM_TITLE = action('ADD_FORM_TITLE');
