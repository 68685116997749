import thunk from 'redux-thunk';
import { LOGOUT_SUCCESS } from 'store/modules/auth';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createRootReducer from './root-reducer';
import {
  gaEventsTrackingMiddleware,
  gaTimingTrackingMiddleware,
} from './middlewares';
import history from './history';

const devMiddlewares = [];

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_DISABLE_REDUX_LOGGER !== 'true'
) {
  const { logger } = require('./redux-logger');
  devMiddlewares.push(logger);
}

function setupStore(history) {
  const persistConfig = {
    key: 'root',
    storage,
  };

  const middlewares = applyMiddleware(
    thunk,
    routerMiddleware(history),
    gaEventsTrackingMiddleware,
    gaTimingTrackingMiddleware,
    ...devMiddlewares
  );

  const enhancer = composeWithDevTools({})(middlewares);

  const connectedReducer = createRootReducer(history);
  const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
      return connectedReducer(undefined, action);
    }

    return connectedReducer(state, action);
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);

  return { store, persistor };
}

const { store, persistor } = setupStore(history);

export { store, persistor };
