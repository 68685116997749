import { schema } from 'normalizr';

import { attachmentsSchema } from './file';
import { libraryElementsSchema } from './library-elements';

export const materialsGroupSchema = new schema.Entity('materialsGroups', {
  attachments: [attachmentsSchema],
  equipment: [libraryElementsSchema],
  instruments: [libraryElementsSchema],
  implants: [libraryElementsSchema],
});

export const operationMaterialsSchema = new schema.Entity(
  'operationMaterials',
  {
    equipment: [libraryElementsSchema],
    instruments: [libraryElementsSchema],
    implants: [libraryElementsSchema],
    materialsGroups: [materialsGroupSchema],
  },
  {
    idAttribute: 'operationId',
  }
);

export const templateMaterialsSchema = new schema.Entity(
  'templateMaterials',
  {
    equipment: [libraryElementsSchema],
    instruments: [libraryElementsSchema],
    implants: [libraryElementsSchema],
    materialsGroups: [materialsGroupSchema],
  },
  {
    idAttribute: 'templateId',
  }
);
