import React, { FC, Fragment } from 'react';

export interface MultilineProps {
  string?: string;
}

export const Multiline: FC<MultilineProps> = ({ string = '', children }) => {
  const inner = children || string;
  if (!inner || typeof inner !== 'string') return null;
  const lines = inner.split('\n');

  return (
    <>
      {lines.map((line, index) => {
        if (index === lines.length - 1)
          return <Fragment key={index}>{line}</Fragment>;

        return (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        );
      })}
    </>
  );
};
