import { LanguageBadge } from 'components/Badge';
import { IconButton } from 'components/Button';
import React, { ComponentType } from 'react';
import {
  TranslatableInputProps,
  useTranslatableInput,
} from '../../hooks/useTranslatableInput';
import Input from '../Input';
import classes from '../Input.module.scss';

interface Props extends TranslatableInputProps {}

export const TranslatableInput: ComponentType<Props & any> = ({
  value,
  name,
  inputLanguage,
  onLanguageChange,
  ...inputProps
}) => {
  const { name: translatableName, value: translatableValue } =
    useTranslatableInput({ name, value, inputLanguage });

  const switchLanguage = () => {
    onLanguageChange(inputLanguage === 'en' ? 'de' : 'en');
  };

  const append = (
    <div className={classes.languageBadge}>
      <LanguageBadge alwaysDisplay lang={inputLanguage} />
      <IconButton
        type="button"
        icon="replace"
        color="primary"
        onClick={switchLanguage}
      />
    </div>
  );

  return (
    <Input
      name={translatableName}
      value={translatableValue}
      append={append}
      {...inputProps}
    />
  );
};

TranslatableInput.displayName = 'TranslatableInput';
