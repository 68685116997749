import React, { FC } from 'react';
import ReactInputMask from 'react-input-mask';
import { InputBase } from '../InputBase';

interface InputMaskProps {
  mask: string;
}

export const InputMask: FC<InputMaskProps> = ({ mask, ...inputProps }) => {
  return <InputBase component={ReactInputMask} mask={mask} {...inputProps} />;
};
