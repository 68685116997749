import React, { FC, useRef, useState } from 'react';
import classnames from 'classnames';

import { ENTER, ESCAPE } from 'app-constants/keycodes';

import { Textarea } from '../../Textarea';
import Input from '../Input';

import styles from './EditableInput.module.scss';
import { ManualPopover } from 'components/Popover';

interface EditableInputProps {
  error?: string | null | unknown;
  inputClassName?: string;
  onEdit?: (...args) => void;
  onEditCancel?: (...args) => void;
  onEditComplete?: (...args) => void;
  onInvalid?: (...args) => void;
  textarea?: boolean;
  className?: string;
  name?: string;
  type?: string;
  min?: string;
  defaultValue?: string | number;
  componentType?: string;
  onChange?(e: any): void;
}

export const EditableInput: FC<EditableInputProps> = ({
  error = '',
  inputClassName = '',
  onEdit,
  onEditCancel,
  onEditComplete,
  onInvalid,
  textarea = false,
  className,
  type,
  name,
  defaultValue,
  // FIXME: check parent users of this component and remove unused props
  /* eslint-disable @typescript-eslint/no-unused-vars */
  ...restProps
}) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [b, setB] = useState(0); // FIXME: hack for force updates
  const inputElement = useRef<any>();

  const inputProps = {
    error,
    onInvalid,
    className,
    name,
    type,
  };

  const getErrorMessage = () => {
    const input = inputElement.current;

    if (error) return error;
    if (input && !input.validity.valid) {
      return input.validationMessage;
    }

    return null;
  };

  const handleEdit = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onEdit) {
      onEdit(event.target.value);
    }
    if (!inputElement.current) return;
    const input = inputElement.current;
    const isBlur = !!(event && event.type === 'blur');

    if (input.validity.valid) {
      if (onEditComplete) {
        onEditComplete(event);
      }
      // if (onEdit) {
      //   onEdit(input.value);
      // }
    } else {
      const { value, validationMessage, validity } = input;
      if (onInvalid) {
        onInvalid({ value, validationMessage, validity }, isBlur);
        // FIXME: error is not rendered without this
        setB((state) => state + 1);
      }
    }
  };

  const handleKeydown = (event) => {
    if (event.keyCode === ENTER) {
      if (textarea && !(event.metaKey || event.ctrlKey)) return;
      // Submit edit on `cmd/ctrl + Enter`
      handleEdit(event);
    } else if (event.keyCode === ESCAPE && onEditCancel) {
      // Cancel editing on `ESCAPE`
      onEditCancel();
    }
  };

  const InputComponent = textarea ? Textarea : Input;
  const errorMessage = getErrorMessage();

  return (
    <span className={classnames(className, styles.wrapper)}>
      <InputComponent
        {...inputProps}
        autoFocus
        className={classnames(inputClassName, { [styles.hasError]: !!error })}
        innerRef={inputElement}
        onBlur={handleEdit}
        onKeyDown={handleKeydown}
        defaultValue={defaultValue}
      />

      {error && (
        <ManualPopover className={styles.error}>{errorMessage}</ManualPopover>
      )}
    </span>
  );
};
