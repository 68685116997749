import { APIv1 } from 'api/instance';
import {
  makeCreateRequest,
  makeReadRequest,
  makeUpdateRequest,
  makeDeleteRequest,
} from './make-request';

export const createChecks = makeCreateRequest('/checks');
export const readChecks = makeReadRequest('/checks');
export const updateChecks = makeUpdateRequest('/checks');
export const deleteChecks = makeDeleteRequest('/checks');

export const createEquipment = makeCreateRequest('/equipment');
export const readEquipment = makeReadRequest('/equipment');
export const updateEquipment = makeUpdateRequest('/equipment');
export const deleteEquipment = makeDeleteRequest('/equipment');

export const createImplants = makeCreateRequest('/implants');
export const readImplants = makeReadRequest('/implants');
export const updateImplants = makeUpdateRequest('/implants');
export const deleteImplants = makeDeleteRequest('/implants');

export const createInstruments = makeCreateRequest('/instruments');
export const readInstruments = makeReadRequest('/instruments');
export const updateInstruments = makeUpdateRequest('/instruments');
export const deleteInstruments = makeDeleteRequest('/instruments');

export const createProcedures = makeCreateRequest('/procedures');
export const readProcedures = makeReadRequest('/procedures');
export const updateProcedures = makeUpdateRequest('/procedures');
export const deleteProcedures = makeDeleteRequest('/procedures');

export const createRisks = makeCreateRequest('/risks');
export const readRisks = makeReadRequest('/risks');
export const updateRisks = makeUpdateRequest('/risks');
export const deleteRisks = makeDeleteRequest('/risks');

export const createPatientRisks = makeCreateRequest('/patient_risks');
export const readPatientRisks = makeReadRequest('/patient_risks');
export const updatePatientRisks = makeUpdateRequest('/patient_risks');
export const deletePatientRisks = makeDeleteRequest('/patient_risks');

export const createNotes = makeCreateRequest('/notes');
export const readNotes = makeReadRequest('/notes');
export const updateNotes = makeUpdateRequest('/notes');
export const deleteNotes = makeDeleteRequest('/notes');

export const searchItems = (query, categories, lang) => {
  const params = { query, lang };

  if (Array.isArray(categories) && categories.length) {
    params.categories = categories.join();
  }

  return APIv1.get('/libraries/search', { params }).then(data => data.results);
};

export const getDiagnosis = id => APIv1.get(`/diagnoses/${id}`);
