import React, { FC } from 'react';
import classnames from 'classnames';

import styles from './Panel.module.scss';

export interface PanelActionsProps {
  variant?: 'default' | 'floating';
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const PanelActions: FC<PanelActionsProps> = ({
  variant = 'default',
  className,
  children,
  ...restProps
}) => {
  return (
    <div
      className={classnames(className, styles.panelActions, {
        [styles[`actions-${variant}`]]: !!variant,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};
