import cn from 'classnames';
import { Label } from 'components/Form/FormInput';
import { Col, Row } from 'components/Grid';
import React from 'react';
import classes from './ToggleBox.module.scss';

export const ToggleBox = ({
  className = '',
  containerClassName = '',
  id,
  label = '',
  disabled = false,
  ...inputProps
}) => (
  <Row
    alignItems="center"
    className={cn('mb-1', containerClassName, classes.toggleContainer, {
      [classes.disabled]: disabled,
    })}
  >
    {label && (
      <Col>
        <Label className="mb-0">{label}</Label>
      </Col>
    )}
    <Col auto>
      <label htmlFor={id} className={cn(className, classes.box)}>
        <input
          id={id}
          type="checkbox"
          className={cn('ToggleBox__input', classes.checkboxInput)}
          disabled={disabled}
          {...inputProps}
        />
        <span className={classes.toggle}>
          <span className={classes.toggleButton} />
        </span>
      </label>
    </Col>
  </Row>
);
