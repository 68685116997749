import { createContext } from 'react';

interface MaterialsFormContextT {
  readonly: boolean;
  isLoading?: boolean;
}

export const MaterialsFormContext = createContext<MaterialsFormContextT>({
  readonly: true,
  isLoading: false,
});
export const { Consumer, Provider } = MaterialsFormContext;
