import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_KEY,
  USER_METADATA,
} from 'app-constants';

import storeInstance from './store';

export const User = (store) => {
  /* get, set, remove user data (email, username, surname) */
  const cleanUser = () => store.remove(USER_KEY);

  const set = (userData) => store.set(USER_KEY, userData);

  const get = () => {
    const userData = store.get(USER_KEY, localStorage);
    if (userData && userData.givenName && userData.surname && userData.email) {
      return userData;
    }
    return null;
  };

  const getKeepSession = () => {
    return store.shouldKeepSession();
  };

  const setKeepSession = (keep) => store.setKeepSession(keep);

  /* get, set, remove tokens */
  const setAccessToken = (token) => store.set(ACCESS_TOKEN_KEY, token);

  const getAccessToken = () => store.get(ACCESS_TOKEN_KEY);

  const setRefreshToken = (token) => store.set(REFRESH_TOKEN_KEY, token);

  const getRefreshToken = () => store.get(REFRESH_TOKEN_KEY);

  const removeTokens = () => {
    store.remove(ACCESS_TOKEN_KEY);
    store.remove(REFRESH_TOKEN_KEY);
  };

  const setTokens = (tokens) => {
    if (!tokens) return;

    setAccessToken(tokens.accessToken);
    setRefreshToken(tokens.refreshToken);
  };

  const authHeader = (token = getAccessToken()) =>
    token ? `Bearer ${token}` : null;

  const getMetadata = () => {
    const metadata = store.get(USER_METADATA);
    if (!metadata || typeof metadata !== 'object') return {};
    return metadata;
  };

  const setUserMetadataValue = (key, value) => {
    if (!key) {
      new TypeError('You should pass key to store value');
      return;
    }
    const metadata = store.get(USER_METADATA);
    return store.set(USER_METADATA, { ...metadata, [key]: value });
  };

  const getUserMetadataValue = (key) => {
    const metadata = getMetadata();
    return metadata[key];
  };

  return Object.freeze({
    authHeader,
    cleanUser,
    get,
    getAccessToken,
    getKeepSession,
    getRefreshToken,
    getUserMetadataValue,
    removeTokens,
    set,
    setKeepSession,
    setTokens,
    setUserMetadataValue,
  });
};

export default User(storeInstance);
