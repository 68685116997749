import React, { FC, HTMLAttributes } from 'react';

import styles from './PaperSheet.module.scss';

interface PaperSheetProps extends HTMLAttributes<HTMLDivElement> {}

export const PaperSheet: FC<PaperSheetProps> = ({ children, ...restProps }) => {
  return (
    <div className={styles.sheet} {...restProps}>
      {children}
    </div>
  );
};
