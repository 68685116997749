import React, { FC, memo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

interface StatefulLinkProps extends Omit<LinkProps, 'to'> {
  /**
   * Link destination. If undefined then it uses "from" property from location state.
   */
  to?: string;
}

/**
 * Link that uses location.state applied by PersistStateLink
 */
export const StatefulLink: FC<StatefulLinkProps> = memo(function StatefulLink({
  children,
  to,
  ...props
}) {
  const { state, pathname: currentPathname } = useLocation<{
    search?: string;
    from?: string;
  }>();

  const pathname = to || state?.from;
  const search = state?.search;
  const newState = { from: currentPathname, search: search || '' };

  return (
    <Link
      to={{
        pathname,
        search,
        state: newState,
      }}
      {...props}
    >
      {children}
    </Link>
  );
});
