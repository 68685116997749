import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'fast-deep-equal';

import styles from './OperationStepEditable.module.scss';

import {
  OperationStepEstimable,
  OperationStepInput,
} from 'common-types/library';
import { List } from 'components/List';
import { Text } from 'components/Typography';
import {
  OperationStepMenu,
  OperationStepContent,
  OperationStepAttachmentsForm,
} from 'components/ElementsForm/OperationStep';
import { id } from 'common-types/api';

type OperationStepProps = Pick<
  OperationStepEstimable,
  | 'attachments'
  | 'category'
  | 'description'
  | 'edited'
  | 'estimatedTime'
  | 'mitigationAction'
  | 'name'
  | 'uuid'
>;

interface OperationStepEditableProps extends OperationStepProps {
  onAttachmentDelete: (uuid: string, update: OperationStepInput) => void;
  onUpdate: (uuid: string, update: OperationStepInput) => void;
  onDelete: (uuid: string) => void;
  onAddToLibrary: () => void;
  onAttachmentClick: (attachmentId: number) => void;
  onAttachmentsFormOpen: (uuid: string) => void;
  wpa?: boolean;
}

export const OperationStepEditable: FC<OperationStepEditableProps> = memo(
  function OperationStepEditable({
    attachments = [],
    category,
    description,
    edited,
    estimatedTime,
    mitigationAction,
    name,
    uuid,
    wpa,
    onAddToLibrary,
    onAttachmentClick,
    onAttachmentsFormOpen,
    onAttachmentDelete,
    onDelete,
    onUpdate,
  }) {
    const { t } = useTranslation();

    const onRemoveAttachmentsHandler = useCallback(() => {
      onAttachmentDelete(uuid, {
        attachments: [],
      });
    }, [onUpdate, uuid]);

    const onRemoveAttachmentHandler = useCallback(
      (attachmentId: id) => {
        onAttachmentDelete(uuid, {
          attachments: attachments.filter((id) => id !== attachmentId),
        });
      },
      [attachments, onUpdate, uuid]
    );

    const onDeleteHandler = useCallback(() => {
      onDelete(uuid);
    }, [onDelete, uuid]);

    const onAttachmentsFormOpenHandler = useCallback(() => {
      onAttachmentsFormOpen?.(uuid);
    }, [onAttachmentsFormOpen, uuid]);

    return (
      <div className={styles.root}>
        <List id={`operation-element-${uuid}`} className={styles.list}>
          <OperationStepContent
            name={name}
            description={description}
            mitigationAction={mitigationAction}
            uuid={uuid}
            category={category}
            estimatedTime={estimatedTime}
            onUpdate={onUpdate}
            hasAttachments={attachments?.length > 0}
            wpa={wpa}
            addon={
              <OperationStepMenu
                attachmentsCount={attachments?.length ?? 0}
                isElementUpdated={!!edited}
                onAddElementToLibrary={onAddToLibrary}
                onAttachImage={onAttachmentsFormOpenHandler}
                onAttachmentsRemove={onRemoveAttachmentsHandler}
                onElementDelete={onDeleteHandler}
              />
            }
          />

          <div className={styles.imgBlock}>
            <OperationStepAttachmentsForm
              id={`upload-op-element-${uuid}`}
              attachments={attachments}
              onRemove={onRemoveAttachmentHandler}
              onPlaceholderClick={onAttachmentsFormOpenHandler}
              onImageClick={onAttachmentClick}
            />
          </div>
        </List>

        <Text
          variant="caption"
          weight="regular"
          className={styles.limitMessage}
        >
          {t('followRecommendedCharactersLimits')}
        </Text>
      </div>
    );
  },
  isEqual
);
