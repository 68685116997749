import { AllowedMimeType } from 'app-constants/files';
import cn from 'classnames';
import mime from 'mime-types';
import React, { ComponentType, HTMLAttributes, useMemo } from 'react';
import { getFileExtension } from 'utils';
import classes from './AttachmentPic.module.scss';
import { File } from './File';
import { Image } from './Image';

interface Props {
  className?: string;
  description?: string | null;
  fileUrl: string;
  mimeType?: AllowedMimeType;
  name: string;
}

export const AttachmentPic: ComponentType<
  Props & HTMLAttributes<HTMLDivElement>
> = ({ fileUrl, name, description, className, mimeType, ...divProps }) => {
  const fileElement = useMemo(() => {
    if (!fileUrl) {
      return null;
    }

    switch (mimeType) {
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/gif':
      case 'image/png': {
        const alt = `${name}${description ? `- ${description}` : ''}`;
        return <Image src={fileUrl} alt={alt} />;
      }

      case 'application/pdf':
      case 'video/mp4':
      case 'video/quicktime':
      default:
        return (
          <File
            extension={getFileExtension(name) || mime.extension(mimeType) || ''}
            name={name}
          />
        );
    }
  }, [fileUrl, mimeType, name, description]);

  return (
    <div className={cn(className, classes.container)} {...divProps}>
      {fileElement}
    </div>
  );
};

AttachmentPic.displayName = 'AttachmentPic';
