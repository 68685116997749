import React, { forwardRef } from 'react';

import { RadioSwitch } from 'components/Form/RadioSwitch';
import { FormInputBase } from '../FormInputBase';

export const FormRadioSwitch = forwardRef<any, any>((props, ref) => {
  return (
    <FormInputBase formInputType="radio" {...props}>
      {({ getProps }) => <RadioSwitch ref={ref} {...getProps()} />}
    </FormInputBase>
  );
});
