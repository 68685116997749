import React, { ReactElement, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import { readTemplateDebriefingQuestions } from 'store/modules/templates-library';
import { selectActiveOperationDebriefings } from 'store/modules/operation-planning/selectors/questions';
import { selectOperationTemplateId } from 'store/modules/operation-planning';
import withRouterParams from 'containers/withRouterParams';

import { useTranslation } from 'react-i18next';
import { OperationTimeoutQuestionType } from 'common-types/operation';
import { RouteComponentProps } from 'react-router';
import { QuestionsList } from './QuestionsList';

interface Props extends RouteComponentProps {
  questions: OperationTimeoutQuestionType[];
  templateId: number;
  operationId: number;
}

const DebriefingQuestionsComponent = ({
  questions,
  templateId,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const fetchQuestions = () => {
    if (templateId) {
      dispatch(readTemplateDebriefingQuestions(templateId));
    }
  };
  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (templateId) {
      fetchQuestions();
    }
  }, [templateId]);

  return (
    <QuestionsList title={t('debriefingQuestions')} questions={questions} />
  );
};

const mapStateToProps = createStructuredSelector<Props, {}>({
  questions: selectActiveOperationDebriefings,
  templateId: selectOperationTemplateId,
});
const withConnect = connect(mapStateToProps);

const enhancer = compose(withRouterParams('operationId', true), withConnect);

export const DebriefingQuestions = enhancer(DebriefingQuestionsComponent);
