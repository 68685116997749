import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import { Loader } from 'components/Loader';
import { MessageBlock } from './MessageBlock';
import styles from './Message.module.scss';
import { isObject } from 'lodash';

export interface MessageProps {
  isSaving?: boolean;
  label?: string;
  status?: 'success' | 'saving' | 'error';
  timeout?: number;
  show?: boolean;
  children?: ReactNode | ReactNode[];
}

const Message: FC<MessageProps> = ({
  isSaving = false,
  label = '',
  status = 'error',
  timeout = 1500,
  show = false,
  children,
}) => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState<boolean>(false ?? show);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const hideMessage = () => {
      resetTimeout();
      timeoutRef.current = setTimeout(() => {
        setIsShown(false);
      }, timeout);
    };

    setIsShown(true);
    hideMessage();
  }, [status, label, isSaving, timeout]);

  useEffect(() => {
    return () => resetTimeout();
  }, []);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const message = isObject(label) ? t('errorUpdating') : label;

  if (!isShown || !status) return null;

  return (
    <div className={styles.message}>
      {isSaving || status === 'saving' ? (
        <MessageBlock type={status}>
          <Loader showSpinner label={t('Saving')} />
        </MessageBlock>
      ) : (
        <MessageBlock className={styles.messageBlock} type={status}>
          {children && children}
          <span>
            {!status && message}
            {status &&
              status === 'success' &&
              (message ?? t('successfullyUpdated'))}
            {status && status === 'error' && (message ?? t('errorUpdating'))}
          </span>
        </MessageBlock>
      )}
    </div>
  );
};

const message = {
  success: (label: string) => <Message status="success" label={label} />,
  error: (label: string) => <Message status="error" label={label} />,
};

export { message };
export default withTranslation()(Message);
