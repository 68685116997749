import { schema } from 'normalizr';
import { attachmentsSchema } from './file';
import { libraryElementsSchema } from './library-elements';
import { authorsSchema } from './users';

export const debriefingQuestionSchema = new schema.Entity(
  'debriefingQuestions'
);

export const debriefingQuestionsListSchema = new schema.Array(
  debriefingQuestionSchema
);

export const timeoutQuestionSchema = new schema.Entity('timeoutQuestions');

export const timeoutQuestionsListSchema = new schema.Array(
  timeoutQuestionSchema
);

export const templateCategoriesSchema = new schema.Entity('templateCategories');
templateCategoriesSchema.define({
  parent: templateCategoriesSchema,
});

export const templateCategoriesListSchema = new schema.Array(
  templateCategoriesSchema
);

export const operationTemplateSchema = new schema.Entity('templates', {
  author: authorsSchema,
  debriefingQuestions: [debriefingQuestionSchema],
  elements: [libraryElementsSchema],
  procedureElements: [libraryElementsSchema],
  timeoutQuestions: [timeoutQuestionSchema],
  attachments: [attachmentsSchema],
});

operationTemplateSchema.define({
  parent: operationTemplateSchema,
});
