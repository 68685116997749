import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import {
  getInitialFormState,
  usePasswordResetRequest,
  AuthFormForgotPasswordState,
} from './utils';
import { AuthFormTemplate } from '../AuthFormTemplate';

import {
  Button,
  SuccessLabel,
  FormActions,
  FormBody,
  FormInput,
} from 'components';
import { useSearchParams } from 'hooks/useSearchParams';
import { LOGIN } from 'scenes/routes.enum';
import { ResetPasswordSchema } from 'validation-schemas';

/**
 * Renders restore password form
 */
export const AuthFormForgotPassword: FC = () => {
  const { t } = useTranslation();

  const [{ email }] = useSearchParams();
  const { response, request, loading, error } = usePasswordResetRequest();

  const done = !!(response && !error);

  const onSubmit = ({ email }: AuthFormForgotPasswordState) => {
    request({ email });
  };

  return (
    <AuthFormTemplate
      title={t('passwordForgotQuestion')}
      subtitle={t('passwordSendResetLinkEmailMessage')}
      successMessage={
        <>
          <p>{t('passwordResetSendEmailMessage')}</p>
          <p>{t('passwordResetEmailSpamMessage')}</p>
        </>
      }
      errorMessage={error}
      displaySubtitle={!done}
      displaySuccessMessage={done}
      displayErrorMessage={!!error}
      className="pt-3"
    >
      <Formik
        initialValues={getInitialFormState(email)}
        validationSchema={ResetPasswordSchema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <FormBody narrow>
              <FormInput
                type="email"
                name="email"
                readOnly={done}
                value={values.email}
                error={errors.email}
                label={t('email')}
                placeholder={t('emailAddress')}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <FormActions>
                <Button
                  type="button"
                  variant="secondary"
                  className="mr-05"
                  to={LOGIN}
                  component={Link}
                >
                  {t('backToLogin')}
                </Button>

                {done ? (
                  <SuccessLabel />
                ) : (
                  <Button type="submit" loading={loading}>
                    {t('send')}
                  </Button>
                )}
              </FormActions>
            </FormBody>
          </form>
        )}
      </Formik>
    </AuthFormTemplate>
  );
};
