import { UPDATE_USER_SUCCESS } from 'store/modules/users';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_USER_SUCCESS: {
      const { entities } = payload;
      const userId = payload.result;
      return {
        ...state,
        [userId]: {
          ...state[userId],
          ...entities.users[userId],
        },
      };
    }

    default:
      return state;
  }
};
