import React, {
  FC,
  memo,
  MouseEvent,
  KeyboardEvent,
  AllHTMLAttributes,
} from 'react';
import cn from 'classnames';
import { ENTER } from 'app-constants/keycodes';

import styles from './MenuListItem.module.scss';

export interface MenuListItemProps extends AllHTMLAttributes<HTMLLIElement> {
  /**
   * Label of menu item
   */
  label: string;
  /**
   * Whether to highlight this menu item
   */
  isDefault?: boolean;
  /**
   * Callback to run on menu item click
   */
  onClick?: (
    event: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>
  ) => void;
}

/**
 * One list item in menu
 */
export const MenuListItem: FC<MenuListItemProps> = memo(function MenuListItem({
  label,
  isDefault = false,
  onClick,
  ...props
}) {
  const handleKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    if (event.keyCode === ENTER && onClick) {
      onClick(event);
    }
  };

  return (
    <li
      role="menuitem"
      tabIndex={0}
      data-e2e-label={label}
      className={cn(styles.subMenuItem, {
        [styles.subMenuDefault]: isDefault,
      })}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      {...props}
    >
      <button type="button" className={styles.sameCatLink}>
        {label}
      </button>
    </li>
  );
});
