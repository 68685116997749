import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isObject } from 'lodash';
import { Formik } from 'formik';

import styles from './AuthFormLogin.module.scss';
import {
  AuthFormReloginState,
  initialReloginFormState,
  useLoginForm,
} from './utils';
import { AuthFormKeepLoggedInField } from './AuthFormKeepLoggedInField';

import { AuthFormTemplate } from 'scenes/Auth/AuthFormTemplate';
import {
  Button,
  Text,
  FormActions,
  FormBody,
  FormInput,
  Avatar,
} from 'components';
import { user as userStore } from 'local-store';
import { LOGIN } from 'scenes/routes.enum';
import { ReloginSchema } from 'validation-schemas';

/**
 * Render resign in to the application form
 */
export const AuthFormRelogin: FC = () => {
  const { givenName, surname, email, image } = useMemo(
    () => userStore.get() || {},
    []
  );

  const { auth, onSubmit, onResetErrors } = useLoginForm();

  const { t } = useTranslation();

  const onSubmitHandler = useCallback(
    ({ password }: AuthFormReloginState) => {
      onSubmit({ email, password });
    },
    [email, onSubmit]
  );

  return (
    <AuthFormTemplate
      title={
        <>
          <span>{t('welcomeBack')}</span>,
          <br />
          <strong>{givenName}</strong>
        </>
      }
      errorMessage={
        isObject(auth.error?.message)
          ? auth.error?.message.password
          : auth.error?.message
      }
      displayErrorMessage={!!auth.error}
    >
      <div className={styles.user}>
        <div className={styles.userData}>
          <Avatar
            className={styles.avatar}
            givenName={givenName}
            surname={surname}
            imageUrl={image}
          />

          <div className={styles.info}>
            <Text weight="bold" className="m-0">
              {givenName}&nbsp;{surname}
            </Text>

            <Text weight="regular" className="m-0">
              {email}
            </Text>
          </div>
        </div>

        <Link to={LOGIN} className={styles.userLink}>
          {t('notNameQuestion', { name: givenName })}
        </Link>
      </div>

      <Formik
        validationSchema={ReloginSchema}
        initialValues={initialReloginFormState}
        onSubmit={onSubmitHandler}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => {
          const onChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(ev);
            onResetErrors();
          };

          return (
            <form onSubmit={handleSubmit}>
              <FormBody narrow>
                <FormInput
                  type="password"
                  name="password"
                  value={values.password}
                  error={touched.password && errors.password && ''}
                  label={t('password')}
                  placeholder={t('passwordEnterYours')}
                  onChange={onChangeHandler}
                  onBlur={handleBlur}
                />

                {touched.password && errors.password && (
                  <div className="text-error mb-1">
                    {t(`validation:${errors.password}`)}
                  </div>
                )}

                <AuthFormKeepLoggedInField email={email} />

                <FormActions>
                  <Button type="submit">{t('logIn')}</Button>
                </FormActions>
              </FormBody>
            </form>
          );
        }}
      </Formik>
    </AuthFormTemplate>
  );
};
