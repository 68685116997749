import { DeprecatedFlex, Icon, PanelActions } from 'components';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { OPSaveState } from './OPSaveState';

interface Props {
  isValid?: boolean;
}

export const OperationPlanningActions: ComponentType<Props> = ({
  children,
  isValid = true,
}) => {
  const { t } = useTranslation();

  return (
    <PanelActions variant="floating">
      <DeprecatedFlex>
        {isValid && (
          <span className="mr-1">
            <OPSaveState />
          </span>
        )}
        {!isValid && (
          <span className="text-warning mr-1">
            <Icon name="incidents" /> {t('fixErrorsToSaveChanges')}
          </span>
        )}
        {children}
      </DeprecatedFlex>
    </PanelActions>
  );
};

export default OperationPlanningActions;
