import qs from 'qs';
import { omit } from 'lodash';

const parseSearchParams = (search = window.location.search, options) =>
  search
    ? qs.parse(search, {
        ignoreQueryPrefix: true,
        decoder: function (str, defaultDecoder, charset, type) {
          if (type === 'value') {
            try {
              const result = JSON.parse(str);
              return result;
            } catch (error) {}
          }

          return defaultDecoder(str, defaultDecoder, charset, type);
        },
        ...options,
      })
    : {};

const mergeUrlParams = (paramsString, newParam, omitProp) => {
  let paramsObj = parseSearchParams(paramsString);

  if (omitProp) paramsObj = omit(paramsObj, omitProp);

  const mergedParams = qs.stringify(
    {
      ...paramsObj,
      ...newParam,
    },
    { arrayFormat: 'repeat', encode: false }
  );

  return mergedParams;
};

export { parseSearchParams, mergeUrlParams };
