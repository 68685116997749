import { FC, memo } from 'react';
import { humanReadableDuration } from 'utils';

interface FormattedDurationProps {
  /**
   * React children prop
   */
  children?: React.ReactNode | ((duration: string | null) => React.ReactNode);
  /**
   * Render children callback
   */
  render?: (duration: string | null) => React.ReactNode;
  /**
   * Duration time
   */
  duration?: number;
  /**
   * Flag to show/hide seconds
   */
  showSeconds?: boolean;
  /**
   * Flag to show/hide plus icon
   */
  showPlus?: boolean;
}

/**
 * Format duration HOC
 */
export const FormattedDuration: FC<FormattedDurationProps> = memo(
  function FormattedDuration({
    children,
    render,
    duration = 0,
    showPlus,
    showSeconds,
  }) {
    const renderProp = children || render;
    const formattedDuration =
      humanReadableDuration(duration, showSeconds, showPlus) || null;

    return typeof renderProp === 'function'
      ? renderProp(formattedDuration)
      : formattedDuration;
  }
);
