import { LibraryCategories } from 'common-types/library';

export const stepsCategories = [
  LibraryCategories.checks,
  LibraryCategories.procedures,
  LibraryCategories.risks,
];

export const stepElements = [
  LibraryCategories.checks,
  LibraryCategories.procedures,
];

export default (elements) => {
  if (!elements || !Array.isArray(elements)) return null;
  let step = 0;
  return elements
    .filter((element) => stepsCategories.includes(element.category))
    .map((element) => {
      if (!stepElements.includes(element.category)) return element;
      // FIXME: this logic could be added in OperationSteps component itself
      // if so, component would pass down elements' ids instead of whole
      // elements collection optimizing the performance
      // see: https://nodusmedical.atlassian.net/browse/NM-2281
      step += 1;
      return { ...element, step };
    });
};
