import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Bugsnag from '@bugsnag/js';

import { reactivateAccount } from 'store/modules/auth';

export const useAccountReactivation = (email: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [done, setDone] = useState<boolean>(false);
  const dispatch = useDispatch();

  const requestAccountReactivation = () => {
    setLoading(true);
    try {
      if (!email) {
        const error = new Error(
          'Email is not available for account deactivation'
        );
        console.error(error);
        Bugsnag.notify(error);
        return;
      }

      dispatch(reactivateAccount(email));
      setDone(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    request: requestAccountReactivation,
    error,
    loading,
    done,
  };
};
