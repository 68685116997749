import { Language } from 'common-types/common';
import { translatableInputName, translateValue } from 'utils';

export interface TranslatableInputProps {
  inputLanguage: Language;
  onLanguageChange: (lang: Language) => void;
}

interface params {
  value: string | number;
  name: string;
  inputLanguage: Language;
}

export const useTranslatableInput = ({
  value,
  name,
  inputLanguage,
}: params) => {
  return {
    value: translateValue(value, inputLanguage, false),
    name: translatableInputName(name, inputLanguage),
  };
};
