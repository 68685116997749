import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { validatePassword } from 'validation-schemas';

export interface FormValidationOption {
  id: string;
  label: string;
  validate: (value: string) => boolean;
}

/**
 * Hook to get translated password validation option
 */
export const usePasswordValidationOptions = (): FormValidationOption[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        id: '1',
        label: t('validation:6PlusCharacters'),
        validate: validatePassword.minLength,
      },
      {
        id: '2',
        label: t('validation:uppercase'),
        validate: validatePassword.uppercase,
      },
      {
        id: '3',
        label: t('validation:lowercase'),
        validate: validatePassword.lowercase,
      },
      {
        id: '4',
        label: t('validation:numericOrSpecial'),
        validate: validatePassword.symbol,
      },
    ];
  }, [t]);
};

/**
 * Hook to get translated operation pin validation option
 */
export const useOperationPinValidationOptions = (): FormValidationOption[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        id: '1',
        label: t('validation:4PlusCharacters'),
        validate: (pass: string) => pass?.length >= 4,
      },
      {
        id: '2',
        label: t('validation:10MinusCharacters'),
        validate: (pass: string) => pass?.length > 0 && pass?.length <= 10,
      },
    ];
  }, [t]);
};
