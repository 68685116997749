import React, { ReactNode } from 'react';

import styles from './ErrorMessage.module.scss';
import cn from 'classnames';

interface ErrorMessageProps {
  className?: string;
  children?: ReactNode;
}

export const ErrorMessage = ({ className, children }: ErrorMessageProps) => (
  <span className={cn(styles.errorMessage, className)}>{children}</span>
);
