import React, { ReactElement, ReactNode } from 'react';

import styles from './ToggleableList.module.scss';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import { OperationTimeoutQuestionType } from 'common-types/operation';
import { ListItemType } from 'common-types/common';
import { ListItem } from 'components/List/ListItem';
import { ToggleBox } from 'components/Form/ToggleBox';
import Input from 'components/Form/Input';

interface Props {
  mode?: 'edit' | 'display';
  checked?: boolean;
  item?: OperationTimeoutQuestionType;
  onCreate?: (content: string) => void;
  onChange?: (id: number, checked: boolean) => void;
  renderItem?: (item: ListItemType) => ReactNode;
  innerRef?: (element?: HTMLElement) => any;
  isDragging?: boolean;
  id?: string;
  restProps?:
    | (DraggableProvidedDragHandleProps & DraggableProvidedDraggableProps)
    | undefined;
}

export const ToggleableListItem = ({
  item,
  checked,
  renderItem,
  mode = 'display',
  onCreate,
  onChange,
  ...restProps
}: Props): ReactElement => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && item && onChange(item.id, event.target.checked);
  };

  const handleItemCreate = (e) => {
    e.preventDefault();

    if (onCreate) {
      const inputValue = e.target[0].value;
      onCreate(inputValue);
    }
  };

  if (mode === 'edit') {
    return (
      <ListItem
        inlineChildren
        addonVisible
        addon={<ToggleBox variant="primary" id="edit-list-item" checked />}
        {...restProps}
      >
        <form onSubmit={handleItemCreate}>
          <Input name="content" className={styles.input} />
          <button className="hidden" />
        </form>
      </ListItem>
    );
  }

  if (!item) return <></>;

  return (
    <ListItem
      addonVisible
      addon={
        <ToggleBox
          id={item.id}
          name={item.id}
          checked={checked}
          variant="primary"
          onChange={handleChange}
        />
      }
      {...restProps}
    >
      {renderItem && renderItem(item)}
    </ListItem>
  );
};
