import React, { FC } from 'react';
import { LibraryFormLimitsType } from 'containers/LibraryItemsSearch/AddLibraryItemForm.types';
import { FormTextarea, MaxLengthIndicator } from 'components';
import { useTranslation } from 'react-i18next';
import { connect, FormikContextType } from 'formik';

interface ChecksFormProps {
  limits?: LibraryFormLimitsType;
}

interface ChecksFormFormikValueProps {
  description: string;
  estimatedTime: string;
}

const ChecksForm: FC<
  ChecksFormProps & FormikContextType<ChecksFormFormikValueProps>
> = ({ values, errors, limits, handleChange }) => {
  const { t } = useTranslation();

  return (
    <FormTextarea
      label={t('Description')}
      name="description"
      mode="translatable"
      placeholder={t('enterCheckDescription')}
      value={values.description}
      error={errors.description}
      onChange={handleChange}
      append={
        limits ? (
          <MaxLengthIndicator
            length={limits.descriptionCharsOccupied}
            limit={limits.descriptionCharsLimit}
          />
        ) : undefined
      }
    />
  );
};

export default connect(ChecksForm);
