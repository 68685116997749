import React, { ComponentType } from 'react';
import classnames from 'classnames';
import { Icon } from '../Icon';

interface Props {
  className?;
}

export const CheckIcon: ComponentType<Props> = function ({
  className,
  ...props
}) {
  return (
    <Icon
      name="check"
      className={classnames(className, 'text-warning')}
      {...props}
    />
  );
};
