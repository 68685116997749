import React, { FC, ReactNode } from 'react';

import { List } from './List';
import { ListHeader } from './ListHeader';
import { ListItem } from './ListItem';

export interface ListElementProps {
  title?: string | ReactNode;
  children?: ReactNode | ReactNode[];
}

export const ListElement: FC<ListElementProps> = ({ title, children }) => {
  return (
    <List>
      <ListHeader>{title}</ListHeader>
      <ListItem>{children}</ListItem>
    </List>
  );
};
