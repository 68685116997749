import { SUPPORTED_LANGUAGES, OPERATION_STATUSES } from 'app-constants';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
  elementType,
} from 'prop-types';
import { CREATE, UPDATE } from 'app-constants/form-modes';

export const addressTypes = shape({
  address: string,
  city: string,
  country: string,
  zipCode: string,
});

export const notificationProps = shape({
  status: string,
  message: string,
});

export const numberOrString = oneOfType([number, string]);

export const componentTypes = elementType;

export const optionTypes = shape({
  label: string,
  value: numberOrString,
});

export const withRouterPropTypes = {
  history: shape({}).isRequired,
  location: shape({ pathname: string }).isRequired,
  match: shape({ url: string }).isRequired,
};

export const withParamsPropTypes = shape({
  p: string, // pagination page
  s: string, // search
  c: oneOf([string, arrayOf(string)]), // category
});

export const translatedString = shape({
  en: string,
  de: string,
});

export const errorResponse = shape({
  statusCode: number,
  error: string,
  message: string,
  fileName: string,
});

export const translatableString = oneOfType([translatedString, string]);

export const languageTypes = SUPPORTED_LANGUAGES;

export const idTypes = number;

export const eventTypes = {
  id: idTypes,
  title: string.isRequired,
  start: instanceOf(Date),
  end: instanceOf(Date),
  status: oneOf(Object.keys(OPERATION_STATUSES)),
};

export const errorTypes = {
  requestBodyErrors: objectOf(string),
  message: string,
  statusCode: number,
};

export const formModeTypes = oneOf([CREATE, UPDATE]);

export const commentTypes = {
  id: idTypes.isRequired,
  content: string,
};

export const commentsTypes = arrayOf(commentTypes);

export const fileTypes = shape({
  id: idTypes,
  fileUrl: string,
  name: string,
  description: string,
});

export const withFileUploadTypes = shape({
  onUpload: func.isRequired,
  failedUpload: arrayOf(),
  uploadErrorMessage: string,
  isFileUploading: bool,
});

export const withUploadModalTypes = shape({
  onUpload: func.isRequired,
  onCancel: func.isRequired,
  onRetry: func.isRequired,
  closeModal: func.isRequired,
  isOpen: bool.isRequired,
  failedUpload: arrayOf(),
  uploadErrorMessage: string,
  isFileUploading: bool,
});
