import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Formik } from 'formik';

import {
  useResetPasswordForm,
  validateResetPasswordForm,
  initialFormState,
  AuthFormResetPasswordState,
} from './utils';
import { AuthFormTemplate } from '../AuthFormTemplate';

import {
  FormPasswordValidation,
  usePasswordValidationOptions,
} from 'forms/FormPasswordValidation';
import { FormActions, FormBody, FormInput, Button } from 'components';
import { useSearchParams } from 'hooks/useSearchParams';
import { FORGOT_PASSWORD, LOGIN } from 'scenes/routes.enum';

/**
 * Renders reset password auth form
 */
export const AuthFormResetPassword: FC = () => {
  const { t } = useTranslation();

  const [{ resetToken }] = useSearchParams();
  const { loading, error, request } = useResetPasswordForm();

  const passwordValidationOptions = usePasswordValidationOptions();

  const onSubmit = async ({ password }: AuthFormResetPasswordState) => {
    request({ token: resetToken, newPassword: password });
  };

  if (!resetToken) {
    return <Redirect to={FORGOT_PASSWORD} />;
  }

  return (
    <AuthFormTemplate
      title={t('passwordReset')}
      subtitle={t('passwordSetNew')}
      errorMessage={error}
      displayErrorMessage={!!error}
    >
      <Formik
        validate={validateResetPasswordForm(t)}
        initialValues={initialFormState}
        onSubmit={onSubmit}
      >
        {({ errors, values, handleChange, handleSubmit, touched }) => (
          <form onSubmit={handleSubmit}>
            <FormBody narrow>
              <FormInput
                id="new-pass"
                name="password"
                type="password"
                value={values.password}
                error={touched.password && errors.password}
                label={t('passwordNew')}
                placeholder={t('passwordEnterNew')}
                onChange={handleChange}
              />

              <FormPasswordValidation
                value={values.password}
                options={passwordValidationOptions}
                className="mb-1-5"
              />

              <FormInput
                id="repeat-new-pass"
                name="passwordConfirmation"
                type="password"
                value={values.passwordConfirmation}
                error={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                label={t('passwordRepeatNew')}
                placeholder={t('passwordRepeatNew')}
                onChange={handleChange}
              />

              <FormActions>
                <Button
                  className="mr-05"
                  component={Link}
                  to={LOGIN}
                  variant="secondary"
                >
                  {t('cancel')}
                </Button>

                <Button loading={loading} type="submit">
                  {t('change')}
                </Button>
              </FormActions>
            </FormBody>
          </form>
        )}
      </Formik>
    </AuthFormTemplate>
  );
};
