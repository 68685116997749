import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './Modal.module.scss';

import { Icon } from 'components/Icon';
import { Text } from 'components/Typography';

export interface ModalHeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  /**
   * Title value displayed on the left side. If absent title wont be rendered
   */
  title?: React.ReactNode;
  /**
   * Callback to be fired on close button click to close modal. If absent close button wont be rendered
   */
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Renders modal header component with title and close button
 */
export const ModalHeader: FC<ModalHeaderProps> = ({
  className,
  title,
  onClose,
  ...props
}) => {
  return (
    <div className={cn(styles.modalHeader, className)} {...props}>
      {title && (
        <Text variant="title" className={styles.title}>
          {title}
        </Text>
      )}

      {onClose && (
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <Icon name="close" />
        </button>
      )}
    </div>
  );
};
