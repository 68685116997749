import { kebab } from 'case';
import cn from 'classnames';
import {
  LibraryCategories,
  OperationStep,
  OperationStepEstimable,
} from 'common-types/library';
import noop from 'lodash/noop';
import React, { ClipboardEvent, FC, useState } from 'react';
import { isPasteFilesEvent } from 'utils/is-paste-files-event';
import { onEnter } from 'utils/key-events';
import classes from './OperationItem.module.scss';
import { OperationItemEstimation } from './OperationItemEstimation';
import { OperationStepAttachmentsForm } from './OperationStepAttachments/OperationStepAttachmentsForm/OperationStepAttachmentsForm';
import { OperationStepAttachmentsList } from './OperationStepAttachments/OperationStepAttachmentsList/OperationStepAttachmentsList';
import { Attachment } from 'common-types/attachments';
import { DataTag } from 'components/DataTag';
import { setDataAttributes } from 'components/DataTag/utils';
import { Multiline } from 'components/Multiline';
import { CheckIcon, RiskIcon } from 'components/Icon';
import { DropList } from 'components/DropList';
import { List, ListItem, ListHeader, ListItemInput } from 'components/List';
import { EditableString } from 'components/EditableString';
import { useTranslation } from 'react-i18next';
import { PatientRisks } from 'common-types/operation';

type DisplayField = {
  key: string;
  label?: string;
};

export type Element = {
  id: number;
  uuid: string;
};

export interface OperationItemProps {
  displayField: DisplayField;
  inputProps?: object;
  item?: OperationStepEstimable | OperationStep | PatientRisks;
  onAttachmentRemove?: (attachmentId: number) => void;
  onAttachmentRequest?: () => void;
  onAttachmentsChange?: (attachments: number[]) => void;
  onDelete?: (id: string | number) => void;
  onSave?: (item: Element, update: object) => void;
  onTriggerGallery?: (attachmentId: number) => void;
  readonly?: boolean;
  renderMenuItems?: (itemProps: any) => Element;
  withImage?: boolean;
  className?: string;
  onUpload?: (files: File[]) => Promise<Attachment[]>;
  closeModal?: () => void;
}

export const OperationItem: FC<OperationItemProps> = ({
  displayField = { key: 'description', label: '' },
  item,
  readonly = false,
  inputProps,
  onAttachmentRequest,
  onAttachmentsChange,
  onDelete,
  onSave,
  onTriggerGallery,
  renderMenuItems,
  withImage = false,
  className,
  closeModal,
  onUpload,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();

  if (!item) return null;

  const shouldShowEstimation = [
    LibraryCategories.procedures,
    LibraryCategories.checks,
  ].includes(item?.category);

  const placeholder = displayField.label || t('enterDescription');

  const content = item[displayField.key];

  const enableEditMode = () => setEditMode(true);

  const disableEditMode = () => setEditMode(false);

  const handleHeaderChange = (headerValue: string) => {
    headerValue.trim() && handleSubmitItem('name', headerValue.trim());
  };

  const handleItemChange = (bodyValue: string) =>
    handleSubmitItem(displayField.key, bodyValue);

  const handleSubmitItem = (propName: string, value: string) => {
    if (typeof onSave !== 'function' || item?.[propName] === value) return;
    onSave(item, { [propName]: value });
    disableEditMode();
  };

  const handleEstimationChange = (estimatedTimeInput: string) => {
    const estimatedTime: number = parseFloat(estimatedTimeInput);
    const hasChanged =
      (item as OperationStepEstimable)?.estimatedTime !== estimatedTime;
    if (!hasChanged || !onSave) return;

    onSave(item, { estimatedTime });
  };

  const handleOpenGallery = (attachmentId: number) => {
    if (!attachmentId || !onTriggerGallery) return;
    onTriggerGallery(attachmentId);
  };

  const renderContent = () => {
    const emptyPlaceHolder = !content && readonly && t && t('nothingWasAdded');
    const isEditable = !readonly && onSave;

    return (
      <ListItem
        tabIndex={isEditable ? 0 : -1}
        onKeyDown={isEditable ? onEnter(enableEditMode) : undefined}
        onClick={isEditable ? enableEditMode : undefined}
      >
        {content ? (
          <DataTag
            propKey="content"
            {...setDataAttributes({ [displayField.key]: null })}
          >
            <Multiline>{content}</Multiline>
          </DataTag>
        ) : (
          <span className="text-placeholder">
            {!emptyPlaceHolder ? placeholder : emptyPlaceHolder}
          </span>
        )}
      </ListItem>
    );
  };

  const renderCategoryIcon = () => {
    const { category } = item;

    switch (category) {
      case LibraryCategories.checks:
        return <CheckIcon className="mr-05" />;
      case LibraryCategories.patientRisks:
      case LibraryCategories.risks:
        return <RiskIcon className="mr-05" />;
      default:
        return null;
    }
  };

  const renderAddon = () => {
    if (readonly) return null;

    return (
      !!renderMenuItems && (
        <DropList dropdownBtnClass="m-0" placement="bottom-start">
          {(getListItemProps) => renderMenuItems(getListItemProps())}
        </DropList>
      )
    );
  };

  const handlePasteFile = async (e: ClipboardEvent<HTMLElement>) => {
    if (!isPasteFilesEvent(e)) return;
    e.preventDefault();

    if (!onUpload || !closeModal || !onAttachmentsChange) return;
    try {
      const files = [...e.clipboardData.files];
      const attachments = (await onUpload(files)).filter(
        Boolean
      ) as Attachment[];
      const filesIds = attachments.map((a) => a.id);
      closeModal();
      onAttachmentsChange(filesIds);
    } catch (error) {
      closeModal();
    }
  };

  return (
    <List
      id={`operation-item-${item.uuid}`}
      className={cn('OperationItem', className, classes.customList)}
      onPaste={handlePasteFile}
      data-category={kebab(item.category)}
      data-library-element=""
    >
      <div className="fullWidth">
        <ListHeader
          className={cn({ [classes.headerWithImage]: withImage })}
          contentClassName="flex align-baseline"
          hoverable={!readonly}
          addonVisible
          addon={renderAddon()}
          itemId={item.uuid}
          onDelete={onDelete}
        >
          {renderCategoryIcon()}
          <strong className="mr-2">
            {!readonly ? (
              <EditableString
                textarea
                onEdit={handleHeaderChange}
                inputProps={{
                  className: classes.editableInput,
                  value: item.name as string,
                }}
              >
                {item.name as string}
              </EditableString>
            ) : (
              <DataTag propKey="name">{item.name}</DataTag>
            )}
          </strong>
          {shouldShowEstimation && (
            <OperationItemEstimation
              actualTime={(item as OperationStepEstimable).actualTime}
              estimatedTime={(item as OperationStepEstimable).estimatedTime}
              onChange={handleEstimationChange}
              readonly={readonly}
            />
          )}
        </ListHeader>
        {(item as OperationStepEstimable | OperationStep)?.incident &&
          'has incident'}
        {editMode ? (
          <ListItemInput
            onSave={handleItemChange}
            onCancel={disableEditMode}
            inputProps={{
              autoFocus: true,
              defaultValue: content,
              placeholder: placeholder,
              display: 'block',
              textarea: true,
              height: '100px',
              ...inputProps,
            }}
          />
        ) : (
          renderContent()
        )}
      </div>

      {withImage && (
        <div className={classes.imgBlock}>
          {readonly ? (
            <OperationStepAttachmentsList
              attachments={
                (item as OperationStepEstimable | OperationStep).attachments
              }
              onImageClick={handleOpenGallery}
            />
          ) : (
            <OperationStepAttachmentsForm
              id={`upload-op-item-${item.uuid}`}
              attachments={
                (item as OperationStepEstimable | OperationStep).attachments
              }
              onRemove={onAttachmentsChange || noop}
              onPlaceholderClick={onAttachmentRequest}
              onImageClick={handleOpenGallery}
            />
          )}
        </div>
      )}
    </List>
  );
};
