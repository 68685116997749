import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PageTitle from './PageTitle';

export default ComponentToWrap => {
  class WithPageTitle extends Component {
    render() {
      return (
        <PageTitle>
          {({ pageTitle }) => (
            <ComponentToWrap pageTitle={pageTitle} {...this.props} />
          )}
        </PageTitle>
      );
    }
  }

  hoistNonReactStatics(WithPageTitle, ComponentToWrap);

  return WithPageTitle;
};
