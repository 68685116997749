import { APIv1 } from 'api/instance';

export function makeCreateRequest(path) {
  return data => APIv1.post(path, data);
}

export function makeReadRequest(path) {
  return options => APIv1.get(path, options);
}

export function makeUpdateRequest(path) {
  return (id, data) => APIv1.put(`${path}/${id}`, data);
}

export function makePatchUpdateRequest(path) {
  return (id, data) => APIv1.patch(`${path}/${id}`, data);
}

export function makeDeleteRequest(path) {
  return id => APIv1.delete(`${path}/${id}`);
}
