import React, { FC, ReactNode } from 'react';
import { identity } from 'lodash';

import { Text } from 'components/Typography/Text';
import { List } from './List';
import { ListItem } from './ListItem';
import { ListHeader } from './ListHeader';
import { ListItemType } from '../../common-types/common';

export interface ElementsListProps {
  title?: string;
  items?: ListItemType[];
  renderItem?(item: ListItemType): ReactNode;
}

export const ElementsList: FC<ElementsListProps> = ({
  title,
  items = [],
  renderItem = identity,
}) => {
  return (
    <List>
      {title && (
        <ListHeader>
          <Text variant="heading-md" component="span">
            {title}
          </Text>
        </ListHeader>
      )}

      {items.map((item) => (
        <ListItem key={item.id} itemId={`item-${item.id}`}>
          {renderItem(item)}
        </ListItem>
      ))}
    </List>
  );
};
