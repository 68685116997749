import {
  ALLOWED_DOCUMENT_TYPES,
  ALLOWED_FILE_TYPES,
  ALLOWED_IMAGE_TYPES,
  ALLOWED_VIDEO_TYPES,
} from 'app-constants/files';
import { FileSizeLimits } from 'common-types/common';
import Url from 'url-parse';

const fileKeyRegExp = /^(\d+)\/([\w-]{36})\.([\w]{2,4})$/i;
const urlKeyRegExp = /^https?:\/\/.+(\d+)\/([\w-]{36})\.([\w]{2,4})/i;
const pdfRegExp = /.+\.pdf/;

export const isFileUrl = (url: string): boolean => !!urlKeyRegExp.exec(url);

export const getFileKey = (url: string): string | null => {
  if (!url || !isFileUrl(url)) return null;
  const { pathname: fileKey } = new Url(url);
  return fileKey.replace(/^\/{1,}/, '');
};

export const isFileKey = (str: string): boolean => fileKeyRegExp.test(str);

export const isPdf = (fileUrl: string): boolean =>
  Boolean(fileUrl.match(pdfRegExp));

const typeToExtension = (type: string): string => {
  const fileType = type.split('/').pop();
  return `.${fileType}`;
};

export const isAllowedUrl = (
  fileUrl: string,
  allowedTypes: string[]
): boolean =>
  allowedTypes
    .map(typeToExtension)
    .some((extension) => fileUrl.includes(extension));

export const isAllowedImgUrl = (fileUrl: string): boolean =>
  isAllowedUrl(fileUrl, ALLOWED_IMAGE_TYPES);

export const isAllowedFileUrl = (fileUrl: string): boolean =>
  isAllowedUrl(fileUrl, ALLOWED_FILE_TYPES);

export const getFileExtension = (fileUrl: string): string | null => {
  if (!fileUrl) return null;
  const [url] = fileUrl.split('?');
  const match = url.match(/\.(\w*)$/);
  if (!match) return match;
  const [, extension] = match;
  return extension;
};

export function validateFileSize(
  file: { type: string; size: number },
  limits: FileSizeLimits
): boolean {
  const typesMap = {
    video: ALLOWED_VIDEO_TYPES,
    pdf: ALLOWED_DOCUMENT_TYPES,
    images: ALLOWED_IMAGE_TYPES,
  };
  const fileType = Object.keys(typesMap).find((type) => {
    return typesMap[type].includes(file.type);
  });

  if (!fileType) return false;
  if (!limits[fileType]) return true;

  const pass = file.size <= limits[fileType];
  return pass;
}
