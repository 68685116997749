import { get, hasIn } from 'lodash';

import {
  ADD_TEMPLATE_ELEMENT,
  DELETE_TEMPLATE_ELEMENT,
  UPDATE_TEMPLATE_ELEMENT,
} from 'store/modules/templates-library/types';
import { EDIT_LIBRARY_ELEMENT } from 'store/modules/library/types';
import {
  ADD_OPERATION_ELEMENT,
  DELETE_OPERATION_ELEMENT,
  UPDATE_OPERATION_ELEMENT,
} from 'store/modules/operation-planning';

export default (state = {}, { type, payload }) => {
  if (hasIn(payload, 'entities.libraryElements')) {
    return { ...(state || {}), ...payload.entities.libraryElements };
  }

  switch (type) {
    case ADD_TEMPLATE_ELEMENT:
    case ADD_OPERATION_ELEMENT: {
      const { element, uuid } = payload;
      return {
        ...state,
        [element.uuid || uuid]: element,
      };
    }

    case DELETE_TEMPLATE_ELEMENT:
    case DELETE_OPERATION_ELEMENT: {
      const { uuid } = payload;
      const element = state[uuid];
      return {
        ...state,
        [uuid]: { ...element, isDeleted: true },
      };
    }

    case EDIT_LIBRARY_ELEMENT:
    case UPDATE_TEMPLATE_ELEMENT:
    case UPDATE_OPERATION_ELEMENT: {
      const { uuid, update } = payload;
      const element = get(state, uuid, {});
      return { ...state, [uuid]: { ...element, ...update, edited: true } };
    }

    default:
      return state;
  }
};
