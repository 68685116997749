import cn from 'classnames';
import { Col, Row } from 'components/Grid';
import React, { ComponentType, HTMLAttributes, ReactNode } from 'react';
import classes from './ListRow.module.scss';
import stepBarClasses from './StepBarItem.module.scss';

interface Props {
  barColumn?: ReactNode;
  isDragging?: boolean;
}

export const ListRow: ComponentType<Props & HTMLAttributes<HTMLDivElement>> = ({
  barColumn = null,
  children,
  className,
  isDragging = false,
  ...restProps
}) => {
  return (
    <Row className={cn(className, stepBarClasses.row)} {...restProps}>
      <Col
        auto
        className={cn(classes.barColumn, {
          [classes.barColumnHidden]: isDragging,
        })}
      >
        {barColumn}
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};
