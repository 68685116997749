import React, { Component, FC } from 'react';
import { compose } from 'redux';
import BigCalendar from '@rusinov/react-big-calendar';
import withDragAndDrop from '@rusinov/react-big-calendar/lib/addons/dragAndDrop';
import 'moment/locale/de';

import Bugsnag from '@bugsnag/js';
import {
  defaultComponents,
  defaultFormats,
  useCalendarLocalizer,
  useCalendarMessages,
} from './utils';
import { OPERATION_STATUSES } from 'app-constants';

import './calendar.scss';
import './calendar.dnd.scss';
import { ComponentError } from 'components/ComponentError';

type Event = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  status: keyof typeof OPERATION_STATUSES;
};

interface CalendarBaseProps {
  /**
   * UI components map (Event, Week, ...)
   */
  components?: Record<string, Component>;
  /**
   * Date formatters map
   */
  formats?: Record<string, (...args: unknown[]) => string>;
  /**
   * List of events to display events
   */
  events?: Event[];
}

/**
 * Renders calendar component without drag and drop functionality
 */
const CalendarBase: FC<CalendarBaseProps> = ({
  components = {},
  formats = {},
  events = [],
  ...props // contains props from withDragAndDrop HOC
}) => {
  // Calendar content localizer
  const localizer = useCalendarLocalizer();

  // Calendar translated content
  const messages = useCalendarMessages();

  try {
    return (
      <BigCalendar
        showMultiDayTimes
        localizer={localizer}
        messages={messages}
        components={{ ...defaultComponents, ...components }}
        formats={{ ...defaultFormats, ...formats }}
        events={events}
        {...props}
      />
    );
  } catch (error) {
    // it is not called in the effect hook because hooks cant be used in conditions or try/catch
    // and in any case it will not be called on every render, but only on renders when an error occurs
    Bugsnag.notify(error);

    return <ComponentError componentName="calendar" />;
  }
};

/**
 * Renders calendar component with drag and drop functionality
 */
export const Calendar = compose(withDragAndDrop)(CalendarBase);
