import React, { forwardRef } from 'react';

import { Textarea } from 'components/Form/Textarea';

import { FormInputBase } from '../FormInputBase';

export const FormTextarea = forwardRef<any, any>((props, ref) => {
  return (
    <FormInputBase formInputType="textarea" {...props}>
      {({ getProps }) => <Textarea ref={ref} {...getProps()} />}
    </FormInputBase>
  );
});
