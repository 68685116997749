import { countriesList } from 'app-constants';
import { sortBy } from 'lodash';

export const getCountryCodes = (
  t,
  countries = countriesList,
  namespace = 'countries'
) =>
  sortBy(
    countries.map(({ value, label }) => ({
      label: `${t(`${namespace}:${value}`)} ${label}`,
      value: label,
    })),
    'label'
  );

export const getCountryOptions = (
  t,
  countries = countriesList,
  namespace = 'countries'
) =>
  sortBy(
    countries.map(({ value }) => ({
      value,
      label: t(`${namespace}:${value}`),
    })),
    'label'
  );
