export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const TEST = 'test';

export type Environment =
  | typeof DEVELOPMENT
  | typeof PRODUCTION
  | typeof STAGING
  | typeof TEST;
