import React, { FC, HTMLAttributes, memo, MouseEvent } from 'react';
import { Option } from 'common-types/common';
import { Dropdown } from 'components/Dropdown';
import { Button, ButtonProps } from 'components/Button';
import { Row } from 'components/Grid';
import { ListItem } from 'components/List';
import styles from './ButtonGroup.module.scss';
import { Icon } from 'components/Icon';
import { Prefer } from 'common-types';

type ButtonGroupProps = Prefer<
  {
    options: Option[];
    onSelect: (option: Option) => void;
    onClick?: (ev: MouseEvent<HTMLElement>) => any;
  },
  HTMLAttributes<HTMLDivElement> & Partial<ButtonProps>
>;

export const ButtonGroup: FC<ButtonGroupProps> = memo(function ButtonGroup({
  children,
  onClick,
  onSelect,
  options,
  className,
  ...props
}) {
  return (
    <Dropdown
      buttonComponent={(dropdownButtonProps: Record<string, any>) => (
        <Row alignItems="center" nowrap>
          <Button
            component="button"
            type="button"
            className={styles.leftButton}
            onClick={onClick}
            {...props}
          >
            {children}
          </Button>
          <Button
            component="button"
            type="button"
            className={styles.rightButton}
            {...dropdownButtonProps}
            {...props}
          >
            <Icon name="chevron-down" className={styles.chevron} />
          </Button>
        </Row>
      )}
    >
      {({ closeMenu }) => (
        <div className={styles.dropdownWrapper}>
          {options.map((option) => (
            <ListItem
              clickable
              onItemClick={closeMenu}
              onClickCapture={() => onSelect(option)}
            >
              {option.label}
            </ListItem>
          ))}
        </div>
      )}
    </Dropdown>
  );
});
