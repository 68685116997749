import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import classes from './ManualPopover.module.scss';

/* !!!important to make ManualPopover visible on hover
  you need to add this rule to you parent class or tag
  &:hover > tag or className {
    visibility: visible;
  }
*/

export interface ManualPopoverProps extends HTMLAttributes<HTMLDivElement> {
  className: string;
  text?: string;
  show?: boolean;
  variant?: string;
  showArrow?: boolean;
}

export const ManualPopover: FC<ManualPopoverProps> = ({
  children,
  text = '',
  variant = 'default',
  className,
  showArrow = true,
  show = false,
}) => {
  return (
    <>
      <span
        className={classNames(classes.ManualPopover, className, {
          [classes[variant]]: variant,
          [classes.show]: show,
          [classes.showArrow]: showArrow,
        })}
      >
        {children || text}
      </span>
    </>
  );
};
