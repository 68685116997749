import { RequestId } from 'common-types/api';
import { MaterialCategories, MaterialElement } from 'common-types/library';
import { OperationMaterials } from 'common-types/materials';
import find from 'lodash/find';
import pick from 'lodash/pick';
import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorsSelector,
  createLoadingSelector,
} from 'store/modules/api-requests';
import {
  createOperationMaterialsSelector,
  READ_OPERATION_MATERIALS_REQUEST,
  UPDATE_OPERATION_MATERIALS_GROUP_REQUEST,
  UPDATE_OPERATION_MATERIALS_REQUEST,
} from 'store/modules/operation-planning';
import {
  readOperationMaterials,
  updateOperationMaterials,
} from 'store/modules/operation-planning/thunks/materials';
import {
  isCountable,
  MATERIALS_CATEGORIES,
} from '../../../../../app-constants';
import { selectLanguage } from 'store/modules/language';

const actionsToFollow = [
  READ_OPERATION_MATERIALS_REQUEST,
  UPDATE_OPERATION_MATERIALS_REQUEST,
  UPDATE_OPERATION_MATERIALS_GROUP_REQUEST,
];
const loadingSelector = createLoadingSelector(actionsToFollow);
const errorsSelector = createErrorsSelector(actionsToFollow);

type OperationMaterialsSelectorCreator = (
  operationId: RequestId
) => (operationId: RequestId) => OperationMaterials | null;

export const useOperationMaterialsApi = (operationId) => {
  const dispatch = useDispatch();
  const materialsSelector = (
    createOperationMaterialsSelector as OperationMaterialsSelectorCreator
  )(operationId);
  const materials: OperationMaterials | null = useSelector<
    any,
    OperationMaterials | null
  >(materialsSelector);
  const lang = useSelector(selectLanguage);
  const loading = useSelector(loadingSelector);
  const errors = useSelector(errorsSelector);

  const fetchMaterials = () => {
    return dispatch(readOperationMaterials(operationId, lang));
  };

  const updateMaterials = (update) => {
    dispatch(updateOperationMaterials(operationId, update, lang));
  };

  const removeElement = (category, elementId) => {
    if (loading) return;
    const plainMaterials = { ...materials };
    const elementsList = plainMaterials[category];
    const elementsUpdate = elementsList.filter((el) => el.uuid !== elementId);
    const update = {
      ...plainMaterials,
      [category]: elementsUpdate,
    };

    return updateMaterials(update);
  };

  const updateElement = (category, elementId, elementUpdate) => {
    if (loading) return;
    const plainMaterials = { ...materials };
    const elementsList = plainMaterials[category];

    const update = {
      ...plainMaterials,
      [category]: elementsList.map((element) => {
        if (element.uuid !== elementId) return element;
        return { ...element, ...elementUpdate };
      }),
    };

    return updateMaterials(update);
  };

  const addElement = async (
    category: MaterialCategories,
    element: MaterialElement
  ) => {
    if (loading || !materials) return;

    // Excluding 'materialsGroups' property for the update request
    const plainMaterials = pick(materials, MATERIALS_CATEGORIES);
    const elementsList = plainMaterials[category] || [];
    const addedElement = find(elementsList, { id: element.id });
    if (addedElement) {
      if (!isCountable(element.category)) return;

      // @ts-ignore
      addedElement.count += 1;
    } else {
      plainMaterials[category] = elementsList.concat(element);
    }

    return updateMaterials(plainMaterials);
  };

  const reorderElements = async (
    category: MaterialCategories,
    fromIndex: number,
    toIndex: number
  ) => {
    const elementsList = materials?.[category] ?? [];
    const [movedEl] = elementsList.splice(fromIndex, 1);
    elementsList.splice(toIndex, 0, movedEl);
    const update = {
      ...materials,
      [category]: elementsList,
    };
    updateMaterials(update);
  };

  return {
    loading,
    errors,
    materials,

    fetchMaterials,
    updateMaterials,
    addElement,
    removeElement,
    updateElement,
    reorderElements,
  };
};
