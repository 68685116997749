import find from 'lodash/find';
import { LibraryElementsEntities } from 'api/v1/schemas/schemas-types';
import { RequestId, uuid } from 'common-types/api';
import { ThunkDispatch } from 'common-types/common';
import { MaterialElement } from 'common-types/library';
import {
  MaterialsGroup,
  MaterialsGroupInput,
  MaterialsGroupType,
} from 'common-types/materials';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorsSelector,
  createLoadingSelector,
} from 'store/modules/api-requests';
import { selectLibraryElements } from 'store/modules/entities/selectors/selectors';
import {
  addOperationMaterialsGroupAttachments,
  updateOperationMaterialsOfGroup,
} from 'store/modules/materials-groups/thunks';
import { UPDATE_OPERATION_MATERIALS_OF_GROUP_REQUEST } from 'store/modules/materials-groups/types';
import {
  createOperationMaterialsGroupsSelector,
  DELETE_OPERATION_MATERIALS_GROUP_REQUEST,
  READ_OPERATION_MATERIALS_GROUPS_REQUEST,
  UPDATE_OPERATION_MATERIALS_GROUP_REQUEST,
} from 'store/modules/operation-planning';
import {
  createOperationMaterialsGroup,
  deleteOperationMaterialsGroup,
  readOperationMaterialsGroups,
  updateOperationMaterialsGroup,
} from 'store/modules/operation-planning/thunks/materials';

import { materialsOrder } from 'utils/materials-order';
import { optimisticUpdateOperationMaterialGroup } from 'store/modules/materials-groups/actions';
import { makeResourcesHook } from 'hooks/resources/make-resources-hook';
import OperationPlanningContext from 'scenes/OperationPlanning/context';
import { READONLY } from 'app-constants';
import {
  getCreateStaticGroupFn,
  getStaticGroup,
  isStaticGroup,
} from 'components';
import { selectLanguage } from 'store/modules/language';

export const useOperationMaterialGroupsResource = makeResourcesHook(
  readOperationMaterialsGroups,
  'materialGroups'
);

const actions = [
  UPDATE_OPERATION_MATERIALS_OF_GROUP_REQUEST,
  DELETE_OPERATION_MATERIALS_GROUP_REQUEST,
  READ_OPERATION_MATERIALS_GROUPS_REQUEST,
  UPDATE_OPERATION_MATERIALS_GROUP_REQUEST,
];
const loadingSelector = createLoadingSelector(actions);
const errorsSelector = createErrorsSelector(actions);

export interface operationMaterialsGroupsApiHook {
  pristine: boolean;
  loading: boolean;
  errors: any[];
  groups: MaterialsGroup[] | null;
  addGroup: (group: MaterialsGroupInput) => Promise<MaterialsGroup>;
  fetchGroups: () => void;
  removeGroup: (groupId: RequestId) => Promise<MaterialsGroup>;
  updateGroup: (
    groupId: RequestId,
    update: Partial<MaterialsGroupInput>
  ) => Promise<MaterialsGroup>;
  createStaticGroup: (groupType: MaterialsGroupType) => Promise<MaterialsGroup>;
  makeStaticGroup: (groupType: MaterialsGroupType) => void;
  addGroupAttachments: (
    groupId: RequestId,
    attachments: number[]
  ) => Promise<MaterialsGroup>;
  addElement: (groupId: RequestId, element: MaterialElement) => void;
  removeElement: (groupId: RequestId, uuid: uuid) => void;
  updateElement: (groupId, uuid, update) => void;
  reorderElements: (groupId, fromIndex, toIndex) => void;
}

export interface useOperationMaterialsGroupsApiFn {
  (operationId: RequestId): operationMaterialsGroupsApiHook;
}

export const useOperationMaterialsGroupsApi: useOperationMaterialsGroupsApiFn =
  (operationId) => {
    const { mode } = useContext(OperationPlanningContext);
    const { t } = useTranslation();
    const [pristine, setPristine] = useState(true);
    const dispatchFn = useDispatch<ThunkDispatch>();
    const loading = useSelector(loadingSelector);
    const language = useSelector(selectLanguage);
    const errors = useSelector(errorsSelector);

    const materials: LibraryElementsEntities = useSelector(
      selectLibraryElements
    );
    const materialsGroupsSelector =
      createOperationMaterialsGroupsSelector(operationId);
    const groups: MaterialsGroup[] | null = useSelector(
      materialsGroupsSelector
    );

    const dispatch = (action) => {
      if (pristine) setPristine(false);
      return dispatchFn(action);
    };

    const { fetch: fetchGroupsResource } = useOperationMaterialGroupsResource();

    const fetchGroups = () => {
      return fetchGroupsResource(operationId, null, language);
    };

    const addGroup = async (group) => {
      const { entities, result } = await dispatch(
        createOperationMaterialsGroup(operationId, group, language)
      );
      return entities?.materialsGroups?.[result];
    };

    const updateGroup = (groupId, update) =>
      dispatch(
        updateOperationMaterialsGroup(operationId, groupId, update, language)
      );

    const removeGroup = (groupId) =>
      dispatch(deleteOperationMaterialsGroup(operationId, groupId));

    const addGroupAttachments = (groupId, attachments) =>
      dispatch(
        addOperationMaterialsGroupAttachments(operationId, groupId, attachments)
      );

    const addElement = (groupId: RequestId, element: MaterialElement) => {
      if (loading || !groups || !element?.category) return Promise.reject();
      const group: MaterialsGroup | undefined = (
        groups as MaterialsGroup[]
      ).find((item) => item.id === groupId);

      if (!group) return Promise.reject();

      const { instruments, implants, equipment }: MaterialsGroup = group;
      const materialGroup = { instruments, implants, equipment };
      const categoryElements = materialGroup[element.category];
      const addedElement = find(categoryElements, { id: element.id });
      if (addedElement) {
        addedElement.count += 1;
      } else {
        element.order =
          (instruments?.length ?? 0) +
          (implants?.length ?? 0) +
          (equipment?.length ?? 0);
        materialGroup[element.category] = categoryElements.concat(element);
      }

      return dispatch(
        updateOperationMaterialsOfGroup(
          operationId,
          groupId,
          materialGroup,
          language
        )
      );
    };

    const removeElement = (groupId, uuid) => {
      if (loading || !groups || !uuid) return Promise.reject();
      const element = materials[uuid];
      const group: MaterialsGroup | undefined = (
        groups as MaterialsGroup[]
      ).find((item) => item.id === groupId);

      if (!group) return Promise.reject();

      const { instruments, implants, equipment }: MaterialsGroup = {
        ...group,
        [element.category]: group[element.category].filter(
          (item) => item.id !== element.id
        ),
      };
      return dispatch(
        updateOperationMaterialsOfGroup(
          operationId,
          groupId,
          {
            instruments,
            implants,
            equipment,
          },
          language
        )
      );
    };

    const updateElement = (groupId, uuid, update) => {
      // Only count can be updated at the moment
      if (loading || !groups || typeof update.count !== 'number') {
        return Promise.reject();
      }
      const element = materials[uuid];
      const group: MaterialsGroup | undefined = (
        groups as MaterialsGroup[]
      ).find((item) => item.id === groupId);

      if (!group) return Promise.reject();

      const category = group[element.category];
      const updatedCategory = category.map((item) => {
        return item.id === element.id ? { ...element, ...update } : item;
      });
      const { instruments, implants, equipment }: MaterialsGroup = {
        ...group,
        [element.category]: updatedCategory,
      };
      return dispatch(
        updateOperationMaterialsOfGroup(
          operationId,
          groupId,
          {
            instruments,
            implants,
            equipment,
          },
          language
        )
      );
    };

    const reorderElements = (groupId, fromIndex, toIndex) => {
      const group: MaterialsGroup | undefined = (
        groups as MaterialsGroup[]
      ).find((item) => item.id === groupId);

      if (!group) return Promise.reject();

      const newMaterialOrder = materialsOrder(
        [...group.instruments, ...group.implants, ...group.equipment],
        fromIndex,
        toIndex
      );

      const getCategoryElements = (collection, category) =>
        collection.filter((item) => item && item.category === category);

      const updatedMaterials = {
        instruments: getCategoryElements(newMaterialOrder, 'instruments'),
        implants: getCategoryElements(newMaterialOrder, 'implants'),
        equipment: getCategoryElements(newMaterialOrder, 'equipment'),
      };

      dispatch(
        optimisticUpdateOperationMaterialGroup({
          ...group,
          ...updatedMaterials,
        })
      );
      dispatch(
        updateOperationMaterialsOfGroup(
          operationId,
          groupId,
          updatedMaterials,
          language
        )
      );
    };

    // Static groups
    const createStaticGroup = (groupType) => {
      const staticGroupObject = getCreateStaticGroupFn(groupType)(t);
      return addGroup(staticGroupObject);
    };

    const makeStaticGroup = async (groupType) => {
      if (!isStaticGroup(groupType)) {
        throw new Error('Invalid type of the static group.');
      }
      const group = getStaticGroup(groups, groupType);
      // If static group doesn't exist yet - create one
      if (!group && mode !== READONLY) await createStaticGroup(groupType);
    };

    return {
      //
      errors,
      groups,
      loading,
      pristine,
      //
      makeStaticGroup,
      createStaticGroup,
      fetchGroups,
      addGroup,
      removeGroup,
      updateGroup,
      addGroupAttachments,
      addElement,
      removeElement,
      updateElement,
      reorderElements,
    };
  };
