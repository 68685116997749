import React, { useMemo, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  DatePicker as AtlaskitDatePicker,
  DatePickerProps as AtlaskitDatePickerProps,
} from '@atlaskit/datetime-picker';

import classes from './DatePicker.module.scss';
import {
  ChangeEventLike,
  preventSubmitOnEnter,
  useDatePickerState,
} from './utils';
import { Label, ErrorMessage } from 'components/Form/FormInput';
import { DATE_FORMAT_MOMENT } from 'app-constants';
import { Prefer } from 'common-types';

interface DatePickerProps {
  /**
   * Date input label
   */
  label?: string;
  /**
   * Html element name
   */
  name?: string;
  /**
   * Date picker value
   */
  value?: string | number | Date;
  /**
   * Date picker error
   */
  error?: boolean | string | Record<string, string>;
  /**
   * Flog to enable/disable date picker
   */
  disabled?: boolean;
  /**
   * Flag to change onChange event value from string to ChangeEvent like
   */
  withEvent?: boolean;
  /**
   * Date picker select classname
   */
  className?: string;
  /**
   * Change event handler
   */
  onChange?: (value?: string | ChangeEventLike) => void;
  /**
   * Blur event handler
   */
  onBlur?: () => void;
}

/**
 * Render date picker component with date input
 */
export const DatePicker: FC<
  Prefer<DatePickerProps, Partial<AtlaskitDatePickerProps>>
> = memo(function DatePicker({
  name = 'date',
  label,
  value: rawValue,
  error,
  disabled,
  withEvent,
  className,
  onChange,
  onBlur,
  ...props
}) {
  const { t } = useTranslation();

  const { dateISO, onDateChange, parseInputValue } = useDatePickerState({
    name,
    rawValue,
    withEvent,
    onChange,
  });

  const datePickerSelectProps = useMemo(
    () => ({
      classNamePrefix: 'datePicker',
      className: cn(classes.datePicker, error && classes.error),
    }),
    [error]
  );

  return (
    <div className={className} onKeyDown={preventSubmitOnEnter}>
      {label !== null && <Label>{label || t('Date')}</Label>}

      <AtlaskitDatePicker
        name={name}
        value={dateISO}
        placeholder="dd.mm.yyyy"
        appearance="subtle"
        spacing="compact"
        hideIcon
        selectProps={datePickerSelectProps}
        isDisabled={disabled}
        isInvalid={!!error}
        dateFormat={DATE_FORMAT_MOMENT}
        parseInputValue={parseInputValue}
        onChange={onDateChange}
        onBlur={onBlur}
        weekStartDay={1}
        {...props}
      />

      {error && typeof error === 'string' && (
        <ErrorMessage>{error}</ErrorMessage>
      )}
    </div>
  );
});
