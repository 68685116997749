import { isEmpty, isNil } from 'lodash';
import { createSelector } from 'reselect';
import { selectUserId } from 'store/modules/auth';
import {
  selectCurrentPageOperation,
  selectOperationSteps,
} from 'store/modules/entities/selectors/operations';
import {
  mockOperationValues,
  prepareOperationInfoFormValues,
} from 'store/modules/operation-planning/helpers';
import { createStateGetter } from 'store/utils';
import { selectOperationTemplateNameTranslated } from './operation';
import { selectTotalStepsEstimation } from './steps';

// OP not submitted to server yet
export const isOperationDraft = createSelector(
  selectCurrentPageOperation,
  isNil
);

export const selectOperationForms = createStateGetter(
  'operationPlanning.forms'
);

export const canSubmitStepsForm = createSelector(
  [selectOperationSteps, selectTotalStepsEstimation],
  (steps, estimation) => Boolean(estimation && !isEmpty(steps))
);

export const selectOperationInfoFormValues = createSelector(
  [
    selectCurrentPageOperation,
    selectOperationTemplateNameTranslated,
    selectUserId,
  ],
  (operation, templateName, userId) => {
    if (!operation) {
      return mockOperationValues({ leadId: userId, templateName });
    }

    return prepareOperationInfoFormValues(operation);
  }
);
