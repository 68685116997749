import { Modifier } from 'react-popper';

import { SelectOption } from 'common-types/common';

/**
 * Select popper modifiers
 */
export const modifiers: ReadonlyArray<Modifier<any>> = [
  {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    /**
     * Makes the width of the popup component no larger than the width of the input
     */
    fn: ({ state }) => {
      if (state.styles.popper && state.rects.reference) {
        state.styles.popper.maxWidth = `${state.rects.reference.width}px`;
      }
    },
  },
];

/**
 * Function to return option display value
 */
export const itemToString = (option?: SelectOption) => {
  return option ? option.label : '';
};

/**
 * Function to return defaul selected value
 */
export const defaultSelectValue = (option: SelectOption) => {
  return option.value;
};
