import {
  CHANGE_TEMPLATES_SEARCH_QUERY,
  READ_TEMPLATES_LIST_FAILURE,
  READ_TEMPLATES_LIST_REQUEST,
  READ_TEMPLATES_LIST_SUCCESS,
} from 'store/modules/templates-library/types';
import { createFailureActionCreator } from 'store/utils';

export const readTemplatesRequest = page => ({
  type: READ_TEMPLATES_LIST_REQUEST,
  payload: {
    page,
  },
});

export const readTemplatesSuccess = ({ templatesTotal, templatesList }) => ({
  type: READ_TEMPLATES_LIST_SUCCESS,
  payload: {
    templatesTotal,
    templatesList,
  },
});

export const readTemplatesFailure = createFailureActionCreator(
  READ_TEMPLATES_LIST_FAILURE
);

export const changeTemplatesSearchQuery = query => ({
  type: CHANGE_TEMPLATES_SEARCH_QUERY,
  payload: { query },
});
