import React, { FC, HTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './Panel.module.scss';

export interface PanelBodyProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const PanelBody: FC<PanelBodyProps & HTMLAttributes<HTMLElement>> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <div className={classnames(className, styles.panelBody)} {...restProps}>
      {children}
    </div>
  );
};
