import { Category } from 'common-types/templates';
import { Menu, MenuItem, TranslatableString } from 'components';
import isEmpty from 'lodash/isEmpty';
import React, { ComponentType, useCallback, useMemo } from 'react';
import {
  getSubcategories,
  getTopLevelCategories,
  selectCategoriesByRelation,
  selectCategoriesParentsChain,
} from 'store/modules/template-categories';
import { CategoryItem } from './CategoryItem';
import { Subcategory } from './Subcategory';
import { TopCategoryItem } from './TopCategoryItem';

const withSafeEvent = (fn) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  fn(e);
};

interface Props {
  activeCategoryId: number | null;
  categories: Category[];
  defaultCategory?: Category;
  hideEmpty?: boolean;
  hideCount?: boolean;
  onCategorySelect?: (category: Category, categoriesFilter: number[]) => void;
}

export const TemplateCategoriesMenuView: ComponentType<Props> = ({
  activeCategoryId,
  categories,
  defaultCategory,
  hideEmpty = false,
  hideCount = false,
  onCategorySelect,
}) => {
  const currentCategoryId = activeCategoryId || defaultCategory?.id;
  const getCategoriesFilter = useCallback(
    (categoryId) =>
      selectCategoriesByRelation(categories, categoryId, 'id' as any), // "as any" is there just because Typescript assumes wrong type here
    [categories]
  );
  const categoriesSelected = useMemo(
    () =>
      selectCategoriesParentsChain(
        getCategoriesFilter(currentCategoryId),
        categories
      ) || [],
    [currentCategoryId, categories, getCategoriesFilter]
  );

  if (!categories?.length) {
    return null;
  }

  const subCategories = getSubcategories(
    categories,
    currentCategoryId,
    hideEmpty
  );
  const topLevelCategories = getTopLevelCategories(categories);
  const selectedTopCategory = categoriesSelected[0];
  const handleSelect = (category) => {
    if (!onCategorySelect || !category) return;
    onCategorySelect(category, getCategoriesFilter(category.id));
  };

  return (
    <Menu>
      <TopCategoryItem
        hideEmpty={hideEmpty}
        topLevelCategories={topLevelCategories}
        defaultCategory={defaultCategory}
        onCategorySelect={handleSelect}
        selectedCategory={selectedTopCategory}
      />
      {!isEmpty(categoriesSelected) &&
        categoriesSelected.map(
          (category, index) =>
            index > 0 && (
              <MenuItem
                id={category.id.toString()}
                key={category.id}
                label={<TranslatableString string={category.name} />}
                closeSubmenuOnClick
                onClick={withSafeEvent(() => handleSelect(category))}
                variant="secondary"
              >
                {categories
                  .filter(
                    (sameLevelCategory) =>
                      (defaultCategory &&
                        sameLevelCategory.id !== defaultCategory.id &&
                        sameLevelCategory.id !== category.id &&
                        sameLevelCategory.parentId === category.parentId) ||
                      (sameLevelCategory.parentId === category.parentId &&
                        sameLevelCategory.id !== category.id)
                  )
                  .map((sameLevelCategory) => (
                    <CategoryItem
                      key={category.id}
                      category={sameLevelCategory}
                      onClick={handleSelect}
                      hideEmpty={hideEmpty}
                    />
                  ))}
              </MenuItem>
            )
        )}
      {subCategories &&
        subCategories.map((category) => (
          <Subcategory
            key={category.id}
            category={category}
            categories={categories}
            onCategorySelect={handleSelect}
            hideEmpty={hideEmpty}
            hideCount={hideCount}
          />
        ))}
    </Menu>
  );
};
