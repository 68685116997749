import {
  readHistoryStatisticPeriodReq,
  readHistoryStatisticReq,
  updateIncident as updateIncidentReq,
} from 'api/v1';
import * as APIv2 from 'api/v2';
import { DATE_RANGES } from 'app-constants';
import { makeThunk } from 'store/utils';
import { makeThunkFromTypes } from 'store/utils/make-thunk';
import {
  readOperationsStatsFailure,
  readOperationsStatsRequest,
  readOperationsStatsSuccess,
  updateIncidentFailure,
  updateIncidentRequest,
  updateIncidentSuccess,
} from './actions';
import {
  READ_OPERATIONS_HISTORY_FAILURE,
  READ_OPERATIONS_HISTORY_REQUEST,
  READ_OPERATIONS_HISTORY_SUCCESS,
} from './types';
import { isProductType } from 'utils/get-env';

export const readOperationsHistory = makeThunkFromTypes(
  isProductType('vet')
    ? APIv2.readOperationsHistoryVet
    : APIv2.readOperationsHistory,
  {
    request: READ_OPERATIONS_HISTORY_REQUEST,
    success: READ_OPERATIONS_HISTORY_SUCCESS,
    failure: READ_OPERATIONS_HISTORY_FAILURE,
  },
  'Failed to read operations history list'
);

export const updateIncident = makeThunk(updateIncidentReq, {
  request: updateIncidentRequest,
  success: updateIncidentSuccess,
  failure: updateIncidentFailure,
});

export const readHistoryStatistic = (timeRange, userId) => async (dispatch) => {
  try {
    dispatch(readOperationsStatsRequest(timeRange));

    let period;
    if (timeRange === DATE_RANGES.week) {
      period = 'week';
    } else if (timeRange === DATE_RANGES.twoWeek) {
      period = 'weeks';
    } else if (timeRange === DATE_RANGES.month) {
      period = 'month';
    }

    const stats = !timeRange
      ? await readHistoryStatisticReq()
      : await readHistoryStatisticPeriodReq(userId, period);

    dispatch(readOperationsStatsSuccess(stats));
  } catch (error) {
    dispatch(readOperationsStatsFailure(error));
  }
};
