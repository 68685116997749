import React, { FC } from 'react';

import { ListItem } from './ListItem';
import styles from './List.module.scss';
import { EditableInput } from 'components/Form/Input';

export interface ListItemInputProps {
  error?: string;
  inputProps?: any; // TODO define correctly input props, suitable for separated task as are spreaded through many components
  onCancel?(): void;
  onSave?(id: string | number): void;
}

export const ListItemInput: FC<ListItemInputProps> = ({
  error,
  onCancel,
  onSave,
  inputProps = {},
}) => {
  return (
    <ListItem inlineChildren>
      <EditableInput
        {...inputProps}
        error={error}
        className={styles.inputWrapper}
        inputClassName={styles.input}
        onEditCancel={onCancel}
        onEdit={onSave}
      />
    </ListItem>
  );
};
