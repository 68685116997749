import { getInitialLanguage } from './helpers';
import { SET_LANGUAGE } from './types';

export const initialState = {
  'deepl-language': getInitialLanguage(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        'deepl-language': action.payload,
      };

    default:
      return state;
  }
};
