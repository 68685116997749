import cn from 'classnames';
import React, { ElementType, AllHTMLAttributes, FC } from 'react';
import styles from './Col.module.scss';

type ColumnSize =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | 'auto';

interface ColProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Root html element
   */
  component?: ElementType;
  /**
   * Flex basis style
   */
  basis?: number | string;
  /**
   * Flex shrink style
   */
  shrink?: boolean;
  /**
   * Column width
   */
  width?: ColumnSize;
  /**
   * Flag to set width auto
   */
  auto?: boolean;
}

/**
 * Renders a row column
 */
export const Col: FC<ColProps> = ({
  component: Component = 'div',
  width,
  basis,
  children,
  className,
  shrink,
  auto,
  ...props
}) => (
  <Component
    className={cn(
      styles.col,
      {
        [styles['width-auto']]: auto,
        [styles[`width-${width}`]]: width,
        [styles.grow]: !basis && !shrink && !auto,
      },
      className
    )}
    style={{ flexBasis: basis }}
    {...props}
  >
    {children}
  </Component>
);
