import { ACCEPT_FILE_TYPES } from 'app-constants/files';
import { Attachment } from 'common-types/attachments';
import { AttachmentsManager } from 'components';
import uniqBy from 'lodash/uniqBy';
import React, { ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOpAttachmentsCollection } from 'scenes/OperationPlanning/hooks/useOpAttachmentsCollection';
import {
  createMaterialsGroupAttachmentsIdsSelector,
  createMaterialsGroupAttachmentsSelector,
} from 'store/modules/operation-planning';

interface Props {
  maxSelect?: number;
  onCancel?: () => void;
  onSelect?: (attachmentsIds: number[]) => void;
  operationId: number;
  selectedGroupId?: number;
}

export const MaterialsGroupAttachmentsManager: ComponentType<Props> = ({
  selectedGroupId,
  operationId,
  onSelect,
  maxSelect = 2,
  onCancel,
}) => {
  const { t } = useTranslation();
  const operationAttachments = useOpAttachmentsCollection(operationId);

  const selectMaterialGroupAttachmentsIds =
    createMaterialsGroupAttachmentsIdsSelector(selectedGroupId);

  const selectMaterialsGroupAttachments =
    createMaterialsGroupAttachmentsSelector(selectedGroupId);

  const tableAttachments = useSelector(selectMaterialsGroupAttachments);
  const selectedAttachmentsIds = useSelector(selectMaterialGroupAttachmentsIds);

  const attachments = useMemo(
    () =>
      uniqBy(
        [...operationAttachments, ...(tableAttachments as Attachment[])],
        'id'
      ),
    [operationAttachments, tableAttachments]
  );

  return (
    <AttachmentsManager
      accept={ACCEPT_FILE_TYPES}
      attachments={attachments}
      allowEmpty
      defaultSelected={selectedAttachmentsIds}
      id="table-attachments-manager"
      maxSelect={maxSelect}
      onCancel={onCancel}
      onSelect={onSelect}
      title={t('tableAttachments')}
    />
  );
};
