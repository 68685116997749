import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../MenuItem/MenuItem';
import { Text } from 'components/Typography/Text';

interface Props {}

export const SearchError: ComponentType<Props> = () => {
  const { t } = useTranslation();

  return (
    <MenuItem
      label={
        <Text variant="caption" color="error">
          {t('somethingWentWrong')}
        </Text>
      }
      sublabel={
        <Text component="span" variant="paragraph-sm" color="error">
          {t('pleaseTryAgainLater')}
        </Text>
      }
    />
  );
};

SearchError.displayName = 'SearchError';
