import React, { FC, memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './SideBar.module.scss';
import { NavigationItem } from './NavigationItem';

import { FeatureFlagCheck } from 'features/FeatureFlagCheck';
import {
  ASSESSMENTS,
  ASSESSMENT_FORMS,
  DASHBOARD,
  HISTORY_OPERATIONS,
  INCIDENTS_REPORTS,
  QUALIFICATION_FORMS,
  QUALIFICATION_TABLE,
  RESEARCH,
  SETTINGS,
  STYLEGUIDE,
  TEMPLATES_LIBRARY,
} from 'scenes/routes.enum';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotApprovedOperationsCount } from 'store/modules/users/selectors';
import { readNotApprovedOperationsCount } from 'store/modules/users';
import { Logo, StatefulNavLink } from 'components';

/**
 * Renders main layout sidebar
 */
export const SideBar: FC = memo(function Sidebar() {
  const { t } = useTranslation();

  const location = useLocation();
  const dispatch = useDispatch();
  const notApprovedOrganizationsCount = useSelector(
    selectNotApprovedOperationsCount
  );

  // Re-request operations count on page change
  useEffect(() => {
    dispatch(readNotApprovedOperationsCount());
  }, [dispatch, location.pathname]);

  return (
    <aside className={styles.sidebar}>
      <div className={styles.logo}>
        <Logo variant="minified" className={styles.logoImage} />
      </div>
      <nav className={styles.navigationContainer}>
        <ul className={styles.navigation}>
          <FeatureFlagCheck name="dashboard">
            <NavigationItem
              name={t('Dashboard')}
              href={DASHBOARD}
              icon="dashboard"
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="library">
            <NavigationItem
              name={t('Library')}
              href={TEMPLATES_LIBRARY}
              icon="library"
              component={StatefulNavLink}
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="history">
            <NavigationItem
              name={t('History')}
              href={HISTORY_OPERATIONS}
              icon="history"
              component={StatefulNavLink}
              className={styles.navigationItem}
              showBadge={
                !!(
                  notApprovedOrganizationsCount &&
                  notApprovedOrganizationsCount > 0
                )
              }
              badge={
                <span className={styles.historyBadge}>
                  {notApprovedOrganizationsCount}
                </span>
              }
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="assessment">
            <NavigationItem
              name={t('collection')}
              href={ASSESSMENTS}
              icon="assessment-collection"
              component={StatefulNavLink}
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="assessmentForms">
            <NavigationItem
              name={t('assessmentForms.title')}
              href={ASSESSMENT_FORMS}
              icon="assessment-forms"
              component={StatefulNavLink}
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="incidentsReporting">
            <NavigationItem
              name={t('Incidents')}
              href={INCIDENTS_REPORTS}
              icon="incidents"
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="qualification">
            <NavigationItem
              name={t('qualification.qualification')}
              href={QUALIFICATION_TABLE}
              icon="chart"
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="qualificationForms">
            <NavigationItem
              name={t('qualification.qualificationForm')}
              href={QUALIFICATION_FORMS}
              icon="briefing"
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <FeatureFlagCheck name="research">
            <NavigationItem
              name={t('Research')}
              href={RESEARCH}
              icon="research"
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
          <NavigationItem
            name={t('Settings')}
            href={SETTINGS}
            icon="settings"
            className={styles.navigationItem}
          />
          <FeatureFlagCheck name="styleguide">
            <NavigationItem
              name="Styleguide"
              href={STYLEGUIDE}
              icon="library"
              className={styles.navigationItem}
            />
          </FeatureFlagCheck>
        </ul>
      </nav>
    </aside>
  );
});
