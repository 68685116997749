import { isValidElement } from 'react';

function validateChildren(c) {
  return (
    isValidElement(c) ||
    typeof c === 'string' ||
    typeof c === 'number' ||
    c === null
  );
}

export function isValidReactChildren(children: any): boolean {
  return Array.isArray(children)
    ? children.every(validateChildren)
    : validateChildren(children);
}
