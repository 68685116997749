import React, { memo, HTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './Loader.module.scss';

export const Spinner = memo<HTMLAttributes<HTMLSpanElement>>(
  ({ className, ...props }) => (
    <span className={cn(styles.spinner, className)} {...props} />
  )
);
