import cn from 'classnames';
import React, { AllHTMLAttributes, ElementType, forwardRef } from 'react';
import styles from './Row.module.scss';

interface RowProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Root html element
   */
  component?: ElementType;
  /**
   * Flex direction style
   */
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  /**
   * Flex align items style
   */
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  /**
   * Flex justify items style
   */
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  /**
   * Flag to set flex-wrap:nowrap style
   */
  nowrap?: boolean;
  /**
   * Flag to add double gap
   */
  doubleGap?: boolean;
  /**
   * Flag to set inline flex style
   */
  inline?: boolean;
}

/**
 * Renders a flex row
 */
export const Row = forwardRef<HTMLElement, RowProps>(
  (
    {
      component: Component = 'div',
      direction = 'row',
      doubleGap,
      nowrap,
      alignItems = 'stretch',
      justifyContent = 'flex-start',
      inline,
      className,
      ...props
    },
    ref
  ) => (
    <Component
      ref={ref}
      className={cn(
        styles.row,
        styles[`align-${alignItems}`],
        styles[`justify-${justifyContent}`],
        styles[direction],
        doubleGap && styles.doubleGap,
        inline && styles.inline,
        !nowrap && styles.wrap,
        className
      )}
      {...props}
    />
  )
);
