import cn from 'classnames';
import { Icon } from 'components/Icon';
import React, {
  ComponentPropsWithoutRef,
  ElementType,
  forwardRef,
  memo,
} from 'react';
import styles from './Button.module.scss';
import { Prefer } from 'common-types';

export type IconButtonProps = Prefer<
  {
    /**
     * Root html element
     */
    component?: ElementType;
    /**
     * Icon name
     */
    icon?: string;
    /**
     * Flag whether to show background color
     */
    keepBackground?: boolean;
    /**
     * Predefined button size styles
     */
    size?: 'sm' | 'md';
    /**
     * Flag to style button's color
     */
    primary?: boolean;
  },
  ComponentPropsWithoutRef<'button'>
>;

/**
 * Render button component with icon
 */
export const IconButton = memo(
  forwardRef<HTMLElement, IconButtonProps>(function IconButton(
    {
      children,
      className,
      icon,
      component: Component = 'button',
      keepBackground = false,
      primary = false,
      size = 'md',
      ...props
    },
    ref
  ) {
    const rootClassName = cn(
      styles.icon, // injects styles from mixin button
      styles.iconButton,
      styles[size],
      primary && styles.primary,
      keepBackground && styles.keepBackground,
      className
    );

    return (
      <Component ref={ref} className={rootClassName} {...props}>
        {children}
        <Icon name={icon} />
      </Component>
    );
  })
);
