import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './EditableString.module.scss';

import { EditableInput } from 'components/Form/Input';
import { Editable } from 'components/Editable';
import { Multiline } from 'components/Multiline';

interface EditableStringProps {
  inputProps?: {
    className?: string;
    value?: string;
  };
  children?: string;
  submitEmpty?: boolean;
  onEdit?(value: string): void;
  textarea?: boolean;
}

export const EditableString: FC<EditableStringProps> = ({
  inputProps,
  children = '',
  submitEmpty = false,
  onEdit,
  textarea = false,
}) => {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(inputProps?.value ?? '');

  const handleComplete = (event) => {
    const value = event.target.value.trim();

    if (!submitEmpty && !value) {
      return setInputError(t('operationStepTitleCannotBeEmpty'));
    }

    if (inputProps?.value !== value && onEdit) {
      onEdit(value);
    }

    setEditMode(false);
  };

  const updatedInputProps = {
    ...inputProps,
    value: inputValue,
  };

  return editMode ? (
    <EditableInput
      {...updatedInputProps}
      textarea={textarea}
      error={inputError}
      inputClassName={classNames(inputProps?.className, styles.input, {
        [styles.errorInput]: inputError,
      })}
      onEditCancel={() => {
        setInputError('');
        setInputValue(inputProps?.value ?? '');
        setEditMode(false);
      }}
      onEditComplete={handleComplete}
      onChange={(e) => {
        setInputError('');
        setInputValue(e.target.value.trim());
      }}
      defaultValue={inputProps?.value}
    />
  ) : (
    <Editable onClick={() => setEditMode(true)}>
      <Multiline>{children}</Multiline>
    </Editable>
  );
};
