import React, { ComponentType, ReactNode } from 'react';

import classes from './Cell.module.scss';

export interface CellProps {
  value?: ReactNode;
  original?: object;
}

export const Cell: ComponentType<CellProps> = ({ children, value }) => {
  return <strong className={classes.cell}>{children || value || null}</strong>;
};
