import { normalizeResponse } from 'api/helpers/normalize-response';
import { APIv1 } from 'api/instance';
import { userSchema } from './schemas';

export const loginUserReq = (data) =>
  APIv1.post('auth/login', data).then(normalizeResponse(userSchema));

export const logoutUserReq = () => APIv1.post('auth/logout');

export const updatePasswordReq = (data) =>
  APIv1.post('/auth/update_password', data);

export const resetPass = (data) => APIv1.post('auth/reset_password', data);

export const readCurrentUser = () => APIv1.get('user/me');

export const reactivateAccountReq = (email) =>
  APIv1.post('/deactivated-accounts/reactivate', {
    email,
  });
