import { uuid } from 'common-types/api';
import { v4 as uuidv4 } from 'uuid';

type Item = {
  id?: string | number;
  [key: string]: any;
};

export const frontendId = () => Math.floor(Math.random() * 10000);
export const frontendUUID = (): uuid => uuidv4();

export const withoutId = (item: Item) => {
  const { id, ...rest } = item;
  return rest;
};
