import * as APIv1 from 'api/v1';
import {
  AllowedMimeType,
  ALLOWED_FILE_TYPES,
  VIDEO_SIZE_LIMIT,
} from 'app-constants/files';
import axios from 'axios';
import { Attachment, LocalFile } from 'common-types/attachments';
import { HTMLFile, ThunkDispatch } from 'common-types/common';
import { mapKeys } from 'lodash';
import mime from 'mime-types';
import { nanoid } from 'nanoid';
import prettyBytes from 'pretty-bytes';
import i18n from 'translations/i18n-instance';
import { getFileExtension } from 'utils';
import {
  resetFilesUploadProgress,
  uploadAttachmentsFailure,
  uploadAttachmentsRequest,
  uploadAttachmentsSuccess,
  uploadFileFailure,
  uploadFileProgress,
  uploadFileRequest,
  uploadFileSuccess,
} from './actions';

function createLocalFile({ id, name, mimeType, uploadedFileMeta }): LocalFile {
  const localFile = {
    id,
    name,
    mimeType: mimeType,
    fileKey: uploadedFileMeta?.fields?.key,
    ...uploadedFileMeta,
  };
  return localFile;
}

export function uploadFileToS3(fileId: string, file: HTMLFile) {
  return async function uploadFileToS3Thunk(
    dispatch: ThunkDispatch
  ): Promise<LocalFile> {
    // Validate file size and extension
    if (file.size > VIDEO_SIZE_LIMIT) {
      throw new Error(
        `${i18n.t('errors:maxUploadFileSizeIs')} ${prettyBytes(
          VIDEO_SIZE_LIMIT
        )}`
      );
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type as AllowedMimeType)) {
      throw new Error(
        `${i18n.t('errors:filetype')} ${file.type} is not allowed`
      );
    }

    const body = {
      mimeType: file.type,
      extension:
        (file.name ? getFileExtension(file.name) : mime.extension(file.type)) ??
        undefined,
    };
    const uploadedFileMeta = await APIv1.createFile_DEPRECATED(body);
    const formData = new FormData();
    // order is important first metadata then file itself
    if (uploadedFileMeta?.fields) {
      mapKeys(uploadedFileMeta.fields, (value, key) =>
        formData.append(key, value)
      );
    }

    formData.append(
      'file', // name of prop is important
      file,
      file.name
    );
    const cancelSource = axios.CancelToken.source();
    const uploadConfig = {
      cancelToken: cancelSource.token,
      onUploadProgress: (ProgressEvent) => {
        let progress = 0;
        const totalLength = ProgressEvent.lengthComputable
          ? ProgressEvent.total
          : ProgressEvent.target.getResponseHeader('content-length') ||
            ProgressEvent.target.getResponseHeader(
              'x-decompressed-content-length'
            );
        if (totalLength !== null) {
          progress = Math.round((ProgressEvent.loaded * 100) / totalLength);
        }

        return dispatch(
          uploadFileProgress({
            id: fileId,
            progress,
            cancel: cancelSource.cancel,
          })
        );
      },
    };

    await APIv1.uploadToS3(uploadedFileMeta, formData, uploadConfig).catch(
      (e) => e
    );

    const localFile = createLocalFile({
      id: fileId,
      name: file.name,
      mimeType: file.type,
      uploadedFileMeta,
    });

    return localFile;
  };
}

export function uploadLocalFile_DEPRECATED({ id: fileId, file }) {
  return async function uploadLocalFileThunk_DEPRECATED(dispatch) {
    try {
      dispatch(uploadFileRequest({ id: fileId, name: file.name, file }));
      const localFile = await dispatch(uploadFileToS3(fileId, file));
      dispatch(uploadFileSuccess(localFile));
      return localFile;
    } catch (error) {
      dispatch(
        uploadFileFailure(file, {
          ...error,
          message: error.message || i18n.t('somethingWentWrong'),
          id: fileId,
          name: file.name,
        })
      );
    }
  };
}

export function uploadFile_DEPRECATED(file, multiple) {
  return async function uploadFileThunk_DEPRECATED(dispatch) {
    if (!multiple) dispatch(resetFilesUploadProgress());
    return dispatch(uploadLocalFile_DEPRECATED({ id: nanoid(), file }));
  };
}

export const uploadFiles_DEPRECATED = (files) => async (dispatch) => {
  if (!files) {
    return Promise.reject();
  }

  const filesUploadsPromises = files.map((file) =>
    dispatch(uploadFile_DEPRECATED(file, true))
  );

  const S3UploadedFiles = await Promise.all(filesUploadsPromises);
  dispatch(resetFilesUploadProgress());
  return S3UploadedFiles.filter(Boolean);
};

export const uploadAttachments_DEPRECATED =
  (files, prevAttachedFilesIds = []) =>
  async (dispatch) => {
    try {
      const attachmentRequest = async ({ name, fileKey, mimeType }) => {
        try {
          const attachmentInput = {
            name,
            fileUrl: fileKey,
            mimeType,
          };
          dispatch(uploadAttachmentsRequest(attachmentInput));
          const attachment = await APIv1.createAttachment(attachmentInput);
          dispatch(uploadAttachmentsSuccess([attachment]));
          return attachment;
        } catch (error) {
          error.attachment = { name, fileKey };
          dispatch(uploadAttachmentsFailure(error.message, error));
        }
      };
      const attachmentsRequests = files.map(attachmentRequest);

      const uploadedAttachments = await Promise.all(attachmentsRequests);
      const attachmentsIds = [
        ...prevAttachedFilesIds,
        ...(uploadedAttachments as Attachment[])
          .map((attachment) => attachment?.id)
          .filter(Boolean),
      ];
      return attachmentsIds;
    } catch (error) {
      dispatch(uploadAttachmentsFailure(error.message, error));
    }
  };
