import cn from 'classnames';
import { TextareaBase } from 'components/Form/Textarea/TextareaBase';
import React, { forwardRef, HTMLAttributes } from 'react';
import classes from '../InputString.module.scss';

interface Props {
  defaultInputSize?: number;
  error?: string;
}

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  Props & HTMLAttributes<HTMLTextAreaElement>
>(({ className, defaultInputSize = 2, error, ...textareaProps }, ref) => (
  <TextareaBase
    ref={ref}
    className={cn(className, classes.input, { [classes.error]: error })}
    // @ts-ignore
    maxRows={defaultInputSize}
    {...textareaProps}
  />
));
