import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const history = createBrowserHistory();

// Track initial pageview
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen(location => {
  ReactGA.pageview(location.pathname + location.search);
});

export default history;
