import { schema } from 'normalizr';

import { addressSchema } from './address';

export const instituteCompanyContactSchema = new schema.Entity(
  'companiesContacts'
);

export const instituteSchema = new schema.Entity(
  'institutes',
  {
    address: addressSchema,
    companiesContacts: [instituteCompanyContactSchema],
  },
  {
    processStrategy(institute) {
      if (!institute.defaultAssignees?.length) return institute;

      institute.defaultAssignees = institute.defaultAssignees.map((d) => {
        d.defaultAssigneeRole = d.role;
        return d;
      });

      return institute;
    },
  }
);

export const departmentSchema = new schema.Entity('departments');

export const materialCompanySchema = new schema.Entity('materialCompanies');

export const materialCompaniesSchema = new schema.Array(materialCompanySchema);
