import { APIv1 } from 'api/instance';
import { AxiosRequestConfig, id } from 'common-types/api';
import { AttachmentInput } from 'common-types/attachments';
import { normalize } from 'utils/normalize';
import { attachmentsSchema } from './schemas';

const normalizeAttachment = (response) =>
  normalize(response, attachmentsSchema);

export const createAttachment = (body, config?: AxiosRequestConfig) => {
  return APIv1.post('/attachments', body, config).then(normalizeAttachment);
};

export const updateAttachment = (attachmentId: id, update: AttachmentInput) =>
  APIv1.patch(`/attachments/${attachmentId}`, update).then(normalizeAttachment);

export const deleteAttachment = (id) =>
  APIv1.delete(`/attachments/${id}`).then(normalizeAttachment);
