import React, { ReactElement } from 'react';
import { compose } from 'redux';

import { Icon } from 'components/Icon';

import withComponentDidCatch from 'containers/withComponentDidCatch';
import { useTranslation } from 'react-i18next';
import { EditableListItem } from 'components/List/EditableListItem';

interface Props {
  notes: string;
  onEdit: Function;
  templateId: number | null;
}

const PostOpNotesInputComponent = ({
  onEdit,
  notes = '',
  templateId = null,
  ...listProps
}: Props): ReactElement => {
  const { t } = useTranslation();

  const handleEdit = (content) => {
    if (!content || content === notes) return;
    onEdit(templateId, content);
  };

  const placeholder = t('enterPostOPNotesToAutoGeneratedReport');

  return (
    <EditableListItem
      translatable
      icon={<Icon name="reports" className="text-primary" />}
      onEdit={handleEdit}
      placeholder={placeholder}
      title={<strong>{t('postOPNotes')}</strong>}
      value={notes}
      inputProps={{ name: 'postop-notes', textarea: true }}
      {...listProps}
    />
  );
};

const enhancer = compose(withComponentDidCatch('Post OP notes'));

export const PostOpNotesInput = enhancer(PostOpNotesInputComponent);
