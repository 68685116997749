import { APIv1 } from 'api/instance';
import { AxiosRequestConfig, RequestId } from 'common-types/api';
import { MaterialsGroupInput } from 'common-types/materials';
import { NormalizedResponse } from 'common-types/normalize';
import { normalize } from 'utils/normalize';
import { omitLocalGeneratedFields } from 'utils/omit-generated-fields';
import { normalizeResponse } from 'api/helpers/normalize-response';
import { operationMaterialsSchema } from '../schemas/materials';
import { normalizeMaterialsGroup, updateMaterialsOfGroupFn } from './common';
import { generateLanguageURL } from 'utils/languages';

export const normalizeOperationMaterials = (operationId) => (response) =>
  normalize(
    {
      operationId,
      ...response,
    },
    operationMaterialsSchema
  );

export const readOperationMaterials = (operationId: RequestId, lang?: string) =>
  APIv1.get(
    generateLanguageURL(`/operations/${operationId}/materials`, lang)
  ).then(normalizeOperationMaterials(operationId));

export const updateOperationMaterials = (
  operationId: RequestId,
  update,
  lang?: string
) =>
  APIv1.post(
    generateLanguageURL(`/operations/${operationId}/materials`, lang),
    omitLocalGeneratedFields(update),
    { ignoreRequestBodyClean: true }
  ).then(normalizeOperationMaterials(operationId));

export const createOperationMaterialsGroup = (
  operationId: RequestId,
  group: MaterialsGroupInput,
  lang?: string
): Promise<NormalizedResponse> =>
  APIv1.post(
    generateLanguageURL(`/operations/${operationId}/materials/groups`, lang),
    group
  ).then(normalizeMaterialsGroup);

export const readOperationMaterialsGroups = (
  operationId: RequestId,
  config?: AxiosRequestConfig,
  lang?: string
): Promise<NormalizedResponse> =>
  APIv1.get(
    generateLanguageURL(`/operations/${operationId}/materials/groups`, lang),
    config
  )
    // Simultate Operation Materials object to normalize it with relation to OP
    .then((materialsGroups) => ({
      materialsGroups,
      operationId,
    }))
    .then(normalizeResponse(operationMaterialsSchema));

export const readOperationMaterialsGroup = (
  operationId: RequestId,
  groupId: RequestId,
  config?: AxiosRequestConfig
): Promise<NormalizedResponse> =>
  APIv1.get(
    `/operations/${operationId}/materials/groups/${groupId}`,
    config
  ).then(normalizeMaterialsGroup);

export const updateOperationMaterialsGroup = (
  operationId: RequestId,
  groupId: RequestId,
  update: Partial<MaterialsGroupInput>,
  lang?: string
): Promise<NormalizedResponse> =>
  APIv1.patch(
    generateLanguageURL(
      `/operations/${operationId}/materials/groups/${groupId}`,
      lang
    ),
    update
  ).then(normalizeMaterialsGroup);

export const deleteOperationMaterialsGroup = (
  operationId: RequestId,
  groupId: RequestId
) =>
  APIv1.delete(`/operations/${operationId}/materials/groups/${groupId}`).then(
    normalizeMaterialsGroup
  );

export const updateOperationMaterialsOfGroup: updateMaterialsOfGroupFn = (
  operationId,
  groupId,
  materialsOfGroup,
  language
) => {
  return APIv1.post(
    generateLanguageURL(
      `/operations/${operationId}/materials/groups/${groupId}/materials`,
      language
    ),
    materialsOfGroup,
    { ignoreRequestBodyClean: true }
  ).then(normalizeMaterialsGroup);
};

export const addOperationMaterialsGroupAttachments = (
  operationId: RequestId,
  groupId: RequestId,
  attachments
) =>
  APIv1.post(
    `/operations/${operationId}/materials/groups/${groupId}/attachments`,
    attachments
  ).then(normalizeMaterialsGroup);

export const deleteOperationMaterialsGroupAttachment = (
  operationId: RequestId,
  groupId: RequestId,
  attachmentId: RequestId
) =>
  APIv1.post(
    `/operations/${operationId}/materials/groups/${groupId}/attachments/${attachmentId}`
  ).then(normalizeMaterialsGroup);
