import { Icon } from 'components/Icon';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {}

export const SuccessLabel: ComponentType<Props> = ({ children }) => {
  const { t } = useTranslation();
  const label = children === undefined ? t('Success') : children;

  return (
    <strong className="text-success">
      <Icon icon="check-circle" gapRight />
      {label}
    </strong>
  );
};

SuccessLabel.displayName = 'SuccessLabel';
