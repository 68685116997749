import { Component } from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import GlobalNotifications from 'scenes/GlobalNotifications';
import { withRouterPropTypes } from 'shared-prop-types';
import { ROOT_CRASHED, AUTH } from 'scenes/routes.enum';

import { PrivateRoute } from 'components';
import { Auth } from 'scenes/Auth';
import { App } from 'scenes/App';

class Base extends Component {
  static _propTypes = {
    ...withRouterPropTypes,
  };

  state = {
    error: null,
    pathname: '/',
  };

  componentDidCatch(error) {
    const {
      location: { pathname },
    } = this.props;

    this.setState({ error, returnPath: pathname });
    Bugsnag.notify(error);
  }

  render() {
    if (this.state.error) {
      return (
        <Redirect to={`${ROOT_CRASHED}?returnPath=${this.state.returnPath}`} />
      );
    }
    return (
      <>
        <Switch>
          <Route path={AUTH} component={Auth} />
          <PrivateRoute path="/" component={App} />
        </Switch>
        <GlobalNotifications />
      </>
    );
  }
}

export default withRouter(Base);
