import ReactGA from 'react-ga';
import qs from 'qs';

import {
  loginUserReq,
  logoutUserReq,
  readCurrentUser as readCurrentUserRequest,
  readUser as readUserRequest,
  resetPass,
  reactivateAccountReq,
} from 'api/v1';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import {
  AUTH,
  AUTH_DEACTIVATED,
  HOME,
  LOGIN,
  REQUIRE_UPDATE_PASSWORD,
  RETURN_LOGIN,
} from 'scenes/routes.enum';
import { FALLBACK_LANGUAGE, USER_METADATA } from 'app-constants';
import { user as userStore, store } from 'local-store';
import { makeThunk } from 'store/utils';
import * as actions from './actions';
import settings from 'local-store/settings';
import { i18n } from 'translations/i18n-instance';

export function saveAuthenticationData(user) {
  const { accessToken, email, givenName, imageUrl, refreshToken, surname } =
    user;
  userStore.setTokens({ accessToken, refreshToken });

  if (userStore.getKeepSession()) {
    userStore.set({
      email: email,
      givenName: givenName,
      surname: surname,
      image: imageUrl,
    });
  } else {
    userStore.cleanUser();
  }

  i18n.changeLanguage(user.settings?.lang ?? FALLBACK_LANGUAGE);

  return true;
}
const requestLogin = (data, redirect) => async (dispatch) => {
  try {
    dispatch(actions.loginRequest(data));
    const response = await loginUserReq(data);
    dispatch(actions.loginSuccess(response));

    const { input: user } = response;

    // Handle auth data persistance
    saveAuthenticationData(user);

    // Handle redirects from login form
    if (user.isPwdUpdated) {
      const redirectRoute =
        !isEmpty(redirect) && redirect.pathname && redirect.search
          ? `${redirect.pathname}${redirect.search}`
          : HOME;
      dispatch(push(redirectRoute.includes(AUTH) ? HOME : redirectRoute));
    } else {
      dispatch(push(REQUIRE_UPDATE_PASSWORD));
    }
  } catch (response) {
    if (response.error?.deactivated) {
      dispatch(
        push(`${AUTH_DEACTIVATED}?${qs.stringify({ email: data.email })}`)
      );
      dispatch(actions.loginFailure());
    } else {
      dispatch(actions.loginFailure(response?.error?.error, response));
    }
  }
};

const requestLogout = () => async (dispatch) => {
  try {
    const userData = Boolean(userStore.get());
    dispatch(actions.logoutRequest());

    await logoutUserReq();
    userStore.removeTokens();

    dispatch(actions.logoutSuccess());
    dispatch(push(userData ? RETURN_LOGIN : LOGIN, { isRedirected: true }));

    store.remove(USER_METADATA);
  } catch (error) {
    dispatch(actions.logoutFailure(error));
  }
};

export const readUser = makeThunk(
  readUserRequest,
  {
    request: actions.readUserRequest,
    success: actions.readUserSuccess,
    failure: actions.readUserFailure,
  },
  (id) => `Failed to request a user by id: ${id}`
);

const readCurrentUser = () => async (dispatch) => {
  try {
    dispatch(actions.readCurrentUserDataRequest());
    const { user } = await readCurrentUserRequest();

    // Initialize GA
    ReactGA.set({
      language: user.settings?.lang,
      userId: user.id,
    });

    // Initialize Local Settings
    settings.initialize(user.id);
    dispatch(actions.readCurrentUserDataSuccess(user));
    i18n.changeLanguage(user.settings?.lang ?? FALLBACK_LANGUAGE);

    return user;
  } catch (error) {
    dispatch(actions.readCurrentUserDataFailure(error));
  }
};

const resetPassword = makeThunk(resetPass, {
  request: actions.resetPassRequest,
  success: actions.resetPassSuccess,
  failure: actions.resetPassFailure,
});

const reactivateAccount = makeThunk(
  reactivateAccountReq,
  {
    request: actions.reactivateAccountRequest,
    success: actions.reactivateAccountSuccess,
    failure: actions.reactivateAccountFailure,
  },
  { toCaptureServerError: false }
);

export {
  requestLogin,
  readCurrentUser,
  resetPassword,
  requestLogout,
  reactivateAccount,
};
