import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import styles from './NavigationItem.module.scss';
import { IconButtonWithBadge, ManualPopover } from 'components';

interface NavigationItemProps {
  /**
   * Page name to go to shown in popup
   */
  name: string;
  /**
   * Icon name to display
   */
  icon: string;
  /**
   * Link to go to
   */
  href: string;
  /**
   * Root component to render
   */
  component?: React.ElementType;
  /**
   * Root component to render
   */
  badge?: React.ReactNode;
  /**
   * Flag indicating if to hide/show badge
   */
  showBadge?: boolean;
  /**
   * HTML classname attribute
   */
  className?: string;
}

/**
 * Renders sidebar navigation tab
 */
export const NavigationItem: FC<NavigationItemProps> = memo(
  function NavigationItem({
    icon,
    href,
    name,
    component: Component = NavLink,
    badge,
    showBadge = false,
    className,
  }) {
    return (
      <Component
        to={href}
        className={cn(styles.root, className)}
        activeClassName={styles.activeLink}
      >
        <IconButtonWithBadge
          badge={badge}
          icon={icon}
          showBadge={showBadge}
          className={styles.icon}
        />
        <ManualPopover className={styles.sidebarPopover}>{name}</ManualPopover>
      </Component>
    );
  }
);
