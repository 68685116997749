import { createSelector } from 'reselect';
import { get } from 'lodash';

import { selectUsers } from 'store/modules/entities/selectors/selectors';
import { createStateGetter, createCollectionSelector } from 'store/utils';
import { selectShortUserData } from 'store/modules/auth';
import { selectCurrentPageOperation } from 'store/modules/entities/selectors/operations';

export const selectOperationParticipants = createSelector(
  selectCurrentPageOperation,
  createStateGetter('participants')
);

export const selectOperationLeadId = createSelector(
  selectCurrentPageOperation,
  (operation) =>
    get(operation, 'participants.leads.0') || get(operation, 'leadId') || null
);

export const selectOperationLead = createSelector(
  [selectOperationLeadId, selectUsers],
  (leadId, users) => get(users, leadId, null)
);

export const selectOperationAssistantId = createSelector(
  selectCurrentPageOperation,
  (operation) =>
    get(operation, 'participants.assistants.0') ||
    get(operation, 'assistantId') ||
    null
);

export const selectOperationAssistant = createSelector(
  [selectOperationAssistantId, selectUsers],
  (assistantId, users) => get(users, assistantId, null)
);

export const selectSignedDoctorsToOperation = createSelector(
  [selectOperationLeadId, selectOperationAssistantId],
  (leadId, assistantId) => [leadId, assistantId].filter(Boolean)
);

export const selectSignedUsersDataPlanOp = createSelector(
  [selectSignedDoctorsToOperation, selectUsers],
  (usersIds, users) =>
    usersIds.map((id) => {
      const user = get(users, id);
      return selectShortUserData(user);
    })
);

export const selectOperationWatchers = createSelector(
  [selectOperationParticipants, selectUsers],
  createCollectionSelector('watchers')
);
