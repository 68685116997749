import React, { FC } from 'react';
import classnames from 'classnames';

import styles from '../Form.module.scss';
import { DeprecatedFlex } from 'components/Grid';

interface FormActionsProps {
  className?: string;
  [key: string]: any;
}

export const FormActions: FC<FormActionsProps> = ({
  className,
  ...restProps
}) => {
  return (
    <DeprecatedFlex
      inline={false}
      className={classnames(className, styles.actions, 'justify-end', {
        [className!]: className,
      })}
      {...restProps}
    />
  );
};
