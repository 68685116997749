import invariant from 'invariant';
import { ENTER, SPACE, ESCAPE } from 'app-constants';
import { KeyboardEvent } from 'react';

export const makeKeyEventHandler = (keyCodes: number[] | number) => {
  invariant(keyCodes, 'keyCodes param is required');

  return (callback: (...any) => any) => {
    invariant(callback, 'callback param is required');

    return (event: KeyboardEvent): void => {
      const matchRequiredKeyCodes =
        (Array.isArray(keyCodes) && keyCodes.includes(event.keyCode)) ||
        event.keyCode === keyCodes;
      if (!matchRequiredKeyCodes) return;

      callback(event);
    };
  };
};

export const onEnter = makeKeyEventHandler(ENTER);
export const onSpace = makeKeyEventHandler(SPACE);
export const onEscape = makeKeyEventHandler(ESCAPE);
