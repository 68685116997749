import { createSelector } from 'reselect';
import {
  selectTimeoutQuestions,
  selectDebriefingQuestions,
} from 'store/modules/entities/selectors/selectors';
import { createCollectionSelector, createStateGetter } from 'store/utils';
import { selectOperationTemplate } from './operation';
import { selectUserId, hasDepartment } from 'store/modules/auth';

const isNotEmpty = question => Boolean(question.content);
const isActiveQuestion = question => Boolean(question && question.isActive);
const selectActiveQuestions = questions =>
  questions && questions.filter(isNotEmpty).filter(isActiveQuestion);

export const selectOperationTimeouts = createSelector(
  [selectOperationTemplate, selectTimeoutQuestions],
  createCollectionSelector('timeoutQuestions')
);

export const selectActiveOperationTimeouts = createSelector(
  selectOperationTimeouts,
  selectActiveQuestions
);

export const selectOperationDebriefings = createSelector(
  [selectOperationTemplate, selectDebriefingQuestions],
  createCollectionSelector('debriefingQuestions')
);

export const selectActiveOperationDebriefings = createSelector(
  selectOperationDebriefings,
  selectActiveQuestions
);

export const selectOperationTemplateAuthorId = createSelector(
  [selectOperationTemplate],
  createStateGetter('createdBy')
);

export const isOwnOperationTemplate = createSelector(
  [selectUserId, selectOperationTemplateAuthorId],
  (userId, templateAuthorId) =>
    Boolean(userId && templateAuthorId && userId === templateAuthorId)
);

export const shouldShowDepartmentQuestionsMessage = createSelector(
  [isOwnOperationTemplate, hasDepartment, selectOperationTimeouts],
  (isOwn, hasDepartment, questions) =>
    Boolean((!questions || !questions.length) && isOwn && !hasDepartment)
);
