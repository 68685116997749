import { processEnv } from 'app-constants/processEnv';
import { constant } from 'case';

// Passed through env variables
export const features = [
  'patientParameters',
  'multilang',
  'dashboard',
  'library',
  'history',
  'assessment',
  'assessmentForms',
  'incidentsReporting',
  'qualification',
  'qualificationForms',
  'research',
  'styleguide',
] as const;

export type Feature = typeof features[number];

/**
 * FEATURE FLAG
 *
 * Disables a feature if the feature flag env variable is absent or is equal to 'false'.
 * The feature is enabled if there is an env variable explicitly named
 * starting with `REACT_APP_FEATURE_` + feature name + `_ENABLED`
 * and is equal to 'true'.
 *
 * Feature can also be enabled by setting feature string in local storage under `ff_featureName`.
 * This will override feature disabled by env variable - it becomes enabled.
 */
export const isFeatureFlagEnabled = (featureName: Feature) => {
  if (getValueFromStorage(featureName) === null) {
    return isEnabledWithEnv(featureName);
  }

  return isEnabledWithStorage(featureName);
};

const isEnabledWithEnv = (featureName: Feature) => {
  const env = processEnv();
  const featureFlagName = `REACT_APP_FEATURE_${constant(featureName)}_ENABLED`;
  const featureFlag = env[featureFlagName];

  return featureFlag === 'true';
};

const getValueFromStorage = (featureName: Feature) =>
  localStorage.getItem(`ff_${featureName}`);

const isEnabledWithStorage = (featureName: Feature) =>
  getValueFromStorage(featureName) === 'true';
