import React, { ReactElement, ReactNode, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OnDragEndResponder } from 'react-beautiful-dnd';

import { ListItemType } from 'common-types/common';
import { PROFILE } from 'scenes/routes.enum';

import { OperationTimeoutQuestionType } from 'common-types/operation';
import { TranslatableString } from 'components/TranslatableString';
import { DataTag } from 'components/DataTag';
import { Text } from 'components/Typography/Text';
import { ToggleableList } from 'components/ToggleableList';
import { ElementsList } from 'components/List/ElementsList';
import { Loader } from 'components/Loader';
import { EditableList } from 'components/EditableList';
import { selectLanguage } from 'store/modules/language';
import { useSelector } from 'react-redux';

interface Props {
  additionalQuestions: OperationTimeoutQuestionType[];
  isLoading?: boolean;
  onCreate: Function;
  onDelete: Function;
  onOrderChange: Function;
  onToggleQuestion: Function;
  readonly?: boolean;
  requestQuestions?: Function | null;
  selectedStandardQuestionsIds?: number[];
  showDepartmentQuestionsMessage?: boolean;
  standardQuestions: OperationTimeoutQuestionType[];
  standardQuestionsTitle?: string;
  templateId?: number;
}

export const TemplateQuestionsForm = ({
  templateId,
  onOrderChange,
  onDelete,
  onCreate,
  onToggleQuestion,
  standardQuestionsTitle,
  additionalQuestions,
  isLoading,
  readonly,
  requestQuestions,
  standardQuestions,
  showDepartmentQuestionsMessage,
  selectedStandardQuestionsIds = [],
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);

  useEffect(() => {
    if (templateId && !isLoading && typeof requestQuestions === 'function') {
      requestQuestions(templateId, language);
    }
  }, []);

  const handleQuestionsOrder: OnDragEndResponder = (event) => {
    const { destination, source } = event;
    if (!destination || !source) return;

    onOrderChange({
      templateId,
      fromIndex: source.index,
      toIndex: destination.index,
    });
  };

  const handleAdditionalQuestionsOrder: OnDragEndResponder = (event) => {
    const { destination, source } = event;
    const addIndex = standardQuestions ? standardQuestions.length : 0;
    if (!destination || !source) return;
    // Template has standard questions added by default at creation.
    // Additional questions that are added by user are going to be at the end of
    // the questions array, so adding a length to the standard questions's index
    // gives ability to exactly match additional questions index despite the
    // fact that they're being rendered from a separate array.

    onOrderChange({
      templateId,
      fromIndex: source.index + addIndex,
      toIndex: destination.index + addIndex,
    });
  };

  const handleQuestionDelete = (id: string | number): void => {
    onDelete({ id, templateId });
  };

  const handleQuestionCreate = (content: string): void => {
    onCreate({ content, templateId });
  };

  /**
   * Toggle value on template question form
   */
  const handleItemToggle = (id: number, checked: boolean): void => {
    onToggleQuestion({ id, templateId, checked });
  };
  /**
   * Callback to render list item.
   */
  const renderItem = (item: ListItemType): ReactNode => (
    <TranslatableString
      string={item.content}
      render={(content) => <DataTag propKey="content">{content}</DataTag>}
    />
  );

  const renderDepartmentQuestions = (): ReactElement | null => {
    const hasQuestions = standardQuestions && standardQuestions.length;

    if (!hasQuestions) {
      if (showDepartmentQuestionsMessage) {
        return (
          <Text className="mb-2 mt-2">
            {t('selectDepartmentFromDefaultQuestions')}
            {'. '}
            <Link to={PROFILE}>{t('goToProfileSettings')}</Link>
          </Text>
        );
      }

      return null;
    }

    return (
      <ToggleableList
        id={`${standardQuestionsTitle}-${templateId}`}
        items={standardQuestions}
        onItemToggle={handleItemToggle}
        renderItem={renderItem}
        selectedItems={selectedStandardQuestionsIds}
        title={standardQuestionsTitle}
        onDragEnd={handleQuestionsOrder}
      />
    );
  };

  if (!standardQuestions && !additionalQuestions) return null;

  return (
    <Suspense fallback={<Loader />}>
      {readonly ? (
        <ElementsList
          items={standardQuestions?.filter((question) => question.isActive)}
          renderItem={renderItem}
          title={standardQuestionsTitle}
        />
      ) : (
        renderDepartmentQuestions()
      )}
      {readonly ? (
        <ElementsList
          items={additionalQuestions}
          renderItem={renderItem}
          title={t('additionalQuestions')}
        />
      ) : (
        <EditableList
          id={`additionalQuestions-${templateId}`}
          items={additionalQuestions}
          onCreate={handleQuestionCreate}
          onDelete={handleQuestionDelete}
          renderItem={renderItem}
          onDragEnd={handleAdditionalQuestionsOrder}
          title={t('additionalQuestions')}
        />
      )}
    </Suspense>
  );
};

TemplateQuestionsForm.displayName = 'TemplateQuestionsForm';
