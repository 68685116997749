import { ProductType } from 'common-types/common';
import { isProductType } from 'utils/get-env';

interface ProductHookResult {
  productType: ProductType;
  productName: string;
  isProductType: (type: ProductType) => boolean;
}

/**
 * Hook to resolve product type based on environment variable.
 * Can be used to conditionally modify / render components based on product type.
 * @returns {Object}
 * - `productType`: stores current product type
 * - `productName`: stores name to be rendered based on product type
 * - `isProductType`: prefer using this in conditions over comparing `productType` directly in components
 */
export const useProduct = (): ProductHookResult => {
  const defaultProductName = 'IMPedia';
  const defaultProductType = 'impedia';

  const productName = isProductType('vet') ? 'IMPediaVET' : defaultProductName;
  const productType = isProductType('vet') ? 'vet' : defaultProductType;

  return {
    productType,
    productName,
    isProductType,
  };
};
