import React, { FC } from 'react';
import classnames from 'classnames';

import styles from '../FormInputBase/FormInputBase.module.scss';

export const Label: FC<{ [key: string]: any }> = ({
  children,
  className,
  ...labelProps
}) => {
  return (
    <label className={classnames(className, styles.label)} {...labelProps}>
      <strong>{children}</strong>
    </label>
  );
};
