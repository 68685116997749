import * as api from 'api/v1';
import * as actions from 'store/modules/operation-planning/actions';
import { makeThunk } from 'store/utils';
import i18n from 'translations/i18n-instance';

export const createVitalInfo = makeThunk(
  api.createVitalInfoReq,
  {
    request: actions.createVitalInfoRequest,
    success: actions.createVitalInfoSuccess,
    failure: actions.createVitalInfoFailure,
  },
  () => i18n.t('errors:failedToCreateVitalInfo')
);

export const updateVitalInfo = makeThunk(
  api.updateVitalInfoReq,
  {
    request: actions.updateVitalInfoRequest,
    success: actions.updateVitalInfoSuccess,
    failure: actions.updateVitalInfoFailure,
  },
  (id) => `${i18n.t('errors:failedToUpdateVitalInfo')} (id: ${id})`
);

export const readVitalInfo = makeThunk(
  api.readVitalInfoReq,
  {
    request: actions.readVitalInfoRequest,
    success: actions.readVitalInfoSuccess,
    failure: actions.readVitalInfoFailure,
  },
  (id) => `${i18n.t('errors:failedToReadVitalInfo')} (id: ${id})`,
  { toCatchServerError: true }
);
