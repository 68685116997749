import cn from 'classnames';
import { Spinner } from 'components/Loader/Spinner';
import React, { forwardRef, memo } from 'react';
import styles from './Button.module.scss';

export type ButtonVariant =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'secondary-danger'
  | 'text'
  | 'text-danger';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  /**
   * Root html element
   */
  component?: React.ElementType;
  /**
   * Element displayed on the left side of children
   */
  before?: React.ReactNode;
  /**
   * Element displayed on the right side of children
   */
  after?: React.ReactNode;
  /**
   * Make button to take all awailable width
   */
  fullWidth?: boolean;
  /**
   * Disable component
   */
  disabled?: boolean;
  /**
   * Show loader
   */
  loading?: boolean;
  /**
   * Predefined button size styles
   */
  size?: ButtonSize;
  /**
   * Predefined button variant styles
   */
  variant?: ButtonVariant;
  /**
   * A string representation of the Link location
   */
  href?: string;
  /**
   * The relationship between a linked resource and the current document.
   */
  rel?: string;
  /**
   * The relationship between a linked resource and the current document.
   */
  to?: string;
  /**
   * Display text in all caps.
   */
  capitalized?: boolean;
}

/**
 * Renders button component
 */
export const Button = memo(
  forwardRef<HTMLElement, ButtonProps>(function Button(
    {
      component: Component = 'button',
      children,
      before,
      after,
      className,
      fullWidth = false,
      disabled = false,
      loading = false,
      size = 'md',
      capitalized = true,
      variant = 'primary',
      type = Component === 'button' ? 'button' : undefined,
      ...props
    },
    ref
  ) {
    const rootClassName = cn(
      styles.button,
      styles[size],
      styles[variant],
      capitalized && styles.capitalized,
      fullWidth && styles.fullWidth,
      loading && styles.loading,
      disabled && styles.disabled,
      className
    );

    return (
      <Component
        ref={ref}
        disabled={disabled || loading}
        className={rootClassName}
        type={type}
        {...props}
      >
        {/* Loader positioned absolutelly over all children elements */}
        {loading && (
          <span className={styles.loader}>
            <Spinner className={styles.spinner} />
          </span>
        )}

        {before && <span className={styles.before}>{before}</span>}
        {children && <span className={styles.children}>{children}</span>}
        {after && <span className={styles.after}>{after}</span>}
      </Component>
    );
  })
);
