import { capital } from 'case';
import { compose } from 'redux';

import * as api from 'api/v1';

import { hideModalDialog } from 'store/modules/modal-dialogs';
import {
  createNewLibraryItemRequest,
  createNewLibraryItemSuccess,
  createNewLibraryItemFailure,
} from './actions';
import { translateObject } from 'utils';
import { setLibraryElementUUID } from 'store/utils';
import i18n from 'translations/i18n-instance';

export const addLibraryItemFromSearch =
  (category, item, onItemAdded) => async (dispatch) => {
    dispatch(createNewLibraryItemRequest(category, item));
    const methodName = `create${capital(category, '')}`;
    const createRequest = api[methodName];

    if (typeof createRequest !== 'function') {
      return dispatch(
        createNewLibraryItemFailure(
          category,
          i18n.t('failedToCreateNewLibraryItem'),
          new Error(`Can't find API request function for category: ${category}`)
        )
      );
    }

    try {
      const createdItem = await createRequest(item);
      const newLibraryItem = compose(
        translateObject,
        setLibraryElementUUID
      )({ ...createdItem, category });
      dispatch(createNewLibraryItemSuccess(category, newLibraryItem));
      dispatch(hideModalDialog());
      if (typeof onItemAdded === 'function') {
        onItemAdded(newLibraryItem);
      }

      return newLibraryItem;
    } catch (error) {
      dispatch(
        createNewLibraryItemFailure(
          category,
          i18n.t('failedToCreateNewLibraryItem'),
          error
        )
      );
    }
  };
