// Interceptors meant to transform config instead of rebuilding it
// for each and every change since its too complex.
/* eslint-disable no-param-reassign */
import { user } from 'local-store';
import { getCurrentLanguage } from 'translations/i18n-instance';
import cleanRequestBody from './clean-request-body';
import { shouldIgnoreRequestBodyClean } from 'api/instance/should-ignore-request-body-clean';

export default () => (config) => {
  config.headers.Authorization = user.authHeader();
  config.headers['Accept-Language'] = getCurrentLanguage();

  const isUpdateRequest = ['post', 'put', 'patch'].includes(config.method);

  if (isUpdateRequest) {
    const ignoreBodyClean = shouldIgnoreRequestBodyClean(config);
    if (!ignoreBodyClean) {
      const { data, method } = config;
      config.data = data ? cleanRequestBody[method](data) : data;
    }
  }

  return config;
};
