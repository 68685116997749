import { ATTACHMENTS } from 'app-constants/analytics';
import { Attachment as AttachmentT } from 'common-types/attachments';
import { AttachmentsList } from './../AttachmentsList/AttachmentsList';
import { Gallery } from 'components/Gallery';
import { Loader } from 'components/Loader';
import React, { ComponentType, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { AttachmentUpdateFormModal } from '../AttachmentUpdateForm/AttachmentUpdateFormModal';

interface Props {
  attachments: AttachmentT[];
  isLoading?: boolean;
  readonly?: boolean;
}

export const AttachmentsForm: ComponentType<Props> = ({
  attachments,
  isLoading = false,
  readonly = true,
}) => {
  const [showGalleryFor, setShowGalleryFor] = useState<null | number>(null);
  const galleryVisible: boolean = typeof showGalleryFor === 'number';
  const [attachmentToUpdate, setAttachmentToUpdate] = useState<number | null>(
    null
  );
  const { t } = useTranslation();

  const isEmpty: boolean = !isLoading && !attachments?.length;

  const showGallery = (id) => {
    if (showGalleryFor) return;

    ReactGA.event({
      category: ATTACHMENTS,
      action: 'Show Gallery',
      value: id,
    });

    setShowGalleryFor(id);
  };

  const closeGallery = () => setShowGalleryFor(null);

  const disableEditMode = () => {
    setShowGalleryFor(attachmentToUpdate);
    setAttachmentToUpdate(null);
  };

  const handleFileUpdateRequest = (id: number) => {
    setShowGalleryFor(null);
    setAttachmentToUpdate(id);
  };

  return (
    <>
      <div tabIndex={1}>
        <Gallery
          isOpen={galleryVisible}
          withImageDots
          openFileId={showGalleryFor ? (showGalleryFor as number) : undefined}
          filesList={attachments}
          onClose={closeGallery}
          onFileUpdate={readonly ? undefined : handleFileUpdateRequest}
        />

        {isEmpty ? (
          <>
            <p>{t('noAttachmentsUploaded')}</p>
          </>
        ) : (
          <>
            <div>
              {isLoading && <Loader />}
              <AttachmentsList
                readonly={readonly}
                attachments={attachments}
                onGalleryRequest={showGallery}
              />
            </div>
          </>
        )}
      </div>

      <AttachmentUpdateFormModal
        opened={!!attachmentToUpdate}
        attachmentId={attachmentToUpdate}
        onRequestClose={disableEditMode}
      />
    </>
  );
};
