import React, { ComponentType, HTMLAttributes } from 'react';
import cn from 'classnames';
import classes from './CornerBadge.module.scss';

export const CornerBadge: ComponentType<HTMLAttributes<HTMLSpanElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <span className={cn(classes.cornerBadge, className)} {...props}>
      {children}
    </span>
  );
};

CornerBadge.displayName = 'CornerBadge';
