import cn from 'classnames';
import React, { AllHTMLAttributes, ElementType, FC, memo } from 'react';

import styles from './Text.module.scss';
import {
  TextVariant,
  TextColor,
  TextWeight,
  TextTransform,
  variantToComponent,
  variantToWeight,
  variantToColor,
  variantToTransform,
  TextAlign,
} from './utils';

export interface TextProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Root html element
   */
  component?: ElementType;
  /**
   * Text size variant
   */
  variant?: TextVariant;
  /**
   * Text color variant
   */
  color?: TextColor;
  /**
   * Text weight variant
   */
  weight?: TextWeight;
  /**
   * Text transform style
   */
  transform?: TextTransform;
  /**
   * Text align style
   */
  align?: TextAlign;
  /**
   * Flag to make text ellipsis
   */
  ellipsis?: boolean;
  /**
   * Display inline-blok
   */
  inline?: boolean;
}

/**
 * Renders text variations
 */
export const Text: FC<TextProps> = memo(function Text({
  variant = 'paragraph-md',
  color = variantToColor[variant] || 'default',
  weight = variantToWeight[variant] || 'regular',
  transform = variantToTransform[variant] || 'unset',
  component: Component = variantToComponent[variant] || 'p',
  align,
  ellipsis,
  inline,
  children,
  className,
  ...props
}) {
  return (
    <Component
      className={cn(
        styles.text,
        styles[`variant-${variant}`],
        styles[`weight-${weight}`],
        styles[`color-${color}`],
        styles[`transform-${transform}`],
        align && styles[`align-${align}`],
        ellipsis && styles.ellipsis,
        inline && styles.inline,
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
});
