import React, { useMemo, ComponentType } from 'react';
import { Category } from 'common-types/templates';
import { CategoryItem } from './CategoryItem';
import { MenuItem, TranslatableString } from 'components';

const isSameLevelCategory = (categoryId, categoryParentId) => (category) =>
  categoryParentId === category.parentId && categoryId !== category.id;

interface Props {
  categories: Category[];
  category: Category;
  hideCount?: boolean;
  hideEmpty?: boolean;
  onCategorySelect: (Category) => void;
}

export const Subcategory: ComponentType<Props> = ({
  category,
  categories,
  onCategorySelect,
  hideCount,
  hideEmpty,
  ...restProps
}) => {
  const childrenCategories = useMemo(
    () =>
      categories?.filter(
        isSameLevelCategory(category?.id, category?.parentId)
      ) ?? [],
    [categories, category]
  );
  if (!category) return null;

  return (
    <MenuItem
      id={category.id.toString()}
      key={category.id}
      label={<TranslatableString string={category.name} />}
      numberOfSubItems={hideCount ? 0 : category.total}
      onClick={() => onCategorySelect(category)}
    >
      {childrenCategories.map((category) => (
        <CategoryItem
          key={category.id}
          category={category}
          onClick={onCategorySelect}
          {...restProps}
        />
      ))}
    </MenuItem>
  );
};
