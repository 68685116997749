import React, { FC, memo, isValidElement } from 'react';
import isObject from 'lodash/isObject';

import { MessageBlock } from 'components/Message';

interface ErrorWithMessageProp {
  message: string;
  error?: never;
}

interface ErrorWithErrorProp {
  message?: never;
  error: string;
}

type ErrorType = string | ErrorWithMessageProp | ErrorWithErrorProp;

interface ErrorMessagesProps {
  /**
   * Errors to show
   */
  errors?: ErrorType | ErrorType[];
}

/**
 * Display all provided error messages
 */
export const ErrorMessages: FC<ErrorMessagesProps> = memo(
  function ErrorMessages({ errors }) {
    if (Array.isArray(errors)) {
      return (
        <>
          {errors.map((error, key) => (
            <ErrorMessages key={key} errors={error} />
          ))}
        </>
      );
    } else if (typeof errors === 'string' || isValidElement(errors)) {
      return <MessageBlock type="error">{errors}</MessageBlock>;
    } else if (isObject(errors)) {
      return (
        <MessageBlock type="error">
          {errors.message || errors.error}
        </MessageBlock>
      );
    }

    return null;
  }
);
