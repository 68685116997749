import React, { FC, memo, HTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './Dot.module.scss';
import { LibraryCategories } from '../../common-types/library';

export interface DotProps extends HTMLAttributes<HTMLSpanElement> {
  /**
   * Predefined dot variant styles
   */
  variant?: LibraryCategories;
}

/**
 * Renders "Dot" component in different colors
 */
export const Dot: FC<DotProps> = memo(function Dot({
  variant = LibraryCategories.procedures,
  className,
  ...props
}) {
  return (
    <span className={cn(styles.dot, styles[variant], className)} {...props} />
  );
});
