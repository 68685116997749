import cn from 'classnames';
import { Text } from 'components/Typography/Text';
import React, { ComponentType, ReactElement } from 'react';
import classes from '../LibraryItemsSearchInput.module.scss';
import { MenuItem } from '../MenuItem/MenuItem';

interface Props {
  append?: ReactElement;
  label: string;
  onClick?: () => any;
  subLabel?: string;
}

export const AdditionalItem: ComponentType<Props> = ({
  label,
  subLabel,
  append,
  onClick,
}) => {
  return (
    <MenuItem
      append={append}
      className={cn('MenuItem MenuItem--not-found', classes.itemNotFound)}
      label={<Text variant="caption">{label}</Text>}
      onClick={onClick}
      sublabel={
        Boolean(subLabel) && (
          <Text component="span" variant="paragraph-sm">
            {subLabel}
          </Text>
        )
      }
    />
  );
};

AdditionalItem.displayName = 'AdditionalItem';
