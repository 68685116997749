import {
  createSuccessActionCreator_DEPRECATED,
  createFailureActionCreator,
  createSuccessActionCreator,
} from 'store/utils/create-action-creators';
import { userSchema } from 'api/v1/schemas';
import * as types from './types';

// @TODO when notification would be done remove it
// https://nodusmedical.atlassian.net/browse/NM-603
export const cleanMessage = () => ({
  type: types.CLEAN_NOTIFY_MESSAGE,
});

// login
export const loginRequest = (id) => ({
  type: types.LOGIN_REQUEST,
  payload: { id },
});

export const loginSuccess = createSuccessActionCreator(types.LOGIN_SUCCESS);

export const loginFailure = createFailureActionCreator(types.LOGIN_FAILURE);

// reset password
export const resetPassRequest = ({ email }) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload: { email },
});

export const resetPassSuccess = (data) => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload: { data },
});

export const resetPassFailure = (error) => ({
  type: types.FORGOT_PASSWORD_FAILURE,
  payload: { error },
});

// user data
/* Logout */
export const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: types.LOGOUT_FAILURE,
  payload: { error },
});

/* Fetch user data */
export const readCurrentUserDataRequest = () => ({
  type: types.READ_CURRENT_USER_DATA_REQUEST,
});

export const readCurrentUserDataSuccess = createSuccessActionCreator_DEPRECATED(
  types.READ_CURRENT_USER_DATA_SUCCESS,
  'user',
  userSchema
);

export const readCurrentUserDataFailure = createFailureActionCreator(
  types.READ_CURRENT_USER_DATA_FAILURE
);

export const clearError = () => ({
  type: types.LOGIN_CLEAR_ERROR,
});

export const readUserRequest = (id) => ({
  type: types.READ_USER_REQUEST,
  payload: { id },
});

export const readUserSuccess = createSuccessActionCreator(
  types.READ_USER_SUCCESS
);

export const readUserFailure = createFailureActionCreator(
  types.READ_USER_FAILURE
);

// re-activate user account
export const reactivateAccountRequest = ({ email }) => ({
  type: types.REACTIVATE_ACCOUNT_REQUEST,
  payload: { email },
});

export const reactivateAccountSuccess = () => ({
  type: types.REACTIVATE_ACCOUNT_SUCCESS,
});

export const reactivateAccountFailure = (error) => ({
  type: types.REACTIVATE_ACCOUNT_FAILURE,
  payload: { error },
});
