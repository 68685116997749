import { ErrorResponse } from 'common-types/api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { clearError, requestLogin } from 'store/modules/auth';

export interface AuthStoreState {
  error?: ErrorResponse | null;
  isLoading?: boolean;
  [key: string]: any;
}

export interface AuthFormLoginState {
  email: string;
  password: string;
}

export interface AuthFormReloginState {
  password: string;
}

export const initialLoginFormState: AuthFormLoginState = {
  email: '',
  password: '',
};

export const initialReloginFormState: AuthFormReloginState = {
  password: '',
};

/**
 * Hook to handle login/relogin shared functionality
 */
export const useLoginForm = () => {
  // TODO - fix store type
  const auth = useSelector<Record<string, any>, AuthStoreState>(
    (state) => state.auth
  );

  const location = useLocation();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data: AuthFormLoginState) => {
      dispatch(requestLogin(data, location.state));
    },
    [dispatch, location.state]
  );

  const onResetErrors = useCallback(() => {
    if (auth.error) dispatch(clearError());
  }, [auth.error, dispatch]);

  return {
    auth,
    location,
    dispatch,
    onSubmit,
    onResetErrors,
  };
};
