import { id, uuid } from 'common-types/api';
import {
  Assessment,
  AssessmentInput,
  AssessmentSignatureInput,
  AssessmentSubmissionInput,
  AssessmentSubmissionInputLegacy,
  QualitativeFeedbackItem,
} from 'common-types/assessments';
import { createNormalizr } from '../../store/utils';
import { APIv1 } from '../instance';
import {
  legacyAssessmentSchema,
  legacyAssessmentListSchema,
  assessmentSchema,
  assessmentListSchema,
} from './schemas';
import { isFeatureFlagEnabled } from 'features/flags';
import { withoutId } from '../../utils/frontendId';

const normalizeAssessment = isFeatureFlagEnabled('assessmentForms')
  ? createNormalizr(assessmentSchema)
  : createNormalizr(legacyAssessmentSchema);

const normalizeAssessmentsList = isFeatureFlagEnabled('assessmentForms')
  ? createNormalizr(assessmentListSchema)
  : createNormalizr(legacyAssessmentListSchema);

export const readAssessments = (config?) =>
  APIv1.get('/assessments', config).then(normalizeAssessmentsList);

export const createAssessment = async (assessmentBody: AssessmentInput) => {
  const newAssessment = {
    ...withoutId(assessmentBody),
    operations: assessmentBody.operations?.map(withoutId),
    traineeId: `${assessmentBody.traineeId}`,
    trainerId: `${assessmentBody.trainerId}`,
    sendEmail: assessmentBody.sendEmail,
  };
  const response = await APIv1.post('/assessments', newAssessment);
  return normalizeAssessment(response);
};

export const readAssessment = (assessmentId: id | uuid) =>
  APIv1.get(`/assessments/${assessmentId}`).then(normalizeAssessment);

export const updateAssessment = (
  assessmentId: id,
  updateBody: Partial<AssessmentInput>,
  config?
) =>
  APIv1.patch(`/assessments/${assessmentId}`, updateBody, config).then(
    normalizeAssessmentsList
  );

export const deleteAssessment = (assessmentId: uuid) =>
  APIv1.delete(`/assessments/${assessmentId}`);

export const submitAssessmentLegacy = (
  assessmentId: id,
  assessmentInput: AssessmentSubmissionInputLegacy,
  config?
) =>
  APIv1.post(
    `/assessments/${assessmentId}/submit`,
    assessmentInput,
    config
  ).then(normalizeAssessment);

export const submitAssessment = (
  assessmentId: uuid,
  assessment: AssessmentSubmissionInput
) =>
  APIv1.post(`/assessments/${assessmentId}/submit`, assessment).then(
    normalizeAssessment
  );

export const createPdf = (assessment: Assessment, institutes) => {
  return APIv1.post(
    `/assessments/${assessment.id}/createPdf`,
    { assessment, institutes },
    { responseType: 'blob' }
  );
};

export const verifyAssessment = (assessmentId, config?) =>
  APIv1.post(`/assessments/${assessmentId}/verify`, undefined, config).then(
    normalizeAssessment
  );

export const signAssessment = async (
  assessmentId: uuid,
  signatures: AssessmentSignatureInput[]
) => {
  const result = await APIv1.post(`/assessments/${assessmentId}/sign`, {
    signatures,
  });
  return normalizeAssessment(result);
};

export const setAssessmentExplanations = (
  assessmentId: uuid,
  subCompetenceId: uuid,
  explanations: QualitativeFeedbackItem[]
): any =>
  APIv1.post(
    `/assessments/${assessmentId}/sub-competences/${subCompetenceId}/explanations`,
    {
      explanations: explanations
        .map((explanation) => explanation.description)
        .filter(Boolean),
    }
  ).then(normalizeAssessment);
