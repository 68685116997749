import { Operation, Patient as PatientI } from 'common-types/operation';
import {
  DataTag,
  FormBody,
  FormTextBox,
  Label,
  Col,
  Row,
  Multiline,
  Text,
} from 'components';
import { FeatureFlagCheck } from 'features/FeatureFlagCheck';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { OswestryScores } from 'scenes/OperationPlanning/forms/Briefing/DiagnosisForm/OswestryScores';
import { createLoadingSelector } from 'store/modules/api-requests';
import {
  selectCurrentPageOperationDenormalized,
  selectOperationPatient,
} from 'store/modules/entities/selectors/operations';
import { READ_OPERATIONS_HISTORY_REQUEST } from 'store/modules/history';
import {
  READ_OPERATION_REQUEST,
  READ_PATIENT_REQUEST,
} from 'store/modules/operation-planning';
import { formatDate, formatUserName } from 'utils';
import { DiagnosesList } from './DiagnosesList';
import { getDefaultPatientBirthDate } from 'utils/patientBirthDate';

interface Props extends RouteComponentProps {
  operation: Operation;
  patient: PatientI;
  isLoading: boolean;
}

const PatientComponent = ({
  operation,
  patient,
  isLoading,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const placeholder = isLoading ? '...' : t('N/A');

  if (!operation) return null;

  const {
    patientId,
    givenName,
    surname,
    birthDate,
    birthdate,
    sex,
    height,
    weight,
    address,
  } = patient || {};

  const hasODI = Boolean(
    operation.patientOswestryScores &&
      (operation.patientOswestryScores['PRE-OP']?.length ||
        operation.patientOswestryScores['POST-OP']?.length)
  );

  return (
    <FormBody>
      <div className="mb-2">
        <Row>
          <Col width="6">
            <FormTextBox label={t('patientID')}>
              <DataTag propKey="patientId">{patientId ?? placeholder}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row data-patient-name={formatUserName(patient) ?? placeholder}>
          <Col width="6">
            <FormTextBox label={t('givenName')}>
              <DataTag propKey="givenName">{givenName ?? placeholder}</DataTag>
            </FormTextBox>
          </Col>
          <Col width="6">
            <FormTextBox label={t('surname')}>
              <DataTag propKey="surname">{surname ?? placeholder}</DataTag>
            </FormTextBox>
          </Col>
        </Row>
        <Row>
          <Col width="9">
            <Row>
              <Col width="6">
                <FormTextBox label={t('dateOfBirth')}>
                  <DataTag propKey="birth-date">
                    {formatDate(
                      birthdate || getDefaultPatientBirthDate(birthDate)
                    ) ?? placeholder}
                  </DataTag>
                </FormTextBox>
              </Col>
              <Col width="3">
                <FormTextBox label={t('Sex')} style={{ textAlign: 'center' }}>
                  <DataTag propKey="sex">{sex ?? placeholder}</DataTag>
                </FormTextBox>
              </Col>
            </Row>
          </Col>
        </Row>
        <FeatureFlagCheck name="patientParameters">
          <Row>
            <Col auto>
              <FormTextBox label={t('Height')}>
                <DataTag propKey="height">
                  {height ? `${height} ${t('cm')}` : placeholder}
                </DataTag>
              </FormTextBox>
            </Col>
            <Col auto>
              <FormTextBox label={t('Weight')}>
                <DataTag propKey="weight">
                  {weight ? `${weight} ${t('kg')}` : placeholder}
                </DataTag>
              </FormTextBox>
            </Col>
          </Row>
        </FeatureFlagCheck>
      </div>

      <div className="mb-2">
        <Text variant="title">{t('Diagnosis')}</Text>
        <DiagnosesList diagnosesIds={operation.diagnosesIds} />
        <FormTextBox
          mode="textarea"
          className="mt-1"
          label={t('diagnosisNotes')}
        >
          {operation.notes && (
            <DataTag propKey="notes">
              <Multiline>{operation.notes.content}</Multiline>
            </DataTag>
          )}
        </FormTextBox>
      </div>

      {hasODI && (
        <div className="mb-2">
          <Text variant="title">{t('oswestryDisabilityIndex')}</Text>
          {operation.patientOswestryScores &&
            !!operation.patientOswestryScores['PRE-OP']?.length && (
              <>
                <Label>{t('PRE-OP')}</Label>
                {
                  <OswestryScores
                    values={operation.patientOswestryScores['PRE-OP']}
                  />
                }
              </>
            )}
          {operation.patientOswestryScores &&
            !!operation.patientOswestryScores['POST-OP']?.length && (
              <>
                <Label>{t('POST-OP')}</Label>
                {
                  <OswestryScores
                    values={operation.patientOswestryScores['POST-OP']}
                  />
                }
              </>
            )}
        </div>
      )}

      {address && (
        <>
          <Text variant="title">{t('patientsAddress')}</Text>
          <FormTextBox label={t('streetNameAndNumber')}>
            <DataTag propKey="street">{address.address ?? placeholder}</DataTag>
          </FormTextBox>
          <Row>
            <Col width="4">
              <FormTextBox label={t('zipCode')}>
                <DataTag propKey="zip-code">
                  {address.zipCode ?? placeholder}
                </DataTag>
              </FormTextBox>
            </Col>
            <Col width="8">
              <FormTextBox label={t('City')}>
                <DataTag propKey="city">{address.city ?? placeholder}</DataTag>
              </FormTextBox>
            </Col>
          </Row>
          <Row>
            <Col width="6">
              <FormTextBox label={t('Country')}>
                <DataTag propKey="country">
                  {address.country ?? placeholder}
                </DataTag>
              </FormTextBox>
            </Col>
          </Row>
        </>
      )}
    </FormBody>
  );
};

const loadingSelector = createLoadingSelector([
  READ_OPERATION_REQUEST,
  READ_OPERATIONS_HISTORY_REQUEST,
  READ_PATIENT_REQUEST,
]);
const mapStateToProps = createStructuredSelector<Props, {}>({
  operation: selectCurrentPageOperationDenormalized,
  patient: selectOperationPatient,
  isLoading: loadingSelector,
});

const withConnect = connect(mapStateToProps);

const enhancer = compose(withConnect);

export const Patient = enhancer(PatientComponent);
