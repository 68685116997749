import React, { ComponentType, useCallback, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ConfirmModal, DropList, DropListItem } from 'components';
import {
  createTemplateByIdSelector,
  deleteTemplate,
} from '../../../store/modules/templates-library';
import { withParams } from '../../../utils';
import CopyTemplateModal from '../../OperationPlanning/forms/CopyTemplateModal';
import { TEMPLATE, TEMPLATES_LIBRARY } from '../../routes.enum';

interface Props {
  readonly?: boolean;
}

export const TemplateTitleMenu: ComponentType<Props> = ({
  readonly = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { templateId } = useParams<{ templateId?: string }>();
  const template = useSelector(createTemplateByIdSelector(templateId));

  const [confirm, setConfirm] = useState<boolean>(false);
  const [showTemplateCopyModal, setShowTemplateCopyModal] = useState(false);

  const handleDelete = useCallback(async () => {
    ReactGA.event({
      category: 'Title Menu',
      action: 'Delete template',
    });
    try {
      await dispatch(deleteTemplate(templateId));
      history.replace(TEMPLATES_LIBRARY);
    } catch (error) {}
  }, [templateId, dispatch, history]);

  const closeConfirm = () => setConfirm(false);
  const openConfirm = () => {
    setConfirm(true);
  };
  const openTemplateCopyModal = () => {
    ReactGA.event({
      category: 'Title Menu',
      action: 'Copy Template',
    });
    setShowTemplateCopyModal(true);
  };
  const closeTemplateCopyModal = ({ newTemplate }) => {
    if (newTemplate) {
      history.push(withParams(TEMPLATE, { templateId: newTemplate.id }));
    }

    setShowTemplateCopyModal(false);
  };

  return (
    <>
      <ConfirmModal
        title={t('deleteTemplate')}
        confirmButtonLabel={t('Delete')}
        declineButtonLabel={t('cancel')}
        content={t('deleteTemplate')}
        onDecline={closeConfirm}
        onConfirm={handleDelete}
        confirmButtonVariant="secondary-danger"
        isOpen={confirm}
      />

      <CopyTemplateModal
        id={templateId}
        isOpen={showTemplateCopyModal}
        onClose={closeTemplateCopyModal}
      />

      <DropList label={t('Options')} size="md">
        {!template?.isPrivate && (
          <DropListItem icon="copy" onClick={openTemplateCopyModal}>
            {t('copyTemplate')}
          </DropListItem>
        )}
        {!readonly && (
          <DropListItem
            icon="delete"
            onClick={openConfirm}
            className="text-error"
          >
            {t('deleteTemplate')}
          </DropListItem>
        )}
      </DropList>
    </>
  );
};

TemplateTitleMenu.displayName = 'TemplateTitleMenu';
