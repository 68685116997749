import { lower } from 'case';
import { RequestId, uuid } from 'common-types/api';
import { Attachment } from 'common-types/attachments';
import {
  Equipment,
  Implant,
  Instrument,
  MaterialElementInput,
} from 'common-types/library';
import { MaterialsGroupInput } from 'common-types/materials';
import { ConfirmDialog } from 'components/Confirm';
import { InputString } from 'components/Form/InputString';
import Bugsnag from '@bugsnag/js';
import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import React, { ComponentType, useContext, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { DateTimeISO8601 } from '../../../../common-types/common';
import { MaterialsFormContext } from '../../context';
import { MaterialElement } from '../../MaterialElement/MaterialElement';
import { MaterialsGroupHeader } from './MaterialsGroupHeader/MaterialsGroupHeader';
import { Gallery } from 'components/Gallery';
import { setDataAttributes } from 'components/DataTag/utils';
import { IconButton, IconButtonWithBadge, Button } from 'components/Button';
import { List, ListHeader, ListItem } from 'components/List';
import { NothingAddedPlaceholder } from 'components/NothingAddedPlaceholder';

export interface MaterialsGroupProps {
  active?: boolean;
  attachments?: Attachment[];
  description?: string;
  id: number | string;
  materials: Array<Instrument | Equipment | Implant>;
  name: string;
  mode?: 'create' | 'show';
  updatedAt?: DateTimeISO8601;
  onRemove?: (groupId: RequestId) => any;
  onUpdate?: (
    groupId: RequestId,
    groupUpdate: Partial<MaterialsGroupInput>
  ) => any;
  onRemoveMaterial?: (groupId: RequestId, uuid: uuid) => any;
  onUpdateMaterial?: (
    groupId: RequestId,
    uuid: uuid,
    update: Partial<MaterialElementInput>
  ) => any;
  onReorderMaterials?: (
    groupId: RequestId,
    fromIndex: number,
    toIndex: number
  ) => void;
  onAttachmentRequest?: (groupId: RequestId) => any;
  onSubmit?: (
    materialsGroup: Pick<MaterialsGroupInput, 'description' | 'name'>
  ) => Promise<any>;
  onSubmitCancel?: () => void;
}

export const MaterialsGroup: ComponentType<MaterialsGroupProps> = ({
  active = false,
  attachments = [],
  description,
  id: groupId,
  materials,
  mode = 'show',
  name,
  onAttachmentRequest,
  onRemove,
  onRemoveMaterial,
  onSubmit,
  onSubmitCancel,
  onUpdate,
  onUpdateMaterial,
  onReorderMaterials,
}) => {
  const { t } = useTranslation();
  const { readonly } = useContext(MaterialsFormContext);
  const dataTags = name
    ? setDataAttributes({
        'materials-list': null,
        [`${lower(name)}-list`]: null,
      })
    : {};

  const [showGallery, setShowGallery] = useState(false);
  const handleGalleryOpen = () => {
    setShowGallery(true);
  };
  const handleGalleryClose = () => {
    setShowGallery(false);
  };

  const handleGroupRemove = () => {
    if (!groupId) {
      const error = new Error(
        'Cannot remove group because no `id` prop passed'
      );
      Bugsnag.notify(error);
      return;
    }
    if (!onRemove) return;
    onRemove(groupId);
  };

  const requestAttachments = () => {
    if (readonly) {
      handleGalleryOpen();
    } else {
      onAttachmentRequest && onAttachmentRequest(groupId);
    }
  };

  const handleRemoveMaterial = (uuid) => {
    invariant(
      onRemoveMaterial,
      'onRemoveMaterial is not defined, but remove requested'
    );
    onRemoveMaterial(groupId, uuid);
  };

  const handleGroupUpdate = (update) => {
    if (!onUpdate) return;
    onUpdate(groupId, update);
  };

  const handleUpdateMaterial = (uuid, update) => {
    invariant(
      onUpdateMaterial,
      'onUpdateMaterial is not defined, but update requested'
    );
    onUpdateMaterial(groupId, uuid, update);
  };

  const handleReorderMaterials = ({ source, destination }: DropResult) => {
    invariant(
      onReorderMaterials,
      'onReorderMaterials is not defined, but reorder requested'
    );
    if (!destination) return;
    onReorderMaterials(groupId, source.index, destination.index);
  };

  const canUpdate = Boolean(!readonly && onUpdate);
  const showAttachmentsButton: boolean = Boolean(
    onAttachmentRequest && (canUpdate || attachments.length)
  );
  const canRemove = Boolean(!readonly && onRemove);

  const headerAddon = mode === 'show' && (
    <span className="nowrap">
      {showAttachmentsButton && (
        <IconButtonWithBadge
          showBadge={!!attachments?.length}
          className="mr-05"
          icon="attachment"
          onClick={requestAttachments}
        />
      )}
      {canRemove && (
        <ConfirmDialog
          title={t('groupRemoval')}
          description={t('areYouSureToDeleteName', { name })}
          confirmButtonLabel={t('Remove')}
          declineButtonLabel={t('cancel')}
          onConfirm={handleGroupRemove}
        >
          <IconButton icon="delete" />
        </ConfirmDialog>
      )}
    </span>
  );

  const [groupInput, setGroupInput] = useState<
    Pick<MaterialsGroupInput, 'description' | 'name'>
  >({ name: '', description: '' });
  const [errors, setErrors] = useState<{
    name?: string;
    description?: string;
  } | null>(null);

  const handleGroupSubmit = async (event) => {
    event.preventDefault();
    if (!onSubmit) return;
    const { name, description } = groupInput;
    if (!name) {
      setErrors({ name: t('nameIsRequired') });
      return;
    }

    await onSubmit({ name, description });
    setGroupInput({ name: '', description: '' });
  };

  const handleGroupInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (errors?.[name]) {
      setErrors((errorsState) => ({ ...errorsState, [name]: null }));
    }
    setErrors(null);
    setGroupInput((state) => ({ ...state, [name]: value }));
  };

  const tableInner = (
    <List dropShadow={active} {...dataTags}>
      <ListHeader addonVisible addon={headerAddon} className="align-start">
        {mode === 'show' && (
          <MaterialsGroupHeader
            name={name}
            description={description}
            onChange={canUpdate ? handleGroupUpdate : undefined}
          />
        )}
        {mode === 'create' && (
          <>
            <strong className="block mb-05">
              <InputString
                autoFocus
                defaultInputSize={(t('enterTitle')?.length ?? 10) + 2}
                name="name"
                error={errors?.name}
                onChange={handleGroupInputChange}
                placeholder={t('enterTitle')}
              />
            </strong>

            <InputString
              multiline
              name="description"
              error={errors?.description}
              onChange={handleGroupInputChange}
              placeholder={t('enterDescription')}
            />
          </>
        )}
      </ListHeader>
      {!isEmpty(materials) &&
        (!readonly ? (
          <DragDropContext onDragEnd={handleReorderMaterials}>
            <Droppable droppableId={String(groupId)}>
              {({ innerRef, droppableProps, placeholder }) => (
                <div ref={innerRef} {...droppableProps}>
                  {materials.map((item, index) => {
                    invariant(
                      item.uuid,
                      'Cannot render materials group element'
                    );
                    const isLast = index === materials.length - 1;
                    return (
                      <Draggable
                        key={item.uuid}
                        draggableId={String(item.uuid)}
                        index={item?.order ?? index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={{
                              ...provided.draggableProps.style,
                              borderBottom: isLast ? '' : '1px solid #dddfe5',
                              backgroundColor: snapshot.isDragging
                                ? '#e4f0ff'
                                : '',
                            }}
                          >
                            <MaterialElement
                              category={item.category}
                              company={item.company}
                              count={item.count}
                              name={item.name}
                              serialNumber={item.serialNumber}
                              size={item.size}
                              uuid={item.uuid}
                              onRemove={handleRemoveMaterial}
                              onUpdate={handleUpdateMaterial}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          materials.map((item) => (
            <MaterialElement
              category={item.category}
              company={item.company}
              count={item.count}
              name={item.name}
              serialNumber={item.serialNumber}
              size={item.size}
              uuid={item.uuid}
              onRemove={handleRemoveMaterial}
              onUpdate={handleUpdateMaterial}
            />
          ))
        ))}

      {isEmpty(materials) && (
        <ListItem>
          <NothingAddedPlaceholder />
        </ListItem>
      )}
    </List>
  );

  return (
    <>
      {!!(readonly && attachments?.length) && (
        <Gallery
          isOpen={!!showGallery}
          withImageDots
          filesList={attachments}
          onClose={handleGalleryClose}
          // onFileUpdate={readonly ? undefined : handleFileUpdateRequest}
        />
      )}

      {mode === 'create' ? (
        <form id="new-group-form" onSubmit={handleGroupSubmit}>
          {tableInner}
          <div className="mb-1">
            <Button
              key="save-button"
              type="submit"
              form="new-group-form"
              className="mr-1"
            >
              {t('save')}
            </Button>
            <Button
              key="cancel-button"
              type="button"
              variant="secondary"
              onClick={onSubmitCancel}
            >
              {t('cancel')}
            </Button>
          </div>
        </form>
      ) : (
        tableInner
      )}
    </>
  );
};
