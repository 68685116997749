import React, { ComponentType } from 'react';
import { MenuItemPreloader } from '../MenuItemPreloader';

interface Props {
  queryLength?: number;
}

const MIN_PRELOADER_ELEMENTS = 1;
const MAX_PRELOADER_ELEMENTS = 6;
const PRELOADER_ELEMENTS_LENGTH_COEFFICIENT = 3;

const getMenuPreloaderItemsLength = (queryLength: number): number =>
  Math.min(
    MAX_PRELOADER_ELEMENTS,
    Math.max(
      Math.floor(
        (MAX_PRELOADER_ELEMENTS / (queryLength || MIN_PRELOADER_ELEMENTS)) *
          PRELOADER_ELEMENTS_LENGTH_COEFFICIENT
      ),
      MIN_PRELOADER_ELEMENTS
    )
  );

export const SearchPreloader: ComponentType<Props> = ({ queryLength = 0 }) => {
  const itemsLength = getMenuPreloaderItemsLength(queryLength);

  return (
    <>
      {Array(itemsLength)
        .fill(true)
        .map((_, i) => (
          <MenuItemPreloader
            className="MenuItemPreloader"
            key={`menu-item-preloader-${i}`}
          />
        ))}
    </>
  );
};

SearchPreloader.displayName = 'SearchPreloader';
