import { createSelector } from 'reselect';
import { createPropsGetter, createStateGetter } from 'store/utils';
import map from 'lodash/map';
import includes from 'lodash/includes';
import { selectLibraryElements } from 'store/modules/entities/selectors/selectors';

const selectLibrarySearchResults = createStateGetter('library.search');
const selectSearchResultsList = createSelector(
  [selectLibraryElements, selectLibrarySearchResults],
  (elements, results) => {
    if (results && !results.length) return results;
    if (!elements || !results) return null;
    return results.map(uuid => elements[uuid]).filter(Boolean);
  }
);

const getSelectedItemsProp = createPropsGetter('selectedItems');

const selectLibrarySearchResultsByProp = createSelector(
  [selectSearchResultsList, getSelectedItemsProp],
  (results, selectedItems = {}) => {
    if (!results || !Array.isArray(results)) return null;

    return results.map(item => {
      const isSelected = !!(
        selectedItems[item.category] &&
        includes(selectedItems[item.category], item.id)
      );

      return { ...item, isSelected };
    });
  }
);

const selectLibrarySearchResultsByUUID = createSelector(
  [selectSearchResultsList, getSelectedItemsProp],
  (results, selectedItems = []) => {
    if (!results) return null;
    const uuids = map(selectedItems, 'uuid');
    return results.map(item => {
      const isSelected = includes(uuids, item.uuid);

      return { ...item, isSelected };
    });
  }
);

export const createElementByUUIDSelector = uuid =>
  createSelector(selectLibraryElements, createStateGetter(uuid));

export {
  selectSearchResultsList,
  getSelectedItemsProp,
  selectLibrarySearchResultsByProp,
  selectLibrarySearchResultsByUUID,
};
