import React, { FC, ReactElement } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { LOGIN } from 'scenes/routes.enum';
import user from 'local-store/user';

interface Props {
  component: FC<RouteComponentProps>;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: Props): ReactElement => (
  <Route
    {...rest}
    render={({ location, ...routeProps }) => {
      const token =
        Boolean(user.getAccessToken()) && Boolean(user.getRefreshToken());

      if (!token) {
        user.removeTokens();
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { ...location, forceLogout: true },
            }}
          />
        );
      }

      return <Component location={location} {...routeProps} />;
    }}
  />
);
