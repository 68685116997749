import React, { memo, ReactNode, HTMLAttributes, FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Spinner } from './Spinner';
import styles from './Loader.module.scss';

interface LoaderProps extends HTMLAttributes<HTMLSpanElement> {
  /**
   * Spinner icon
   */
  icon?: ReactNode;
  /**
   * Loader label string describing loader process
   */
  label?: ReactNode;
  /**
   * Loader view mode
   */
  mode?: 'minimal' | 'spinner';
  /**
   * Flag to show/hide spinner in default view mode
   */
  showSpinner?: boolean;
}

/**
 * Renders a component that displays the loading state
 */
export const Loader: FC<LoaderProps> = memo(function Loader({
  label,
  mode,
  icon = <Spinner />,
  showSpinner = false,
  className,
  ...props
}) {
  const { t } = useTranslation();

  const renderChildren = () => {
    switch (mode) {
      case 'spinner':
        return <span className={styles.icon}>{icon}</span>;

      case 'minimal':
        return (
          <span className={styles.dots}>
            <span className={styles.dot} />
            <span className={styles.dot} />
            <span className={styles.dot} />
          </span>
        );

      default:
        return (
          <>
            {showSpinner && <span className={styles.icon}>{icon}</span>}
            <span className={styles.label}>{label || t('Loading')}</span>
          </>
        );
    }
  };

  return (
    <span className={cn(styles.loader, className)} {...props}>
      {renderChildren()}
    </span>
  );
});
