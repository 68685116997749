import { combineReducers } from 'redux';
import { makeSetReducer } from '../utils/make-set-reducer';
import attachmentsReducer from './attachments';
import debriefingQuestionsReducer from './debriefing-questions';
import libraryElementsReducer from './library-elements';
import operationMaterialsReducer from './operation-materials';
import operationsReducer from './operations';
import researchReducer from './research';
import templateMaterialsReducer from './template-materials';
import templatesReducer from './templates';
import timeoutQuestionsReducer from './timeout-questions';
import assessmentsReducer from './assessments';
import institutesReducer from './institutes';
import assessmentFormsReducerLegacy from './assessment-form-legacy';
import usersReducer from './users';

/**
 * TODO:
 * Would be nice to have all Entity schemas
 * automatically added to the reducer
 */
export default combineReducers({
  addresses: makeSetReducer({ propName: 'addresses' }),
  assessments: makeSetReducer(
    {
      propName: 'assessments',
    },
    assessmentsReducer
  ),
  assessmentsSubmissions: makeSetReducer({
    propName: 'assessmentsSubmissions',
  }),
  assessmentForms: makeSetReducer(
    {
      propName: 'assessmentForms',
    },
    assessmentFormsReducerLegacy
  ),
  attachments: attachmentsReducer,
  contacts: makeSetReducer({ propName: 'contacts' }),
  criteria: makeSetReducer({ propName: 'criteria' }),
  debriefingQuestions: debriefingQuestionsReducer,
  debriefings: makeSetReducer({ propName: 'debriefings', deep: true }),
  departments: makeSetReducer({ propName: 'departments' }),
  diagnoses: makeSetReducer({ propName: 'diagnoses' }),
  incidents: makeSetReducer({ propName: 'incidents' }),
  institutes: makeSetReducer({ propName: 'institutes' }, institutesReducer),
  libraryElements: libraryElementsReducer,
  materialsGroups: makeSetReducer({ propName: 'materialsGroups' }),
  operationMaterials: operationMaterialsReducer,
  operations: operationsReducer,
  operationStepsActualTime: makeSetReducer({
    propName: 'operationStepsActualTime',
  }),
  patients: makeSetReducer({ propName: 'patients' }),
  postOPNotes: makeSetReducer({ propName: 'postOPNotes' }),
  reports: makeSetReducer({ propName: 'reports' }),
  research: researchReducer,
  templateCategories: makeSetReducer({
    propName: 'templateCategories',
    deep: true,
  }),
  templateMaterials: templateMaterialsReducer,
  templates: templatesReducer,
  timeoutQuestions: timeoutQuestionsReducer,
  users: makeSetReducer({ propName: 'users' }, usersReducer),
  vitalInformation: makeSetReducer({ propName: 'vitalInformation' }),
  materialCompanies: makeSetReducer({ propName: 'materialCompanies' }),
  companiesContacts: makeSetReducer({ propName: 'companiesContacts' }),
});
