import ReactGA from 'react-ga';

import Bugsnag from '@bugsnag/js';

import { getEnv } from 'utils';
import { PRODUCTION } from 'app-constants';

export const initializeAnalytics = () => {
  const EXAMPLE_TRACKING_ID = 'UA-000000000-0';
  const ANALYTICS_DEBUG_MODE_ENABLED =
    process.env.REACT_APP_DEBUG_ANALYTICS === 'true';
  const ENV = getEnv();

  if (ENV === PRODUCTION || ANALYTICS_DEBUG_MODE_ENABLED) {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

    if (
      ENV === PRODUCTION &&
      (!trackingId || trackingId === EXAMPLE_TRACKING_ID)
    ) {
      const error = new Error('No GA tracking ID provided');
      Bugsnag.notify(error);
    } else {
      ReactGA.initialize(trackingId, {
        debug: ENV !== PRODUCTION && ANALYTICS_DEBUG_MODE_ENABLED,
        standardImplementation: true,
      });
    }
  }
};
