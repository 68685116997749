import { AxiosRequestConfig } from 'common-types/api';

export const shouldIgnoreRequestBodyClean = (
  config: AxiosRequestConfig
): boolean => {
  const isDisabled = config?.ignoreRequestBodyClean === true;
  const hasDifferentContentType =
    config?.headers?.['Content-Type'] !== 'application/json';
  const shouldIgnore = isDisabled || hasDifferentContentType;

  return shouldIgnore;
};
