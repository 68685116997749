import React, { FC, HTMLAttributes, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { IconButton } from 'components/Button';
import styles from './Panel.module.scss';

export interface PanelHeaderProps {
  closeButtonProps?: any;
  showClose?: boolean;
  mode?: 'default' | 'tabs';
  customCloseButton?: ReactNode;
}

export const PanelHeader: FC<
  PanelHeaderProps & HTMLAttributes<HTMLDivElement>
> = ({
  className,
  children,
  closeButtonProps = {},
  showClose,
  mode = 'default',
  customCloseButton,
  ...restProps
}) => {
  const { closeButtonClassName, restCloseButtonProps } = useMemo(() => {
    const { className: closeButtonClassName, ...restCloseButtonProps } =
      closeButtonProps;
    return { closeButtonClassName, restCloseButtonProps };
  }, [closeButtonProps]);
  const closeButton = useMemo(
    () =>
      showClose ? (
        <IconButton
          icon="close"
          color="body"
          className={cn(styles.close, closeButtonClassName)}
          {...restCloseButtonProps}
        />
      ) : customCloseButton ? (
        customCloseButton
      ) : null,
    [closeButtonClassName, restCloseButtonProps, showClose, customCloseButton]
  );

  return (
    <div
      className={cn(className, styles.panelHeader, {
        [styles[mode]]: mode,
      })}
      {...restProps}
    >
      <div className={styles.child}>{children}</div>
      {closeButton}
    </div>
  );
};
