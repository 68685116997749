import React, { FC } from 'react';
import File from 'assets/File.svg';
import FileError from 'assets/FileError.svg';
import cn from 'classnames';
import { IconButton } from 'components/Button';
import { Col, Row } from 'components/Grid';
import classes from './AttachmentUpload.module.scss';
import { AttachmentUploadStatus } from './AttachmentUploadStatus';

export interface AttachmentUploadProps {
  id: string;
  onRetry?(param: { id: string }): void;
  onCancel(param: { id: string }): void;
  progress: number | null;
  name: string;
  error?: string;
}

export const AttachmentUpload: FC<AttachmentUploadProps> = ({
  id,
  onRetry,
  onCancel,
  progress,
  name,
  error,
}) => {
  const handleUploadRetry = () => {
    onRetry?.({ id });
  };

  const handleUploadCancel = () => {
    onCancel({ id });
  };
  const hasError = !!error;
  const canCancel = !progress || progress < 100;

  return (
    <Row alignItems="center" className="mb-2">
      <Col auto>
        <img src={error ? FileError : File} alt="file_icon" />
      </Col>
      <Col>
        <strong className={classes.fileName}>{name}</strong>
        <Row>
          <Col>
            <progress
              className={cn(classes.progress, {
                [classes.error]: error,
              })}
              value={progress ?? undefined}
              max="100"
            />
          </Col>
          <Col auto basis="90px">
            <AttachmentUploadStatus progress={progress} error={error} />
          </Col>
        </Row>
      </Col>
      <Col auto>
        {typeof onRetry === 'function' && hasError && (
          <IconButton
            type="button"
            icon="refresh"
            onClick={handleUploadRetry}
          />
        )}

        {canCancel && (
          <IconButton
            type="button"
            className="ml-05"
            icon="close"
            onClick={handleUploadCancel}
          />
        )}
      </Col>
    </Row>
  );
};
