import { REQUEST_STATES, REQUEST_TYPES } from 'app-constants';

export const getActionRequestType = (requestName) => {
  // [ namespace ]: *REQUEST_TYPE*_SOMETHING_SOMETHING
  const match = requestName.match(/^\[ .* \]: ([^_]*)_/);
  if (!match) return null;

  const [, type] = match;
  switch (type) {
    case 'CREATE':
      return REQUEST_TYPES.CREATE;
    case 'UPDATE':
      return REQUEST_TYPES.UPDATE;
    case 'DELETE':
      return REQUEST_TYPES.DELETE;
    case 'SUBMIT':
      return REQUEST_TYPES.SUBMIT;
    case 'READ':
      return REQUEST_TYPES.READ;
    case 'SEARCH':
      return REQUEST_TYPES.SEARCH;
    case 'UPLOAD':
      return REQUEST_TYPES.UPLOAD;
    case 'LOAD':
      return REQUEST_TYPES.LOAD;
    case 'AUTOSAVE':
      return REQUEST_TYPES.AUTOSAVE;
    default:
      return REQUEST_TYPES.OTHER;
  }
};

export default function parseAsyncActionType(actionType) {
  /**
   * Async action types have following format:
   * ```
   * '[ <NAMESPACE> ]: <REQUEST_TYPE>_<ENTITY_NAME>_<REQUEST_STATE>'
   * ```
   *
   * eg "[ auth ]: READ_USER_SUCCESS"
   */
  const matches = /(\[ (.*) \]: )?(.*)_(REQUEST|SUCCESS|FAILURE|RESET)/.exec(
    actionType
  );

  if (!matches) return null;

  const [, , actionNamespace, actionName, requestState] = matches;

  const isNotStarted = requestState === REQUEST_STATES.NOT_STARTED;
  const isFailure = requestState === REQUEST_STATES.FAILURE;
  const isRequesting = requestState === REQUEST_STATES.REQUEST;
  const isSuccess = requestState === REQUEST_STATES.SUCCESS;
  const isReset = requestState === REQUEST_STATES.RESET;

  const requestType = getActionRequestType(actionType);

  return {
    actionName,
    actionNamespace,
    actionType,
    isFailure,
    isNotStarted,
    isRequesting,
    isReset,
    isSuccess,
    requestName: `[ ${actionNamespace} ]: ${actionName}`,
    requestState,
    requestType,
  };
}
