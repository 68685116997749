const MIN_PRELOADER_ELEMENTS = 1;
const MAX_PRELOADER_ELEMENTS = 6;
const PRELOADER_ELEMENTS_LENGTH_COEFICIENT = 3;

export const getMenuPreloaderItemsLength = (queryLength: number) =>
  Math.min(
    MAX_PRELOADER_ELEMENTS,
    Math.max(
      Math.floor(
        (MAX_PRELOADER_ELEMENTS / (queryLength || MIN_PRELOADER_ELEMENTS)) *
          PRELOADER_ELEMENTS_LENGTH_COEFICIENT
      ),
      MIN_PRELOADER_ELEMENTS
    )
  );
