import React, { ReactElement, useEffect, useState } from 'react';
import { i18n } from 'translations/i18n-instance';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEqual, noop, map } from 'lodash';
import { Formik, FormikProps } from 'formik';
import { createStructuredSelector } from 'reselect';

import {
  CREATE,
  TEMPLATE_ACCESS_LEVELS,
  TEMPLATE_ACCESS_LIST,
  UPDATE,
} from 'app-constants';
import UserFetcher from 'fetchers/UserFetcher';
import { TEMPLATE_MATERIALS } from 'scenes/routes.enum';
import { selectDefaultCategoryId } from 'store/modules/auth';
import {
  applyPathParams,
  formatDate,
  formatUserName,
  guessTranslation,
  isTranslatableStringEmpty,
  sanitizeTranslatable,
} from 'utils';
import { omitEmpty } from 'utils/omit-empty';

import {
  FormInputTranslatable,
  FormTextareaTranslatable,
  FormTextBox,
  FormSelect,
} from 'components/Form/FormInput';
import { useTranslation } from 'react-i18next';
import { FormBody } from 'components/Form/FormBody';
import { TranslatableString } from 'components/TranslatableString';
import { LanguageBadge } from 'components/Badge';
import { Row, Col } from 'components/Grid';
import { FormTemplateCategoryInput } from 'components/TemplateCategory';
import { PanelActions } from 'components/Panel/PanelActions';
import { Button } from 'components/Button';
import { PersistStateLink } from 'components/Link';

interface Props {
  /**
   * Does create or update, which one depends on the mode
   */
  onSubmit: Function;
  /**
   * Is template loading
   */
  isLoading: boolean;
  /**
   * CREATE || UPDATE
   */
  mode: string;
  readonly: boolean;
  templateId: number;
  template: any;
  /**
   * Default category id for the logged user
   */
  defaultCategory: number;
}

const TemplateInfoFormComponent = ({
  defaultCategory,
  templateId,
  isLoading = false,
  mode = CREATE,
  onSubmit = noop,
  readonly = false,
  template = null,
}: Props): ReactElement => {
  const [inputLanguage, setInputLanguage] = useState(i18n.language);
  const { t } = useTranslation();

  useEffect(() => {
    if (template) {
      const guessedTranslation = guessTranslation(template.name, inputLanguage);
      if (
        guessedTranslation?.language &&
        guessedTranslation?.language !== inputLanguage
      ) {
        setInputLanguage(guessedTranslation.language);
      }
    }
  }, [inputLanguage, template]);

  const formValues = () => {
    const defaultValues = {
      name: null,
      description: null,
      categoryId: defaultCategory,
      access: TEMPLATE_ACCESS_LEVELS.public,
      codeableConcepts: {},
    };

    if (!template) {
      return defaultValues;
    }

    const { name, description, categoryId, isPrivate, instituteOnly } =
      template;
    const access = isPrivate
      ? TEMPLATE_ACCESS_LEVELS.private
      : instituteOnly
      ? TEMPLATE_ACCESS_LEVELS.institute
      : TEMPLATE_ACCESS_LEVELS.public;

    const values = {
      ...defaultValues,
      access,
      categoryId,
      description: sanitizeTranslatable(description),
      name: sanitizeTranslatable(name),
    };

    if (template.codeableConcepts?.length) {
      values.codeableConcepts = template.codeableConcepts.reduce(
        (acc, concept) => {
          acc[concept.slotName] = concept.code;
          return acc;
        },
        {}
      );
    }

    return values;
  };

  const handleValidation = (values) => {
    const errors: { categoryId?: string; name?: string } = {};

    if (!values.categoryId) {
      errors.categoryId = t('selectTemplateDiscipline');
    }

    if (isTranslatableStringEmpty(values.name)) {
      errors.name = t('nameIsRequired');
    }

    return errors;
  };

  const handleSubmit = (submitValues) => {
    const { access, codeableConcepts, ...inputBody } = submitValues;
    if (isEqual(submitValues, formValues)) return;

    // Due to API validation, the "name" property cannot have empty value
    inputBody.name = omitEmpty(inputBody.name);

    if (access) {
      inputBody.isPrivate = access === TEMPLATE_ACCESS_LEVELS.private;
      inputBody.instituteOnly = access === TEMPLATE_ACCESS_LEVELS.institute;
    }

    inputBody.codeableConcepts = map(codeableConcepts, (code, slotName) => ({
      code,
      slotName,
    }));

    onSubmit(inputBody, template?.id);
  };

  const renderReadonly = () => {
    return (
      <FormBody>
        <TranslatableString allowFallback string={template?.name}>
          {(value, opts) => (
            <FormTextBox
              label={t('Name')}
              value={value}
              inputAddon={<LanguageBadge lang={opts?.language} />}
            />
          )}
        </TranslatableString>
        <TranslatableString allowFallback string={template?.description}>
          {(value) => (
            <FormTextBox
              label={t('Description')}
              mode="textarea"
              value={value}
            />
          )}
        </TranslatableString>
        {renderAdditionalInfo()}
      </FormBody>
    );
  };

  const renderAdditionalInfo = () => {
    if (!template) return null;

    return (
      <Row>
        <Col width="6">
          <FormTextBox
            label={t('Author')}
            value={
              <UserFetcher id={template.createdBy}>
                {formatUserName}
              </UserFetcher>
            }
          />
        </Col>
        <Col width="6">
          <FormTextBox
            label={t('Created')}
            value={formatDate(template.createdAt)}
          />
        </Col>
      </Row>
    );
  };

  const handleInputLanguageChange = (inputLanguage) => {
    setInputLanguage(inputLanguage);
  };

  if (readonly) {
    return renderReadonly();
  }

  return (
    <Formik
      initialValues={formValues()}
      validate={handleValidation}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        dirty,
        errors,
        handleChange,
        handleSubmit,
        isValid,
        submitForm,
        touched,
        values,
      }: FormikProps<{ [field: string]: any }>) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <FormBody>
                <FormTemplateCategoryInput
                  label={t('Discipline')}
                  name="categoryId"
                  error={touched?.categoryId && errors.categoryId}
                  onChange={handleChange}
                  formInputType=""
                  id="categoryId"
                  allowEmpty={false}
                  className=""
                  placeholder=""
                  onBlur={mode === UPDATE ? submitForm : undefined}
                  value={values.categoryId}
                />
                <FormInputTranslatable
                  error={touched?.name && errors.name}
                  name="name"
                  label={t('Name')}
                  placeholder={t('enterTemplateName')}
                  onChange={handleChange}
                  value={values.name}
                  onBlur={mode === UPDATE ? submitForm : undefined}
                  inputLanguage={inputLanguage}
                  onLanguageChange={handleInputLanguageChange}
                />
                <FormTextareaTranslatable
                  error={touched?.description && errors.description}
                  label={t('Description')}
                  name="description"
                  onBlur={mode === UPDATE ? submitForm : undefined}
                  onChange={handleChange}
                  placeholder={t('enterTemplateDescription')}
                  value={values.description}
                  inputLanguage={inputLanguage}
                  onLanguageChange={handleInputLanguageChange}
                />
                <FormSelect
                  name="access"
                  mode="select"
                  id="access"
                  label={t('Access')}
                  placeholder={t('selectTemplateVisibility')}
                  value={values.access}
                  onChange={handleChange}
                  onSelect={
                    mode === UPDATE
                      ? () => setTimeout(submitForm, 0)
                      : undefined
                  }
                  options={TEMPLATE_ACCESS_LIST(t)}
                  required
                />
                {renderAdditionalInfo()}
              </FormBody>

              <PanelActions variant="floating">
                {mode === CREATE ? (
                  <Button
                    type="submit"
                    disabled={!dirty || !isValid || isLoading}
                  >
                    {t('Create')}
                  </Button>
                ) : (
                  <Button
                    component={PersistStateLink}
                    to={applyPathParams(TEMPLATE_MATERIALS, { templateId })}
                  >
                    {t('Next')}
                  </Button>
                )}
              </PanelActions>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = createStructuredSelector({
  defaultCategory: selectDefaultCategoryId,
});

const withConnect = connect(mapStateToProps);

const enhancer = compose(withConnect);

export const TemplateInfoForm = enhancer(TemplateInfoFormComponent);
