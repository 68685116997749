import { operationsListSchema, incidentSchema } from 'api/v1/schemas';
import {
  createSuccessActionCreator_DEPRECATED,
  createFailureActionCreator,
} from 'store/utils';

import * as types from './types';

export const startOperationReportEdit = () => ({
  type: types.START_OPERATION_REPORT_EDIT,
});

export const closeOperationReportEdit = () => ({
  type: types.CLOSE_OPERATION_REPORT_EDIT,
});

export const readOperationsHistoryRequest = payload => ({
  type: types.READ_OPERATIONS_HISTORY_REQUEST,
  payload,
});

export const readOperationsHistorySuccess = createSuccessActionCreator_DEPRECATED(
  types.READ_OPERATIONS_HISTORY_SUCCESS,
  null,
  operationsListSchema
);

export const readOperationsHistoryFailure = createFailureActionCreator(
  types.READ_OPERATIONS_HISTORY_FAILURE
);

export const updateIncidentRequest = (operationId, id, update) => ({
  type: types.UPDATE_INCIDENT_REQUEST,
  payload: { operationId, id, update },
});

export const updateIncidentSuccess = createSuccessActionCreator_DEPRECATED(
  types.UPDATE_INCIDENT_SUCCESS,
  null,
  incidentSchema
);

export const updateIncidentFailure = createFailureActionCreator(
  types.UPDATE_INCIDENT_FAILURE
);

/* history stats */
export const readOperationsStatsRequest = timeRange => ({
  type: types.READ_OPERATIONS_STATS_REQUEST,
  payload: {
    timeRange,
  },
});

export const readOperationsStatsSuccess = payload => ({
  type: types.READ_OPERATIONS_STATS_SUCCESS,
  payload,
});

export const readOperationsStatsFailure = createFailureActionCreator(
  types.READ_OPERATIONS_STATS_FAILURE
);
