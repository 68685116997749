import { operationMaterialsSchema } from 'api/v1/schemas';
import { OPERATION_STATUSES } from 'app-constants';
import invariant from 'invariant';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import memoize from 'lodash/memoize';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {
  createCollectionSelector,
  createDenormalizr,
  createEntitySelector,
  createMatchParamsGetter,
  createStateGetter,
  mapElementsSteps,
} from 'store/utils';
import {
  operationSchema,
  operationVetSchema,
} from '../../../../api/v2/schemas';
import {
  selectAddresses,
  selectAttachments,
  selectDebriefings,
  selectEntities,
  selectIncidents,
  selectLibraryElements,
  selectOperationMaterials,
  selectOperations,
  selectOperationStepsActualTime,
  selectPatients,
  selectTemplates,
} from './selectors';
import { isProductType } from 'utils/get-env';

const selectCurrentPageOperationId = (state, props) => {
  let operationId =
    props?.id ||
    props?.operationId ||
    createMatchParamsGetter('operationId')(state, props);
  if (!parseInt(operationId, 10)) return null;
  return operationId;
};

export const selectOperationById = (operationId) => {
  return createSelector(selectOperations, createStateGetter(operationId, null));
};

const operationDenormalizr = createDenormalizr(
  isProductType('vet') ? operationVetSchema : operationSchema
);
export const selectDenormalizedOperationById = (operationId) => {
  const operationSelector = selectOperationById(operationId);
  const selector = createSelector(
    [operationSelector, selectEntities],
    operationDenormalizr
  );

  return selector;
};

export const selectCurrentPageOperation = createEntitySelector(
  selectOperations,
  selectCurrentPageOperationId
);

export const selectCurrentPageOperationDenormalized = createSelector(
  [selectCurrentPageOperation, selectEntities],
  (operation, entities) =>
    denormalize(
      operation,
      isProductType('vet') ? operationVetSchema : operationSchema,
      entities
    )
);

export const hasOperationAssessments = createSelector(
  [selectCurrentPageOperation],
  (operation) => {
    return !!operation?.assessments?.length;
  }
);

export const selectHasAssessment = createSelector(
  selectCurrentPageOperation,
  (operation) => Boolean(operation?.assessments?.length)
);

const opCollectionSelector = createCollectionSelector([
  'procedureElements',
  'elements',
]);
export const selectOperationElements = createSelector(
  [selectCurrentPageOperation, selectLibraryElements],
  (operation, elements) => {
    // FIXME: this selector tries to find elements in one of the following
    // properties. It is a fragile approach.
    const opElements = opCollectionSelector(operation, elements);
    const mappedElements = mapElementsSteps(opElements);
    return mappedElements;
  }
);

export const selectOperationElementsUUIDs = memoize((operationId) =>
  createSelector(selectOperations, (operations) => {
    const operation = operations?.[operationId];
    const elements =
      operation?.elements ?? operation?.procedureElements ?? null;

    return elements;
  })
);

export const selectOperationSteps = createSelector(
  [selectOperationElements],
  (entityElements) => {
    const elements = mapElementsSteps(entityElements);
    return elements;
  }
);

export const selectOperationStatus = createSelector(
  selectCurrentPageOperation,
  createStateGetter('status')
);

export const isOperationDone = createSelector(
  selectOperationStatus,
  (status) => OPERATION_STATUSES.done === status
);

export const selectOperationPatient = createSelector(
  [selectCurrentPageOperation, selectPatients, selectAddresses],
  (operation, patients, addresses) => {
    if (!operation) return null;

    const patient = get(
      patients,
      operation.patientId || operation.patient,
      null
    );

    if (!patient || patient.isFake) return null;
    const address = get(addresses, patient.addressId || patient.address, null);

    return {
      ...patient,
      address,
    };
  }
);

export const selectOperationVetPatient = createSelector(
  [selectCurrentPageOperation, selectPatients, selectAddresses],
  (operation, vetPatients) => {
    if (!operation) return null;

    const vetPatient = get(
      vetPatients,
      operation.caseNumber || operation.vetPatient,
      null
    );

    return {
      ...vetPatient,
    };
  }
);

export const selectOperationDebriefing = createSelector(
  [selectCurrentPageOperation, selectDebriefings],
  (operation, debriefings) => {
    if (!operation || !debriefings) return null;
    return get(debriefings, get(operation, 'debriefing'), null);
  }
);

export const selectOperationDebriefingId = createSelector(
  selectOperationDebriefing,
  (debriefing) => debriefing?.id ?? null
);

export const selectOperationIncidentsIds = createSelector(
  [selectOperationDebriefing, selectEntities],
  (debriefing, entities) => {
    if (!debriefing || !entities) return null;

    return (debriefing.incidents || []).filter(
      (i) => !entities.incidents[i].isDeleted
    );
  }
);

export const selectOperationIncidentsList = createSelector(
  [selectOperationIncidentsIds, selectIncidents],
  createCollectionSelector()
);

export const selectActualStepsTimeList = createSelector(
  [selectOperationDebriefing, selectOperationStepsActualTime],
  createCollectionSelector('stepsActualTime')
);

const selectOperationStepsIncidentsMap = createSelector(
  selectOperationIncidentsList,
  (incidents) => (incidents?.length ? keyBy(incidents, 'elementId') : null)
);

const selectActualStepsTimeMap = createSelector(
  [selectActualStepsTimeList],
  (list) => (list?.length ? keyBy(list, 'relationId') : null)
);

export const selectHistoryStepsList = createSelector(
  [
    selectOperationSteps,
    selectActualStepsTimeMap,
    selectOperationStepsIncidentsMap,
  ],
  (steps, stepsActualTime, stepsIncidents) => {
    if (!steps || (!stepsActualTime && !stepsIncidents)) return steps;

    return steps.map((step) => {
      if (stepsActualTime) {
        const stepTime = stepsActualTime[step.relationId];
        if (stepTime) {
          step.actualTime = stepTime.actualTime;
        }
      }

      if (stepsIncidents) {
        const stepIncident = stepsIncidents[step.relationId];
        if (stepIncident) {
          step.incident = stepIncident;
        }
      }

      return step;
    });
  }
);

export const selectOperationPatientRisks = createSelector(
  [selectCurrentPageOperation],
  createStateGetter('patientRisks')
);

export const selectOperationMaterialsById = createSelector(
  [selectCurrentPageOperationId, selectOperationMaterials],
  (operationId, materials) => get(materials, operationId, null)
);

export const createOperationMaterialsDenormalizedSelector = (operationId) => {
  invariant(operationId, 'operationId required');
  return createSelector(
    [selectOperationMaterials, selectEntities],
    (materials, entities) => {
      if (!materials || !entities) return null;
      return denormalize(
        materials[operationId],
        operationMaterialsSchema,
        entities
      );
    }
  );
};

export const getOperationTemplateId = (operation) =>
  operation?.templateId || operation?.operationTemplateId;

export const selectOperationTemplateIdByOpId = (operationId) =>
  createSelector([selectOperations], (operations) => {
    if (!operations) return null;
    const operation = operations[operationId];
    if (!operation) return null;
    return getOperationTemplateId(operation) || null;
  });

export const selectTemplateByOpId = (operationId) => {
  const templateIdSelector = selectOperationTemplateIdByOpId(operationId);

  return createSelector(
    [selectTemplates, templateIdSelector],
    (templates, templateId) => {
      if (!templates || !templateId) return null;
      return templates[templateId] || null;
    }
  );
};

export const selectTemplateAttachmentsByOpId = (operationId) => {
  const templateSelector = selectTemplateByOpId(operationId);

  return createSelector(
    [templateSelector, selectAttachments],
    (template, attachments) => {
      if (!template || !attachments) return null;
      if (!template.attachments || !Array.isArray(template.attachments)) {
        return [];
      }

      return template.attachments.map((id) => attachments[id]).filter(Boolean);
    }
  );
};
