export const getResults = (payload, entityName) => {
  const { entities, result } = payload;
  const entitiesMap = entities[entityName];

  if (typeof result === 'number' || typeof result === 'string') {
    const obj = entitiesMap[result];
    return obj;
  }

  if (Array.isArray(result)) {
    const results = result.map((id) => entitiesMap[id]);
    return results;
  }

  throw new TypeError('result value is invalid');
};
