import React, { ComponentType, HTMLAttributes } from 'react';
import cn from 'classnames';
import classes from './Content.module.scss';

interface Props {}

export const Content: ComponentType<Props & HTMLAttributes<HTMLPreElement>> = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <pre className={cn(classes.content, className)} {...restProps}>
      {children}
    </pre>
  );
};

Content.displayName = 'Content';
