import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectOperationPageTitle,
  selectOperationTemplateCategoryId,
  selectOperationTemplateId,
} from 'store/modules/operation-planning';
import { Col, Row, IconEmphasized as Icon } from 'components';
import TitleAndTemplateCategory from './TitleAndTemplateCategory';
import { selectHasAssessment } from 'store/modules/entities/selectors/operations';

const OperationHistoryTitle: ComponentType<any> = (props) => {
  return (
    <Row alignItems="center" nowrap>
      <Col auto>
        <Icon icon="history" type="warning" />
      </Col>
      <Col>
        <TitleAndTemplateCategory {...props} />
      </Col>
    </Row>
  );
};

const mapStateToProps = createStructuredSelector({
  hasAssessment: selectHasAssessment,
  categoryId: selectOperationTemplateCategoryId,
  templateId: selectOperationTemplateId,
  title: selectOperationPageTitle,
});
const withConnect = connect(mapStateToProps);

export default withConnect(OperationHistoryTitle);
