import {
  READ_DEPARTMENTS_LIST_SUCCESS,
  VIEW_SURGEON_PIN_SUCCESS,
  RESET_VIEW_SURGEON_PIN,
} from './types';

export const initialState = {
  departments: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case READ_DEPARTMENTS_LIST_SUCCESS:
      return {
        ...state,
        departments: action.payload.result,
      };

    case VIEW_SURGEON_PIN_SUCCESS:
      return {
        ...state,
        surgeonPin: action.payload.surgeonPin,
      };

    case RESET_VIEW_SURGEON_PIN:
      return {
        ...state,
        surgeonPin: null,
      };

    default:
      return state;
  }
};
