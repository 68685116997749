import cn from 'classnames';
import React, { AllHTMLAttributes, ElementType, FC } from 'react';

import styles from './Flex.module.scss';

interface FlexProps extends AllHTMLAttributes<HTMLElement> {
  /**
   * Root html element
   */
  component?: ElementType;
  /**
   * Flag to set width 100% styles
   */
  fullWidth?: boolean;
  /**
   * Flag to make set inline-flex style
   */
  inline?: boolean;
  /**
   * Flag to set flex-wrap: nowrap style
   */
  nowrap?: boolean;
}

/**
 * Some weird positioning/spacing component.
 * At the moment this is too hard to remove from the app due to the high number of occurrences in the app.
 * TODO - remove or replace when faced with it during refactoring.
 */
export const DeprecatedFlex: FC<FlexProps> = ({
  children,
  className,
  component: Component = 'span',
  fullWidth = false,
  inline = true,
  nowrap,
  ...props
}) => (
  <Component
    className={cn(styles.flex, className, {
      [styles.inline]: inline,
      [styles.fullWidth]: fullWidth,
      [styles.nowrap]: nowrap,
    })}
    {...props}
  >
    {children}
  </Component>
);
