import {
  createSuccessActionCreator_DEPRECATED,
  createFailureActionCreator,
  createSuccessActionCreator,
} from 'store/utils';
import { instituteSchema, userSchema } from 'api/v1/schemas';
import * as types from './types';

/* **** Institute **** */

const readInstituteRequest = (id) => ({
  type: types.READ_INSTITUTE_REQUEST,
  id,
});

const readInstituteSuccess = createSuccessActionCreator_DEPRECATED(
  types.READ_INSTITUTE_SUCCESS,
  'institute',
  instituteSchema
);

const readInstituteFailure = createFailureActionCreator(
  types.READ_INSTITUTE_FAILURE
);

const updateInstituteRequest = (id, data) => ({
  type: types.UPDATE_INSTITUTE_REQUEST,
  payload: {
    id,
    data,
  },
});

const updateInstituteSuccess = createSuccessActionCreator(
  types.UPDATE_INSTITUTE_SUCCESS
);

const updateInstituteFailure = createFailureActionCreator(
  types.UPDATE_INSTITUTE_FAILURE
);

const readInstitutesRequest = () => ({
  type: types.READ_INSTITUTES_LIST_REQUEST,
});

const readInstitutesSuccess = (institutes) => ({
  type: types.READ_INSTITUTES_LIST_SUCCESS,
  payload: institutes,
});

const readInstitutesFailure = (error) => ({
  type: types.READ_INSTITUTES_LIST_FAILURE,
  payload: {
    error,
  },
});

/* **** Department **** */

const readDepartmentsRequest = () => ({
  type: types.READ_DEPARTMENTS_LIST_REQUEST,
});

const readDepartmentsSuccess = (departments) => ({
  type: types.READ_DEPARTMENTS_LIST_SUCCESS,
  payload: departments,
});

const readDepartmentsFailure = (error) => ({
  type: types.READ_DEPARTMENTS_LIST_FAILURE,
  payload: {
    error,
  },
});

/* **** Profile **** */

const updateProfileRequest = (id, data) => ({
  type: types.UPDATE_PROFILE_REQUEST,
  payload: {
    id,
    data,
  },
});

const updateProfileSuccess = (user) => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  payload: user,
});

const updateProfileFailure = (error, errorMessage) => ({
  type: types.UPDATE_PROFILE_FAILURE,
  payload: {
    error,
    errorMessage,
  },
});

/* ***** Email ***** */

const updateEmailRequest = (newEmail, currentEmail) => ({
  type: types.UPDATE_PROFILE_EMAIL_REQUEST,
  payload: {
    newEmail,
    currentEmail,
  },
});

const updateEmailSuccess = (updateStatus) => ({
  type: types.UPDATE_PROFILE_EMAIL_SUCCESS,
  payload: {
    status: 'success',
    ...updateStatus,
  },
});

const updateEmailFailure = (error) => ({
  type: types.UPDATE_PROFILE_EMAIL_FAILURE,
  payload: {
    status: 'error',
    ...error,
  },
});

/* **** Password **** */

const updatePasswordRequest = ({ newPassword, currentPassword }) => ({
  type: types.UPDATE_PASSWORD_REQUEST,
  payload: {
    newPassword,
    currentPassword,
  },
});

const updatePasswordSuccess = () => ({
  type: types.UPDATE_PASSWORD_SUCCESS,
});

const updatePasswordFailure = createFailureActionCreator(
  types.UPDATE_PASSWORD_FAILURE
);

/* **** Surgeon pin **** */

const createSurgeonPinRequest = () => ({
  type: types.CREATE_SURGEON_PIN_REQUEST,
});

const createSurgeonPinSuccess = createSuccessActionCreator_DEPRECATED(
  types.CREATE_SURGEON_PIN_SUCCESS,
  'user',
  userSchema
);

const createSurgeonPinFailure = (error, errorMessage) => ({
  type: types.CREATE_SURGEON_PIN_FAILURE,
  payload: { error, errorMessage },
});

const viewSurgeonPinRequest = () => ({
  type: types.VIEW_SURGEON_PIN_REQUEST,
});

const viewSurgeonPinSuccess = (payload) => ({
  type: types.VIEW_SURGEON_PIN_SUCCESS,
  payload,
});

const viewSurgeonPinFailure = (error, errorMessage) => ({
  type: types.VIEW_SURGEON_PIN_FAILURE,
  payload: { error, errorMessage },
});

const resetViewSurgeonPin = () => ({
  type: types.RESET_VIEW_SURGEON_PIN,
});

const resetSurgeonPinRequest = () => ({
  type: types.RESET_SURGEON_PIN_REQUEST,
});

const resetSurgeonPinSuccess = createSuccessActionCreator_DEPRECATED(
  types.RESET_SURGEON_PIN_SUCCESS,
  'user',
  userSchema
);

const resetSurgeonPinFailure = (error, errorMessage) => ({
  type: types.RESET_SURGEON_PIN_FAILURE,
  payload: { error, errorMessage },
});

export {
  readInstituteRequest,
  readInstituteSuccess,
  readInstituteFailure,
  //
  updateInstituteRequest,
  updateInstituteSuccess,
  updateInstituteFailure,
  //
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  //
  updateEmailRequest,
  updateEmailSuccess,
  updateEmailFailure,
  //
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
  //
  readInstitutesRequest,
  readInstitutesSuccess,
  readInstitutesFailure,
  //
  readDepartmentsRequest,
  readDepartmentsSuccess,
  readDepartmentsFailure,
  //
  createSurgeonPinRequest,
  createSurgeonPinSuccess,
  createSurgeonPinFailure,
  //
  viewSurgeonPinRequest,
  viewSurgeonPinSuccess,
  viewSurgeonPinFailure,
  resetViewSurgeonPin,
  //
  resetSurgeonPinRequest,
  resetSurgeonPinSuccess,
  resetSurgeonPinFailure,
};
