import { id } from '../common-types/api';
import { Category } from '../common-types/templates';

export function getCategoriesTree(
  categoryId: id,
  categories: { [index: string]: Category }
) {
  function getCategoriesChain(categoryId: id, chain: Category[] = []) {
    const category: Category = categories?.[categoryId];
    if (!category) return chain;
    chain.unshift(category);
    if (category?.parentId) {
      return getCategoriesChain(category.parentId, chain);
    }
    return chain;
  }

  return getCategoriesChain(categoryId);
}
