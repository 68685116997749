import React, { FC } from 'react';

import { Panel } from 'components/Panel';
import { BaseModal, BaseModalProps } from './BaseModal';
import { ModalHeader } from './ModalHeader';

export interface ModalProps extends BaseModalProps {
  /**
   * Title displayed on on top of the modal
   */
  title?: React.ReactNode;
}

/**
 * Renders modal component with title and close button
 */
export const Modal: FC<ModalProps> = ({
  children,
  title,
  onRequestClose,
  ...props
}) => {
  return (
    <BaseModal onRequestClose={onRequestClose} {...props}>
      <Panel>
        <ModalHeader title={title} onClose={onRequestClose} />
        {children}
      </Panel>
    </BaseModal>
  );
};
