import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, BadgeProps } from './Badge';

/**
 * Renders translated WPA string to current application language
 */
export const WpaBadge: FC<BadgeProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Badge variant="primary" {...props}>
      {t('WPA')}
    </Badge>
  );
};
