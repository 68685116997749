import { SHOW_NOTIFICATION } from './types';

export default (state = [], { type, payload }) => {
  switch (type) {
    case SHOW_NOTIFICATION: {
      return [...state, { id: Date.now(), ...payload }];
    }

    default:
      return state;
  }
};
