import { createSelector } from 'reselect';
import { createStateGetter } from 'store/utils';
import { selectEntities } from '../entities/selectors/selectors';

export const selectSettings = createStateGetter('settings');

export const selectDepartments = createSelector(
  [selectSettings, selectEntities],
  (settings, entities) => {
    if (!settings?.departments || !entities) return [];
    const departmentsIds = settings.departments || [];
    return departmentsIds.map((id) => {
      const department = entities?.departments?.[id];
      return {
        label: department?.name,
        value: department?.id,
      };
    });
  }
);

export const selectInstitutes = createSelector(
  [selectSettings, selectEntities],
  (settings, entities) => {
    if (!settings?.institutes || !entities) return [];
    const institutesIds = settings.institutes || [];
    return institutesIds.map((id) => {
      const institute = entities?.institutes?.[id];
      return {
        label: institute?.name,
        value: institute?.id,
      };
    });
  }
);

export const selectUserSurgeonPin = createSelector(
  selectSettings,
  (settings) => {
    return settings.surgeonPin;
  }
);
