import { createSelector } from 'reselect';
import { createStateGetter, createCollectionSelector } from 'store/utils';

const selectResearchData = createStateGetter('research');
const selectResearchEntity = createStateGetter('entities.research');
export const selectFavoriteArticlesIds = createStateGetter(
  'research.favoriteIds',
  []
);
export const selectPagesCount = createStateGetter('research.meta.range.total');
export const selectResearchLoading = createStateGetter('research.isLoading');
export const selectResearchErrorMessage = createStateGetter('research.error');

const selectResearchCollection = createSelector(
  [selectResearchData, selectResearchEntity],
  createCollectionSelector('articles')
);

export const selectResearch = createSelector(
  [selectResearchCollection, selectResearchData],
  (articles, data) => {
    if (!articles) return null;
    return articles.map(article => {
      if (article.isBookmarked === undefined) {
        const isBookmarked = Boolean(
          data?.favoriteIds && data.favoriteIds.indexOf(article.id) >= 0
        );
        return {
          isBookmarked,
          ...article,
        };
      }

      return {
        ...article,
      };
    });
  }
);
