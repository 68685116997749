import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { List } from './List';
import { ListHeader } from './ListHeader';
import cn from 'classnames';
import { ListProps } from './List';

import classes from './ListCollapsible.module.scss';
import { Row, Col } from 'components/Grid';
import { IconButton } from 'components/Button';

export interface ListCollapsibleBaseProps {
  collapsibleClassName?: string;
  expand?: boolean;
  header?: ReactNode | ReactNode[];
  onCollapseToggle: () => void;
}

export const ListCollapsibleBase: FC<
  ListCollapsibleBaseProps & ListProps & HTMLAttributes<HTMLElement>
> = ({
  header,
  children,
  expand,
  onCollapseToggle,
  collapsibleClassName,
  ...listProps
}) => {
  return (
    <List {...listProps}>
      <ListHeader
        className={cn({
          [classes.headerCollapse]: !expand,
        })}
        contentGrow
        onClick={onCollapseToggle}
      >
        <Row justifyContent="space-between">
          <Col>{header ?? null}</Col>
          <Col auto>
            <IconButton
              type="button"
              icon={expand ? 'chevron-up' : 'chevron-down'}
            />
          </Col>
        </Row>
      </ListHeader>
      {expand && (
        <div
          className={cn(classes.collapsible, collapsibleClassName, {
            [classes.expand]: expand,
          })}
        >
          {children}
        </div>
      )}
    </List>
  );
};

ListCollapsibleBase.displayName = 'ListCollapsibleBase';
