import omit from 'lodash/omit';
import { omitGeneratedFields } from 'utils';
import { updateOperationPatientRisks } from './operation';
import { createPatient, updatePatient } from './patient';

export const submitPatientForm =
  ({ patientId, patientValues, operationId }) =>
  async (dispatch) => {
    const patientBody = {
      ...omit(patientValues, 'isFake'),
    };

    if (patientValues.address) {
      patientBody.address = omitGeneratedFields(patientValues.address);
    }
    const patientExists = patientId && !patientValues.isFake;
    const patientRequestThunk = patientExists ? updatePatient : createPatient;
    return dispatch(
      patientRequestThunk({ id: operationId, body: patientBody })
    );
  };

export const submitVetPatientForm =
  ({ patient, caseNumber, operationId }) =>
  async (dispatch) => {
    patient = {
      ...patient,
      castrated: !!patient.castrated,
    };
    const vetPatientExists = !!caseNumber;
    const vetPatientRequestThunk = vetPatientExists
      ? updatePatient
      : createPatient;
    return dispatch(vetPatientRequestThunk({ id: operationId, body: patient }));
  };

export const submitPatientRisks =
  (operationId, selectedRisks) => async (dispatch) => {
    if (!operationId) throw new Error('Operation ID is required.');
    let patientRisks = selectedRisks;
    if (Array.isArray(patientRisks)) {
      patientRisks = patientRisks.map((risk) =>
        omit(risk, ['value', 'label', 'isSelected', 'category', 'uuid'])
      );
    }

    return dispatch(updateOperationPatientRisks(operationId, patientRisks));
  };
