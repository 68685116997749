import * as APIv2 from 'api/v2';

import { makeThunkFromTypes } from 'store/utils/make-thunk';
import i18n from 'translations/i18n-instance';
import {
  CREATE_PATIENT_FAILURE,
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  CREATE_VET_PATIENT_FAILURE,
  CREATE_VET_PATIENT_REQUEST,
  CREATE_VET_PATIENT_SUCCESS,
  READ_PATIENT_FAILURE,
  READ_PATIENT_REQUEST,
  READ_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
} from '../types';
import { isProductType } from 'utils/get-env';

/**
 * @param body {object}
 */
export const createPatient = makeThunkFromTypes(
  isProductType('vet') ? APIv2.createVetPatient : APIv2.createOperationPatient,
  {
    request: isProductType('vet')
      ? CREATE_VET_PATIENT_REQUEST
      : CREATE_PATIENT_REQUEST,
    success: isProductType('vet')
      ? CREATE_VET_PATIENT_SUCCESS
      : CREATE_PATIENT_SUCCESS,
    failure: isProductType('vet')
      ? CREATE_VET_PATIENT_FAILURE
      : CREATE_PATIENT_FAILURE,
  },
  () => i18n.t('errors:failedToCreateaPatient')
);

/**
 * @param id {number}
 */
export const readPatient = makeThunkFromTypes(
  isProductType('vet')
    ? APIv2.readOperationVetPatient
    : APIv2.readOperationPatient,
  {
    request: READ_PATIENT_REQUEST,
    success: READ_PATIENT_SUCCESS,
    failure: READ_PATIENT_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToReadPatient')} (id: ${id})`
);

/**
 * @param id {number}
 * @param update {object}
 */
export const updatePatient = makeThunkFromTypes(
  isProductType('vet')
    ? APIv2.updateOperationVetPatient
    : APIv2.updateOperationPatient,
  {
    request: UPDATE_PATIENT_REQUEST,
    success: UPDATE_PATIENT_SUCCESS,
    failure: UPDATE_PATIENT_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdatePatient')} (id: ${id})`
);
