import {
  readResearchArticlesReq,
  readResearchFavArticlesReq,
  readFavoritesArticlesIdsReq,
  postAddArticleToFavoritesReq,
  deleteArticleFromFavoritesReq,
} from 'api/v1';
import { makeThunk } from 'store/utils';
import { calculatePagination } from 'utils/pagination';

import * as actions from './actions';

export const readResearchArticles = ({
  page,
  searchQuery,
}) => async dispatch => {
  try {
    dispatch(actions.readResearchArticlesRequest(page));
    dispatch(actions.readFavoritesArticlesIdsRequest());

    const favoritesArticles = await readFavoritesArticlesIdsReq();
    const articlesList = await readResearchArticlesReq(
      calculatePagination(page),
      searchQuery
    );

    dispatch(actions.readFavoritesArticlesIdsSuccess(favoritesArticles));
    dispatch(actions.readResearchArticlesSuccess(articlesList));
  } catch (error) {
    dispatch(actions.readResearchArticlesFailure(error));
  }
};

export const readResearchBookmarkedArticles = () => async dispatch => {
  try {
    dispatch(actions.readResearchFavArticlesRequest());
    dispatch(actions.readFavoritesArticlesIdsRequest());

    const favoritesArticles = await readFavoritesArticlesIdsReq();
    const articlesList = await readResearchFavArticlesReq();

    dispatch(actions.readFavoritesArticlesIdsSuccess(favoritesArticles));
    dispatch(actions.readResearchFavArticlesSuccess(articlesList));
  } catch (error) {
    dispatch(actions.readResearchFavArticlesFailure(error));
  }
};

export const addArticleToFavorites = makeThunk(postAddArticleToFavoritesReq, {
  request: actions.addArticleToFavoritesRequest,
  success: actions.addArticleToFavoritesSuccess,
  failure: actions.addArticleToFavoritesFailure,
});

export const removeArticleFromFavorites = makeThunk(
  deleteArticleFromFavoritesReq,
  {
    request: actions.removeArticleFromFavoritesRequest,
    success: actions.removeArticleFromFavoritesSuccess,
    failure: actions.removeArticleFromFavoritesFailure,
  }
);

export const clearResearchData = actions.setEmptyResearchData;
