import React, { ComponentType, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import Bugsnag from '@bugsnag/js';
import { OP_STEP_ATTACHMENTS } from 'app-constants/analytics';
import {
  ACCEPT_IMAGE_TYPES,
  AllowedMimeType,
  ALLOWED_DOCUMENT_TYPES,
  ALLOWED_IMAGE_TYPES,
  ALLOWED_VIDEO_TYPES,
  toAllowed,
} from 'app-constants/files';
import { Attachment, LocalAttachment } from 'common-types/attachments';
import {
  InjectedProps,
  withAttachmentsUpload,
} from 'containers/withAttachmentsUpload';
import isEqual from 'lodash/isEqual';
import { createLocalAttachment } from 'store/modules/attachments';
import { AttachmentsManagerView } from './AttachmentsManagerView';

export interface AttachmentsManagerProps extends InjectedProps {
  accept?: string;
  allowEmpty?: boolean;
  attachments?: Attachment[];
  defaultSelected?: number[];
  emptyLabel?: string;
  id: string;
  maxSelect?: number;
  title: string;

  onCancel?: () => void;
  onSelect?: (attachmentsIds: number[], hasRejected?: boolean) => any;
}

type selectedId = string | number;

const AttachmentsManagerComponent: ComponentType<AttachmentsManagerProps> = ({
  accept = ACCEPT_IMAGE_TYPES,
  allowEmpty = false,
  attachments: availableAttachments = [],
  defaultSelected = [],
  id,
  maxSelect = Infinity,
  title,
  //
  closeModal,
  onUpload,
  onCancel,
  onSelect,
}) => {
  const [pristine, setPristine] = useState<boolean>(true);
  const [localAttachments, setLocalAttachments] = useState<LocalAttachment[]>(
    []
  );
  const [selectedAttachmentsIds, setSelectedIds] =
    useState<Array<selectedId>>(defaultSelected);

  const canSave: boolean = Boolean(
    !pristine &&
      (!!selectedAttachmentsIds.length || allowEmpty) &&
      defaultSelected.length
      ? !isEqual(defaultSelected, selectedAttachmentsIds)
      : true
  );

  const allowed: AllowedMimeType[] = useMemo(() => toAllowed(accept), [accept]);
  const videoAllowed: boolean = useMemo(
    () => allowed.some((t) => ALLOWED_VIDEO_TYPES.includes(t)),
    [allowed]
  );
  const imagesAllowed: boolean = useMemo(
    () => allowed.some((t) => ALLOWED_IMAGE_TYPES.includes(t)),
    [allowed]
  );
  const pdfAllowed: boolean = useMemo(
    () => allowed.some((t) => ALLOWED_DOCUMENT_TYPES.includes(t)),
    [allowed]
  );

  const localFilesMap = useMemo(() => {
    return localAttachments.reduce((map, attachment) => {
      return {
        ...map,
        [attachment.id]: attachment.file,
      };
    }, {});
  }, [localAttachments]);

  const selectedLocalFilesIds = useMemo(
    () => selectedAttachmentsIds.filter((id) => !!localFilesMap[id]),
    [selectedAttachmentsIds, localFilesMap]
  );

  const filesToUpload = useMemo(
    () => selectedLocalFilesIds.map((id) => localFilesMap[id]),
    [selectedLocalFilesIds, localFilesMap]
  );

  // Attachment selected by User
  function handleAttachmentSelect(attachmentId: selectedId, type) {
    const isAllowedType = allowed.includes(type);
    const isSelected = selectedAttachmentsIds.includes(attachmentId);
    if (
      !isSelected &&
      (!isAllowedType || selectedAttachmentsIds.length >= maxSelect)
    )
      return;

    setPristine(false);

    if (isSelected) {
      setSelectedIds(
        selectedAttachmentsIds.filter((id) => id !== attachmentId)
      );
      return;
    }

    setSelectedIds([...selectedAttachmentsIds, attachmentId]);
  }

  // Attachments were uploaded by User should be automatically selected
  function selectInputAttachments(attachments: Array<LocalAttachment>) {
    const selectSlotsAvailable = maxSelect - selectedAttachmentsIds.length;
    let selectedIds: Array<number | string> = [];
    let attachmentsIds = attachments.map((a) => a.id);

    if (selectSlotsAvailable && attachments.length <= selectSlotsAvailable) {
      selectedIds = selectedAttachmentsIds.concat(attachmentsIds);
    } else {
      selectedIds = attachmentsIds;
    }

    setPristine(false);
    setSelectedIds(selectedIds);
  }

  function handleFilesInput(files: File[]): void {
    const newLocalAttachments = files.map(createLocalAttachment);
    setLocalAttachments([...localAttachments, ...newLocalAttachments]);
    selectInputAttachments(newLocalAttachments);
  }

  function handleCancel() {
    if (!onCancel) return;
    onCancel();

    ReactGA.event({
      category: OP_STEP_ATTACHMENTS,
      action: 'Cancel Attaching',
    });
  }

  async function handleAttachmentsSave() {
    if (!canSave || !onSelect) return;

    ReactGA.event({
      category: OP_STEP_ATTACHMENTS,
      action: 'Choose Attachments',
    });

    let selectedIds = [...selectedAttachmentsIds];

    if (!filesToUpload?.length) {
      closeModal();
      onSelect(selectedIds as number[]);
      return;
    }

    try {
      const uploadedAttachments = await onUpload(filesToUpload);
      const hasRejected =
        filesToUpload.length !== uploadedAttachments.filter(Boolean).length;

      const filesToAttachmentsIdsMap = (
        uploadedAttachments as unknown as (Attachment | null)[]
      ).reduce((result, attachment, index) => {
        if (!attachment) return result;
        const fileId = selectedLocalFilesIds[index];
        result[fileId] = attachment.id;
        return result;
      }, {} as { [index: string]: number });

      selectedIds = selectedIds
        .map((id) => {
          if (filesToAttachmentsIdsMap[id]) return filesToAttachmentsIdsMap[id];
          if (localFilesMap[id]) return null;
          return id;
        })
        .filter(Boolean) as number[];

      setSelectedIds(selectedIds);
      onSelect(selectedIds as number[], hasRejected);

      if (!hasRejected) {
        closeModal();
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  return (
    <>
      <AttachmentsManagerView
        accept={accept}
        availableAttachments={availableAttachments}
        canSave={canSave}
        hasFilesToUpload={!!filesToUpload?.length}
        id={id}
        imagesAllowed={imagesAllowed}
        localAttachments={localAttachments}
        maxSelect={maxSelect}
        onAttachmentSelect={handleAttachmentSelect}
        onCancel={handleCancel}
        onFilesInput={handleFilesInput}
        onSave={handleAttachmentsSave}
        pdfAllowed={pdfAllowed}
        selectedAttachmentsIds={selectedAttachmentsIds}
        title={title}
        videoAllowed={videoAllowed}
      />
    </>
  );
};

export const AttachmentsManager = withAttachmentsUpload(
  AttachmentsManagerComponent
);
