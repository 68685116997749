import {
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  READ_TEMPLATE_REQUEST,
  READ_TEMPLATE_SUCCESS,
  READ_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  CREATE_TEMPLATE_SNAPSHOT_REQUEST,
  CREATE_TEMPLATE_SNAPSHOT_SUCCESS,
  CREATE_TEMPLATE_SNAPSHOT_FAILURE,
} from 'store/modules/templates-library/types';
import {
  createSuccessActionCreator_DEPRECATED,
  createFailureActionCreator,
} from 'store/utils';
import { operationTemplateSchema } from 'api/v1/schemas';

export const createTemplateSnapshotRequest = templateId => ({
  type: CREATE_TEMPLATE_SNAPSHOT_REQUEST,
  payload: { templateId },
});

export const createTemplateSnapshotSuccess = createSuccessActionCreator_DEPRECATED(
  CREATE_TEMPLATE_SNAPSHOT_SUCCESS,
  'template',
  operationTemplateSchema
);

export const createTemplateSnapshotFailure = createFailureActionCreator(
  CREATE_TEMPLATE_SNAPSHOT_FAILURE
);

export const createTemplateRequest = template => ({
  type: CREATE_TEMPLATE_REQUEST,
  payload: { template },
});

export const createTemplateSuccess = createSuccessActionCreator_DEPRECATED(
  CREATE_TEMPLATE_SUCCESS,
  'template',
  operationTemplateSchema
);

export const createTemplateFailure = createFailureActionCreator(
  CREATE_TEMPLATE_FAILURE
);

export const readTemplateRequest = id => ({
  type: READ_TEMPLATE_REQUEST,
  payload: { id },
});

export const readTemplateSuccess = createSuccessActionCreator_DEPRECATED(
  READ_TEMPLATE_SUCCESS,
  'template',
  operationTemplateSchema
);

export const readTemplateFailure = createFailureActionCreator(
  READ_TEMPLATE_FAILURE
);

export const updateTemplateRequest = (id, update) => ({
  type: UPDATE_TEMPLATE_REQUEST,
  payload: { id, update },
});

export const updateTemplateSuccess = createSuccessActionCreator_DEPRECATED(
  UPDATE_TEMPLATE_SUCCESS,
  'template',
  operationTemplateSchema
);

export const updateTemplateFailure = createFailureActionCreator(
  UPDATE_TEMPLATE_FAILURE
);

export const deleteTemplateRequest = templateId => ({
  type: DELETE_TEMPLATE_REQUEST,
  payload: { templateId },
});

export const deleteTemplateSuccess = createSuccessActionCreator_DEPRECATED(
  DELETE_TEMPLATE_SUCCESS
);

export const deleteTemplateFailure = createFailureActionCreator(
  DELETE_TEMPLATE_FAILURE
);
