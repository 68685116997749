import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Table.module.scss';
import { Button, PageNumberButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { prefilledArray } from '../../utils';

const MAX_PAGES = 5;

export interface PaginationProps {
  pages?: number;
  currentPage?: number;
  onPageChange(page: number): void;
}

export const Pagination: FC<PaginationProps> = ({
  pages = 1,
  currentPage = 1,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const getVisiblePages = () => {
    const nextPage = currentPage + 1;
    const middle = Math.round(MAX_PAGES / 2);
    let pagesArray;

    const setStartPagesRange = () => {
      if (pages === 0) {
        pagesArray = prefilledArray(1);
      } else if (pages <= MAX_PAGES + 1) {
        pagesArray = prefilledArray(pages);
      } else {
        pagesArray = prefilledArray(MAX_PAGES + 1);
        pagesArray[MAX_PAGES] = '...';
        pagesArray[MAX_PAGES + 1] = pages;
      }

      return pagesArray;
    };

    const setPagesRange = (currentPageSelected) => {
      pagesArray = prefilledArray(MAX_PAGES + 2);

      pagesArray[1] = '...';
      pagesArray[MAX_PAGES] = '...';
      pagesArray[MAX_PAGES + 1] = pages;

      if (currentPageSelected) {
        pagesArray[middle - 1] = currentPageSelected - 1;
        pagesArray[middle] = currentPageSelected;
        pagesArray[middle + 1] = currentPageSelected + 1;
      }
      return pagesArray;
    };

    const setFinishPagesRange = () => {
      pagesArray = prefilledArray(MAX_PAGES + 1, pages - MAX_PAGES);

      pagesArray[0] = 1;
      pagesArray[1] = '...';

      return pagesArray;
    };

    if (nextPage > MAX_PAGES && nextPage < pages - 1) {
      return setPagesRange(currentPage);
    } else if (pages === MAX_PAGES + 1 || nextPage <= MAX_PAGES) {
      return setStartPagesRange();
    } else if (nextPage >= pages - middle) {
      return setFinishPagesRange();
    }

    return setStartPagesRange();
  };

  const setCurrentPage = (page: number) => {
    onPageChange(page);
  };

  const changePage = (event) => {
    const value = event.currentTarget.name;
    const page = parseInt(value, 10);

    if (value === '...' || currentPage === page) return;

    setCurrentPage(page);
  };

  const handleNextButtonClick = () => {
    const nextPage = currentPage + 1;

    if (currentPage === pages) return;

    setCurrentPage(nextPage);
  };

  const handlePrevButtonClick = () => {
    const prevPage = currentPage - 1;

    if (prevPage < 1) return;

    setCurrentPage(prevPage);
  };

  return (
    <Fragment>
      <Button
        type="button"
        variant="secondary"
        before={<Icon name="chevron-left" />}
        disabled={currentPage === 1}
        onClick={handlePrevButtonClick}
        className={styles.paginationBtn}
      >
        {t('previous')}
      </Button>
      <div>
        {getVisiblePages().map((page, index) =>
          typeof page === 'string' ? (
            <span className={styles.paginationDots} key={`${page}${index}}`}>
              {page}
            </span>
          ) : (
            <PageNumberButton
              key={page}
              name={page}
              active={currentPage === page}
              page={page}
              onClick={changePage}
            />
          )
        )}
      </div>
      <Button
        type="button"
        variant="secondary"
        disabled={currentPage === pages}
        after={<Icon name="chevron-right" />}
        onClick={handleNextButtonClick}
        className={styles.paginationBtn}
      >
        {t('next')}
      </Button>
    </Fragment>
  );
};
