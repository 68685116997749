import { id } from 'common-types/api';
import { Attachment } from 'common-types/attachments';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Multiline } from 'components/Multiline';
import { NotFound } from 'components/NotFound';
import { Text } from 'components/Typography/Text';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Gallery.module.scss';

interface Props {
  index: number;
  onFileUpdate?: (id: id) => void;
}

function Item({ fileUrl, name, description, mimeType }) {
  const { t } = useTranslation();

  switch (mimeType) {
    case 'application/pdf':
      return (
        <NotFound
          className={classes.notSupportedContainer}
          header={t('FiletypeNotSupported')}
          withBackButton={false}
          message={
            <>
              <p>{t('canYouDownloadTheFile')}&nbsp;</p>
              <Button
                component="a"
                href={fileUrl}
                rel="nofollow"
                data-e2e-download
                title={`Download the file ${name}`}
              >
                {t('Download')}
              </Button>
            </>
          }
        />
      );

    case 'video/mp4':
    case 'video/quicktime':
      return (
        <video src={fileUrl} className={classes.itemVideo} controls></video>
      );

    default:
      return (
        <img
          className={classes.itemImage}
          src={fileUrl}
          alt={description || ''}
        />
      );
  }
}

export const GalleryItem: ComponentType<Props & Attachment> = ({
  description,
  fileUrl,
  id,
  mimeType,
  name,
  onFileUpdate,
}) => {
  const { t } = useTranslation();

  const handleFileUpdate = () => {
    if (!onFileUpdate) return;
    onFileUpdate(id);
  };

  return (
    <div className={classes.itemContainer}>
      <div>
        <Item
          description={description}
          fileUrl={fileUrl}
          mimeType={mimeType}
          name={name}
        />
      </div>

      <div className="mt-1">
        <div>
          <p>
            <Text variant="caption">{name}</Text>
          </p>
          {description && (
            <p>
              <Multiline>{description}</Multiline>
            </p>
          )}
        </div>

        {Boolean(onFileUpdate) && (
          <Button
            type="button"
            variant="text"
            size="xs"
            onClick={handleFileUpdate}
          >
            <Icon name="edit" /> {t('Edit')}
          </Button>
        )}
      </div>
    </div>
  );
};

GalleryItem.displayName = 'GalleryItem';
