import React, { FC, HTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './MenuItemPreloader.module.scss';
import { MenuItem } from './MenuItem/MenuItem';

interface MenuItemPreloaderProps {}

export const MenuItemPreloader: FC<
  MenuItemPreloaderProps & HTMLAttributes<HTMLElement>
> = () => {
  return (
    <MenuItem
      label={
        <span className={classnames(styles.preloader, styles.preloaderMain)} />
      }
      sublabel={
        <span
          className={classnames(styles.preloader, styles.preloaderSecondary)}
        />
      }
    />
  );
};
