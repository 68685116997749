import { patientSchema } from 'api/v1/schemas';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {
  selectCurrentPageOperation,
  selectOperationPatientRisks as selectOperationPatientRisksUUIDS,
} from 'store/modules/entities/selectors/operations';
import {
  selectEntities,
  selectLibraryElements,
  selectPatients,
} from 'store/modules/entities/selectors/selectors';
import { createCollectionSelector } from 'store/utils';
import { isProductType } from 'utils/get-env';
import getOneOf from 'utils/get-one-of';

export const selectOperationPatient = createSelector(
  [selectCurrentPageOperation, selectPatients, selectEntities],
  (operation, patients, entities) => {
    if (!operation || !patients) return null;
    const patientId = isProductType('vet')
      ? getOneOf(operation, ['vetPatientId', 'vetPatient'], null)
      : getOneOf(operation, ['patientId', 'patient'], null);
    const patient = patients[patientId];
    if (patient?.isFake) return null;

    return denormalize(patient, patientSchema, entities);
  }
);

export const hasOperationPatient = createSelector(
  selectOperationPatient,
  Boolean
);

export const selectOperationPatientRisks = createSelector(
  [selectOperationPatientRisksUUIDS, selectLibraryElements],
  createCollectionSelector()
);
