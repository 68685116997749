import takeRight from 'lodash/takeRight';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Icon } from 'components';
import { selectRequestsLog } from '../../store/modules/api-requests';
import { parseAsyncActionType } from '../../store/utils';
import classes from './OPSaveState.module.scss';

interface Props {}

const useOpSaveState = () => {
  const [state, setState] = useState<null | 'SUCCESS' | 'FAILURE' | 'REQUEST'>(
    null
  );
  const { operationId } = useParams<{ operationId?: string }>();
  const isScheduled = !!operationId;
  const requestsLog = useSelector(selectRequestsLog);
  const activeStatus = useMemo(() => {
    const statusResult = takeRight<any>(requestsLog, 2).reduce(
      (newStatus, { status, actionType }) => {
        const parsed = parseAsyncActionType(actionType);
        if (!parsed) return newStatus;
        const { requestType } = parsed;
        const isUpdateRequest =
          requestType &&
          ['CREATE', 'UPDATE', 'DELETE', 'SUBMIT', 'UPLOAD'].includes(
            requestType
          );
        if (!isUpdateRequest) return newStatus;

        return status;
      },
      state
    );

    return statusResult;
  }, [requestsLog, state]);

  useEffect(() => {
    if (!isScheduled) {
      if (state) setState(null);
      return;
    }

    if (activeStatus !== state) setState(activeStatus);
  }, [activeStatus, isScheduled, state]);

  return state;
};

export const OPSaveState: ComponentType<Props> = () => {
  const state = useOpSaveState();
  const { t } = useTranslation();

  if (!state || state === 'REQUEST') return null;

  return (
    <div className={classes.container}>
      <strong>
        {state === 'SUCCESS' && (
          <span className="text-success">
            <Icon icon="check-circle" gapRight />
            {t('Saved')}
          </span>
        )}
        {state === 'FAILURE' && (
          <span className="text-error">
            <Icon icon="validation-error" gapRight />
            {t('Failed')}
          </span>
        )}
      </strong>
    </div>
  );
};

OPSaveState.displayName = 'OPSaveState';
