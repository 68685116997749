import namespace from './namespace';

export const CLEAN_NOTIFY_MESSAGE = `${namespace} CLEAN_NOTIFY_MESSAGE`;

export const LOGIN_REQUEST = `${namespace}LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${namespace}LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${namespace}LOGIN_FAILURE`;

export const RELOGIN_REQUEST = `${namespace}RELOGIN_REQUEST`;
export const RELOGIN_SUCCESS = `${namespace}RELOGIN_SUCCESS`;
export const RELOGIN_FAILURE = `${namespace}RELOGIN_FAILURE`;

export const FORGOT_PASSWORD_REQUEST = `${namespace}FORGOT_PASSWORD_REQUEST`;
export const FORGOT_PASSWORD_SUCCESS = `${namespace}FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAILURE = `${namespace}FORGOT_PASSWORD_FAILURE`;

export const LOGOUT_REQUEST = `${namespace}LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${namespace}LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${namespace}LOGOUT_FAILURE`;

export const LOGIN_CLEAR_ERROR = `${namespace}LOGIN_CLEAR_ERROR`;

export const READ_CURRENT_USER_DATA_REQUEST = `${namespace}READ_CURRENT_USER_DATA_REQUEST`;
export const READ_CURRENT_USER_DATA_SUCCESS = `${namespace}READ_CURRENT_USER_DATA_SUCCESS`;
export const READ_CURRENT_USER_DATA_FAILURE = `${namespace}READ_CURRENT_USER_DATA_FAILURE`;

export const READ_USER_REQUEST = `${namespace}READ_USER_REQUEST`;
export const READ_USER_SUCCESS = `${namespace}READ_USER_SUCCESS`;
export const READ_USER_FAILURE = `${namespace}READ_USER_FAILURE`;

export const REACTIVATE_ACCOUNT_REQUEST = `${namespace}REACTIVATE_ACCOUNT_REQUEST`;
export const REACTIVATE_ACCOUNT_SUCCESS = `${namespace}REACTIVATE_ACCOUNT_SUCCESS`;
export const REACTIVATE_ACCOUNT_FAILURE = `${namespace}REACTIVATE_ACCOUNT_FAILURE`;
