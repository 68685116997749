import React, { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

// Filter out all react-i18next props except `t` function to not apply invalid
// props while spreading them down onto simple elements
const withT_DEPRECATED = (
  Component: ComponentType<any>,
  wnsOpts?: any
): ComponentType<any> =>
  withTranslation<any>(wnsOpts)(
    ({
      t,
      i18n,
      i18nOptions,
      lng,
      tReady,
      reportNS,
      defaultNS,
      ...props
    }: any) => <Component t={t} {...props} />
  );

export default withT_DEPRECATED;
