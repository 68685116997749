import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { useAccountReactivation } from './utils';

import {
  Button,
  FormActions,
  FormBody,
  MessageBlock,
  SuccessLabel,
} from 'components';
import { useSearchParams } from 'hooks/useSearchParams';
import { LOGIN } from 'scenes/routes.enum';

/**
 * Renders deactivated account form
 */
export const AuthFormDeactivatedAccount: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [{ email }] = useSearchParams();

  const { loading, done, error, request } = useAccountReactivation(email);

  if (!email) return <Redirect to={LOGIN} />;

  return (
    <FormBody narrow>
      <MessageBlock type="warning">
        <p>{t('deactivatedEmailAccount', { email })}</p>
        <p>{t('hint:deactivatedAccountMessage')}</p>
      </MessageBlock>

      {error && (
        <MessageBlock type="error">{error?.message ?? error}</MessageBlock>
      )}

      <FormActions>
        <Button
          type="button"
          className="mr-05"
          variant="text"
          onClick={history.goBack}
        >
          {t('Back')}
        </Button>

        {done ? (
          <SuccessLabel>{t('successfullyRequested')}</SuccessLabel>
        ) : (
          <Button type="button" loading={loading} onClick={request}>
            {t('passwordRequestNew')}
          </Button>
        )}
      </FormActions>
    </FormBody>
  );
};
