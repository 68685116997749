import * as types from 'store/modules/operation-planning/types';
import {
  createFailureActionCreator,
  createSuccessActionCreator_DEPRECATED,
} from 'store/utils/create-action-creators';
import { vitalInformationSchema } from 'api/v1/schemas';

export const createVitalInfoRequest = (operationId, info) => ({
  type: types.CREATE_OPERATION_VITAL_INFO_REQUEST,
  payload: { operationId, info },
});

export const createVitalInfoSuccess = createSuccessActionCreator_DEPRECATED(
  types.CREATE_OPERATION_VITAL_INFO_SUCCESS,
  'info',
  vitalInformationSchema
);

export const createVitalInfoFailure = createFailureActionCreator(
  types.CREATE_OPERATION_VITAL_INFO_FAILURE
);

export const updateVitalInfoRequest = (operationId, info) => ({
  type: types.UPDATE_OPERATION_VITAL_INFO_REQUEST,
  payload: { operationId, info },
});

export const updateVitalInfoSuccess = createSuccessActionCreator_DEPRECATED(
  types.UPDATE_OPERATION_VITAL_INFO_SUCCESS,
  'info',
  vitalInformationSchema
);

export const updateVitalInfoFailure = createFailureActionCreator(
  types.UPDATE_OPERATION_VITAL_INFO_FAILURE
);

export const readVitalInfoRequest = (operationId) => ({
  type: types.READ_OPERATION_VITAL_INFO_REQUEST,
  payload: { operationId },
});

export const readVitalInfoSuccess = createSuccessActionCreator_DEPRECATED(
  types.READ_OPERATION_VITAL_INFO_SUCCESS,
  'info',
  vitalInformationSchema
);

export const readVitalInfoFailure = createFailureActionCreator(
  types.READ_OPERATION_VITAL_INFO_FAILURE
);
