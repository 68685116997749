import React, { ComponentType } from 'react';
import AttachmentPlaceholder from './AttachmentPlaceholder';
import { AttachmentsList } from './AttachmentsList';
import classes from './OperationStepAttachments.module.scss';

interface Props {
  attachments?: number[];
  id?: string;
  onRemove?: (attachmentId: number) => void;
  onImageClick?: (attachmentId: number) => void;
  onPlaceholderClick?: () => void;
}

export const OperationStepAttachmentsForm: ComponentType<Props> = ({
  attachments,
  onRemove,
  onImageClick,
  onPlaceholderClick,
}) => {
  if (!attachments?.length) {
    return (
      <AttachmentPlaceholder
        className={classes.imgPlaceholder}
        onClick={onPlaceholderClick}
      />
    );
  }

  return (
    <AttachmentsList
      attachments={attachments}
      onImageClick={onImageClick}
      onRemove={onRemove}
    />
  );
};
