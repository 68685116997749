import Axios from 'axios';
import { APIv1 } from 'api/instance';

export const CancelToken = Axios.CancelToken;

let cancelRequest = null;
export const fileUploadCancellation = () => cancelRequest;

type FileResponse = {
  fields: {
    key: string;
    bucket: string;
    policy: string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    'x-amz-signature': string;
  };
  url: string;
};
export const createFile_DEPRECATED = ({
  mimeType,
  extension,
}: {
  mimeType: any;
  extension: string;
}) =>
  APIv1.post<FileResponse, FileResponse>('/files', {
    mimeType,
    extension,
  });

export const readFile = (folder, filename) =>
  APIv1.get(`files/${folder}/${filename}`);

export const uploadToS3 = ({ url }, formData, config) =>
  Axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
