import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BigCalendar from '@rusinov/react-big-calendar';
import moment from 'moment';
import 'moment/locale/de';

import { FIRST_DAY_OF_THE_WEEK } from 'app-constants';

import { Toolbar } from './Toolbar';
import { useTranslation } from 'react-i18next';
import { Language } from 'common-types/common';
import { selectUserLanguage } from 'store/modules/auth';

/**
 * Localizes calendar content messages
 */
export const useCalendarMessages = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      agenda: t('Agenda'),
      allDay: t('allDay'),
      date: t('Date'),
      day: t('Day'),
      event: t('Event'),
      month: t('Month'),
      next: t('Next'),
      noEventsInRange: t('thereAreNoEventsInThisRange'),
      previous: t('Back'),
      time: t('Time'),
      today: t('Today'),
      tomorrow: t('Tomorrow'),
      week: t('Week'),
      work_week: t('workWeek'),
      yesterday: t('Yesterday'),
      showMore: (total: string | number) => t('totalMore', { total }),
    }),
    [t]
  );
};

/**
 * Setup calendar language depend on user's language
 */
export const useCalendarLocalizer = () => {
  const lang = useSelector<any, Language>(selectUserLanguage);

  return useMemo(() => {
    moment.locale(lang, {
      // set start day of the week to be monday
      week: { dow: FIRST_DAY_OF_THE_WEEK },
    });

    return BigCalendar.momentLocalizer(moment);
  }, [lang]);
};

/**
 * Date formatters for BigCalendar
 */
export const defaultFormats = {
  dayFormat: (date, culture, localizer) => {
    return localizer.format(date, 'DD MMMM', culture);
  },

  dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
    const areStartEndSameMonth = moment(start).isSame(end, 'month');
    const areStartEndSameYear = moment(start).isSame(end, 'year');
    const isCurrentYear =
      areStartEndSameYear && moment(new Date()).isSame(end, 'year');

    const startLabel = `${localizer.format(
      start,
      areStartEndSameMonth ? 'DD' : 'DD MMMM',
      culture
    )}${
      areStartEndSameYear ? '' : ' ' + localizer.format(start, 'YYYY', culture)
    }`;

    const endLabel = `${localizer.format(end, 'DD MMMM', culture)}${
      isCurrentYear ? '' : ` ${localizer.format(end, 'YYYY', culture)}`
    }`;

    return `${startLabel} — ${endLabel}`;
  },
};

/**
 * Defaul calendar toolbar component
 */
export const defaultComponents = { toolbar: Toolbar };

/**
 * Calendar toolbar navigation step
 */
export enum NAVIGATION_STEPS {
  DATE = 'DATE',
  NEXT = 'NEXT',
  PREV = 'PREV',
  TODAY = 'TODAY',
}
