import React, { ComponentType, HTMLAttributes, ReactNode } from 'react';
import classes from './Popover.module.scss';

interface Props {
  text?: ReactNode;
}

export const Popover: ComponentType<
  Props & HTMLAttributes<HTMLSpanElement>
> = ({ children, text }) => {
  return (
    <span className={classes.popover}>
      <span className={classes.text}>{text}</span>
      {children}
    </span>
  );
};

Popover.displayName = 'Popover';
