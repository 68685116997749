import React, { FC, HTMLAttributes } from 'react';
import classnames from 'classnames';

import { ListItem } from './ListItem';
import styles from './List.module.scss';
import { ListItemProps } from './ListItem';

export interface ListHeaderProps {
  className?: string;
}

export const ListHeader: FC<
  ListHeaderProps & ListItemProps & HTMLAttributes<HTMLElement>
> = ({ className = '', ...restProps }) => {
  return (
    <ListItem
      className={classnames(styles.header, { [className]: className })}
      {...restProps}
    />
  );
};
