import { bool, number, string, shape, object } from 'prop-types';
import { idTypes, translatableString } from './common';
import { diagnosesTypes } from './library';

export const timeoutsTypes = {
  id: idTypes,
  content: translatableString,
};

export const patientTypes = {
  addressId: idTypes,
  diagnoses: diagnosesTypes,
  givenName: string,
  id: idTypes,
  patientId: string,
  surname: string,
};

export const templateTypes = {
  id: idTypes,
  name: translatableString,
  description: translatableString,
  categoryId: idTypes,
};

export const operationBriefingNoteTypes = {
  id: idTypes,
  content: string,
};

export const operationTypes = {
  assistantId: idTypes,
  id: idTypes,
  leadId: idTypes,
  templateId: idTypes,
  patientId: idTypes,
  startsAt: string,
  title: string,
  totalEstimation: number,
  notes: shape(operationBriefingNoteTypes),
};

export const reportTypes = {
  assistant: string,
  bloodLoss: string,
  createdAt: string,
  createdBy: idTypes,
  diagnosis: string,
  id: idTypes,
  indication: string,
  isDeleted: bool,
  isFinished: bool,
  lead: string,
  notes: string,
  diagnosisNotes: object,
  operationId: idTypes,
  patient: string,
  patientAddress: string,
  patientBirthDate: string,
  patientId: string,
  reportData: string,
  reportDate: string,
  signature: string,
  supervisor: string,
  technique: string,
  title: string,
  totalDuration: string,
  updatedAt: string,
  updatedBy: idTypes,
};
