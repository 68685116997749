import React, {
  ComponentType,
  HTMLAttributes,
  useRef,
  useEffect,
  Ref,
} from 'react';
import cn from 'classnames';
import classes from '../InputString.module.scss';

interface Props {
  defaultInputSize?: number;
  error?: string;
  maxSize?: number;
}

const INPUT_MIN_SIZE = 10;
const INPUT_MAX_SIZE = 30;

export const AutosizeInput: ComponentType<
  Props & HTMLAttributes<HTMLInputElement>
> = ({
  className,
  onKeyPress,
  defaultInputSize = INPUT_MIN_SIZE,
  maxSize = INPUT_MAX_SIZE,
  error,
  ...inputProps
}) => {
  const minSize: number = Math.min(defaultInputSize, maxSize) || INPUT_MIN_SIZE;
  const inputRef: Ref<HTMLInputElement> = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = inputRef?.current;
    if (minSize && input?.size && input.size !== minSize) {
      input.size = minSize;
    }
  }, [minSize]);

  const handleKeyPress = (event): void => {
    const input = event.target;
    const length = input.value.length;
    input.size =
      length > minSize ? (length <= maxSize ? length : maxSize) : minSize;

    if (typeof onKeyPress === 'function') onKeyPress(event);
  };

  return (
    <input
      ref={inputRef}
      className={cn(className, classes.input, { [classes.error]: error })}
      {...inputProps}
      onKeyPress={handleKeyPress}
    />
  );
};
