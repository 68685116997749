import { Component } from 'react';
import { func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { withRouterPropTypes } from 'shared-prop-types';
import {
  DASHBOARD,
  HISTORY,
  INCIDENTS_REPORTS,
  NEW_OPERATION,
  NEW_TEMPLATE,
  ASSESSMENTS,
  OPERATION_PAGES,
  RESEARCH,
  SETTINGS,
  STYLEGUIDE,
  TEMPLATES_LIBRARY,
  ASSESSMENT_FORMS,
  QUALIFICATION_FORMS,
  QUALIFICATION_TABLE,
} from 'scenes/routes.enum';
import withT from 'containers/withT';

export class PageTitle extends Component {
  static _propTypes = {
    ...withRouterPropTypes,
    children: func.isRequired,
    t: func.isRequired,
  };

  getPageTitle = () => {
    const {
      t,
      location: { pathname },
    } = this.props;
    let pageTitle = '';
    const matchLocationWithPath = (path) => pathname.indexOf(path) === 0;
    const pageTitles = [
      { path: NEW_OPERATION, title: t('newOperation') },
      { path: OPERATION_PAGES, title: t('editOperation') },
      { path: TEMPLATES_LIBRARY, title: t('Library') },
      { path: DASHBOARD, title: t('Dashboard') },
      { path: HISTORY, title: t('History') },
      {
        path: ASSESSMENT_FORMS,
        title: t('assessmentForms.title'),
      },
      { path: ASSESSMENTS, title: t('assessmentsCollection') },
      { path: NEW_TEMPLATE, title: t('newTemplate') },
      { path: INCIDENTS_REPORTS, title: t('Incidents') },
      { path: SETTINGS, title: t('Settings') },
      { path: RESEARCH, title: t('Research') },
      { path: STYLEGUIDE, title: t('styleGuide') },
      { path: QUALIFICATION_TABLE, title: t('qualification.qualification') },
      {
        path: QUALIFICATION_FORMS,
        title: t('qualification.qualificationForm'),
      },
    ];

    pageTitles.some(({ path, title }) => {
      const match = matchLocationWithPath(path);
      if (match) {
        pageTitle = title;
      }
      return match;
    });

    return pageTitle;
  };

  get pageTitle() {
    return this.getPageTitle();
  }

  render() {
    const { children } = this.props;
    return children({ pageTitle: this.pageTitle });
  }
}

const enhancer = compose(withT, withRouter);

export default enhancer(PageTitle);
