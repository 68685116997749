import { MAX_STEP_ATTACHMENTS } from 'app-constants/files';
import { uuid } from 'common-types/api';
import {
  OperationStep as OperationStepType,
  OperationStepInput,
} from 'common-types/library';
import React, { ComponentType, memo, ReactElement } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ElementRowInner } from './ElementRowInner';

interface Props {
  barColumn?: ReactElement;
  element: OperationStepType;
  index: number;
  onAddToLibrary?(element: OperationStepType): void;
  onChange?(element: OperationStepType, update: OperationStepInput): void;
  onDelete?(element: OperationStepType): void;
  onAttachmentRequest?(uuid: uuid): void;
  onTriggerGallery?(id: number): void;
}

export const ElementRow: ComponentType<Props> = memo(
  ({
    barColumn,
    element,
    index,
    onAddToLibrary,
    onAttachmentRequest,
    onChange,
    onDelete,
    onTriggerGallery,
  }) => {
    const handleAttachmentChange = (attachments) => {
      const existingAttachments = element.attachments;
      const noAttachments = !existingAttachments?.length;
      const hasMaxAttachments =
        existingAttachments?.length === MAX_STEP_ATTACHMENTS;
      const canAddAttachment =
        (existingAttachments?.length ?? 0) + (attachments?.length ?? 0) <=
        MAX_STEP_ATTACHMENTS;

      if (noAttachments || hasMaxAttachments) {
        onChange?.(element, { attachments });
      } else if (canAddAttachment) {
        onChange?.(element, {
          attachments: existingAttachments.concat(attachments),
        });
      }
    };

    const handleAttachmentRemove = (attachmentId: number) => {
      onChange?.(element, {
        attachments: (element?.attachments ?? []).filter(
          (id) => attachmentId !== id
        ),
      });
    };

    const handleAttachmentRequest = () => {
      if (!onAttachmentRequest) return;
      onAttachmentRequest(element.uuid);
    };

    return (
      <Draggable draggableId={String(element.uuid)} index={index}>
        {(draggableProvided, { isDragging }) => {
          return (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
            >
              <ElementRowInner
                isDragging={isDragging}
                element={element}
                barColumn={barColumn}
                draggableHandleProps={draggableProvided.dragHandleProps}
                onChange={onChange}
                onDelete={onDelete}
                onAddToLibrary={onAddToLibrary}
                onAttachmentRemove={handleAttachmentRemove}
                onAttachmentRequest={handleAttachmentRequest}
                onAttachmentsChange={handleAttachmentChange}
                onTriggerGallery={onTriggerGallery}
              />
            </div>
          );
        }}
      </Draggable>
    );
  }
);
