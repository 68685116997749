import React, { forwardRef } from 'react';

import { TextBox } from '../../Input';
import { FormInputBase } from '../FormInputBase';

export const FormTextBox = forwardRef<any, any>((props, ref) => {
  return (
    <FormInputBase formInputType="text-box" {...props}>
      {({ getProps }) => (
        <TextBox ref={ref} {...getProps()}>
          {props.children}
        </TextBox>
      )}
    </FormInputBase>
  );
});
