import { APIv1 } from 'api/instance';
import { Report } from 'common-types/report';
import { reportSchema } from './schemas';
import { normalize } from 'utils/normalize';

const normalizeReportResponse = (reportResponse) =>
  normalize(reportResponse, reportSchema);

export const readOperationReport = (operationId: number) =>
  APIv1.get(`/operations/${operationId}/report`).then(normalizeReportResponse);

export const updateOperationReport = (operationId: number, update: Report) =>
  APIv1.put(`/operations/${operationId}/report`, update);

export const patchOperationReport = (operationId: number, update: object) =>
  APIv1.patch(`/operations/${operationId}/report`, update).then(
    normalizeReportResponse
  );
