import React, { ComponentType, FC, memo, ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import Downshift, { Callback } from 'downshift';
import { Options as PopperOptions } from '@popperjs/core';

import styles from './Dropdown.module.scss';
import { ElementType } from 'react';
import { Button } from 'components/Button';
import { Panel } from 'components/Panel';

export interface DropdownProps extends Partial<PopperOptions> {
  /**
   * Component to handle opened/closed state
   */
  buttonComponent?: ComponentType<Record<string, string>> | ElementType;
  /**
   * Component shown in dropdown
   */
  children: (args: { closeMenu: (cb?: Callback) => void }) => ReactNode;
}

/**
 * Renders button component which will handle opened/closed state for dropdown
 * Renders passed children in dropdown
 */
export const Dropdown: FC<DropdownProps> = memo(function Dropdown({
  buttonComponent: ButtonComponent = Button,
  children,
  placement = 'bottom-start',
  ...options
}) {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    refElement,
    popperElement,
    { placement, ...options }
  );

  return (
    <Downshift>
      {({ isOpen, getToggleButtonProps, getMenuProps, closeMenu }) => (
        <span className={styles.wrapper}>
          <span ref={setRefElement} className={styles.reference}>
            <ButtonComponent {...getToggleButtonProps()} />
          </span>

          {isOpen && (
            <Panel
              shadow="2"
              data-placement={placement}
              {...getMenuProps({
                ref: setPopperElement,
                style: popperStyles.popper,
                className: styles.menu,
                ...attributes.popper,
              })}
            >
              {children({ closeMenu })}
            </Panel>
          )}
        </span>
      )}
    </Downshift>
  );
});
