import { createStateGetter } from 'store/utils';
import { createSelector } from 'reselect';

export const selectUploadsList = createStateGetter(
  'filesUploadProgress.uploadsList',
  []
);

export const selectUploadsMap = createStateGetter(
  'filesUploadProgress.uploadsMap',
  {}
);

export const selectOngoingFilesUploads = createSelector(
  [selectUploadsList, selectUploadsMap],
  (uploadsList, uploadsMap) => uploadsList.map((id) => uploadsMap[id])
);

export const selectFailedFilesUpload = createSelector(
  selectOngoingFilesUploads,
  (uploads) => uploads.filter((upload) => !!upload.error)
);

export const selectUploadErrorMessage = createSelector(
  selectUploadsMap,
  (map) => map && Object.values(map).find((progress) => progress?.error)
);
