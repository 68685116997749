import React, { ChangeEvent, FC } from 'react';
import classnames from 'classnames';

import styles from './RadioSwitch.module.scss';

interface RadioSwitchProps {
  id?: string;
  name?: string;
  options: {
    label: string;
    value: string | number | null;
  }[];
  size?: 'md' | 'sm';
  value?: string | number | readonly string[] | null;
  defaultValue?: number | string | readonly string[];
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const RadioSwitch: FC<RadioSwitchProps> = ({
  className,
  name,
  options,
  value = null,
  size = 'md',
  defaultValue = null,
  onChange,
  disabled,
  ...radioProps
}) => {
  if (!options) return null;

  return (
    <div
      className={classnames(className, styles.container, {
        [styles[`size-${size}`]]: size,
      })}
    >
      {options.map((option) => {
        const id = `${name}-${option.value}`;
        let defaultChecked;
        let checked: boolean | undefined = false;

        if (defaultValue && defaultValue === option.value) {
          defaultChecked = true;
          checked = undefined;
        } else if (option.value === value) {
          checked = true;
        }

        return (
          <label key={id} htmlFor={id} className={styles.label}>
            <input
              {...radioProps}
              className={styles.input}
              id={id}
              name={name}
              type="radio"
              value={option.value || ''}
              checked={checked}
              defaultChecked={defaultChecked}
              onChange={onChange}
              disabled={disabled}
            />
            <span className={styles.labelText}>{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};
