import React, { ComponentType } from 'react';
import cn from 'classnames';
import classes from './IconEmphasized.module.scss';
import { Icon } from '../Icon';

interface Props {
  type: 'info' | 'warning' | 'success' | 'error';
  icon?: string;
  name?: string;
}

export const IconEmphasized: ComponentType<Props> = (props) => {
  const { type, ...iconProps } = props;

  return (
    <span
      className={cn(classes.iconEmph, {
        [classes.info]: type === 'info',
        [classes.warning]: type === 'warning',
        [classes.success]: type === 'success',
        [classes.error]: type === 'error',
      })}
    >
      <Icon {...iconProps} />
    </span>
  );
};

IconEmphasized.displayName = 'IconEmphasized';
