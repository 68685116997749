import { i18n } from '../translations/i18n-instance';

import { addMethod, array } from 'yup';

function uniqValidator(message = i18n.t('cannotSelectOneItemTwice'), params) {
  return this.test({
    message: message,
    name: 'uniq',
    params,
    exclusive: true,
    test(value) {
      const schema = this;
      const { createError, path } = schema;
      const testValue = [...value];
      const duplicates = testValue.filter(
        (item, index) => testValue.indexOf(item) !== index
      );

      if (!duplicates?.length) return true;

      const [duplicate] = duplicates;
      const duplicateIndex = testValue.lastIndexOf(duplicate);
      const error = createError({
        path: `${path}.${duplicateIndex}`,
        message,
      });

      return error;
    },
  });
}

addMethod(array, 'uniq', uniqValidator);

export * from 'yup';
