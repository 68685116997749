import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import React, {
  Children,
  cloneElement,
  FC,
  FocusEvent,
  isValidElement,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConfirmDialog.module.scss';

interface ConfirmDialogProps {
  /**
   * Confirm button label (Yes | Confirm)
   */
  confirmButtonLabel?: string;
  /**
   * Decline button label (No | Decline)
   */
  declineButtonLabel?: string;
  /**
   * Dialog title
   */
  title?: string;
  /**
   * Description of the performed action
   */
  description?: ReactNode;
  /**
   * Confirm handler which will be resolved after click on confirm button
   */
  onConfirm?: (value?: string | null) => void;
  /**
   * Decline handler which will be resolved after click on decline button
   */
  onDecline?: () => void;
  /**
   * Close handler
   */
  onClose?: () => void;
  /**
   * Flag to enable/disable child event listener
   */
  listenChildEvent?: boolean;
  /**
   * Flag to be passed to opened state after child event fired
   */
  openOnChildEvent?: boolean;
  /**
   * Initial opened state
   */
  defaultOpened?: boolean;
  /**
   * Children event handler
   */
  childEvent?: 'onClick' | 'onBlur' | 'onKeyDown';
}

/**
 * Renders dialog component with children which will be opened on one of selected child event
 */
export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  onConfirm,
  onDecline,
  onClose,
  children,
  title,
  description,
  confirmButtonLabel,
  declineButtonLabel,
  listenChildEvent = true,
  openOnChildEvent = true,
  defaultOpened = false,
  childEvent = 'onClick',
}) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(defaultOpened);
  const [loading, setLoading] = useState(false);
  const [childValue, setChildValue] = useState<string | null>(null);

  const onCloseHandler = useCallback(() => {
    onClose?.();
    setOpened(false);
    setLoading(false);
  }, [onClose]);

  const onDeclineHandler = useCallback(() => {
    onDecline?.();
    setOpened(false);
    onCloseHandler();
  }, [onDecline, onCloseHandler]);

  const onConfirmHandler = useCallback(() => {
    if (onConfirm) {
      setLoading(false);
      onConfirm(childValue);
      onCloseHandler();
    }
  }, [childValue, onCloseHandler, onConfirm]);

  // children extended with childEvent listener
  const extendedChildren = useMemo(() => {
    if (listenChildEvent) {
      const onChildEventHandler = (
        ev:
          | MouseEvent<HTMLElement>
          | FocusEvent<HTMLElement>
          | KeyboardEvent<HTMLElement>
      ) => {
        const target =
          (ev.currentTarget as HTMLElement & { value?: string }) ||
          (ev.target as HTMLElement & { value?: string });

        setChildValue(target?.value || null);
        setOpened(openOnChildEvent);
      };

      return Children.map(children, (child) => {
        return isValidElement(child)
          ? cloneElement(child, { [childEvent]: onChildEventHandler })
          : null;
      });
    }

    return null;
  }, [childEvent, children, listenChildEvent, openOnChildEvent]);

  return (
    <>
      {extendedChildren || children}

      <Modal opened={opened} title={title || t('performTheAction')}>
        {description && <p>{description}</p>}

        <div className={styles.actions}>
          <Button variant="secondary" onClick={onDeclineHandler}>
            {declineButtonLabel || t('no')}
          </Button>
          <Button loading={loading} onClick={onConfirmHandler}>
            {confirmButtonLabel || t('yes')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
