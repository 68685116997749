import storeInstance from './store';
import { id } from 'common-types/api';

const defaultSettings = {
  expandHistoryStatistics: true,
};

class Settings {
  store: {
    get: (key: string) => any;
    set: (key: string, value: any) => void;
  };

  constructor(storeInstance) {
    this.store = storeInstance;
  }

  initialize(userId: id) {
    if (!this.settings) {
      return this.setupSettings(userId);
    }

    const { userId: settingsUserId } = this.settings;
    if (userId !== settingsUserId) {
      return this.setupSettings(userId);
    }

    return true;
  }

  setupSettings(userId: id): boolean {
    this.settings = { ...defaultSettings, userId };
    return true;
  }

  get settings() {
    return this.store.get('localSettings');
  }

  set settings(value) {
    this.store.set('localSettings', { ...this.settings, ...value });
  }

  get(key) {
    return this.settings[key];
  }

  set(key, value) {
    this.settings = { [key]: value };
  }
}

export default new Settings(storeInstance);
