import { materialsGroupSchema, operationMaterialsSchema } from 'api/v1/schemas';
import { get, isEmpty } from 'lodash';
import omit from 'lodash/omit';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { selectOperationMaterialsById } from 'store/modules/entities/selectors/operations';
import {
  selectEntities,
  selectLibraryElements,
  selectOperationMaterials,
  selectMaterialsGroups,
  selectAttachments,
} from 'store/modules/entities/selectors/selectors';
import { createStateGetter, createEntitiesListSelector } from 'store/utils';

const createMaterialsSelector = (category) =>
  createSelector(
    [selectOperationMaterialsById, selectLibraryElements],
    (materials, libraryElements) => {
      const elements = get(materials, category, null);
      if (!Array.isArray(elements)) return [];

      return elements
        .map((uuid) => get(libraryElements, uuid, null))
        .filter(Boolean);
    }
  );

export const selectOperationEquipmentById = createMaterialsSelector(
  'equipment'
);
export const selectOperationInstrumentsById = createMaterialsSelector(
  'instruments'
);
export const selectOperationImplantsById = createMaterialsSelector('implants');

export const selectOperationMaterialsUUIDs = createSelector(
  [selectOperationMaterialsById],
  (materials) => {
    let selectedList = [];
    if (isEmpty(materials)) {
      return selectedList;
    }
    const { equipment, implants, instruments } = materials;
    equipment && (selectedList = [...selectedList, ...equipment]);
    implants && (selectedList = [...selectedList, ...implants]);
    instruments && (selectedList = [...selectedList, ...instruments]);
    return selectedList;
  }
);

export const selectOperationMaterialsList = createSelector(
  [
    selectOperationEquipmentById,
    selectOperationImplantsById,
    selectOperationInstrumentsById,
  ],
  (equipment, implants, instruments) => ({
    equipment,
    implants,
    instruments,
  })
);

export const createNormalizedOperationMaterialsSelector = (operationId) =>
  createSelector(
    selectOperationMaterials,
    (materials) => materials?.[operationId] ?? null
  );

export const createOperationMaterialsSelector = (operationId) => {
  const normalizedMaterialsSelector = createNormalizedOperationMaterialsSelector(
    operationId
  );

  return createSelector(
    [normalizedMaterialsSelector, selectEntities],
    (operationMaterials, entities) => {
      if (!operationMaterials || !entities) return null;
      return omit(
        denormalize(operationMaterials, operationMaterialsSchema, entities),
        ['operationId', 'materialsGroups']
      );
    }
  );
};

export const createOperationMaterialsGroupsIdsSelector = (operationId) => {
  const selectOperationMaterials = createNormalizedOperationMaterialsSelector(
    operationId
  );
  return createSelector(
    selectOperationMaterials,
    createStateGetter('materialsGroups', [])
  );
};

export const createOperationMaterialsGroupsSelector = (operationId) => {
  const groupsIdsSelector = createOperationMaterialsGroupsIdsSelector(
    operationId
  );

  return createSelector(
    [groupsIdsSelector, selectEntities],
    (groups, entities) => denormalize(groups, [materialsGroupSchema], entities)
  );
};

export const createOperationMaterialsGroupsAttachmentsSelector = (
  operationId
) => {
  const groupsIdsSelector = createOperationMaterialsGroupsIdsSelector(
    operationId
  );

  return createSelector(
    [groupsIdsSelector, selectMaterialsGroups, selectAttachments],
    (groupsIds, groups, attachments) => {
      if (!groupsIds && !groups) return null;
      const attachmentsIds = groupsIds.reduce((result, groupId) => {
        const attachmentsIds = groups?.[groupId]?.attachments ?? [];
        return result.concat(attachmentsIds);
      }, []);

      const selectedAttachments = attachmentsIds.map((id) => attachments[id]);
      return selectedAttachments;
    }
  );
};

export const createMaterialsGroupSelector = (groupId) => {
  return createSelector(
    [selectMaterialsGroups],
    createStateGetter(groupId, null)
  );
};

export const createMaterialsGroupAttachmentsIdsSelector = (groupId) => {
  const groupSelector = createMaterialsGroupSelector(groupId);
  return createSelector(groupSelector, createStateGetter('attachments', []));
};

export const createMaterialsGroupAttachmentsSelector = (groupId) => {
  const selectAttachmentsIds = createMaterialsGroupAttachmentsIdsSelector(
    groupId
  );

  return createSelector(
    [selectAttachments, selectAttachmentsIds],
    createEntitiesListSelector([])
  );
};
