import i18n from 'translations/i18n-instance';
import { string, object } from 'yup';

/*
  In case of any changes to PasswordSchema note that there is a settings password reset
  component with duplicated functionality (validatePassword)
*/
const PasswordSchema = string().required(
  i18n.t('validation:invalidEmptyPassword')
);

export const validatePassword = {
  minLength: (data) => data.length >= 6,
  uppercase: (data) => Boolean(data.match(/(?=.*[A-Z])/)),
  lowercase: (data) => Boolean(data.match(/(?=.*[a-z])/)),
  symbol: (data) =>
    Boolean(data.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\^\d+$/]/)),
};

/* ****************************** */

export const LoginSchema = object().shape({
  email: string()
    .email(i18n.t('validation:invalidEmailFormat'))
    .required(i18n.t('validation:invalidEmailFormat')),
  password: PasswordSchema,
});

export const ReloginSchema = object().shape({
  password: PasswordSchema,
});

export const ResetPasswordSchema = object().shape({
  email: string()
    .email(i18n.t('validation:invalidEmailFormat'))
    .required(i18n.t('validation:emailFieldCannotBeEmpty')),
});

export const passwordSchema = string()
  .min(6, i18n.t('validation:6PlusCharacters'))
  .matches(/(?=.*[A-Z])/, i18n.t('validation:uppercase'))
  .matches(/(?=.*[a-z])/, i18n.t('validation:lowercase'))
  .matches(
    /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\^\d+$/]/,
    i18n.t('validation:numericAndOrSpecialCharacters')
  )
  .required();

export const SettingsPasswordSchema = object().shape({
  currentPassword: string().required(
    i18n.t('validation:currentPasswordIsRequiredField')
  ),
  password: passwordSchema,
});
