import React, { forwardRef } from 'react';

import Input from '../Input';
import { FormInputBase } from './FormInputBase';

export const FormInput = forwardRef<any, any>((props, ref) => {
  return (
    <FormInputBase formInputType="input" {...props}>
      {({ getProps }) => <Input ref={ref} {...getProps()} />}
    </FormInputBase>
  );
});
