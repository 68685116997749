import React, { ReactElement } from 'react';
import cn from 'classnames';

import { TranslatableString as TString } from 'common-types/common';
import { id } from '../../common-types/api';
import { TranslatableString } from '../TranslatableString/TranslatableString';
import classes from './TemplateCategory.module.scss';

interface Props {
  id: id;
  name: TString;
}

export const TemplateCategoryLabel = ({ id, name }: Props): ReactElement => {
  return (
    <span
      className={cn('TemplateCategory', classes.categoryName)}
      data-e2e-template-id={`template-category-${id}`}
    >
      <TranslatableString string={name} />
    </span>
  );
};

TemplateCategoryLabel.displayName = 'TemplateCategoryLabel';
