import * as actionTypes from './types';
import * as APIv1 from 'api/v1';
import { makeThunkFromTypes } from 'store/utils/make-thunk';
import { MaterialsGroup } from 'common-types/materials';

export const updateOperationMaterialsOfGroup = makeThunkFromTypes(
  APIv1.updateOperationMaterialsOfGroup,
  {
    request: actionTypes.UPDATE_OPERATION_MATERIALS_OF_GROUP_REQUEST,
    success: actionTypes.UPDATE_OPERATION_MATERIALS_OF_GROUP_SUCCESS,
    failure: actionTypes.UPDATE_OPERATION_MATERIALS_OF_GROUP_FAILURE,
  }
);

export const addOperationMaterialsGroupAttachments = makeThunkFromTypes(
  APIv1.addOperationMaterialsGroupAttachments,
  {
    request: actionTypes.ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_REQUEST,
    success: actionTypes.ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_SUCCESS,
    failure: actionTypes.ADD_OPERATION_MATERIALS_GROUP_ATTACHMENTS_FAILURE,
  }
);

export const updateTemplateMaterialsOfGroup = makeThunkFromTypes(
  APIv1.updateTemplateMaterialsOfGroup,
  {
    request: actionTypes.ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_REQUEST,
    success: actionTypes.ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_SUCCESS,
    failure: actionTypes.ADD_TEMPLATE_MATERIALS_GROUP_INSTRUMENTS_FAILURE,
  }
);

export const addTemplateMaterialsGroupAttachments = makeThunkFromTypes<MaterialsGroup>(
  APIv1.addTemplateMaterialsGroupAttachments,
  {
    request: actionTypes.ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_REQUEST,
    success: actionTypes.ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_SUCCESS,
    failure: actionTypes.ADD_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_FAILURE,
  }
);

export const deleteTemplateMaterialsGroupAttachment = makeThunkFromTypes(
  APIv1.deleteTemplateMaterialsGroupAttachment,
  {
    request: actionTypes.DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_REQUEST,
    success: actionTypes.DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_SUCCESS,
    failure: actionTypes.DELETE_TEMPLATE_MATERIALS_GROUP_ATTACHMENTS_FAILURE,
  }
);
