import { string, shape, number, oneOf } from 'prop-types';
import { idTypes, addressTypes } from './common';

export const instituteTypes = shape({
  id: idTypes,
  name: string,
  phone: string,
  addressId: number,
  address: oneOf([addressTypes, {}]),
});
