import SecureLS from 'secure-ls';

import browserInfo from 'utils/browser-info';
import { SHOULD_KEEP_SESSION_KEY } from 'app-constants';

const { version, name } = browserInfo.parsedResult.browser;

const ls = new SecureLS({
  encodingType: 'rc4',
  isCompression: true,
  encryptionSecret: `${version}${name}`,
});

export const shouldKeepSession = () =>
  Boolean(JSON.parse(localStorage.getItem(SHOULD_KEEP_SESSION_KEY)));

const Store = () => {
  const setKeepSession = (keep) => {
    localStorage.setItem(SHOULD_KEEP_SESSION_KEY, JSON.stringify(keep));
  };

  const browserStoreInstance = (manualStorageType) => {
    let instance = manualStorageType || localStorage;
    if (!shouldKeepSession() && !manualStorageType) {
      instance = sessionStorage;
    }

    ls.ls = {
      setItem: (key, value) => instance.setItem(key, value),
      getItem: (key) => instance.getItem(key),
      removeItem: (key) => instance.removeItem(key),
      clear: () => instance.clear(),
    };

    return ls;
  };

  const clear = () => browserStoreInstance().clear();

  const remove = (key) => {
    browserStoreInstance().remove(key);
  };

  const get = (key, manualStorageType) => {
    try {
      return browserStoreInstance(manualStorageType).get(key);
    } catch (err) {
      console.error('something went wrong', err);
      clear();
    }
    return null;
  };

  const set = (key, value) => browserStoreInstance().set(key, value);

  return Object.freeze({
    get,
    set,
    remove,
    clear,
    shouldKeepSession,
    setKeepSession,
  });
};

export { ls as mockHashedStore };
export default Store();
