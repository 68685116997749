import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorsSelector,
  createLoadingSelector,
} from 'store/modules/api-requests';
import {
  searchLibraryItems,
  searchReset,
  SEARCH_LIBRARY_ELEMENTS_REQUEST,
  selectSearchResultsList,
} from 'store/modules/library';
import { LibraryCategories } from 'common-types/library';

const loadingSelector = createLoadingSelector([
  SEARCH_LIBRARY_ELEMENTS_REQUEST,
]);
const errorSelector = createErrorsSelector([SEARCH_LIBRARY_ELEMENTS_REQUEST]);

export const useLibrarySearch = (
  categories: LibraryCategories | LibraryCategories[]
) => {
  const dispatch = useDispatch();
  const results = useSelector(selectSearchResultsList);

  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);

  const search = (query) =>
    dispatch(
      searchLibraryItems(query, ([] as LibraryCategories[]).concat(categories))
    );

  const reset = () => dispatch(searchReset());

  return {
    results,
    reset,
    search,
    loading,
    error,
  };
};
