import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './NotificationBadge.module.scss';

export interface NotificationBadgeProps {
  children?: ReactNode | ReactNode[];
  className?: string;
  show?: boolean;
}

export const NotificationBadge: FC<NotificationBadgeProps> = ({
  children,
  className,
  show = false,
  ...restProps
}) => (
  <div
    className={cn(styles.badge, {
      [styles.badgeShow]: show,
    })}
    {...restProps}
  >
    {children}
  </div>
);
