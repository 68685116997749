import cn from 'classnames';
import React, { ComponentType, HTMLAttributes } from 'react';
import classes from '../InputString.module.scss';

interface Props {
  error?: string;
}

export const Input: ComponentType<Props & HTMLAttributes<HTMLInputElement>> = ({
  className,
  error,
  ...inputProps
}) => (
  <input
    className={cn(className, classes.input, { [classes.error]: error })}
    {...inputProps}
  />
);
