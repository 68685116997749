import React, { FC, MouseEvent, useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { isSameWeek, isValid } from 'date-fns';
import noop from 'lodash/noop';

import styles from './Toolbar.module.scss';
import { NAVIGATION_STEPS } from './utils';

import { parseDate } from 'utils';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Dropdown } from 'components/Dropdown';
import { DatePicker } from 'components/DatePicker';

interface ToolbarProps {
  /**
   * Calendar view (Week, Month, ...)
   */
  view: string;
  /**
   * Date range
   */
  label: string;
  /**
   * Localized calendar content
   */
  localizer: { messages: Record<string, string> };
  /**
   * Selected date
   */
  date?: number;
  /**
   * Function to move between dates
   */
  onNavigate: (step: NAVIGATION_STEPS, date?: string) => void;
}

/**
 * Renders calendar toolbar with select, move between dates functionality
 */
export const Toolbar: FC<ToolbarProps> = ({
  view,
  label,
  localizer,
  date,
  onNavigate,
}) => {
  const isCurrentWeek = useMemo(() => {
    if (view !== 'week') return false;
    return date ? isSameWeek(new Date(), new Date(date)) : false;
  }, [date, view]);

  // go to next, prev, today steps
  const onNavigateHandler = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      onNavigate(ev.currentTarget.value as NAVIGATION_STEPS);
    },
    [onNavigate]
  );

  // go to slected date
  const onNavigateToDateHandler = useCallback(
    (date: string) => {
      const parsedDate = parseDate(date);

      if (isValid(parsedDate)) {
        onNavigate(NAVIGATION_STEPS.DATE, parsedDate);
      }
    },
    [onNavigate]
  );

  return (
    <div className={styles.toolbar}>
      <Button
        variant="secondary"
        onClick={isCurrentWeek ? undefined : onNavigateHandler}
        value={NAVIGATION_STEPS.TODAY}
        className={styles.today}
      >
        {localizer.messages.today}
      </Button>
      <Button
        variant="secondary"
        onClick={onNavigateHandler}
        value={NAVIGATION_STEPS.PREV}
        className={styles.prev}
      >
        <Icon name="chevron-left" />
      </Button>
      <Button
        variant="secondary"
        onClick={onNavigateHandler}
        value={NAVIGATION_STEPS.NEXT}
        className={styles.next}
      >
        <Icon name="chevron-right" />
      </Button>
      <Dropdown
        buttonComponent={(props: Record<string, any>) => (
          // Overwrite onBlur drop down handler to prevent close popup on datepicker autofocus
          <Button variant="secondary" {...props} onBlur={noop}>
            {label}
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <DatePicker
            autoFocus
            value={date}
            onChange={compose(closeMenu, onNavigateToDateHandler)}
            className={styles.datePicker}
          />
        )}
      </Dropdown>
    </div>
  );
};
