import { combineReducers } from 'redux';

import { READ_SCHEDULED_OPERATIONS_SUCCESS } from './types';

export const operationsReducer = (state = null, { type, payload }) => {
  switch (type) {
    case READ_SCHEDULED_OPERATIONS_SUCCESS:
      return payload.result;

    default:
      return state;
  }
};

export default combineReducers({
  operations: operationsReducer,
});
