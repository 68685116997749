import { instituteSchema } from 'api/v1/schemas';
import { FALLBACK_LANGUAGE } from 'app-constants';
import { NURSE } from 'app-constants/operation-participants-roles';
import { pick } from 'lodash';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { createPropsGetter, createStateGetter } from 'store/utils';
import { getCurrentLanguage } from 'translations/i18n-instance';
import { translateValue } from 'utils';
import someArgument from 'utils/some-argument';
import { selectEntities, selectUsers } from '../entities/selectors/selectors';

export const selectUser = createSelector(
  [createStateGetter('auth.userId'), selectEntities],
  (userId, entities) => {
    const user = entities?.users?.[userId];
    return user ?? null;
  }
);

export const selectMessageStatus = createStateGetter('auth.notify');

export const selectUserLanguage = createSelector(selectUser, (user) => {
  return user?.settings?.lang ?? FALLBACK_LANGUAGE;
});

export const selectCurrentLanguage = createSelector(
  [
    getCurrentLanguage,
    selectUserLanguage,
    createPropsGetter('lng'),
    createPropsGetter('language'),
  ],
  someArgument
);

export const selectUserInstituteId = createSelector(
  selectUser,
  createStateGetter('instituteId')
);

export const selectUserDepartmentId = createSelector(
  selectUser,
  createStateGetter('departmentId')
);

export const hasDepartment = createSelector(selectUserDepartmentId, Boolean);

export const selectDefaultCategoryId = createSelector(
  selectUser,
  createStateGetter('defaultCategoryId')
);

export const selectUserContacts = createSelector(
  [selectUser],
  createStateGetter('contacts', [])
);

export const selectReportingUserContacts = createSelector(
  [selectUserContacts],
  (contacts) => contacts.map((email) => ` ${email.value}`)
);

export const isLoggedIn = createSelector(selectUser, (user) => Boolean(user));

export const selectUserId = createSelector(
  selectUser,
  (user) => user && user.id
);

export const createTranslatableSelector = (selector) =>
  createSelector([selector, selectCurrentLanguage], translateValue);

export const selectUserSignatureUrl = createSelector(
  selectUser,
  createStateGetter('signatureUrl')
);

export const selectShortUserData = (userData) =>
  pick(userData, ['id', 'givenName', 'surname', 'imageUrl', 'institute']);

export const selectInstitute = createSelector(
  [selectUser, selectEntities],
  (user, entities) => {
    const instituteId = user?.instituteId ?? null;
    const institute = entities?.institutes?.[instituteId];
    return denormalize(institute, instituteSchema, entities);
  }
);

export const selectInstituteContacts = createSelector(
  [selectInstitute, selectEntities],
  (institute) => institute?.companiesContacts
);

export const selectUserDefaultAssignees = createSelector(
  [selectUser, selectUsers],
  (user, users) => {
    if (!user || !users) return null;
    return user.defaultAssignees.map((assigneeId) => users[assigneeId]);
  }
);

export const selectInstituteDefaultAssignees = createSelector(
  [selectInstitute],
  createStateGetter('defaultAssignees')
);

export const getNurseAssignee = (assignees) =>
  assignees?.find((a) => a.defaultAssigneeRole === NURSE);

export const selectDefaultNurseAssignee = createSelector(
  [selectUserDefaultAssignees, selectInstituteDefaultAssignees],
  (userAssignees, instituteAssignees) => {
    const defaultAssignee =
      getNurseAssignee(userAssignees) || getNurseAssignee(instituteAssignees);
    return defaultAssignee;
  }
);

export const selectUserRoles = createSelector(
  [selectUser],
  createStateGetter('roles', {})
);
