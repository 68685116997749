import Bugsnag from '@bugsnag/js';
import * as APIv1 from 'api/v1';
import i18n from 'translations/i18n-instance';
import {
  ADD_OPERATION_ELEMENT,
  ADD_OPERATION_ELEMENT_FAILURE,
  ADD_OPERATION_ELEMENT_REQUEST,
  ADD_OPERATION_ELEMENT_SUCCESS,
  CHANGE_OPERATION_ELEMENTS_ORDER,
  DELETE_OPERATION_ELEMENT,
  READ_OPERATION_ELEMENTS_ATTACHMENTS_FAILURE,
  READ_OPERATION_ELEMENTS_ATTACHMENTS_REQUEST,
  READ_OPERATION_ELEMENTS_ATTACHMENTS_SUCCESS,
  READ_OPERATION_ELEMENTS_FAILURE,
  READ_OPERATION_ELEMENTS_REQUEST,
  READ_OPERATION_ELEMENTS_SUCCESS,
  UPDATE_OPERATION_ELEMENT,
  UPDATE_OPERATION_ELEMENTS_FAILURE,
  UPDATE_OPERATION_ELEMENTS_REQUEST,
  UPDATE_OPERATION_ELEMENTS_SUCCESS,
  UPDATE_OPERATION_ELEMENT_FAILURE,
  UPDATE_OPERATION_ELEMENT_REQUEST,
  UPDATE_OPERATION_ELEMENT_SUCCESS,
} from '../types';
import { makeThunkFromTypes } from 'store/utils/make-thunk';
import { normalizeLibraryElement } from 'api/v1/schemas';
import { getClearedUpElementsList } from '../helpers';
import { selectOperationElements } from 'store/modules/entities/selectors/operations';

export const readOperationElements = makeThunkFromTypes(
  APIv1.readOperationElements,
  {
    request: READ_OPERATION_ELEMENTS_REQUEST,
    success: READ_OPERATION_ELEMENTS_SUCCESS,
    failure: READ_OPERATION_ELEMENTS_FAILURE,
  }
);

export const readOperationElementsAttachments = makeThunkFromTypes(
  APIv1.readOperationElementsAttachments,
  {
    request: READ_OPERATION_ELEMENTS_ATTACHMENTS_REQUEST,
    success: READ_OPERATION_ELEMENTS_ATTACHMENTS_SUCCESS,
    failure: READ_OPERATION_ELEMENTS_ATTACHMENTS_FAILURE,
  }
);

export const updateOperationElements = makeThunkFromTypes(
  APIv1.updateOperationElements,
  {
    request: UPDATE_OPERATION_ELEMENTS_REQUEST,
    success: UPDATE_OPERATION_ELEMENTS_SUCCESS,
    failure: UPDATE_OPERATION_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateOperationElements')} (id: ${id})`
);

export const reorderOperationElements = makeThunkFromTypes(
  APIv1.reorderOperationElements,
  {
    request: UPDATE_OPERATION_ELEMENTS_REQUEST,
    success: UPDATE_OPERATION_ELEMENTS_SUCCESS,
    failure: UPDATE_OPERATION_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateOperationElements')} (id: ${id})`
);

export const updateOperationElementThunk = makeThunkFromTypes(
  APIv1.updateOperationElement,
  {
    request: UPDATE_OPERATION_ELEMENT_REQUEST,
    success: UPDATE_OPERATION_ELEMENT_SUCCESS,
    failure: UPDATE_OPERATION_ELEMENT_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateOperationElements')} (id: ${id})`
);

export const updateOperationElementAttachmentThunk = makeThunkFromTypes(
  APIv1.updateOperationElementAttachment,
  {
    request: UPDATE_OPERATION_ELEMENT_REQUEST,
    success: UPDATE_OPERATION_ELEMENT_SUCCESS,
    failure: UPDATE_OPERATION_ELEMENT_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateOperationElements')} (id: ${id})`
);

export const deleteOperationElement = makeThunkFromTypes(
  APIv1.deleteOperationElement,
  {
    request: UPDATE_OPERATION_ELEMENTS_REQUEST,
    success: UPDATE_OPERATION_ELEMENTS_SUCCESS,
    failure: UPDATE_OPERATION_ELEMENTS_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateOperationElements')} (id: ${id})`
);

// FIXME: Change actions
export const addOperationElementThunk = makeThunkFromTypes(
  APIv1.addOperationElement,
  {
    request: ADD_OPERATION_ELEMENT_REQUEST,
    success: ADD_OPERATION_ELEMENT_SUCCESS,
    failure: ADD_OPERATION_ELEMENT_FAILURE,
  },
  (id) => `${i18n.t('errors:failedToUpdateOperationElements')} (id: ${id})`
);

export const addOperationElement =
  ({ operationId, element: rawElement, index, language }) =>
  async (dispatch, getState) => {
    const normalized = normalizeLibraryElement(rawElement);
    const { entities, result } = normalized;
    const element = entities.libraryElements[result];
    const clearedUpElements = getClearedUpElementsList([element]);
    const state = getState();

    await dispatch({
      type: ADD_OPERATION_ELEMENT,
      payload: {
        operationId,
        index,
        ...normalized,
        element,
      },
    });

    const elementWithOrder = { ...clearedUpElements[0], order: index };

    try {
      return dispatch(
        addOperationElementThunk(
          operationId,
          elementWithOrder,
          state?.language?.['deepl-language'] || language
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const updateOperationElementAttachment =
  ({ operationId, uuid, update, language }) =>
  async (dispatch, getState) => {
    await dispatch({
      type: UPDATE_OPERATION_ELEMENT,
      payload: { operationId, uuid, update },
    });

    const state = getState();
    const updatedElements = selectOperationElements(state, {
      id: operationId,
    });

    const elementToUpdate = updatedElements.find((el) => el.uuid === uuid);
    const element = getClearedUpElementsList([elementToUpdate])[0];

    try {
      return dispatch(
        updateOperationElementAttachmentThunk(
          operationId,
          element.relationId,
          update,
          state?.language?.['deepl-language'] || language
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const updateOperationElementById =
  ({ operationId, uuid, update, language }) =>
  async (dispatch, getState) => {
    await dispatch({
      type: UPDATE_OPERATION_ELEMENT,
      payload: { operationId, uuid, update },
    });

    const state = getState();
    const updatedElements = selectOperationElements(state, {
      id: operationId,
    });

    const elementToUpdate = updatedElements.find((el) => el.uuid === uuid);

    try {
      const clearedUpElements = getClearedUpElementsList([elementToUpdate]);
      return dispatch(
        updateOperationElementThunk(
          operationId,
          elementToUpdate.relationId,
          { ...clearedUpElements[0], order: elementToUpdate.order },
          state?.language?.['deepl-language'] || language
        )
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const deleteOperationElementThunk =
  (operationId, uuid, lang) => async (dispatch, getState) => {
    const state = getState();
    const elements = selectOperationElements(state, {
      id: operationId,
    });

    await dispatch({
      type: DELETE_OPERATION_ELEMENT,
      payload: { operationId, uuid },
    });

    const elementToDelete = elements.find((el) => el.uuid === uuid);
    // const clearedUpElements = getClearedUpElementsList([elementToDelete]);

    try {
      return dispatch(
        deleteOperationElement(operationId, elementToDelete.relationId, lang)
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

export const changeOperationElementsOrder =
  (payload) => async (dispatch, getState) => {
    const { operationId, language } = payload;
    await dispatch({ type: CHANGE_OPERATION_ELEMENTS_ORDER, payload });
    const state = getState();
    const updatedElements = selectOperationElements(state, {
      id: operationId,
    });

    try {
      const clearedUpElements = getClearedUpElementsList(updatedElements);
      return dispatch(
        reorderOperationElements(operationId, clearedUpElements, language)
      );
    } catch (error) {
      Bugsnag.notify(error);
    }
  };
