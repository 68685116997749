import React, { forwardRef, TextareaHTMLAttributes, useMemo } from 'react';
import translatableHOC from '../translatableHOC';
import { TextareaBase } from './TextareaBase';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  mode?: 'default' | 'translatable';
  name?: string;
  innerRef?: any;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { mode = 'default', ...textareaProps } = props;
    const TextareaComponent = useMemo(() => {
      switch (mode) {
        case 'translatable':
          return translatableHOC(TextareaBase);
        default:
          return TextareaBase;
      }
    }, [mode]);

    return <TextareaComponent ref={ref} {...textareaProps} />;
  }
);
