import { ResponseMeta, ThunkDispatch } from 'common-types/common';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

interface useResourceHookFn {
  (defaultValue?: any): {
    error: string | null;
    loading: boolean;
    meta?: ResponseMeta | null;
    pristine: boolean;
    resource: any;
    result?: number | number[];
    fetch: (...requestArgs: any) => void;
    response?: any;
    parsed?: any;
  };
}

const parseResource = (entities, result, entityName) => {
  if (Array.isArray(result)) {
    return result.map((resourceId) => entities[entityName]?.[resourceId]);
  } else if (typeof result === 'number' || typeof result === 'string') {
    return entities[entityName]?.[result];
  } else if (typeof result === 'object') {
    return parseResource(entities, result[entityName], entityName);
  }
};

const parseResponse = (
  response: { entities; result: number | number[]; meta: ResponseMeta },
  entityName: string
): null | { resource; result: number | number[]; meta: ResponseMeta } => {
  const { entities, result, meta } = response;
  if (!result) return null;
  const resource = parseResource(entities, result, entityName);
  return { resource, result, meta };
};

interface makeResourcesHookFn {
  (requestThunk: any, entityName: string): useResourceHookFn;
}

export const makeResourcesHook: makeResourcesHookFn = (
  requestThunk,
  entityName
) => {
  const useResource: useResourceHookFn = (defaultValue = null) => {
    const dispatch = useDispatch<ThunkDispatch>();
    const [pristine, setPristine] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [responseState, setResponseState] = useState<{
      meta?: ResponseMeta | null;
      parsed?: any;
      resource: any;
      response?: any;
      result?: number | number[];
    }>({ resource: defaultValue });

    const fetch = useCallback(
      async function fetchResource(...requestArgs) {
        setError(null);
        setPristine(false);
        setLoading(true);
        try {
          const response = (await dispatch(requestThunk(...requestArgs))) || {};
          const parsed = parseResponse(response, entityName);
          if (!parsed) throw new Error('Error: cannot parse server response.');
          const { resource, result, meta = null } = parsed;
          setResponseState({
            resource,
            result,
            meta,
            response,
            parsed,
          });
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      },
      [dispatch]
    );
    const { resource, result, meta = null, response, parsed } = responseState;

    return {
      error,
      fetch,
      loading,
      meta,
      pristine,
      resource,
      result,
      response,
      parsed,
    };
  };

  return useResource;
};
