import { ProductType } from 'common-types/common';

import impediaLogoPrimarySrc from './images/logo.svg';
import impediaLogoSecondarySrc from './images/logo-secondary.svg';
import impediaLogoMinifiedSrc from './images/logo-minified.svg';

import vetLogoPrimarySrc from './images/logo-vet.svg';
import vetLogoSecondarySrc from './images/logo-secondary-vet.svg';
import vetLogoMinifiedSrc from './images/logo-minified-vet.svg';

export type LogoVariant = 'primary' | 'secondary' | 'minified';

type LogoVariantSources = Record<LogoVariant, string>;

type ProductLogoSources = Record<ProductType, LogoVariantSources>;

const impediaLogoSrcs: LogoVariantSources = {
  primary: impediaLogoPrimarySrc,
  secondary: impediaLogoSecondarySrc,
  minified: impediaLogoMinifiedSrc,
};

const vetLogoSrcs: LogoVariantSources = {
  primary: vetLogoPrimarySrc,
  secondary: vetLogoSecondarySrc,
  minified: vetLogoMinifiedSrc,
};

const logoSrcs: ProductLogoSources = {
  impedia: impediaLogoSrcs,
  vet: vetLogoSrcs,
};

/**
 * Returns logo src depend on variant prop
 */
export const getLogoSrc = (
  productType: ProductType,
  variant: LogoVariant = 'primary'
) => {
  return logoSrcs[productType][variant];
};
