import React, { ComponentType } from 'react';
import { OperationStepAttachment } from './OperationStepAttachment';

import classes from './OperationStepAttachments.module.scss';

interface Props {
  attachments: number[];
  onImageClick;
  onRemove;
}

export const AttachmentsList: ComponentType<Props> = ({
  attachments,
  onImageClick,
  onRemove,
}) => {
  return (
    <div className={classes.imgList}>
      {attachments.map((attachmentId) => (
        <OperationStepAttachment
          key={attachmentId}
          id={attachmentId}
          onClick={onImageClick}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};

AttachmentsList.displayName = 'AttachmentsList';
