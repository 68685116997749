import { AxiosRequestConfig } from 'axios';
import { APIv1 } from 'api/instance';
import { normalizeResponse } from 'api/helpers/normalize-response';
import { assessmentFormListSchema } from './schemas';
import { withoutId } from '../../utils';
import { AssessmentFormData } from 'scenes/AssessmentForms/admin/types/assessmentFormData';
import { AssessmentFormTypes } from 'common-types/assessmentFormTypes';
import { AssessmentForm } from 'common-types/assessmentForm';

export const createAssessmentForm = (assessmentForm: AssessmentFormData) => {
  const {
    title,
    type,
    categoryId: disciplineId,
    weightingEnabled,
    subType,
    scoreRange,
  } = assessmentForm.form;

  const topics = assessmentForm.topics.map((topic) => ({
    ...withoutId(topic),
    formFreeTexts: topic.formFreeTexts.map((freeText) => ({
      ...withoutId(freeText),
    })),
    competences: topic.competences.map((competence) => ({
      ...withoutId(competence),
      subCompetences: competence.subCompetences.map(withoutId),
    })),
  }));

  const normalizedType = type === AssessmentFormTypes.SOB ? type : subType;

  return APIv1.post(`/assessments/forms`, {
    title,
    disciplineId: disciplineId !== null ? `${disciplineId}` : disciplineId, // leave only disciplineId after we migrate
    type: normalizedType,
    weightingEnabled: !!+weightingEnabled,
    topics,
    scoreRange,
  });
};

export const updateAssessmentForm = (assessmentForm: AssessmentFormData) => {
  const {
    id,
    title,
    type,
    categoryId: disciplineId,
    weightingEnabled,
    subType,
    scoreRange,
  } = assessmentForm.form;

  const normalizedType = type === AssessmentFormTypes.SOB ? type : subType;

  return APIv1.patch(`/assessments/forms/${id}`, {
    title,
    type: normalizedType,
    disciplineId: disciplineId !== null ? `${disciplineId}` : disciplineId, // leave only disciplineId after we migrate
    weightingEnabled: !!+weightingEnabled,
    topics: assessmentForm.topics,
    scoreRange,
  });
};

export const readAssessmentForms = async (config?: AxiosRequestConfig) => {
  return APIv1.get(`/assessments/forms`, config).then(
    normalizeResponse(assessmentFormListSchema)
  );
};

export const readAssessmentFormById = async (
  id: string,
  config?: AxiosRequestConfig
) => {
  return APIv1.get<AssessmentForm>(`/assessments/forms/${id}`, config).then(
    (resp) => resp as unknown as AssessmentForm
  );
};

export const deleteAssessmentFormById = (id: string, config?) => {
  return APIv1.delete(`/assessments/forms/${id}`, config);
};
