import { has } from 'lodash';
import { toHash } from 'utils/arrayUtils';

export default (state = {}, { payload }) => {
  if (payload?.attachments) {
    const { attachments } = payload;
    return {
      ...state,
      ...toHash(attachments, 'id'),
    };
  } else if (has(payload, 'entities.attachments')) {
    return { ...(state || {}), ...payload.entities.attachments };
  }

  return state;
};
