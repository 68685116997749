import cn from 'classnames';
import React, { FC, HTMLAttributes, memo, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import classes from './NotFound.module.scss';

import { HOME } from 'scenes/routes.enum';
import { Logo } from 'components/Logo';
import { Text } from 'components/Typography/Text';
import { Button } from 'components/Button';

interface NotFoundProps extends HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  message?: ReactNode;
  backLabel?: string;
  backLink?: string;
  withBackButton?: boolean;
  onBackButtonClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

export const NotFound: FC<NotFoundProps> = memo(function NotFound({
  header,
  message,
  backLabel,
  backLink = HOME,
  withBackButton = true,
  onBackButtonClick,
  className,
  children,
}) {
  const { t } = useTranslation();

  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.container}>
        <Logo variant="minified" className={classes.img} />

        <Text variant="heading-xl" color="default" className="mb-05">
          {header ?? t('pageNotFound')}
        </Text>

        <Text variant="paragraph-sm" color="secondary" className="mb-1">
          {message ?? t('pageDoesNotExists')}
        </Text>

        {withBackButton && (
          <Link to={backLink}>
            <Button onClick={onBackButtonClick}>
              {backLabel || t('backToDashboard')}
            </Button>
          </Link>
        )}

        {children}
      </div>
    </div>
  );
});
