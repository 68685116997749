import { Select } from 'components/Form/Select';
import React, { forwardRef } from 'react';
import { FormInputBase } from '../FormInputBase';

export const FormSelect = forwardRef<any, any>((props, ref) => {
  return (
    <FormInputBase formInputType="input" {...props}>
      {({ getProps }) => <Select ref={ref} {...getProps()} />}
    </FormInputBase>
  );
});
