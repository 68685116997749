import FileIcon from 'assets/File.svg';
import React, { ComponentType } from 'react';
import classes from './AttachmentPic.module.scss';

type FileProps = {
  extension: string;
  name: string;
};

export const File: ComponentType<FileProps> = ({ extension }) => {
  return (
    <div className={classes.file}>
      <img className={classes.fileIcon} src={FileIcon} alt="File" />
      {extension && <span className={classes.extension}>{extension}</span>}
    </div>
  );
};
