import { isObject } from 'lodash';
import { kebab } from 'case';

const getDataAttribute = (key: string, value?: string | number | null) => ({
  [`data-${kebab(key)}`]:
    typeof value === 'string' || typeof value === 'number' ? value : '',
});

/**
 * Creates object of data attributes
 */
export const setDataAttributes = (
  tags: string | string[] | Record<string, string | null>,
  value?: string | number | unknown
) => {
  if (!tags) return {};

  if (typeof tags === 'string') {
    return getDataAttribute(tags, value as string);
  } else if (Array.isArray(tags)) {
    return tags.reduce(
      (acc, key) => ({
        ...acc,
        ...getDataAttribute(key),
      }),
      {}
    );
  } else if (isObject(tags)) {
    return Object.keys(tags).reduce(
      (acc, key) => ({
        ...acc,
        ...getDataAttribute(key, tags[key]),
      }),
      {}
    );
  }
};
