import set from 'lodash/set';
import not from 'lodash/negate';

export const deleteItemById = (collection, id) =>
  collection.filter(item => item.id !== id);

export const setState = (state, path, update) =>
  set({ ...state }, path, update);

export const updateOrAddItemById = (collection, id, updatedItem) => {
  const isNewItem = !collection.find(item => item.id === id);
  if (isNewItem) return [...collection, updatedItem];

  return collection.map(item => {
    if (item.id === id) {
      return { ...item, ...updatedItem };
    }

    return item;
  });
};

const isStandardQuestion = question => question.isStandard;
const isNotEmpty = question => Boolean(question.content);

export const getStandardQuestions = questions => {
  if (!Array.isArray(questions)) return questions;
  return questions.filter(isNotEmpty).filter(isStandardQuestion);
};

export const getNonStandardQuestions = questions => {
  if (!Array.isArray(questions)) return questions;
  return questions.filter(isNotEmpty).filter(not(isStandardQuestion));
};

export const getActiveItemsIds = items => {
  if (!Array.isArray(items)) return items;
  return items
    .filter(question => question.isActive)
    .map(question => question.id);
};
