import { REQUEST_TYPES, REQUEST_STATES } from 'app-constants';

export default (requestState, requestType) => {
  if (requestState === REQUEST_STATES.REQUEST) {
    switch (requestType) {
      case REQUEST_TYPES.CREATE:
        return 'save';
      case REQUEST_TYPES.UPDATE:
        return 'update';
      case REQUEST_TYPES.DELETE:
        return 'delete';
      case REQUEST_TYPES.SUBMIT:
        return 'submit';
      case REQUEST_TYPES.SEARCH:
        return 'search';
      case REQUEST_TYPES.UPLOAD:
        return 'upload';
      default:
        return 'load';
    }
  } else if (requestState === REQUEST_STATES.SUCCESS) {
    switch (requestType) {
      case REQUEST_TYPES.CREATE:
        return 'save-completed';
      case REQUEST_TYPES.UPDATE:
        return 'update-completed';
      case REQUEST_TYPES.DELETE:
        return 'delete-completed';
      case REQUEST_TYPES.SUBMIT:
        return 'submit-completed';
      case REQUEST_TYPES.SEARCH:
        return 'search-completed';
      case REQUEST_TYPES.UPLOAD:
        return 'upload-completed';
      default:
        return 'load-completed';
    }
  }
  return 'load';
};
