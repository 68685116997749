import forEach from 'lodash/forEach';
import { FALLBACK_LANGUAGE, SUPPORTED_LANGUAGES } from 'app-constants';
import { getCurrentLanguage, i18n } from 'translations/i18n-instance';
import {
  TranslatableString,
  Language,
  TranslationResult,
} from 'common-types/common';

/**
 * WARNING! This function has a side-effect since it uses the i18n.language variable
 * @param str
 */
export const toTranslatable = (str: string): TranslatableString => {
  return { [i18n.language]: str ?? '' };
};

/**
 * WARNING! This function has a side-effect since it uses the i18n.language variable
 * @param translatable
 */
export const sanitizeTranslatable = (translatable) => {
  const { id, ...translatableWithNoId } = translatable;
  const sanitizedTranslatable = SUPPORTED_LANGUAGES.reduce((result, lang) => {
    result[lang] = result[lang] || '';
    return result;
  }, translatableWithNoId);
  return sanitizedTranslatable;
};

export const isTranslatableString = (value: any): boolean =>
  Boolean(
    value &&
      typeof value === 'object' &&
      SUPPORTED_LANGUAGES.some((language) => value.hasOwnProperty(language))
  );

export const translateValue = (
  translatable: TranslatableString | any,
  lang?: Language,
  allowFallback: boolean = true
): string => {
  // 1. Return translatable argument itself if its not of type TranslatableString
  if (typeof translatable === 'string' || !isTranslatableString(translatable)) {
    return translatable;
  }

  const language = lang || getCurrentLanguage();
  let translatedString = (translatable as TranslatableString)[language];

  // 2. Return fallback if the translatable have no required language translation
  // and the `allowFallback` option is enabled
  if (!translatedString && allowFallback) {
    translatedString =
      translatable[FALLBACK_LANGUAGE] ||
      SUPPORTED_LANGUAGES.reduce(
        (translated, langKey) => translated || translatable[langKey],
        ''
      );
  }

  // 3. Return translated or empty string if not available
  return translatedString || '';
};

export const translateObject = (obj: object, lang: Language): object => {
  forEach(obj, (value, field) => {
    obj[field] = translateValue(value, lang); // eslint-disable-line no-param-reassign
  });

  return obj;
};

export const isTranslatableStringEmpty = (
  translatable: TranslatableString | any
) => {
  if (!isTranslatableString(translatable)) return true;
  return !SUPPORTED_LANGUAGES.some((lang) =>
    Boolean((translatable as TranslatableString)[lang])
  );
};

export const translatableInputName = (name, language) => `${name}.${language}`;

export const guessTranslation = (translatable, language) => {
  if (!translatable || typeof translatable === 'string')
    return {
      language: null,
      translation: translatable,
      translatable,
    };

  const translatedString = translateValue(translatable, language, false);

  if (translatedString) {
    return {
      language,
      translation: translatedString,
      translatable,
    };
  }

  const fallbackLanguages = (SUPPORTED_LANGUAGES as Language[]).filter(
    (l) => l !== language
  );
  const fallbackTranslation = fallbackLanguages.reduce(
    (result, currentFallbackLang) => {
      if (result) return result;
      const fallbackTranslatedString = translateValue(
        translatable,
        currentFallbackLang,
        false
      );
      return fallbackTranslatedString
        ? {
            language: currentFallbackLang,
            translation: fallbackTranslatedString,
            translatable,
          }
        : null;
    },
    null as TranslationResult | null
  );

  return fallbackTranslation;
};

export const useTranslatableString = (
  translatable: string | TranslatableString,
  opts: { lang?: Language; allowFallback?: boolean } = {}
): TranslationResult | null => {
  const { lang: langProp, allowFallback = true } = opts;
  const i18nLanguage = getCurrentLanguage();
  const language: Language =
    (i18nLanguage as Language) ?? langProp ?? FALLBACK_LANGUAGE;

  if (!translatable || typeof translatable === 'string') {
    return {
      language: null,
      translation: translatable,
      translatable,
    };
  }

  const translatedString = translateValue(translatable, language, false);
  if (!translatedString && allowFallback) {
    const fallbackTranslation = guessTranslation(translatable, language);
    return fallbackTranslation;
  }

  return {
    language,
    translation: translatedString,
    translatable,
  };
};
