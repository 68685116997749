import * as types from './types';

export const showModalDialog = (
  modalName,
  {
    dialogProps,
    closeOn,
    modalProps,
  }: { dialogProps?; closeOn?; modalProps? } = {}
) => ({
  type: types.SHOW_MODAL_DIALOG,
  payload: {
    closeOn,
    dialogProps,
    modalName,
    modalProps,
  },
});

export const hideModalDialog = () => ({
  type: types.HIDE_MODAL_DIALOG,
});
