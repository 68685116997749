import classNames from 'classnames';
import React, { ElementType, FC } from 'react';

import styles from './Tabs.module.scss';

export interface TabProps {
  className?: string;
  component?: ElementType;
  isActive?: boolean;
  activeClassName?: string;
}

export const Tab: FC<TabProps> = ({
  className,
  children,
  component: RenderComponent = 'li',
  isActive = false,
  ...restProps
}) => {
  return (
    <RenderComponent
      className={classNames(className, styles.tab, {
        [styles.activeTab]: isActive,
      })}
      {...restProps}
    >
      {children}
    </RenderComponent>
  );
};
